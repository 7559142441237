import * as React from 'react'
import { IBill } from '../../../types';
import { CartTable } from './Auxiliary/CartTable';
import { CartTableRow } from './Auxiliary/CartTableRow';

export interface ShoppingCartProps {
    title: JSX.Element
    optionsTitle: string
    bills: IBill[]
    billTotal: number;
    deleteBill: (billId: string) => void;
}

export default class ShoppingCart extends React.Component<ShoppingCartProps>{
    constructor(props: ShoppingCartProps) {
        super(props);
    }
    render() {
        const selectedItems: JSX.Element[] = [];
        let i = 0;
        this.props.bills.forEach((bill: IBill) => {
            selectedItems.push(
                <CartTableRow
                    billId={bill.id}
                    billType={bill.billType}
                    billNumber={bill.id1}
                    paymentType={bill.id3}
                    amount={bill.amountDue || bill.amount}
                    key={i}
                    deleteBill={this.props.deleteBill}
                />
            );
            i++;
        });
        return (
            <div id="PaymentApplicationContent_pnlDefaulted">
                <div id="display-section-header" className="secured">
                    <span id="PaymentApplicationContent_lblSecured" title={this.props.optionsTitle}>{this.props.title}</span>
                </div>
                <div className="table-responsive">
                    <div>
                        <CartTable totalAmount={this.props.billTotal}>
                            {selectedItems}
                        </CartTable>
                    </div>
                </div>
            </div>
        )
    }
}