import React from "react";
import { Button, Col, Image, Row } from "react-bootstrap";
import { LinkPrivacyPolicy } from "../../../resources";
import TermsOfUse from "../TermsOfUse/TermsOfUse";

export interface FooterProps {
    footerImageUrl: string;
    friendlyName: string;
    customFooterHtml: string | null;
}

export const Footer = (props: FooterProps) => {
    const open = (event: React.MouseEvent<Button>) => {
        event.preventDefault();
        window.open("https://www.heartland.us/privacy-policy", "_blank");
    };

    return (
        <footer>
            <Row>
                <Col sm={4}>
                    <Image src={props.footerImageUrl} responsive alt="..." />
                </Col>
                <Col sm={4}>
                    <div className="text-center">
                        <b>{props.friendlyName}</b>
                    </div>
                    <div className="text-center">
                        <TermsOfUse />|
                        <Button bsStyle="link" onClick={open}>
                            {LinkPrivacyPolicy}
                        </Button>
                    </div>
                </Col>
            </Row>
            {props.customFooterHtml && (
                <div
                    dangerouslySetInnerHTML={{
                        __html: `<span>${props.customFooterHtml}</span>`,
                    }}
                />
            )}
        </footer>
    );
};
