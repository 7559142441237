import React from "react";
import { Row } from "react-bootstrap";

export const FooterSanDiego = () => {
    return (
        <footer className="footer billsearch-footer">
            <Row className="justify-content-md-center billsearch-footer-row mb-1">
                <div className="text-center">
                    <b>DAN McALLISTER</b> | County of San Diego Treasurer-Tax
                    Collector
                </div>
            </Row>
            <div className="hidden-xs">
                <Row className="justify-content-md-center billsearch-footer-row mb-1">
                    <div className="text-center">
                        San Diego County Administration Center | 1600 Pacific
                        Highway - Room 162 | San Diego, CA 92101
                    </div>
                </Row>
                <Row className="justify-content-md-center billsearch-footer-row mb-2">
                    <div className="text-center">
                        Questions: 877.829.4732 | Pay by Phone: 855.829.3773
                    </div>
                </Row>
            </div>
        </footer>
    );
};
