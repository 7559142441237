import React from "react";
import { connect } from "react-redux";
import { RouterProps } from "react-router";
import { search, updateCriteria } from "../../actions/BillSearch";
import {
    IApplication,
    IApplicationState,
    IBillSearchCriteria,
} from "../../types";
import SearchOptionContent from "./SearchOptionContent";

interface ISearchOptionStateProps {
    application: IApplication;
    criteria: IBillSearchCriteria;
    merchantName: string;
}

interface ISearchOptionDispatchProps {
    search: (
        merchantName: string,
        application: IApplication,
        billType: string,
        id1: string,
        id2: string,
        id3: string,
        id4: string,
        language: number,
        includePaidBills: boolean,
        address: string,
        postalCode: string,
        offset?: number,
        take?: number
    ) => void;
    updateCriteria: (criteria: IBillSearchCriteria) => void;
}

export type SearchOptionProps = ISearchOptionStateProps &
    ISearchOptionDispatchProps &
    RouterProps;

class SearchByOptions extends React.Component<SearchOptionProps> {
    constructor(props: SearchOptionProps) {
        super(props);
    }

    componentDidMount() {
        const query = this.getqueryParams();
        const id1 = query.get("id");
        if (id1) {
            this.searchSpecificBill(id1);
        }
    }

    render() {
        return <SearchOptionContent history={this.props.history} />;
    }

    private searchSpecificBill = (billId1: string) => {
        const { merchantName, application, criteria } = this.props;

        this.props.updateCriteria({
            ...criteria,
            id1: billId1,
            meta: {
                searchType: "QUERY_STRING",
                searchGroup: "BILL_NUMBER",
                searchCriteria: {
                    parcelNumber: billId1,
                    mailingAddress: "",
                    countryCode: "USA",
                    zipCode: "",
                    year: "",
                    billNumber: "",
                },
            },
        });
        this.props.search(
            merchantName,
            application,
            "",
            billId1,
            "",
            "",
            "",
            1,
            true,
            "",
            "",
            0,
            100
        );
        this.props.history.push("search-result");
    };

    private getqueryParams() {
        return new URLSearchParams(window.location.search);
    }
}

function mapStateToProps(state: IApplicationState) {
    return {
        merchantName: state.settings.merchantName,
        application: state.application,
        criteria: state.billSearch.criteria || ({} as IBillSearchCriteria),
    };
}

const mapDispatchToProps = {
    search,
    updateCriteria,
};

export default connect(mapStateToProps, mapDispatchToProps)(SearchByOptions);
