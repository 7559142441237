import React from "react";

import { Nav, NavItem } from "react-bootstrap";
import { Post } from "../../../utils/Post";

import { ILink } from "../../../types";

export interface MerchantLinksProps {
    links: ILink[] | null;
}

const handleSelect = (eventKey: any) => {
    /* if (event) {
        event.preventDefault();
    } */
    Post(eventKey, [], "GET");
};

const MerchantLinks = (props: MerchantLinksProps) => {
    const links =
        props.links &&
        props.links.map((link: any, index: any) => {
            return (
                <NavItem key={index} eventKey={link.href} href={link.href}>
                    {link.text}
                </NavItem>
            );
        });

    return <Nav onSelect={handleSelect}>{links}</Nav>;
};

export default MerchantLinks;
