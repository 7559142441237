import { faEdit } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { History } from "history";
import React from "react";
import { Col, Row } from "react-bootstrap";
import { FormattedMessage } from "react-intl";
import * as Resources from "../../resources";
import { IAccount, IApplication, IPayment, ISettings } from "../../types";
import BankPayment from "./BankPayment";
import CardPayment from "./CardPayment";
import SavedPayment from "./SavedPayment";

export interface IPaymentInformationProps {
    application: IApplication;
    history: History;
    merchant: ISettings;
    payment: IPayment;
    showEdit: boolean;
    userAccounts: IAccount[];
}

class PaymentInformation extends React.Component<IPaymentInformationProps> {
    constructor(props: IPaymentInformationProps) {
        super(props);

        this.onClickEdit = this.onClickEdit.bind(this);
    }

    onClickEdit(event: any) {
        event.preventDefault();
        const { application, merchant } = this.props;
        const path = application.guid
            ? `/${merchant.merchantName}/payment/${application.guid}`
            : `/${merchant.merchantName}/payment`;
        this.props.history.push(path);
        return true;
    }

    buildInput(label: any, value: any, labelIcon?: any) {
        return (
            <Row>
                <Col xs={5} sm={4} md={4}>
                    <strong>{label}</strong>
                </Col>
                <Col xs={7} sm={8} md={8}>
                    <span className="uneditable-input">{value}</span>
                </Col>
            </Row>
        );
    }

    formatCityStateZip(city: any, state: any, postal: any) {
        if (city && state && postal) {
            return city + ", " + state + ", " + postal;
        }
        if (city && state && !postal) {
            return city + ", " + state;
        }
        if (city && !state && postal) {
            return city + ", " + postal;
        }
        if (!city && state && postal) {
            return state + ", " + postal;
        }
        if (city) {
            return city;
        }
        if (state) {
            return state;
        }
        return postal;
    }

    render() {
        const { merchant, payment, showEdit, userAccounts } = this.props;
        let method: any;
        switch (payment.method) {
            case "credit":
                method = <CardPayment payment={payment} />;
                break;
            case "debit":
                method = <CardPayment payment={payment} />;
                break;
            case "checking":
                method = <BankPayment payment={payment} />;
                break;
            case "saved":
                method = (
                    <SavedPayment
                        payment={payment}
                        userAccounts={userAccounts}
                    />
                );
                break;
            default:
                break;
        }

        const labelBusiness = (
            <FormattedMessage
                id="common.payment.business"
                defaultMessage="Business Name"
            />
        );
        const labelFullName = (
            <FormattedMessage
                id="common.payment.fullname"
                defaultMessage="Full Name"
            />
        );
        const labelAddress = (
            <FormattedMessage
                id="common.payment.address"
                defaultMessage="Address"
            />
        );
        const businessInput = this.buildInput(labelBusiness, payment.business);
        const fullname = payment.firstName + " " + payment.lastName;
        const nameInput = this.buildInput(labelFullName, fullname);

        const cityStateZip = this.formatCityStateZip(
            payment.city,
            payment.state,
            payment.postal
        );

        const address = (
            <div>
                {payment.address && <div>{payment.address}</div>}
                {cityStateZip && <div>{cityStateZip}</div>}
            </div>
        );
        const addressInput = this.buildInput(labelAddress, address);
        const phone = (
            <div>
                {payment.phone && (
                    <span>
                        <i
                            className={`flag-icon flag-icon-${payment.phoneCountry.toLowerCase()}`}
                        />
                        &nbsp; {payment.phone}
                    </span>
                )}
            </div>
        );
        const phoneInput = this.buildInput(Resources.LabelPhoneNumber, phone);
        const header = (
            <FormattedMessage
                id="confirm.panel.payment.header"
                defaultMessage="Payment Information"
            />
        );
        return (
            <fieldset>
                <legend>
                    {header}
                    {showEdit && (
                        <div className="pull-right">
                            <a href="#" onClick={this.onClickEdit}>
                                <FontAwesomeIcon icon={faEdit} />
                            </a>
                        </div>
                    )}
                </legend>
                <Row className="margin-bottom-10">
                    <Col xs={12} sm={12} md={12}>
                        {method}
                    </Col>
                </Row>
                <Row>
                    <Col xs={12} sm={12} md={12}>
                        {merchant.businessNameIsVisible && (
                            <div>
                                {businessInput}
                                <br />
                            </div>
                        )}
                    </Col>
                </Row>
                <Row>
                    <Col xs={12} sm={12} md={12}>
                        {nameInput}
                        <br />
                    </Col>
                </Row>
                <Row>
                    <Col xs={12} sm={12} md={12}>
                        {addressInput}
                        <br />
                    </Col>
                </Row>
                <Row>
                    <Col xs={12} sm={12} md={12}>
                        {phoneInput}
                        <br />
                    </Col>
                </Row>
            </fieldset>
        );
    }
}

export default PaymentInformation;
