import React from "react";

import { Col, Row } from "react-bootstrap";

import * as Resources from "../../resources";
import {
    GetCardImageByNumber,
    GetCardImageByType,
    GetCardLast4,
    GetCardName,
} from "../../utils/CardTypes";

class CardPayment extends React.Component<any, any> {
    constructor(props: any) {
        super(props);
    }

    buildInput(label: any, value: any) {
        return (
            <Row>
                <Col xs={5} sm={4} md={4}>
                    <strong>{label}</strong>
                </Col>
                <Col xs={7} sm={8} md={8}>
                    <span className="uneditable-input">{value}</span>
                </Col>
            </Row>
        );
    }

    render() {
        const { payment } = this.props;
        const googlePayCardImage = <i className="pay-method-icon pmtype-googlepay vcenter" />;
        let type;
        if (payment.type)
        {
            if (payment.googlePayToken !== "")
            {
                type = (
                    <span>
                        {googlePayCardImage}&nbsp;{" "}
                        {GetCardImageByType(payment.type)}&nbsp;{" "}
                        <strong>{payment.type}</strong>
                    </span>
                );
            }
            else
            {
                type = (
                    <span>
                        {GetCardImageByType(payment.type)}&nbsp;{" "}
                        <strong>{payment.type}</strong>
                    </span>
                );
            }

        } else {
            type = (
                <span>
                    {GetCardImageByNumber(payment.cardNumber)}&nbsp;{" "}
                    <strong>{GetCardName(payment.paymentMethod)}</strong>
                </span>
            );
        }

        const last4 = GetCardLast4(payment.cardNumber);
        const cardInput = this.buildInput(
            Resources.LabelCardNumber,
            Resources.MessageCardInformation(type, last4)
        );
        return (
            <div>
                {cardInput}
                <br />
            </div>
        );
    }
}

export default CardPayment;
