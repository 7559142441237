import React from "react";
import { connect, MapDispatchToPropsParam } from "react-redux";
import {
    createOrUpdateAccount,
    deleteAccount,
    loadUserAccounts,
    setAccountAsDefault,
} from "../../actions/Account";
import { clearBills, createOrUpdate, deleteBill } from "../../actions/Bill";
import { searchEBPP } from "../../actions/EbppBill";
import { selectAccount } from "../../actions/MyAccount";

import { RouteComponentProps } from "react-router-dom";
import {
    IAccount,
    IApplication,
    IApplicationRoute,
    IApplicationState,
    IBill,
    IMyAccount,
    IPayment,
    IPayor,
    ISettings,
    IUser,
} from "../../types";
import { MyBillsForm } from "./MyBillsForm";

interface AccountsStateProps {
    application: IApplication;
    settings: ISettings;
    payment: IPayment;
    payor: IPayor;
    user: IUser;
    userAccounts: IAccount[];
    myAccount: IMyAccount;
    ebppBills: IBill[];
}

interface AccountsDispatchProps {
    saveAccount: typeof createOrUpdateAccount;
    selectAccount: typeof selectAccount;
    loadUserAccounts: typeof loadUserAccounts;
    deleteAccount: typeof deleteAccount;
    setAccountAsDefault: typeof setAccountAsDefault;
    createOrUpdate: typeof createOrUpdate;
    deleteBill: typeof deleteBill;
    clearBills: typeof clearBills;
    searchEBPP: typeof searchEBPP;
}

export type AccountsProps = AccountsStateProps &
    AccountsDispatchProps &
    RouteComponentProps<IApplicationRoute>;

class MyBills extends React.Component<AccountsProps> {
    constructor(props: any) {
        super(props);
    }
    render() {
        return (
            <div className="margin-top-20">
                <MyBillsForm {...this.props} />
            </div>
        );
    }
}

export default connect(
    (state: IApplicationState) => ({
        application: state.application,
        settings: state.settings,
        payment: state.payment,
        payor: state.payor,
        user: state.user,
        userAccounts: state.userAccounts.accounts,
        billDetails: state.billDetails.customerBillDetails,
        bills: state.bills.bills,
        billTypes: state.billTypes.billTypes,
        ebppBills: state.ebppBills.bills,
        myAccount: state.myAccount,
    }),
    {
        saveAccount: createOrUpdateAccount,
        selectAccount,
        loadUserAccounts,
        deleteAccount,
        setAccountAsDefault,
        clearBills,
        createOrUpdate,
        deleteBill,
        searchEBPP,
    }
)(MyBills);
