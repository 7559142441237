import { ISettings } from "../types";

export const getTemplateId = (methodId: number, settings: ISettings): number => {
    switch (methodId) {
        case 1:
        case 2:
        case 3:
        case 4:
            return settings.creditPaymentTermsTemplateId;
        case 7:
            return settings.achPaymentTermsTemplateId;
        case 9:
        case 11:
        case 12:
        case 13:
        case 14:
            return settings.debitPaymentTermsTemplateId;
        // JCB = 5,
        // GSB = 6,
        // ECheck = 8,
        // Cash = 10,
        // ACHCard = 15
        default:
            return 0;
    }
}

export const getAutopayTemplateId = (methodId: number, settings: ISettings): number => {
    switch (methodId) {
        case 1:
        case 2:
        case 3:
        case 4:
            // return settings.creditPaymentTermsTemplateId;
            return 483;
        case 7:
            // return settings.achPaymentTermsTemplateId;
            return 485;
        case 9:
        case 11:
        case 12:
        case 13:
        case 14:
            // return settings.debitPaymentTermsTemplateId;
            return 484;
        // JCB = 5,
        // GSB = 6,
        // ECheck = 8,
        // Cash = 10,
        // ACHCard = 15
        default:
            return 0;
    }
}