import React from "react";

export interface TableHeaderColumn {
    cssClasses: string;
    name: JSX.Element;
}

interface GroupColumnsTableHeaderProps {
    columns: TableHeaderColumn[];
}

export class GroupColumnsTableHeader extends React.Component<GroupColumnsTableHeaderProps> {
    constructor(props: GroupColumnsTableHeaderProps) {
        super(props);
    }

    render() {
        const { columns } = this.props;

        const headers = columns.map((column, i) => {
            return (
                <th className={column.cssClasses} key={i}>
                    {column.name}
                </th>
            );
        });

        return (
            <thead>
                <tr className="text-th-color text-th-bg-color">{headers}</tr>
            </thead>
        );
    }
}
