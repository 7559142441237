import React, { ReactNode } from "react";
import { matchPath, RouteComponentProps, withRouter } from "react-router-dom";
import "../../css/SanDiegoBillSearch/common.css";
import * as Resources from "../../resources";
import { IApplicationRoute } from "../../types";
import StepperPanel from "./StepperPanel";

interface IComponentState {
    currentSearchBlockName: string;
}

interface LayoutRouteProps extends IApplicationRoute {
    page: string;
}

export interface IBillSearchProps
    extends RouteComponentProps<{ merchantName: string }> {
    children?: ReactNode | ReactNode[];
}

class BillSearch extends React.Component<IBillSearchProps, IComponentState> {
    constructor(props: IBillSearchProps) {
        super(props);

        this.state = {
            currentSearchBlockName: "",
        };
    }

    render() {
        let title: JSX.Element | null = null;
        const currentPage = this.getRouteProps(this.props.location.pathname);
        const currentPageLowerCase = currentPage.page
            ? currentPage.page.toLowerCase()
            : "";
        let article1: JSX.Element | null = null;
        let article2: JSX.Element | null = null;
        switch (currentPageLowerCase) {
            case "search-result":
                title = Resources.BillSearchResultsTitle;
                article1 = Resources.BillSearchResultArticle1;
                break;
            case "bills":
                title = Resources.BillSearchShoppingCartTitle;
                break;
            case "payor":
                title = Resources.BillSearchPayorInformationTitle;
                break;
            case "payment":
                title = Resources.BillSearchPaymentInformationTitle;
                break;
            case "verify":
                title = Resources.BillSearchVerificationTitle;
                break;
            case "receipt":
                title = Resources.ReceiptPageMainTitle;
                article1 = Resources.ReceiptPageTitle;
                break;
            default:
                title = Resources.BillSearchMainTitle;
                article1 = Resources.BillSearchArticle1;
                article2 = Resources.BillSearchArticle2;
                break;
        }
        return (
            <div className="billsearch-wrap-overflow">
                <div id="payment_content">
                    <span className="payment_header">{title}</span>
                    <div className="mb-20px">{article1}</div>
                    <div className="margin-top-20">
                        <div id="search_steps">
                            <StepperPanel position={currentPageLowerCase} />
                            {this.props.children}
                        </div>
                    </div>
                    {article2}
                </div>
            </div>
        );
    }

    private getRouteProps = (path: string): LayoutRouteProps => {
        const pathToMatch = path.includes("CredentialManager")
            ? "/:merchantName?/:page?/:passwordResetToken?/:guid?"
            : "/:merchantName?/:page?/:guid?/:transactionId?";

        const match = matchPath<LayoutRouteProps>(path, {
            path: pathToMatch,
            exact: false,
            strict: false,
        });

        return match ? match.params : ({} as LayoutRouteProps);
    };
}

export default withRouter(BillSearch);
