import React from "react";
import { injectIntl } from "react-intl";
import { Input } from "./Input";

export enum SelectInputPropsEnum {
    id,
    name,
    intl,
    placeholder,
    options,
    onBlur,
    onError,
    onChange,
    readOnly,
}
class SelectInput extends React.Component<any, any> {
    constructor(props: any) {
        super(props);
        const { id, name } = this.props;
        this.state = {
            id: id || name,
        };
        this.handleBlur = this.handleBlur.bind(this);
        this.handleError = this.handleError.bind(this);
        this.handleChange = this.handleChange.bind(this);
    }
    componentDidMount() {
        const { id } = this.state;
        const element: any = document.getElementById(id);
        const selectedIndex = element.selectedIndex;
        if (element && selectedIndex >= 0) {
            const options = element.options;
            const tempIndex = (selectedIndex + 1) % options.length;
            options[tempIndex].selected = true;
            options[selectedIndex].selected = true;
        }
    }
    handleError(name: any, message: any) {
        if (this.props.onError) {
            this.props.onError(name, message);
        }
    }
    handleBlur(event: any) {
        if (this.props.onBlur) {
            this.props.onBlur(event);
        }
    }
    handleChange(event: any) {
        const { onChange, readOnly } = this.props;
        if (!readOnly && onChange) {
            this.props.onChange(event);
        }
    }
    render() {
        const {
            name,
            intl: { formatMessage },
            readOnly,
            placeholder,
            options,
        } = this.props;
        const selectOne = {
            value: "",
            text: placeholder || formatMessage({ id: "option.selectOne" }),
        };
        const selectOptions = !this.props.hideSelectOne
            ? [selectOne, ...options]
            : [...options];
        const props: any = Object.assign({}, this.props);
        Object.keys(this.props).forEach((key) => {
            if (key in SelectInputPropsEnum) {
                delete props[key];
            }
        });
        return (
            <Input
                {...props}
                id={this.state.id}
                name={name}
                options={selectOptions}
                componentClass="select"
                onBlur={this.handleBlur}
                onChange={this.handleChange}
                onError={this.handleError}
                readOnly={readOnly}
            />
        );
    }
}

export default injectIntl(SelectInput);
