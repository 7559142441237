import React from "react";
import {
    Button,
    ButtonToolbar,
    Col,
    Form,
    Modal,
    Panel,
    Row,
} from "react-bootstrap";
import AlertMessage from "../Layout/AlertMessage";
import { ButtonBar } from "../Layout/ButtonBar";
import {
    CancelButton,
    CountryInput,
    EmailInput,
    PhoneInput,
    PostalInput,
    SelectInput,
    StateInput,
    TextInput,
} from "../Input";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { CheckFieldValidity, CheckFormValidity } from "../../utils/Validation";
import { IPayor, ISelectOption, PreferredContactEnum } from "../../types";
import * as Resources from "../../resources";
import { LocationDescriptorObject } from "../../../node_modules/@types/history";
import {
    faCheck,
    faCircleNotch,
    faExclamationTriangle,
} from "@fortawesome/free-solid-svg-icons";
import { IFormError } from "../../types/IFormError";
import AutoPayButton from "../BillDetail/AutoPayButton";

export default class PayorForm extends React.Component<any, any> {
    private confirmInput: any;
    private unblock: any;

    constructor(props: any) {
        super(props);
        this.validate = this.validate.bind(this);
        this.handleSave = this.handleSave.bind(this);
        this.handleBlur = this.handleBlur.bind(this);
        this.handleError = this.handleError.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleAutopayClick = this.handleAutopayClick.bind(this);
        this.handlePhoneCountryChange =
            this.handlePhoneCountryChange.bind(this);
        this.handleMobilePhoneCountryChange =
            this.handleMobilePhoneCountryChange.bind(this);
        this.handleBackButton = this.handleBackButton.bind(this);
        this.handleIsValid = this.handleIsValid.bind(this);
        this.handleSubmitted = this.handleSubmitted.bind(this);
        this.handleSaveProfile = this.handleSaveProfile.bind(this);
        this.onSaveProfileError = this.onSaveProfileError.bind(this);
        this.onSaveProfileSuccess = this.onSaveProfileSuccess.bind(this);
        this.handleCloseModal = this.handleCloseModal.bind(this);
        this.handleSaveModal = this.handleSaveModal.bind(this);
        this.handlePhoneChange = this.handlePhoneChange.bind(this);
        this.handleMobilePhoneChange = this.handleMobilePhoneChange.bind(this);

        let initialPayor = {} as IPayor;
        if (
            props.user.isLoggedIn &&
            props.user.isVerified &&
            props.user.profile &&
            !this.props.initialPayor.saved
        ) {
            initialPayor = Object.assign(
                {},
                props.initialPayor,
                props.user.profile
            );
            initialPayor.confirmEmail = props.user.profile.email;
        } else {
            initialPayor = Object.assign({}, props.initialPayor);
            initialPayor.confirmEmail = props.initialPayor.email;
        }
        initialPayor.mobilePhoneCountry =
            initialPayor.mobilePhoneCountry || "US";
        initialPayor.phoneCountry = initialPayor.phoneCountry || "US";
        initialPayor.country = initialPayor.country || "US";

        this.state = {
            submitting: false,
            dirty: false,
            showModal: false,
            message: {},
            payor: initialPayor,
            errors: {},
        };
    }

    public componentWillMount(): void {
        const { application, settings, bills, myAccount } = this.props;
        if (application.guid && application.expired) {
            const path = `/${settings.merchantName}/expired/${application.guid}`;
            this.props.history.push(path);
            return;
        }
        if (application.guid && application.canceled) {
            const path = `/${settings.merchantName}/canceled/${application.guid}`;
            this.props.history.push(path);
            return;
        }
        if (!myAccount.active && bills.length === 0) {
            const path = application.guid
                ? `/${settings.merchantName}/bills/${application.guid}`
                : `/${settings.merchantName}/bills`;
            this.props.history.push(path);
            return;
        }
    }

    public componentDidMount(): void {
        const {
            myAccount,
            user: { isLoggedIn, isVerified },
        } = this.props;
        this.unblock = this.props.history.block(
            (nextLocation: LocationDescriptorObject) => {
                if (isLoggedIn && isVerified && this.state.dirty) {
                    this.setState({
                        showModal: true,
                        nextLocation: nextLocation,
                    });
                }
                return isLoggedIn && isVerified
                    ? !this.state.dirty || myAccount.active
                    : true;
            }
        );
    }

    public componentWillUnmount(): void {
        const { dirty, payor } = this.state;
        if (!this.props.myAccount.active && dirty) {
            this.props.savePayor(payor);
        }
        if (this.unblock) {
            this.unblock();
        }
    }

    public componentDidUpdate(prevProps: any): void {
        if (
            this.props.user.isLoggedIn &&
            this.props.user.isVerified &&
            !prevProps.user.isLoggedIn &&
            !prevProps.user.isVerified &&
            !this.props.initialPayor.saved
        ) {
            const initialPayor: IPayor = Object.assign(
                {},
                this.props.initialPayor,
                this.props.user.profile
            );
            initialPayor.confirmEmail = this.props.user.profile.email;
            this.setState({ payor: initialPayor });
        }
    }

    private validate(): void {
        const {
            payor: { email, confirmEmail },
        } = this.state;
        if (
            email &&
            confirmEmail &&
            email.toLowerCase() !== confirmEmail.toLowerCase()
        ) {
            this.confirmInput.setCustomValidity("form.error.nomatch");
        } else if (!email && confirmEmail) {
            this.confirmInput.setCustomValidity("form.error.nomatch");
        } else {
            this.confirmInput.setCustomValidity("");
        }
        if (confirmEmail) {
            CheckFieldValidity(this.confirmInput, this.handleError);
        }
    }

    private handleAutopayClick() {
        const { application, settings, user } = this.props;
        if (
            settings.enableMyAccount &&
            application.id === 33 &&
            !(user && user.isLoggedIn && user.isVerified)
        ) {
            this.props.showLoginModal();
        } else {
            const path = application.guid
                ? `/${settings.merchantName}/billdetail/${application.guid}`
                : `/${settings.merchantName}/billdetail`;
            this.props.history.push(path);
            return;
        }
    }

    private handleSubmitted(event: any): void {
        event.preventDefault();
        this.validate();
        if (!CheckFormValidity("payor-form", this.handleFormErrors)) {
            this.setState({ submitting: false });
            return;
        }
        this.setState({ submitting: true });
        this.handleSave();
    }

    private handleSave(): void {
        this.validate();
        if (!CheckFormValidity("payor-form", this.handleFormErrors)) {
            this.setState({ submitting: false });
            return;
        }
        const { application, settings } = this.props;
        const payor = { ...this.state.payor };
        if (this.props.myAccount.active) {
            this.handleSaveProfile(payor);
        } else {
            this.props.savePayor(payor);
            this.setState({
                dirty: false,
                payor,
            });

            const path = application.guid
                ? `/${settings.merchantName}/payment/${application.guid}`
                : `/${settings.merchantName}/payment`;
            this.props.history.push(path);
        }
    }

    private handleSaveProfile(payor: IPayor): void {
        const {
            application,
            settings: { merchantName },
            user,
            updateProfile,
        } = this.props;
        updateProfile(
            application,
            merchantName,
            user.authToken,
            payor,
            this.onSaveProfileError,
            this.onSaveProfileSuccess
        );
    }

    private onSaveProfileSuccess(email: string): void {
        if (this.state.showModal) {
            this.handleCloseModalNavigate();
        } else {
            this.setState({
                message: {
                    text: Resources.MessageSaveProfile,
                    isSuccess: true,
                },
                submitting: false,
                dirty: false,
            });
        }
    }

    private onSaveProfileError(text: string): void {
        this.setState({
            message: { text: text, isSuccess: false },
            submitting: false,
            showModal: false,
            dirty: false,
        });
    }

    private handleCloseModal(): void {
        this.setState({ showModal: false });
    }

    private handleCloseModalNavigate = (): void => {
        this.setState({ dirty: false }, () => {
            this.props.history.push(this.state.nextLocation);
            this.handleCloseModal();
        });
    };

    private handleSaveModal(): void {
        const { payor } = this.state;
        this.handleSaveProfile(payor);
    }

    private handleBlur(event: any): void {
        this.validate();
        CheckFieldValidity(event.target, this.handleError);
    }

    private handleError(name: string, message: string): void {
        this.setState({
            errors: {
                ...this.state.errors,
                [name]: message,
            },
        });
    }

    private handleFormErrors = (errors: IFormError): void => {
        this.setState({
            errors: {
                ...this.state.errors,
                ...errors,
            },
        });
    };

    private handleIsValid(isValid: boolean): void {
        const { submitting } = this.state;
        if (submitting && isValid) {
            this.handleSave();
        }
        this.setState({
            submitting: false,
        });
    }

    private handleChange(event: any): void {
        const { settings } = this.props;
        const field = event.target.name;
        const payor = { ...this.state.payor };
        if (
            field === "country" &&
            payor.country === "US" &&
            event.target.value !== "US"
        ) {
            payor.state = "";
        }
        if (field === "preferredContactMethod") {
            payor[field] = PreferredContactEnum[event.target.value];
        } else {
            if (field === "business") {
                if (
                    (document.activeElement &&
                        document.activeElement.id === "businessNameId") ||
                    settings.businessNameAutofillEnabled
                ) {
                    payor[field] = event.target.value;
                }
            } else {
                payor[field] = event.target.value;
            }
        }
        if (!event.target.validity.valid) {
            CheckFieldValidity(event.target, this.handleError);
        }
        if (event.target.id === "email") {
            payor.confirmEmail = "";
        }
        let isDirty = true;
        const {
            user: { profile },
        } = this.props;
        if (!this.state.dirty && profile) {
            isDirty = event.target.value === profile[field] ? false : true;
        }
        this.setState({ dirty: isDirty, payor });
    }

    private handleMobilePhoneCountryChange(country: string): void {
        this.setState({
            dirty: true,
            payor: {
                ...this.state.payor,
                mobilePhoneCountry: country,
            },
        });
    }

    private handleMobilePhoneChange(phone: string): void {
        this.setState({
            dirty: true,
            payor: {
                ...this.state.payor,
                mobilePhone: phone,
            },
        });
    }

    private handlePhoneCountryChange(country: string): void {
        this.setState({
            dirty: true,
            payor: {
                ...this.state.payor,
                phoneCountry: country,
            },
        });
    }

    private handlePhoneChange(phone: string): void {
        this.setState({
            dirty: true,
            payor: {
                ...this.state.payor,
                phone,
            },
        });
    }

    private handleBackButton(event: any): void {
        event.preventDefault();
        const { application, settings } = this.props;
        if (this.props.myAccount.active) {
            this.props.history.goBack();
        } else {
            const path = application.guid
                ? `/${settings.merchantName}/bills/${application.guid}`
                : `/${settings.merchantName}/bills`;
            this.props.history.push(path);
        }
    }

    public render(): JSX.Element {
        const { settings, myAccount } = this.props;
        const { payor, errors, submitting, message, showModal } = this.state;
        const bsStyle =
            message === undefined
                ? ""
                : message.isSuccess
                ? "success"
                : "danger";
        const icon =
            message !== undefined && message.isSuccess
                ? faCheck
                : faExclamationTriangle;
        const showAlert =
            message !== undefined &&
            message.text &&
            (myAccount.active || !message.isSuccess);
        const saveButton = submitting ? (
            <FontAwesomeIcon icon={faCircleNotch} spin />
        ) : myAccount.active ? (
            Resources.ButtonSave
        ) : (
            Resources.ButtonContinue
        );
        const cancelButton = !this.props.myAccount.active ? (
            <CancelButton
                disabled={this.props.bills.length === 0}
                application={this.props.application}
                settings={this.props.settings}
                history={this.props.history}
                cancel={this.props.cancel}
            />
        ) : null;
        const actionButton =
            (this.props.application.id === 34 ||
                this.props.application.id === 35) &&
            settings.skipBillsPage ? null : (
                <Button
                    bsClass="btn btn-default"
                    onClick={this.handleBackButton}
                    style={{ minWidth: "6.5em" }}
                >
                    {Resources.ButtonBack}
                </Button>
            );
        const continueButton = (
            <Button
                bsClass="btn btn-primary"
                disabled={submitting}
                onClick={this.handleSubmitted}
                style={{ minWidth: "6.5em" }}
            >
                {saveButton}
            </Button>
        );
        const autoPayButton = (
            <AutoPayButton
                user={this.props.user}
                bills={this.props.bills}
                billTypes={this.props.billTypes}
                billDetails={this.props.billDetails}
                settings={this.props.settings}
                submitting={this.state.submitting}
                onClick={this.handleAutopayClick}
            />
        );
        const stateProvidence =
            payor.country === "US" ? (
                <StateInput
                    label={Resources.LabelState}
                    name="state"
                    value={payor.state}
                    error={errors.state}
                    onBlur={this.handleBlur}
                    onChange={this.handleChange}
                    onError={this.handleError}
                    required={settings.stateIsRequired}
                    readOnly={!payor.stateIsEditable}
                />
            ) : (
                <TextInput
                    label={Resources.LabelStateProvince}
                    name="state"
                    value={payor.state}
                    error={errors.state}
                    onBlur={this.handleBlur}
                    onChange={this.handleChange}
                    onError={this.handleError}
                    required={settings.stateIsRequired}
                    readOnly={!payor.stateIsEditable}
                />
            );
        const inputRef = (input: any) => {
            this.confirmInput = input;
        };
        const header = myAccount.active
            ? Resources.ManageProfile
            : Resources.HeaderPayorInformation;
        const options: ISelectOption[] = [];
        const preferredContactMethod =
            PreferredContactEnum[payor.preferredContactMethod];
        Object.keys(PreferredContactEnum)
            .filter((key) => isNaN(Number(key)))
            .map((key) => {
                options.push({ value: key, text: key } as ISelectOption);
            });

        return (
            <div>
                <div>
                    <Modal show={showModal} onHide={this.handleCloseModal}>
                        <Modal.Header closeButton>
                            <Modal.Title>
                                {Resources.HeaderSaveProfile}
                            </Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            {Resources.MessageSaveProfileDialog(payor.email)}
                        </Modal.Body>
                        <Modal.Footer>
                            <div className="pull-right">
                                <ButtonToolbar>
                                    <Button
                                        onClick={this.handleCloseModalNavigate}
                                    >
                                        {Resources.ButtonNo}
                                    </Button>
                                    <Button
                                        bsStyle="primary"
                                        onClick={this.handleSaveModal}
                                        style={{ minWidth: "6.5em" }}
                                    >
                                        {Resources.ButtonYes}
                                    </Button>
                                </ButtonToolbar>
                            </div>
                        </Modal.Footer>
                    </Modal>
                </div>
                <Form id="payor-form">
                    <Panel bsStyle="primary">
                        <Panel.Heading>{header}</Panel.Heading>
                        <Panel.Body>
                            {showAlert && (
                                <AlertMessage
                                    bsStyle={bsStyle}
                                    showIcon
                                    icon={icon}
                                    message={message}
                                />
                            )}
                            <Row>
                                <Col xs={12}>
                                    <div className="well">
                                        <fieldset>
                                            <legend>
                                                {
                                                    Resources.HeaderPersonalInformation
                                                }
                                            </legend>
                                            {!myAccount.active &&
                                                settings.businessNameIsVisible && (
                                                    <Row>
                                                        <Col xs={12}>
                                                            <TextInput
                                                                label={
                                                                    Resources.LabelBusinessName
                                                                }
                                                                id={
                                                                    "businessNameId"
                                                                }
                                                                name="business"
                                                                value={
                                                                    payor.business
                                                                }
                                                                error={
                                                                    errors.business
                                                                }
                                                                onBlur={
                                                                    this
                                                                        .handleBlur
                                                                }
                                                                onChange={
                                                                    this
                                                                        .handleChange
                                                                }
                                                                onError={
                                                                    this
                                                                        .handleError
                                                                }
                                                                required={
                                                                    settings.businessNameIsRequired
                                                                }
                                                                readOnly={
                                                                    !payor.businessIsEditable
                                                                }
                                                                autocomplete={
                                                                    settings.businessNameAutofillEnabled
                                                                }
                                                            />
                                                        </Col>
                                                    </Row>
                                                )}
                                            <Row>
                                                <Col
                                                    xs={12}
                                                    sm={4}
                                                    md={4}
                                                    lg={5}
                                                >
                                                    <TextInput
                                                        label={
                                                            Resources.LabelFirstName
                                                        }
                                                        name="firstName"
                                                        value={payor.firstName}
                                                        error={errors.firstName}
                                                        onBlur={this.handleBlur}
                                                        onChange={
                                                            this.handleChange
                                                        }
                                                        onError={
                                                            this.handleError
                                                        }
                                                        required={
                                                            myAccount.active ||
                                                            settings.firstNameIsRequired
                                                        }
                                                        readOnly={
                                                            !payor.firstNameIsEditable
                                                        }
                                                    />
                                                </Col>
                                                <Col
                                                    xs={12}
                                                    sm={4}
                                                    md={4}
                                                    lg={2}
                                                >
                                                    <TextInput
                                                        label={
                                                            Resources.LabelMiddleName
                                                        }
                                                        name="middleName"
                                                        value={payor.middleName}
                                                        error={
                                                            errors.middleName
                                                        }
                                                        onBlur={this.handleBlur}
                                                        onChange={
                                                            this.handleChange
                                                        }
                                                        onError={
                                                            this.handleError
                                                        }
                                                        required={
                                                            settings.middleNameIsRequired
                                                        }
                                                        readOnly={
                                                            !payor.middleNameIsEditable
                                                        }
                                                    />
                                                </Col>
                                                <Col
                                                    xs={12}
                                                    sm={4}
                                                    md={4}
                                                    lg={5}
                                                >
                                                    <TextInput
                                                        label={
                                                            Resources.LabelLastName
                                                        }
                                                        name="lastName"
                                                        value={payor.lastName}
                                                        error={errors.lastName}
                                                        onBlur={this.handleBlur}
                                                        onChange={
                                                            this.handleChange
                                                        }
                                                        onError={
                                                            this.handleError
                                                        }
                                                        required={
                                                            myAccount.active ||
                                                            settings.lastNameIsRequired
                                                        }
                                                        readOnly={
                                                            !payor.lastNameIsEditable
                                                        }
                                                    />
                                                </Col>
                                            </Row>
                                        </fieldset>
                                        <fieldset>
                                            <legend>
                                                {
                                                    Resources.HeaderAddressInformation
                                                }
                                            </legend>
                                            <Row>
                                                <Col xs={12} sm={12} md={12}>
                                                    <TextInput
                                                        label={
                                                            Resources.LabelAddress
                                                        }
                                                        name="address"
                                                        value={payor.address}
                                                        error={errors.address}
                                                        onBlur={this.handleBlur}
                                                        onChange={
                                                            this.handleChange
                                                        }
                                                        onError={
                                                            this.handleError
                                                        }
                                                        required={
                                                            settings.addressIsRequired
                                                        }
                                                        readOnly={
                                                            !payor.addressIsEditable
                                                        }
                                                    />
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col xs={12} sm={6} md={6}>
                                                    <TextInput
                                                        label={
                                                            Resources.LabelCity
                                                        }
                                                        name="city"
                                                        value={payor.city}
                                                        error={errors.city}
                                                        onBlur={this.handleBlur}
                                                        onChange={
                                                            this.handleChange
                                                        }
                                                        onError={
                                                            this.handleError
                                                        }
                                                        required={
                                                            settings.cityIsRequired
                                                        }
                                                        readOnly={
                                                            !payor.cityIsEditable
                                                        }
                                                    />
                                                </Col>
                                                <Col xs={12} sm={6} md={6}>
                                                    {stateProvidence}
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col xs={12} sm={6} md={6}>
                                                    <PostalInput
                                                        label={
                                                            Resources.LabelPostal
                                                        }
                                                        name="postal"
                                                        country={payor.country}
                                                        value={payor.postal}
                                                        error={errors.postal}
                                                        onBlur={this.handleBlur}
                                                        onChange={
                                                            this.handleChange
                                                        }
                                                        onError={
                                                            this.handleError
                                                        }
                                                        required={
                                                            !myAccount.active &&
                                                            settings.postalIsRequired
                                                        }
                                                        readOnly={
                                                            !payor.postalIsEditable
                                                        }
                                                    />
                                                </Col>
                                                <Col xs={12} sm={6} md={6}>
                                                    <CountryInput
                                                        label={
                                                            Resources.LabelCountry
                                                        }
                                                        name="country"
                                                        value={payor.country}
                                                        error={errors.country}
                                                        onBlur={this.handleBlur}
                                                        onChange={
                                                            this.handleChange
                                                        }
                                                        onError={
                                                            this.handleError
                                                        }
                                                        required={
                                                            settings.countryIsRequired
                                                        }
                                                        readOnly={
                                                            !payor.countryIsEditable
                                                        }
                                                    />
                                                </Col>
                                            </Row>
                                        </fieldset>
                                        <fieldset>
                                            <legend>
                                                {
                                                    Resources.HeaderContactInformation
                                                }
                                            </legend>
                                            <Row>
                                                <Col xs={12} sm={6} md={6}>
                                                    <EmailInput
                                                        label={
                                                            Resources.LabelEmail
                                                        }
                                                        name="email"
                                                        value={payor.email}
                                                        error={errors.email}
                                                        onBlur={this.handleBlur}
                                                        onChange={
                                                            this.handleChange
                                                        }
                                                        onError={
                                                            this.handleError
                                                        }
                                                        required={
                                                            myAccount.active ||
                                                            settings.emailIsRequired
                                                        }
                                                        readOnly={
                                                            !payor.emailIsEditable
                                                        }
                                                    />
                                                </Col>
                                                <Col xs={12} sm={6} md={6}>
                                                    <TextInput
                                                        type="email"
                                                        label={
                                                            Resources.LabelConfirmEmail
                                                        }
                                                        name="confirmEmail"
                                                        value={
                                                            payor.confirmEmail
                                                        }
                                                        error={
                                                            errors.confirmEmail
                                                        }
                                                        onBlur={this.handleBlur}
                                                        onChange={
                                                            this.handleChange
                                                        }
                                                        onError={
                                                            this.handleError
                                                        }
                                                        required={
                                                            payor.email
                                                                ? true
                                                                : false
                                                        }
                                                        inputRef={inputRef}
                                                        readOnly={
                                                            !payor.emailIsEditable
                                                        }
                                                    />
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col xs={12} sm={6} md={6}>
                                                    <PhoneInput
                                                        label={
                                                            Resources.LabelPhoneNumber
                                                        }
                                                        name="phone"
                                                        countryValue={
                                                            payor.phoneCountry
                                                        }
                                                        value={payor.phone}
                                                        error={errors.phone}
                                                        onBlur={this.handleBlur}
                                                        onChange={
                                                            this
                                                                .handlePhoneChange
                                                        }
                                                        onError={
                                                            this.handleError
                                                        }
                                                        onCountryChange={
                                                            this
                                                                .handlePhoneCountryChange
                                                        }
                                                        required={
                                                            settings.phoneIsRequired
                                                        }
                                                        readOnly={
                                                            !payor.phoneIsEditable
                                                        }
                                                        isValid={
                                                            this.handleIsValid
                                                        }
                                                    />
                                                </Col>
                                                <Col xs={12} sm={6} md={6}>
                                                    <PhoneInput
                                                        label={
                                                            Resources.LabelMobilePhoneNumber
                                                        }
                                                        name="mobilePhone"
                                                        countryValue={
                                                            payor.mobilePhoneCountry
                                                        }
                                                        value={
                                                            payor.mobilePhone
                                                        }
                                                        error={
                                                            errors.mobilePhone
                                                        }
                                                        onBlur={this.handleBlur}
                                                        onChange={
                                                            this
                                                                .handleMobilePhoneChange
                                                        }
                                                        onError={
                                                            this.handleError
                                                        }
                                                        onCountryChange={
                                                            this
                                                                .handleMobilePhoneCountryChange
                                                        }
                                                        required={
                                                            payor.preferredContactMethod ===
                                                            2
                                                        }
                                                        readOnly={
                                                            !payor.mobilePhoneIsEditable
                                                        }
                                                        isValid={
                                                            this.handleIsValid
                                                        }
                                                    />
                                                </Col>
                                            </Row>
                                            {settings.enableTextNotifications &&
                                                myAccount.active && (
                                                    <Row>
                                                        <Col
                                                            xs={12}
                                                            sm={6}
                                                            md={6}
                                                        >
                                                            <SelectInput
                                                                label={
                                                                    Resources.LabelPreferredContactMethod
                                                                }
                                                                name="preferredContactMethod"
                                                                value={
                                                                    preferredContactMethod
                                                                }
                                                                error={
                                                                    errors.accountType
                                                                }
                                                                onChange={
                                                                    this
                                                                        .handleChange
                                                                }
                                                                onError={
                                                                    this.props
                                                                        .onError
                                                                }
                                                                onBlur={
                                                                    this.props
                                                                        .onBlur
                                                                }
                                                                hideSelectOne={
                                                                    true
                                                                }
                                                                options={
                                                                    options
                                                                }
                                                            />
                                                        </Col>
                                                    </Row>
                                                )}
                                        </fieldset>
                                    </div>
                                    <ButtonBar
                                        cancelButton={cancelButton}
                                        leftActionButton={actionButton}
                                        middleActionButton={autoPayButton}
                                        rightActionButton={continueButton}
                                    />
                                </Col>
                            </Row>
                        </Panel.Body>
                    </Panel>
                </Form>
            </div>
        );
    }
}
