import { Reducer } from "redux";
import { IMyAccount } from "../types";
import { LogoutUserAction, USER_LOGOUT } from "../actionTypes/User";
import { MYACCOUNT_SET, MYACCOUNT_SELECT } from "../actionTypes/MyAccount";
import {
  SetMyAccountAction,
  SelectMyAccountAction,
  MyAccountActions,
} from "../actionTypes/MyAccount";

export const initialState: IMyAccount = {
  selected: undefined,
  active: false,
};

const myAccountSetAction = (
  state: IMyAccount,
  action: SetMyAccountAction
): IMyAccount => {
  return {
    ...state,
    active: action.data,
  };
};

const myAccountSelectAction = (
  state: IMyAccount,
  action: SelectMyAccountAction
): IMyAccount => {
  return {
    ...state,
    selected: action.data,
  };
};

const myAccountLogoutAction = (
  state: IMyAccount,
  action: LogoutUserAction
): IMyAccount => {
  return initialState;
};

export const reducer: Reducer<IMyAccount> = (
  state: IMyAccount = initialState,
  action: MyAccountActions
): IMyAccount => {
  switch (action.type) {
    case MYACCOUNT_SET:
      return myAccountSetAction(state, action);
    case MYACCOUNT_SELECT:
      return myAccountSelectAction(state, action);
    case USER_LOGOUT:
      return myAccountLogoutAction(state, action);
    default:
      return state;
  }
};
