import { findIndex } from "lodash";
import { IBill, IBillRecord } from "../../../../../types";
import { BillInstallmentType, ICommonBill } from "../../../types";

export default class BillUtils {
    static getBills(billRecords: IBillRecord[]): ICommonBill[] {
        const bills = [] as ICommonBill[];

        billRecords.forEach((b) => {
            const billIsNotInArray =
                findIndex(bills, function (mb) {
                    return (
                        mb.parcelNumber === b.id1 &&
                        mb.owner ===
                            `${b.obligorFirstName} ${b.obligorLastName}` &&
                        mb.billType == b.billType
                    );
                }) === -1;

            if (billIsNotInArray) {
                const isDefaultedOrUnsecured =
                    b.billType === "Defaulted" || b.billType === "Unsecured"
                        ? true
                        : false;

                const firstPaymentTypeBill: IBillRecord = billRecords.find(
                    function (b1) {
                        return (
                            b1.id1 === b.id1 &&
                            b1.obligorFirstName === b.obligorFirstName &&
                            b1.obligorLastName === b.obligorLastName &&
                            b1.billType === b.billType &&
                            (isDefaultedOrUnsecured
                                ? b1.id2 === BillInstallmentType.balance
                                : b1.id3 === BillInstallmentType.first)
                        );
                    }
                )!;

                const secondPaymentTypeBill: IBillRecord = billRecords.find(
                    function (b2) {
                        return (
                            b2.id1 === b.id1 &&
                            b2.obligorFirstName === b.obligorFirstName &&
                            b2.obligorLastName === b.obligorLastName &&
                            b2.billType === b.billType &&
                            (isDefaultedOrUnsecured
                                ? b2.id2 === BillInstallmentType.installment
                                : b2.id3 === "Second")
                        );
                    }
                )!;

                const bill: ICommonBill = {
                    parcelNumber: b.id1,
                    owner: b.obligorFirstName + " " + b.obligorLastName,
                    type: isDefaultedOrUnsecured
                        ? "common"
                        : "withInstallments",
                    billType: b.billType,
                    firstPaymentType: {
                        id: firstPaymentTypeBill.id,
                        amount: firstPaymentTypeBill.amount,
                        amountDue: firstPaymentTypeBill.amountDue,
                        dueDate: new Date(firstPaymentTypeBill.dueDate),
                        isPayable: firstPaymentTypeBill.isPayable,
                        status: firstPaymentTypeBill.id4,
                        paymentType: isDefaultedOrUnsecured
                            ? (firstPaymentTypeBill.id2 as BillInstallmentType)
                            : (firstPaymentTypeBill.id3 as BillInstallmentType),
                    },
                    secondPaymentType: null,
                    totalDue: b.amountDue,
                };

                if (secondPaymentTypeBill) {
                    bill.secondPaymentType = {
                        id: secondPaymentTypeBill.id,
                        amount: secondPaymentTypeBill.amount,
                        dueDate: new Date(secondPaymentTypeBill.dueDate),
                        status: secondPaymentTypeBill.id4,
                        amountDue: secondPaymentTypeBill.amountDue,
                        isPayable: secondPaymentTypeBill.isPayable,
                        paymentType: isDefaultedOrUnsecured
                            ? (secondPaymentTypeBill.id2 as BillInstallmentType)
                            : (secondPaymentTypeBill.id3 as BillInstallmentType),
                    };
                    bill.totalDue =
                        bill.totalDue + secondPaymentTypeBill.amountDue;
                }

                bills.push(bill);
            }
        });

        return bills;
    }

    static getBillFromCommonBill(
        bill: ICommonBill,
        billType: string,
        installmentType: BillInstallmentType
    ): IBill {
        const amount =
            installmentType === BillInstallmentType.first ||
            installmentType === BillInstallmentType.balance
                ? bill.firstPaymentType.amount
                : bill.secondPaymentType!.amount;

        const amountDue =
            installmentType === BillInstallmentType.first ||
            installmentType === BillInstallmentType.balance
                ? bill.firstPaymentType.amountDue
                : bill.secondPaymentType!.amountDue;

        const dueDate =
            installmentType === BillInstallmentType.first ||
            installmentType === BillInstallmentType.balance
                ? bill.firstPaymentType.dueDate
                : bill.secondPaymentType!.dueDate;

        const status =
            installmentType === BillInstallmentType.first ||
            installmentType === BillInstallmentType.balance
                ? bill.firstPaymentType.status
                : bill.secondPaymentType!.status;

        return {
            id: "",
            billId: 0,
            billType: billType,
            id1: bill.parcelNumber,
            id2: bill.owner,
            id3: installmentType,
            id4: status,
            amount: amount,
            amountDue: amountDue,
            dueDate: dueDate.toJSON(),
            customAmount: null,
            error: "",
            id1Label: null,
            id2Label: null,
            id3Label: null,
            id4Label: null,
            hasImage: false,
            imageContent: null,
            detectedMIMEType: null,
            purchaseOrder: null,
            isTaxExempt: null,
            taxAmount: null,
        };
    }
}
