import React from "react";
import { Button, Glyphicon, Modal } from "react-bootstrap";
import { IModalProps } from "../HeaderSanDiego";
import * as Resources from "../../../../resources";

export default class ModalContact extends React.Component<IModalProps> {
    constructor(props: IModalProps) {
        super(props);
    }

    render() {
        return (
            <Modal
                onHide={this.props.hideModal}
                show={this.props.showComponent}
            >
                <Modal.Header>
                    <Button onClick={this.props.hideModal} className="close">
                        <Glyphicon
                            glyph="remove"
                            style={{ fontSize: "16px" }}
                        />
                    </Button>
                    <Modal.Title>
                        {Resources.HeaderContactInformation}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <h4>San Diego County Administration Center</h4>
                    <h4>1600 Pacific Highway - Room 162</h4>
                    <h4>San Diego, CA 92101</h4>
                    <br />
                    <h4>Questions: 877.829.4732</h4>
                    <h4>Pay by Phone: 855.829.3773</h4>
                </Modal.Body>
                <Modal.Footer>
                    <Button bsStyle="danger" onClick={this.props.hideModal}>
                        {Resources.ButtonClose}
                    </Button>
                </Modal.Footer>
            </Modal>
        );
    }
}
