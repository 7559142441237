import React from "react";
import { connect } from "react-redux";
import { RouteComponentProps } from "react-router-dom";
import { loadUserAccounts } from "../../actions/Account";
import { setIsPaymentCompleted } from "../../actions/Application";
import {
    createOrUpdate as saveBill,
    deleteBill,
    loadBillImage,
} from "../../actions/Bill";
import { loadCustomerBillDetails } from "../../actions/BillDetails";
import { loadImage } from "../../actions/BillType";
import { cancel, clear } from "../../actions/Common";
import { logout, updateUser, userVerified } from "../../actions/User";
import { login } from "../../api/User";
import {
    IApplication,
    IApplicationRoute,
    IApplicationState,
    IBill,
    IBillType,
    IInternationalization,
    ISettings,
    IUser,
} from "../../types";
import { totalBillAmount, totalBillAmountEBPP } from "../../utils/BollettaMath";
import { LoginModal } from "../Layout/Header/LoginModal";
import { MultipleBill } from "./MultipleBill";
import SingleBill from "./SingleBill";

interface BillStateProps {
    bills: IBill[];
    ebppBills: IBill[];
    intl: IInternationalization;
    application: IApplication;
    settings: ISettings;
    billTypes: IBillType[];
    user: IUser;
    billTotal: number;
    clear: VoidFunction;
    setIsPaymentCompleted: (isPaymentCompleted: boolean) => void;
}

interface BillDispatchProps {
    cancel: VoidFunction;
    loadUserAccounts: (
        application: IApplication,
        merchantName: string,
        authToken: string,
        onError?: (text: string) => void
    ) => void;
    loadCustomerBillDetails: (
        merchantName: string,
        authToken: string,
        merchantCustomerId: string
    ) => void;
    logout: VoidFunction;
    updateUser: (user: IUser) => void;
    userVerified: (value: boolean) => void;
    saveBill: (bill: IBill) => void;
    deleteBill: (billId: string) => void;
    loadImage: (
        application: IApplication,
        merchantName: string,
        billName: string,
        language: number
    ) => void;
    loadBillImage: (
        merchantName: string,
        billId: string,
        application: IApplication
    ) => void;
}

export type BillProps = BillStateProps &
    BillDispatchProps &
    RouteComponentProps<IApplicationRoute>;

export interface IBillState {
    showLoginModal: boolean;
}

export class Bill extends React.Component<BillProps, IBillState> {
    constructor(props: BillProps) {
        super(props);

        this.state = {
            showLoginModal: false,
        };
    }

    public componentDidMount(): void {
      const { setIsPaymentCompleted, clear, application: { isPaymentCompleted } } = this.props;
      if (isPaymentCompleted) {
        clear();
        setIsPaymentCompleted(false);
      }
      //Only skip bill page when flow is secure pay.
        if (
            (this.props.application.id === 34 ||
                this.props.application.id === 35 ||
                this.props.application.isEBPP) &&
            this.props.settings.skipBillsPage &&
            this.props.bills.length > 0
        ) {
            let url = `/${this.props.match.params.merchantName}/`;
            url += this.props.settings.skipPayorPage ? "payment" : "payor";

            if (this.props.match.params.guid) {
                url = url + `/${this.props.match.params.guid}`;
            }

            this.props.history.push(url);
        }

        if (this.props.application.isEBPP && this.props.ebppBills) {
            const url = `/${this.props.match.params.merchantName}/myaccount/mybills/${this.props.match.params.guid}`;
            this.props.history.push(url);
        }
    }

    private onLogin = (
        userName: string,
        password: string,
        recaptcha?: string | null
    ): Promise<IUser> => {
        return login(
            this.props.application,
            this.props.settings.merchantName,
            userName,
            password,
            recaptcha
        );
    };

    private renderBill = (props: BillProps): JSX.Element => {
        const billFormProps = {
            bills: props.bills,
            billTotal: props.billTotal,
            settings: props.settings,
            intl: props.intl,
            application: props.application,
            billTypes: props.billTypes,
            user: props.user,
            history: props.history,
            params: props.match.params,
            saveBill: props.saveBill,
            cancel: props.cancel,
            loadImage: props.loadImage,
            showLoginModal: this.showLoginModal,
            onContinue: this.onContinue,
        };

        return !props.settings.allowMultipleBillPayments &&
            props.settings.allowBlindPayments &&
            props.application.id === 33 ? (
            <SingleBill {...billFormProps} />
        ) : (
            <MultipleBill
                {...billFormProps}
                loadBillImage={props.loadBillImage}
                deleteBill={props.deleteBill}
            />
        );
    };

    public render(): JSX.Element {
        const requirePasswordReset =
            this.props.application.isEBPP &&
            this.props.settings.enableMyAccount &&
            this.props.bills.length > 0 &&
            this.props.user.requirePasswordReset;

        return (
            <div className="margin-top-20">
                {this.renderBill(this.props)}
                {this.props.settings.enableMyAccount && (
                    <LoginModal
                        application={this.props.application}
                        merchantName={this.props.settings.merchantName}
                        allowAutoPay={this.props.settings.allowAutoBillPay}
                        requireRecaptcha={this.props.settings.requireReCaptcha}
                        requireLoginEBPP={this.props.application.isEBPP}
                        requirePasswordReset={requirePasswordReset}
                        locale={this.props.intl.locale}
                        show={this.state.showLoginModal}
                        allowGuestLogin={true}
                        user={this.props.user}
                        onClose={this.hideLoginModal}
                        onContinue={this.onContinue}
                        updateUser={this.props.updateUser}
                        userVerified={this.props.userVerified}
                        login={this.onLogin}
                        logout={this.props.logout}
                        loadUserAccounts={this.props.loadUserAccounts}
                        loadCustomerBillDetails={
                            this.props.loadCustomerBillDetails
                        }
                        webPaymentsCustomerAccountLoginMessage={
                            this.props.settings
                                .webPaymentsCustomerAccountLoginMessage
                        }
                    />
                )}
            </div>
        );
    }

    private showLoginModal = (): void => {
        this.setState({ showLoginModal: true });
    };

    private hideLoginModal = (): void => {
        this.setState({ showLoginModal: false });
    };

    private onContinue = (): void => {
        const {
            application: { guid },
            settings: { merchantName, skipPayorPage },
        } = this.props;
        const step = skipPayorPage ? "payment" : "payor";
        const path = guid
            ? `/${merchantName}/${step}/${guid}`
            : `/${merchantName}/${step}`;

        this.props.history.push(path);
    };
}

export default connect(
    (state: IApplicationState) =>
        ({
            intl: state.intl,
            application: state.application,
            settings: state.settings,
            bills: state.bills.bills,
            ebppBills: state.ebppBills.bills,
            billTypes: state.billTypes.billTypes,
            user: state.user,
            billTotal: state.application.isEBPP
                ? totalBillAmountEBPP(state.bills.bills)
                : totalBillAmount(state.bills.bills),
        } as BillStateProps),
    {
        cancel,
        saveBill,
        deleteBill,
        updateUser,
        userVerified,
        loadUserAccounts,
        loadCustomerBillDetails,
        loadImage,
        loadBillImage,
      logout,
      clear,
      setIsPaymentCompleted
    }
)(Bill);
