import { CANCEL, CLEAR, CommonActions } from "../actionTypes/Common";
import { IAppThunkAction } from "../types";

export const clear = (): IAppThunkAction<CommonActions> => (dispatch) => {
  dispatch({
    type: CLEAR,
  });
};

export const cancel = (): IAppThunkAction<CommonActions> => (dispatch) => {
  dispatch({
    type: CANCEL,
  });
};
