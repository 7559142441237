import { HeaderLogo } from "./HeaderLogo";

export interface MerchantLogoProps {
    friendlyName: string;
    desktopLogo: string | null;
    desktopLogoOnly: boolean;
    mobileLogo: string | null;
    mobileLogoOnly: boolean;
    linkUrl: string | null;
    desktopCustomName: string | null;
    mobileCustomName: string | null;
}

const MerchantLogo = (props: MerchantLogoProps) => {
    const longname = props.desktopCustomName || props.friendlyName;
    const desktopName =
        props.desktopLogo && props.desktopLogoOnly ? "" : longname;
    const shortname = props.mobileCustomName || props.friendlyName;
    const mobileName =
        props.mobileLogo && props.mobileLogoOnly ? "" : shortname;

    return (
        <div className="inline pull-left">
            <span className="visible-sm visible-md visible-lg">
                <HeaderLogo
                    imageUrl={props.desktopLogo}
                    linkUrl={props.linkUrl}
                    name={desktopName}
                />
            </span>
            <span className="visible-xs">
                <HeaderLogo
                    imageUrl={props.mobileLogo}
                    linkUrl={props.linkUrl}
                    name={mobileName}
                />
            </span>
        </div>
    );
};

export default MerchantLogo;
