import React from "react";
import { Route } from "react-router-dom";
import { default as Layout } from "./components/Layout";
import App from "./components/App/App";
import Bill from "./components/Bill/Bill";
import Search from "./components/Search/Search";
import Payor from "./components/Payor/Payor";
import Payment from "./components/Payment/Payment";
import Verify from "./components/Verify/Verify";
import BillDetail from "./components/BillDetail/BillDetail";
import ManageProfile from "./components/MyAccount/ManageProfile";
import ManageAccounts from "./components/MyAccount/ManageAccounts";
import MyBills from "./components/MyAccount/MyBills";
import PaymentHistory from "./components/MyAccount/PaymentHistory";
import ReturnToPayment from "./components/MyAccount/ReturnToPayment";
import Receipt from "./components/Receipt/Receipt";
import Canceled from "./components/Canceled/Canceled";
import SessionExpired from "./components/SessionExpired/SessionExpired";
import PaymentScheduled from "./components/Payment/PaymentScheduled";
import Token from "./components/Token/Token";
import InvalidRequest from "./components/InvalidRequest/InvalidRequest";
import CredentialManager from "./components/CredentialManager/CredentialManager";
import Login from "./components/Login/Login";
import SearchByOptions from "./components/SanDiegoBillSearch/SearchByOptions";
import SearchResult from "./components/SanDiegoBillSearch/BillSearchResult/SearchResult";
import Cart from "./components/SanDiegoBillSearch/ShoppingCart/Cart";

export const defaultRoutes = [
    <Route exact path="/" render={(props) => <InvalidRequest {...props} />} />,
    <Route
        exact
        path="/:merchantName"
        render={(props) => <App {...props} />}
    />,
    <Route
        exact
        path="/:merchantName/uid/:guid?"
        render={(props) => <App {...props} />}
    />,
    <Route
        exact
        path="/:merchantName/guid/:guid?"
        render={(props) => <App {...props} />}
    />,
    <Route
        path="/:merchantName/login/:guid?"
        render={(props) => <Login {...props} />}
    />,
    <Route
        path="/:merchantName/bills/:guid?"
        render={(props) => <Bill {...props} />}
    />,
    <Route
        path="/:merchantName/search/:guid?"
        render={(props) => <Search {...props} />}
    />,
    <Route
        path="/:merchantName/payor/:guid?"
        render={(props) => <Payor {...props} />}
    />,
    <Route
        path="/:merchantName/payment/:guid?"
        render={(props) => <Payment {...props} />}
    />,
    <Route
        path="/:merchantName/verify/:guid?"
        render={(props) => <Verify {...props} />}
    />,
    <Route
        path="/:merchantName/billdetail/:guid?"
        render={(props) => <BillDetail {...props} />}
    />,
    <Route
        path="/:merchantName/billdetail/:guid?/edit?"
        render={(props) => <BillDetail {...props} />}
    />,
    <Route
        path="/:merchantName/receipt/:guid?/:transactionId?"
        render={(props) => <Receipt {...props} />}
    />,
    <Route
        path="/:merchantName/token/:guid"
        render={(props) => <Token {...props} />}
    />,
    <Route
        path="/:merchantName/canceled/:guid?"
        render={(props) => <Canceled {...props} />}
    />,
    <Route
        path="/:merchantName/expired/:guid?"
        render={(props) => <SessionExpired {...props} />}
    />,
    <Route
        path="/:merchantName/paymentscheduled/:guid?"
        render={(props) => <PaymentScheduled {...props} />}
    />,
    <Route
        path="/:merchantName/credentialmanager/:passwordResetToken/:guid?"
        render={(props) => <CredentialManager {...props} />}
    />,
    <Route
        path="/:merchantName/myaccount/profile/:guid?"
        render={(props) => <ManageProfile {...props} />}
    />,
    <Route
        path="/:merchantName/myaccount/accounts/:guid?"
        render={(props) => <ManageAccounts {...props} />}
    />,
    <Route
        path="/:merchantName/myaccount/accounts/:guid?/add?"
        render={(props) => <ManageAccounts {...props} />}
    />,
    <Route
        path="/:merchantName/myaccount/accounts/:guid?/edit?"
        render={(props) => <ManageAccounts {...props} />}
    />,
    <Route
        path="/:merchantName/myaccount/mybills/:guid?"
        render={(props) => <MyBills {...props} />}
    />,
    <Route
        path="/:merchantName/myaccount/billdetail/:guid?"
        render={(props) => <BillDetail {...props} />}
    />,
    <Route
        path="/:merchantName/myaccount/billdetail/:guid?/edit?"
        render={(props) => <BillDetail {...props} />}
    />,
    <Route
        path="/:merchantName/myaccount/transactions/:guid?"
        render={(props) => <PaymentHistory {...props} />}
    />,
    <Route
        path="/:merchantName/myaccount/returntopayment/:guid?"
        render={(props) => <ReturnToPayment {...props} />}
    />,
    <Route
        path="/:merchantName/InvalidRequest/:guid?"
        render={(props) => <InvalidRequest {...props} />}
    />,
];

export const routesSDInternal = [
    // SanDiego Titles
    <Route exact path="/" render={(props) => <InvalidRequest {...props} />} />,
    <Route
        exact
        path="/:merchantName"
        render={(props) => <App {...props} />}
    />,
    <Route
        exact
        path="/:merchantName/uid/:guid?"
        render={(props) => <App {...props} />}
    />,
    <Route
        exact
        path="/:merchantName/guid/:guid?"
        render={(props) => <App {...props} />}
    />,
    <Route
        path="/:merchantName/login/:guid?"
        render={(props) => <Login {...props} />}
    />,
    <Route
        path="/:merchantName/payor/:guid?"
        render={(props) => <Payor {...props} />}
    />,
    <Route
        path="/:merchantName/payment/:guid?"
        render={(props) => <Payment {...props} />}
    />,
    <Route
        path="/:merchantName/verify/:guid?"
        render={(props) => <Verify {...props} />}
    />,
    <Route
        path="/:merchantName/billdetail/:guid?"
        render={(props) => <BillDetail {...props} />}
    />,
    <Route
        path="/:merchantName/search"
        render={(props) => <SearchByOptions {...props} />}
    />,
    <Route
        path="/:merchantName/bills"
        render={(props) => <Cart {...props} />}
    />,
    <Route
        path="/:merchantName/search-result"
        render={(props) => <SearchResult {...props} />}
    />,

    <Route
        path="/:merchantName/receipt/:guid?/:transactionId?"
        render={(props) => <Receipt {...props} />}
    />,
    //

    <Route
        path="/:merchantName/billdetail/:guid?/edit?"
        render={(props) => <BillDetail {...props} />}
    />,
    <Route
        path="/:merchantName/token/:guid"
        render={(props) => <Token {...props} />}
    />,
    <Route
        path="/:merchantName/canceled/:guid?"
        render={(props) => <Canceled {...props} />}
    />,
    <Route
        path="/:merchantName/expired/:guid?"
        render={(props) => <SessionExpired {...props} />}
    />,
    <Route
        path="/:merchantName/paymentscheduled/:guid?"
        render={(props) => <PaymentScheduled {...props} />}
    />,
    <Route
        path="/:merchantName/credentialmanager/:passwordResetToken/:guid?"
        render={(props) => <CredentialManager {...props} />}
    />,
    <Route
        path="/:merchantName/myaccount/profile/:guid?"
        render={(props) => <ManageProfile {...props} />}
    />,
    <Route
        path="/:merchantName/myaccount/accounts/:guid?"
        render={(props) => <ManageAccounts {...props} />}
    />,
    <Route
        path="/:merchantName/myaccount/accounts/:guid?/add?"
        render={(props) => <ManageAccounts {...props} />}
    />,
    <Route
        path="/:merchantName/myaccount/accounts/:guid?/edit?"
        render={(props) => <ManageAccounts {...props} />}
    />,
    <Route
        path="/:merchantName/myaccount/mybills/:guid?"
        render={(props) => <MyBills {...props} />}
    />,
    <Route
        path="/:merchantName/myaccount/billdetail/:guid?"
        render={(props) => <BillDetail {...props} />}
    />,
    <Route
        path="/:merchantName/myaccount/billdetail/:guid?/edit?"
        render={(props) => <BillDetail {...props} />}
    />,
    <Route
        path="/:merchantName/myaccount/transactions/:guid?"
        render={(props) => <PaymentHistory {...props} />}
    />,
    <Route
        path="/:merchantName/myaccount/returntopayment/:guid?"
        render={(props) => <ReturnToPayment {...props} />}
    />,
    <Route
        path="/:merchantName/InvalidRequest/:guid?"
        render={(props) => <InvalidRequest {...props} />}
    />,
];

/* export const routes = (
    <Layout>
        {defaultRoutes.map((component, index) => (
            <span key={index}>{component}</span>
        ))}
    </Layout>
); */
