import React from "react";
import { HeaderInvalidRequest, MessageInvalidRequest } from "../../resources";

export default class InvalidRequest extends React.Component {
    render() {
        return (
            <div>
                <h2 className="page-header">{HeaderInvalidRequest}</h2>
                <p>{MessageInvalidRequest}</p>
            </div>
        );
    }
}
