import { loadBillTypes, loadBillTypeImage } from "../api/BillTypes";

import {
  BILLTYPES_LOAD,
  BILLTYPES_LOAD_SUCCESS,
  BILLTYPES_LOAD_FAIL,
  BILLTYPEIMAGE_LOAD,
  BILLTYPEIMAGE_LOAD_SUCCESS,
  BILLTYPEIMAGE_LOAD_FAIL,
  BillTypeActions,
} from "../actionTypes/BillType";
import { IApplication, IAppThunkAction } from "../types";

export const loadApplicationBillTypes =
  (
    merchantName: string,
    intlId: number, 
    application: IApplication
  ): IAppThunkAction<BillTypeActions> =>
  (dispatch) => {
    loadBillTypes(merchantName, intlId, application)
      .then((billTypes) =>
        dispatch({ type: BILLTYPES_LOAD_SUCCESS, data: billTypes })
      )
      .catch((error) => dispatch({ type: BILLTYPES_LOAD_FAIL, data: error }));

    dispatch({ type: BILLTYPES_LOAD });
  };

export const loadImage =
  (
    application: IApplication,
    merchantName: string,
    billName: string,
    language: number
  ): IAppThunkAction<BillTypeActions> =>
  (dispatch) => {
    loadBillTypeImage(application, merchantName, billName, language)
      .then((image) =>
        dispatch({ type: BILLTYPEIMAGE_LOAD_SUCCESS, billName, data: image })
      )
      .catch((error) =>
        dispatch({ type: BILLTYPEIMAGE_LOAD_FAIL, billName, data: error })
      );

    dispatch({ type: BILLTYPEIMAGE_LOAD });
  };
