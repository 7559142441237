import { IInitializationModel } from "../types/IInitializationModel";

/* export function loadInit(merchantName: string, guid?: string): Promise<number> {
  var options: RequestInit = {
    headers: { HTTP_X_FORWARDED_FOR: "my browser" },
  };
  return fetch(
    `/webapi/api/Init?merchantName=${merchantName}` +
      (guid ? `&guid=${guid}` : ``),
    options
  )
    .then((response) => response.text() as Promise<number>)
    .catch((error) => error);
} */

export function loadInitData(
    merchantName: string,
    guid?: string
): Promise<IInitializationModel> {
    const baseUrl = process.env.REACT_APP_API_BASEURL;
    const options: RequestInit = {
        mode: baseUrl ? "cors" : "same-origin",
    };
    return fetch(
        `${baseUrl}/webapi/api/Init?merchantName=${merchantName}` +
            (guid ? `&guid=${guid}` : ``),
        options
    )
        .then((response) => response.json() as Promise<IInitializationModel>)
        .catch((error) => error);
}
