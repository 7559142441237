import { getPayor } from "../api/Payor";
import {
  PAYOR_LOAD,
  PAYOR_LOAD_SUCCESS,
  PAYOR_LOAD_FAIL,
  PAYOR_UPDATE,
  PayorActions,
} from "../actionTypes/Payor";
import { IAppThunkAction, IPayor } from "../types";

export const loadPayor =
  (merchantName: string, guid?: string): IAppThunkAction<PayorActions> =>
  (dispatch) => {
    getPayor(merchantName, guid)
      .then((payor) => dispatch({ type: PAYOR_LOAD_SUCCESS, data: payor }))
      .catch((error) => dispatch({ type: PAYOR_LOAD_FAIL, data: error }));

    dispatch({ type: PAYOR_LOAD });
  };

export const updatePayor =
  (payor: IPayor): IAppThunkAction<PayorActions> =>
  (dispatch) => {
    dispatch({ type: PAYOR_UPDATE, data: payor });
  };
