import React from "react";
import { FormattedNumber } from "react-intl";
import { AddBillToCardButton, PaymentsDisabledMessage } from "../../../..";
import {
    BillSearchResultBtnAddCart,
    BillSearchResultBtnAddCartMobile,
    BillSearchResultBtnInCart,
} from "../../../../../../resources";
import { IBill, IBillType } from "../../../../../../types";
import {
    BillInstallmentType,
    ICommonBill,
    SanDiegoBillType,
} from "../../../../types";
import BillUtils from "../../Tables/Mapper";

interface SecondRowOfDefaultedUnsecuredBillProps {
    trKey: string;
    billType: IBillType | undefined;
    billTypeName: SanDiegoBillType;
    row: ICommonBill;
    addToCart: (row: IBill) => void;
    isFirstRowAddedToCart: boolean;
    isSecondRowAddedToCart: boolean;
}

export class SecondRowOfDefaultedUnsecuredBill extends React.Component<SecondRowOfDefaultedUnsecuredBillProps> {
    constructor(props: SecondRowOfDefaultedUnsecuredBillProps) {
        super(props);
    }

    handleAddToCart = () => {
        const { row, billTypeName, addToCart } = this.props;
        const bill = BillUtils.getBillFromCommonBill(
            row,
            billTypeName,
            BillInstallmentType.installment
        );
        addToCart(bill);
    };

    render() {
        const {
            trKey,
            row,
            billType,
            isFirstRowAddedToCart,
            isSecondRowAddedToCart,
        } = this.props;
        const firstRowOfDefaultedUnsecuredBillPaidOrAddedToCart =
            !row.firstPaymentType.isPayable || isFirstRowAddedToCart;
        const renderButton =
            row.secondPaymentType!.isPayable &&
            !firstRowOfDefaultedUnsecuredBillPaidOrAddedToCart;
        const showPaymentsDisabledMessage =
            !row.secondPaymentType!.isPayable &&
            billType != undefined &&
            billType.paymentsDisabled === true;
        const buttonLabel = isSecondRowAddedToCart
            ? BillSearchResultBtnInCart
            : BillSearchResultBtnAddCart;
        const buttonLabelMobile = isSecondRowAddedToCart
            ? BillSearchResultBtnInCart
            : BillSearchResultBtnAddCartMobile;

        return (
            <tr className="sr-btm-rw-l" key={trKey}>
                <td className="gridRowText status">
                    {row.secondPaymentType!.status}
                </td>
                <td className="gridRowText visible-xs">
                    {renderButton && (
                        <AddBillToCardButton
                            label={buttonLabelMobile}
                            disabled={isSecondRowAddedToCart}
                            handler={this.handleAddToCart}
                        />
                    )}
                    {showPaymentsDisabledMessage && (
                        <PaymentsDisabledMessage
                            message={billType!.paymentsDisabledMessage}
                        />
                    )}
                </td>
                <td className="gridRowText hidden-xs">
                    {row.secondPaymentType!.paymentType}
                </td>
                <td className="gridRowNumeric">
                    <FormattedNumber
                        value={row.secondPaymentType!.amountDue}
                        style="currency"
                        currency="USD"
                    />
                </td>
                <td className="gridRowText visible-xs">
                    {row.secondPaymentType!.paymentType}
                </td>
                <td className="gridRowText action-cell hidden-xs">
                    {renderButton && (
                        <AddBillToCardButton
                            label={buttonLabel}
                            disabled={isSecondRowAddedToCart}
                            handler={this.handleAddToCart}
                        />
                    )}
                    {showPaymentsDisabledMessage && (
                        <PaymentsDisabledMessage
                            message={billType!.paymentsDisabledMessage}
                        />
                    )}
                </td>
            </tr>
        );
    }
}
