import React from "react";
import {
    BillSearchCartHeaderPNumber,
    BillSearchCartHeaderType,
    BillSearchCartHeaderPaymentType,
    BillSearchCartHeaderAmount,
    BillSearchCartFooterTotal,
} from "../../../../resources/index";
import "../../../../css/SanDiegoBillSearch/shoppingCart.css";
import { FormattedNumber } from "react-intl";
import { Table } from "react-bootstrap";

interface IOwnProps {
    totalAmount: number;
    children: any;
}

export const CartTable = (props: IOwnProps) => {
    return (
        <Table className="table cart-table" cellSpacing="0">
            <tbody>
                <tr className="cart-table-header">
                    <th className="gridRowText" scope="col">
                        {BillSearchCartHeaderPNumber}
                    </th>
                    <th className="gridRowText" scope="col">
                        {BillSearchCartHeaderType}
                    </th>
                    <th className="gridRowText" scope="col">
                        {BillSearchCartHeaderPaymentType}
                    </th>
                    <th className="gridRowNumeric" scope="col">
                        {BillSearchCartHeaderAmount}
                    </th>
                    <th className="gridRowText" scope="col">
                        &nbsp;
                    </th>
                </tr>
                {props.children}
                <tr className="cart-table-footer">
                    <td>&nbsp;</td>
                    <td>&nbsp;</td>
                    <td className="cart-total">{BillSearchCartFooterTotal}</td>
                    <td className="cart-total">
                        <FormattedNumber
                            value={props.totalAmount}
                            style="currency"
                            currency="USD"
                        />
                    </td>
                    <td>&nbsp;</td>
                </tr>
            </tbody>
        </Table>
    );
};

export interface CartTableRowProps {
    billId: string;
    amount: number;
    paymentType: string;
    billType: string;
    billNumber: string;
    deleteBill: (billId: string) => void;
}
