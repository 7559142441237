import { faShoppingCart } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { Button } from "react-bootstrap";

interface AddBillToCardButtonProps {
    label: JSX.Element;
    disabled: boolean;
    handler: () => void;
}

export class AddBillToCardButton extends React.Component<AddBillToCardButtonProps> {
    constructor(props: AddBillToCardButtonProps) {
        super(props);
    }
    render() {
        const { label, disabled, handler } = this.props;
        return (
            <Button
                onClick={handler}
                bsClass={
                    disabled
                        ? "btn btn-info btn-xs btn-adapt btn-width-130 disabled"
                        : "btn btn-info btn-xs btn-adapt btn-width-130"
                }
                disabled={disabled}
            >
                <FontAwesomeIcon icon={faShoppingCart} className="fa-icon" />
                <span aria-hidden="true" className="inline-block align-top">
                    {label}
                </span>
            </Button>
        );
    }
}
