import { getTerms } from "../api/Terms";
import {
  TERMSOFUSE_LOAD,
  TERMSOFUSE_LOAD_FAIL,
  TERMSOFUSE_LOAD_SUCCESS,
  TermsActions,
} from "../actionTypes/Terms";
import { IAppThunkAction } from "../types";

export const loadTermsOfUse =
  (languageId: number): IAppThunkAction<TermsActions> =>
  (dispatch) => {
    getTerms(languageId)
      .then((terms) => dispatch({ type: TERMSOFUSE_LOAD_SUCCESS, data: terms }))
      .catch((error) => dispatch({ type: TERMSOFUSE_LOAD_FAIL, data: error }));

    dispatch({ type: TERMSOFUSE_LOAD });
  };
