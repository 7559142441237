import { PAYMENT_UPDATE, PaymentActions } from "../actionTypes/Payment";
import { IAppThunkAction, IPayment } from "../types";

export const createOrUpdate =
  (payment: IPayment): IAppThunkAction<PaymentActions> =>
  (dispatch) => {
    dispatch({
      type: PAYMENT_UPDATE,
      data: payment,
    });
  };
