import React from "react";
import { Checkbox, Col, Row } from "react-bootstrap";
import { FormattedMessage } from "react-intl";

class PaymentTerms extends React.Component<any, any> {
    constructor(props: any) {
        super(props);

        this.handleChange = this.handleChange.bind(this);
        this.createMarkup = this.createMarkup.bind(this);
    }
    handleChange(event: any) {
        const { updateAccepted } = this.props;
        updateAccepted(event.target.checked);
    }
    createMarkup() {
        const { paymentTerms } = this.props;
        return { __html: paymentTerms.terms };
    }
    render() {
        const { intl, application, paymentTerms } = this.props;
        const labelTermsAgree = (
            <FormattedMessage
                id="payment.terms.agree"
                defaultMessage="I agree to the Terms and Conditions"
            />
        );
        return (
            <div>
                {application.id !== 35 && (
                    <form>
                        <Row>
                            <Col xs={12}>
                                <div
                                    key={intl.locale}
                                    dangerouslySetInnerHTML={this.createMarkup()}
                                    style={{
                                        border: "1px solid #e5e5e5",
                                        maxHeight: "200px",
                                        overflow: "auto",
                                        padding: "10px",
                                    }}
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={12}>
                                <Checkbox
                                    checked={paymentTerms.accepted}
                                    name="termsAccepted"
                                    onChange={this.handleChange}
                                    required
                                >
                                    {labelTermsAgree}
                                </Checkbox>
                            </Col>
                        </Row>
                    </form>
                )}
                <Row>
                    <Col xs={12}>
                        <div>&nbsp; </div>
                    </Col>
                </Row>
            </div>
        );
    }
}

export default PaymentTerms;
