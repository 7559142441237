import React from "react";
import * as Browser from "../../utils/Browser";
export class BackgroundImage extends React.Component<any, any> {
    public static defaultProps = {
        height: "500px",
    };

    private img: any;
    constructor(props: any) {
        super(props);
        this.state = {
            scale: 1,
            xOffset: 0,
            yOffset: 0,
            zoomed: false,
        };
        this.handleMouseMove = this.handleMouseMove.bind(this);
        this.handleClick = this.handleClick.bind(this);
    }
    handleMouseMove(event: any) {
        function getOffset(el: any) {
            let x = 0;
            let y = 0;
            while (el && !isNaN(el.offsetLeft) && !isNaN(el.offsetTop)) {
                x += el.offsetLeft - el.scrollLeft;
                y += el.offsetTop - el.scrollTop;
                el = el.offsetParent;
            }
            return { x, y };
        }
        const offset = getOffset(this.img);
        const x = ((event.pageX - offset.x) / this.img.offsetWidth) * 100;
        const y = ((event.pageY - offset.y) / this.img.offsetHeight) * 100;
        this.setState({
            xOffset: x,
            yOffset: y,
        });
    }

    handleClick(event: any) {
        this.setState({
            zoomed: !this.state.zoomed,
        });
    }
    render() {
        const { imageContent, scale, height } = this.props;
        const { zoomed, xOffset, yOffset } = this.state;
        let cursor;
        switch (Browser.GetType()) {
            case Browser.IE:
                cursor = zoomed
                    ? "url('https://images-na.ssl-images-amazon.com/images/G/01/detail-page/cursors/zoom-out._CB184888790_.bmp'), auto"
                    : "url('https://images-na.ssl-images-amazon.com/images/G/01/detail-page/cursors/zoom-in._CB184888790_.bmp'), auto";
                break;
            default:
                cursor = zoomed ? "zoom-out" : "zoom-in";
                break;
        }
        const style = {
            width: "100%",
            cursor: cursor,
            height: height,
            backgroundPosition: "center",
            backgroundRepeat: "no-repeat",
            backgroundSize: "contain",
            backgroundImage: `url(data:image/jpeg;base64,${imageContent})`,
            transition: "transform .5s ease-out",
            transform: `scale(${zoomed ? scale : 1})`,
            transformOrigin: `${xOffset}% ${yOffset}% `,
        };
        return (
            <div
                style={{
                    position: "relative",
                    overflow: "hidden",
                }}
                onMouseMove={this.handleMouseMove}
                onClick={this.handleClick}
                ref={(ref) => (this.img = ref)}
            >
                <div className="photo" style={style}></div>
            </div>
        );
    }
}
