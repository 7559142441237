import React from "react";
import { Row, Col, Form, Modal, Button, ButtonToolbar } from "react-bootstrap";
import { SelectInput, BackgroundImage } from "../Input";
import * as Resources from "../../resources";
import { BillForm } from "./BillForm";
import { SampleBill } from "./SampleBill";
import { ModalTitleSampleBill } from "../../resources";
import { CheckFormValidity, CheckFieldValidity } from "../../utils/Validation";
import {
    ConfigureBillFromBillType,
    GetBillOptions,
} from "../../utils/BillHelpers";
import { getLanguageCode } from "../../utils/Intl";
import { IApplication, IBill, IBillType, ISelectOption } from "../../types";
import { IFormError } from "../../types/IFormError";

export interface BillModalProps {
    application: IApplication;
    locale: string;
    billInstructionsPrompt: string | null;
    merchantName: string;
    billTypes: IBillType[];
    maxAmount: number;
    showModal: boolean;
    close: () => void;
    saveBill: (bill: IBill) => void;
    loadImage: (
        application: IApplication,
        merchantName: string,
        billType: string,
        localeId: number
    ) => void;
}

export interface BillModalState {
    bill: IBill;
    selectedBillType: IBillType | null;
    options: ISelectOption[];
    isSaveButtonDisabled: boolean;
    errors: IFormError;
}

export class BillModal extends React.PureComponent<
    BillModalProps,
    BillModalState
> {
    private formId = "modal-bill-form";

    constructor(props: BillModalProps) {
        super(props);

        const { billTypes } = props;
        const initialBill = ConfigureBillFromBillType(billTypes);

        this.state = {
            bill: Object.assign({}, initialBill),
            selectedBillType:
                props.billTypes.length === 1 ? props.billTypes[0] : null,
            options: GetBillOptions(billTypes),
            isSaveButtonDisabled: !initialBill.billType,
            errors: {},
        };

        this.onSave = this.onSave.bind(this);
        this.onBlur = this.onBlur.bind(this);
        this.onError = this.onError.bind(this);
        this.onChange = this.onChange.bind(this);
        this.onBillTypeChange = this.onBillTypeChange.bind(this);
        this.closeDialog = this.closeDialog.bind(this);
    }

    public componentDidMount() {
        if (this.props.billTypes.length === 1) {
            this.props.loadImage(
                this.props.application,
                this.props.merchantName,
                this.props.billTypes[0].name,
                getLanguageCode(this.props.locale)
            );
        }
    }

    private onBillTypeChange(e: React.ChangeEvent<HTMLSelectElement>) {
        const { billTypes } = this.props;
        const bill = Object.assign(
            {},
            ConfigureBillFromBillType(billTypes, e.target.value),
            { id: this.state.bill.id }
        );
        const billType =
            billTypes &&
            billTypes.find(
                (b: IBillType) => b.id.toString() === e.target.value
            );

        if (billType && !billType.imageContent) {
            this.props.loadImage(
                this.props.application,
                this.props.merchantName,
                e.target.value,
                getLanguageCode(this.props.locale)
            );
        }

        this.setState({
            bill,
            selectedBillType: billType || null,
            isSaveButtonDisabled: false,
            errors: {},
        });
    }

    private onSave(event: React.MouseEvent<Button>) {
        event.preventDefault();

        if (!CheckFormValidity(this.formId, this.handleFormErrors)) {
            return false;
        }

        this.props.saveBill(this.state.bill);
        this.closeDialog();

        return true;
    }

    private closeDialog() {
        this.setState({
            bill:
                this.props.billTypes.length === 1
                    ? Object.assign(
                          {},
                          ConfigureBillFromBillType(this.props.billTypes)
                      )
                    : ({} as IBill),
            selectedBillType:
                this.props.billTypes.length === 1
                    ? this.props.billTypes[0]
                    : null,
            isSaveButtonDisabled: this.props.billTypes.length > 1,
            errors: {},
        });
        this.props.close();
    }

    private onBlur(
        event: React.FocusEvent<HTMLInputElement | HTMLSelectElement>
    ) {
        CheckFieldValidity(event.target, this.onError);
    }

    private handleFormErrors = (errors: IFormError): void => {
        this.setState({
            errors: {
                ...this.state.errors,
                ...errors,
            },
        });
    };

    private onError(name: string, message: string) {
        const updatedState = { [name]: message };

        return this.setState({
            errors: { ...this.state.errors, ...updatedState },
        });
    }

    private onChange(
        event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
    ) {
        const field: string = event.target.name;
        const updatedState = { [field]: event.target.value || null };

        if (field === "amount") {
            updatedState.customAmount = null;
        }
        
        if (event.target.validity.valid) {
            CheckFieldValidity(event.target, this.onError);
        }

        return this.setState({ bill: { ...this.state.bill, ...updatedState } });
    }

    public render(): JSX.Element {
        const { bill, options, errors, selectedBillType } = this.state;
        const { maxAmount, showModal } = this.props;
        const headerText = bill.billType
            ? this.props.billInstructionsPrompt
                ? this.props.billInstructionsPrompt
                : Resources.HeaderEnterBillInformation
            : Resources.HeaderSelectBillInformation;
        const modalSize =
            selectedBillType && selectedBillType.imageContent
                ? "large"
                : undefined;
        const colSize =
            selectedBillType && selectedBillType.imageContent ? 6 : 12;

        return (
            <span>
                <Modal
                    bsSize={modalSize}
                    show={showModal}
                    onHide={this.closeDialog}
                >
                    <Modal.Header closeButton>
                        <Modal.Title>{headerText}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Row>
                            <Col xs={12} sm={colSize} md={colSize} lg={colSize}>
                                <Form id={this.formId}>
                                    {options.length > 1 && (
                                        <SelectInput
                                            label={Resources.LabelBillType}
                                            name="billtype"
                                            value={
                                                selectedBillType
                                                    ? selectedBillType.id
                                                    : 0
                                            }
                                            options={options}
                                            onChange={this.onBillTypeChange}
                                        />
                                    )}
                                    {selectedBillType && (
                                        <div className="well">
                                            <fieldset>
                                                <legend>
                                                    {selectedBillType &&
                                                        selectedBillType.displayName}
                                                    {selectedBillType &&
                                                        selectedBillType.imageContent && (
                                                            <div className="pull-right hidden-sm hidden-md hidden-lg">
                                                                <SampleBill
                                                                    title={
                                                                        ModalTitleSampleBill
                                                                    }
                                                                    imageContent={
                                                                        selectedBillType.imageContent
                                                                    }
                                                                />
                                                            </div>
                                                        )}
                                                </legend>
                                                {selectedBillType && (
                                                    <div className="row">
                                                        <div className="col-xs-12">
                                                            <div
                                                                dangerouslySetInnerHTML={{
                                                                    __html: selectedBillType.description,
                                                                }}
                                                            />
                                                        </div>
                                                    </div>
                                                )}
                                                <BillForm
                                                    bill={bill}
                                                    billType={selectedBillType}
                                                    maxAmount={maxAmount}
                                                    errors={errors}
                                                    onBlur={this.onBlur}
                                                    onChange={this.onChange}
                                                    onError={this.onError}
                                                />
                                            </fieldset>
                                        </div>
                                    )}
                                </Form>
                            </Col>
                            {selectedBillType &&
                                selectedBillType.imageContent && (
                                    <Col xsHidden sm={6} md={6} lg={6}>
                                        <BackgroundImage
                                            scale={3}
                                            imageContent={
                                                selectedBillType.imageContent
                                            }
                                        />
                                    </Col>
                                )}
                        </Row>
                    </Modal.Body>
                    <Modal.Footer>
                        <div className="pull-right">
                            <ButtonToolbar>
                                <Button onClick={this.closeDialog}>
                                    {Resources.ButtonClose}
                                </Button>
                                <Button
                                    bsStyle="primary"
                                    onClick={this.onSave}
                                    disabled={this.state.isSaveButtonDisabled}
                                    style={{ minWidth: "6.5em" }}
                                >
                                    {Resources.ButtonSave}
                                </Button>
                            </ButtonToolbar>
                        </div>
                    </Modal.Footer>
                </Modal>
            </span>
        );
    }
}
