import { IApplicationMaintenance } from "../types/IApplicationMaintenanceState";
export const APPLICATION_MAINTENANCE_LOAD = "redux/applicationMaintenance/LOAD";
export const APPLICATION_MAINTENANCE_LOAD_SUCCESS = "redux/applicationMaintenance/LOAD_SUCCESS";
export const APPLICATION_MAINTENANCE_LOAD_FAIL = "redux/applicationMaintenance/LOAD_FAIL";


export type ApplicationMaintenanceLoadAction = {
    type: typeof APPLICATION_MAINTENANCE_LOAD;
}

export type ApplicationMaintenanceLoadSuccessAction = {
    type: typeof APPLICATION_MAINTENANCE_LOAD_SUCCESS;
    data: IApplicationMaintenance;
}

export type ApplicationMaintenanceLoadFailedAction = {
    type: typeof APPLICATION_MAINTENANCE_LOAD_FAIL;
    data: string;
}

export type ApplicationMaintenanceActions = ApplicationMaintenanceLoadAction | ApplicationMaintenanceLoadSuccessAction |
    ApplicationMaintenanceLoadFailedAction 