import { IBillRecord, IBillSearchResults } from "../types";
import { ClearAction } from "./Common";
export const BILLSEARCH_LOAD = "redux/billSearch/LOAD";
export const BILLSEARCH_LOAD_SUCCESS = "redux/billSearch/LOAD_SUCCESS";
export const BILLSEARCH_LOAD_FAIL = "redux/billSearch/LOAD_FAIL";
export const BILLSEARCH_UPDATE_RECORD = "redux/billSearch/UPDATE_RECORD";
export const BILLSEARCH_UPDATE_CRITERIA = "redux/billSearch/UPDATE_CRITERIA";
export const BILLSEARCH_RESET = "redux/billSearch/RESET";
export const BILLSEARCH_NEW_SESSION = "redux/billSearch/NEW_SESSION";

export type RequestBillSearchAction = {
    type: typeof BILLSEARCH_LOAD;
};

export type ReceiveBillSearchAction = {
    type: typeof BILLSEARCH_LOAD_SUCCESS;
    data: IBillSearchResults;
};

export type FailedBillSearchAction = {
    type: typeof BILLSEARCH_LOAD_FAIL;
    data: string;
};

export type UpdateBillSearchAction = {
    type: typeof BILLSEARCH_UPDATE_RECORD;
    data: IBillRecord;
};

export type ResetBillSearchAction = {
    type: typeof BILLSEARCH_RESET;
    data: any;
};

export type UpdateCriteriaAction = {
    type: typeof BILLSEARCH_UPDATE_CRITERIA;
    data: any;
};

export type NewBillSearchSessionAction = {
    type: typeof BILLSEARCH_NEW_SESSION;
};

export type BillSearchActions =
    | RequestBillSearchAction
    | ReceiveBillSearchAction
    | FailedBillSearchAction
    | UpdateBillSearchAction
    | ResetBillSearchAction
    | UpdateCriteriaAction
    | ClearAction
    | NewBillSearchSessionAction;
