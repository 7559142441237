import React from "react";
import { connect } from "react-redux";
import { Modal, Button } from "react-bootstrap";
import { loadTermsOfUse } from "../../../actions/Terms";
import { getLanguageCode } from "../../../utils/Intl";
import {
    LinkTermsOfUse,
    ModalTitleTermsOfUse,
    ButtonClose,
} from "../../../resources";

class TermsOfUse extends React.Component<any, any> {
    constructor(props: any) {
        super(props);
        this.state = {
            showModal: false,
        };
        this.close = this.close.bind(this);
        this.open = this.open.bind(this);
    }

    close() {
        this.setState({ showModal: false });
    }

    open(event: any) {
        event.preventDefault();
        const { loadTermsOfUse, intl } = this.props;
        loadTermsOfUse(getLanguageCode(intl.locale));
        this.setState({ showModal: true });
    }

    createMarkup() {
        const { termsOfUse } = this.props;

        return { __html: termsOfUse };
    }

    render() {
        return (
            <span>
                <Button bsStyle="link" onClick={this.open}>
                    {LinkTermsOfUse}
                </Button>
                <Modal show={this.state.showModal} onHide={this.close}>
                    <Modal.Header closeButton>
                        <Modal.Title>{ModalTitleTermsOfUse}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div dangerouslySetInnerHTML={this.createMarkup()} />
                    </Modal.Body>
                    <Modal.Footer>
                        <Button onClick={this.close}>{ButtonClose}</Button>
                    </Modal.Footer>
                </Modal>
            </span>
        );
    }
}

const mapStateToProps = (state: any) => ({
    intl: state.intl,
    termsOfUse: state.termsOfUse,
});

const mapDispatchToProps = {
    loadTermsOfUse,
};

export default connect(mapStateToProps, mapDispatchToProps)(TermsOfUse);
