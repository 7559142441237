import React from "react";

interface PaymentsDisabledMessageProps {
    message: string;
}

export class PaymentsDisabledMessage extends React.Component<PaymentsDisabledMessageProps> {
    constructor(props: PaymentsDisabledMessageProps) {
        super(props);
    }
    render() {
        const { message } = this.props;
        return <div className="payments-disabled-message">{message}</div>;
    }
}
