import React from "react";
import * as Resources from "../../resources";
import { Panel, PanelGroup } from "react-bootstrap";
import { InjectedIntlProps, injectIntl } from "react-intl";
import {
    SearchByBillNumberBlock,
    SearchByMailingAddressBlock,
    SearchByUnsecuredBillNumberBlock,
} from ".";
import { BillSearchOption, IBillSearchResults } from "../../types";
import { RouterProps } from "react-router";

interface IOwnProps {
    collapseCallback: (blockName: BillSearchOption) => void;
    billsearchResult: IBillSearchResults;
}

class SearchOptionWrapper extends React.Component<
    IOwnProps & InjectedIntlProps & RouterProps,
    { expandedBlock: BillSearchOption }
> {
    constructor(props: IOwnProps & InjectedIntlProps & RouterProps) {
        super(props);
        this.state = {
            expandedBlock: null,
        };
        this.handleOnEnterBlock1 = this.handleOnEnterBlock1.bind(this);
        this.handleOnEnterBlock2 = this.handleOnEnterBlock2.bind(this);
        this.handleOnEnterBlock3 = this.handleOnEnterBlock3.bind(this);
    }

    componentDidMount() {
        const { billsearchResult } = this.props;
        if (
            billsearchResult &&
            billsearchResult.criteria!.meta &&
            billsearchResult.criteria!.meta.searchGroup
        ) {
            const blockThatShouldBeOpened =
                billsearchResult.criteria!.meta.searchGroup!;
            this.setState({ expandedBlock: blockThatShouldBeOpened });
        }
    }

    handleOnEnterBlock1 = () => {
        const { collapseCallback } = this.props;
        collapseCallback("BILL_NUMBER");
    };

    handleOnEnterBlock2 = () => {
        const { collapseCallback } = this.props;
        collapseCallback("SEARCH_BY_MAILING_ADDRESS");
    };

    handleOnEnterBlock3 = () => {
        const { collapseCallback } = this.props;
        collapseCallback("SEARCH_BY_UNSECURE_BILL_NUMBER");
    };

    handleSelect = (e: BillSearchOption) => {
        this.setState({
            expandedBlock: e,
        });
    };

    render() {
        const { intl } = this.props;
        const { expandedBlock } = this.state;
        return (
            <PanelGroup
                id="BillSearchPanelGroup"
                accordion
                className="accordion-search-options"
                activeKey={expandedBlock}
                onSelect={(e: any) => this.handleSelect(e)}
            >
                <Panel eventKey="SearchByBillNumberBlock">
                    <Panel.Heading>
                        <Panel.Title
                            toggle
                            title={intl.formatMessage({
                                id: "billSearch.bill_number.option_title",
                            })}
                            className="searchform_header_bill"
                        >
                            {Resources.BillSearchOption1}
                        </Panel.Title>
                    </Panel.Heading>
                    <Panel.Collapse onEnter={this.handleOnEnterBlock1}>
                        <Panel.Body>
                            <SearchByBillNumberBlock
                                id="TestId"
                                history={this.props.history}
                                collapseCallback={this.props.collapseCallback}
                            ></SearchByBillNumberBlock>
                        </Panel.Body>
                    </Panel.Collapse>
                </Panel>
                <Panel eventKey="SearchByMailingAddressBlock">
                    <Panel.Heading>
                        <Panel.Title
                            toggle
                            title={intl.formatMessage({
                                id: "billSearch.mailing_address.option_title",
                            })}
                            className="searchform_header_mail"
                        >
                            {Resources.BillSearchOption2}
                        </Panel.Title>
                    </Panel.Heading>
                    <Panel.Collapse onEnter={this.handleOnEnterBlock2}>
                        <Panel.Body>
                            <SearchByMailingAddressBlock
                                id="TestId"
                                history={this.props.history}
                                collapseCallback={this.props.collapseCallback}
                            />
                        </Panel.Body>
                    </Panel.Collapse>
                </Panel>
                <Panel eventKey="SearchByUnsecureBillNumberBlock">
                    <Panel.Heading>
                        <Panel.Title
                            toggle
                            title={intl.formatMessage({
                                id: "billSearch.unsecured_bill_number.option_title",
                            })}
                            className="searchform_header_unsecured"
                        >
                            {Resources.BillSearchOption3}
                        </Panel.Title>
                    </Panel.Heading>
                    <Panel.Collapse onEnter={this.handleOnEnterBlock3}>
                        <Panel.Body>
                            <SearchByUnsecuredBillNumberBlock
                                id="TestId"
                                history={this.props.history}
                                collapseCallback={this.props.collapseCallback}
                            />
                        </Panel.Body>
                    </Panel.Collapse>
                </Panel>
            </PanelGroup>
        );
    }
}

export default injectIntl(SearchOptionWrapper);
