import { CommonActions } from './Common';

export const PAYMENTTERMS_RESET = "redux/paymentterms/RESET";
export const PAYMENTTERMS_LOAD = "redux/paymentterms/LOAD";
export const PAYMENTTERMS_LOAD_SUCCESS = "redux/paymentterms/LOAD_SUCCESS";
export const PAYMENTTERMS_LOAD_FAIL = "redux/paymentterms/LOAD_FAIL";
export const PAYMENTTERMS_UPDATE_ACCEPTED = "redux/paymentterms/UPDATE_ACCEPTED";
export const PAYMENTTERMS_UPDATE_CAPTCHA = "redux/paymentterms/UPDATE_CAPTCHA";

export type RequestPaymentTermsAction = {
    type: typeof PAYMENTTERMS_LOAD;
}

export type ReceivePaymentTermsAction = {
    type: typeof PAYMENTTERMS_LOAD_SUCCESS;
    data: string;
}

export type FailedPaymentTermsAction = {
    type: typeof PAYMENTTERMS_LOAD_FAIL;
    data: string;
}

export type ResetPaymentTermsAction = {
    type: typeof PAYMENTTERMS_RESET;
}

export type UpdateAcceptancePaymentTermsAction = {
    type: typeof PAYMENTTERMS_UPDATE_ACCEPTED;
    data: boolean;
}

export type CaptchaPaymentTermsAction = {
    type: typeof PAYMENTTERMS_UPDATE_CAPTCHA;
    data: string;
}

export type PaymentTermsActions = RequestPaymentTermsAction | ReceivePaymentTermsAction | FailedPaymentTermsAction | ResetPaymentTermsAction | UpdateAcceptancePaymentTermsAction | CaptchaPaymentTermsAction | CommonActions;