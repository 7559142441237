import React from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExclamationTriangle } from "@fortawesome/free-solid-svg-icons";
import { Alert } from "react-bootstrap";
import { FormattedNumber } from "react-intl";
import {
    LabelUnderMinPaymentAmount,
    ErrorSumUnderlimit,
} from "../../../resources";

export interface UnderLimitAlertProps {
    amount: number;
}

export const UnderLimitAlert = ({
    amount,
}: UnderLimitAlertProps): JSX.Element => {
    return (
        <Alert bsStyle="danger">
            <h4>
                <FontAwesomeIcon icon={faExclamationTriangle} />{" "}
                {LabelUnderMinPaymentAmount}
            </h4>
            <p>
                {ErrorSumUnderlimit(
                    <FormattedNumber
                        value={amount}
                        style="currency"
                        currency="USD"
                    />
                )}
            </p>
        </Alert>
    );
};
