import React from "react";
import { connect } from "react-redux";
import { Post } from "../../utils/Post";
import { clear } from "../../actions/Common";
import {
    HeaderSessionTimeout,
    MessageSessionExpired,
    LinkStartOver,
} from "../../resources";
import { IApplication, IApplicationState, ISettings } from "../../types";
import { RouterProps } from "react-router";

interface IOwnProps {
    clear: () => void;
}

interface IDispatchedStateProps {
    settings: ISettings;
    application: IApplication;
}
type IComponentProps = IOwnProps & IDispatchedStateProps & RouterProps;
class SessionExpired extends React.Component<IComponentProps, any> {
    constructor(props: any) {
        super(props);
        this.handleStartOver = this.handleStartOver.bind(this);
    }

    handleStartOver(event: any) {
        event.preventDefault();
        this.setState({
            showModal: false,
        });
        const { application, settings, clear } = this.props;
        clear();
        if (application.id === 33) {
            this.props.history.push(`/${settings.merchantName}`);
        } else {
            const data = {
                GUID: application.guid,
                DataPostMode: "Cancel",
            };
            if (application.id === 35) {
                Post(settings.securePayMOTOCancelUrl, data);
            } else {
                Post(settings.cancelUrl, data);
            }
        }
    }

    render() {
        return (
            <div>
                <h2 className="page-header">{HeaderSessionTimeout}</h2>
                <p>{MessageSessionExpired}</p>
                <p>
                    <a
                        href={`/${this.props.settings.merchantName}`}
                        onClick={this.handleStartOver}
                    >
                        {LinkStartOver}
                    </a>
                </p>
            </div>
        );
    }
}

export default connect(
    (state: IApplicationState) => ({
        application: state.application,
        settings: state.settings,
    }),
    { clear: clear }
)(SessionExpired);
