export enum PaymentMethodEnum
{
    Unassigned = 0,
    VISACredit = 1,
    MastercardCredit = 2,
    DiscoverCredit = 3,
    AmericanExpressCredit = 4,
    JCB = 5,
    GSB = 6,
    ACH = 7,
    ECheck = 8,
    Debit = 9,
    Cash = 10,
    VisaDebit = 11,
    MastercardDebit = 12,
    DiscoverDebit = 13,
    AmericanExpressDebit = 14,
    ACHCard = 15
}