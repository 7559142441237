import { createOrUpdate } from "../api/Account";
import { ACCOUNTS_UPDATE } from "../actionTypes/Account";
import {
  IApplication,
  IAppThunkAction,
  IBillDetail,
  IUser,
  IAccount,
  IBill,
} from "../types";
import {
  getCustomerBillDetails,
  setAutoPay,
  setBillNotification,
  getAutopayTerms,
  generateBillDetailsPdf,
} from "../api/BillDetails";
import {
  AUTOPAYTERMS_LOAD,
  AUTOPAYTERMS_LOAD_FAIL,
  AUTOPAYTERMS_LOAD_SUCCESS,
  AUTOPAYTERMS_RESET,
  AUTOPAYTERMS_UPDATE_ACCEPTED,
  AUTOPAYTERMS_UPDATE_CAPTCHA,
  BILLDETAILS_CREATE,
  BILLDETAILS_DELETE,
  BILLDETAILS_LOAD,
  BILLDETAILS_LOAD_FAIL,
  BILLDETAILS_LOAD_SUCCESS,
  BILLDETAILS_UPDATE,
  BillDetailActions,
} from "../actionTypes/BillDetails";

export const loadCustomerBillDetails =
  (
    merchantName: string,
    authToken: string,
    merchantCustomerId: string
  ): IAppThunkAction<BillDetailActions> =>
  (dispatch) => {
    getCustomerBillDetails(merchantName, authToken)
      .then((response) =>
        dispatch({
          type: BILLDETAILS_LOAD_SUCCESS,
          data: response.customerBillDetails,
        })
      )
      .catch((error) => dispatch({ type: BILLDETAILS_LOAD_FAIL, data: error }));

    dispatch({ type: BILLDETAILS_LOAD });
  };

export const createCustomerBillDetail =
  (billDetails: IBillDetail[]): IAppThunkAction<BillDetailActions> =>
  (dispatch) => {
    dispatch({
      type: BILLDETAILS_CREATE,
      data: billDetails,
    });
  };

export const deleteCustomerBillDetail =
  (billDetails: IBillDetail[]): IAppThunkAction<BillDetailActions> =>
  (dispatch) => {
    dispatch({
      type: BILLDETAILS_DELETE,
      data: billDetails,
    });
  };

export const updateCustomerBillDetail =
  (billDetail: IBillDetail): IAppThunkAction<BillDetailActions> =>
  (dispatch) => {
    dispatch({
      type: BILLDETAILS_UPDATE,
      data: billDetail,
    });
  };

export const setCustomerAutoPay =
  (
    application: IApplication,
    merchantName: string,
    billDetails: IBillDetail[],
    user: IUser,
    account: IAccount,
    onError: (text: string) => void,
    onSuccess: (id1: string) => void
  ): IAppThunkAction<BillDetailActions> =>
  (dispatch) => {
    if (account.token) {
      billDetails.map((billDetail) => {
        billDetail.token = account.token ? account.token : "";
        setAutoPay(
          application,
          merchantName,
          billDetail,
          user.merchantCustomerID
        )
          .then((result) => {
            if (result.isSuccessful) {
              result.customerBillDetail.paymentMethod = account.paymentMethod;
              result.customerBillDetail.tokenLastFour =
                account.accountNumber.substr(account.accountNumber.length - 4);
              dispatch({
                type: BILLDETAILS_UPDATE,
                data: result.customerBillDetail,
              });
              if (billDetail.isNotificationEnabled) {
                setBillNotification(
                  application,
                  merchantName,
                  billDetail,
                  user.merchantCustomerID
                )
                  .then((result) => {
                    if (result.isSuccessful) {
                      dispatch({
                        type: BILLDETAILS_UPDATE,
                        data: result.customerBillDetail,
                      });
                      if (onSuccess) {
                        onSuccess(result.customerBillDetail.identifier1);
                      }
                    } else {
                      if (onError) {
                        const error =
                          (result.messages && result.messages[0]) ||
                          "Error registering customer bill notification.";
                        onError(error);
                      }
                    }
                  })
                  .catch((error) => {
                    if (onError) {
                      onError(error.exceptionMessage);
                    }
                  });
              }
              if (onSuccess) {
                onSuccess(result.customerBillDetail.identifier1);
              }
            } else {
              if (onError) {
                const error =
                  (result.messages && result.messages[0]) ||
                  "Error registering customer autopay.";
                onError(error);
              }
            }
          })
          .catch((error) => {
            if (onError) {
              onError(error.exceptionMessage);
            }
          });
      });
    } else if (!account.token) {
      createOrUpdate(application, merchantName, user, account, "POST") 
        .then((result) => {
          if (result.isSuccessful) {
            dispatch({ type: ACCOUNTS_UPDATE, data: result });
            billDetails.map((billDetail) => {
              billDetail.token = result.account.token
                ? result.account.token
                : "";
              setAutoPay(
                application,
                merchantName,
                billDetail,
                user.merchantCustomerID
              )
                .then((response) => {
                  if (response.isSuccessful) {
                    response.customerBillDetail.paymentMethod =
                      account.paymentMethod;
                    response.customerBillDetail.tokenLastFour =
                      account.accountNumber.substr(
                        account.accountNumber.length - 4
                      );
                    dispatch({
                      type: BILLDETAILS_UPDATE,
                      data: response.customerBillDetail,
                    });
                    if (billDetail.isNotificationEnabled) {
                      setBillNotification(
                        application,
                        merchantName,
                        billDetail,
                        user.merchantCustomerID
                      )
                        .then((result) => {
                          if (result.isSuccessful) {
                            dispatch({
                              type: BILLDETAILS_UPDATE,
                              data: result.customerBillDetail,
                            });
                            if (onSuccess) {
                              onSuccess(result.customerBillDetail.identifier1);
                            }
                          } else {
                            if (onError) {
                              const error =
                                (result.messages && result.messages[0]) ||
                                "Error registering customer bill notification.";
                              onError(error);
                            }
                          }
                        })
                        .catch((error) => {
                          if (onError) {
                            onError(error.exceptionMessage);
                          }
                        });
                    }

                    if (onSuccess) {
                      onSuccess(response.customerBillDetail.identifier1);
                    }
                  } else {
                    if (onError) {
                      const error =
                        (response.messages && response.messages[0]) ||
                        "Error registering customer autopay.";
                      onError(error);
                    }
                  }
                })
                .catch((error) => {
                  if (onError) {
                    onError(error.exceptionMessage);
                  }
                });
            });
          } else {
            if (onError) {
              const error =
                (result.messages && result.messages[0]) ||
                "Error registering customer autopay.";
              onError(error);
            }
          }
        })
        .catch((error) => {
          if (onError) {
            onError(error.exceptionMessage);
          }
        });
    }
  };

export const setCustomerBillNotification =
  (
    application: IApplication,
    merchantName: string,
    billDetails: IBillDetail[],
    merchantCustomerId: string,
    onError: (text: string) => void,
    onSuccess: (email: string) => void
  ): IAppThunkAction<BillDetailActions> =>
  (dispatch) => {
    billDetails.map((billDetail) => {
      setBillNotification(
        application,
        merchantName,
        billDetail,
        merchantCustomerId
      )
        .then((result) => {
          if (result.isSuccessful) {
            dispatch({
              type: BILLDETAILS_UPDATE,
              data: result.customerBillDetail,
            });
            if (onSuccess) {
              onSuccess(result.customerBillDetail.identifier1);
            }
          } else {
            if (onError) {
              const error =
                (result.messages && result.messages[0]) ||
                "Error registering customer bill notification.";
              onError(error);
            }
          }
        })
        .catch((error) => {
          if (onError) {
            onError(error.exceptionMessage);
          }
        });
    });
  };

export const loadAutoPayTerms =
  (
    application: IApplication,
    merchantName: string,
    templateId: number,
    methodId: number,
    languageId: number
  ): IAppThunkAction<BillDetailActions> =>
  (dispatch) => {
    getAutopayTerms(application, merchantName, templateId, methodId, languageId)
      .then((result) =>
        dispatch({ type: AUTOPAYTERMS_LOAD_SUCCESS, data: result })
      )
      .catch((error) =>
        dispatch({ type: AUTOPAYTERMS_LOAD_FAIL, data: error.exceptionMessage })
      );

    dispatch({ type: AUTOPAYTERMS_LOAD });
  };

export const updateAcceptedAutoPayTerms =
  (value: boolean): IAppThunkAction<BillDetailActions> =>
  (dispatch) => {
    dispatch({
      type: AUTOPAYTERMS_UPDATE_ACCEPTED,
      data: value,
    });
  };

export const updateCaptchaAutoPayTerms =
  (value: string): IAppThunkAction<BillDetailActions> =>
  (dispatch) => {
    dispatch({
      type: AUTOPAYTERMS_UPDATE_CAPTCHA,
      data: value,
    });
  };

export const resetAutoPayTerms =
  (): IAppThunkAction<BillDetailActions> => (dispatch) => {
    dispatch({
      type: AUTOPAYTERMS_RESET,
    });
  };

export const generatePdf =
  (
    merchantName: string,
    billType: string,
    bill: IBill
  ): IAppThunkAction<BillDetailActions> =>
  () => {
    generateBillDetailsPdf(merchantName, billType, bill);
  };
