import React from "react";
import { Button, Glyphicon } from "react-bootstrap";
import * as Resources from "../../resources";

class ResultsButton extends React.Component<any, any> {
    static defaultProps: Partial<any> = {
        block: false,
        ignoreDisable: false,
    };
    render() {
        /* Set props to local variables */
        const {
            settings: {
                allowMultipleBillPayments,
                allowBillOverpayment,
                maxPaymentAmount,
            },
            bill,
            billType,
            selected,
            block,
            deleteFromBills,
            addToBills,
        } = this.props;
        /* Disable button if user entered amount is zero or error. */
        const customAmount = Number(bill.customAmount);
        const disabled =
            customAmount === 0 || customAmount > maxPaymentAmount || bill.error
                ? true
                : false;
        const showPaymentsDisabledMessage =
            !bill.isPayable &&
            billType != undefined &&
            billType.paymentsDisabled === true;

        /* Is the bill already paid in full? */
        const paidInFull =
            bill.paidInFullDate || bill.amountDue <= 0 ? true : false;
        /*
            AllowMultipleBillPayments: true
            Selected: true
            Current bill is selected, allow user to remove bill from cart.
        */
        if (allowMultipleBillPayments && selected) {
            return (
                <Button
                    bsStyle="danger"
                    onClick={deleteFromBills}
                    style={{ minWidth: "4.0em", width: "100%" }}
                    disabled={disabled}
                    block={block}
                >
                    <Glyphicon glyph="remove" /> {Resources.ButtonRemove}
                </Button>
            );
        }

        if (showPaymentsDisabledMessage) {
            return <span>{billType!.paymentsDisabledMessage}</span>;
        }

        /*
            AllowMultipleBillPayments: true
            Allow user to add bill to cart when allow over pay is enabled or the bill is not paid in full.
        */
        if (
            allowMultipleBillPayments &&
            (allowBillOverpayment || !paidInFull)
        ) {
            return (
                <Button
                    bsStyle="success"
                    onClick={addToBills}
                    style={{ minWidth: "4.0em", width: "100%" }}
                    disabled={disabled}
                    block={block}
                >
                    <Glyphicon glyph="plus" /> {Resources.ButtonAdd}
                </Button>
            );
        }
        /*
            AllowBillOverpayment: false
            Do not allow user to add bill to cart, because overpay is not enabled and bill is already paid.
        */
        if (!allowBillOverpayment && paidInFull) {
            return (
                <Button
                    bsStyle="default"
                    style={{ minWidth: "4.0em", width: "100%" }}
                    disabled={disabled}
                    block={block}
                >
                    {Resources.ButtonPaid}
                </Button>
            );
        }
        /*
            Defalut: Add single pay bill to cart.
        */
        return (
            <Button
                bsStyle="primary"
                onClick={addToBills}
                style={{ minWidth: "4.0em", width: "100%" }}
                disabled={disabled}
                block={block}
            >
                {Resources.ButtonContinueBillPage}
            </Button>
        );
    }
}

export default ResultsButton;
