import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faExclamationTriangle,
    faCheck,
} from "@fortawesome/free-solid-svg-icons";
import {
    Alert,
    Button,
    Row,
    Col,
    Form,
    Panel,
    Well,
    Glyphicon,
} from "react-bootstrap";
import { BlockUi } from "../Layout/BlockUi";
import {
    HeaderResetPassword,
    HeaderSetupPassword,
    MessagePasswordResetSuccess,
    MessagePasswordSetupSuccess,
    MessageErrorProcessingRequest,
} from "../../resources";
import { EmailInput, PasswordInput } from "../Input";
import { CheckFormValidity, CheckFieldValidity } from "../../utils/Validation";
import Recaptcha from "react-google-recaptcha";
import { changeCustomerPassword } from "../../api/Account";
import { IUser } from "../../types";
import { IFormError } from "../../types/IFormError";
import { RECAPTCHA_SITEKEY } from "../../core/constants";

const number = new RegExp(/\d{1}/);
const lower = new RegExp(/[a-z]{1}/);
const upper = new RegExp(/[A-Z]{1}/);
const special = new RegExp(/[^a-zA-Z0-9\s]{1}/);
const whitespace = new RegExp(/\s{1}/);

export class ResetPasswordForm extends React.Component<any, any> {
    private recaptcha = React.createRef<Recaptcha>();
    constructor(props: any) {
        super(props);
        this.state = {
            user: {
                userName: this.props.userName,
                password: "",
                passwordResetToken: this.props.passwordResetToken,
            },
            success: false,
            error: false,
            errors: {},
        };
    }

    private handleFormErrors = (errors: IFormError): void => {
        this.setState({
            errors: {
                ...this.state.errors,
                ...errors,
            },
        });
    };

    handleError = (name: any, message: any) => {
        return this.setState({
            errors: {
                ...this.state.errors,
                [name]: message,
            },
        });
    };

    handleBlur = (event: any) => {
        CheckFieldValidity(event.target, this.handleError);
    };

    handleChange = (event: any) => {
        if (event.target.validity.valid) {
            CheckFieldValidity(event.target, this.handleError);
        }
        return this.setState({
            user: {
                ...this.state.user,
                [event.target.name]: event.target.value,
            },
        });
    };

    passwordMatchTest = () => {
        const {
            user: { password, verifyPassword },
        } = this.state;
        if (!password) {
            return false;
        }
        if (!verifyPassword) {
            return false;
        }
        return password === verifyPassword;
    };

    disableContinue = () => {
        const { user } = this.state;
        if (user.password.length < 9) {
            return true;
        }
        if (!lower.test(user.password)) {
            return true;
        }
        if (!upper.test(user.password)) {
            return true;
        }
        if (!number.test(user.password)) {
            return true;
        }
        if (!special.test(user.password)) {
            return true;
        }
        if (whitespace.test(user.password)) {
            return true;
        }
        if (!this.passwordMatchTest()) {
            return true;
        }
        return false;
    };

    onResolved = (token?: string | null) => {
        const { application, settings, updateUser } = this.props;
        const { user } = this.state;
        //const recaptcha = this.recaptcha.getResponse();

        this.setState({
            submitting: true,
            success: false,
            error: null,
        });

        changeCustomerPassword(
            application,
            settings.merchantName,
            user.passwordResetToken,
            user.userName,
            user.password,
            token
        )
            .then((result: IUser) => {
                if (result.isLoggedIn) {
                    updateUser(result);
                    if (application.id !== 35) {
                        this.props.loadUserAccounts(
                            application,
                            settings.merchantName,
                            result.authToken
                        );

                        if (settings.allowAutoBillPay) {
                            this.props.loadCustomerBillDetails(
                                settings.merchantName,
                                result.authToken,
                                result.merchantCustomerID
                            );
                        }
                    }

                    this.setState({
                        submitting: false,
                        submitted: true,
                        success: true,
                    });
                } else {
                    this.setState({
                        submitting: false,
                        error: { MessageErrorProcessingRequest },
                    });
                }
                this.recaptcha.current?.reset();
            })
            .catch((reason: any) => {
                this.recaptcha.current?.reset();
                this.setState({
                    submitting: false,
                    error: null,
                });
            });
    };

    handleSubmit = async (event: any) => {
        event.preventDefault();
        if (!CheckFormValidity("reset-password-form", this.handleFormErrors)) {
            return false;
        }
        this.setState({
            submitting: true,
            error: null,
        });
        const {
            application: { id },
            settings: { requireReCaptcha },
        } = this.props;
        if (requireReCaptcha) {
            //this.recaptcha.execute();
            const token = await this.recaptcha.current?.executeAsync();

            this.onResolved(token);
        } else {
            this.onResolved();
        }
        return true;
    };

    handleClose = (event: any) => {
        event.preventDefault();
        let path = `/${this.props.settings.merchantName}/`;
        if (this.props.guid) path += `uid/${this.props.guid}/`;
        this.props.history.push(path);
        return true;
    };

    render() {
        const { intl, isFirstTimeSetup } = this.props;
        const { user, errors, success, error, submitting } = this.state;
        const glyphChecked = (
            <span className="text-success">
                <Glyphicon glyph="check" />
            </span>
        );
        const glyphUnchecked = (
            <span className="text-danger">
                <Glyphicon glyph="unchecked" />
            </span>
        );
        const disableContinue = this.disableContinue();

        return (
            <Form id="reset-password-form">
                <BlockUi shouldRender={submitting} />
                <Row>
                    <Col xs={0} sm={1} md={2} />
                    <Col xs={12} sm={10} md={8}>
                        <Panel bsStyle="primary">
                            <Panel.Heading>
                                <Panel.Title componentClass="h3">
                                    {isFirstTimeSetup
                                        ? HeaderSetupPassword
                                        : HeaderResetPassword}
                                </Panel.Title>
                            </Panel.Heading>
                            <Panel.Body>
                                <Well>
                                    <Row>
                                        <Col xs={12}>
                                            <EmailInput
                                                type="email"
                                                name="userName"
                                                placeholder="example@domain.com"
                                                label="Email Address"
                                                value={user.userName}
                                                error={errors.userName}
                                                onChange={this.handleChange}
                                                onError={this.handleError}
                                                onBlur={this.handleBlur}
                                                required
                                            />
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col xs={12}>
                                            <PasswordInput
                                                name="password"
                                                placeholder="Password"
                                                label="Password"
                                                value={user.password}
                                                error={errors.password}
                                                onChange={this.handleChange}
                                                onError={this.handleError}
                                                onBlur={this.handleBlur}
                                                required
                                            />
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col xs={12}>
                                            <PasswordInput
                                                name="verifyPassword"
                                                placeholder="Re-type your password"
                                                label="Re-type your password"
                                                value={user.verifyPassword}
                                                error={errors.verifyPassword}
                                                onChange={this.handleChange}
                                                onError={this.handleError}
                                                onBlur={this.handleBlur}
                                                required
                                            />
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col xs={12}>
                                            <ul>
                                                <li>
                                                    {user.password.length >= 9
                                                        ? glyphChecked
                                                        : glyphUnchecked}{" "}
                                                    Passwords must be at least 9
                                                    characters long.
                                                </li>
                                                <li>
                                                    {lower.test(user.password)
                                                        ? glyphChecked
                                                        : glyphUnchecked}{" "}
                                                    Passwords must contain at
                                                    least one lowercase letter.
                                                </li>
                                                <li>
                                                    {upper.test(user.password)
                                                        ? glyphChecked
                                                        : glyphUnchecked}{" "}
                                                    Passwords must contain at
                                                    least one uppercase letter.
                                                </li>
                                                <li>
                                                    {number.test(user.password)
                                                        ? glyphChecked
                                                        : glyphUnchecked}{" "}
                                                    Passwords must contain at
                                                    least one number.
                                                </li>
                                                <li>
                                                    {special.test(user.password)
                                                        ? glyphChecked
                                                        : glyphUnchecked}{" "}
                                                    Passwords must contain at
                                                    least one special character.
                                                </li>
                                                <li>
                                                    {whitespace.test(
                                                        user.password
                                                    )
                                                        ? glyphUnchecked
                                                        : glyphChecked}{" "}
                                                    Passwords must not contain
                                                    any spaces.
                                                </li>
                                                <li>
                                                    {this.passwordMatchTest()
                                                        ? glyphChecked
                                                        : glyphUnchecked}{" "}
                                                    Both passwords must match.
                                                </li>
                                            </ul>
                                        </Col>
                                    </Row>
                                    {error && (
                                        <Row>
                                            <Col xs={12} sm={12} md={12}>
                                                <Alert bsStyle="danger">
                                                    <FontAwesomeIcon
                                                        icon={
                                                            faExclamationTriangle
                                                        }
                                                    />{" "}
                                                    {error}
                                                </Alert>
                                            </Col>
                                        </Row>
                                    )}
                                    {success && (
                                        <Row>
                                            <Col xs={12} sm={12} md={12}>
                                                <Alert bsStyle="success">
                                                    <FontAwesomeIcon
                                                        icon={faCheck}
                                                    />{" "}
                                                    {isFirstTimeSetup
                                                        ? MessagePasswordSetupSuccess
                                                        : MessagePasswordResetSuccess}
                                                </Alert>
                                            </Col>
                                        </Row>
                                    )}
                                    <Row>
                                        <Col xs={12}>
                                            {!success && (
                                                <Button
                                                    bsStyle="primary"
                                                    onClick={this.handleSubmit}
                                                    disabled={disableContinue}
                                                    style={{
                                                        minWidth: "6.5em",
                                                    }}
                                                    block
                                                >
                                                    {isFirstTimeSetup
                                                        ? HeaderSetupPassword
                                                        : HeaderResetPassword}
                                                </Button>
                                            )}
                                            {success && (
                                                <Button
                                                    bsStyle="primary"
                                                    onClick={this.handleClose}
                                                    style={{
                                                        minWidth: "6.5em",
                                                    }}
                                                    block
                                                >
                                                    Close
                                                </Button>
                                            )}
                                        </Col>
                                    </Row>
                                </Well>
                            </Panel.Body>
                        </Panel>
                    </Col>
                    <Col xs={0} sm={1} md={2} />
                </Row>
                <div style={{ display: "none" }}>
                    <Recaptcha
                        ref={this.recaptcha}
                        hl={intl.locale}
                        size="invisible"
                        sitekey={RECAPTCHA_SITEKEY}
                    />
                </div>
            </Form>
        );
    }
}
