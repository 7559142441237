import { ConfigurationActions } from '../actionTypes/Configuration';
import { ISettings } from '../types';

export const SETTINGS_LOAD = "redux/settings/LOAD";
export const SETTINGS_LOAD_SUCCESS = "redux/settings/LOAD_SUCCESS";
export const SETTINGS_LOAD_FAIL = "redux/settings/LOAD_FAIL";

export type RequestSettingsAction = {
    type: typeof SETTINGS_LOAD;
}

export type ReceiveSettingsAction = {
    type: typeof SETTINGS_LOAD_SUCCESS;
    data: ISettings;
}

export type FailedSettingsAction = {
    type: typeof SETTINGS_LOAD_FAIL;
    data: string;
}

export type SettingsActions = RequestSettingsAction | ReceiveSettingsAction | FailedSettingsAction | ConfigurationActions;