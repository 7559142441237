export function isNullOrUndefined(value?: string | number | boolean | null) {
    return typeof value === 'undefined' || value === null;
}

export function isNullOrEmpty(value: string | null) {
    return typeof value === null || value === '';
}

export function getTimestamp(): string {
    function pad2(n: number) {  // always returns a string
        return (n < 10 ? '0' : '') + n;
    }

    const currentDate = new Date();
    const formattedTimestamp = currentDate.getFullYear() +
        pad2(currentDate.getMonth() + 1) +
        pad2(currentDate.getDate()) +
        pad2(currentDate.getHours()) +
        pad2(currentDate.getMinutes()) +
        pad2(currentDate.getSeconds());

    return formattedTimestamp;
}