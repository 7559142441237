import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExclamationTriangle } from "@fortawesome/free-solid-svg-icons";
// import PropTypes from "prop-types";
import { injectIntl } from "react-intl";
import {
    Row,
    Col,
    Panel,
    Form,
    Button,
    Glyphicon,
    Alert,
} from "react-bootstrap";
import { TextInput, EmailInput, PasswordInput } from "../../Input";
import { BlockUi } from "../BlockUi";
import { register } from "../../../api/User";
import Recaptcha from "react-google-recaptcha";
import {
    CheckFormValidity,
    CheckFieldValidity,
} from "../../../utils/Validation";
import * as Resources from "../../../resources";
import { IUser } from "../../../types";
import { IFormError } from "../../../types/IFormError";
import { RECAPTCHA_SITEKEY } from "../../../core/constants";

const number = new RegExp(/\d{1}/);
const lower = new RegExp(/[a-z]{1}/);
const upper = new RegExp(/[A-Z]{1}/);
const special = new RegExp(/[^a-zA-Z0-9\s]{1}/);
const whitespace = new RegExp(/\s{1}/);
class RegisterPanel extends React.Component<any, any> {
    private recaptcha = React.createRef<Recaptcha>();

    constructor(props: any) {
        super(props);
        this.state = {
            user: { firstname: "", lastname: "", username: "", password: "" },
            errors: {},
        };
        this.onResolved = this.onResolved.bind(this);
        this.handleBlur = this.handleBlur.bind(this);
        this.handleError = this.handleError.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.passwordMatchTest = this.passwordMatchTest.bind(this);
        this.disableContinue = this.disableContinue.bind(this);
    }
    handleBlur(event: any) {
        CheckFieldValidity(event.target, this.handleError);
    }

    private handleFormErrors = (errors: IFormError): void => {
        this.setState({
            errors: {
                ...this.state.errors,
                ...errors,
            },
        });
    };

    handleError(name: any, message: any) {
        return this.setState({
            errors: {
                ...this.state.errors,
                [name]: message,
            },
        });
    }
    handleChange(event: any) {
        if (event.target.validity.valid) {
            CheckFieldValidity(event.target, this.handleError);
        }
        return this.setState({
            user: {
                ...this.state.user,
                [event.target.name]: event.target.value,
            },
        });
    }
    async handleSubmit(event: any) {
        event.preventDefault();
        if (!CheckFormValidity("register-form", this.handleFormErrors)) {
            return false;
        }
        this.setState({
            submitting: true,
            error: null,
        });
        const {
            application: { id },
            requireRecaptcha,
        } = this.props;
        if (requireRecaptcha) {
            const token = await this.recaptcha.current?.executeAsync();

            this.onResolved(token);
        } else {
            this.onResolved();
        }
        return true;
    }
    passwordMatchTest() {
        const {
            user: { password, verifyPassword },
        } = this.state;
        if (!password) {
            return false;
        }
        if (!verifyPassword) {
            return false;
        }
        return password === verifyPassword;
    }
    disableContinue() {
        const { user } = this.state;
        if (user.password.length < 9) {
            return true;
        }
        if (!lower.test(user.password)) {
            return true;
        }
        if (!upper.test(user.password)) {
            return true;
        }
        if (!number.test(user.password)) {
            return true;
        }
        if (!special.test(user.password)) {
            return true;
        }
        if (whitespace.test(user.password)) {
            return true;
        }
        if (!this.passwordMatchTest()) {
            return true;
        }
        return false;
    }
    onResolved(token?: string | null) {
        const { application, merchantName, updateUser, onClose, afterSuccess } =
            this.props;
        const { user } = this.state;
        //const recaptcha = this.recaptcha.getResponse();
        register(
            application,
            merchantName,
            user.username,
            user.password,
            user.firstname,
            user.lastname,
            token
        )
            .then((result: IUser) => {
                if (result.isLoggedIn) {
                    const challengeMfa = result.mfaRequired;

                    if (!challengeMfa) {
                        result.isVerified = true;
                    }

                    updateUser(result);

                    if (challengeMfa) {
                        if (this.props.onChallengeMfa) {
                            this.props.onChallengeMfa();
                            return;
                        }
                    }

                    if (onClose) {
                        onClose();
                    }
                    if (afterSuccess) {
                        afterSuccess();
                    }
                } else {
                    this.setState({
                        submitting: false,
                        error: result.messages[0] || Resources.AjaxErrorMessage,
                    });
                }
                this.recaptcha.current?.reset();
            })
            .catch((reason: any) => {
                this.recaptcha.current?.reset();
                this.setState({
                    submitting: false,
                    error: Resources.AjaxErrorMessage,
                });
            });
    }
    render() {
        const { intl, updateUser, afterSuccess } = this.props;
        const { user, submitting, error, errors } = this.state;
        const success = (
            <span className="text-success">
                <Glyphicon glyph="check" />
            </span>
        );
        const danger = (
            <span className="text-danger">
                <Glyphicon glyph="unchecked" />
            </span>
        );
        const disableContinue = this.disableContinue();
        return (
            <div>
                <BlockUi shouldRender={submitting} />
                <Panel>
                    <Panel.Body>
                        <Row>
                            <Col xs={12} sm={12} md={12} lg={12}>
                                <Form id="register-form">
                                    <Row>
                                        <Col xs={12} sm={6}>
                                            <TextInput
                                                name="firstname"
                                                label={Resources.LabelFirstName}
                                                value={user.firstname}
                                                error={errors.firstname}
                                                onChange={this.handleChange}
                                                onError={this.handleError}
                                                onBlur={this.handleError}
                                                maxLength={50}
                                                required
                                            />
                                        </Col>
                                        <Col xs={12} sm={6}>
                                            <TextInput
                                                name="lastname"
                                                label={Resources.LabelLastName}
                                                value={user.lastname}
                                                error={errors.lastname}
                                                onChange={this.handleChange}
                                                onError={this.handleError}
                                                onBlur={this.handleError}
                                                maxLength={50}
                                                required
                                            />
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col xs={12}>
                                            <EmailInput
                                                type="email"
                                                name="username"
                                                placeholder="example@domain.com"
                                                label={Resources.LabelEmail}
                                                value={user.username}
                                                error={errors.username}
                                                onChange={this.handleChange}
                                                onError={this.handleError}
                                                onBlur={this.handleError}
                                                required
                                            />
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col xs={12}>
                                            <PasswordInput
                                                name="password"
                                                placeholder={intl.formatMessage(
                                                    {
                                                        id: "message.password",
                                                    }
                                                )}
                                                label={Resources.Password}
                                                value={user.password}
                                                error={errors.password}
                                                onChange={this.handleChange}
                                                onError={this.handleError}
                                                onBlur={this.handleBlur}
                                                required
                                            />
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col xs={12}>
                                            <PasswordInput
                                                name="verifyPassword"
                                                placeholder={intl.formatMessage(
                                                    {
                                                        id: "message.retypePassword",
                                                    }
                                                )}
                                                label={Resources.RetypePassword}
                                                value={user.verifyPassword}
                                                error={errors.verifyPassword}
                                                onChange={this.handleChange}
                                                onError={this.handleError}
                                                onBlur={this.handleBlur}
                                                required
                                            />
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col xs={12}>
                                            <ul>
                                                <li>
                                                    {user.password.length >= 9
                                                        ? success
                                                        : danger}{" "}
                                                    {Resources.PasswordLength}
                                                </li>
                                                <li>
                                                    {lower.test(user.password)
                                                        ? success
                                                        : danger}{" "}
                                                    {Resources.PasswordLower}
                                                </li>
                                                <li>
                                                    {upper.test(user.password)
                                                        ? success
                                                        : danger}{" "}
                                                    {Resources.PasswordUpper}
                                                </li>
                                                <li>
                                                    {number.test(user.password)
                                                        ? success
                                                        : danger}{" "}
                                                    {Resources.PasswordNumber}
                                                </li>
                                                <li>
                                                    {special.test(user.password)
                                                        ? success
                                                        : danger}{" "}
                                                    {Resources.PasswordSpecial}
                                                </li>
                                                <li>
                                                    {whitespace.test(
                                                        user.password
                                                    )
                                                        ? danger
                                                        : success}{" "}
                                                    {
                                                        Resources.PasswordWhiteSpace
                                                    }
                                                </li>
                                                <li>
                                                    {this.passwordMatchTest()
                                                        ? success
                                                        : danger}{" "}
                                                    {Resources.PasswordMatch}
                                                </li>
                                            </ul>
                                        </Col>
                                    </Row>
                                    {error && (
                                        <Row>
                                            <Col xs={12} sm={12} md={12}>
                                                <Alert bsStyle="danger">
                                                    <FontAwesomeIcon
                                                        icon={
                                                            faExclamationTriangle
                                                        }
                                                    />{" "}
                                                    {error}
                                                </Alert>
                                            </Col>
                                        </Row>
                                    )}
                                    <Row>
                                        <Col xs={12}>
                                            <Button
                                                bsStyle="primary"
                                                onClick={this.handleSubmit}
                                                disabled={disableContinue}
                                                style={{ minWidth: "6.5em" }}
                                                block
                                            >
                                                {Resources.ButtonContinue}
                                            </Button>
                                        </Col>
                                    </Row>
                                </Form>
                            </Col>
                            <div style={{ display: "none" }}>
                                <Recaptcha
                                    ref={this.recaptcha}
                                    hl={intl.locale}
                                    sitekey={RECAPTCHA_SITEKEY}
                                    size="invisible"
                                />
                            </div>
                        </Row>
                    </Panel.Body>
                </Panel>
            </div>
        );
    }
}

export default injectIntl(RegisterPanel);
