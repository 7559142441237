import * as React from 'react'
import { FormattedMessage } from 'react-intl'

export default class RecordNotFound extends React.Component {
    render() {
        return (
        <span className="text-danger">
            <FormattedMessage
                id="billSearchResult.No_Record_found"
                defaultMessage="No record found."
            />
        </span>
        )
    }
}