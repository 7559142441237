import React from "react";
import { Alert, Modal } from "react-bootstrap";
import { InjectedIntlProps, injectIntl, IntlProvider } from "react-intl";
import {
    activateFactor,
    challenge,
    enrollCallFactor,
    enrollSmsFactor,
    enrollTotpOktaFactor,
    unenrollFactor,
    verifyFactor,
} from "../../../api/User";
import {
    mapFactorTypeToEnrollType,
    canFactorResend,
} from "../../../utils/Identity";
import { OktaErrorCode, RecaptchaErrorCode } from "../../../core/constants";
import { intlErrorTypeGruard } from "../../../core/exceptions";
import { MessageMultiFactorManagementInstructions } from "../../../resources";
import { IApplication, IKeyValue, IUser, EnrollTypes } from "../../../types";
import { IFormError } from "../../../types/IFormError";
import { FactorType, IFactorTotp } from "../../../types/IUser";
import { BlockUi } from "../BlockUi";
import MultiFactorCodeEntryPanel from "./MultiFactorCodeEntryPanel";
import MultiFactorEnrollCallPanel from "./MultiFactorEnrollCallPanel";
import MultiFactorEnrollSmsPanel from "./MultiFactorEnrollSmsPanel";
import MultiFactorManagementPanel from "./MultiFactorManagementPanel";
import MultiFactorPanel from "./MultiFactorPanel";

export interface ManageFactorsModalProps extends InjectedIntlProps {
    application: IApplication;
    user: IUser;
    merchantName: string;
    show: boolean;
    updateUser?: (user: IUser) => void;
    locale: string;
    localeTranslations: IKeyValue;
    onClose: () => void;
}

export interface ManageFactorsModalState {
    error: string | JSX.Element | null;
    errors: IFormError;
    factorId: string;
    key: EnrollTypes | "activateMfa" | "enrollMfa" | "mfa" | "verifyMfa";
    isSubmitting: boolean;
}

class ManageFactorsModal extends React.Component<
    ManageFactorsModalProps,
    ManageFactorsModalState
> {
    public static defaultProps: Partial<any> = {
        show: false,
    };

    constructor(props: ManageFactorsModalProps) {
        super(props);
        this.state = {
            error: null,
            errors: {},
            factorId: "",
            key: props.user.isManageVerified ? "enrollMfa" : "mfa",
            isSubmitting: false,
        };
    }

    public componentWillReceiveProps(nextProps: ManageFactorsModalProps) {
        // when hiding the dialog, reset the key based on if the user has been verified.
        if (!nextProps.show) {
            this.setState({
                key: nextProps.user.isManageVerified ? "enrollMfa" : "mfa",
            });
        }
    }

    handleClose = (): void => {
        this.setState({
            key: this.props.user.isManageVerified ? "enrollMfa" : "mfa",
        });
        this.props.onClose();
    };

    handleChallenge = (factorId: string): void => {
        this.setState({ isSubmitting: true, error: null });
        challenge(factorId, this.props.user.userId, "en_us")
            .then((result) => {
                this.setState({ isSubmitting: false });
                if (result.isSuccessful) {
                    this.setState({ factorId, key: "verifyMfa" });
                }
            })
            .catch((err) => {
                this.setState({ error: err.message, isSubmitting: false });
            });
    };

    handleManageFactor = (factorType: FactorType, enabling: boolean): void => {
        //call API to enable or disable a factor, if enabling we need to navigate user to screen to enter factor.
        if (enabling) {
            switch (factorType) {
                case "token:software:totp":
                    this.handleEnrollTotpOkta();
                    break;
                default: {
                    this.setState({
                        key: mapFactorTypeToEnrollType(factorType),
                    });
                }
            }
        } else {
            //make api call to unenroll from factor.
            const factor = this.props.user.factors.find(
                (f) => f.factorInfo.factorType === factorType
            );

            if (factor) {
                this.setState({ isSubmitting: true, error: null });
                unenrollFactor(this.props.user.userId, factor.factorId)
                    .then((result) => {
                        this.setState({ isSubmitting: false });
                        if (result.isSuccessful) {
                            this.props.updateUser &&
                                this.props.updateUser({
                                    ...this.props.user,
                                    factors: this.props.user.factors.filter(
                                        (f) =>
                                            f.factorInfo.factorType !==
                                            factorType
                                    ),
                                });
                        }
                    })
                    .catch((err) => {
                        this.setState({
                            error: err.message,
                            isSubmitting: false,
                        });
                    });
            }
        }
    };

    handleActivate = (
        factorId: string,
        code: string,
        rememberDevice: boolean
    ): void => {
        this.setState({ isSubmitting: true, error: null });
        activateFactor(
            this.props.application,
            this.props.user.authToken,
            this.props.merchantName,
            factorId,
            this.props.user.userId,
            code,
            rememberDevice
        )
            .then((result) => {
                this.setState({ isSubmitting: false });
                if (result.isSuccessful) {
                    if (this.props.updateUser) {
                        this.props.updateUser({
                            ...this.props.user,
                            factors: this.props.user.factors.map((f) => {
                                if (f.factorId === factorId) {
                                    return result.factor;
                                }

                                return f;
                            }),
                            isVerified: true,
                            authToken: result.authToken,
                        });
                    }

                    this.setState({ key: "enrollMfa" });
                }
            })
            .catch((err) => {
                this.setState({
                    isSubmitting: false,
                    error: err.message,
                });
            });
    };

    handleEnrollSms = (phoneNumber: string): void => {
        this.setState({ isSubmitting: true, error: null });
        enrollSmsFactor(
            this.props.user.userId,
            phoneNumber,
            this.props.user.authToken
        )
            .then((result) => {
                this.setState({ isSubmitting: false });
                if (result.isSuccessful) {
                    if (result.factor.factorInfo.status === "ACTIVE") {
                        if (this.props.updateUser) {
                            this.props.updateUser({
                                ...this.props.user,
                                isVerified: true,
                                authToken: result.authToken,
                                factors: [
                                    ...this.props.user.factors,
                                    result.factor,
                                ],
                            });
                        }
                        this.setState({ key: "enrollMfa" });
                    } else {
                        if (this.props.updateUser) {
                            this.props.updateUser({
                                ...this.props.user,
                                factors: [
                                    ...this.props.user.factors,
                                    result.factor,
                                ],
                            });
                        }
                        this.setState({
                            factorId: result.factor.factorId,
                            key: "activateMfa",
                        });
                    }
                }
            })
            .catch((err) => {
                this.setState({ error: err.message, isSubmitting: false });
            });
    };

    handleEnrollCall = (
        phoneNumber: string,
        extension: string,
        regionCode: string
    ): void => {
        this.setState({ isSubmitting: true, error: null });
        enrollCallFactor(
            this.props.user.userId,
            phoneNumber,
            extension,
            regionCode
        )
            .then((result) => {
                this.setState({ isSubmitting: false });
                if (result.isSuccessful) {
                    if (result.factor.factorInfo.status === "ACTIVE") {
                        if (this.props.updateUser) {
                            this.props.updateUser({
                                ...this.props.user,
                                isVerified: true,
                                authToken: result.authToken,
                                factors: [
                                    ...this.props.user.factors,
                                    result.factor,
                                ],
                            });
                        }
                        this.setState({ key: "enrollMfa" });
                    } else {
                        if (this.props.updateUser) {
                            this.props.updateUser({
                                ...this.props.user,
                                factors: [
                                    ...this.props.user.factors,
                                    result.factor,
                                ],
                            });
                        }
                        this.setState({
                            factorId: result.factor.factorId,
                            key: "activateMfa",
                        });
                    }
                }
            })
            .catch((err) => {
                this.setState({ error: err.message, isSubmitting: false });
            });
    };

    handleEnrollTotpOkta = (): void => {
        this.setState({ isSubmitting: true, error: null });
        enrollTotpOktaFactor(this.props.user.userId)
            .then((result) => {
                this.setState({ isSubmitting: false });
                if (result.isSuccessful) {
                    if (result.factor.factorInfo.status === "ACTIVE") {
                        if (this.props.updateUser) {
                            this.props.updateUser({
                                ...this.props.user,
                                isVerified: true,
                                authToken: result.authToken,
                                factors: [
                                    ...this.props.user.factors,
                                    result.factor,
                                ],
                            });
                        }
                        this.setState({ key: "enrollMfa" });
                    } else {
                        if (this.props.updateUser) {
                            this.props.updateUser({
                                ...this.props.user,
                                factors: [
                                    ...this.props.user.factors,
                                    result.factor,
                                ],
                            });
                        }
                        this.setState({
                            factorId: result.factor.factorId,
                            key: "activateMfa",
                        });
                    }
                }
            })
            .catch((err) => {
                this.setState({ error: err.message, isSubmitting: false });
            });
    };

    handleVerify = (
        factorId: string,
        code: string,
        rememberDevice: boolean,
        recaptcha?: string | null
    ): void => {
        this.setState({ isSubmitting: true, error: null });
        verifyFactor(
            this.props.application,
            factorId,
            this.props.merchantName,
            this.props.user.userId,
            code,
            rememberDevice,
            this.props.locale,
            this.props.user.authToken,
            recaptcha
        )
            .then((result) => {
                this.setState({ isSubmitting: false });
                if (result.isSuccessful) {
                    if (this.props.updateUser) {
                        this.props.updateUser({
                            ...this.props.user,
                            isVerified: true,
                            isManageVerified: true,
                            authToken: result.authToken,
                        });
                    }
                    this.setState({ key: "enrollMfa" });
                }
            })
            .catch((err) => {
                const error = intlErrorTypeGruard(err)
                    ? this.props.intl.formatMessage({
                          id: err.messageId,
                          defaultMessage: err.message,
                      })
                    : err.message;

                this.setState({
                    error,
                    isSubmitting: false,
                });
            });
    };

    handleCancelMfa = () => {
        this.setState({ key: "enrollMfa" });
    };

    handleCancelVerify = () => {
        this.setState({ key: "mfa" });
    };

    handleResend = (factorId: string) => {
        this.setState({ isSubmitting: true, error: null });
        challenge(factorId, this.props.user.userId, this.props.locale)
            .then((result) => {
                this.setState({ isSubmitting: false });
                if (result.isSuccessful) {
                    this.setState({ factorId, key: "verifyMfa" });
                }
            })
            .catch((err) => {
                this.setState({ error: err.message, isSubmitting: false });
            });
    };

    render() {
        const { user } = this.props;
        let currentFactor = null;

        if (user.factors) {
            currentFactor = user.factors.find(
                (n) => n && n.factorId === this.state.factorId
            );
        }

        const canResend =
            currentFactor != null
                ? canFactorResend(currentFactor.factorInfo.factorType)
                : true;
        return (
            <IntlProvider
                locale={this.props.locale}
                messages={this.props.localeTranslations}
            >
                <Modal show={this.props.show} onHide={this.handleClose}>
                    <Modal.Header closeButton>
                        <Modal.Title>Manage Authentication Factors</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <BlockUi shouldRender={this.state.isSubmitting} />
                        {!!this.state.error && (
                            <Alert bsStyle="warning">{this.state.error}</Alert>
                        )}
                        {this.state.key === "enrollMfa" && (
                            <MultiFactorManagementPanel
                                header={
                                    MessageMultiFactorManagementInstructions
                                }
                                factors={this.props.user.factors}
                                onClose={this.handleClose}
                                onManageFactor={this.handleManageFactor}
                            />
                        )}
                        {this.state.key === "mfa" && (
                            <MultiFactorPanel
                                onClose={this.handleClose}
                                onVerifyFactor={this.handleChallenge}
                                factors={this.props.user.factors}
                            />
                        )}
                        {this.state.key === "activateMfa" && (
                            <MultiFactorCodeEntryPanel
                                canRemember={true}
                                factorId={this.state.factorId}
                                onClose={this.handleCancelMfa}
                                onOk={this.handleActivate}
                                onResend={this.handleResend}
                                canFactorResend={canResend}
                                totpInfo={currentFactor as IFactorTotp}
                            />
                        )}
                        {this.state.key === "verifyMfa" && (
                            <MultiFactorCodeEntryPanel
                                factorId={this.state.factorId}
                                onClose={this.handleCancelVerify}
                                onOk={this.handleVerify}
                                onResend={this.handleResend}
                                canFactorResend={canResend}
                            />
                        )}
                        {this.state.key === "enrollCall" && (
                            <MultiFactorEnrollCallPanel
                                onClose={this.handleCancelMfa}
                                onOk={this.handleEnrollCall}
                            />
                        )}
                        {this.state.key === "enrollSms" && (
                            <MultiFactorEnrollSmsPanel
                                onClose={this.handleCancelMfa}
                                onOk={this.handleEnrollSms}
                            />
                        )}
                    </Modal.Body>
                </Modal>
            </IntlProvider>
        );
    }
}

export default injectIntl(ManageFactorsModal);
