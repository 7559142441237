import React from "react";
import { FormattedMessage, injectIntl, InjectedIntlProps } from "react-intl";

interface MaintenancePageOwnProps {
    messages: MaintenanceMessage[];
}

export type MaintenancePageProps = MaintenancePageOwnProps & InjectedIntlProps;

interface MaintenanceMessage {
    text: string;
    languageId: number;
}

export enum Language {
    NotProvided = 0,
    en = 1,
    es = 2,
    zh = 3,
    vi = 4,
    hy = 5,
    ko = 6,
}

class MaintenancePage extends React.Component<MaintenancePageProps> {
    constructor(props: MaintenancePageProps) {
        super(props);
    }

    render() {
        const currentLang = this.props.intl.locale;
        let message: MaintenanceMessage | undefined;
        if (currentLang) {
            const lang = (Language as any)[currentLang!];
            message = this.props.messages.find(
                (x) => x.languageId == lang
            ) as MaintenanceMessage;
            if (!message) {
                message = this.getEnglishMessage(this.props.messages);
            }
        }
        return (
            <div className="maintenance-block">
                <h1 style={{ textAlign: "center" }}>
                    <FormattedMessage
                        id="text.maintenance.header"
                        defaultMessage="The system is under maintenance."
                    />
                </h1>
                <h3 style={{ textAlign: "center", marginTop: "40px" }}>
                    {message !== undefined ? message?.text : ""}
                </h3>
            </div>
        );
    }

    private getEnglishMessage(messages: MaintenanceMessage[]) {
        return messages.find((x) => x.languageId === Language.en);
    }
}

export default injectIntl(MaintenancePage);
