import React from "react";
import ShoppingCart from "../ShoppingCart";
import "../../../../css/SanDiegoBillSearch/searchPage.css";
import { InjectedIntlProps, injectIntl } from "react-intl";
import { IBill } from "../../../../types";
import { BillSearchCartHeaderTitle } from "../../../../resources/index";

interface IOwnProps {
    bills: IBill[];
    billTotal: number;
    deleteBill: (billId: string) => void;
}

class ShoppingCartWrapper extends React.Component<
    IOwnProps & InjectedIntlProps
> {
    constructor(props: IOwnProps & InjectedIntlProps) {
        super(props);
    }
    render() {
        const { intl } = this.props;
        return (
            <div className="panel-group" id="accordion">
                <ShoppingCart
                    bills={this.props.bills}
                    billTotal={this.props.billTotal}
                    deleteBill={this.props.deleteBill}
                    title={BillSearchCartHeaderTitle}
                    optionsTitle={intl.formatMessage({
                        id: "billSearchResult.option_title.unsecured",
                    })}
                />
            </div>
        );
    }
}

export default injectIntl(ShoppingCartWrapper);
