import { faSearch } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { Button } from "react-bootstrap";
import { BillSearchResultBtnViewBill } from "../../../../../resources";
import { IBill } from "../../../../../types/IBill";
import BillUtils from "../Tables/Mapper";
import "../../../../../css/SanDiegoBillSearch/searchPage.css";
import { BillInstallmentType, ICommonBill } from "../../../types";

interface ViewBillButtonProps {
    billType: string;
    row: ICommonBill;
    rowType?: string;
    handleViewBill: (bill: IBill) => void;
}

export class ViewBillButton extends React.Component<ViewBillButtonProps> {
    constructor(props: ViewBillButtonProps) {
        super(props);

        this.handleOnClick = this.handleOnClick.bind(this);
    }

    handleOnClick(e: React.MouseEvent<Button>) {
        e.preventDefault();

        const { row, billType, handleViewBill } = this.props;
        switch (row.type) {
            case "common": {
                const commonBill = BillUtils.getBillFromCommonBill(
                    row,
                    billType,
                    BillInstallmentType.balance
                );
                handleViewBill(commonBill);
                break;
            }
            case "withInstallments": {
                const billWithInstallments = BillUtils.getBillFromCommonBill(
                    row,
                    billType,
                    BillInstallmentType.first
                );
                handleViewBill(billWithInstallments);
                break;
            }
        }
    }

    render() {
        return (
            <Button
                onClick={this.handleOnClick}
                bsClass="btn btn-info btn-xs btn-adapt d-inline-block"
            >
                <FontAwesomeIcon icon={faSearch} className="fa-icon" />
                <span
                    aria-hidden="true"
                    className="hidden-xs inline-block align-top"
                >
                    {BillSearchResultBtnViewBill}
                </span>
            </Button>
        );
    }
}
