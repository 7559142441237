import { IApplication } from "../types";

export function getPaymentTerms(
    application: IApplication,
    merchantName: string,
    templateId: number,
    methodId: number,
    languageId: number,
    feeAmount: number
): Promise<string> {
    const baseUrl = process.env.REACT_APP_API_BASEURL;

    return fetch(
        `${baseUrl}/webapi/api/PaymentTerms?merchantName=${merchantName}&templateId=${templateId}&language=${languageId}&method=${methodId}&feeAmount=${feeAmount}`,
        {
            method: "POST",
            mode: baseUrl ? "cors" : "same-origin",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(application),
        }
    )
        .then((response) => response.json() as Promise<string>)
        .catch((error) => error);
}
