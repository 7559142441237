import React from "react";
import { RouteComponentProps } from "react-router-dom";
import { connect } from "react-redux";
import { setIsPaymentCompleted } from "../../actions/Application";
import { clear } from "../../actions/Common";
import {
    IApplication,
    ISettings,
    IApplicationRoute,
    IApplicationState,
    IBill
} from "../../types";

interface AppStateProps {
    application: IApplication;
    bills: IBill[];
    settings: ISettings;
    clear: VoidFunction;
    setIsPaymentCompleted: (isPaymentCompleted: boolean) => void;
}

export class App extends React.Component<
    AppStateProps & RouteComponentProps<IApplicationRoute>
> {
    public componentDidMount() {
        if (!this.props.match.params.merchantName) {
            this.props.history.push(`/InvalidRequest`);
        } else if (this.props.match.params.guid) {
            switch (this.props.application.securePayPaymentType) {
                case 1:
                    this.props.history.push(
                        `/${this.props.match.params.merchantName}/bills/${this.props.match.params.guid}`
                    );
                    break;
                case 2:
                    this.props.history.push(
                        `/${this.props.match.params.merchantName}/bills/${this.props.match.params.guid}`
                    );
                    break;
                case 3:
                    this.props.history.push(
                        `/${this.props.match.params.merchantName}/token/${this.props.match.params.guid}`
                    );
                    break;
                default:
                    this.props.history.push(
                        `/${this.props.match.params.merchantName}/InvalidRequest`
                    );
            }
        } else {
            const { setIsPaymentCompleted, clear, application: {isPaymentCompleted}} = this.props;
            if (isPaymentCompleted){
                clear();
                setIsPaymentCompleted(false);
            }
            if (
                (this.props.settings.billSearchType === "SanDiego" &&
                    this.props.bills.length !== 0) ||
                (this.props.bills.length === 0 &&
                    !this.props.settings.allowBlindPayments)
            ) {
                this.props.history.push(
                    `/${this.props.match.params.merchantName}/search`
                );
            } else {
                this.props.history.push(
                    `/${this.props.match.params.merchantName}/bills`, {shouldReset: true}
                );
            }
        }
    }

    public render() {
        return <div></div>;
    }
}

export default connect(
    (state: IApplicationState) =>
        ({
            application: state.application,
            bills: state.bills.bills,
            settings: state.settings,
        } as AppStateProps),{
            clear,
            setIsPaymentCompleted
        }
)(App);
