export type { IAccount, IAccounts, IAccountResponse } from "./IAccount";
export type { IAlertMessage } from "./IAlertMessage";
export type { IApiResponse } from "./IApiResponse";
export type { IApplication } from "./IApplication";
export type { IApplicationAnalytics } from "./IApplicationAnalytics";
export type { IApplicationBillTypes } from "./IApplicationBillTypes";
export type { IApplicationRoute } from "./IApplicationRoute";
export type { IApplicationState } from "./IApplicationState";
export type { IAppThunkAction } from "./IAppThunkAction";
export type { IBankInformation } from "./IBankInformation";
export type { IBill } from "./IBill";
export type {
  IBillDetail,
  IBillDetails,
  IBillDetailResponse,
} from "./IBillDetail";
export type { IBillImage, IBillImageResponse } from "./IBillImage";
export type { IBillRecord } from "./IBillRecord";
export type {
  ISearchCriteria,
  IBillSearchCriteria,
  BillSearchOption,
} from "./IBillSearchCriteria";
export type { IBillSearchResults } from "./IBillSearchResults";
export type { IBillType } from "./IBillType";
export type { IConfiguration } from "./IConfiguration";
export type { IConvenienceFee } from "./IConvenienceFee";
export type { ICpcData, ICpcDataRequest, ICpcDataResponse } from "./ICpcData";
export type { ICustomField } from "./ICustomField";
export type { IDateResponse } from "./IDateResponse";
export type { IGeneratedReceiptResponse } from "./IGeneratedReceiptResponse";
export type { IInternationalization } from "./IInternationalization";
export type { IKeyValue } from "./IKeyValue";
export type { ILoadable } from "./ILoadable";
export type { IMerchantBills } from "./IMerchantBills";
export type { IMyAccount } from "./IMyAccount";
export type { IObligor } from "./IObligor";
export type { IPayment } from "./IPayment";
export type { IPaymentTerms } from "./IPaymentTerms";
export type { IPayor } from "./IPayor";
export type { IPhoneValidationResponse } from "./IPhoneValidationResponse";
export type { IReceipt } from "./IReceipt";
export type { IResponse } from "./IResponse";
export type { ISecurePayData } from "./ISecurePayData";
export type { ISelectOption } from "./ISelectOption";
export type { ISettings, ILink } from "./ISettings";
export type { IToken } from "./IToken";
export type { ITransaction, ITransactions } from "./ITransaction";
export type { IUser, IProfile } from "./IUser";
export type { IUserAccount } from "./IUserAccount";
export type { IValidator } from "./IValidator";
export { PreferredContactEnum } from "./enums/PreferredContactEnum";
export { CardProcessingMethodEnum } from "./enums/CardProcessingMethodEnum";
export { PaymentMethodEnum } from "./enums/PaymentMethodEnum";
export { Key } from "./enums/Key";
export type { ITotpEnrollInfo, EnrollTypes } from "./IIdentityFactors";

/* export interface ILoadable {
    isLoading: boolean;
    isLoaded: boolean;
    error: string | null;
} */

export const DEVICE_ID = "webpayments_device_id";
