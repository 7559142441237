import React from "react";
import { connect } from "react-redux";
import { loadUserAccounts } from "../../actions/Account";
import { createOrUpdate, deleteBill } from "../../actions/Bill";
import { loadCustomerBillDetails } from "../../actions/BillDetails";
import { reset, search, update } from "../../actions/BillSearch";
import { loadImage } from "../../actions/BillType";
import { cancel, clear } from "../../actions/Common";
import { setIsPaymentCompleted } from "../../actions/Application";
import { logout, updateUser, userVerified } from "../../actions/User";
import { login } from "../../api/User";
import {
    IApplication,
    IApplicationRoute,
    IApplicationState,
    IBill,
    IBillRecord,
    IBillSearchCriteria,
    IBillSearchResults,
    IBillType,
    IInternationalization,
    ISettings,
} from "../../types";
import { IUser } from "../../types/IUser";
import SearchPanel from "./SearchPanel";
import { RouteComponentProps } from "react-router-dom";

export interface ISearchStateProps {
    application: IApplication;
    bills: IBill[];
    billTypes: IBillType[];
    blockUI: boolean;
    intl: IInternationalization;
    searchResults: IBillSearchResults;
    settings: ISettings;
  user: IUser;
}

export interface ISearchDispatchProps {
    addToBills: (bill: IBill) => void;
    billSearch: (
        merchantName: string,
        application: IApplication,
        billType: string,
        id1: string,
        id2: string,
        id3: string,
        id4: string,
        language: number,
        includePaidBills: boolean,
        address: string,
        postalCode: string,
        offset?: number,
        take?: number
    ) => void;
    cancel: VoidFunction;
    deleteBill: (billId: string) => void;
    loadImage: (
        application: IApplication,
        merchantName: string,
        billName: string,
        language: number
    ) => void;
    loadUserAccounts: (
        application: IApplication,
        merchantName: string,
        authToken: string,
        onError?: (text: string) => void
    ) => void;
    loadCustomerBillDetails: (
        merchantName: string,
        authToken: string,
        merchantCustomerId: string
    ) => void;
    login: (
        application: IApplication,
        merchantName: string,
        username: string,
        password: string,
        recaptcha?: string | null
    ) => Promise<IUser>;
    logout: VoidFunction;
    resetSearch: (criteria: IBillSearchCriteria) => void;
    updateSearchRecord: (results: IBillRecord) => void;
    updateUser: (user: IUser) => void;
    userVerified: (value: boolean) => void;
    clear: VoidFunction;
    setIsPaymentCompleted: (isPaymentCompleted: boolean) => void;
}

export type SearchProps = ISearchStateProps &
    ISearchDispatchProps &
    RouteComponentProps<IApplicationRoute>;

class Search extends React.Component<SearchProps> {

    componentDidMount() {
      const { setIsPaymentCompleted, clear, application: { isPaymentCompleted } } = this.props;
      if (isPaymentCompleted) {
        clear();
        setIsPaymentCompleted(false);
      }
    }
    render() {
        return (
            <div className="margin-top-20">
                <SearchPanel
                    {...this.props}
                    login={this.onLogin}
                    history={this.props.history}
                />
            </div>
        );
    }

    private onLogin = (
        userName: string,
        password: string,
        recaptcha?: string | null
    ): Promise<IUser> => {
        return login(
            this.props.application,
            this.props.settings.merchantName,
            userName,
            password,
            recaptcha
        );
    };
}

function mapStateToProps(state: IApplicationState) {
    return {
        intl: state.intl,
        user: state.user,
        application: state.application,
        settings: state.settings,
        bills: state.bills.bills,
        billTypes: state.billTypes.billTypes,
        searchResults: state.billSearch,
        blockUI: state.ajaxCounter && state.ajaxCounter.loadCount > 0,
    };
}
const mapDispatchToProps = {
    cancel,
    loadImage: loadImage,
    resetSearch: reset,
    billSearch: search,
    addToBills: createOrUpdate,
    deleteBill,
    updateSearchRecord: update,
    loadCustomerBillDetails,
    loadUserAccounts,
    updateUser,
    login,
    logout,
    userVerified,
    setIsPaymentCompleted,
    clear
};
export default connect(mapStateToProps, mapDispatchToProps)(Search);
