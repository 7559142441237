import { History } from "history";
import React from "react";
import { Button, ButtonToolbar, Col, Row } from "react-bootstrap";
import { setIsPaymentCompleted } from "../../actions/Application";
import { clear } from "../../actions/Common";
import { GetDataPostData } from "../../helpers/DataPost";
import {
    IApplication,
    IPayment,
    IPayor,
    ISettings,
    ITransaction,
} from "../../types";
import { Post } from "../../utils/Post";

export interface IFinishButtonProps {
    application: IApplication;
    settings: ISettings;
    history: History;
    payment: IPayment;
    payor: IPayor;
    transaction?: ITransaction;
    clear: VoidFunction;
    setIsPaymentCompleted: (isPaymentCompleted: boolean) => void;
}

export class FinishButton extends React.Component<IFinishButtonProps> {
    constructor(props: IFinishButtonProps) {
        super(props);
    }

    handleClick = (event: any) => {
        event.preventDefault();
        const {
            application,
            settings,
            payor,
            payment,
            transaction,
            clear,
            setIsPaymentCompleted,
        } = this.props;
        clear();
        setIsPaymentCompleted(false);
        if (application.id === 33) {
            this.props.history.push(`/${settings.merchantName}`);
        } else {
            const data = GetDataPostData(
                application,
                payor,
                payment,
                transaction
            );
            if (application.id === 35) {
                Post(settings.securePayMOTOReturnUrl, data);
            } else {
                Post(settings.returnUrl, data);
            }
        }
    };

    render() {
        return (
            <Row>
                <Col xs={12}>
                    <div className="pull-right">
                        <ButtonToolbar>
                            <Button
                                bsStyle="primary"
                                style={{ minWidth: "6.5em" }}
                                onClick={this.handleClick}
                            >
                                {this.props.settings.receiptFinishButtonCaption}
                            </Button>
                        </ButtonToolbar>
                    </div>
                </Col>
            </Row>
        );
    }
}
