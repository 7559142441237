import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faExclamationTriangle,
    faEnvelope,
} from "@fortawesome/free-solid-svg-icons";
import { Row, Col, Panel, Form, Button, Alert, Well } from "react-bootstrap";
import { EmailInput } from "../../Input";
import { BlockUi } from "../BlockUi";
import { forgotPassword } from "../../../api/Account";
import Recaptcha from "react-google-recaptcha";
import {
    CheckFormValidity,
    CheckFieldValidity,
} from "../../../utils/Validation";
import {
    MessageRegisterFinishInstructions,
    MessageForgotPasswordInstructions,
    MessageResetEmailSent,
    MessageErrorProcessingRequest,
    ButtonSend,
} from "../../../resources";
import { injectIntl, InjectedIntlProps } from "react-intl";
import { IApplication, IUser } from "../../../types";
import { IFormError } from "../../../types/IFormError";
import { RECAPTCHA_SITEKEY } from "../../../core/constants";

export interface ForgotPasswordProps extends InjectedIntlProps {
    application: IApplication;
    merchantName: string;
    requireRecaptcha: boolean;
    requirePasswordReset: boolean;
    onRecaptchaLoaded: () => void;
    handleOnClose?: () => void;
    updateUser?: (user: IUser) => void;
}

class ForgotPasswordPanel extends React.Component<ForgotPasswordProps, any> {
    private recaptcha = React.createRef<Recaptcha>();

    constructor(props: any) {
        super(props);
        this.state = {
            user: { username: "" },
            errors: {},
        };
        this.onResolved = this.onResolved.bind(this);
        this.handleBlur = this.handleBlur.bind(this);
        this.handleError = this.handleError.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleClose = this.handleClose.bind(this);
    }
    handleBlur(event: any) {
        CheckFieldValidity(event.target, this.handleError);
    }

    private handleFormErrors = (errors: IFormError): void => {
        this.setState({
            errors: {
                ...this.state.errors,
                ...errors,
            },
        });
    };

    handleError(name: any, message: any) {
        return this.setState({
            errors: {
                ...this.state.errors,
                [name]: message,
            },
        });
    }
    handleChange(event: any) {
        if (event.target.validity.valid) {
            CheckFieldValidity(event.target, this.handleError);
        }
        return this.setState({
            user: {
                ...this.state.user,
                [event.target.name]: event.target.value,
            },
        });
    }
    async handleSubmit(event: any) {
        event.preventDefault();
        if (!CheckFormValidity("forgot-password-form", this.handleFormErrors)) {
            return false;
        }
        this.setState({
            submitted: false,
            submitting: true,
            error: null,
            success: null,
        });
        const {
            application: { id },
            requireRecaptcha,
        } = this.props;
        if (requireRecaptcha) {
            const token = await this.recaptcha.current?.executeAsync();

            this.onResolved(token);
        } else {
            this.onResolved();
        }
        return true;
    }

    handleClose = (event: any) => {
        event.preventDefault();
        if (this.props.handleOnClose) {
            this.props.handleOnClose();
        }
    };

    onResolved(token?: string | null) {
        const { application, requirePasswordReset } = this.props;
        const {
            user: { username },
        } = this.state;
        //const recaptcha = this.recaptcha.getResponse();

        forgotPassword(
            application,
            this.props.merchantName,
            username,
            requirePasswordReset,
            token
        )
            .then((result: any) => {
                if (result.isSuccessful) {
                    this.setState({
                        submitting: false,
                        submitted: true,
                        success: true,
                    });
                } else {
                    this.setState({
                        submitting: false,
                        error: result.messages[0] || {
                            MessageErrorProcessingRequest,
                        },
                    });
                }
                this.recaptcha.current?.reset();
            })
            .catch((reason: any) => {
                this.recaptcha.current?.reset();
                this.setState({
                    submitting: false,
                    error: { MessageErrorProcessingRequest },
                });
            });
    }
    render() {
        const { user, submitting, submitted, error, success, errors } =
            this.state;
        const forgotPasswordMessage = this.props.requirePasswordReset
            ? MessageRegisterFinishInstructions
            : MessageForgotPasswordInstructions;
        return (
            <div>
                <BlockUi shouldRender={submitting} />
                <Panel>
                    <Panel.Body>
                        <Row>
                            <Col xs={12} sm={12} md={12} lg={12}>
                                <Form id="forgot-password-form">
                                    <Row>
                                        <Col xs={12}>
                                            <Well>{forgotPasswordMessage}</Well>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col xs={12}>
                                            <EmailInput
                                                type="email"
                                                name="username"
                                                placeholder="example@domain.com"
                                                label="Email Address"
                                                value={user.username}
                                                error={errors.username}
                                                onChange={this.handleChange}
                                                onError={this.handleError}
                                                onBlur={this.handleError}
                                                required
                                            />
                                        </Col>
                                    </Row>
                                    {error && (
                                        <Row>
                                            <Col xs={12} sm={12} md={12}>
                                                <Alert bsStyle="danger">
                                                    <FontAwesomeIcon
                                                        icon={
                                                            faExclamationTriangle
                                                        }
                                                    />{" "}
                                                    {error}
                                                </Alert>
                                            </Col>
                                        </Row>
                                    )}
                                    {success && (
                                        <Row>
                                            <Col xs={12} sm={12} md={12}>
                                                <Alert bsStyle="success">
                                                    <FontAwesomeIcon
                                                        icon={faEnvelope}
                                                    />{" "}
                                                    {MessageResetEmailSent}
                                                </Alert>
                                            </Col>
                                        </Row>
                                    )}
                                    {success && (
                                        <Row>
                                            <Col xs={12}>
                                                <Button
                                                    bsStyle="primary"
                                                    onClick={this.handleClose}
                                                    style={{
                                                        minWidth: "6.5em",
                                                    }}
                                                    block
                                                >
                                                    Close
                                                </Button>
                                            </Col>
                                        </Row>
                                    )}
                                    {!submitted && (
                                        <Row>
                                            <Col xs={12}>
                                                <Button
                                                    type="submit"
                                                    bsStyle="primary"
                                                    onClick={this.handleSubmit}
                                                    disabled={submitting}
                                                    style={{
                                                        minWidth: "6.5em",
                                                    }}
                                                    block
                                                >
                                                    {ButtonSend}
                                                </Button>
                                            </Col>
                                        </Row>
                                    )}
                                </Form>
                            </Col>
                            <div style={{ display: "none" }}>
                                <Recaptcha
                                    ref={this.recaptcha}
                                    hl={this.props.intl.locale}
                                    size="invisible"
                                    sitekey={RECAPTCHA_SITEKEY}
                                    asyncScriptOnLoad={
                                        this.props.onRecaptchaLoaded
                                    }
                                />
                            </div>
                        </Row>
                    </Panel.Body>
                </Panel>
            </div>
        );
    }
}

export default injectIntl(ForgotPasswordPanel);
