import React from "react";
import { Alert } from "react-bootstrap";
import { connect } from "react-redux";
import { RouteComponentProps } from "react-router-dom";
import { cancel, clear } from "../../actions/Common";
import { setIsPaymentCompleted } from "../../actions/Application";
import {
    loadPaymentTerms,
    updateAccepted,
    updateCaptcha,
} from "../../actions/PaymentTerms";
import {
    IApplication,
    IApplicationRoute,
    IApplicationState,
    IBill,
    IInternationalization,
    IPayment,
    IPaymentTerms,
    IPayor,
    ISettings,
} from "../../types";
import TokenForm from "./TokenForm";

function InitializePayment(state: any) {
    const payor = state.payor;
    const settings = state.settings;
    const payment = Object.assign({}, state.payment);
    if (!settings.creditIsAccepted && payment.method === "credit") {
        if (settings.debitIsAccepted) {
            payment.method = "debit";
        } else {
            payment.method = "checking";
        }
    } else if (!settings.debitIsAccepted && payment.method === "debit") {
        if (settings.creditIsAccepted) {
            payment.method = "credit";
        } else {
            payment.method = "checking";
        }
    } else if (!settings.achIsAccepted && payment.method === "checking") {
        if (settings.creditIsAccepted) {
            payment.method = "credit";
        } else {
            payment.method = "debit";
        }
    } else {
        if (settings.creditIsAccepted) {
            payment.method = "credit";
        } else if (settings.debitIsAccepted) {
            payment.method = "debit";
        } else {
            payment.method = "checking";
        }
    }
    if (payor) {
        if (settings.businessNameIsVisible) {
            payment.business = payor.business;
        }
        payment.firstName = payor.firstName;
        payment.middleName = payor.middleName;
        payment.lastName = payor.lastName;
        payment.address = payor.address;
        payment.country = payor.phoneCountry;
        payment.state = payor.state;
        payment.city = payor.city;
        payment.postal = payor.postal;
        payment.phone = payor.phone;
        payment.email = payor.email;
    }
    return payment;
}

interface TokenPageStateProps {
    intl: IInternationalization;
    application: IApplication;
    settings: ISettings;
    bills: IBill[];
    paymentTerms: IPaymentTerms;
    shareTokenWithGroup: boolean;
    payor: IPayor;
    initialPayment: IPayment;
}

interface ITokenPageDispatchProps {
    loadPaymentTerms: (
        application: IApplication,
        merchantName: string,
        templateId: number,
        methodId: number,
        languageId: number,
        feeAmount: number
    ) => void;
    updateAccepted: (value: boolean) => void;
    updateCaptcha: (value: string) => void;
    cancel: VoidFunction;
    clear: VoidFunction;
    setIsPaymentCompleted: (isPaymentCompleted: boolean) => void;
}

export type TokenPageProps = TokenPageStateProps &
    ITokenPageDispatchProps &
    RouteComponentProps<IApplicationRoute>;

class TokenPage extends React.Component<TokenPageProps> {
    constructor(props: TokenPageProps) {
        super(props);
    }

    public componentDidMount() {
        if (
            this.props.application.securePayPaymentType !== 3 ||
            !this.props.match.params.guid
        ) {
            this.props.history.push(
                `/${this.props.match.params.merchantName}/InvalidRequest`
            );
        }
    }

    render() {
        return (
            <div className="margin-top-20">
                {!!this.props.settings.webPaymentsAppMessage && (
                    <Alert bsStyle="warning" style={{ margin: 0 }}>
                        {this.props.settings.webPaymentsAppMessage}
                    </Alert>
                )}
                <TokenForm {...this.props} locale={this.props.intl.locale} />
            </div>
        );
    }
}

export default connect(
    (state: IApplicationState) => ({
        intl: state.intl,
        application: state.application,
        settings: state.settings,
        bills: state.bills.bills,
        shareTokenWithGroup: state.bills.shareTokenWithGroup,
        paymentTerms: state.paymentTerms,
        payor: state.payor,
        initialPayment: InitializePayment(state),
    }),
    {
        cancel,
        clear,
        loadPaymentTerms,
        updateAccepted,
        updateCaptcha,
        setIsPaymentCompleted,
    }
)(TokenPage);
