import React from "react";
import { Button, Form } from "react-bootstrap";
import { injectIntl } from "react-intl";
import { connect } from "react-redux";
import {
    reset,
    search,
    updateCriteria,
    newBillsearchSession,
} from "../../actions/BillSearch";
import "../../css/SanDiegoBillSearch/searchPage.css";
import * as Resources from "../../resources";
import { IApplicationState, IBillSearchCriteria, IBillType } from "../../types";
import { CheckFieldValidity } from "../../utils/Validation";
import { PostalInput, SelectInput, TextInput } from "../Input";
import cities from "./countrySource.json";
import { IComponentProps, SearchByOptionsBase } from "./SearchByOptionsBase";
import { SanDiegoBillType } from "./types";
import { clearBills, createOrUpdate, deleteBill } from "../../actions/Bill";

export interface StepperCity {
    code: string;
    name: string;
}

class SearchByMailingAddressBlock extends SearchByOptionsBase {
    protected SearchBlockGroup = "SEARCH_BY_MAILING_ADDRESS";
    private usCountryCode = "USA";

    constructor(props: IComponentProps) {
        super(props);

        this.formId = "search-by-mailing-address-block-form";

        this.props.updateCriteria({
            ...this.props.criteria,
            address: "",
            postalCode: "",
            billType: "",
            billSearchType: this.props.settings.billSearchType,
            meta: {
                searchType: "MANUAL",
                searchGroup: "BILL_NUMBER",
                searchCriteria: {
                    parcelNumber: "",
                    mailingAddress: "",
                    countryCode: this.usCountryCode,
                    zipCode: "",
                    year: "",
                    billNumber: "",
                },
            },
        });
    }

    handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        event.preventDefault();

        if (!event.target.validity.valid) {
            CheckFieldValidity(event.target, this.handleError);
        }

        const field = event.target.name;
        const searchCriteria = { ...this.props.criteria.meta.searchCriteria };

        switch (field) {
            case "address":
                searchCriteria.mailingAddress = event.target.value;
                break;
            case "country":
                searchCriteria.countryCode = event.target.value;
                if (searchCriteria.countryCode != this.usCountryCode) {
                    searchCriteria.zipCode = "";
                    this.state.errors["postalCode"] = null;
                }
                break;
            case "postalCode":
                searchCriteria.zipCode = event.target.value;
                break;
        }

        this.props.updateCriteria({
            ...this.props.criteria,
            id1: "",
            address: searchCriteria.mailingAddress,
            postalCode: searchCriteria.zipCode,
            billType: "",
            billSearchType: this.props.settings.billSearchType,
            meta: {
                searchType: "MANUAL",
                searchGroup: "SEARCH_BY_MAILING_ADDRESS",
                searchCriteria: searchCriteria,
            },
        });
    };

    render() {
        const { intl, criteria, billTypes } = this.props;
        const { errors } = this.state;
        const selectionCities = cities.map((city: StepperCity) => ({
            text: city.name,
            value: city.code,
        }));
        const billType = billTypes.billTypes.find(
            (s: IBillType) => s.name === SanDiegoBillType.secured
        );

        if (!billType) {
            return <h1> Error occured </h1>;
        }

        const { id1 } = billType as IBillType;

        const showPostalCode =
            criteria.meta.searchCriteria.countryCode == this.usCountryCode;
        const searchError = this.getSearchError();
        return (
            <Form id={this.formId}>
                <div className="row">
                    <div className="col-xs-12 col-sm-4">
                        <TextInput
                            name="address"
                            className="input-width-220p"
                            label={Resources.BillSearchOption2MailField}
                            value={criteria.meta.searchCriteria.mailingAddress}
                            error={searchError || errors.address}
                            onChange={this.handleChange}
                            onBlur={this.handleBlur}
                            onError={this.handleError}
                            onKeyDown={this.handleKeyDown}
                            pattern={this.getRegExp(id1.pattern)}
                            required={true}
                        />
                        {Resources.BillSearchOption2MailFieldHelp}
                    </div>

                    <div className="col-xs-12 col-sm-4">
                        <SelectInput
                            name="country"
                            label={Resources.BillSearchOption2countryField}
                            value={criteria.meta.searchCriteria.countryCode}
                            error={searchError || errors.country}
                            onChange={this.handleChange}
                            onError={this.handleError}
                            onKeyDown={this.handleKeyDown}
                            options={selectionCities}
                            required={true}
                        />
                    </div>

                    {showPostalCode && (
                        <div className="col-xs-12 col-sm-4">
                            <PostalInput
                                name="postalCode"
                                label={Resources.BillSearchOption2ZipCode}
                                disabled={false}
                                className="input-width-220p"
                                country={
                                    criteria.meta.searchCriteria.countryCode
                                }
                                value={criteria.meta.searchCriteria.zipCode}
                                error={searchError || errors.postalCode}
                                onBlur={this.handleBlur}
                                onChange={this.handleChange}
                                onError={this.handleError}
                                onKeyDown={this.handleKeyDown}
                                required={true}
                                blacklist={[]}
                                sameAsPayor={true}
                            />
                            {Resources.BillSearchOption2ZipCodeHelp}
                        </div>
                    )}
                </div>
                <div className="row mt-10p">
                    <div className="col-xs-12 col-sm-4 col-md-6 ">
                        <Button
                            bsClass="btn btn-large btn-primary text-center btn-custom"
                            onClick={this.handleSearch}
                        >
                            {intl.formatMessage({
                                id: "billSearch.option.btn",
                            })}
                        </Button>
                    </div>
                </div>
            </Form>
        );
    }
}

export default injectIntl(
    connect(
        (state: IApplicationState) => ({
            merchantName: state.settings.merchantName,
            application: state.application,
            criteria: state.billSearch.criteria || ({} as IBillSearchCriteria),
            billTypes: state.billTypes,
            searchResult: state.billSearch,
            settings: state.settings,
            billSearchResult: state.billSearch,
            billsInCartAny: state.bills.bills.length > 0,
        }),
        {
            clearBills,
            newBillsearchSession,
            search,
            updateCriteria,
            reset,
        }
    )(SearchByMailingAddressBlock)
);
