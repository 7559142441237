import {
  MyAccountActions,
  MYACCOUNT_SET,
  MYACCOUNT_SELECT,
} from "../actionTypes/MyAccount";
import { IAppThunkAction } from "../types";

export const setActive =
  (active: boolean): IAppThunkAction<MyAccountActions> =>
  (dispatch) => {
    dispatch({
      type: MYACCOUNT_SET,
      data: active,
    });
  };

export const selectAccount =
  (selected: string): IAppThunkAction<MyAccountActions> =>
  (dispatch) => {
    dispatch({
      type: MYACCOUNT_SELECT,
      data: selected,
    });
  };
