import { History } from "history";
import React from "react";
import { Button, Panel, Form } from "react-bootstrap";
import * as Resources from "../../resources";
import {
    IApplication,
    IBill,
    IBillType,
    IInternationalization,
    ISettings,
    IUser,
} from "../../types";
import { CancelButton } from "../Input";
import { OverLimitAlert } from "../Layout/Alerts/OverLimitAlert";
import { UnderLimitAlert } from "../Layout/Alerts/UnderLimitAlert";
import BillTotal from "../Layout/BillTotal";
import { ButtonBar } from "../Layout/ButtonBar";
import { BillGrid } from "./BillGrid";
import { BillModal } from "./BillModal";
import { IFormError } from "../../types/IFormError";
import { CheckFormValidity } from "../../utils/Validation";

export interface MultipleBillProps {
    bills: IBill[];
    billTotal: number;
    application: IApplication;
    intl: IInternationalization;
    settings: ISettings;
    billTypes: IBillType[];
    user: IUser;
    history: History;
    params: { merchantName: string; guid?: string };
    cancel: () => void;
    saveBill: (bill: IBill) => void;
    deleteBill: (billId: string) => void;
    loadImage: (
        application: IApplication,
        merchantName: string,
        billName: string,
        language: number
    ) => void;
    loadBillImage: (
        merchantName: string,
        billId: string,
        application: IApplication
    ) => void;
    showLoginModal: () => void;
    onContinue: () => void;
}

export interface MultipleBillState {
    errors: IFormError;
    showBillModal: boolean;
}

export class MultipleBill extends React.Component<
    MultipleBillProps,
    MultipleBillState
> {
    private formId = "multi-bill-form";

    constructor(props: MultipleBillProps) {
        super(props);

        this.state = {
            showBillModal: false,
            errors: {},
        };

        this.hideBillModal = this.hideBillModal.bind(this);
        this.showBillModal = this.showBillModal.bind(this);
        this.onBackButton = this.onBackButton.bind(this);
        this.onPayButtonClick = this.onPayButtonClick.bind(this);
        this.loadImageForBill = this.loadImageForBill.bind(this);
    }

    public componentDidMount(): void {
        const { application, settings, bills, user } = this.props;

        if (application.guid && application.expired) {
            const path = `/${settings.merchantName}/expired/${application.guid}`;
            this.props.history.push(path);
            return;
        }

        if (application.guid && application.canceled) {
            const path = `/${settings.merchantName}/canceled/${application.guid}`;
            this.props.history.push(path);
            return;
        }

        if (bills.length === 0 && !settings.allowBlindPayments) {
            const path = `/${settings.merchantName}/search`;
            this.props.history.push(path);
            return;
        }
    }

    private isUnderPaymentLimit(): boolean {
        return (
            this.props.bills &&
            this.props.bills.length > 0 &&
            this.props.settings.minPaymentAmount > this.props.billTotal
        );
    }

    private isOverPaymentLimit(): boolean {
        return (
            this.props.bills &&
            this.props.bills.length > 0 &&
            this.props.settings.maxPaymentAmount < this.props.billTotal
        );
    }

    private showBillModal(): void {
        this.setState({ showBillModal: true });
    }

    private hideBillModal(): void {
        this.setState({ showBillModal: false });
    }

    private onBackButton(event: React.MouseEvent<Button>): void {
        const path = `/${this.props.settings.merchantName}/search`;

        this.props.history.push(path);
    }

    private loadImageForBill(merchantName: string) {
        return (billId: string) => {
            return this.props.loadBillImage(
                merchantName,
                billId,
                this.props.application
            );
        };
    }

    private onPayButtonClick(event: React.MouseEvent<Button>): void {
        event.preventDefault();

        const { application, settings, user } = this.props;
        if (application.isEBPP && settings.allowBillAmountToBeEdited) {
            if (!CheckFormValidity(this.formId, this.handleFormErrors)) {
                return;
            }
        }

        settings.enableMyAccount &&
        application.id === 33 &&
        !(user && user.isLoggedIn)
            ? this.props.showLoginModal()
            : this.props.onContinue();
    }

    private handleFormErrors = (errors: IFormError): void => {
        this.setState({
            errors: {
                ...this.state.errors,
                ...errors,
            },
        });
    };

    private onError = (name: string, message: string) => {
        const updatedState = { [name]: message };

        return this.setState({
            errors: { ...this.state.errors, ...updatedState },
        });
    };

    public render(): JSX.Element {
        const { bills, application, settings, billTotal } = this.props;
        const buttonText = settings.addButtonPrompt || Resources.ButtonAddBill;
        const addBillText =
            settings.addButtonPrompt ||
            (bills.length === 0
                ? Resources.ButtonAddBill
                : Resources.ButtonAddBills);
        const hideBillInfo =
            settings.requireLoginEBPP &&
            application.isEBPP &&
            !this.props.user.isLoggedIn
                ? true
                : false;
        const isUnderLimit = this.isUnderPaymentLimit();
        const isOverLimit = this.isOverPaymentLimit();
        const isPayButtonDisabled =
            application.isEBPP && settings.allowBillAmountToBeEdited
                ? bills.length === 0 ||
                  isUnderLimit ||
                  isOverLimit ||
                  (billTotal === 0 && !settings.allowBillOverpayment)
                : bills.length === 0 ||
                  isUnderLimit ||
                  isOverLimit ||
                  billTotal === 0;
        const cancelButton = (
            <CancelButton
                disabled={this.props.bills.length === 0}
                application={this.props.application}
                settings={this.props.settings}
                history={this.props.history}
                cancel={this.props.cancel}
            />
        );
        const payButton = (
            <Button
                bsClass="btn btn-success"
                disabled={isPayButtonDisabled}
                onClick={this.onPayButtonClick}
                style={{ minWidth: "6.5em" }}
            >
                {Resources.ButtonContinueBillPage} <BillTotal bills={bills} />
            </Button>
        );
        const actionButton =
            application.id === 33 &&
            settings.allowBlindPayments &&
            !application.isEBPP ? (
                <Button
                    bsStyle="primary"
                    onClick={this.showBillModal}
                    style={{ minWidth: "6.5em" }}
                >
                    {addBillText}
                </Button>
            ) : application.id === 33 &&
              !settings.allowBlindPayments &&
              !application.isEBPP ? (
                <Button bsStyle="default" onClick={this.onBackButton}>
                    {Resources.ButtonBack}
                </Button>
            ) : null;
        return (
            <div>
                {application.id === 33 && settings.allowBlindPayments && (
                    <p>{Resources.MessageGreeting(buttonText)}</p>
                )}
                {!hideBillInfo && (
                    <Panel bsStyle="primary">
                        <Panel.Heading>
                            <Panel.Title componentClass="h3">
                                {settings.billsToPayPrompt}
                            </Panel.Title>
                        </Panel.Heading>
                        <Panel.Body>
                            <Form inline id={this.formId}>
                                <BillGrid
                                    intl={this.props.intl}
                                    billTypes={this.props.billTypes}
                                    bills={this.props.bills}
                                    isEBPP={this.props.application.isEBPP}
                                    showEdit={
                                        application.id === 33 &&
                                        !application.isEBPP
                                    }
                                    onDelete={this.props.deleteBill}
                                    onError={this.onError}
                                    errors={this.state.errors}
                                    saveBill={this.props.saveBill}
                                    loadImageForBill={this.loadImageForBill(
                                        this.props.settings.merchantName
                                    )}
                                    maxPaymentAmount={
                                        this.props.settings.maxPaymentAmount
                                    }
                                    minPaymentAmount={
                                        this.props.settings.minPaymentAmount
                                    }
                                    allowBillOverpayment={
                                        this.props.settings.allowBillOverpayment
                                    }
                                    allowBillAmountToBeEdited={
                                        this.props.settings
                                            .allowBillAmountToBeEdited
                                    }
                                />
                                {isOverLimit && (
                                    <OverLimitAlert
                                        amount={settings.maxPaymentAmount}
                                    />
                                )}
                                {isUnderLimit && (
                                    <UnderLimitAlert
                                        amount={settings.minPaymentAmount}
                                    />
                                )}
                                <ButtonBar
                                    cancelButton={cancelButton}
                                    leftActionButton={actionButton}
                                    middleActionButton={null}
                                    rightActionButton={payButton}
                                />
                            </Form>
                        </Panel.Body>
                    </Panel>
                )}
                <BillModal
                    application={this.props.application}
                    billTypes={this.props.billTypes}
                    billInstructionsPrompt={
                        this.props.settings.billInstructionsPrompt
                    }
                    merchantName={this.props.settings.merchantName}
                    locale={this.props.intl.locale}
                    showModal={this.state.showBillModal}
                    maxAmount={settings.maxPaymentAmount}
                    close={this.hideBillModal}
                    saveBill={this.props.saveBill}
                    loadImage={this.props.loadImage}
                />
            </div>
        );
    }
}
