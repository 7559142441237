import React from "react";
import "../../../../css/SanDiegoBillSearch/searchPage.css";
import { groupBy } from "lodash";
import {
    IBill,
    IBillType,
    IBillRecord,
    IBillSearchCriteria,
    ISettings,
} from "../../../../types";
import { ICommonBill, SanDiegoBillType } from "../../types";
import {
    DefaultedBillsTable,
    SecuredBillsTable,
    SupplementalBillsTable,
    UnsecuredBillsTable,
} from "../..";
import { RouterProps } from "react-router";

interface IOwnProps {
    settings: ISettings;
    billTypes: IBillType[];
    selectedBills: IBill[];
    bills: IBillRecord[];
    mappedBills?: ICommonBill[];
    addToCart: (row: IBill) => void;
    handleViewBill: (bill: IBill) => void;
    criteria: IBillSearchCriteria | null;
    merchantName: string;
}

type IComponentProps = IOwnProps & RouterProps;

class SearchResultWrapper extends React.Component<IComponentProps> {
    constructor(props: IComponentProps) {
        super(props);
    }

    render() {
        const {
            selectedBills,
            addToCart,
            handleViewBill,
            mappedBills,
            billTypes,
        } = this.props;
        const groupedBills = groupBy(mappedBills, "billType");
        const groupedBillsComponents = [] as JSX.Element[];

        if (
            Object.hasOwnProperty.call(groupedBills, SanDiegoBillType.secured)
        ) {
            groupedBillsComponents.push(
                <SecuredBillsTable
                    bills={groupedBills[SanDiegoBillType.secured]}
                    selectedBills={selectedBills}
                    addToCart={addToCart}
                    billType={
                        billTypes.filter(
                            (x) => x.name == SanDiegoBillType.secured
                        )[0]
                    }
                    handleViewBill={handleViewBill}
                    key={SanDiegoBillType.secured}
                ></SecuredBillsTable>
            );
        }

        if (
            Object.hasOwnProperty.call(
                groupedBills,
                SanDiegoBillType.supplemental
            )
        ) {
            groupedBillsComponents.push(
                <SupplementalBillsTable
                    bills={groupedBills[SanDiegoBillType.supplemental]}
                    selectedBills={selectedBills}
                    addToCart={addToCart}
                    billType={
                        billTypes.filter(
                            (x) => x.name == SanDiegoBillType.supplemental
                        )[0]
                    }
                    handleViewBill={handleViewBill}
                    key={SanDiegoBillType.supplemental}
                ></SupplementalBillsTable>
            );
        }

        if (
            Object.hasOwnProperty.call(groupedBills, SanDiegoBillType.defaulted)
        ) {
            groupedBillsComponents.push(
                <DefaultedBillsTable
                    bills={groupedBills[SanDiegoBillType.defaulted]}
                    selectedBills={selectedBills}
                    addToCart={addToCart}
                    billType={
                        billTypes.filter(
                            (x) => x.name == SanDiegoBillType.defaulted
                        )[0]
                    }
                    handleViewBill={handleViewBill}
                    key={SanDiegoBillType.defaulted}
                ></DefaultedBillsTable>
            );
        }

        if (
            Object.hasOwnProperty.call(groupedBills, SanDiegoBillType.unsecured)
        ) {
            groupedBillsComponents.push(
                <UnsecuredBillsTable
                    bills={groupedBills[SanDiegoBillType.unsecured]}
                    selectedBills={selectedBills}
                    addToCart={addToCart}
                    billType={
                        billTypes.filter(
                            (x) => x.name == SanDiegoBillType.unsecured
                        )[0]
                    }
                    handleViewBill={handleViewBill}
                    key={SanDiegoBillType.unsecured}
                ></UnsecuredBillsTable>
            );
        }

        return (
            <div className="panel-group" id="accordion">
                {groupedBillsComponents}
            </div>
        );
    }
}

export default SearchResultWrapper;
