import {
    faExclamationTriangle,
    faQuestionCircle,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import {
    Alert,
    Checkbox,
    Col,
    Image,
    InputGroup,
    OverlayTrigger,
    Panel,
    Popover,
    Row,
} from "react-bootstrap";
import { InjectedIntlProps, injectIntl } from "react-intl";
import * as Resources from "../../resources";
import {
    IAccount,
    IApplication,
    IBankInformation,
    IPayment,
    ISettings,
} from "../../types";
import { IFormError } from "../../types/IFormError";
import { CheckFieldValidity, routingNumberCheck } from "../../utils/Validation";
import {
    NumberInput,
    RoutingNumberInput,
    SelectInput,
    TextInput,
} from "../Input";
import FeeTable from "./FeeTable";
import FutureDatePayment from "./FutureDatePayment";

import sampleCheck from "../../images/sample-check.svg";
import sampleCheckBusiness from "../../images/sample-check-business.svg";

interface IBankAccountPanelState {
    accountType: string;
    confirmAccountType: string;
    options: { value: string; text: string }[];
}

interface IBankAccountPanelProps {
    application: IApplication;
    bankInformation?: IBankInformation;
    billTotal?: number;
    errors: IFormError;
    hideConfirmAccount?: boolean;
    hideFeePanel: boolean;
    hideFutureDated: boolean;
    hideRoutingNumber?: boolean;
    isLoggedIn?: boolean;
    locale: string;
    maxFuturePaymentDays?: number;
    merchant: ISettings;
    payment: IPayment | IAccount;
    readOnlyField?: boolean;
    showRememberAccount?: boolean;
    calculateFee?: (
        application: IApplication,
        merchantName: string,
        language: string,
        amount: number,
        paymentMethod: number,
        cardProcessingMethod: number | null,
        cardNumber: string | null
    ) => void;
    clearField: (field: string) => void;
    loadAutoPayTerms?: (
        application: IApplication,
        merchantName: string,
        templateId: number,
        methodId: number,
        languageId: number
    ) => void;
    onBlur: (event: any) => void;
    onChange: (event: any) => void;
    onChecked?: (event: any) => void;
    onError: (name: any, message: any) => void;
}

type BankAccountPanelProps = IBankAccountPanelProps & InjectedIntlProps;

class BankAccountPanel extends React.Component<
    BankAccountPanelProps,
    IBankAccountPanelState
> {
    private confirmInput: any;
    public static defaultProps: Partial<BankAccountPanelProps> = {
        hideFeePanel: false,
        isLoggedIn: false,
    };

    constructor(props: BankAccountPanelProps) {
        super(props);
        const { merchant } = this.props;
        this.state = {
            accountType: "password",
            confirmAccountType: "password",
            options: this.buildOptions(merchant.onlyChecking),
        };
        this.handleBlur = this.handleBlur.bind(this);
        this.handleFocus = this.handleFocus.bind(this);
        this.handleIgnore = this.handleIgnore.bind(this);
        this.handleBlurGetFee = this.handleBlurGetFee.bind(this);
    }

    componentWillReceiveProps(nextProps: any) {
        if (this.props !== nextProps) {
            const { merchant } = this.props;
            this.setState({
                options: this.buildOptions(merchant.onlyChecking),
            });
        }
    }

    buildOptions(onlyChecking: any) {
        const {
            intl: { formatMessage },
        } = this.props;
        let options = [
            {
                value: "personalChecking",
                text: formatMessage({ id: "option.personalChecking" }),
            },
            {
                value: "businessChecking",
                text: formatMessage({ id: "option.businessChecking" }),
            },
        ];
        if (!onlyChecking) {
            options = [
                ...options,
                {
                    value: "personalSavings",
                    text: formatMessage({ id: "option.personalSavings" }),
                },
                {
                    value: "businessSavings",
                    text: formatMessage({ id: "option.businessSavings" }),
                },
            ];
        }
        return options;
    }

    handleIgnore(event: any) {
        event.preventDefault();
    }

    handleFocus(event: any) {
        if (event.target.name === "accountNumber") {
            this.props.clearField("accountNumber");
            this.setState({ accountType: "tel" });
        }
        if (event.target.name === "confirmAccountNumber") {
            this.props.clearField("confirmAccountNumber");
            this.setState({ confirmAccountType: "tel" });
        }
    }

    handleBlurGetFee(event: any) {
        const {
            application,
            merchant: { merchantName },
            calculateFee,
            billTotal,
        } = this.props;
        if (routingNumberCheck(event.target.value)) {
            if (calculateFee) {
                calculateFee(
                    application,
                    merchantName,
                    "en",
                    billTotal || 0,
                    7,
                    null,
                    null
                );
            }
            if (
                application.isBillDetail &&
                this.props.merchant.allowAutoBillPay
            ) {
                this.props.loadAutoPayTerms!(
                    application,
                    merchantName,
                    484,
                    7,
                    1
                );
            }
        }
        if (this.props.onBlur) {
            this.props.onBlur(event);
        }
    }

    handleBlur(event: any) {
        const { payment } = this.props;
        if (event.target.name === "accountNumber") {
            this.setState({ accountType: "password" });
        }
        if (event.target.name === "confirmAccountNumber") {
            this.setState({ confirmAccountType: "password" });
        }

        if (
            payment.accountNumber &&
            (payment as IPayment).confirmAccountNumber &&
            payment.accountNumber !== (payment as IPayment).confirmAccountNumber
        ) {
            this.confirmInput.setCustomValidity("form.error.accountMismatch");
        } else {
            this.confirmInput.setCustomValidity("");
        }
        if (this.props.onError && this.confirmInput.value) {
            CheckFieldValidity(this.confirmInput, this.props.onError);
        }
        if (this.props.onBlur) {
            this.props.onBlur(event);
        }
    }

    render() {
        const {
            intl: { formatMessage },
            merchant,
            payment,
            hideFeePanel,
            hideFutureDated,
            errors,
            onChecked,
            bankInformation,
            billTotal,
        } = this.props;
        const {
            showRememberAccount,
            hideRoutingNumber,
            hideConfirmAccount,
            readOnlyField,
        } = this.props;
        const { accountType, confirmAccountType, options } = this.state;
        const popover = (
            <Popover id="popover" title="Sample Check">
                {payment.accountType === "personalChecking" ? (
                    <Image src={sampleCheck} responsive />
                ) : (
                    <Image src={sampleCheckBusiness} responsive />
                )}
            </Popover>
        );
        const feedback = (
            <InputGroup.Addon>
                <OverlayTrigger
                    trigger={["hover", "focus"]}
                    placement="bottom"
                    overlay={popover}
                >
                    <FontAwesomeIcon icon={faQuestionCircle} />
                </OverlayTrigger>
            </InputGroup.Addon>
        );
        const isRequired = (payment as IPayment).method === "checking";
        const inputRef = (input: any) => {
            this.confirmInput = input;
        };
        return (
            <Panel>
                <Panel.Body>
                    <Row>
                        <Col xs={12} sm={12} md={12} lg={12}>
                            <TextInput
                                label={Resources.LabelNameOnAccount}
                                name="nameOnAccount"
                                value={payment.nameOnAccount}
                                error={errors.nameOnAccount}
                                onChange={this.props.onChange}
                                onError={this.props.onError}
                                onBlur={this.props.onBlur}
                                required={isRequired}
                                readOnly={readOnlyField}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={12} sm={12} md={6} lg={6}>
                            <SelectInput
                                label={Resources.LabelAccountType}
                                name="accountType"
                                value={payment.accountType}
                                error={errors.accountType}
                                onChange={this.props.onChange}
                                onError={this.props.onError}
                                onBlur={this.props.onBlur}
                                options={options}
                                required={isRequired}
                            />
                        </Col>
                        {!hideRoutingNumber && (
                            <Col xs={12} sm={12} md={6} lg={6}>
                                <RoutingNumberInput
                                    label={Resources.LabelRoutingNumber}
                                    name="routingNumber"
                                    value={payment.routingNumber}
                                    format="#########"
                                    decimalSeparator={false}
                                    error={errors.routingNumber}
                                    onChange={this.props.onChange}
                                    onError={this.props.onError}
                                    onBlur={this.handleBlurGetFee}
                                    onCut={this.handleIgnore}
                                    onCopy={this.handleIgnore}
                                    //onPaste={this.handleIgnore}
                                    required={isRequired}
                                    addonAfter={feedback}
                                />
                            </Col>
                        )}
                    </Row>
                    <Row>
                        <Col xs={12} sm={12} md={6} lg={6}>
                            <NumberInput
                                type={accountType}
                                label={Resources.LabelAccountNumber}
                                name="accountNumber"
                                value={payment.accountNumber}
                                format="#################"
                                decimalSeparator={false}
                                error={errors.accountNumber}
                                onBlur={this.handleBlur}
                                onFocus={this.handleFocus}
                                onChange={this.props.onChange}
                                onError={this.props.onError}
                                onCut={this.handleIgnore}
                                onCopy={this.handleIgnore}
                                onPaste={this.handleIgnore}
                                pattern={/^[0-9]{4,17}$/}
                                customError={formatMessage({
                                    id: "form.error.accountNumber",
                                })}
                                title={formatMessage({
                                    id: "form.error.accountNumber",
                                })}
                                required={isRequired}
                                readOnly={readOnlyField}
                                addonAfter={feedback}
                            />
                        </Col>
                        {!hideConfirmAccount && (
                            <Col xs={12} sm={12} md={6} lg={6}>
                                <NumberInput
                                    type={confirmAccountType}
                                    label={Resources.LabelConfirmAccountNumber}
                                    name="confirmAccountNumber"
                                    value={
                                        (payment as IPayment)
                                            .confirmAccountNumber
                                    }
                                    format="#################"
                                    decimalSeparator={false}
                                    error={errors.confirmAccountNumber}
                                    onBlur={this.handleBlur}
                                    onFocus={this.handleFocus}
                                    onChange={this.props.onChange}
                                    onError={this.props.onError}
                                    onCut={this.handleIgnore}
                                    onCopy={this.handleIgnore}
                                    onPaste={this.handleIgnore}
                                    pattern={/^[0-9]{4,17}$/}
                                    required={isRequired}
                                    inputRef={inputRef}
                                />
                            </Col>
                        )}
                    </Row>
                    {showRememberAccount && (
                        <Row>
                            <Col xs={12} sm={12} md={12}>
                                <Checkbox
                                    checked={
                                        (payment as IPayment)
                                            .saveAccountInMyAccountWallet
                                    }
                                    name="saveAccountInMyAccountWallet"
                                    onChange={onChecked}
                                >
                                    Remember this account for future payments.
                                </Checkbox>
                            </Col>
                        </Row>
                    )}
                    {!hideFutureDated && (
                        <Row>
                            <Col xs={7} sm={7} md={5}>
                                <FutureDatePayment
                                    name="futurePaymentDate"
                                    locale={this.props.locale}
                                    label={merchant.futureDatedPaymentLabel}
                                    selectedDate={
                                        (payment as IPayment).futurePaymentDate
                                    }
                                    maxNumberOfDays={
                                        this.props.maxFuturePaymentDays || 0
                                    }
                                    onChanged={this.props.onChange}
                                />
                            </Col>
                        </Row>
                    )}
                    {!hideFeePanel && (
                        <Row>
                            <Col xs={12} sm={12} md={12}>
                                <br />
                                <FeeTable
                                    merchant={merchant}
                                    payment={payment as IPayment}
                                    billTotal={billTotal || 0}
                                />
                            </Col>
                        </Row>
                    )}
                    {bankInformation && bankInformation.bankName && (
                        <Row>
                            <Col xs={12} sm={12} md={12}>
                                <Panel>
                                    <Panel.Heading>
                                        {Resources.BankInformationHeader}
                                    </Panel.Heading>
                                    <Panel.Body>
                                        <div>{bankInformation.bankName}</div>
                                        <div>
                                            {bankInformation.city},{" "}
                                            {bankInformation.state}{" "}
                                            {bankInformation.zipcode}
                                        </div>
                                        <div>{bankInformation.phoneNumber}</div>
                                    </Panel.Body>
                                </Panel>
                            </Col>
                        </Row>
                    )}
                    <Row>
                        <Col xs={12} sm={12} md={12}>
                            {errors.system && (
                                <Alert bsStyle="danger">
                                    <FontAwesomeIcon
                                        icon={faExclamationTriangle}
                                    />{" "}
                                    {errors.system}
                                </Alert>
                            )}
                        </Col>
                    </Row>
                </Panel.Body>
            </Panel>
        );
    }
}

export default injectIntl(BankAccountPanel);
