import { CancelAction, ClearAction } from './Common';
import { ConfigurationActions } from '../actionTypes/Configuration';
import { IBill, IBillImageResponse, IUser } from '../types';
import { USER_UPDATE } from './User';

export const BILLS_LOAD = "redux/bills/LOAD";
export const BILLS_LOAD_SUCCESS = "redux/bills/LOAD_SUCCESS";
export const BILLS_LOAD_FAIL = "redux/bills/LOAD_FAIL";
export const BILL_IMAGE_LOAD_SUCCESS = "redux/billimage/LOAD_SUCCESS";
export const BILL_IMAGE_LOAD_FAIL = "redux/billimage/LOAD_FAIL";
export const BILLS_CREATE = "redux/bills/CREATE";
export const BILLS_UPDATE = "redux/bills/UPDATE";
export const BILLS_DELETE = "redux/bills/DELETE";
export const BILLS_CLEAR = "redux/bills/BILLS_CLEAR";

export type RequestBillsAction = {
    type: typeof BILLS_LOAD;
}

export type ReceiveBillsAction = {
    type: typeof BILLS_LOAD_SUCCESS;
    data: IBill[];
}

export type FailedBillsAction = {
    type: typeof BILLS_LOAD_FAIL;
    data: string;
}

export type ReceiveBillImageAction = {
    type: typeof BILL_IMAGE_LOAD_SUCCESS;
    billId: string | number;
    data: IBillImageResponse;
}

export type FailedBillImageAction = {
    type: typeof BILL_IMAGE_LOAD_FAIL;
    billId: string | number;
    data: string;
}

export type CreateBillAction = {
    type: typeof BILLS_CREATE;
    data: IBill;
}
export type UpdateBillAction = {
    type: typeof BILLS_UPDATE;
    data: IBill;
}
export type DeleteBillAction = {
    type: typeof BILLS_DELETE;
    data: string;
}
export type UpdateUserAction = {
    type: typeof USER_UPDATE;
    data: IUser;
}

export type ClearBillAction = {
    type: typeof BILLS_CLEAR;
    data: string;
}


export type BillsActions = RequestBillsAction | ReceiveBillsAction | FailedBillsAction | ReceiveBillImageAction | FailedBillImageAction | CreateBillAction | UpdateBillAction | DeleteBillAction | CancelAction | ClearAction | ConfigurationActions | UpdateUserAction | ClearBillAction;