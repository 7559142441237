import React from "react";
import { HeaderTokenError, MessageTokenExpired } from "../../resources";

export class TokenError extends React.Component {
    render() {
        return (
            <div>
                <h2 className="page-header">{HeaderTokenError}</h2>
                <p>{MessageTokenExpired}</p>
            </div>
        );
    }
}
