import React from "react";
import { Button } from "react-bootstrap";
import { injectIntl, InjectedIntlProps } from "react-intl";
import { connect } from "react-redux";
import { RouterProps } from "react-router";
import { clearBills } from "../../actions/Bill";
import { newBillsearchSession, reset } from "../../actions/BillSearch";
import * as Resources from "../../resources";
import {
    BillSearchOption,
    IApplicationState,
    IBillSearchCriteria,
    IBillSearchResults,
} from "../../types";
import SearchOptionWrapper from "./SearchOptionsWrapper";

interface ISearchOptionContentState {
    currentSearchBlockName: BillSearchOption;
}

interface ISearchOptionContentStateProps {
    criteria: IBillSearchCriteria;
    merchantName: string;
    billSearchResult: IBillSearchResults;
    billsInCartAny: boolean;
}

interface ISearchOptionContentDispatchProps {
    reset: (criteria: IBillSearchCriteria) => void;
    newBillsearchSession: VoidFunction;
    clearBills: VoidFunction;
}

export type SearchOptionContentProps = ISearchOptionContentStateProps &
    ISearchOptionContentDispatchProps &
    InjectedIntlProps &
    RouterProps;

class SearchOptionContent extends React.Component<
    SearchOptionContentProps,
    ISearchOptionContentState
> {
    constructor(props: SearchOptionContentProps) {
        super(props);
        this.state = {
            currentSearchBlockName: null,
        };
    }

    private clearCriterias = (blockName: BillSearchOption) => {
        if (blockName !== this.state.currentSearchBlockName) {
            this.props.reset({
                ...this.props.criteria,
                id1: "",
                id2: "",
                id3: "",
                id4: "",
                showPaidBills: true,
                address: "",
                postalCode: "",
                meta: {
                    searchType: "MANUAL",
                    searchGroup: this.state.currentSearchBlockName,
                    searchCriteria: {
                        parcelNumber: "",
                        mailingAddress: "",
                        countryCode: "USA",
                        zipCode: "",
                        year: "",
                        billNumber: "",
                    },
                },
            });
        }
        this.setState({ currentSearchBlockName: blockName });
    };

    private handleNewSession = (e: React.MouseEvent<Button>) => {
        const { newBillsearchSession, clearBills } = this.props;
        clearBills();
        newBillsearchSession();
        window.location.reload();
    };

    handleViewCart = (e: React.MouseEvent<Button>) => {
        const { history, merchantName } = this.props;
        history.push(`/${merchantName}/bills`);
    };

    private handleNewSessionOnMouseDown = (e: React.MouseEvent<Button>) => {
        e.preventDefault();
    };

    render() {
        const { intl, billsInCartAny } = this.props;
        return (
            <div>
                <p className="text-options-title-color">
                    <strong>
                        <span id="PaymentApplicationContent_lblSearchOptionTitle">
                            {Resources.BillSearchTitle}
                        </span>
                    </strong>
                </p>
                <SearchOptionWrapper
                    billsearchResult={this.props.billSearchResult}
                    collapseCallback={this.clearCriterias}
                    history={this.props.history}
                />

                <div className="span12 text-center">
                    {billsInCartAny && (
                        <Button
                            onClick={this.handleViewCart}
                            bsClass="btn btn-large btn-primary text-center btn-custom"
                        >
                            {intl.formatMessage({
                                id: "billSearchResult.btn.view_cart",
                            })}
                        </Button>
                    )}
                    <Button
                        onClick={this.handleNewSession}
                        onMouseDown={this.handleNewSessionOnMouseDown}
                        bsClass="btn btn-large btn-primary text-center btn-custom"
                    >
                        {intl.formatMessage({
                            id: "billSearch.new_session",
                        })}
                    </Button>
                </div>
            </div>
        );
    }
}

export default injectIntl(
    connect(
        (state: IApplicationState) => ({
            criteria: state.billSearch.criteria || ({} as IBillSearchCriteria),
            merchantName: state.settings.merchantName,
            billSearchResult: state.billSearch,
            billsInCartAny: state.bills.bills.length > 0,
        }),
        {
            reset,
            newBillsearchSession,
            clearBills,
        }
    )(SearchOptionContent)
);
