import React from "react";

import { SampleBill } from "./SampleBill";

export interface GridRowViewImageButtonProps {
    title: string | JSX.Element;
    imageContent: string | null;
    detectedMIMEType: string | null;
    onOpenSampleImage?: () => void;
}

export const GridRowViewImageButton = (
    props: GridRowViewImageButtonProps
): JSX.Element => {
    return (
        <td>
            <SampleBill
                title={props.title}
                imageContent={props.imageContent}
                detectedMIMEType={props.detectedMIMEType}
                onOpenSampleImage={props.onOpenSampleImage}
            />
        </td>
    );
};
