import React from "react";
import { toastr } from "react-redux-toastr";
import { Checkbox, Table } from "react-bootstrap";
import { Pagination } from "../Input";
import { LabelDueDate, LabelAmountDue } from "../../resources";
import { getLanguageCode } from "../../utils/Intl";
import ResultsRow from "./ResultsRow";
import { CheckFieldValidity } from "../../utils/Validation";
import { GetMaxPaymentAmount } from "../../utils/BillHelpers";
import { connect } from "react-redux";
import { IApplicationState } from "../../types";
import { updateCriteria } from "../../actions/BillSearch";
import { FormattedMessage } from "react-intl";

class ResultsTable extends React.Component<any, any> {
    constructor(props: any) {
        super(props);
        this.state = {
            activePage: 1,
        };
        this.handleSelect = this.handleSelect.bind(this);
        this.handlePaidBillsChecked = this.handlePaidBillsChecked.bind(this);
    }
    handleSelect(eventKey: any) {
        const {
            application,
            settings,
            intl,
            criteria,
            billSearch,
            searchResults: { pageSize },
        } = this.props;
        this.setState({ activePage: eventKey });
        billSearch(
            settings.merchantName,
            application,
            criteria.billType,
            criteria.id1,
            criteria.id2,
            criteria.id3,
            criteria.id4,
            getLanguageCode(intl.locale),
            criteria.showPaidBills,
            "",
            "",
            eventKey,
            pageSize
        );
    }
    handlePaidBillsChecked(event: any) {
        const checked = event.target.checked;
        this.props.updateCriteria({ showPaidBills: checked });
        const defaultPage = 1;
        this.setState({ activePage: defaultPage });
        const {
            criteria,
            intl: { locale },
            searchResults: { pageSize },
        } = this.props;
        this.props.billSearch(
            this.props.merchantName,
            this.props.application,
            criteria.billType,
            criteria.id1,
            criteria.id2,
            criteria.id3,
            criteria.id4,
            getLanguageCode(locale),
            checked,
            "",
            "",
            defaultPage,
            pageSize
        );
    }
    render() {
        const {
            settings,
            billType,
            bills,
            searchResults,
            addToBills,
            deleteBill,
            updateSearchRecord,
        } = this.props;
        const { pageNumber, pageSize, totalRowCount } = searchResults;
        const totalPages = Math.ceil(totalRowCount / pageSize);
        return (
            <div>
                {searchResults && (
                    <div className="table-responsive">
                        <Table condensed bordered>
                            <thead>
                                <tr>
                                    {billType.id1.label &&
                                    !billType.id1.isSearchOnly ? (
                                        <th>{billType.id1.label}</th>
                                    ) : null}
                                    {billType.id2.label &&
                                    !billType.id2.isSearchOnly ? (
                                        <th>{billType.id2.label}</th>
                                    ) : null}
                                    {billType.id3.label &&
                                    !billType.id3.isSearchOnly ? (
                                        <th>{billType.id3.label}</th>
                                    ) : null}
                                    {billType.id4.label &&
                                    !billType.id4.isSearchOnly ? (
                                        <th>{billType.id4.label}</th>
                                    ) : null}
                                    {!settings.billDueDateIsHidden ? (
                                        <th>{LabelDueDate}</th>
                                    ) : null}
                                    <th>{billType.amount.label}</th>
                                    {settings.allowBillAmountToBeEdited && (
                                        <th>{LabelAmountDue}</th>
                                    )}
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody>
                                {searchResults &&
                                    searchResults.bills.map(
                                        (bill: any, index: any) => {
                                            const calculateAmount = function (
                                                record: any
                                            ) {
                                                if (record.useCustom) {
                                                    return record.customAmount;
                                                }
                                                return (
                                                    record.customAmount ||
                                                    (record.amountDue < 0
                                                        ? Number(0).toFixed(2)
                                                        : record.amountDue.toFixed(
                                                              2
                                                          ))
                                                );
                                            };
                                            const handleAddToBills = function (
                                                event: any
                                            ) {
                                                event.preventDefault();
                                                if (!validateL2Data()) return;
                                                addToBills(bill);
                                            };
                                            const handleDelete = function (
                                                event: any
                                            ) {
                                                event.preventDefault();
                                                deleteBill(bill.id.toString());
                                                bill.customAmount = null;
                                                updateSearchRecord(bill);
                                            };
                                            const handleError = function (
                                                name: any,
                                                message: any
                                            ) {
                                                bill.error = message;
                                                updateSearchRecord(bill);
                                            };
                                            const handleChange = function (
                                                event: any
                                            ) {
                                                event.preventDefault();
                                                bill.customAmount =
                                                    event.target.value;
                                                bill.useCustom = true;
                                                updateSearchRecord(bill);
                                                if (
                                                    event.target.validity.valid
                                                ) {
                                                    CheckFieldValidity(
                                                        event.target,
                                                        handleError
                                                    );
                                                }
                                            };
                                            const handleBlur = function (
                                                event: any
                                            ) {
                                                event.preventDefault();
                                                if (bill.useCustom) {
                                                    bill.customAmount = Number(
                                                        bill.customAmount
                                                    ).toFixed(2);
                                                    bill.useCustom = false;
                                                    updateSearchRecord(bill);
                                                }
                                                CheckFieldValidity(
                                                    event.target,
                                                    handleError
                                                );
                                            };
                                            const validateL2Data = function () {
                                                if (!settings.l2DataEnabled)
                                                    return true;
                                                const purchaseOrders =
                                                    bills.map(
                                                        (b: any) =>
                                                            b.purchaseOrder
                                                    );
                                                const taxExemptValues =
                                                    bills.map(
                                                        (b: any) =>
                                                            b.isTaxExempt
                                                    );
                                                if (purchaseOrders.length > 0) {
                                                    if (
                                                        !purchaseOrders.includes(
                                                            bill.purchaseOrder
                                                        )
                                                    ) {
                                                        toastr.error(
                                                            "Add Bill Error",
                                                            `${bill.id1} has different PO Number than bill already added.`
                                                        );
                                                        return false;
                                                    }
                                                    if (
                                                        !taxExemptValues.includes(
                                                            bill.isTaxExempt
                                                        )
                                                    ) {
                                                        toastr.error(
                                                            "Add Bill Error",
                                                            `${bill.id1} has different Tax Exempt status than bill already added.`
                                                        );
                                                        return false;
                                                    }
                                                }
                                                return true;
                                            };
                                            const selected = bills.some(
                                                (s: any) =>
                                                    s.id.toString() ===
                                                    bill.id.toString()
                                            );
                                            if (selected) {
                                                const currentBill = bills.find(
                                                    (s: any) =>
                                                        s.id.toString() ===
                                                        bill.id.toString()
                                                );
                                                bill.customAmount =
                                                    currentBill.customAmount;
                                            }
                                            bill.customAmount =
                                                calculateAmount(bill);
                                            return (
                                                <ResultsRow
                                                    key={bill.id}
                                                    settings={settings}
                                                    billType={billType}
                                                    bill={bill}
                                                    selected={selected}
                                                    addToBills={
                                                        handleAddToBills
                                                    }
                                                    deleteFromBills={
                                                        handleDelete
                                                    }
                                                    onChange={handleChange}
                                                    onError={handleError}
                                                    onBlur={handleBlur}
                                                    maxAmount={GetMaxPaymentAmount(
                                                        settings.allowBillOverpayment,
                                                        settings.maxPaymentAmount,
                                                        bill.amountDue
                                                    )}
                                                    calculateAmount={
                                                        calculateAmount
                                                    }
                                                />
                                            );
                                        }
                                    )}
                            </tbody>
                        </Table>
                        {totalPages > 1 && (
                            <div>
                                <div className="text-center">
                                    <Pagination
                                        first
                                        last
                                        prev
                                        activePage={pageNumber}
                                        next
                                        totalPages={totalPages}
                                        maxPages={5}
                                        onSelect={this.handleSelect}
                                    />
                                </div>
                            </div>
                        )}
                        <div style={{ float: "right" }}>
                            <Checkbox
                                checked={this.props.criteria.showPaidBills}
                                name="termsAccepted"
                                onChange={this.handlePaidBillsChecked}
                            >
                                <FormattedMessage
                                    id="text.includePaidBills"
                                    defaultMessage="Include Paid bills"
                                />
                            </Checkbox>
                        </div>
                    </div>
                )}
            </div>
        );
    }
}

export default connect(
    (state: IApplicationState) => ({
        criteria: state.billSearch.criteria,
        application: state.application,
        merchantName: state.settings.merchantName,
        intl: state.intl,
    }),
    {
        updateCriteria,
    }
)(ResultsTable);
