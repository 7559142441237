import { ConfigurationActions } from '../actionTypes/Configuration';

export const INTL_LOAD = "redux/intl/LOAD";
export const INTL_LOAD_SUCCESS = "redux/intl/LOAD_SUCCESS";
export const INTL_LOAD_FAIL = "redux/intl/LOAD_FAIL";
export const INTL_SET = "redux/intl/SET";

export type RequestIntlAction = {
    type: typeof INTL_LOAD;
}

export type ReceiveIntlAction = {
    type: typeof INTL_LOAD_SUCCESS;
    data: number;
}

export type FailedIntlAction = {
    type: typeof INTL_LOAD_FAIL;
    data: string;
}

export type SetIntlAction = {
    type: typeof INTL_SET;
    data: string;
}

export type IntlActions = RequestIntlAction | ReceiveIntlAction | FailedIntlAction | SetIntlAction | ConfigurationActions;