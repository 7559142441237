import React from "react";

interface GroupNameTableHeaderProps {
    groupName: JSX.Element;
    groupNameTooltip: string;
    groupCssClassName: string;
}

export class GroupNameTableHeader extends React.Component<GroupNameTableHeaderProps> {
    constructor(props: GroupNameTableHeaderProps) {
        super(props);
    }

    render() {
        const { groupName, groupNameTooltip, groupCssClassName } = this.props;

        return (
            <div
                id="display-section-header"
                className={`${groupCssClassName} ` + "r-section-header"}
            >
                <span title={groupNameTooltip}>{groupName}</span>
            </div>
        );
    }
}
