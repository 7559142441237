import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExclamationCircle } from "@fortawesome/free-solid-svg-icons";
import Idle from "react-idle-timer";
import { Modal, ButtonToolbar, Button } from "react-bootstrap";
import * as Resources from "../../resources";
import { Post } from "../../utils/Post";
import { disableSecurePayGuid } from "../../api/Payment";
import { IApplication, ISettings } from "../../types";
import { History } from "history";

const warningLength = 60;
const minute = Number(60000);

export interface IIdleTimeoutProps {
    application: IApplication;
    settings: ISettings;
    history: History;
    cancel: () => void;
}

export interface IIdleTimeoutState {
    showModal: boolean;
    counter: number;
    intervalId?: number;
}

class IdleTimeout extends React.Component<
    IIdleTimeoutProps,
    IIdleTimeoutState
> {
    constructor(props: IIdleTimeoutProps) {
        super(props);

        this.state = {
            showModal: false,
            counter: Number(warningLength),
        };

        this.handleOpen = this.handleOpen.bind(this);
        this.handleClose = this.handleClose.bind(this);
        this.handleCancel = this.handleCancel.bind(this);
    }

    componentDidUpdate(
        nextProps: IIdleTimeoutProps,
        nextState: IIdleTimeoutState
    ) {
        const { counter } = nextState;
        const { application, settings, cancel } = nextProps;
        if (nextState !== this.state) {
            if (counter < 0 && nextState.showModal) {
                if (this.state.intervalId) {
                    window.clearInterval(this.state.intervalId);
                }

                this.setState({
                    showModal: false,
                    intervalId: undefined,
                });

                cancel();

                disableSecurePayGuid(application, settings.merchantName);

                if (application.guid) {
                    const path = `/${settings.merchantName}/expired/${application.guid}`;
                    this.props.history.push(path);
                } else {
                    const path = `/${settings.merchantName}/expired`;
                    this.props.history.push(path);
                }
            }
        }
    }

    componentWillUnmount() {
        if (this.state.intervalId) {
            window.clearInterval(this.state.intervalId);
        }
    }

    handleOpen() {
        const { showModal } = this.state;
        if (!showModal) {
            const intervalId = window.setInterval(() => {
                this.setState({ counter: this.state.counter - 1 });
            }, 1000);

            this.setState({
                intervalId: intervalId,
            });
        }
        this.setState({
            showModal: true,
        });
    }

    handleClose() {
        if (this.state.intervalId) {
            window.clearInterval(this.state.intervalId);
        }

        this.setState({
            showModal: false,
            counter: Number(warningLength),
        });
    }

    handleCancel(event: any) {
        event.preventDefault();

        if (this.state.intervalId) {
            window.clearInterval(this.state.intervalId);
        }

        this.setState({
            showModal: false,
            intervalId: undefined,
        });

        const { application, settings, cancel } = this.props;

        cancel();
        if (application.id === 33) {
            this.props.history.push(`/${settings.merchantName}`);
        } else {
            const data = {
                GUID: application.guid,
                DataPostMode: "Cancel",
            };
            if (application.id === 35) {
                Post(settings.securePayMOTOCancelUrl, data);
            } else {
                Post(settings.cancelUrl, data);
            }
        }
    }

    render() {
        const { showModal, counter } = this.state;
        const securePayPaymentType = null;
        let buttonText = Resources.ButtonCancelPayment;
        if (securePayPaymentType === 3) {
            buttonText = Resources.ButtonCancel;
        }
        return (
            <div>
                <Idle crossTab={true} timeout={10 * minute} onIdle={() => this.handleOpen()} />
                <Modal show={showModal} onHide={this.handleClose}>
                    <Modal.Header>
                        <Modal.Title>{Resources.HeaderIdleTimeout}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <p>
                            <FontAwesomeIcon icon={faExclamationCircle} />{" "}
                            {Resources.MessageIdleTimeout1(counter)}
                        </p>
                        <p>{Resources.MessageIdleTimeout2}</p>
                    </Modal.Body>
                    <Modal.Footer>
                        <div className="pull-right">
                            <ButtonToolbar>
                                <Button onClick={this.handleCancel}>
                                    {buttonText}
                                </Button>
                                <Button
                                    bsStyle="primary"
                                    onClick={this.handleClose}
                                    style={{ minWidth: "6.5em" }}
                                >
                                    {Resources.ButtonContinue}
                                </Button>
                            </ButtonToolbar>
                        </div>
                    </Modal.Footer>
                </Modal>
            </div>
        );
    }
}
export default IdleTimeout;
