import React from "react";
import { HeaderBillInformation } from "../../resources";
import { IBill, IBillType, IInternationalization } from "../../types";
import { IFormError } from "../../types/IFormError";
import { BillTable } from "./BillTable";

export interface BillGridProps {
    intl: IInternationalization;
    billTypes: IBillType[];
    bills: IBill[];
    isEBPP: boolean;
    showEdit: boolean;
    errors?: IFormError;
    maxPaymentAmount?: number;
    minPaymentAmount?: number;
    allowBillOverpayment?: boolean;
    allowBillAmountToBeEdited?: boolean;
    loadImageForBill?: (billId: string) => void;
    onDelete?: (billId: string) => void;
    onError?: (name: string, message: string) => void;
    saveBill?: (bill: IBill) => void;
}

export class BillGrid extends React.Component<BillGridProps> {
    constructor(props: BillGridProps) {
        super(props);
    }

    private buildTablesForBillTypes(
        props: BillGridProps
    ): (JSX.Element | null)[] {
        return props.billTypes.map((billType: IBillType, index: number) => {
            const billsByType = props.bills.filter(
                (bill: IBill) => bill.billType === billType.name
            );
            const billWithImage = billsByType.find((b) => b.hasImage); 
            if (billsByType.length === 0) return null;

            return (
                <BillTable
                    key={billType.name}
                    intl={props.intl}
                    billType={billType}
                    bills={billsByType}
                    isEBPP={props.isEBPP}
                    billHasImage={billWithImage ? true : false}
                    showEdit={props.showEdit}
                    onOpenSampleImage={props.loadImageForBill}
                    onDelete={props.onDelete}
                    onError={props.onError}
                    errors={props.errors}
                    saveBill={props.saveBill}
                    maxPaymentAmount={this.props.maxPaymentAmount}
                    minPaymentAmount={this.props.minPaymentAmount}
                    allowBillOverpayment={this.props.allowBillOverpayment}
                    allowBillAmountToBeEdited={
                        this.props.allowBillAmountToBeEdited
                    }
                />
            );
        });
    }

    public render(): JSX.Element {
        return (
            <section>
                <fieldset>
                    <legend>{HeaderBillInformation}</legend>
                    {this.buildTablesForBillTypes(this.props)}
                </fieldset>
            </section>
        );
    }
}
