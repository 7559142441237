import React from "react";

import { ButtonGroup, ButtonToolbar, Col, Row, Well } from "react-bootstrap";

export interface ButtonBarProps {
    cancelButton: JSX.Element | null;
    leftActionButton: JSX.Element | null;
    middleActionButton: JSX.Element | null;
    rightActionButton: JSX.Element | null;
}

export const ButtonBar = (props: ButtonBarProps): JSX.Element => {
    return (
        <Well>
            <Row>
                <Col xsHidden sm={12}>
                    <ButtonToolbar>
                        <ButtonGroup>{props.cancelButton}</ButtonGroup>
                        <div className="pull-right">
                            <ButtonGroup>
                                {props.leftActionButton}
                                {props.middleActionButton}
                                {props.rightActionButton}
                            </ButtonGroup>
                        </div>
                    </ButtonToolbar>
                </Col>
                <Col xs={12} smHidden mdHidden lgHidden>
                    <ButtonToolbar>
                        <ButtonGroup vertical block>
                            {props.cancelButton}
                            {props.leftActionButton}
                            {props.middleActionButton}
                            {props.rightActionButton}
                        </ButtonGroup>
                    </ButtonToolbar>
                </Col>
            </Row>
        </Well>
    );
};
