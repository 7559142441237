import { Reducer } from "redux";
import {
  PAYOR_LOAD,
  PAYOR_LOAD_SUCCESS,
  PAYOR_LOAD_FAIL,
  PAYOR_UPDATE,
  RequestPayorAction,
  ReceivePayorAction,
  FailedPayorAction,
  UpdatePayorAction,
  PayorActions,
} from "../actionTypes/Payor";
import {
  CONFIGURATION_LOAD_SUCCESS,
  CONFIGURATION_EBPP_LOAD_SUCCESS,
  CONFIGURATION_LOAD_FAIL,
  ReceiveConfigurationAction,
  ReceiveConfigurationEBPPAction,
  FailedConfigurationAction,
} from "../actionTypes/Configuration";
import { CANCEL, CLEAR } from "../actionTypes/Common";
import { IPayor } from "../types";
import { verifyCounty } from "../utils/Country";

export const initialState: IPayor = {
  isLoaded: false,
  isLoading: false,
  error: null,
  business: "",
  businessIsEditable: true,
  firstName: "",
  firstNameIsEditable: true,
  middleName: "",
  middleNameIsEditable: true,
  lastName: "",
  lastNameIsEditable: true,
  address: "",
  addressIsEditable: true,
  country: "US",
  countryIsEditable: true,
  state: "",
  stateIsEditable: true,
  city: "",
  cityIsEditable: true,
  postal: "",
  postalIsEditable: true,
  phone: "",
  phoneCountry: "US",
  phoneIsEditable: true,
  mobilePhone: "",
  mobilePhoneCountry: "US",
  mobilePhoneIsEditable: true,
  preferredContactMethod: 1,
  preferredContactMethodIsEditable: true,
  email: "",
  confirmEmail: "",
  emailIsEditable: true,
  saved: false,
};

const payorLoadAction = (state: IPayor, action: RequestPayorAction): IPayor => {
  return {
    ...state,
    isLoading: true,
    isLoaded: false,
    error: null,
  };
};

const payorLoadSuccessAction = (
  state: IPayor,
  action: ReceivePayorAction
): IPayor => {
  return {
    ...state,
    ...action.data,
    isLoading: false,
    isLoaded: true,
    error: null,

    country: verifyCounty(action.data.country),
  };
};

const payorLoadFailAction = (
  state: IPayor,
  action: FailedPayorAction
): IPayor => {
  return {
    ...state,
    isLoading: false,
    isLoaded: false,
    error: action.data,
  };
};

const payorUpdateAction = (
  state: IPayor,
  action: UpdatePayorAction
): IPayor => {
  return {
    ...state,
    ...action.data,
    isLoading: false,
    isLoaded: true,
    error: null,
    country: verifyCounty(action.data.country),
    saved: true,
  };
};

const configurationLoadSuccessAction = (
  state: IPayor,
  action: ReceiveConfigurationAction
): IPayor => {
  return {
    ...state,
    ...action.data.payor,
    isLoading: false,
    isLoaded: true,
    error: null,
    country: verifyCounty(action.data.payor.country),
  };
};

const configurationEbppLoadSuccessAction = (
  state: IPayor,
  action: ReceiveConfigurationEBPPAction
): IPayor => {
  return {
    ...state,
    ...action.data.payor,
    isLoading: false,
    isLoaded: true,
    error: null,
    country: verifyCounty(action.data.payor.country),
  };
};

const configurationLoadFailAction = (
  state: IPayor,
  action: FailedConfigurationAction
): IPayor => {
  return {
    ...state,
    isLoading: false,
    isLoaded: false,
    error: action.data,
  };
};

export const reducer: Reducer<IPayor> = (
  state: IPayor = initialState,
  action: PayorActions
): IPayor => {
  switch (action.type) {
    case PAYOR_LOAD:
      return payorLoadAction(state, action);
    case PAYOR_LOAD_SUCCESS:
      return payorLoadSuccessAction(state, action);
    case PAYOR_LOAD_FAIL:
      return payorLoadFailAction(state, action);
    case PAYOR_UPDATE:
      return payorUpdateAction(state, action);
    case CONFIGURATION_LOAD_SUCCESS:
      return configurationLoadSuccessAction(state, action);
    case CONFIGURATION_EBPP_LOAD_SUCCESS:
      return configurationEbppLoadSuccessAction(state, action);
    case CONFIGURATION_LOAD_FAIL:
      return configurationLoadFailAction(state, action);
    case CLEAR:
      return { ...initialState };
    case CANCEL:
      return { ...initialState };
    default:
      return state;
  }
};
