import React from "react";
import { Modal } from "react-bootstrap";
import { IApplication, IUser } from "../../../types";
import LoginForm from "./LoginForm";

export interface LoginModalProps {
    application: IApplication;
    merchantName: string;
    allowAutoPay: boolean;
    requireRecaptcha: boolean;
    requireLoginEBPP: boolean;
    requirePasswordReset: boolean;
    locale: string;
    show: boolean;
    allowGuestLogin: boolean;
    user: IUser;
    webPaymentsCustomerAccountLoginMessage?: string;
    onClose: () => void;
    onContinue?: () => void;
    updateUser: (user: IUser) => void;
    userVerified: (value: boolean) => void;
    login: (
        userName: string,
        password: string,
        recaptcha?: string | null
    ) => Promise<IUser>;
    logout: () => void;
    loadUserAccounts: (
        application: IApplication,
        merchantName: string,
        authToken: string,
        onError?: (text: string) => void
    ) => void;
    loadCustomerBillDetails: (
        merchantName: string,
        authToken: string,
        merchantCustomerId: string
    ) => void;
}

export class LoginModal extends React.Component<LoginModalProps> {
    public static defaultProps: Partial<LoginModalProps> = {
        show: false,
    };

    private onClose = (event: any): void => {
        // in case the user clicks outside of the dialog to close it and they have not been
        // verified by MFA then log them out.
        if (!this.props.user.isVerified) {
            this.props.logout();
        }

        this.props.onClose();
    };

    public render(): JSX.Element {
        return (
            <Modal show={this.props.show} onHide={this.onClose}>
                <Modal.Body>
                    <LoginForm
                        application={this.props.application}
                        merchantName={this.props.merchantName}
                        allowAutoPay={this.props.allowAutoPay}
                        requireRecaptcha={this.props.requireRecaptcha}
                        requireLoginEBPP={this.props.requireLoginEBPP}
                        requirePasswordReset={this.props.requirePasswordReset}
                        locale={this.props.locale}
                        allowGuestLogin={this.props.allowGuestLogin}
                        user={this.props.user}
                        onClose={this.props.onClose}
                        onContinue={this.props.onContinue}
                        updateUser={this.props.updateUser}
                        userVerified={this.props.userVerified}
                        login={this.props.login}
                        logout={this.props.logout}
                        loadUserAccounts={this.props.loadUserAccounts}
                        loadCustomerBillDetails={
                            this.props.loadCustomerBillDetails
                        }
                        webPaymentsCustomerAccountLoginMessage={
                            this.props.webPaymentsCustomerAccountLoginMessage
                        }
                    />
                </Modal.Body>
            </Modal>
        );
    }
}
