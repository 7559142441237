import React from "react";
import * as Resources from "../../../../resources";
import SearchResultWrapper from "./SearchResultWrapper";
import { FormattedNumber, InjectedIntlProps, injectIntl } from "react-intl";
import { connect, MapDispatchToPropsParam } from "react-redux";
import {
    IApplication,
    IApplicationState,
    IBillRecord,
    IBill,
    IBillType,
    ISettings,
    IBillSearchCriteria,
} from "../../../../types";
import { totalBillAmount } from "../../../../utils/BollettaMath";
import { InformModal } from "../../../Layout/Modals/InformModal";
import {
    ErrorSumOverlimit,
    LabelOvermaxPaymentAmount,
    LabelViewBillInfoBody,
    LabelViewBillInfoHeader,
} from "../../../../resources";
import { Button } from "react-bootstrap";
import { RouterProps } from "react-router";
import { clearBills } from "../../../../actions/Bill";
import { newBillsearchSession } from "../../../../actions/BillSearch";

interface IOwnProps {
    settings: ISettings;
    addToBills: (bill: IBill) => void;
    generatePdf: (merchantName: string, billType: string, bill: IBill) => void;
}

interface IConnectedProps {
    application: IApplication;
    bills: IBillRecord[];
    billTypes: IBillType[];
    criteria: IBillSearchCriteria | null;
    merchantName: string;
    billsInCart: IBill[];
}

interface IDispatchProps {
    clearBills: VoidFunction;
    newBillsearchSession: VoidFunction;
}

type IComponentProps = IOwnProps &
    IConnectedProps &
    InjectedIntlProps &
    RouterProps &
    IDispatchProps;

interface IComponentState {
    showMaxPaymentAmountExceeded: boolean;
    selected: boolean;
    selectedBillToView: IBill | null;
    showViewBillInformModal: boolean;
}

class SearchResultContent extends React.Component<
    IComponentProps,
    IComponentState
> {
    constructor(props: IComponentProps) {
        super(props);
        this.state = {
            showMaxPaymentAmountExceeded: false,
            selected: props.billsInCart.length > 0,
            selectedBillToView: null,
            showViewBillInformModal: false,
        };
    }

    handleAddToBills = (row: IBill) => {
        const { settings, addToBills, billsInCart } = this.props;

        row.amount = row.amountDue || row.amount;

        const amount = row.amount;

        if (amount <= settings.maxPaymentAmount) {
            const initialAmount = totalBillAmount(billsInCart);
            const totalAmount = initialAmount + amount;

            if (settings.maxPaymentAmount < totalAmount) {
                this.setState({
                    showMaxPaymentAmountExceeded: true,
                });
            } else {
                if (billsInCart.length === 0) {
                    this.setState({ selected: !this.state.selected });
                }

                addToBills(row);
                this.setState({ selected: true });
            }
        } else {
            this.setState({
                showMaxPaymentAmountExceeded: true,
            });
        }
    };

    handleMaxPaymentAmountExceededModalClose = () => {
        this.setState({
            showMaxPaymentAmountExceeded: false,
        });
    };

    handleViewBillInformModalClose = () => {
        const { merchantName, generatePdf } = this.props;
        const { selectedBillToView } = this.state;

        generatePdf(
            merchantName,
            selectedBillToView!.billType,
            selectedBillToView!
        );

        this.setState({
            showViewBillInformModal: false,
        });
    };

    handleViewBill = (bill: IBill) => {
        this.setState({
            selectedBillToView: bill,
            showViewBillInformModal: true,
        });
    };

    handleViewCart = (e: React.MouseEvent<Button>) => {
        const { history, merchantName } = this.props;
        history.push(`/${merchantName}/bills`);
    };

    handleNewSearch = () => {
        const { history, merchantName, newBillsearchSession } = this.props;
        newBillsearchSession();
        history.push(`/${merchantName}/search`);
    };

    handleClearSession = () => {
        const { history, merchantName } = this.props;
        this.props.clearBills();
        history.push(`/${merchantName}/search`);
    };

    render() {
        const { intl, settings, bills, billTypes, billsInCart } = this.props;
        const { showMaxPaymentAmountExceeded, showViewBillInformModal } =
            this.state;
        const buttonCssClass =
            "btn btn-large btn-primary text-center btn-custom";

        return (
            <div>
                <SearchResultWrapper
                    {...this.props}
                    bills={bills}
                    billTypes={billTypes}
                    selectedBills={billsInCart}
                    addToCart={this.handleAddToBills}
                    handleViewBill={this.handleViewBill}
                />
                {Resources.BillSearchResultContentArticle}
                <div className="span12 text-center">
                    {this.state.selected || billsInCart.length > 0 ? (
                        <Button
                            bsClass={buttonCssClass}
                            onClick={this.handleViewCart}
                        >
                            {intl.formatMessage({
                                id: "billSearchResult.btn.view_cart",
                            })}
                        </Button>
                    ) : (
                        <div></div>
                    )}
                    <Button
                        bsClass={buttonCssClass}
                        onClick={this.handleNewSearch}
                    >
                        {intl.formatMessage({
                            id: "billSearchResult.btn",
                        })}
                    </Button>
                    {this.state.selected || billsInCart.length > 0 ? (
                        <Button
                            bsClass={buttonCssClass}
                            onClick={this.handleClearSession}
                        >
                            {intl.formatMessage({
                                id: "billSearchResult.btn.clear_session",
                            })}
                        </Button>
                    ) : (
                        <div></div>
                    )}
                </div>
                <InformModal
                    headerText={LabelOvermaxPaymentAmount}
                    message={ErrorSumOverlimit(
                        <FormattedNumber
                            value={settings.maxPaymentAmount}
                            style="currency"
                            currency="USD"
                        />
                    )}
                    show={showMaxPaymentAmountExceeded}
                    onClose={this.handleMaxPaymentAmountExceededModalClose}
                />
                <InformModal
                    headerText={LabelViewBillInfoHeader}
                    message={LabelViewBillInfoBody}
                    show={showViewBillInformModal}
                    onClose={this.handleViewBillInformModalClose}
                />
            </div>
        );
    }
}

export default injectIntl(
    connect(
        (state: IApplicationState) => ({
            criteria: state.billSearch.criteria,
            application: state.application,
            merchantName: state.settings.merchantName,
            bills: state.billSearch.bills,
            billsInCart: state.bills.bills,
            billTypes: state.billTypes.billTypes,
        }),
        {
            clearBills,
            newBillsearchSession,
        } as MapDispatchToPropsParam<IDispatchProps, IOwnProps>
    )(SearchResultContent)
);
