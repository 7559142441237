import React from "react";
import { Panel, Table } from "react-bootstrap";
import {
    HeaderAutoPay,
    HeaderEditPaymentInformation,
    HeaderLastPaidDate,
    HeaderNotifications,
} from "../../resources";
import { IBillDetail, IBillType, ISettings } from "../../types";
import {
    GetCardImageByPaymentMethod,
    GetCardName,
} from "../../utils/CardTypes";
import BillDetailRow from "./BillDetailRow";

export interface IBillDetailTableProps {
    selectedId: string;
    settings: ISettings;
    billType: IBillType;
    billDetails: IBillDetail[];
    isNewBillDetails: boolean;
    handleEdit: (event: any) => void;
    onAutoPayChanged: (event: any) => void;
    onNotificationChanged: (event: any) => void;
}

class BillDetailTable extends React.Component<IBillDetailTableProps, any> {
    constructor(props: any) {
        super(props);
    }
    buildTableRows = (props: IBillDetailTableProps) => {
        const { settings, billType, billDetails } = props;
        const billDetailsSort =
            billDetails &&
            billDetails.sort((a, b) => {
                return parseInt(a.identifier1) - parseInt(b.identifier1);
            });
        return (
            billDetailsSort &&
            billDetailsSort.map((billDetail: IBillDetail, index: number) => {
                if (billDetail) {
                    const paymentMethod = billDetail.paymentMethod;
                    const cardType = (
                        <span>
                            {GetCardImageByPaymentMethod(paymentMethod)}&nbsp;{" "}
                            <strong>{GetCardName(paymentMethod)}</strong>
                        </span>
                    );
                    return (
                        <BillDetailRow
                            key={billDetail.identifier1}
                            billDetail={billDetail}
                            billType={billType}
                            cardType={cardType}
                            settings={this.props.settings}
                            handleEdit={this.props.handleEdit}
                            selectedId={this.props.selectedId}
                            isNewBillDetails={this.props.isNewBillDetails}
                            onAutoPayChanged={this.props.onAutoPayChanged}
                            onNotificationChanged={
                                this.props.onNotificationChanged
                            }
                        />
                    );
                }
            })
        );
    };
    render() {
        const { billType, isNewBillDetails, selectedId, settings } = this.props;
        return (
            <div>
                {billType && (
                    <Panel>
                        <Panel.Heading>
                            {this.props.billType.name}
                        </Panel.Heading>
                        <Table
                            className="panel-heading"
                            condensed
                            bordered
                            responsive
                        >
                            <thead>
                                <tr>
                                    {billType.id1.label &&
                                    !billType.id1.isSearchOnly ? (
                                        <th>{billType.id1.label}</th>
                                    ) : null}
                                    {billType.id2.label &&
                                    !billType.id2.isSearchOnly ? (
                                        <th>{billType.id2.label}</th>
                                    ) : null}
                                    {billType.id3.label &&
                                    !billType.id3.isSearchOnly ? (
                                        <th>{billType.id3.label}</th>
                                    ) : null}
                                    {billType.id4.label &&
                                    !billType.id4.isSearchOnly ? (
                                        <th>{billType.id4.label}</th>
                                    ) : null}
                                    {!isNewBillDetails && !selectedId && (
                                        <th
                                            style={{ width: "120px" }}
                                            className="text-center"
                                        >
                                            {HeaderLastPaidDate}
                                        </th>
                                    )}
                                    {settings.allowAutoBillPay &&
                                        !isNewBillDetails &&
                                        !selectedId && (
                                            <th
                                                style={{ width: "320px" }}
                                                className="text-center"
                                            >
                                                {HeaderEditPaymentInformation}
                                            </th>
                                        )}
                                    {settings.allowAutoBillPay && (
                                        <th
                                            style={{ width: "100px" }}
                                            className="text-center"
                                        >
                                            {HeaderAutoPay}
                                        </th>
                                    )}
                                    {settings.enableNewBillNotifications && (
                                        <th
                                            style={{ width: "120px" }}
                                            className="text-center"
                                        >
                                            {HeaderNotifications}
                                        </th>
                                    )}
                                </tr>
                            </thead>
                            <tbody>{this.buildTableRows(this.props)}</tbody>
                        </Table>
                    </Panel>
                )}
            </div>
        );
    }
}

export default BillDetailTable;
