import React from "react";
import { IBillType, ICustomField } from "../../types";
import { LabelAmountDue } from "../../resources";

export interface BillTableHeaderProps {
    billType: IBillType;
    billHasImage: boolean;
    showEdit: boolean;
    allowBillAmountToBeEdited: boolean;
    isEBPP: boolean;
}

const showIDColumn = (field: ICustomField): boolean =>
    field.label && !field.isSearchOnly ? true : false;

export const BillTableHeader = (props: BillTableHeaderProps): JSX.Element => {
    const fixedColStyles = {width: "200px"};
    const actionColStyles = {width: "20px"};
    return (
        <tr>
            {showIDColumn(props.billType.id1) ? (
                <th style={fixedColStyles}>{props.billType.id1.label}</th>
            ) : null}
            {showIDColumn(props.billType.id2) ? (
                <th style={fixedColStyles}>{props.billType.id2.label}</th>
            ) : null}
            {showIDColumn(props.billType.id3) ? (
                <th style={fixedColStyles}>{props.billType.id3.label}</th>
            ) : null}
            {showIDColumn(props.billType.id4) ? (
                <th style={fixedColStyles}>{props.billType.id4.label}</th>
            ) : null}
            <th style={fixedColStyles}>{props.billType.amount.label}</th>
            {props.isEBPP && props.allowBillAmountToBeEdited && (
                <th style={fixedColStyles}>{LabelAmountDue}</th>
            )}
            {props.billHasImage && <th style={actionColStyles}></th>}
            {props.showEdit ? <th style={actionColStyles}></th> : null}
        </tr>
    );
};
