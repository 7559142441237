import React from "react";
import { IModalProps } from "../HeaderSanDiego";
import * as Resources from "../../../../resources";
import { Button, Glyphicon, Modal } from "react-bootstrap";

export default class ModalDisclaimer extends React.Component<IModalProps> {
    constructor(props: IModalProps) {
        super(props);
    }
    render() {
        return (
            <Modal
                onHide={this.props.hideModal}
                show={this.props.showComponent}
            >
                <Modal.Header className="modal-header">
                    <Button className="close" onClick={this.props.hideModal}>
                        <Glyphicon
                            glyph="remove"
                            style={{ fontSize: "16px" }}
                        />
                    </Button>
                    <Modal.Title>
                        {Resources.BillSearchNavbarDisclaimer}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <ul>
                        <li>{Resources.BillSearchDisclaimerParagraph1}</li>

                        <li>
                            {Resources.BillSearchDisclaimerParagraph2a}
                            {Resources.BillSearchDisclaimerParagraph2b}
                        </li>

                        <li>{Resources.BillSearchDisclaimerParagraph3}</li>

                        <li>{Resources.BillSearchDisclaimerParagraph4}</li>
                    </ul>
                </Modal.Body>
                <Modal.Footer>
                    <Button bsStyle="danger" onClick={this.props.hideModal}>
                        {Resources.ButtonClose}
                    </Button>
                </Modal.Footer>
            </Modal>
        );
    }
}
