import { Reducer } from "redux";
import { addLocaleData, Locale } from "react-intl";
import * as en from "react-intl/locale-data/en";
import * as es from "react-intl/locale-data/es";
import * as zh from "react-intl/locale-data/zh";
import * as vi from "react-intl/locale-data/vi";
import * as hy from "react-intl/locale-data/hy";
import * as ko from "react-intl/locale-data/ko";
import { getLanguageFromId, getMessages } from "../utils/Intl";
import { IInternationalization } from "../types";
import {
    INTL_LOAD,
    INTL_LOAD_SUCCESS,
    INTL_LOAD_FAIL,
    INTL_SET,
    ReceiveIntlAction,
    RequestIntlAction,
    FailedIntlAction,
    SetIntlAction,
    IntlActions,
} from "../actionTypes/Intl";
import {
    CONFIGURATION_LOAD_SUCCESS,
    CONFIGURATION_EBPP_LOAD_SUCCESS,
    CONFIGURATION_LOAD_FAIL,
    ReceiveConfigurationAction,
    ReceiveConfigurationEBPPAction,
    FailedConfigurationAction,
} from "../actionTypes/Configuration";

export const initialState: IInternationalization = {
    isLoaded: false,
    isLoading: false,
    locale: "en",
    messages: {},
    error: null,
};

const setupLocaleData = (locale: string) => {
    let localeData: Locale[];

    switch (locale) {
        case "es":
            localeData = es;
            break;
        case "hy":
            localeData = hy;
            break;
        case "ko":
            localeData = ko;
            break;
        case "vi":
            localeData = vi;
            break;
        case "zh":
            localeData = zh;
            break;
        default:
            localeData = en;
    }

    addLocaleData(localeData);
};

const intlLoadAction = (
    state: IInternationalization,
    action: RequestIntlAction
): IInternationalization => {
    return {
        ...state,
        isLoading: true,
        isLoaded: false,
        error: null,
    };
};

const intlLoadSuccessAction = (
    state: IInternationalization,
    action: ReceiveIntlAction
): IInternationalization => {
    const locale = getLanguageFromId(action.data);
    setupLocaleData(locale);

    return {
        ...state,
        isLoading: false,
        isLoaded: true,
        error: null,
        locale: locale,
        messages: getMessages(locale),
    };
};

const intlLoadFailAction = (
    state: IInternationalization,
    action: FailedIntlAction
): IInternationalization => {
    return {
        ...state,
        isLoading: false,
        isLoaded: false,
        error: action.data,
    };
};

const intlSetAction = (
    state: IInternationalization,
    action: SetIntlAction
): IInternationalization => {
    setupLocaleData(action.data);

    return {
        ...state,
        isLoading: false,
        isLoaded: true,
        error: null,
        locale: action.data,
        messages: getMessages(action.data),
    };
};

const configurationLoadSuccessAction = (
    state: IInternationalization,
    action: ReceiveConfigurationAction
): IInternationalization => {
    const locale = getLanguageFromId(action.data.intlId);
    setupLocaleData(locale);

    return {
        ...state,
        isLoading: false,
        isLoaded: true,
        error: null,
        locale: locale,
        messages: getMessages(locale),
    };
};

const configurationEbppLoadSuccessAction = (
    state: IInternationalization,
    action: ReceiveConfigurationEBPPAction
): IInternationalization => {
    const locale = getLanguageFromId(action.data.intlId);
    setupLocaleData(locale);

    return {
        ...state,
        isLoading: false,
        isLoaded: true,
        error: null,
        locale: locale,
        messages: getMessages(locale),
    };
};

const configurationLoadFailAction = (
    state: IInternationalization,
    action: FailedConfigurationAction
): IInternationalization => {
    return {
        ...state,
        isLoading: false,
        isLoaded: false,
        error: action.data,
    };
};

export const reducer: Reducer<IInternationalization> = (
    state: IInternationalization = initialState,
    action: IntlActions
) => {
    switch (action.type) {
        case INTL_LOAD:
            return intlLoadAction(state, action);
        case INTL_LOAD_SUCCESS:
            return intlLoadSuccessAction(state, action);
        case INTL_LOAD_FAIL:
            return intlLoadFailAction(state, action);
        case INTL_SET:
            return intlSetAction(state, action);
        case CONFIGURATION_LOAD_SUCCESS:
            return configurationLoadSuccessAction(state, action);
        case CONFIGURATION_EBPP_LOAD_SUCCESS:
            return configurationEbppLoadSuccessAction(state, action);
        case CONFIGURATION_LOAD_FAIL:
            return configurationLoadFailAction(state, action);
        default:
            return state;
    }
};
