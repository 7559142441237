import React from "react";
import { Checkbox, Col, ControlLabel, FormGroup, Row } from "react-bootstrap";
import * as Resources from "../../resources";
import {
    CountryInput,
    EmailInput,
    PhoneInput,
    PostalInput,
    StateInput,
    TextInput,
} from "../Input";

class BillingPanel extends React.Component<any, any> {
    private emailInput: any;
    public static defaultProps: Partial<any> = {
        hideCopyPayor: false,
    };

    constructor(props: any) {
        super(props);

        this.state = {
            sameAsPayor: !props.hideCopyPayor
                ? this.isSame(props.payor, props.payment)
                : false,
            sameAsProfile:
                props.hideCopyPayor && props.user
                    ? this.isSame(props.user.profile, props.payment)
                    : false,
            sendEmailCheckBox: this.props.payment.sendEmailOnACHReturn,
        };
        this.isSame = this.isSame.bind(this);
        this.handleChecked = this.handleChecked.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handlePhoneChange = this.handlePhoneChange.bind(this);
        this.handleCopyPayorChecked = this.handleCopyPayorChecked.bind(this);
        this.handleCopyProfileChecked =
            this.handleCopyProfileChecked.bind(this);
        this.handleIsValid = this.handleIsValid.bind(this);
    }

    componentDidMount() {
        const { payment, payor } = this.props;
        if (
            !payment.business &&
            !payment.firstName &&
            !payment.middleName &&
            !payment.lastName &&
            !payment.address &&
            !payment.state &&
            !payment.city &&
            !payment.postal &&
            !payment.phone
        ) {
            this.props.copyPayorInformation(payor);
        }
    }
    componentWillReceiveProps(nextProps: any) {
        if (nextProps.hideCopyPayor) {
            if (nextProps.user) {
                this.setState({
                    sameAsProfile: this.isSame(
                        nextProps.user.profile,
                        nextProps.payment
                    ),
                });
            }
        } else {
            this.setState({
                sameAsPayor: this.isSame(nextProps.payor, nextProps.payment),
            });
        }
        if (
            this.props.application.securePayPaymentType !== 3 &&
            this.props.application.isFutureDatedPayment !==
                nextProps.application.isFutureDatedPayment
        ) {
            if (!nextProps.application.isFutureDatedPayment) {
                this.emailInput.setCustomValidity("");
            }
        }
    }
    isSame(payor: any, payment: any) {
        const { hideCopyPayor, merchant } = this.props;
        return (
            (hideCopyPayor ||
                !merchant.businessNameIsVisible ||
                payment.business === payor.business) &&
            payment.firstName === payor.firstName &&
            payment.middleName === payor.middleName &&
            payment.lastName === payor.lastName &&
            payment.address === payor.address &&
            payment.country === payor.country &&
            payment.state === payor.state &&
            payment.city === payor.city &&
            payment.postal === payor.postal &&
            payment.phone === payor.phone &&
            payment.phoneCountry === payor.phoneCountry
        );
    }

    handleCopyProfileChecked(event: any) {
        if (
            event.target.checked &&
            this.props.user &&
            this.props.user.profile
        ) {
            this.props.copyPayorInformation(this.props.user.profile);
        }
        this.setState({
            sameAsProfile: event.target.checked,
        });
    }
    handleCopyPayorChecked(event: any) {
        const { payor, payment } = this.props;
        if (event.target.checked) {
            this.props.copyPayorInformation(payor);
        } else if (!event.target.checked) {
            if (this.props.clearPayorInformation) {
                this.props.clearPayorInformation();
            }
            if (payment && payment.customerAccountName) {
                this.props.onSavedAccountNotSameAsPayor(
                    payment.customerAccountName
                );
            }
        }
        this.setState({
            sameAsPayor: event.target.checked,
        });
    }
    handleIsValid() {
        if (this.props.isValid) {
            this.props.isValid();
        }
    }
    handleChange(event: any) {
        if (this.props.onChange) {
            this.props.onChange(event);
        }
    }
    handlePhoneChange(phone: string) {
        if (this.props.onPhoneChange) {
            this.props.onPhoneChange(phone);
        }
    }
    handleChecked = (event: any) => {
        this.setState({ sendEmailCheckBox: Boolean(event.target.checked) });

        if (this.props.onChange) {
            this.props.onChange(event);
        }

        if (
            event.target.name === "sendEmailOnACHReturn" &&
            !event.target.checked
        ) {
            this.emailInput.setCustomValidity("");
        }
    };

    render() {
        const {
            application,
            disabled,
            payment,
            errors,
            merchant,
            hideCopyPayor,
            hideCopyProfile,
            requiredToSaveACHForFutureUse,
            showACHReturnNotification
        } = this.props;
        const { sameAsPayor, sameAsProfile } = this.state;
        const isEmailRequired =
            payment.sendEmailOnACHReturn ||
            (application.securePayPaymentType !== 3 &&
                application.isFutureDatedPayment);
        const inputRef = (input: any) => {
            this.emailInput = input;
        };

        const stateProvidence =
            payment.country === "US" ? (
                <StateInput
                    disabled={disabled}
                    label={Resources.LabelState}
                    name="state"
                    value={payment.state}
                    error={errors.state}
                    onBlur={this.props.onBlur}
                    onChange={this.handleChange}
                    onError={this.props.onError}
                    required={merchant.stateIsRequired && !disabled}
                    blacklist={merchant.statesToDeny}
                    sameAsPayor={this.state.sameAsPayor}
                />
            ) : (
                <TextInput
                    disabled={disabled}
                    label={Resources.LabelStateProvince}
                    name="state"
                    value={payment.state}
                    error={errors.state}
                    onBlur={this.props.onBlur}
                    onChange={this.handleChange}
                    onError={this.props.onError}
                    required={merchant.stateIsRequired && !disabled}
                />
            );
        return (
            <div>
                {!hideCopyPayor && (
                    <Row>
                        <Col xs={12} sm={12} md={12}>
                            <FormGroup>
                                <Checkbox
                                    disabled={disabled}
                                    checked={sameAsPayor}
                                    onChange={this.handleCopyPayorChecked}
                                >
                                    {Resources.LabelSamePayorInfo}
                                </Checkbox>
                            </FormGroup>
                        </Col>
                    </Row>
                )}
                {!hideCopyProfile && (
                    <Row>
                        <Col xs={12} sm={12} md={12}>
                            <FormGroup>
                                <Checkbox
                                    disabled={disabled}
                                    checked={sameAsProfile}
                                    onChange={this.handleCopyProfileChecked}
                                >
                                    {Resources.LabelSameProfileInfo}
                                </Checkbox>
                            </FormGroup>
                        </Col>
                    </Row>
                )}
                {merchant.businessNameIsVisible && (
                    <Row>
                        <Col xs={12}>
                            <TextInput
                                label={Resources.LabelBusinessName}
                                disabled={disabled}
                                name="business"
                                value={payment.business}
                                error={errors.business}
                                onBlur={this.props.onBlur}
                                onChange={this.handleChange}
                                onError={this.props.onError}
                                required={
                                    merchant.businessNameIsRequired && !disabled
                                }
                            />
                        </Col>
                    </Row>
                )}
                <Row>
                    <Col xs={12} sm={4} md={4}>
                        <TextInput
                            label={Resources.LabelFirstName}
                            disabled={disabled}
                            name="firstName"
                            value={payment.firstName}
                            error={errors.firstName}
                            onChange={this.handleChange}
                            onError={this.props.onError}
                            onBlur={this.props.onBlur}
                            required={merchant.firstNameIsRequired && !disabled}
                        />
                    </Col>
                    <Col xs={12} sm={4} md={4}>
                        <TextInput
                            label={Resources.LabelMiddleName}
                            disabled={disabled}
                            name="middleName"
                            value={payment.middleName}
                            error={errors.middleName}
                            onChange={this.handleChange}
                            onError={this.props.onError}
                            onBlur={this.props.onBlur}
                            required={
                                merchant.middleNameIsRequired && !disabled
                            }
                        />
                    </Col>
                    <Col xs={12} sm={4} md={4}>
                        <TextInput
                            label={Resources.LabelLastName}
                            disabled={disabled}
                            name="lastName"
                            value={payment.lastName}
                            error={errors.lastName}
                            onChange={this.handleChange}
                            onError={this.props.onError}
                            onBlur={this.props.onBlur}
                            required={
                                (merchant.lastNameIsRequired && !disabled) ||
                                requiredToSaveACHForFutureUse
                            }
                        />
                    </Col>
                </Row>
                <Row>
                    <Col xs={12} sm={12} md={12}>
                        <TextInput
                            label={Resources.LabelAddress}
                            disabled={disabled}
                            name="address"
                            value={payment.address}
                            error={errors.address}
                            onChange={this.handleChange}
                            onError={this.props.onError}
                            onBlur={this.props.onBlur}
                            required={merchant.addressIsRequired && !disabled}
                        />
                    </Col>
                </Row>
                <Row>
                    <Col xs={12} sm={6} md={6}>
                        <TextInput
                            label={Resources.LabelCity}
                            disabled={disabled}
                            name="city"
                            value={payment.city}
                            error={errors.city}
                            onBlur={this.props.onBlur}
                            onChange={this.handleChange}
                            onError={this.props.onError}
                            required={merchant.cityIsRequired && !disabled}
                        />
                    </Col>
                    <Col xs={12} sm={6} md={6}>
                        {stateProvidence}
                    </Col>
                </Row>
                <Row>
                    <Col xs={12} sm={6} md={6}>
                        <PostalInput
                            label={Resources.LabelPostal}
                            disabled={disabled}
                            name="postal"
                            country={payment.country}
                            value={payment.postal}
                            error={errors.postal}
                            onBlur={this.props.onBlur}
                            onChange={this.handleChange}
                            onError={this.props.onError}
                            required={merchant.postalIsRequired && !disabled}
                            blacklist={merchant.postalCodesToDeny}
                            sameAsPayor={this.state.sameAsPayor}
                        />
                    </Col>
                    <Col xs={12} sm={6} md={6}>
                        <CountryInput
                            label={Resources.LabelCountry}
                            disabled={disabled}
                            name="country"
                            value={payment.country}
                            error={errors.country}
                            onBlur={this.props.onBlur}
                            onChange={this.handleChange}
                            onError={this.props.onError}
                            required={merchant.countryIsRequired && !disabled}
                        />
                    </Col>
                </Row>
                <Row>
                    <Col xs={12} sm={12} md={12}>
                        <PhoneInput
                            label={Resources.LabelPhoneNumber}
                            disabled={disabled}
                            name="phone"
                            countryValue={payment.phoneCountry}
                            value={payment.phone}
                            error={errors.phone}
                            onBlur={this.props.onBlur}
                            onChange={this.handlePhoneChange}
                            onError={this.props.onError}
                            onCountryChange={this.props.phoneCountryChange}
                            required={merchant.phoneIsRequired && !disabled}
                            isValid={this.handleIsValid}
                        />
                    </Col>
                </Row>
                {!this.props.hideReceiptEmail && (
                    <Row>
                        <Col xs={12} sm={12} md={12}>
                            <EmailInput
                                label={Resources.LabelEmail}
                                name="email"
                                value={payment.email}
                                error={errors.email}
                                onBlur={this.props.onBlur}
                                onChange={this.handleChange}
                                onError={this.props.onError}
                                required={isEmailRequired}
                                inputRef={inputRef}
                            />
                        </Col>
                    </Row>
                )}
                {showACHReturnNotification && !this.props.hideReceiptEmail && (
                    <Row>
                        <Col xs={12} sm={12} md={12}>
                            <ControlLabel>
                                {Resources.LabelACHReturn}
                            </ControlLabel>
                            <Checkbox
                                checked={this.state.sendEmailCheckBox}
                                name="sendEmailOnACHReturn"
                                onChange={this.handleChecked}
                            >
                                {Resources.MessageACHReturn}
                            </Checkbox>
                        </Col>
                    </Row>
                )}
            </div>
        );
    }
}

export default BillingPanel;
