import React from "react";
import { connect } from "react-redux";
import { RouteComponentProps } from "react-router-dom";

import { cancel } from "../../actions/Common";
import { updatePayor } from "../../actions/Payor";
import {
    logout,
    updateProfile,
    updateUser,
    userVerified,
} from "../../actions/User";
import { login } from "../../api/User";
import { LoginModal } from "../Layout/Header/LoginModal";
import PayorForm from "./PayorForm";

import { loadUserAccounts } from "../../actions/Account";
import { loadCustomerBillDetails } from "../../actions/BillDetails";
import {
    IApplication,
    IApplicationRoute,
    IApplicationState,
    IBill,
    IBillDetail,
    IInternationalization,
    IMyAccount,
    IPayor,
    ISettings,
    IUser,
} from "../../types";

export interface PayorStateProps
    extends RouteComponentProps<IApplicationRoute> {
    application: IApplication;
    settings: ISettings;
    intl: IInternationalization;
    billDetails: IBillDetail[];
    bills: IBill[];
    payor: IPayor;
    myAccount: IMyAccount;
    user: IUser;
}

export interface IPayorState {
    showLoginModal: boolean;
}

export interface PayorDispatchProps {
    cancel: VoidFunction;
    loadUserAccounts: (
        application: IApplication,
        merchantName: string,
        authToken: string,
        onError?: (text: string) => void
    ) => void;
    loadCustomerBillDetails: (
        merchantName: string,
        authToken: string,
        merchantCustomerId: string
    ) => void;
    logout: VoidFunction;
    updateUser: (user: IUser) => void;
    savePayor: (payor: IPayor) => void;
    updateProfile: (
        application: IApplication,
        merchantName: string,
        authToken: string,
        payor: IPayor,
        onError: (text: string | JSX.Element) => void,
        onSuccess: (email: string) => void,
        password: string | null
    ) => void;
    userVerified: (value: boolean) => void;
}

export type PayorProps = PayorStateProps &
    PayorDispatchProps &
    RouteComponentProps<IApplicationRoute>;

class Payor extends React.Component<PayorProps, IPayorState> {
    constructor(props: PayorProps) {
        super(props);

        this.state = {
            showLoginModal: false,
        };
    }

    private showLoginModal = (): void => {
        this.setState({ showLoginModal: true });
    };

    private hideLoginModal = (): void => {
        this.setState({ showLoginModal: false });
    };

    private onLogin = (
        userName: string,
        password: string,
        recaptcha?: string | null
    ): Promise<IUser> => {
        return login(
            this.props.application,
            this.props.settings.merchantName,
            userName,
            password,
            recaptcha
        );
    };

    render() {
        const requirePasswordReset =
            this.props.application.isEBPP &&
            this.props.settings.enableMyAccount &&
            this.props.bills.length > 0 &&
            this.props.user.requirePasswordReset;
        return (
            <div className="margin-top-20">
                <PayorForm
                    {...this.props}
                    initialPayor={this.props.payor}
                    showLoginModal={this.showLoginModal}
                />
                {this.props.settings.enableMyAccount && (
                    <LoginModal
                        application={this.props.application}
                        merchantName={this.props.settings.merchantName}
                        allowAutoPay={this.props.settings.allowAutoBillPay}
                        requireRecaptcha={this.props.settings.requireReCaptcha}
                        requireLoginEBPP={
                            this.props.application.isEBPP &&
                            this.props.settings.requireLoginEBPP
                        }
                        requirePasswordReset={requirePasswordReset}
                        locale={this.props.intl.locale}
                        show={this.state.showLoginModal}
                        allowGuestLogin={true}
                        user={this.props.user}
                        onClose={this.hideLoginModal}
                        onContinue={this.hideLoginModal}
                        updateUser={this.props.updateUser}
                        userVerified={this.props.userVerified}
                        login={this.onLogin}
                        logout={this.props.logout}
                        loadUserAccounts={this.props.loadUserAccounts}
                        loadCustomerBillDetails={
                            this.props.loadCustomerBillDetails
                        }
                        webPaymentsCustomerAccountLoginMessage={
                            this.props.settings
                                .webPaymentsCustomerAccountLoginMessage
                        }
                    />
                )}
            </div>
        );
    }
}

const mapStateToProps = (state: IApplicationState) => ({
    application: state.application,
    settings: state.settings,
    bills: state.bills.bills,
    billTypes: state.billTypes.billTypes,
    billDetails: state.billDetails.customerBillDetails,
    intl: state.intl,
    payor: state.payor,
    myAccount: state.myAccount,
    user: state.user,
});

const mapDispatchToProps = {
    savePayor: updatePayor,
    loadCustomerBillDetails,
    loadUserAccounts,
    cancel,
    updateProfile,
    updateUser,
    logout,
    userVerified,
};

export default connect(mapStateToProps, mapDispatchToProps)(Payor);
