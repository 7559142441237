import { getBills, getEBPPBills, getBillImage } from "../api/Bill";
import { UniqueId } from "../utils/UniqueId";

import {
  BILLS_LOAD,
  BILLS_LOAD_SUCCESS,
  BILLS_LOAD_FAIL,
  BILL_IMAGE_LOAD_SUCCESS,
  BILL_IMAGE_LOAD_FAIL,
  BILLS_CREATE,
  BILLS_UPDATE,
  BILLS_DELETE,
  BillsActions,
  BILLS_CLEAR,
} from "../actionTypes/Bills";
import { IApplication, IAppThunkAction, IBill } from "../types";
import { USER_UPDATE } from "../actionTypes/User";

export const loadBills =
  (merchantName: string, guid?: string): IAppThunkAction<BillsActions> =>
  (dispatch) => {
    getBills(merchantName, guid)
      .then((response) => {
        dispatch({ type: USER_UPDATE, data: response.authenticateCustomer });
        dispatch({ type: BILLS_LOAD_SUCCESS, data: response.bills });
      })
      .catch((error) => dispatch({ type: BILLS_LOAD_FAIL, data: error }));

    dispatch({ type: BILLS_LOAD });
  };

export const searchEBPP =
  (
    merchantName: string,
    guid: string,
    application: IApplication,
    authToken: string
  ): IAppThunkAction<BillsActions> =>
  (dispatch) => {
    getEBPPBills(merchantName, guid, application, null, authToken)
      .then((response) =>
        dispatch({ type: BILLS_LOAD_SUCCESS, data: response.bills })
      )
      .catch((error) => dispatch({ type: BILLS_LOAD_FAIL, data: error }));

    dispatch({ type: BILLS_LOAD });
  };

export const loadBillImage =
  (
    merchantName: string,
    billId: string,
    application: IApplication
  ): IAppThunkAction<BillsActions> =>
  (dispatch) => {
    getBillImage(merchantName, billId, application)
      .then((image) =>
        dispatch({ type: BILL_IMAGE_LOAD_SUCCESS, billId, data: image })
      )
      .catch((error) =>
        dispatch({ type: BILL_IMAGE_LOAD_FAIL, billId, data: error })
      );
  };

export const createOrUpdate =
  (bill: IBill): IAppThunkAction<BillsActions> =>
  (dispatch) => {
    if (bill.id) {
      dispatch({
        type: BILLS_UPDATE,
        data: bill,
      });
    } else {
      bill.id = UniqueId();
      dispatch({
        type: BILLS_CREATE,
        data: bill,
      });
    }
  };

export const deleteBill =
  (billId: string): IAppThunkAction<BillsActions> =>
  (dispatch) => {
    dispatch({
      type: BILLS_DELETE,
      data: billId,
    });
  };

export const clearBills = (): IAppThunkAction<BillsActions> => (dispatch) => {
  dispatch({
    type: BILLS_CLEAR,
    data: "",
  });
};
