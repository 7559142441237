import React from "react";
import { connect } from "react-redux";
import { RouteComponentProps } from "react-router-dom";
import { getLanguageCode } from "../../utils/Intl";
import { totalBillAmount } from "../../utils/BollettaMath";
import { getTemplateId } from "../../utils/Template";
import { loadUserAccounts } from "../../actions/Account";
import { clearBills, loadBillImage } from "../../actions/Bill";
import { loadCustomerBillDetails } from "../../actions/BillDetails";
import {
    loadPaymentTerms,
    updateAccepted,
    updateCaptcha,
} from "../../actions/PaymentTerms";
import { LoginModal } from "../Layout/Header/LoginModal";
import { updatePayor } from "../../actions/Payor";
import { logout, updateUser, userVerified } from "../../actions/User";
import { login } from "../../api/User";
import { create as createAccount } from "../../actions/Account";
import { setIsPaymentCompleted } from "../../actions/Application";
import { editCPCData } from "../../actions/Receipt";
import { save as saveReceipt } from "../../actions/Receipt";
import { cancel } from "../../actions/Common";
import VerifyPanel from "./VerifyPanel";
import {
    IApplication,
    IInternationalization,
    IUser,
    IBillDetail,
    IApplicationState,
    IReceipt,
    IApplicationRoute,
    IAccount,
} from "../../types";
import {
    IBill,
    IBillType,
    IPayment,
    IPayor,
    IPaymentTerms,
    ISettings,
} from "../../types";
import PaymentIsCompletedPanel from "../Payment/PaymentIsCompletedPanel";

interface VerifyStateProps {
    application: IApplication;
    bills: IBill[];
    billTypes: IBillType[];
    billDetails: IBillDetail[];
    intl: IInternationalization;
    payor: IPayor;
    payment: IPayment;
    paymentTerms: IPaymentTerms;
    receipt: IReceipt;
    settings: ISettings;
    shareTokenWithGroup: boolean;
    user: IUser;
    userAccounts: IAccount[];
    isPaymentCompleted: boolean;
}

export interface IVerifyState {
    showLoginModal: boolean;
}

interface VerifyDispatchProps {
    loadCustomerBillDetails: typeof loadCustomerBillDetails;
    loadUserAccounts: typeof loadUserAccounts;
    loadPaymentTerms: typeof loadPaymentTerms;
    logout: typeof logout;
    updateAccepted: typeof updateAccepted;
    updateCaptcha: typeof updateCaptcha;
    saveReceipt: typeof saveReceipt;
    editCPCData: typeof editCPCData;
    createAccount: typeof createAccount;
    savePayor: typeof updatePayor;
    updateUser: typeof updateUser;
    cancel: typeof cancel;
    userVerified: typeof userVerified;
    setIsPaymentCompleted: typeof setIsPaymentCompleted;
    clearBills: typeof clearBills;
    loadBillImage: typeof loadBillImage;
}

export type VerifyProps = VerifyStateProps &
    VerifyDispatchProps &
    RouteComponentProps<IApplicationRoute>;

class VerifyPage extends React.Component<VerifyProps, IVerifyState> {
    constructor(props: any) {
        super(props);

        this.state = {
            showLoginModal: false,
        };
    }

    private showLoginModal = (): void => {
        this.setState({ showLoginModal: true });
    };

    private hideLoginModal = (): void => {
        this.setState({ showLoginModal: false });
    };

    private onLogin = (
        userName: string,
        password: string,
        recaptcha?: string | null
    ): Promise<IUser> => {
        return login(
            this.props.application,
            this.props.settings.merchantName,
            userName,
            password,
            recaptcha
        );
    };

    public componentDidMount() {
            if (!(this.props.paymentTerms && this.props.paymentTerms.isLoaded)) {
            this.props.loadPaymentTerms(
                this.props.application,
                this.props.settings.merchantName,
                getTemplateId(
                    this.props.payment.paymentMethod,
                    this.props.settings
                ),
                this.props.payment.paymentMethod,
                getLanguageCode(this.props.intl.locale),
                this.props.payment.convenienceFee || 0
            );
        }
    }

    componentWillReceiveProps(nextProps: any) {
        if (
            this.props.application != nextProps.application ||
            this.props.settings.merchantName !=
                nextProps.settings.merchantName ||
            this.props.settings.creditPaymentTermsTemplateId !=
                nextProps.settings.creditPaymentTermsTemplateId ||
            this.props.settings.achPaymentTermsTemplateId !=
                nextProps.settings.achPaymentTermsTemplateId ||
            this.props.settings.debitPaymentTermsTemplateId !=
                nextProps.settings.debitPaymentTermsTemplateId ||
            this.props.payment.paymentMethod !=
                nextProps.payment.paymentMethod ||
            this.props.payment.convenienceFee !=
                nextProps.payment.convenienceFee
        ) {
            this.props.loadPaymentTerms(
                nextProps.application,
                nextProps.settings.merchantName,
                getTemplateId(
                    nextProps.payment.paymentMethod,
                    nextProps.settings
                ),
                nextProps.payment.paymentMethod,
                getLanguageCode(nextProps.intl.locale),
                nextProps.payment.convenienceFee
            );
        }
    }
    renderVerify() {
        const requirePasswordReset =
            this.props.application.isEBPP &&
            this.props.settings.enableMyAccount &&
            this.props.bills.length > 0 &&
            this.props.user.requirePasswordReset;
        return (
            <div>
                <VerifyPanel
                    application={this.props.application}
                    settings={this.props.settings}
                    intl={this.props.intl}
                    payment={this.props.payment}
                    paymentTerms={this.props.paymentTerms}
                    user={this.props.user}
                    bills={this.props.bills}
                    billDetails={this.props.billDetails}
                    billTotal={totalBillAmount(this.props.bills) || 0}
                    billTypes={this.props.billTypes}
                    payor={this.props.payor}
                    receipt={this.props.receipt}
                    userAccounts={this.props.userAccounts}
                    updateAccepted={this.props.updateAccepted}
                    updateCaptcha={this.props.updateCaptcha}
                    loadBillDetails={this.props.loadCustomerBillDetails}
                    loadBillImage={this.props.loadBillImage}
                    loadPaymentTerms={this.props.loadPaymentTerms}
                    savePayor={this.props.savePayor}
                    saveReceipt={this.props.saveReceipt}
                    editCPCData={this.props.editCPCData}
                    shareTokenWithGroup={this.props.shareTokenWithGroup}
                    createAccount={this.props.createAccount}
                    cancel={this.props.cancel}
                    history={this.props.history}
                    showLoginModal={this.showLoginModal}
                    setIsPaymentCompleted={this.props.setIsPaymentCompleted}
                    isPaymentCompleted={this.props.isPaymentCompleted}
                    clearBills={this.props.clearBills}
                />
                {this.props.settings.enableMyAccount && (
                    <LoginModal
                        application={this.props.application}
                        merchantName={this.props.settings.merchantName}
                        allowAutoPay={this.props.settings.allowAutoBillPay}
                        requireRecaptcha={this.props.settings.requireReCaptcha}
                        requireLoginEBPP={
                            this.props.application.isEBPP &&
                            this.props.settings.requireLoginEBPP
                        }
                        requirePasswordReset={requirePasswordReset}
                        locale={this.props.intl.locale}
                        show={this.state.showLoginModal}
                        allowGuestLogin={true}
                        user={this.props.user}
                        onClose={this.hideLoginModal}
                        onContinue={this.hideLoginModal}
                        updateUser={this.props.updateUser}
                        userVerified={this.props.userVerified}
                        login={this.onLogin}
                        logout={this.props.logout}
                        loadUserAccounts={this.props.loadUserAccounts}
                        loadCustomerBillDetails={
                            this.props.loadCustomerBillDetails
                        }
                        webPaymentsCustomerAccountLoginMessage={
                            this.props.settings
                                .webPaymentsCustomerAccountLoginMessage
                        }
                    />
                )}
            </div>
        );
    }
    render() {
        const { isPaymentCompleted } = this.props;
        return (
            <div className="margin-top-20">
                {isPaymentCompleted ? (
                    <PaymentIsCompletedPanel />
                ) : (
                    this.renderVerify()
                )}
            </div>
        );
    }
}

export default connect(
    (state: IApplicationState) => ({
        intl: state.intl,
        application: state.application,
        settings: state.settings,
        billDetails: state.billDetails.customerBillDetails,
        billTypes: state.billTypes.billTypes,
        bills: state.bills.bills,
        payor: state.payor,
        payment: state.payment,
        receipt: state.receipt,
        user: state.user,
        userAccounts: state.userAccounts.accounts,
        paymentTerms: state.paymentTerms,
        shareTokenWithGroup: state.bills.shareTokenWithGroup,
        isPaymentCompleted: state.application.isPaymentCompleted,
    }),
    {
        loadCustomerBillDetails,
        loadUserAccounts,
        loadPaymentTerms,
        editCPCData,
        saveReceipt,
        savePayor: updatePayor,
        updateUser,
        updateAccepted,
        updateCaptcha,
        createAccount,
        cancel,
        loadBillImage,
        logout,
        userVerified,
        setIsPaymentCompleted,
        clearBills,
    }
)(VerifyPage);
