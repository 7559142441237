import React from "react";
import { TextGridCell } from "./TextGridCell";
import { FormattedNumberGridCell } from "./FormattedNumberGridCell";
import { GridRowViewImageButton } from "./GridRowViewImageButton";
import { GridRowDeleteButton } from "./GridRowDeleteButton";
import { CurrencyInput } from "../Input";
import { isNullOrUndefined } from "../../utils/Utilities";
import { GetMaxPaymentAmount } from "../../utils/BillHelpers";
import { ModalTitleBill, MessagePaidInFull } from "../../resources";
import { CheckFieldValidity } from "../../utils/Validation";
import { IBill, IBillType, ICustomField } from "../../types";
import { IFormError } from "../../types/IFormError";

export interface BillTableRowProps {
    key: string;
    index: number;
    bill: IBill;
    billType: IBillType;
    isEBPP: boolean;
    showEdit: boolean;
    errors?: IFormError;
    billHasImage: boolean;
    maxPaymentAmount?: number;
    minPaymentAmount?: number;
    allowBillOverpayment?: boolean;
    allowBillAmountToBeEdited?: boolean;
    onOpenSampleImage?: (billId: string) => void;
    onDelete?: (billId: string) => void;
    onError?: (name: string, message: string) => void;
    saveBill?: (bill: IBill) => void;
}

const showIDColumn = (field: ICustomField): boolean =>
    field.label && !field.isSearchOnly ? true : false;

const normalizeValue = (value: string): string =>
    value === "" ? "\u00a0" : value;

const loadBillImageOnOpen =
    (billId: string, onOpenSampleImage?: (billId: string) => void) => () =>
        onOpenSampleImage ? onOpenSampleImage(billId) : null;

export const BillTableRow = (props: BillTableRowProps): JSX.Element => {
    const amountDue = !isNullOrUndefined(props.bill.amountDue)
        ? props.bill.amountDue!
        : props.bill.amount;
    const billAmount =
        props.isEBPP && props.allowBillAmountToBeEdited
            ? amountDue
            : props.bill.customAmount || amountDue;
    const billId =
        props.bill.id === "00000000-0000-0000-0000-000000000000"
            ? props.bill.billId.toString()
            : props.bill.id;
    const maxAmount = GetMaxPaymentAmount(
        props.allowBillOverpayment!,
        props.maxPaymentAmount!,
        props.bill.amountDue || props.bill.amount
    );
    const calculateAmount = function (record: any) {
        if (record.useCustom) {
            return record.customAmount;
        }
        return (
            record.customAmount ||
            (record.amountDue < 0
                ? Number(0).toFixed(2)
                : record.amountDue.toFixed(2))
        );
    };

    const onBlur = (
        event: React.FocusEvent<HTMLInputElement | HTMLSelectElement>
    ) => {
        CheckFieldValidity(event.target, props.onError);
    };

    const onChange = (
        event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
    ) => {
        const field: string = event.target.name;
        const updatedState = { [field]: event.target.value || null };

        if (event.target.validity.valid) {
            CheckFieldValidity(event.target, props.onError);
        }

        const billParam = { ...props.bill, ...updatedState };
        props.saveBill!(billParam);
    };

    return (
        <tr>
            {showIDColumn(props.billType.id1) ? (
                <TextGridCell text={normalizeValue(props.bill.id1)} />
            ) : null}
            {showIDColumn(props.billType.id2) ? (
                <TextGridCell text={normalizeValue(props.bill.id2)} />
            ) : null}
            {showIDColumn(props.billType.id3) ? (
                <TextGridCell text={normalizeValue(props.bill.id3)} />
            ) : null}
            {showIDColumn(props.billType.id4) ? (
                <TextGridCell text={normalizeValue(props.bill.id4)} />
            ) : null}
            {props.isEBPP && billAmount === 0 ? (
                <td>{MessagePaidInFull}</td>
            ) : (
                <FormattedNumberGridCell value={billAmount} />
            )}
            {props.isEBPP && props.allowBillAmountToBeEdited && (
                <td
                    style={{
                        verticalAlign: "middle",
                        minWidth: "7.0em",
                        maxWidth: "9.0em",
                    }}
                >
                    <CurrencyInput
                        name="customAmount"
                        value={calculateAmount(props.bill)}
                        maxAmount={maxAmount}
                        minAmount={props.minPaymentAmount}
                        disableScrollStep={true}
                        disableArrowStep={true}
                        style={{
                            verticalAlign: "middle",
                            minWidth: "6.0em",
                            maxWidth: "7.0em",
                        }}
                        error={props.errors!.customAmount}
                        onBlur={onBlur}
                        onChange={onChange}
                        onError={props.onError}
                        disabled={
                            amountDue <= 0 && !props.allowBillOverpayment
                                ? true
                                : false
                        }
                    />
                </td>
            )}
            {props.billHasImage &&
                (props.bill.hasImage ? (
                    <GridRowViewImageButton
                        title={ModalTitleBill}
                        imageContent={props.bill.imageContent}
                        detectedMIMEType={props.bill.detectedMIMEType}
                        onOpenSampleImage={loadBillImageOnOpen(
                            billId,
                            props.onOpenSampleImage
                        )}
                    />
                ) : (
                    <td></td>
                ))}
            {props.showEdit && (
                <GridRowDeleteButton
                    billId={props.bill.id}
                    onDelete={props.onDelete}
                />
            )}
        </tr>
    );
};
