import {
    faCircleNotch,
    faExclamationTriangle,
    faInfoCircle,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { History } from "history";
import React from "react";
import { Alert, Button, Col, Panel, Row, Table } from "react-bootstrap";
import { FormattedDate } from "react-intl";
import { makePayment, schedulePayment } from "../../api/Payment";
import { GetDataPostData } from "../../helpers/DataPost";
import {
    ButtonBack,
    ButtonChangePaymentMethod,
    ButtonSchedulePayment,
    ButtonSubmitPayment,
    HeaderReviewPage,
    LabelScheduledDate,
    MessageAgreeTerms,
    MessagePaymentFailed,
    MessagePaymentProcessing,
    MessageScheduledPaymentFailed,
} from "../../resources";
import {
    IAccount,
    IApplication,
    IBill,
    IBillDetail,
    IBillType,
    IInternationalization,
    IPayment,
    IPaymentTerms,
    IPayor,
    IReceipt,
    ISettings,
    ITransaction,
    IUser,
} from "../../types";
import { IAuthorization } from "../../types/IAuthorization";
import { ICpcData, ICpcDataRequest } from "../../types/ICpcData";
import { IFutureDatedPaymentResponse } from "../../types/IFutureDatedPaymentResponse";
import { GetCardLast4, GetCpcDescription } from "../../utils/CardTypes";
import { getLanguageCode } from "../../utils/Intl";
import { Post } from "../../utils/Post";
import { BillGrid } from "../Bill/BillGrid";
import AutoPayButton from "../BillDetail/AutoPayButton";
import { CancelButton } from "../Input";
import { ButtonBar } from "../Layout/ButtonBar";
import FeeTable from "../Payment/FeeTable";
import { CpcDataModal } from "./CpcDataModal";
import PaymentInformation from "./PaymentInformation";
import PaymentTerms from "./PaymentTerms";
import PayorInformation from "./PayorInformation";
import Recaptcha from "react-google-recaptcha";
import { RECAPTCHA_SITEKEY } from "../../core/constants";
import { setIsPaymentCompleted } from "../../actions/Application";
import PaymentIsCompletedAlert from "../Payment/PaymentIsCompletedPanel";
import { truncate } from "lodash";
import { clearBills } from "../../actions/Bill";

export interface IVerifyPanelProps {
    application: IApplication;
    settings: ISettings;
    intl: IInternationalization;
    payment: IPayment;
    paymentTerms: IPaymentTerms;
    user: IUser;
    bills: IBill[];
    billTotal: number;
    billDetails: IBillDetail[];
    billTypes: IBillType[];
    payor: IPayor;
    receipt: IReceipt;
    userAccounts: IAccount[];
    shareTokenWithGroup: boolean;
    history: History;
    updateAccepted: (value: boolean) => void;
    updateCaptcha: (value: string) => void;
    loadBillDetails: (
        merchantName: string,
        authToken: string,
        merchantCustomerId: string
    ) => void;
    loadPaymentTerms: (
        application: IApplication,
        merchantName: string,
        templateId: number,
        methodId: number,
        languageId: number,
        feeAmount: number
    ) => void;
    loadBillImage: (
        merchantName: string,
        billId: string,
        application: IApplication
    ) => void;
    saveReceipt: (
        billTypes: IBillType[],
        bills: IBill[],
        payor: IPayor,
        payment: IPayment,
        transaction: ITransaction,
        paymentTerms: string
    ) => void;
    editCPCData: (
        application: IApplication,
        merchantName: string,
        languageId: number,
        request: ICpcDataRequest
    ) => void;
    createAccount: (account: IAccount) => void;
    savePayor: (payor: IPayor) => void;
    showLoginModal: () => void;
    cancel: () => void;
    setIsPaymentCompleted: typeof setIsPaymentCompleted;
    isPaymentCompleted: boolean;
    clearBills: typeof clearBills;
}

export interface IVerifyPanelState {
    showCpcDataModal: boolean;
    submitting: boolean;
    isDeclined: boolean;
    error: string;
    errorIsHtml: boolean;
}

class VerifyPanel extends React.Component<
    IVerifyPanelProps,
    IVerifyPanelState
> {
    private recaptcha = React.createRef<Recaptcha>();

    constructor(props: IVerifyPanelProps) {
        super(props);

        this.state = {
            showCpcDataModal: false,
            submitting: false,
            isDeclined: false,
            error: "",
            errorIsHtml: false,
        };

        this.handleAutoPayClick = this.handleAutoPayClick.bind(this);
        this.handleBackButton = this.handleBackButton.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.onResolved = this.onResolved.bind(this);
    }

    public componentDidMount() {
        const {
            application,
            settings,
            payment,
            updateAccepted,
            updateCaptcha,
        } = this.props;
        if (application.guid && application.expired) {
            const path = `/${settings.merchantName}/expired/${application.guid}`;
            this.props.history.push(path);
            return;
        }
        if (application.guid && application.canceled) {
            const path = `/${settings.merchantName}/canceled/${application.guid}`;
            this.props.history.push(path);
            return;
        }
        if (!payment.saved) {
            const path = application.guid
                ? `/${settings.merchantName}/payment/${application.guid}`
                : `/${settings.merchantName}/payment`;
            this.props.history.push(path);
            return;
        }
        if (settings.skipPayorPage || payment.email) {
            this.updatePayorWithPaymentInfo();
        }
        updateAccepted(false);
        updateCaptcha("");
    }

    private updatePayorWithPaymentInfo = () => {
        const { payment, settings } = this.props;
        const payor = { ...this.props.payor };
        if (settings.skipPayorPage) {
            payor.address = payment.address;
            payor.business = payment.business;
            payor.city = payment.city;
            payor.country = payment.country;
            payor.email = payment.email;
            payor.firstName = payment.firstName;
            payor.lastName = payment.lastName;
            payor.middleName = payment.middleName;
            payor.mobilePhone = payment.mobilePhone;
            payor.mobilePhoneCountry = payment.mobilePhoneCountry;
            payor.phone = payment.phone;
            payor.phoneCountry = payment.phoneCountry;
            payor.postal = payment.postal;
            payor.state = payment.state;
        } else if (payment.email) {
            payor.email = payment.email;
        }
        this.props.savePayor(payor);
    };

    private handleBackButton(event: any): void {
        const { application, settings } = this.props;

        const path = application.guid
            ? `/${settings.merchantName}/payment/${application.guid}`
            : `/${settings.merchantName}/payment`;

        this.props.history.push(path);
    }

    private handleSubmit(event: any): void {
        const {
            application: { id, guid },
            paymentTerms: { accepted },
            settings: { requireReCaptcha },
        } = this.props;

        if (!accepted && id !== 35) {
            if (requireReCaptcha) {
                this.recaptcha.current?.reset();
            }
            this.setState({
                submitting: false,
                error: MessageAgreeTerms.props.defaultMessage,
            });

            return;
        }

        this.setState({
            submitting: true,
            error: "",
        });

        this.checkRecaptcha();
    }

    private handleAutoPayClick() {
        const { application, settings, user } = this.props;
        if (
            settings.enableMyAccount &&
            application.id === 33 &&
            !(user && user.isLoggedIn)
        ) {
            this.props.showLoginModal!();
        } else {
            const path = application.guid
                ? `/${settings.merchantName}/billdetail/${application.guid}`
                : `/${settings.merchantName}/billdetail`;
            this.props.history.push(path);
            return;
        }
    }

    private async checkRecaptcha() {
        const useCaptcha = this.props.settings.requireReCaptcha || this.props.payment.googlePayToken; 
        if (useCaptcha && this.recaptcha) { 
            this.setState(
            {
              submitting: false,
            });
            const token = await this.recaptcha.current?.executeAsync();
            this.setState(
            {
                submitting: true,
            });

            this.onResolved(token);
        } else {
            this.onResolved();
        }
    }

    private loadImageForBill = (billId: string) => {
        return this.props.loadBillImage(
            this.props.settings.merchantName,
            billId,
            this.props.application
        );
    };

    private isFuturePayment = (): boolean => {
        const { application, payment } = this.props;
        return payment.futurePaymentDate && application.isFutureDatedPayment
            ? true
            : false;
    };

    private clearSessionAndReloadPage() {
        sessionStorage.clear();
        window.location.reload();
    }

    private makePayment = (token?: string | null) => {
        const {
            application,
            intl,
            settings,
            user,
            billTypes,
            bills,
            payor,
            payment,
            paymentTerms,
            saveReceipt,
            createAccount,
            loadBillDetails,
            shareTokenWithGroup,
            setIsPaymentCompleted,
            clearBills,
        } = this.props;
        //const recaptcha = this.recaptcha ? this.recaptcha.getResponse() : "";

        bills.forEach((bill) => {
            bill.id2 = truncate(bill.id2, { length: 50 });
        });

        makePayment(
            application,
            settings.merchantName,
            getLanguageCode(intl.locale),
            bills,
            user.merchantCustomerID,
            payor,
            payment,
            paymentTerms.accepted,
            shareTokenWithGroup,
            token
        )
            .then((result: IReceipt) => {
                if (result.isSuccessful) {
                    if (user.isLoggedIn)
                        loadBillDetails(
                            settings.merchantName,
                            user.authToken,
                            user.merchantCustomerID
                        );
                    const transactionParam = {
                        ...result.transaction,
                        cpcIndicator: result.cpcIndicator,
                        authorizations: result.authorizations,
                    } as ITransaction;
                    saveReceipt(
                        billTypes,
                        bills,
                        payor,
                        payment,
                        transactionParam,
                        paymentTerms.isLoaded && paymentTerms.accepted
                            ? paymentTerms.terms
                            : ""
                    );
                    clearBills();
                    if (result.accountSaved) {
                        const account: IAccount = {
                            nickname: result.savedCustomerAccountName!,
                            cardNumber: payment.cardNumber,
                            isDefault: true,
                            paymentMethod: payment.paymentMethod,
                            achAccountType: "",
                            achDepositType: "",
                            accountNumber:
                                payment.paymentMethod === 7
                                    ? GetCardLast4(payment.accountNumber)
                                    : GetCardLast4(payment.cardNumber),
                            accountType: payment.accountType,
                            routingNumber: payment.routingNumber,
                            expirationMonth: Number(
                                payment.expirationMonth || 0
                            ),
                            expirationYear: Number(payment.expirationYear || 0),
                            address: payment.address,
                            city: payment.city,
                            state: payment.state,
                            postal: payment.postal,
                            nameOnAccount: payment.nameOnAccount,
                            phone: payment.phone,
                            phoneCountry: payment.phoneCountry,
                            firstName: payment.firstName,
                            lastName: payment.lastName,
                            middleName: payment.middleName,
                            token: "",
                            tokenPaymentMethod: 0,
                            mobilePhone: payment.mobilePhone,
                            mobilePhoneCountry: payment.mobilePhoneCountry,
                        };
                        createAccount(account);
                    }
                    if (
                        (result.cpcIndicator &&
                        result.cpcIndicator !== "0" &&
                        !bills[0].purchaseOrder) &&
                        this.props.application.id === 35
                    ) {
                        this.setState({ showCpcDataModal: true });                        
                    } else {
                        setIsPaymentCompleted(true);
                        this.getReceiptOrDataPost();
                    }
                } else {
                    let error =
                        "There was an error processing your request, please try again later.";
                    let errorIsHtml = false;
                    if (result.messages[0]) {
                        // If the data post failed but the status was zero (Succesful), display the custom decline message if it exists
                        if (
                            result.messages[0].includes("Data Post failed") &&
                            settings.dataPostDeclineMessage &&
                            result.dataPostStatusCode === 0
                        ) {
                            error = settings.dataPostDeclineMessage;
                            errorIsHtml = true;
                        } else {
                            error = result.messages[0];
                        }
                    }

                    if (settings.requireReCaptcha) {
                        this.recaptcha.current?.reset();
                    }
                    this.setState({
                        isDeclined: result.isDeclined,
                        submitting: false,
                        error,
                        errorIsHtml: errorIsHtml,
                    });

                    if (result.isMaintenance) {
                        this.clearSessionAndReloadPage();
                    }
                }
            })
            .catch((reason: any) => {
                if (settings.requireReCaptcha) {
                    this.recaptcha.current?.reset();
                }
                this.setState({
                    submitting: false,
                    error: "There was an error processing your request, please try again later.",
                    errorIsHtml: false,
                });
            });
    };

    private schedulePayment = (token?: string | null) => {
        const {
            application,
            intl,
            settings,
            user,
            bills,
            billTypes,
            payor,
            payment,
            paymentTerms,
            shareTokenWithGroup,
            saveReceipt,
        } = this.props;
        //const recaptcha = this.recaptcha ? this.recaptcha.getResponse() : "";

        schedulePayment(
            application,
            settings.merchantName,
            getLanguageCode(intl.locale),
            bills,
            user,
            payor,
            payment,
            paymentTerms.accepted,
            shareTokenWithGroup,
            token
        )
            .then((result: IFutureDatedPaymentResponse) => {
                if (result.isSuccessful) {
                    const transaction = {
                        applicationId: application.id,
                        amount: payment.total! - payment.convenienceFee!,
                        feeAmount: payment.convenienceFee,
                        paymentMethod: payment.paymentMethod,
                        transactionDateUTC: payment.futurePaymentDate,
                        maskedAccountNumber: null,
                        transactionId: 0,
                        templateId: 0,
                        language: 0,
                        token: result.token,
                    } as ITransaction;
                    if (
                        application.id !== 33 &&
                        !settings.showReceipt &&
                        settings.returnUrl
                    ) {
                        const data = GetDataPostData(
                            application,
                            payor,
                            payment,
                            transaction
                        );
                        Post(settings.returnUrl, data);
                    } else {
                        saveReceipt(
                            billTypes,
                            bills,
                            payor,
                            payment,
                            transaction,
                            paymentTerms.isLoaded && paymentTerms.accepted
                                ? paymentTerms.terms
                                : ""
                        );
                        const path = `/${settings.merchantName}/paymentscheduled`;
                        this.props.history.push(path);
                    }
                } else {
                    let error =
                        "There was an error processing your request, please try again later.";
                    const errorIsHtml = false;
                    if (result.messages[0]) {
                        error = result.messages[0];
                    }

                    if (settings.requireReCaptcha) {
                        this.recaptcha.current?.reset();
                    }
                    this.setState({
                        submitting: false,
                        error,
                        errorIsHtml: errorIsHtml,
                    });
                }
            })
            .catch((reason: any) => {
                if (settings.requireReCaptcha) {
                    this.recaptcha.current?.reset();
                }
                this.setState({
                    submitting: false,
                    error: "There was an error processing your request, please try again later.",
                    errorIsHtml: false,
                });
            });
    };

    private onResolved(token?: string | null): void {
        if (this.isFuturePayment()) {
            this.schedulePayment(token);
        } else {
            this.makePayment(token);
        }
    }

    private showCpcDataModal = (): void => {
        this.setState({ showCpcDataModal: true });
    };

    private hideCpcDataModal = (): void => {
        this.setState(
            {
                showCpcDataModal: false,
                submitting: false,
            },
            () => this.getReceiptOrDataPost()
        );
    };

    private getReceiptOrDataPost = (): void => {
        const { application, settings, payor, payment, receipt } = this.props;
        if (
            application.id !== 33 &&
            !settings.showReceipt &&
            settings.returnUrl
        ) {
            const data = GetDataPostData(
                application,
                payor,
                payment,
                receipt.transaction
            );
            Post(settings.returnUrl, data);
        } else {
            const path = `/${settings.merchantName}/receipt`;
            this.props.history.push(path);
        }
    };

    private saveCPCEdit = (cpcData: ICpcData): void => {
        const {
            intl,
            application,
            settings,
            bills,
            receipt: { transaction },
        } = this.props;
        let taxtype = "";

        const editCpcRequest = {
            cardHolderPONbr: cpcData.merchantPONumber,
            gatewayTxnId: "",
            taxAmount: null,
            taxType: "",
            x_global_transaction_id: "",
            endUserIPAddress: "",
            endUserBrowserType: "",
            transactionId: transaction.transactionId
        } as ICpcDataRequest;

        let taxAmt: number | null = null;
        if (bills != null && bills.length > 0) {
            taxAmt = bills[0].taxAmount;
        }

        if (
            transaction.authorizations &&
            transaction.authorizations.length > 0
        ) {
            transaction.authorizations.forEach((authorization) => {
                editCpcRequest.gatewayTxnId = authorization.gatewayReferenceId;
                if (authorization.type === 1) {
                    if (taxAmt != null) {
                        taxtype = "SALESTAX";
                    } else if (cpcData.isTaxExempt) {
                        taxtype = "TAXEXEMPT";
                    } else {
                        taxtype = "NOTUSED";
                    }
                    editCpcRequest.taxType = taxtype;
                    editCpcRequest.taxAmount =
                        taxtype === "SALESTAX" ? taxAmt : null;
                } else if (authorization.type === 2) {
                    editCpcRequest.taxAmount = null;
                    editCpcRequest.taxType = "NOTUSED";
                }
                this.props.editCPCData(
                    application,
                    settings.merchantName,
                    getLanguageCode(intl.locale),
                    editCpcRequest
                );
            });
        }

        this.getReceiptOrDataPost();
    };

    public render(): JSX.Element {
        const {
            intl,
            application,
            settings,
            payor,
            payment,
            userAccounts,
            billTotal,
            isPaymentCompleted,
        } = this.props;
        const { submitting, isDeclined, error, errorIsHtml } = this.state;
        const submitButtonText = submitting ? (
            <FontAwesomeIcon icon={faCircleNotch} spin />
        ) : this.isFuturePayment() ? (
            ButtonSchedulePayment
        ) : (
            ButtonSubmitPayment
        );

        const cancelButton = (
            <CancelButton
                disabled={submitting}
                application={this.props.application}
                settings={this.props.settings}
                history={this.props.history}
                cancel={this.props.cancel}
            />
        );
        const continueButton = isDeclined ? (
            <Button
                bsClass="btn btn-primary btn-justified"
                onClick={this.handleBackButton}
                style={{ minWidth: "6.5em" }}
            >
                {ButtonChangePaymentMethod}
            </Button>
        ) : (
            <Button
                bsClass="btn btn-primary btn-justified"
                onClick={this.handleSubmit}
                disabled={submitting}
                style={{ minWidth: "6.5em" }}
            >
                {" "}
                {submitButtonText}{" "}
            </Button>
        );

        const actionButton = (
            <Button
                bsClass="btn btn-default"
                onClick={this.handleBackButton}
                disabled={submitting}
                style={{ minWidth: "6.5em" }}
            >
                {ButtonBack}
            </Button>
        );
        const autoPayButton = (
            <AutoPayButton
                user={this.props.user}
                bills={this.props.bills}
                billTypes={this.props.billTypes}
                billDetails={this.props.billDetails}
                settings={this.props.settings}
                submitting={this.state.submitting}
                onClick={this.handleAutoPayClick}
            />
        );

        // Fix Google's null style error
        if ((document.querySelector("body") as any).setActive != undefined) {
            (document.querySelector("body") as any).setActive();
        }
        const futurePaymentDate = payment.futurePaymentDate || new Date();
        const scheduledDate = (
            <FormattedDate
                value={futurePaymentDate}
                year="numeric"
                month="long"
                day="2-digit"
            />
        );
        const messageFailed = application.isFutureDatedPayment
            ? MessageScheduledPaymentFailed
            : MessagePaymentFailed;
        const cpcDescription = GetCpcDescription(
            this.props.receipt.transaction.cpcIndicator || ""
        );
        return (
            <div>
                <Panel bsStyle="primary">
                    <Panel.Heading>{HeaderReviewPage}</Panel.Heading>
                    <Panel.Body>
                        <Row>
                            <Col xs={12} sm={12} md={12} lg={12}>
                                <BillGrid
                                    intl={this.props.intl}
                                    billTypes={this.props.billTypes}
                                    bills={this.props.bills}
                                    isEBPP={this.props.application.isEBPP}
                                    showEdit={false}
                                    loadImageForBill={this.loadImageForBill}
                                />
                            </Col>
                        </Row>
                        <div className="well">
                            <Row>
                                <Col xs={12} sm={6} md={6} lg={6}>
                                    <PayorInformation
                                        application={application}
                                        history={this.props.history}
                                        merchant={settings}
                                        payor={payor}
                                        showEdit={false}
                                    />
                                </Col>
                                <Col xs={12} sm={6} md={6} lg={6}>
                                    <PaymentInformation
                                        application={application}
                                        history={this.props.history}
                                        merchant={settings}
                                        payment={payment}
                                        userAccounts={userAccounts}
                                        showEdit={false}
                                    />
                                </Col>
                            </Row>
                        </div>
                        <Row>
                            <Col xs={12} sm={12} mdOffset={1} md={10}>
                                <FeeTable
                                    merchant={settings}
                                    payment={payment}
                                    billTotal={billTotal}
                                />
                            </Col>
                        </Row>
                        {this.isFuturePayment() && (
                            <Row>
                                <Col xs={12} sm={12} mdOffset={1} md={10}>
                                    <Table bordered>
                                        <tbody>
                                            <tr>
                                                <td>
                                                    <strong>
                                                        {LabelScheduledDate}
                                                    </strong>
                                                </td>
                                                <td>
                                                    <span className="pull-right">
                                                        {scheduledDate}
                                                    </span>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </Table>
                                </Col>
                            </Row>
                        )}
                        {application.id !== 35 && (
                            <Row>
                                <Col xs={12} sm={12}>
                                    <div>
                                        <PaymentTerms {...this.props} />
                                    </div>
                                </Col>
                            </Row>
                        )}
                        <Row>
                            <Col xs={12} sm={12}>
                                {submitting && (
                                    <Alert bsStyle="info">
                                        <FontAwesomeIcon icon={faInfoCircle} />{" "}
                                        <strong>
                                            {MessagePaymentProcessing}
                                        </strong>
                                    </Alert>
                                )}
                                {error && errorIsHtml && (
                                    <div
                                        dangerouslySetInnerHTML={{
                                            __html: error,
                                        }}
                                    />
                                )}
                                {error && !errorIsHtml && (
                                    <Alert bsStyle="danger">
                                        <FontAwesomeIcon
                                            icon={faExclamationTriangle}
                                        />{" "}
                                        <strong>{messageFailed}</strong> {error}
                                    </Alert>
                                )}
                            </Col>
                        </Row>
                        {isPaymentCompleted ? (
                            <PaymentIsCompletedAlert />
                        ) : (
                            <ButtonBar
                                cancelButton={cancelButton}
                                leftActionButton={actionButton}
                                middleActionButton={autoPayButton}
                                rightActionButton={continueButton}
                            />
                        )}
                        {settings.requireReCaptcha && (
                            <div style={{ display: "none" }}>
                                <Recaptcha
                                    ref={this.recaptcha}
                                    hl={intl.locale}
                                    sitekey={RECAPTCHA_SITEKEY}
                                    size="invisible"
                                />
                            </div>
                        )}
                    </Panel.Body>
                </Panel>
                <CpcDataModal
                    application={this.props.application}
                    merchantName={this.props.settings.merchantName}
                    cpcDescription={cpcDescription}
                    locale={this.props.intl.locale}
                    showModal={this.state.showCpcDataModal}
                    close={this.hideCpcDataModal}
                    saveCPCEdit={this.saveCPCEdit}
                />
            </div>
        );
    }
}

export default VerifyPanel;
