import React from "react";
import { Image } from "react-bootstrap";

export interface MerchantBannerProps {
    bannerImage: string | null;
    bannerImageMobile?: string | null;
}

const MerchantBanner = (props: MerchantBannerProps) => {
    return (
        <div>
            <div className="hidden-xs">
                {props.bannerImage && (
                    <Image src={props.bannerImage} responsive />
                )}
            </div>
            <div className="visible-xs">
                {props.bannerImageMobile && (
                    <Image src={props.bannerImageMobile} responsive />
                )}
            </div>
        </div>
    );
};

export default MerchantBanner;
