import React from "react";
import SelectInput from "./SelectInput";

import CountryList from "./CountryList";

export class CountryInput extends React.Component<any, any> {
    constructor(props: any) {
        super(props);
        this.state = {
            options: [],
        };
    }

    componentWillMount() {
        const options = CountryList.getCountries();
        this.setState({
            options: options,
        });
    }

    render() {
        const props = Object.assign({}, this.props);
        return <SelectInput {...props} options={this.state.options} />;
    }
}
