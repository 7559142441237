import React from "react";
import { Button, Form } from "react-bootstrap";
import { injectIntl } from "react-intl";
import { connect } from "react-redux";
import { clearBills } from "../../actions/Bill";
import {
    newBillsearchSession,
    reset,
    search,
    updateCriteria,
} from "../../actions/BillSearch";
import "../../css/SanDiegoBillSearch/searchPage.css";
import * as Resources from "../../resources";
import { IApplicationState, IBillSearchCriteria, IBillType } from "../../types";
import { CheckFieldValidity } from "../../utils/Validation";
import { NumberInput } from "../Input";
import { IComponentProps, SearchByOptionsBase } from "./SearchByOptionsBase";
import {
    SanDiegoBillType,
    UnsecuredBillNumberRegExpPattern,
    YearRegExpPattern,
} from "./types";

class SearchByUnsecuredBillNumberBlock extends SearchByOptionsBase {
    protected SearchBlockGroup = "SEARCH_BY_UNSECURE_BILL_NUMBER";
    constructor(props: IComponentProps) {
        super(props);

        this.formId = "search-by-unsecured-bill-number-block-form";
    }

    handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        event.preventDefault();

        if (!event.target.validity.valid) {
            CheckFieldValidity(event.target, this.handleError);
        }

        const field = event.target.name;
        const searchCriteria = { ...this.props.criteria.meta.searchCriteria };

        if (field === "year") {
            searchCriteria.year = event.target.value;
        } else if (field === "unsecuredBillNumber") {
            searchCriteria.billNumber = event.target.value;
        }

        this.props.updateCriteria({
            ...this.props.criteria,
            id1: `${searchCriteria.year}${searchCriteria.billNumber}`,
            billType: SanDiegoBillType.unsecured,
            billSearchType: this.props.settings.billSearchType,
            meta: {
                searchType: "MANUAL",
                searchGroup: "SEARCH_BY_UNSECURE_BILL_NUMBER",
                searchCriteria: searchCriteria,
            },
        });
    };

    render() {
        const { billTypes, criteria, intl } = this.props;
        const { errors } = this.state;
        const billType = billTypes.billTypes.find(
            (s: IBillType) => s.name === SanDiegoBillType.secured
        );
        const searchError = this.getSearchError();
        if (!billType) {
            return <h1> Error occured </h1>;
        }

        return (
            <Form id={this.formId}>
                <div className="row">
                    <div className="col-xs-12 col-sm-4 col-md-6">
                        <NumberInput
                            name="year"
                            label={Resources.BillSearchOption3YearField}
                            className="input-width-120p"
                            value={criteria.meta.searchCriteria.year}
                            format="####"
                            decimalSeparator={false}
                            error={searchError || errors.year}
                            onChange={this.handleChange}
                            onError={this.handleError}
                            onBlur={this.handleBlur}
                            onKeyDown={this.handleKeyDown}
                            pattern={YearRegExpPattern}
                            required={true}
                        />
                    </div>
                    <div className="col-xs-12 col-sm-4 col-md-6">
                        <NumberInput
                            name="unsecuredBillNumber"
                            label={
                                Resources.BillSearchOption3UnsecuredBillNumberField
                            }
                            className="input-width-120p"
                            value={criteria.meta.searchCriteria.billNumber}
                            format="######"
                            decimalSeparator={false}
                            error={searchError || errors.unsecuredBillNumber}
                            onChange={this.handleChange}
                            onError={this.handleError}
                            onBlur={this.handleBlur}
                            onKeyDown={this.handleKeyDown}
                            pattern={UnsecuredBillNumberRegExpPattern}
                            required={true}
                        />
                    </div>
                </div>
                <div className="row mt-10p">
                    <div className="col-xs-12 col-sm-4 col-md-6">
                        <Button
                            bsClass="btn btn-large btn-primary text-center btn-custom"
                            onClick={this.handleSearch}
                        >
                            {intl.formatMessage({
                                id: "billSearch.option.btn",
                            })}
                        </Button>
                    </div>
                </div>
            </Form>
        );
    }
}

export default injectIntl(
    connect(
        (state: IApplicationState) => ({
            merchantName: state.settings.merchantName,
            application: state.application,
            criteria: state.billSearch.criteria || ({} as IBillSearchCriteria),
            billTypes: state.billTypes,
            searchResult: state.billSearch,
            settings: state.settings,
            billSearchResult: state.billSearch,
            billsInCartAny: state.bills.bills.length > 0,
        }),
        {
            clearBills,
            newBillsearchSession,
            search,
            updateCriteria,
            reset,
        }
    )(SearchByUnsecuredBillNumberBlock)
);
