import En from "../resources/locales/data.json";
import Es from "../resources/locales/data.es.json";
import Hy from "../resources/locales/data.hy.json";
import Ko from "../resources/locales/data.ko.json";
import Vi from "../resources/locales/data.vi.json";
import Zh from "../resources/locales/data.zh.json";

function getDefault() {
  if (typeof window === "undefined" || window === null) {
    return "en";
  }

  const language =
    (navigator.languages && navigator.languages[0]) ||
    navigator.language ||
    (navigator as any).userLanguage;
  return language.toLowerCase().split(/[_-]+/)[0];
}

export function getLanguage(globalState: any) {
  if (globalState.intl && globalState.intl.locale) {
    return globalState.intl.locale;
  }

  return getDefault();
}

export function getLanguageCode(language: any) {
  switch (language) {
    case "en":
      return 1;
    case "es":
      return 2;
    case "zh":
      return 3;
    case "vi":
      return 4;
    case "hy":
      return 5;
    case "ko":
      return 6;
    default:
      return 1;
  }
}

export function getLanguageFromId(code: number) {
  switch (code) {
    case 1:
      return "en";
    case 2:
      return "es";
    case 3:
      return "zh";
    case 4:
      return "vi";
    case 5:
      return "hy";
    case 6:
      return "ko";
    default:
      return "en";
  }
}

export function getMessages(language: string) {
  switch (language) {
    case "es":
      return (Es as any).es || (En as any).en;
    case "zh":
      return (Zh as any).zh || (En as any).en;
    case "vi":
      return (Vi as any).vi || (En as any).en;
    case "hy":
      return (Hy as any).hy || (En as any).en;
    case "ko":
      return (Ko as any).ko || (En as any).en;
    default:
      return (En as any).en;
  }
}
