import { reducer as toastr } from "react-redux-toastr";
import { ReducersMapObject } from "redux";
import { IApplicationState } from "../types";
import { reducer as userAccounts } from "./Accounts";
import { reducer as application } from "./Application";
import { reducer as applicationMaintenance } from "./ApplicationMaintenance";
import { reducer as bills } from "./Bill";
import { reducer as billDetails } from "./BillDetails";
import { reducer as billSearch } from "./BillSearch";
import { reducer as billTypes } from "./BillTypes";
import { reducer as ebppBills } from "./EbppBill";
import { reducer as intl } from "./Intl";
import { reducer as ajaxCounter } from "./LoadCounter";
import { reducer as myAccount } from "./MyAccount";
import { reducer as payment } from "./Payment";
import { reducer as paymentTerms } from "./PaymentTerms";
import { reducer as payor } from "./Payor";
import { reducer as receipt } from "./Receipt";
import { reducer as settings } from "./Settings";
import { reducer as termsOfUse } from "./Terms";
import { reducer as transactions } from "./Transactions";
import { reducer as user } from "./User";

export const rootReducer: ReducersMapObject<IApplicationState> = {
    ajaxCounter,
    application,
    bills,
    billDetails,
    billSearch,
    billTypes,
    ebppBills,
    intl,
    myAccount,
    payment,
    paymentTerms,
    payor,
    receipt,
    settings,
    termsOfUse,
    transactions,
    user,
    userAccounts,
    applicationMaintenance,
    toastr,
};
