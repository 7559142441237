import React from "react";
import { Button, ButtonToolbar, Col, Form, Modal, Row } from "react-bootstrap";
import * as Resources from "../../resources";
import { IApplication, ICpcData } from "../../types";
import { IFormError } from "../../types/IFormError";
import { isNullOrEmpty } from "../../utils/Utilities";
import { CheckFieldValidity, CheckFormValidity } from "../../utils/Validation";
import { TextInput } from "../Input";
import { setIsPaymentCompleted } from "../../actions/Application";


export interface CpcDataModalProps {
    application: IApplication;
    locale: string;
    merchantName: string;
    cpcDescription: string;
    showModal: boolean;
    close: () => void;
    saveCPCEdit: (cpcData: ICpcData) => void;
}

export interface CpcDataModalState {
    cpcData: ICpcData;
    isSaveButtonDisabled: boolean;
    errors: IFormError;
    dirty: boolean;
}

export class CpcDataModal extends React.PureComponent<
    CpcDataModalProps,
    CpcDataModalState
> {
    private formId = "modal-bill-form";

    private initialCpcData = {
        merchantPONumber: null,
        isTaxExempt: null,
        taxAmount: null,
        transactionId: null
    } as ICpcData;

    constructor(props: CpcDataModalProps) {
        super(props);

        this.state = {
            isSaveButtonDisabled: true,
            cpcData: Object.assign({}, this.initialCpcData),
            dirty: false,
            errors: {},
        };
    }

    private onSubmit = (event: React.MouseEvent<Button>) => {
        event.preventDefault();

        if (!CheckFormValidity(this.formId, this.handleFormErrors)) {
            return false;
        }

        this.props.saveCPCEdit(this.state.cpcData);
        this.closeDialog();

        return true;
    };

    private closeDialog = (): void => {
        this.setState({
            cpcData: this.initialCpcData,
            isSaveButtonDisabled: true,
            errors: {},
        });
        setIsPaymentCompleted(true);
        this.props.close();
    };

    private onBlur = (
        event: React.FocusEvent<HTMLInputElement | HTMLSelectElement>
    ): void => {
        CheckFieldValidity(event.target, this.onError);
    };

    private handleFormErrors = (errors: IFormError): void => {
        this.setState({
            errors: {
                ...this.state.errors,
                ...errors,
            },
        });
    };

    private onError = (name: string, message: string) => {
        const updatedState = { [name]: message };

        return this.setState({
            errors: { ...this.state.errors, ...updatedState },
        });
    };

    private onChange = (
        event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
    ): void => {
        const field: string = event.target.name;
        const updatedState = { [field]: event.target.value || null };

        if (event.target.validity.valid) {
            CheckFieldValidity(event.target, this.onError);
        }

        return this.setState({
            cpcData: { ...this.state.cpcData, ...updatedState },
            dirty: !isNullOrEmpty(event.target.value),
        });
    };

    public render(): JSX.Element {
        const { cpcData, errors } = this.state;
        const { cpcDescription, showModal } = this.props;

        return (
            <span>
                <Modal show={showModal} onHide={this.closeDialog}>
                    <Modal.Header closeButton>
                        <Modal.Title>
                            {Resources.MessageEnterL2Data(cpcDescription)}
                            <br />
                            {Resources.MessageEnterPONumber}
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Form id={this.formId}>
                            <Row>
                                <Col xs={12} sm={6} md={6}>
                                    <TextInput
                                        label={Resources.LabelPONumber}
                                        name="merchantPONumber"
                                        value={cpcData.merchantPONumber}
                                        disableScrollStep={true}
                                        disableArrowStep={true}
                                        error={errors.customAmount}
                                        onChange={this.onChange}
                                        onError={this.onError}
                                        onBlur={this.onBlur}
                                        maxLength={25}
                                    />
                                </Col>
                            </Row>
                        </Form>
                    </Modal.Body>
                    <Modal.Footer>
                        <div className="pull-right">
                            <ButtonToolbar>
                                <Button
                                    bsStyle="primary"
                                    onClick={this.onSubmit}
                                    disabled={!this.state.dirty}
                                    style={{ minWidth: "6.5em" }}
                                >
                                    {Resources.ButtonSubmit}
                                </Button>
                                <Button
                                    onClick={this.closeDialog}
                                    disabled={this.state.dirty}
                                >
                                    {Resources.ButtonSkip}
                                </Button>
                            </ButtonToolbar>
                        </div>
                    </Modal.Footer>
                </Modal>
            </span>
        );
    }
}
