import React from "react";
import { Button } from "react-bootstrap";
import { InjectedIntlProps } from "react-intl";
import { RouterProps } from "react-router";
import {
    BillSearchOption,
    IApplication,
    IApplicationBillTypes,
    IBillSearchCriteria,
    IBillSearchResults,
    ISettings,
    Key,
} from "../../types";
import { IFormError } from "../../types/IFormError";
import { getLanguageCode } from "../../utils/Intl";
import { CheckFieldValidity, CheckFormValidity } from "../../utils/Validation";
import RecordNotFound from "./shared/RecordNotFound";

export interface ISearchByOptionsBaseOwnProps {
    //searchBlockName: string;
    settings: ISettings;
    id: string;
    //billInputName: string;
    //submitInputName: string;
    //optionTitle: string;
    collapseCallback: (blockName: BillSearchOption) => void;
    //billSearchResult: IBillSearchResults;
}

export interface ISearchByOptionsBaseDispatchProps {
    search: (
        merchantName: string,
        application: IApplication,
        billType: string,
        id1: string,
        id2: string,
        id3: string,
        id4: string,
        language: number,
        includePaidBills: boolean,
        address: string,
        postalCode: string,
        offset?: number,
        take?: number
    ) => void;
    updateCriteria: (criteria: IBillSearchCriteria) => void;
    reset: (criteria: IBillSearchCriteria) => void;
    newBillsearchSession: VoidFunction;
    clearBills: VoidFunction;
}

export interface ISearchByOptionsBasePropsFromState {
    merchantName: string;
    application: IApplication;
    criteria: IBillSearchCriteria;
    billTypes: IApplicationBillTypes;
    billSearchResult: IBillSearchResults;
    billsInCartAny: boolean;
}

export type IComponentProps = ISearchByOptionsBaseDispatchProps &
    ISearchByOptionsBaseOwnProps &
    ISearchByOptionsBasePropsFromState &
    InjectedIntlProps &
    RouterProps;

interface IComponentState {
    errors: IFormError;
    searchError: boolean;
}

export abstract class SearchByOptionsBase extends React.Component<
    IComponentProps,
    IComponentState
> {
    protected formId = "";
    protected abstract SearchBlockGroup: string;
    constructor(props: IComponentProps) {
        super(props);

        this.state = {
            errors: {},
            searchError: false,
        };
    }

    componentWillReceiveProps(nextProps: IComponentProps) {
        const currentBlockSearchComplete =
            this.props.billSearchResult.isLoaded !==
                nextProps.billSearchResult.isLoaded &&
            nextProps.billSearchResult.isLoaded === true &&
            nextProps.billSearchResult!.criteria!.meta.searchGroup ===
                this.SearchBlockGroup;

        if (currentBlockSearchComplete) {
            if (nextProps.billSearchResult.totalRowCount > 0) {
                const { merchantName, history } = this.props;
                history.push(`/${merchantName}/search-result`);
            } else {
                this.setState({
                    searchError: true,
                });
            }
        }
    }

    abstract render(): JSX.Element;

    protected getRegExp(pattern: string) {
        if (pattern) {
            return new RegExp(pattern);
        }

        return null;
    }

    protected searchBill = () => {
        const {
            criteria,
            merchantName,
            application,
            intl: { locale },
            search,
        } = this.props;
        search(
            merchantName,
            application,
            criteria.billType,
            criteria.id1,
            criteria.id2,
            criteria.id3,
            criteria.id4,
            getLanguageCode(locale),
            true,
            criteria.address,
            criteria.postalCode,
            0,
            100
        );
    };

    protected handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { criteria, settings, updateCriteria } = this.props;

        event.preventDefault();

        if (!event.target.validity.valid) {
            CheckFieldValidity(event.target, this.handleError);
        }

        criteria.billSearchType = settings.billSearchType;

        updateCriteria({
            ...criteria,
            [event.target.name]: event.target.value,
        });
    };

    protected handleBlur = (event: React.FocusEvent<HTMLInputElement>) => {
        CheckFieldValidity(event.target, this.handleError);
    };

    protected handleKeyDown = (
        event: React.KeyboardEvent<HTMLInputElement>
    ) => {
        switch (event.keyCode) {
            case Key.Enter:
                event.preventDefault();
                event.stopPropagation();

                if (!CheckFormValidity(this.formId, this.handleFormErrors)) {
                    return false;
                }

                this.searchBill();
                break;
            default:
                break;
        }
    };

    protected getSearchError = () => {
        if (this.state.searchError) {
            return <RecordNotFound />;
        }
    };

    protected handleError = (name: string, message: string) => {
        if (message) {
            this.setState({
                searchError: false,
            });
        }
        return this.setState({
            errors: {
                ...this.state.errors,
                [name]: message,
            },
        });
    };

    private handleFormErrors = (errors: IFormError): void => {
        this.setState({
            errors: {
                ...this.state.errors,
                ...errors,
            },
        });
    };

    protected handleSearch = (e: React.MouseEvent<Button>) => {
        if (!CheckFormValidity(this.formId, this.handleFormErrors)) {
            return false;
        }

        this.searchBill();
    };
}
