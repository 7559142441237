import React from "react";
import MyAccount from "./MyAccount";
import { NavItem } from "react-bootstrap";
import { LinkLogin } from "../../../resources";

import { IMyAccount } from "../../../types";

export interface MerchantLoginProps {
    isAutoPayEnabled: boolean;
    isLoggedIn: boolean;
    myAccount: IMyAccount;
    merchantName: string;
    openChangePassword: () => void;
    openManageFactors: () => void;
    openLogin: (ev: React.MouseEvent<NavItem>) => void;
    logout: () => void;
}

const MerchantLogin = (props: MerchantLoginProps) => {
    return props.isLoggedIn ? (
        <MyAccount
            isAutoPayEnabled={props.isAutoPayEnabled}
            myAccount={props.myAccount}
            merchantName={props.merchantName}
            openChangePassword={props.openChangePassword}
            openManageFactors={props.openManageFactors}
            logout={props.logout}
        />
    ) : (
        <NavItem onClick={props.openLogin} href="#">
            {LinkLogin}
        </NavItem>
    );
};

export default MerchantLogin;
