import { IApplication, ITransactions } from "../types";

export const loadPaymentHistory = (
    application: IApplication,
    merchantName: string,
    authToken: string,
    pageNumber: number,
    pageSize: number
): Promise<ITransactions> => {
    const baseUrl = process.env.REACT_APP_API_BASEURL;

    return fetch(
        `${baseUrl}/webapi/api/PaymentHistory?merchantName=${merchantName}`,
        {
            method: "POST",
            mode: baseUrl ? "cors" : "same-origin",
            headers: { "Content-Type": "application/json; charset=utf-8" },
            body: JSON.stringify({
                application: application,
                authToken: authToken,
                pageNumber: pageNumber,
                pageSize: pageSize,
            }),
        }
    ).then((response) => {
        if (response.status === 401) {
            throw new Error("Not Authorized");
        }

        return response.json() as Promise<ITransactions>;
    });
};
