import React from "react";
import {
    Button,
    Checkbox,
    Col,
    FormGroup,
    Panel,
    Row,
    Well,
    Image,
} from "react-bootstrap";
import Recaptcha from "react-google-recaptcha";
import { InjectedIntlProps, injectIntl } from "react-intl";
import { connect } from "react-redux";
import { RECAPTCHA_SITEKEY } from "../../../core/constants";
import {
    ButtonCancel,
    ButtonOk,
    ButtonResend,
    MessageMFAEnterCodeLabel,
    MessageMFAEnterCodeTotpLabel,
    MFACodeLabel,
    RememberDeviceLabel,
} from "../../../resources";
import {
    IApplication,
    IApplicationState,
    ISettings,
    ITotpEnrollInfo,
} from "../../../types";
import { IFormError } from "../../../types/IFormError";
import { CheckFieldValidity } from "../../../utils/Validation";
import { TextInput } from "../../Input/TextInput";

interface ConnectedProps {
    settings: ISettings;
    application: IApplication;
}

export interface IMultiFactorCodeEntryPanelProps {
    canRemember?: boolean;
    factorId: string;
    onClose: () => void;
    onOk: (
        factorId: string,
        code: string,
        rememberDevice: boolean,
        recaptcha?: string | null
    ) => void;
    onResend: (factorId: string) => void;
    canFactorResend: boolean;
    totpInfo?: ITotpEnrollInfo;
}

export interface IMultiFactorCodeEntryPanelState {
    code: string;
    rememberDevice: boolean;
    errors: IFormError;
}

class MultiFactorCodeEntryPanel extends React.Component<
    ConnectedProps & InjectedIntlProps & IMultiFactorCodeEntryPanelProps,
    IMultiFactorCodeEntryPanelState
> {
    private recaptcha = React.createRef<Recaptcha>();

    constructor(
        props: ConnectedProps &
            InjectedIntlProps &
            IMultiFactorCodeEntryPanelProps
    ) {
        super(props);
        this.state = {
            code: "",
            rememberDevice: false,
            errors: {},
        };
    }

    handleBlur = (event: React.FocusEvent<HTMLInputElement>): void => {
        CheckFieldValidity(event.target, this.handleError);
    };

    handleClose = (event: React.MouseEvent<Button>) => {
        event.preventDefault();
        this.props.onClose();
    };

    handleOk = async (event: React.MouseEvent<Button>) => {
        event.preventDefault();
        let token: string | null | undefined = null;

        if (this.props.settings.requireReCaptcha) {
            token = await this.recaptcha.current?.executeAsync();
        }

        this.onResolved(token);
    };

    handleResend = (event: React.MouseEvent<Button>): void => {
        this.props.onResend(this.props.factorId);
    };

    handleError = (name: "mfacode", message: string): void => {
        this.setState({
            errors: { ...this.state.errors, [name]: message },
        });
    };

    handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.validity.valid) {
            CheckFieldValidity(event.target, this.handleError);
        }

        this.setState({
            code: event.currentTarget.value,
        });
    };

    handleRememberDeviceChange = (event: any): void => {
        this.setState({
            rememberDevice: event.target.checked,
        });
    };

    private readonly onResolved = (token?: string | null) => {
        this.props.onOk(
            this.props.factorId,
            this.state.code,
            this.state.rememberDevice,
            token
        );
    };

    render() {
        const { canFactorResend, totpInfo } = this.props;
        const { totpSharedSecret = undefined, qrBase64Encoded = undefined } =
            totpInfo || {};
        return (
            <Panel>
                <Panel.Body>
                    <Row>
                        <Col xs={12}>
                            <Well>
                                {totpInfo
                                    ? MessageMFAEnterCodeTotpLabel
                                    : MessageMFAEnterCodeLabel}
                            </Well>
                        </Col>
                        {totpInfo && (
                            <Col
                                xs={12}
                                style={{
                                    display: "flex",
                                    alignItems: "center",
                                }}
                            >
                                <Col xs={12} md={6}>
                                    <Image src={qrBase64Encoded} responsive />
                                </Col>
                                <Col xs={12} md={6}>
                                    <strong>{totpSharedSecret}</strong>
                                </Col>
                            </Col>
                        )}
                        <Col xs={12}>
                            <TextInput
                                type="text"
                                name="mfacode"
                                maxLength={10}
                                label={MFACodeLabel}
                                value={this.state.code}
                                error={this.state.errors.mfacode}
                                onChange={this.handleChange}
                                onError={this.handleError}
                                onBlur={this.handleBlur}
                                required
                            />
                        </Col>
                        {this.props.canRemember && (
                            <Col xs={12} sm={12} md={12}>
                                <FormGroup>
                                    <Checkbox
                                        checked={this.state.rememberDevice}
                                        onChange={
                                            this.handleRememberDeviceChange
                                        }
                                    >
                                        {RememberDeviceLabel}
                                    </Checkbox>
                                </FormGroup>
                            </Col>
                        )}
                        <Col xs={12}>
                            <div style={{ paddingTop: 8, paddingBottom: 8 }}>
                                <Button
                                    bsStyle="primary"
                                    onClick={this.handleOk}
                                    style={{ minWidth: "6.5em" }}
                                    block
                                >
                                    {ButtonOk}
                                </Button>
                                <Button
                                    bsStyle="primary"
                                    onClick={this.handleClose}
                                    style={{ minWidth: "6.5em" }}
                                    block
                                >
                                    {ButtonCancel}
                                </Button>
                                {canFactorResend && (
                                    <Button
                                        bsStyle="primary"
                                        onClick={this.handleResend}
                                        style={{ minWidth: "6.5em" }}
                                        block
                                    >
                                        {ButtonResend}
                                    </Button>
                                )}
                            </div>
                        </Col>
                    </Row>
                    {this.props.settings.requireReCaptcha && (
                        <div className="hide">
                            <Recaptcha
                                ref={this.recaptcha}
                                hl={this.props.intl.locale}
                                size="invisible"
                                sitekey={RECAPTCHA_SITEKEY}
                            />
                        </div>
                    )}
                </Panel.Body>
            </Panel>
        );
    }
}

const MultiFactorCodeEntryPanelConnected = connect(
    (state: IApplicationState) => ({
        settings: state.settings,
        application: state.application,
    })
)(MultiFactorCodeEntryPanel);

export default injectIntl(MultiFactorCodeEntryPanelConnected);
