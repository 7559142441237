import React from "react";
import { connect } from "react-redux";
import { RouteComponentProps } from "react-router-dom";
import { loadUserAccounts } from "../../actions/Account";
import { loadCustomerBillDetails } from "../../actions/BillDetails";
import { clear } from "../../actions/Common";
import { updateUser } from "../../actions/User";
import { validateCustomerPasswordResetToken } from "../../api/Account";
import { ResetPasswordForm } from "../../components/CredentialManager/ResetPasswordForm";
import { TokenError } from "../../components/CredentialManager/TokenError";
import {
    IApplication,
    IApplicationState,
    IInternationalization,
    ISettings,
} from "../../types";

export interface ICredentialManagerRouteProps {
    merchantName: string;
    passwordResetToken: string;
    guid?: string;
}

interface CredentialManagerStateProps {
    intl: IInternationalization;
    application: IApplication;
    settings: ISettings;
}

export interface CredentialManagerState {
    isLoaded: boolean;

    isTokenValid: boolean;

    isFirstTimeSetup: boolean;
}

export type CredentialManagerProps =
    RouteComponentProps<ICredentialManagerRouteProps> &
        CredentialManagerStateProps;

class CredentialManager extends React.Component<
    CredentialManagerProps,
    CredentialManagerState
> {
    constructor(props: CredentialManagerProps) {
        super(props);

        this.state = {
            isLoaded: false,
            isTokenValid: false,
            isFirstTimeSetup: false,
        };
    }

    componentDidMount() {
        const {
            application,
            settings,
            match: {
                params: { passwordResetToken },
            },
        } = this.props;

        validateCustomerPasswordResetToken(
            application,
            settings.merchantName,
            passwordResetToken
        ).then((result: any) => {
            if (result.isSuccessful) {
                this.setState({
                    isLoaded: true,
                    isTokenValid: true,
                    isFirstTimeSetup: result.isFirstTimeSetup,
                });
            } else {
                this.setState({
                    isLoaded: true,
                    isTokenValid: false,
                    isFirstTimeSetup: false,
                });
            }
        });
    }

    render() {
        const {
            match: {
                params: { passwordResetToken, guid },
            },
        } = this.props;
        const { isLoaded, isTokenValid, isFirstTimeSetup } = this.state;
        return (
            <div>
                {isLoaded && isTokenValid && (
                    <ResetPasswordForm
                        {...this.props}
                        passwordResetToken={passwordResetToken}
                        guid={guid}
                        isFirstTimeSetup={isFirstTimeSetup}
                    />
                )}
                {isLoaded && !isTokenValid && <TokenError />}
            </div>
        );
    }
}

export default connect(
    (state: IApplicationState) => ({
        intl: state.intl,
        application: state.application,
        settings: state.settings,
    }),
    {
        clear: clear,
        updateUser: updateUser,
        loadUserAccounts: loadUserAccounts,
        loadCustomerBillDetails: loadCustomerBillDetails,
    }
)(CredentialManager);
