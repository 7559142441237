import { IBillType } from '../types';
import { ConfigurationActions } from '../actionTypes/Configuration';


export const BILLTYPES_LOAD = "redux/billTypes/LOAD";
export const BILLTYPES_LOAD_SUCCESS = "redux/billTypes/LOAD_SUCCESS";
export const BILLTYPES_LOAD_FAIL = "redux/billTypes/LOAD_FAIL";
export const BILLTYPEIMAGE_LOAD = "redux/billTypeImage/LOAD";
export const BILLTYPEIMAGE_LOAD_SUCCESS = "redux/billTypeImage/LOAD_SUCCESS";
export const BILLTYPEIMAGE_LOAD_FAIL = "redux/billTypeImage/LOAD_FAIL";

export type RequestBillTypeAction = {
    type: typeof BILLTYPES_LOAD;
}

export type ReceiveBillTypeAction = {
    type: typeof BILLTYPES_LOAD_SUCCESS;
    data: IBillType[];
}

export type FailedBillTypeAction = {
    type: typeof BILLTYPES_LOAD_FAIL;
    data: string;
}

export type RequestBillTypeImageAction = {
    type: typeof BILLTYPEIMAGE_LOAD;
}

export type ReceiveBillTypeImageAction = {
    type: typeof BILLTYPEIMAGE_LOAD_SUCCESS;
    billName: string;
    data: string;
}

export type FailedBillTypeImageAction = {
    type: typeof BILLTYPEIMAGE_LOAD_FAIL;
    billName: string;
    data: string;
}

export type BillTypeActions = RequestBillTypeAction | ReceiveBillTypeAction | FailedBillTypeAction | RequestBillTypeImageAction | ReceiveBillTypeImageAction | FailedBillTypeImageAction | ConfigurationActions;

//export type BillTypeImageActions = RequestBillTypeImageAction | ReceiveBillTypeImageAction | FailedBillTypeImageAction;