import { Reducer } from 'redux';
import {
    BILLSEARCH_LOAD, BILLSEARCH_LOAD_SUCCESS, BILLSEARCH_LOAD_FAIL, BILLSEARCH_UPDATE_RECORD,
    BILLSEARCH_RESET, RequestBillSearchAction, ReceiveBillSearchAction, FailedBillSearchAction,
    UpdateBillSearchAction, ResetBillSearchAction, BillSearchActions, UpdateCriteriaAction, BILLSEARCH_UPDATE_CRITERIA,
    NewBillSearchSessionAction, BILLSEARCH_NEW_SESSION
} from '../actionTypes/BillSearch';
import { CLEAR } from '../actionTypes/Common';
import BillUtils from '../components/SanDiegoBillSearch/BillSearchResult/Auxiliary/Tables/Mapper';
import { IBillSearchResults, IBillSearchCriteria } from '../types';

export const initialState: IBillSearchResults = {
    isLoaded: false,
    isLoading: false,
    error: null,
    bills: [],
    mappedBills: [],
    pageNumber: 0,
    pageSize: 0,
    totalRowCount: 0,
    isSuccessful: false,
    messages: [],
    criteria: {
        showPaidBills: false,
        meta: {
            searchType: 'MANUAL',
            searchGroup: null,
            searchCriteria: {
                parcelNumber: '',
                mailingAddress: '',
                countryCode: 'USA',
                zipCode: '',
                year: '',
                billNumber: ''
            }
        }
    } as IBillSearchCriteria
};

const billSearchLoadAction = (state: IBillSearchResults, action: RequestBillSearchAction): IBillSearchResults => {
    return {
        ...state,
        isLoading: true,
        isLoaded: false,
        error: null
    };
};

const billSearchLoadSuccessAction = (state: IBillSearchResults, action: ReceiveBillSearchAction): IBillSearchResults => {
    if (state.criteria && state.criteria.billSearchType && state.criteria.billSearchType === 'SanDiego') {
        action.data.mappedBills = BillUtils.getBills(action.data.bills);
    }

    return {
        ...state,
        ...action.data,
        isLoading: false,
        isLoaded: true,
        error: null
    };
};

const billSearchLoadFailAction = (state: IBillSearchResults, action: FailedBillSearchAction): IBillSearchResults => {
    return {
        ...state,
        isLoading: false,
        isLoaded: false,
        error: action.data
    };
};

const billSearchUpdateAction = (state: IBillSearchResults, action: UpdateBillSearchAction): IBillSearchResults => {
    return {
        ...state,
        isLoading: false,
        isLoaded: true,
        error: null,
        bills: [
            ...state.bills.map(b => b.id === action.data.id ? action.data : b)
        ]
    };
};

const billSearchUpdateCriterion = (state: IBillSearchResults, action: UpdateCriteriaAction): IBillSearchResults => {
    return {
        ...state,
        criteria: { ...state.criteria, ...action.data }
    }
}

const billSearchResetAction = (state: IBillSearchResults, action: ResetBillSearchAction): IBillSearchResults => {
    return {
        ...initialState,
        criteria: { ...state.criteria, ...action.data, }
    };
};

const billsearchNewSession = (state: IBillSearchResults, action: NewBillSearchSessionAction): IBillSearchResults => {
    return {
        ...initialState,
    }
}


export const reducer: Reducer<IBillSearchResults> = (state: IBillSearchResults = initialState, action: BillSearchActions) => {
    switch (action.type) {
        case BILLSEARCH_LOAD: return billSearchLoadAction(state, action);
        case BILLSEARCH_LOAD_SUCCESS: return billSearchLoadSuccessAction(state, action);
        case BILLSEARCH_LOAD_FAIL: return billSearchLoadFailAction(state, action);
        case BILLSEARCH_UPDATE_RECORD: return billSearchUpdateAction(state, action);
        case BILLSEARCH_RESET: return billSearchResetAction(state, action);
        case BILLSEARCH_UPDATE_CRITERIA: return billSearchUpdateCriterion(state, action);
        case BILLSEARCH_NEW_SESSION: return billsearchNewSession(state, action);
        case CLEAR: return { ...initialState };
        default: return state;
    }
};