import {
  getCustomerAccountsForMerchant,
  createOrUpdate,
  removeAccount,
  updateAccountAsDefault,
} from "../api/Account";
import { validatePhone } from "../api/Validation";
import {
  IAccount,
  IAccounts,
  IApplication,
  IAppThunkAction,
  IUser,
} from "../types";
import {
  ACCOUNTS_LOAD,
  ACCOUNTS_LOAD_SUCCESS,
  ACCOUNTS_LOAD_FAIL,
  ACCOUNTS_CREATE,
  ACCOUNT_SETASDEFAULT_SUCCESS,
  ACCOUNT_SETASDEFAULT_FAIL,
  ACCOUNTS_UPDATE,
  ACCOUNTS_DELETE,
  AccountActions,
} from "../actionTypes/Account";

export const loadUserAccounts =
  (
    application: IApplication,
    merchantName: string,
    authToken: string,
    onError?: (text: string) => void
  ): IAppThunkAction<AccountActions> =>
  (dispatch) => {
    getCustomerAccountsForMerchant(application, merchantName, authToken)
      .then((accounts) =>
        validatePhoneNumber(accounts).then((validatedAccounts) =>
          dispatch({ type: ACCOUNTS_LOAD_SUCCESS, data: validatedAccounts })
        )
      )
      .catch((error: Error) => {
        dispatch({ type: ACCOUNTS_LOAD_FAIL, data: error.message });
        if (onError) {
          onError(error.message);
        }
      });

    dispatch({ type: ACCOUNTS_LOAD });
  };

export const validatePhoneNumber = (
  userAccounts: IAccounts
): Promise<IAccounts> => {
  const promises = userAccounts.accounts.map((account: IAccount) =>
    validatePhone(account.phoneCountry, account.phone)
  );

  return Promise.all(promises).then((response) => {
    const updatedAccounts = userAccounts.accounts.map(
      (account: IAccount, index: number) => {
        account.phone = response[index].nationalFormat;

        return account;
      }
    );

    userAccounts.accounts = updatedAccounts;

    return userAccounts;
  });
};

export const create =
  (account: IAccount): IAppThunkAction<AccountActions> =>
  (dispatch) => {
    dispatch({
      type: ACCOUNTS_CREATE,
      data: account,
    });
  };

export const createOrUpdateAccount =
  (
    application: IApplication,
    merchantName: string,
    user: IUser,
    account: IAccount,
    verb: string,
    onError: (text: string) => void,
    onSuccess: (email: string) => void
  ): IAppThunkAction<AccountActions> =>
  (dispatch) => {
    createOrUpdate(application, merchantName, user, account, verb)
      .then((result) => {
        if (result.isSuccessful) {
          dispatch({ type: ACCOUNTS_UPDATE, data: result });
          if (onSuccess) {
            onSuccess(
              result.account.token
                ? result.account.token
                : result.account.nickname
            );
          }
        } else {
          if (onError) {
            onError(result.messages[0]);
          }
        }
      })
      .catch((error: Error) => {
        if (onError) {
          onError(error.message);
        }
      });
  };

export const deleteAccount =
  (
    application: IApplication,
    merchantName: string,
    user: IUser,
    accountName: string,
    onError: (text: string) => void,
    onSuccess: (accountName: string) => void
  ): IAppThunkAction<AccountActions> =>
  (dispatch) => {
    removeAccount(application, merchantName, user, accountName)
      .then((result) => {
        if (result.isSuccessful) {
          dispatch({ type: ACCOUNTS_DELETE, data: result });
          if (onSuccess) {
            onSuccess(result.accountName);
          }
        } else {
          if (onError) {
            onError(result.messages[0]);
          }
        }
      })
      .catch((error: Error) => {
        if (onError) {
          onError(error.message);
        }
      });
  };

export const setAccountAsDefault =
  (
    application: IApplication,
    account: IAccount,
    merchantName: string,
    authToken: string
  ): IAppThunkAction<AccountActions> =>
  (dispatch, getState) => {
    updateAccountAsDefault(application, account, merchantName, authToken)
      .then((result) => {
        if (result.isSuccessful) {
          dispatch({
            type: ACCOUNT_SETASDEFAULT_SUCCESS,
            data: result.accountName,
          });
        } else {
          dispatch({
            type: ACCOUNT_SETASDEFAULT_FAIL,
            data:
              result.messages.length > 0
                ? result.messages[0]
                : "Error setting account as default",
          });
        }
      })
      .catch((error: Error) =>
        dispatch({ type: ACCOUNT_SETASDEFAULT_FAIL, data: error.message })
      );
  };
