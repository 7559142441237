import React from "react";
import { Button } from "react-bootstrap";

export interface IMultiFactorButtonProps {
    factorId: string;
    value: string;
    onVerify: (factorId: string) => void;
}

class MultiFactorButton extends React.Component<IMultiFactorButtonProps> {
    constructor(props: IMultiFactorButtonProps) {
        super(props);
        this.handleClick = this.handleClick.bind(this);
    }

    handleClick = (event: React.MouseEvent<Button>) => {
        event.preventDefault();
        this.props.onVerify(this.props.factorId);
    };

    render() {
        return (
            <Button
                bsStyle="primary"
                onClick={this.handleClick}
                style={{ minWidth: "6.5em" }}
                block
            >
                {this.props.value}
            </Button>
        );
    }
}

export default MultiFactorButton;
