import React from "react";
import { InjectedIntlProps, injectIntl } from "react-intl";
import {
    BillTable,
    GroupColumnsTableHeader,
    GroupNameTableHeader,
    TableHeaderColumn,
} from "../../..";
import { BillSearchResultSecured } from "../../../../../resources";
import { GroupedBillsTableProps, SanDiegoBillType } from "../../../types";
import { securedBillGridColumns } from "./grid-configuration";

class SecuredBillsTable extends React.Component<
    GroupedBillsTableProps & InjectedIntlProps
> {
    constructor(
        props: GroupedBillsTableProps & InjectedIntlProps,
        context: any
    ) {
        super(props);
    }

    render() {
        const {
            intl,
            billType,
            bills,
            selectedBills,
            addToCart,
            handleViewBill,
        } = this.props;
        const columns = securedBillGridColumns as TableHeaderColumn[];

        return (
            <div id="PaymentApplicationContent_pnlSecured">
                <GroupNameTableHeader
                    groupName={BillSearchResultSecured}
                    groupNameTooltip={intl.formatMessage({
                        id: "billSearchResult.option_title.secured",
                    })}
                    groupCssClassName="secured"
                ></GroupNameTableHeader>

                <div className="r-table">
                    <table
                        className="table"
                        cellSpacing="0"
                        id="PaymentApplicationContent_gvSecured"
                    >
                        <GroupColumnsTableHeader
                            columns={columns}
                        ></GroupColumnsTableHeader>
                        <BillTable
                            billType={billType}
                            billTypeName={SanDiegoBillType.secured}
                            rows={bills}
                            selectedBills={selectedBills}
                            addToCart={addToCart}
                            handleViewBill={handleViewBill}
                        ></BillTable>
                    </table>
                </div>
            </div>
        );
    }
}

export default injectIntl(SecuredBillsTable);
