import { getPaymentTerms } from "../api/PaymentTerms";
import {
  PAYMENTTERMS_LOAD,
  PAYMENTTERMS_LOAD_SUCCESS,
  PAYMENTTERMS_LOAD_FAIL,
  PAYMENTTERMS_RESET,
  PAYMENTTERMS_UPDATE_ACCEPTED,
  PAYMENTTERMS_UPDATE_CAPTCHA,
  PaymentTermsActions,
} from "../actionTypes/PaymentTerms";
import { IApplication, IAppThunkAction } from "../types";

export const loadPaymentTerms =
  (
    application: IApplication,
    merchantName: string,
    templateId: number,
    methodId: number,
    languageId: number,
    feeAmount: number
  ): IAppThunkAction<PaymentTermsActions> =>
  (dispatch) => {
    getPaymentTerms(
      application,
      merchantName,
      templateId,
      methodId,
      languageId,
      feeAmount
    )
      .then((paymentTerms) =>
        dispatch({ type: PAYMENTTERMS_LOAD_SUCCESS, data: paymentTerms })
      )
      .catch((error) =>
        dispatch({ type: PAYMENTTERMS_LOAD_FAIL, data: error })
      );

    dispatch({ type: PAYMENTTERMS_LOAD });
  };

export const reset = (): IAppThunkAction<PaymentTermsActions> => (dispatch) => {
  dispatch({
    type: PAYMENTTERMS_RESET,
  });
};

export const updateAccepted =
  (value: boolean): IAppThunkAction<PaymentTermsActions> =>
  (dispatch) => {
    dispatch({
      type: PAYMENTTERMS_UPDATE_ACCEPTED,
      data: value,
    });
  };

export const updateCaptcha =
  (value: string): IAppThunkAction<PaymentTermsActions> =>
  (dispatch) => {
    dispatch({
      type: PAYMENTTERMS_UPDATE_CAPTCHA,
      data: value,
    });
  };
