import React from "react";
import { Button, Col, Panel, Row, Well } from "react-bootstrap";
import { InjectedIntlProps, injectIntl } from "react-intl";
import {
    ButtonClose,
    UnenrollFactorDialogHeader,
    UnenrollFactorDialogMessage,
} from "../../../resources";
import { FactorType, IFactor } from "../../../types/IUser";
import { ConfirmModal } from "../Modals/ConfirmModal";

export interface IMultiFactorManagementPanelProps extends InjectedIntlProps {
    header: string | JSX.Element;
    factors: IFactor[];
    onClose: () => void;
    onManageFactor: (factorType: FactorType, enrolling: boolean) => void;
}

export interface IMultiFactorPanelState {
    authenticated: boolean;
    confirmationCallback?: () => void;
    showConfirmation: boolean;
}

class MultiFactorManagementPanel extends React.Component<
    IMultiFactorManagementPanelProps,
    IMultiFactorPanelState
> {
    constructor(props: IMultiFactorManagementPanelProps) {
        super(props);
        this.state = {
            authenticated: false,
            showConfirmation: false,
        };
        this.handleClose = this.handleClose.bind(this);
    }

    handleClose = (event: React.MouseEvent<Button>) => {
        event.preventDefault();
        this.props.onClose();
    };

    handleEnrollSms = (event: React.MouseEvent<Button>) => {
        this.props.onManageFactor("sms", true);
    };

    handleUnenrollSms = () => {
        this.setState({
            showConfirmation: false,
            confirmationCallback: undefined,
        });
        this.props.onManageFactor("sms", false);
    };

    handleEnrollTotp = (event: React.MouseEvent<Button>) => {
        this.props.onManageFactor("token:software:totp", true);
    };
    handleUnenrollTotp = () => {
        this.setState({
            showConfirmation: false,
            confirmationCallback: undefined,
        });
        this.props.onManageFactor("token:software:totp", false);
    };

    handleEnrollCall = (event: React.MouseEvent<Button>) => {
        this.props.onManageFactor("call", true);
    };

    handleUnenrollCall = () => {
        this.setState({
            showConfirmation: false,
            confirmationCallback: undefined,
        });
        this.props.onManageFactor("call", false);
    };

    handleUnenrollCallClick = (event: React.MouseEvent<Button>) => {
        this.setState({
            showConfirmation: true,
            confirmationCallback: this.handleUnenrollCall,
        });
    };

    handleUnenrollSmsClick = (event: React.MouseEvent<Button>) => {
        this.setState({
            showConfirmation: true,
            confirmationCallback: this.handleUnenrollSms,
        });
    };

    handleUnenrollTotpClick = (event: React.MouseEvent<Button>) => {
        this.setState({
            showConfirmation: true,
            confirmationCallback: this.handleUnenrollTotp,
        });
    };

    handleCloseConfirmation = () => {
        this.setState({ showConfirmation: false });
    };

    render() {
        return (
            <div>
                {this.state.confirmationCallback && (
                    <ConfirmModal
                        headerText={UnenrollFactorDialogHeader}
                        message={UnenrollFactorDialogMessage}
                        show={this.state.showConfirmation}
                        onClose={this.handleCloseConfirmation}
                        onConfirm={this.state.confirmationCallback}
                    />
                )}
                <Panel>
                    <Panel.Body>
                        <Row>
                            <Col xs={12}>
                                <Well>{this.props.header}</Well>
                            </Col>
                            <Col xs={12}>
                                {this.props.factors.some(
                                    (f) =>
                                        f.factorInfo.factorType === "sms" &&
                                        f.factorInfo.status === "ACTIVE"
                                ) ? (
                                    <Button
                                        id="sms-unenroll"
                                        bsStyle="primary"
                                        onClick={this.handleUnenrollSmsClick}
                                        style={{ minWidth: "6.5em" }}
                                        block
                                    >
                                        Unenroll from Text Message
                                    </Button>
                                ) : (
                                    <Button
                                        bsStyle="primary"
                                        onClick={this.handleEnrollSms}
                                        style={{ minWidth: "6.5em" }}
                                        block
                                    >
                                        Enroll in Text Message
                                    </Button>
                                )}
                                {this.props.factors.some(
                                    (f) =>
                                        f.factorInfo.factorType === "call" &&
                                        f.factorInfo.status === "ACTIVE"
                                ) ? (
                                    <Button
                                        id="call-unenroll"
                                        bsStyle="primary"
                                        onClick={this.handleUnenrollCallClick}
                                        style={{ minWidth: "6.5em" }}
                                        block
                                    >
                                        Unenroll from Phone Call
                                    </Button>
                                ) : (
                                    <Button
                                        bsStyle="primary"
                                        onClick={this.handleEnrollCall}
                                        style={{ minWidth: "6.5em" }}
                                        block
                                    >
                                        Enroll in Phone Call
                                    </Button>
                                )}
                                {this.props.factors.some(
                                    (f) =>
                                        f.factorInfo.factorType ===
                                            "token:software:totp" &&
                                        f.factorInfo.status === "ACTIVE"
                                ) ? (
                                    <Button
                                        id="totp-unenroll"
                                        bsStyle="primary"
                                        onClick={this.handleUnenrollTotpClick}
                                        style={{ minWidth: "6.5em" }}
                                        block
                                    >
                                        Unenroll from Authenticator
                                    </Button>
                                ) : (
                                    <Button
                                        bsStyle="primary"
                                        onClick={this.handleEnrollTotp}
                                        style={{ minWidth: "6.5em" }}
                                        block
                                    >
                                        Enroll in Authenticator
                                    </Button>
                                )}
                            </Col>
                            <Col xs={12}>
                                <div
                                    style={{ paddingTop: 8, paddingBottom: 8 }}
                                >
                                    <Button
                                        bsStyle="primary"
                                        onClick={this.handleClose}
                                        style={{ minWidth: "6.5em" }}
                                        block
                                    >
                                        {ButtonClose}
                                    </Button>
                                </div>
                            </Col>
                        </Row>
                    </Panel.Body>
                </Panel>
            </div>
        );
    }
}

export default injectIntl(MultiFactorManagementPanel);
