import React from "react";
import { connect } from "react-redux";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { clearBills, createOrUpdate, deleteBill } from "../../../actions/Bill";
import { generatePdf } from "../../../actions/BillDetails";
import {
    IApplicationRoute,
    IBill,
    IBillSearchCriteria,
    IBillSearchResults,
    IInternationalization,
    ISettings,
} from "../../../types";
import { IApplicationState } from "../../../types/IApplicationState";
import { ICommonBill } from "../types";
import SearchResultContent from "./Auxiliary/SearchResultContent";

export interface SearchResultStateProps
    extends RouteComponentProps<IApplicationRoute> {
    settings: ISettings;
    intl: IInternationalization;
    mappedBills: ICommonBill[];
    criteria: IBillSearchCriteria | null;
    billsearch: IBillSearchResults;
}

export interface SearchResultDispatchProps {
    addToBills: (bill: IBill) => void;
    deleteBill: (billId: string) => void;
    clearBills: VoidFunction;
    generatePdf: (merchantName: string, billType: string, bill: IBill) => void;
}

export type SearchResultProps = RouteComponentProps<IApplicationRoute> &
    SearchResultStateProps &
    SearchResultDispatchProps;

class SearchResult extends React.Component<SearchResultProps> {
    constructor(props: SearchResultProps) {
        super(props);

        const { history, billsearch, criteria } = this.props;
        const { merchantName } = this.props.settings;

        if (
            !billsearch.bills ||
            (billsearch.bills.length === 0 &&
                criteria?.meta.searchType === "MANUAL")
        ) {
            history.push(`/${merchantName}/`);
        }
    }

    render() {
        return <SearchResultContent {...this.props} />;
    }
}

function mapStateToProps(state: IApplicationState) {
    return {
        intl: state.intl,
        settings: state.settings,
        mappedBills: state.billSearch.mappedBills || [],
        billsearch: state.billSearch,
        criteria: state.billSearch.criteria,
    };
}

const mapDispatchToProps = {
    addToBills: createOrUpdate,
    deleteBill,
    generatePdf,
    clearBills,
};

export default connect(mapStateToProps, mapDispatchToProps)(SearchResult);
