import { IApplication, ISettings, IDateResponse } from "../types";

export function getSettingsForApplication(
    merchantName: string,
    intlId: number,
    system: IApplication
): Promise<ISettings> {
    const baseUrl = process.env.REACT_APP_API_BASEURL;

    return fetch(
        `${baseUrl}/webapi/api/Settings?merchantName=${merchantName}&language=${intlId}`,
        {
            method: "POST",
            mode: baseUrl ? "cors" : "same-origin",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(system),
        }
    )
        .then((response) => response.json() as Promise<ISettings>)
        .catch((error) => error);
}

export function GetCurrentUTCDate(): Promise<IDateResponse> {
    const baseUrl = process.env.REACT_APP_API_BASEURL;

    return fetch(`${baseUrl}/webapi/api/Settings/GetCurrentUTCDate`, {
        mode: baseUrl ? "cors" : "same-origin",
    })
        .then((response) => response.json() as Promise<IDateResponse>)
        .catch((error) => error);
}
