import { faCheck } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { Alert, Col, Row, Table } from "react-bootstrap";
import { FormattedDate, FormattedNumber } from "react-intl";
import { connect } from "react-redux";
import { RouteComponentProps } from "react-router-dom";
import { clear } from "../../actions/Common";
import { setIsPaymentCompleted } from "../../actions/Application";
import {
    LabelBaseAmount,
    LabelScheduledDate,
    LabelTotalAmount,
    MessageScheduledPaymentContact,
    MessageScheduledPaymentSuccess,
} from "../../resources";
import {
    IApplication,
    IApplicationRoute,
    IApplicationState,
    IPayment,
    IPayor,
    ISettings,
    ITransaction,
} from "../../types";
import { FinishButton } from "../Input";

interface PaymentScheduledStateProps {
    application: IApplication;
    settings: ISettings;
    payment: IPayment;
    payor: IPayor;
    transaction: ITransaction;
}

interface PaymentScheduledDispatchProps {
    clear: VoidFunction;
    setIsPaymentCompleted: (isPaymentCompleted: boolean) => void;
}

export type PaymentScheduledProps = PaymentScheduledStateProps &
    PaymentScheduledDispatchProps &
    RouteComponentProps<IApplicationRoute>;

class PaymentScheduled extends React.Component<PaymentScheduledProps> {
    constructor(props: PaymentScheduledProps) {
        super(props);
    }

    componentWillUnmount() {
        if (this.props.clear) {
            this.props.clear();
        }
    }

    render() {
        const { settings, payment } = this.props;
        const paymentTotal = payment.total || 0;
        const convenienceFee = payment.convenienceFee || 0;
        const futurePaymentDate = payment.futurePaymentDate || new Date();
        const amountCurrency = (
            <FormattedNumber
                value={paymentTotal - convenienceFee}
                style="currency"
                currency="USD"
            />
        );
        const feeCurrency = (
            <FormattedNumber
                value={convenienceFee}
                style="currency"
                currency="USD"
            />
        );
        const totalCurrency = (
            <FormattedNumber
                value={paymentTotal}
                style="currency"
                currency="USD"
            />
        );
        const scheduledDate = (
            <FormattedDate
                value={futurePaymentDate}
                year="numeric"
                month="long"
                day="2-digit"
            />
        );
        return (
            <div>
                <Alert bsStyle="success">
                    <Row>
                        <Col xs={12}>
                            <h3>
                                <FontAwesomeIcon icon={faCheck} />{" "}
                                {MessageScheduledPaymentSuccess(
                                    settings.friendlyName
                                )}
                            </h3>
                        </Col>
                    </Row>
                </Alert>
                <Row>
                    <Col xs={12} sm={12}>
                        <Table bordered>
                            <tbody>
                                <tr>
                                    <th>{LabelScheduledDate}</th>
                                    <td>
                                        <span className="pull-right">
                                            {scheduledDate}
                                        </span>
                                    </td>
                                </tr>
                                {settings.chargeConvenienceFee && (
                                    <tr>
                                        <th>{LabelBaseAmount}</th>
                                        <td>
                                            <span className="pull-right">
                                                {amountCurrency}
                                            </span>
                                        </td>
                                    </tr>
                                )}
                                {settings.chargeConvenienceFee && (
                                    <tr>
                                        <th>{settings.convenienceFeeLabel}</th>
                                        <td>
                                            <span className="pull-right">
                                                {feeCurrency}
                                            </span>
                                        </td>
                                    </tr>
                                )}
                                <tr>
                                    <th>{LabelTotalAmount}</th>
                                    <td>
                                        <span className="pull-right">
                                            {totalCurrency}
                                        </span>
                                    </td>
                                </tr>
                            </tbody>
                        </Table>
                    </Col>
                </Row>
                <Row>
                    <Col xs={12} sm={12}>
                        <h4>
                            {MessageScheduledPaymentContact(
                                settings.friendlyName
                            )}
                        </h4>
                        <FinishButton {...this.props} />
                    </Col>
                </Row>
            </div>
        );
    }
}

export default connect(
    (state: IApplicationState) =>
        ({
            application: state.application,
            settings: state.settings,
            payment: state.payment,
            payor: state.payor,
            transaction: state.receipt.transaction,
        } as PaymentScheduledStateProps),
    {
        clear: clear,
        setIsPaymentCompleted,
    }
)(PaymentScheduled);
