import React from "react";
import { Col, Row, Alert } from "react-bootstrap";
import { faCheck } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    MessagePaymentHasBeenCompleted,
    HeaderPaymentSucessfull,
} from "../../resources";

class PaymentIsCompletedPanel extends React.PureComponent{
    render() {
        return (
            <Alert bsStyle="success">
                <Row>
                    <Col xs={12}>
                        <h4>
                            <FontAwesomeIcon icon={faCheck} />{" "}
                            {HeaderPaymentSucessfull}
                        </h4>
                        {MessagePaymentHasBeenCompleted}
                    </Col>
                </Row>
            </Alert>
        );
    }
}

export default PaymentIsCompletedPanel;
