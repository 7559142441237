import { Reducer } from 'redux';
import { CLEAR, CANCEL } from '../actionTypes/Common';
import { PAYMENTTERMS_LOAD, PAYMENTTERMS_LOAD_SUCCESS, PAYMENTTERMS_LOAD_FAIL, PAYMENTTERMS_RESET, PAYMENTTERMS_UPDATE_ACCEPTED, PAYMENTTERMS_UPDATE_CAPTCHA, ReceivePaymentTermsAction, RequestPaymentTermsAction, FailedPaymentTermsAction, ResetPaymentTermsAction, UpdateAcceptancePaymentTermsAction, CaptchaPaymentTermsAction, PaymentTermsActions } from '../actionTypes/PaymentTerms';
import { IPaymentTerms } from '../types';


export const initialState: IPaymentTerms = {
    isLoaded: false,
    isLoading: false,
    accepted: false,
    captcha: '',
    error: null,
    terms: ''
};

const paymentTermsLoadAction = (state: IPaymentTerms, action: RequestPaymentTermsAction): IPaymentTerms => {
    return {
        ...state,
        isLoading: true,
        isLoaded: false,
        error: null
    };
};

const paymentTermsLoadSuccessAction = (state: IPaymentTerms, action: ReceivePaymentTermsAction): IPaymentTerms => {
    return {
        ...state,
        isLoading: false,
        isLoaded: true,
        error: null,
        terms: action.data
    };
};

const paymentTermsLoadFailAction = (state: IPaymentTerms, action: FailedPaymentTermsAction): IPaymentTerms => {
    return {
        ...state,
        isLoading: false,
        isLoaded: false,
        accepted: false,
        terms: '',
        error: action.data
    };
};

const paymentTermsCaptchaAction = (state: IPaymentTerms, action: CaptchaPaymentTermsAction): IPaymentTerms => {
    return {
        ...state,
        captcha: action.data
    };
};

const paymentTermsAcceptAction = (state: IPaymentTerms, action: UpdateAcceptancePaymentTermsAction): IPaymentTerms => {
    return {
        ...state,
        accepted: action.data
    };
};

export const reducer: Reducer<IPaymentTerms> = (state: IPaymentTerms = initialState, action: PaymentTermsActions): IPaymentTerms => {
    switch (action.type) {
        case PAYMENTTERMS_RESET: return { ...initialState };
        case PAYMENTTERMS_LOAD: return paymentTermsLoadAction(state, action);
        case PAYMENTTERMS_LOAD_SUCCESS: return paymentTermsLoadSuccessAction(state, action);
        case PAYMENTTERMS_LOAD_FAIL: return paymentTermsLoadFailAction(state, action);
        case PAYMENTTERMS_UPDATE_CAPTCHA: return paymentTermsCaptchaAction(state, action);
        case PAYMENTTERMS_UPDATE_ACCEPTED: return paymentTermsAcceptAction(state, action);
        case CLEAR: return { ...initialState };
        case CANCEL: return { ...initialState };
        default:
            return state;
    }
}
