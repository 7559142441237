import { CancelAction, ClearAction } from './Common';
import { IPayment } from '../types';
import { ReceiveConfigurationAction, ReceiveConfigurationEBPPAction } from './Configuration';

export const PAYMENT_UPDATE = "redux/payment/UPDATE";

export type UpdatePaymentAction = {
    type: typeof PAYMENT_UPDATE;
    data: IPayment;
}

export type PaymentActions = UpdatePaymentAction | CancelAction | ClearAction | ReceiveConfigurationAction | ReceiveConfigurationEBPPAction;