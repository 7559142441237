import {
    FailedConfigurationAction,
    ReceiveConfigurationAction,
    ReceiveConfigurationEBPPAction,
} from "../actionTypes/Configuration";
import { ICpcDataResponse, IReceipt } from "../types";
import { ReceiveBillImageAction } from "./Bills";
import { ClearAction } from "./Common";

export const RECEIPT_LOAD = "redux/receipt/LOAD";
export const RECEIPT_LOAD_SUCCESS = "redux/receipt/LOAD_SUCCESS";
export const RECEIPT_LOAD_FAIL = "redux/receipt/LOAD_FAIL";
export const RECEIPT_GENERATE = "redux/receipt/GENERATE";
export const RECEIPT_SAVE = "redux/receipt/SAVE";
export const EDITCPCDATA_LOAD = "redux/editCpcData/LOAD";
export const EDITCPCDATA_SUCCESS = "redux/editCpcData/LOAD_SUCCESS";
export const EDITCPCDATA_FAIL = "redux/editCpcData/LOAD_FAIL";

export type RequestReceiptAction = {
    type: typeof RECEIPT_LOAD;
};

export type ReceiveReceiptAction = {
    type: typeof RECEIPT_LOAD_SUCCESS;
    data: IReceipt;
};

export type FailedReceiptAction = {
    type: typeof RECEIPT_LOAD_FAIL;
    data: string;
};

export type GenerateReceiptAction = {
    type: typeof RECEIPT_GENERATE;
    data: string;
};

export type SaveReceiptAction = {
    type: typeof RECEIPT_SAVE;
    data: IReceipt;
};
export interface ReceiveEditCPCDataAction {
    type: typeof EDITCPCDATA_SUCCESS;
    data: ICpcDataResponse;
}
export interface FailedEditCPCDataAction {
    type: typeof EDITCPCDATA_FAIL;
    data: string;
}
export interface RequestEditCPCDataRequest {
    type: typeof EDITCPCDATA_LOAD;
}

export type ReceiptActions =
    | RequestReceiptAction
    | ReceiveReceiptAction
    | FailedReceiptAction
    | GenerateReceiptAction
    | SaveReceiptAction
    | ClearAction
    | ReceiveConfigurationAction
    | ReceiveConfigurationEBPPAction
    | FailedConfigurationAction
    | ReceiveBillImageAction
    | ReceiveEditCPCDataAction
    | FailedEditCPCDataAction
    | RequestEditCPCDataRequest;
