import React from "react";
import { FormGroup, Radio, Panel, Row, Col, Alert } from "react-bootstrap";
import FeeTable from "./FeeTable";
import FutureDatePayment from "./FutureDatePayment";
import {
    GetCardImageByPaymentMethod,
    GetCardName,
    GetCardLast4,
} from "../../utils/CardTypes";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExclamationTriangle } from "@fortawesome/free-solid-svg-icons";
import * as Resources from "../../resources";

export default class UserAccountPanel extends React.Component<any, any> {
    public static defaultProps: Partial<any> = {
        hideFeePanel: false,
    };

    constructor(props: any) {
        super(props);

        this.handleOnChange = this.handleOnChange.bind(this);
    }

    public componentDidMount() {
        if (this.props.defaultSelected) {
            this.onAccountChanged(this.props.defaultSelected.nickname);
        }
    }

    private handleOnChange(event: any): void {
        this.onAccountChanged(event.target.value);

        if (this.props.onChange) {
            this.props.onChange(event);
        }
    }

    private onAccountChanged = (accountName: string) => {
        const {
            application,
            merchant: { merchantName },
            userAccounts,
            calculateFee,
            billTotal,
        } = this.props;
        const userAccount = userAccounts.find(
            (x: any) => x.nickname === accountName
        );

        if (userAccount && calculateFee) {
            calculateFee(
                application,
                merchantName,
                "en",
                billTotal,
                userAccount.paymentMethod,
                null,
                null
            );
        }
        if (
            userAccount &&
            application.isBillDetail &&
            this.props.merchant.allowAutoBillPay
        ) {
            this.props.loadAutoPayTerms!(application, merchantName, 484, 7, 1);
        }
        if (userAccount && this.props.copyAccountInformation) {
            this.props.copyAccountInformation(userAccount);
        }
    };

    public render(): JSX.Element {
        const {
            merchant,
            errors,
            userAccounts,
            payment,
            hideFeePanel,
            hideFutureDated,
            billTotal,
        } = this.props;
        const accounts = userAccounts.map((userAccount: any, index: any) => {
            let checked = false;

            if (payment.customerAccountName) {
                checked = payment.customerAccountName === userAccount.nickname;
            } else {
                if (this.props.defaultSelected) {
                    checked =
                        this.props.defaultSelected.nickname ===
                        userAccount.nickname;
                } else {
                    checked = index === 0 ? true : false;
                }
            }

            const type = (
                <span>
                    {GetCardImageByPaymentMethod(userAccount.paymentMethod)}
                    &nbsp;{" "}
                    <strong>{GetCardName(userAccount.paymentMethod)}</strong>
                </span>
            );
            return (
                <Row key={index}>
                    <Col xs={12}>
                        <Radio
                            checked={checked}
                            name="customerAccountName"
                            onChange={this.handleOnChange}
                            value={userAccount.nickname}
                        >
                            {Resources.MessageCardInformation(
                                type,
                                GetCardLast4(userAccount.accountNumber)
                            )}
                        </Radio>
                    </Col>
                </Row>
            );
        });
        return (
            <Panel>
                <Panel.Body>
                    <FormGroup>{accounts}</FormGroup>
                    {!hideFutureDated && (
                        <Row>
                            <Col xs={7} sm={7} md={5}>
                                <FutureDatePayment
                                    name="futurePaymentDate"
                                    locale={this.props.locale}
                                    label={merchant.futureDatedPaymentLabel}
                                    selectedDate={payment.futurePaymentDate}
                                    maxNumberOfDays={
                                        this.props.maxFuturePaymentDays
                                    }
                                    onChanged={this.props.onChange}
                                />
                            </Col>
                        </Row>
                    )}
                    {!hideFeePanel && (
                        <Row>
                            <Col xs={12} sm={12} md={12}>
                                <br />
                                <FeeTable
                                    merchant={merchant}
                                    payment={payment}
                                    billTotal={billTotal}
                                />
                            </Col>
                        </Row>
                    )}
                    <Row>
                        <Col xs={12} sm={12} md={12}>
                            {errors.system && (
                                <Alert bsStyle="danger">
                                    <FontAwesomeIcon
                                        icon={faExclamationTriangle}
                                    />{" "}
                                    {errors.system}
                                </Alert>
                            )}
                        </Col>
                    </Row>
                </Panel.Body>
            </Panel>
        );
    }
}
