import {
  APPLICATION_SET_FUTUREDATEDPAYMENT,
  APPLICATION_SET_LOCATION,
  APPLICATION_LOAD,
  APPLICATION_LOAD_SUCCESS,
  APPLICATION_LOAD_FAIL,
  ApplicationLoadAction,
  ApplicationLoadSuccessAction,
  ApplicationLoadFailedAction,
  ApplicationSetLocationAction,
  ApplicationActions,
  SetFutureDatedPaymentAction,
  SetIsPaymentCompletedAction,
  APPLICATION_SET_ISPAYMENTCOMPLETED,
} from "../actionTypes/Application";
import {
  CONFIGURATION_LOAD_SUCCESS,
  CONFIGURATION_EBPP_LOAD_SUCCESS,
  CONFIGURATION_LOAD_FAIL,
  ReceiveConfigurationAction,
  ReceiveConfigurationEBPPAction,
  FailedConfigurationAction,
} from "../actionTypes/Configuration";
import { CANCEL, CLEAR, CommonActions } from "../actionTypes/Common";
import { IApplication } from "../types";
import { Reducer } from "../../node_modules/redux";

export const initialState: IApplication = {
  isLoaded: false,
  isLoading: false,
  canceled: false,
  id: 33,
  isSuccessful: false,
  guid: "",
  isEBPP: false,
  isBillDetail: false,
  isFutureDatedPayment: false,
  location: "",
  token: "",
  securePayPaymentType: 1,
  error: null,
  expired: false,
  isPaymentCompleted: false,
};

const applicationLoadAction = (
  state: IApplication,
  action: ApplicationLoadAction
): IApplication => {
  return {
    ...state,
    isLoading: true,
    isLoaded: false,
    error: null,
  };
};

const applicationLoadSuccessAction = (
  state: IApplication,
  action: ApplicationLoadSuccessAction
): IApplication => {
  return {
    ...state,
    ...action.data,
    isLoading: false,
    isLoaded: true,
    error: null,
    isEBPP: action.data.id === 33 && Boolean(action.data.guid),
    canceled: (action.data.guid ? true : false) && !action.data.isSuccessful,
  };
};

const applicationLoadFailAction = (
  state: IApplication,
  action: ApplicationLoadFailedAction
): IApplication => {
  return {
    ...state,
    isLoading: false,
    isLoaded: false,
    error: action.data,
  };
};

const configurationLoadSuccessAction = (
  state: IApplication,
  action: ReceiveConfigurationAction
): IApplication => {
  return {
    ...state,
    ...action.data.application,
    isLoading: false,
    isLoaded: true,
    error: null,
    isEBPP:
      action.data.application.id === 33 &&
      Boolean(action.data.application.guid),
    canceled:
      (action.data.application.guid ? true : false) &&
      !action.data.application.isSuccessful,
  };
};

const configurationLoadFailAction = (
  state: IApplication,
  action: FailedConfigurationAction
): IApplication => {
  return {
    ...state,
    isLoading: false,
    isLoaded: false,
    error: action.data,
  };
};

const configurationEbppLoadSuccessAction = (
  state: IApplication,
  action: ReceiveConfigurationEBPPAction
): IApplication => {
  return {
    ...state,
    ...action.data.application,
    isLoading: false,
    isLoaded: true,
    error: null,
    isEBPP:
      action.data.application.id === 33 &&
      Boolean(action.data.application.guid),
    canceled:
      (action.data.application.guid ? true : false) &&
      !action.data.application.isSuccessful,
  };
};

const applicationSetLocationAction = (
  state: IApplication,
  action: ApplicationSetLocationAction
): IApplication => {
  return {
    ...state,
    location: action.data,
    isBillDetail:
      action.data && action.data.toLowerCase().includes("billdetail")
        ? true
        : false,
  };
};

const setFutureDatedPaymentAction = (
  state: IApplication,
  action: SetFutureDatedPaymentAction
): IApplication => {
  return {
    ...state,
    isFutureDatedPayment: action.data,
  };
};

const setIsPaymentCompleted = (
  state: IApplication,
  action: SetIsPaymentCompletedAction
) => {
  return {
    ...state,
    isPaymentCompleted: action.data,
  };
};

const clearAction = (state: IApplication, action: CommonActions) => {
  return {
    ...state,
    expired: !state.canceled && (state.guid ? true : false),
  };
};

const cancelAction = (state: IApplication, action: CommonActions) => {
  return {
    ...state,
    canceled: state.id !== 33,
  };
};

export const reducer: Reducer<IApplication> = (
  state: IApplication = initialState,
  action: ApplicationActions | CommonActions
) => {
  switch (action.type) {
    case APPLICATION_LOAD:
      return applicationLoadAction(state, action);
    case APPLICATION_LOAD_SUCCESS:
      return applicationLoadSuccessAction(state, action);
    case APPLICATION_LOAD_FAIL:
      return applicationLoadFailAction(state, action);
    case CONFIGURATION_LOAD_SUCCESS:
      return configurationLoadSuccessAction(state, action);
    case CONFIGURATION_EBPP_LOAD_SUCCESS:
      return configurationEbppLoadSuccessAction(state, action);
    case CONFIGURATION_LOAD_FAIL:
      return configurationLoadFailAction(state, action);
    case APPLICATION_SET_LOCATION:
      return applicationSetLocationAction(state, action);
    case APPLICATION_SET_FUTUREDATEDPAYMENT:
      return setFutureDatedPaymentAction(state, action);
    case APPLICATION_SET_ISPAYMENTCOMPLETED:
      return setIsPaymentCompleted(state, action);
    case CLEAR:
      return clearAction(state, action);
    case CANCEL:
      return cancelAction(state, action);
    default:
      return state;
  }
};
