import { FactorType } from "../types/IUser";
import { EnrollTypes } from "../types/IIdentityFactors";

export function mapFactorTypeToEnrollType(factorType: FactorType): EnrollTypes {
  switch (factorType) {
    case "call":
      return "enrollCall";
    case "sms":
      return "enrollSms";
    case "token:software:totp":
      return "enrollTotpOkta";
  }
}

export function canFactorResend(factorType: FactorType | undefined): boolean {
  if (!factorType) return true;
  switch (factorType) {
    case "call":
    case "sms":
      return true;
    case "token:software:totp":
      return false;
  }
}
