import React from "react";
import { MenuItem, Dropdown } from "react-bootstrap";
import { CheckFieldValidity } from "../../utils/Validation";
import { TextInput } from "./TextInput";

import CountryList from "./CountryList";
import { validatePhone } from "../../api/Validation";

import * as Resources from "../../resources";

import { find } from "lodash";
import { IPhoneValidationResponse } from "../../types";

export enum PhoneInputPropsEnum {
    id,
    disabled,
    name,
    value,
    countryValue,
    onBlur,
    onError,
    onChange,
    onCountryChange,
    readOnly,
    isValid,
    autocomplete,
    onSetRef,
    hideCountries,
}
export class PhoneInput extends React.Component<any, any> {
    private phoneInput: any;

    constructor(props: any) {
        super(props);
        const { value } = this.props;
        this.state = {
            value: value,
            preferredCountries: [],
            id: props.id,
            countries: CountryList.getCountries(),
        };
        this.handleBlur = this.handleBlur.bind(this);
        this.handleError = this.handleError.bind(this);
        this.handleSelect = this.handleSelect.bind(this);
        this.validate = this.validate.bind(this);
        this.handleChange = this.handleChange.bind(this);
    }
    componentDidMount() {
        const { countryValue, value } = this.props;
        if (value) {
            this.validate(countryValue, value);
        }
        if (this.props.onSetRef) {
            this.props.onSetRef(this.phoneInput);
        }
    }
    componentDidUpdate() {
        if (this.props.autoFocused) {
            this.phoneInput.focus();
        }
    }
    componentWillReceiveProps(nextProps: any) {
        if (this.state.value !== nextProps.value) {
            if (nextProps.error === "") {
                const x = this.phoneInput;
                x.setCustomValidity("");
            }
            this.setState({
                value: nextProps.value,
            });
        }
    }
    validate(country: string, phoneNumber: string) {
        const x = this.phoneInput;
        const { isValid } = this.props;
        if (!phoneNumber) {
            if (isValid) {
                isValid(true);
            }
            x.setCustomValidity("");
            CheckFieldValidity(x, this.handleError);
            return false;
        }

        validatePhone(country, phoneNumber)
            .then((result: IPhoneValidationResponse) => {
                if (result.isSuccessful) {
                    x.setCustomValidity("");
                    this.setState({
                        value: result.nationalFormat,
                    });

                    if (isValid) {
                        isValid(result.isSuccessful);
                    }
                    CheckFieldValidity(x, this.handleError);

                    try {
                        const event = new Event("input", { bubbles: true });
                        x.dispatchEvent(event); // Sets current prop
                        this.props.onChange(result.nationalFormat); // Sets next prop
                    } catch (error) {
                        // Support IE
                        const event = document.createEvent("Event");
                        event.initEvent("input", true, false);
                        x.dispatchEvent(event);
                        this.props.onChange(result.nationalFormat);
                    }
                } else {
                    x.setCustomValidity("phonePatternMismatch");
                }
            })
            .catch((reason: any) => {
                if (isValid) {
                    isValid(false);
                }
                console.log("Handle rejected promise (" + reason + ") here.");
            });

        return false;
    }
    handleSelect(eventKey: any) {
        const { readOnly, onCountryChange } = this.props;
        if (readOnly) {
            return;
        }
        if (this.state.value) {
            this.validate(eventKey, this.state.value);
        }
        if (onCountryChange) {
            this.props.onCountryChange(eventKey);
        }
    }
    handleBlur(event: any) {
        const { countryValue } = this.props;
        const { value } = this.state;
        this.validate(countryValue, value);
        if (this.props.onBlur) {
            this.props.onBlur(event);
        }
    }
    handleChange(event: any) {
        if (this.props.onChange) {
            this.validate(this.props.countryValue, event.target.value);
            this.props.onChange(event.target.value);
        }
    }
    handleError(name: any, message: any) {
        if (this.props.onError) {
            this.props.onError(name, message);
        }
    }
    render() {
        const { disabled, name, countryValue, readOnly, autocomplete } =
            this.props;
        const { value, countries } = this.state;
        const country = find(countries, function (c: any) {
            return c.value.toUpperCase() === (countryValue || "").toUpperCase();
        });
        const countryDropDownList = countries.map(function (
            item: any,
            index: any
        ) {
            const active = country ? item.value === country.value : false;
            return (
                <MenuItem
                    key={index}
                    eventKey={item.value.toUpperCase()}
                    active={active}
                    aria-controls="phone-country-dropdown"
                >
                    <i
                        className={`flag-icon flag-icon-${item.value.toLowerCase()}`}
                    />
                    &nbsp;
                    <span>{item.text}</span>
                </MenuItem>
            );
        },
        this);
        const countryButton = (
            <span className="input-group-btn">
                <label
                    htmlFor="phone-country-dropdown"
                    aria-valuetext={country && country.text}
                >
                    {Resources.LabelPhoneCountry}
                </label>
                <Dropdown
                    id="phone-country-dropdown"
                    disabled={disabled || readOnly}
                    onSelect={this.handleSelect}
                    style={{
                        top: "-2.5px",
                        bottom: "2px",
                        borderBottomLeftRadius: "3px",
                        borderTopLeftRadius: "3px",
                        height: "34px",
                    }}
                >
                    <Dropdown.Toggle>
                        <i
                            className={`flag-icon flag-icon-${
                                country &&
                                country.value &&
                                country.value.toLowerCase()
                            }`}
                        />
                    </Dropdown.Toggle>
                    <Dropdown.Menu className="scrollable">
                        {countryDropDownList}
                    </Dropdown.Menu>
                </Dropdown>
            </span>
        );
        const props: any = Object.assign({}, this.props);
        Object.keys(this.props).forEach((key) => {
            if (key in PhoneInputPropsEnum) {
                delete props[key];
            }
        });
        const inputRef = (ref: any) => (this.phoneInput = ref);
        return (
            <TextInput
                {...props}
                disabled={disabled}
                type="tel"
                name={name}
                inputRef={inputRef}
                value={this.state.value}
                onBlur={this.handleBlur}
                onError={this.handleError}
                onChange={this.handleChange}
                readOnly={readOnly}
                addonBefore={this.props.hideCountries ? null : countryButton}
                autocomplete={autocomplete}
                maxLength={20}
            />
        );
    }
}

export default PhoneInput;
