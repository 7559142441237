import React from "react";
import { FormattedNumber } from "react-intl";

export interface FormattedNumberGridCellProps {
    value: number;
    style?: string;
    currency?: string;
}

export const FormattedNumberGridCell = ({
    value,
    style = "currency",
    currency = "USD",
}: FormattedNumberGridCellProps): JSX.Element => (
    <td>
        <FormattedNumber value={value} style={style} currency={currency} />
    </td>
);
