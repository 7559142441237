import { Reducer } from "redux";
import {
  FailedTransactionAction,
  PAYMENTHISTORY_LOAD,
  PAYMENTHISTORY_LOAD_FAIL,
  PAYMENTHISTORY_LOAD_SUCCESS,
  ReceiveTransactionAction,
  RequestTransactionAction,
  TransactionActions,
} from "../actionTypes/Transactions";
import { ITransactions } from "../types";

export const initialState: ITransactions = {
  isLoaded: false,
  isLoading: false,
  error: null,
  totalPages: 0,
  transactions: [],
  isSuccessful: false,
  messages: [],
};

const transactionsLoadAction = (
  state: ITransactions,
  action: RequestTransactionAction
): ITransactions => {
  return {
    ...state,
    isLoading: true,
    isLoaded: false,
    error: null,
  };
};

const transactionsLoadSuccessAction = (
  state: ITransactions,
  action: ReceiveTransactionAction
): ITransactions => {
  return {
    ...state,
    ...action.data,
    isLoading: false,
    isLoaded: true,
    error: null,
  };
};

const transactionsLoadFailAction = (
  state: ITransactions,
  action: FailedTransactionAction
): ITransactions => {
  return {
    ...state,
    isLoading: false,
    isLoaded: false,
    error: action.data,
  };
};

export const reducer: Reducer<ITransactions> = (
  state: ITransactions = initialState,
  action: TransactionActions
): ITransactions => {
  switch (action.type) {
    case PAYMENTHISTORY_LOAD:
      return transactionsLoadAction(state, action);
    case PAYMENTHISTORY_LOAD_SUCCESS:
      return transactionsLoadSuccessAction(state, action);
    case PAYMENTHISTORY_LOAD_FAIL:
      return transactionsLoadFailAction(state, action);
    default:
      return state;
  }
};
