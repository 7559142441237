import React from "react";
import { Location } from "history";
import {
    LabelBillInformation,
    LabelDone,
    LabelPaymentInformation,
    LabelPayorInformation,
    LabelSearch,
    LabelVerify,
} from "../../resources";
import { IApplication, ISettings } from "../../types";
import StepLink from "./StepLink";

export interface IStepWizardProps {
    application: IApplication;
    settings: ISettings;
    location: Location;
}

export interface IStepWizardState {
    step: number;
}

class StepWizard extends React.Component<IStepWizardProps, IStepWizardState> {
    constructor(props: any) {
        super(props);
        this.state = {
            step: 0,
        };
        this.setStepNumber = this.setStepNumber.bind(this);
        this.setStep = this.setStep.bind(this);
    }

    public componentWillMount(): void {
        this.setStepNumber(this.props.location.pathname);
    }

    public componentWillReceiveProps(nextProps: any): void {
        if (this.props !== nextProps) {
            this.setStepNumber(nextProps.location.pathname);
        }
    }

    private buildSteps(): JSX.Element[] {
        const { application, settings } = this.props;
        let breadcrumbCount =
            (application.id === 34 || application.id === 35) &&
            settings.skipBillsPage &&
            settings.skipPayorPage
                ? 3
                : 4;

        if (
            !settings.allowBlindPayments &&
            !application.guid &&
            !application.isEBPP
        ) {
            breadcrumbCount = breadcrumbCount + 1;
        }

        if (
            !settings.skipPayorPage &&
            (!settings.skipBillsPage || application.id === 33)
        ) {
            breadcrumbCount = breadcrumbCount + 1;
        }

        const listItemWidth = 100 / breadcrumbCount;
        const steps = [] as JSX.Element[];
        let stepNumber = 1;

        if (
            !settings.allowBlindPayments &&
            !application.guid &&
            !application.isEBPP
        ) {
            steps.push(
                <li key={stepNumber} style={{ width: listItemWidth + "%" }}>
                    <StepLink
                        step={stepNumber++}
                        label={LabelSearch}
                        link={this.getSearchStepLink()}
                        disabled={this.state.step === 6}
                    />
                </li>
            );
        }
        if (!settings.skipBillsPage || application.id === 33) {
            steps.push(
                <li key={stepNumber} style={{ width: listItemWidth + "%" }}>
                    <StepLink
                        step={stepNumber++}
                        label={LabelBillInformation(
                            this.props.settings.billsToPayPrompt
                        )}
                        link={this.getBillStepLink()}
                        disabled={this.state.step < 2 || this.state.step === 6}
                    />
                </li>
            );
        }
        if (!settings.skipPayorPage) {
            steps.push(
                <li key={stepNumber} style={{ width: listItemWidth + "%" }}>
                    <StepLink
                        step={stepNumber++}
                        label={LabelPayorInformation}
                        link={this.getPayorStepLink()}
                        disabled={this.state.step < 3 || this.state.step === 6}
                    />
                </li>
            );
        }
        steps.push(
            <li key={stepNumber} style={{ width: listItemWidth + "%" }}>
                <StepLink
                    step={stepNumber++}
                    label={LabelPaymentInformation}
                    link={this.getPaymentStepLink()}
                    disabled={this.state.step < 4 || this.state.step === 6}
                />
            </li>
        );
        steps.push(
            <li key={stepNumber} style={{ width: listItemWidth + "%" }}>
                <StepLink
                    step={stepNumber++}
                    label={LabelVerify}
                    link={this.getVerifyStepLink()}
                    disabled={this.state.step < 5 || this.state.step === 6}
                />
            </li>
        );
        if (!application.isFutureDatedPayment) {
            steps.push(
                <li key={stepNumber} style={{ width: listItemWidth + "%" }}>
                    <StepLink
                        step={stepNumber++}
                        label={LabelDone}
                        link={this.getReceiptStepLink()}
                        disabled={this.state.step < 6}
                    />
                </li>
            );
        } else {
            steps.push(
                <li key={stepNumber} style={{ width: listItemWidth + "%" }}>
                    <StepLink
                        step={stepNumber++}
                        label={LabelDone}
                        link={this.getPaymentScheduledStepLink()}
                        disabled={this.state.step < 6}
                    />
                </li>
            );
        }

        return steps;
    }

    private getSearchStepLink(): string {
        return `/${this.props.settings.merchantName}/search`;
    }

    private getBillStepLink(): string {
        const {
            application: { guid },
            settings: { merchantName },
        } = this.props;
        return guid
            ? `/${merchantName}/bills/${guid}`
            : `/${merchantName}/bills`;
    }

    private getPayorStepLink(): string {
        const {
            application: { guid },
            settings: { merchantName },
        } = this.props;
        return guid
            ? `/${merchantName}/payor/${guid}`
            : `/${merchantName}/payor`;
    }

    private getPaymentStepLink(): string {
        const {
            application: { guid },
            settings: { merchantName },
        } = this.props;
        return guid
            ? `/${merchantName}/payment/${guid}`
            : `/${merchantName}/payment`;
    }

    private getVerifyStepLink(): string {
        const {
            application: { guid },
            settings: { merchantName },
        } = this.props;
        return guid
            ? `/${merchantName}/verify/${guid}`
            : `/${merchantName}/verify`;
    }

    private getReceiptStepLink(): string {
        return `/${this.props.settings.merchantName}/receipt`;
    }

    private getPaymentScheduledStepLink(): string {
        return `/${this.props.settings.merchantName}/paymentscheduled`;
    }

    private setStepNumber(path: any): void {
        switch (path) {
            case this.getSearchStepLink():
                this.setStep(1);
                break;
            case this.getBillStepLink():
                this.setStep(2);
                break;
            case this.getPayorStepLink():
                this.setStep(3);
                break;
            case this.getPaymentStepLink():
                this.setStep(4);
                break;
            case this.getVerifyStepLink():
                this.setStep(5);
                break;
            case this.getReceiptStepLink():
                this.setStep(6);
                break;
            case this.getPaymentScheduledStepLink():
                this.setStep(6);
                break;
            default:
                this.setStep(1);
        }
    }

    private setStep(nextStep: any): void {
        this.setState({
            step: nextStep,
        });
    }

    public render(): JSX.Element {
        return (
            <div className="breadcrumbs hidden-print">
                <ul className="cf">{this.buildSteps()}</ul>
            </div>
        );
    }
}

export default StepWizard;
