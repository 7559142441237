import React from "react";
import { connect } from "react-redux";
import { cancel } from "../../actions/Common";
import { loadTransactions } from "../../actions/Transactions";
import { generate, refresh } from "../../actions/Receipt";
import PaymentHistoryForm from "./PaymentHistoryForm";
import { RouteComponentProps } from "react-router-dom";
import {
    IApplication,
    ISettings,
    IMyAccount,
    IInternationalization,
    IUser,
    IApplicationState,
    ITransactions,
    IReceipt,
} from "../../types";

export interface PaymentHistoryProps extends RouteComponentProps<any> {
    application: IApplication;
    settings: ISettings;
    transactions: ITransactions;
    myAccount: IMyAccount;
    receipt: IReceipt;
    intl: IInternationalization;
    user: IUser;
}

class PaymentHistory extends React.Component<any, any> {
    constructor(props: any) {
        super(props);
    }
    render() {
        return (
            <div className="margin-top-20">
                <PaymentHistoryForm {...this.props} />
            </div>
        );
    }
}

const mapStateToProps = (state: IApplicationState) => ({
    application: state.application,
    settings: state.settings,
    transactions: state.transactions,
    myAccount: state.myAccount,
    receipt: state.receipt,
    intl: state.intl,
    user: state.user,
});

const mapDispatchToProps = {
    loadTransactions,
    generate,
    refresh,
    cancel,
};

export default connect(mapStateToProps, mapDispatchToProps)(PaymentHistory);
