import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleNotch } from "@fortawesome/free-solid-svg-icons";

export interface BlockUiProps {
    shouldRender: boolean;
}
export const BlockUi = (props: BlockUiProps) => {
    if (!props.shouldRender) return null;

    return (
        <div className="overlay">
            <div className="overlay-content">
                <FontAwesomeIcon icon={faCircleNotch} spin />
            </div>
        </div>
    );
};
