const allStates = [
    ["AL", "Alabama"],
    ["AK", "Alaska"],
    ["AZ", "Arizona"],
    ["AR", "Arkansas"],
    ["CA", "California"],
    ["CO", "Colorado"],
    ["CT", "Connecticut"],
    ["DE", "Delaware"],
    ["DC", "District Of Columbia"],
    ["FL", "Florida"],
    ["GA", "Georgia"],
    ["HI", "Hawaii"],
    ["ID", "Idaho"],
    ["IL", "Illinois"],
    ["IN", "Indiana"],
    ["IA", "Iowa"],
    ["KS", "Kansas"],
    ["KY", "Kentucky"],
    ["LA", "Louisiana"],
    ["ME", "Maine"],
    ["MD", "Maryland"],
    ["MA", "Massachusetts"],
    ["MI", "Michigan"],
    ["MN", "Minnesota"],
    ["MS", "Mississippi"],
    ["MO", "Missouri"],
    ["MT", "Montana"],
    ["NE", "Nebraska"],
    ["NV", "Nevada"],
    ["NH", "New Hampshire"],
    ["NJ", "New Jersey"],
    ["NM", "New Mexico"],
    ["NY", "New York"],
    ["NC", "North Carolina"],
    ["ND", "North Dakota"],
    ["OH", "Ohio"],
    ["OK", "Oklahoma"],
    ["OR", "Oregon"],
    ["PA", "Pennsylvania"],
    ["RI", "Rhode Island"],
    ["SC", "South Carolina"],
    ["SD", "South Dakota"],
    ["TN", "Tennessee"],
    ["TX", "Texas"],
    ["UT", "Utah"],
    ["VT", "Vermont"],
    ["VA", "Virginia"],
    ["WA", "Washington"],
    ["WV", "West Virginia"],
    ["WI", "Wisconsin"],
    ["WY", "Wyoming"]
];

const allTerritories = [
    ["AS", "American Samoa"],
    ["GU", "Guam"],
    ["MP", "Northern Mariana Islands"],
    ["PR", "Puerto Rico"],
    ["UM", "United States Minor Outlying Islands"],
    ["VI", "Virgin Islands"]
];

const allArmedServices = [
    ["AA", "Armed Forces Americas"],
    ["AP", "Armed Forces Pacific"],
    ["AE", "Armed Forces Others"]
];

const statesList: any[] = [];
for (let i = 0; i < allStates.length; i++) {
    const c = allStates[i];
    const [value, text] = c;
    statesList.push({ value, text });
}
statesList.push({ value: "--", text: "Armed Service" });
for (let i = 0; i < allArmedServices.length; i++) {
    const c = allArmedServices[i];
    const [value, text] = c;
    statesList.push({ value, text });
}
statesList.push({ value: "--", text: "Territories" });
for (let i = 0; i < allTerritories.length; i++) {
    const c = allTerritories[i];
    const [value, text] = c;
    statesList.push({ value, text });
}

class StatesList {
    static getStates() {
        return Object.assign([], statesList);
    }

    static getStateNames(states: any) {
        return statesList.filter(({value}) => states.includes(value)).map(({text}) => text);
    }
}

export default StatesList;