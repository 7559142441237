import React from "react";
import { MessageCardInformation } from "../../resources";
import { Checkbox, Button, Glyphicon } from "react-bootstrap";
import { IBillType, IBillDetail, ISettings } from "../../types";
import { FormattedDate } from "react-intl";

const BillDetailRow = ({
    billDetail,
    billType,
    cardType,
    settings,
    handleEdit,
    selectedId,
    isNewBillDetails,
    onAutoPayChanged,
    onNotificationChanged,
}: {
    billDetail: IBillDetail;
    billType: IBillType;
    cardType: JSX.Element;
    settings: ISettings;
    handleEdit: (event: any) => void;
    selectedId: string;
    isNewBillDetails: boolean;
    onAutoPayChanged: (event: any) => void;
    onNotificationChanged: (event: any) => void;
}) => (
    <tr key={billDetail.identifier1} style={{ verticalAlign: "middle" }}>
        {billType.id1.label && !billType.id1.isSearchOnly ? (
            <td style={{ verticalAlign: "middle" }}>
                {billDetail.identifier1}
            </td>
        ) : null}
        {billType.id2.label && !billType.id2.isSearchOnly ? (
            <td style={{ verticalAlign: "middle" }}>
                {billDetail.identifier2}
            </td>
        ) : null}
        {billType.id3.label && !billType.id3.isSearchOnly ? (
            <td style={{ verticalAlign: "middle" }}>
                {billDetail.identifier3}
            </td>
        ) : null}
        {billType.id4.label && !billType.id4.isSearchOnly ? (
            <td style={{ verticalAlign: "middle" }}>
                {billDetail.identifier4}
            </td>
        ) : null}
        {!isNewBillDetails && !selectedId && (
            <td style={{ verticalAlign: "middle" }}>
                {billDetail.lastPaidDateUTC && (
                    <FormattedDate
                        value={billDetail.lastPaidDateUTC}
                        year="numeric"
                        month="2-digit"
                        day="2-digit"
                    />
                )}
            </td>
        )}
        {settings.allowAutoBillPay && !isNewBillDetails && !selectedId && (
            <td style={{ verticalAlign: "middle" }}>
                {billDetail.isAutopayEnabled && billDetail.tokenLastFour ? (
                    <span style={{ float: "left" }}>
                        {MessageCardInformation(
                            cardType,
                            billDetail.tokenLastFour
                        )}
                    </span>
                ) : null}
                {billDetail.isAutopayEnabled && billDetail.tokenLastFour && (
                    <Button
                        id={billDetail.identifier1}
                        bsStyle="info"
                        bsSize="xsmall"
                        onClick={handleEdit}
                        style={{ minWidth: "1.9em", float: "right" }}
                        disabled={!billDetail.isAutopayEnabled}
                    >
                        <Glyphicon glyph="pencil" />
                    </Button>
                )}
            </td>
        )}
        {settings.allowAutoBillPay && (
            <td className="text-center">
                <Checkbox
                    style={{ margin: 0 }}
                    checked={billDetail.isAutopayEnabled}
                    name={billDetail.identifier1}
                    onChange={onAutoPayChanged}
                    disabled={!billType.autopayEligible || selectedId !== ""}
                ></Checkbox>
            </td>
        )}
        {settings.enableNewBillNotifications && (
            <td className="text-center">
                <Checkbox
                    style={{ margin: 0 }}
                    checked={billDetail.isNotificationEnabled}
                    name={billDetail.identifier1}
                    onChange={onNotificationChanged}
                    disabled={
                        billDetail.isAutopayEnabled ||
                        !billType.notificationEligible ||
                        selectedId !== ""
                    }
                ></Checkbox>
            </td>
        )}
    </tr>
);

export default BillDetailRow;
