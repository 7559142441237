import {
    IApplication,
    IAccount,
    IAccounts,
    IAccountResponse,
    IResponse,
    IUser,
} from "../types";

export const getCustomerAccountsForMerchant = (
    application: IApplication,
    merchantName: string,
    authToken: string
): Promise<IAccounts> => {
    const baseUrl = process.env.REACT_APP_API_BASEURL;

    return fetch(
        `${baseUrl}/webapi/api/CustomerAccounts?merchantName=${merchantName}`,
        {
            method: "POST",
            mode: baseUrl ? "cors" : "same-origin",
            headers: { "Content-Type": "application/json; charset=utf-8" },
            body: JSON.stringify({
                application: application,
                AuthToken: authToken,
            }),
        }
    ).then((response) => {
        if (response.status === 401) {
            throw new Error("Not Authorized");
        }

        return response.json() as Promise<IAccounts>;
    });
};

export const createOrUpdate = (
    application: IApplication,
    merchantName: string,
    user: IUser,
    account: IAccount,
    verb: string
): Promise<IAccountResponse> => {
    const baseUrl = process.env.REACT_APP_API_BASEURL;
    const url =
        verb === "POST"
            ? `${baseUrl}/webapi/api/CustomerAccounts/Insert`
            : `${baseUrl}/webapi/api/CustomerAccounts`;
    return fetch(url, {
        method: verb,
        mode: baseUrl ? "cors" : "same-origin",
        headers: { "Content-Type": "application/json; charset=utf-8" },
        body: JSON.stringify({
            merchantName: merchantName,
            application: application,
            AuthToken: user.authToken,
            account: account,
        }),
    })
        .then((response) => {
            if (response.status === 401) {
                throw new Error("Not Authorized");
            }

            return response.json();
        })
        .then((accountResponse: IAccountResponse) => {
            accountResponse.account.isDefault = account.isDefault;

            return accountResponse;
        });
};

export const removeAccount = (
    application: any,
    merchantName: any,
    user: any,
    accountName: any
): Promise<IAccountResponse> => {
    const baseUrl = process.env.REACT_APP_API_BASEURL;

    return fetch(`${baseUrl}/webapi/api/CustomerAccounts`, {
        method: "DELETE",
        mode: baseUrl ? "cors" : "same-origin",
        headers: { "Content-Type": "application/json; charset=utf-8" },
        body: JSON.stringify({
            CustomerAccountName: accountName,
            merchantName: merchantName,
            application: application,
            AuthToken: user.authToken,
        }),
    }).then((response) => {
        if (response.status === 401) {
            throw new Error("Not Authorized");
        }

        return response.json() as Promise<IAccountResponse>;
    });
};

export const updateAccountAsDefault = (
    application: IApplication,
    account: IAccount,
    merchantName: string,
    authToken: string
): Promise<IAccountResponse> => {
    const baseUrl = process.env.REACT_APP_API_BASEURL;

    return fetch(`${baseUrl}/webapi/api/CustomerAccounts/SetAccountAsDefault`, {
        method: "POST",
        mode: baseUrl ? "cors" : "same-origin",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
            application,
            account,
            authToken,
            merchantName,
        }),
    }).then((response) => {
        if (response.status === 401) {
            throw new Error("Not Authorized");
        }

        return response.json();
    });
};

export const validateCustomerPasswordResetToken = (
    application: IApplication,
    merchantName: string,
    token: string
): Promise<IResponse> => {
    const baseUrl = process.env.REACT_APP_API_BASEURL;

    return fetch(
        `${baseUrl}/webapi/api/Account/ValidateCustomerPasswordResetToken?merchantName=${merchantName}`,
        {
            method: "POST",
            mode: baseUrl ? "cors" : "same-origin",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({
                application,
                token,
            }),
        }
    ).then((response) => response.json() as Promise<IResponse>);
};

export const changeCustomerPassword = (
    application: IApplication,
    merchantName: string,
    token: string,
    userName: string,
    password: string,
    recaptcha?: string | null
): Promise<IUser> => {
    const baseUrl = process.env.REACT_APP_API_BASEURL;

    return fetch(
        `${baseUrl}/webapi/api/Account/ChangeCustomerPasswordAndLogin?merchantName=${merchantName}`,
        {
            method: "POST",
            mode: baseUrl ? "cors" : "same-origin",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({
                application,
                token,
                userName,
                password,
                recaptcha,
            }),
        }
    ).then((response) => response.json() as Promise<IUser>);
};

export const changePassword = (
    merchantName: string,
    authToken: string,
    password: string
): Promise<string> => {
    const baseUrl = process.env.REACT_APP_API_BASEURL;

    return fetch(
        `${baseUrl}/webapi/api/Account/ChangePassword?merchantName=${merchantName}`,
        {
            method: "POST",
            mode: baseUrl ? "cors" : "same-origin",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({
                //application: application,
                authToken: authToken,
                //currentPassword: data.current,
                newPassword: password,
            }),
        }
    ).then((response) => response.text() as Promise<string>);
};

export const forgotPassword = (
    application: IApplication,
    merchantName: string,
    username: string,
    isFirstTimeSetup: boolean,
    recaptcha?: string | null
): Promise<IResponse> => {
    const baseUrl = process.env.REACT_APP_API_BASEURL;

    return fetch(
        `${baseUrl}/webapi/api/Account/ForgotPassword?merchantName=${merchantName}`,
        {
            method: "POST",
            mode: baseUrl ? "cors" : "same-origin",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({
                application,
                username,
                recaptcha,
                isFirstTimeSetup,
            }),
        }
    ).then((response) => response.json() as Promise<IResponse>);
};
