import { loadInitData } from "../api/Init";
import { getBills, getEBPPBills } from "../api/Bill";
import { getReceiptWithPaymentTerms } from "../api/Receipt";
import {
  IAppThunkAction,
  IApplication,
  IBill,
  IReceipt,
  IUser,
  IProfile,
} from "../types";
import {
  CONFIGURATION_LOAD,
  CONFIGURATION_LOAD_SUCCESS,
  CONFIGURATION_EBPP_LOAD_SUCCESS,
  ConfigurationActions,
} from "../actionTypes/Configuration";
import { USER_UPDATE } from "../actionTypes/User";
import {
  ApplicationMaintenanceActions,
  APPLICATION_MAINTENANCE_LOAD_FAIL,
  APPLICATION_MAINTENANCE_LOAD_SUCCESS,
} from "../actionTypes/ApplicationMaintenance";
import { loadMaintenance } from "../api/ApplicationMaintenance";

const getBillsByApplication = (
  application: IApplication,
  merchantName: string,
  dispatch: (action: ConfigurationActions) => void,
  authToken: string,
  guid?: string
): Promise<IBill[]> => {
  if (application.id === 33 && Boolean(guid)) {
    return getEBPPBills(merchantName, guid!, application, null, authToken).then(
      (response) => {
        const ebppUser: IUser = {
          requirePasswordReset: response.requirePasswordReset,
          merchantCustomerID: response.merchantCustomerId,
          isSuccessful: false,
          messages: [],
          isLoggedIn: false,
          isVerified: false,
          isManageVerified: false,
          authToken: "",
          profile: {} as IProfile,
          autoPayEnabled: false,
          notificationsEnabled: false,
          mfaRequired: false,
          factors: [],
          userId: "",
        };
        dispatch({ type: USER_UPDATE, data: ebppUser });
        return response.bills;
      }
    );
  } else if (application.id !== 33 && Boolean(guid)) {
    return getBills(merchantName, guid).then((response) => {
      dispatch({ type: USER_UPDATE, data: response.authenticateCustomer });
      return response.bills;
    });
  }

  return Promise.resolve<IBill[]>([] as IBill[]);
};

export const loadInitialData =
  (
    merchantName: string,
    guid?: string,
    transactionId?: string
  ): IAppThunkAction<ConfigurationActions> =>
  (dispatch) => {
    loadInitData(merchantName, guid).then((response) => {
      const receiptPromise = transactionId
        ? getReceiptWithPaymentTerms(
            response.application,
            merchantName,
            parseInt(transactionId),
            response.language
          )
        : Promise.resolve<IReceipt>({} as IReceipt);

      return receiptPromise.then((receipt: IReceipt) => {
        if (response.application.id === 33 && !!response.application.guid) {
          dispatch({
            type: CONFIGURATION_EBPP_LOAD_SUCCESS,
            data: {
              accounts: response.accounts,
              application: response.application,
              billDetails: response.billDetails,
              billTypes: response.billTypes,
              bills: response.bills,
              intlId: response.language,
              payor: response.payor,
              receipt,
              settings: response.settings,
            },
          });
        } else {
          dispatch({
            type: CONFIGURATION_LOAD_SUCCESS,
            data: {
              accounts: response.accounts,
              application: response.application,
              billDetails: response.billDetails,
              billTypes: response.billTypes,
              bills: response.bills,
              intlId: response.language,
              payor: response.payor,
              receipt,
              settings: response.settings,
            },
          });
        }
      });
    });

    dispatch({ type: CONFIGURATION_LOAD });
  };

export const loadMaintenanceData =
  (
    merchantName: string,
    application?: IApplication
  ): IAppThunkAction<ApplicationMaintenanceActions> =>
  (dispatch) => {
    loadMaintenance(merchantName, application)
      .then((res) => {
        dispatch({
          type: APPLICATION_MAINTENANCE_LOAD_SUCCESS,
          data: {
            isLoaded: true,
            error: null,
            isLoading: false,
            maintenance: res,
          },
        });
      })
      .catch((err) => {
        dispatch({
          type: APPLICATION_MAINTENANCE_LOAD_FAIL,
          data: err,
        });
      });
  };
