import { searchBills } from "../api/BillSearch";

import {
    BILLSEARCH_LOAD,
    BILLSEARCH_LOAD_SUCCESS,
    BILLSEARCH_LOAD_FAIL,
    BILLSEARCH_RESET,
    BILLSEARCH_UPDATE_RECORD,
    BillSearchActions,
    BILLSEARCH_UPDATE_CRITERIA,
    BILLSEARCH_NEW_SESSION,
} from "../actionTypes/BillSearch";
import {
    IApplication,
    IAppThunkAction,
    IBillRecord,
    IBillSearchCriteria,
} from "../types";

export const search =
    (
        merchantName: string,
        application: IApplication,
        billType: string,
        id1: string,
        id2: string,
        id3: string,
        id4: string,
        language: number,
        includePaidBills = false,
        address = "",
        postalCode = "",
        offset?: number,
        take?: number
    ): IAppThunkAction<BillSearchActions> =>
    (dispatch) => {
        searchBills(
            merchantName,
            application,
            billType,
            id1,
            id2,
            id3,
            id4,
            language,
            offset,
            take,
            includePaidBills,
            address,
            postalCode
        )
            .then((results) =>
                dispatch({ type: BILLSEARCH_LOAD_SUCCESS, data: results })
            )
            .catch((error) =>
                dispatch({ type: BILLSEARCH_LOAD_FAIL, data: error })
            );

        dispatch({ type: BILLSEARCH_LOAD });
    };

export const reset =
    (criteria: IBillSearchCriteria): IAppThunkAction<BillSearchActions> =>
    (dispatch) => {
        dispatch({
            type: BILLSEARCH_RESET,
            data: criteria,
        });
    };

export const update =
    (results: IBillRecord): IAppThunkAction<BillSearchActions> =>
    (dispatch) => {
        dispatch({
            type: BILLSEARCH_UPDATE_RECORD,
            data: results,
        });
    };

export const updateCriteria =
    (criteria: IBillSearchCriteria): IAppThunkAction<BillSearchActions> =>
    (dispatch) => {
        dispatch({
            type: BILLSEARCH_UPDATE_CRITERIA,
            data: criteria,
        });
    };

export const newBillsearchSession =
    (): IAppThunkAction<BillSearchActions> => (dispatch) => {
        dispatch({
            type: BILLSEARCH_NEW_SESSION,
        });
    };
