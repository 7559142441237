import React from "react";
import {
    FirstRowOfDefaultedUnsecuredBill,
    FirstRowOfSecuredSupplementalBill,
    SecondRowOfDefaultedUnsecuredBill,
    SecondRowOfSecuredSupplementalBill,
} from "../../..";
import "../../../../../css/SanDiegoBillSearch/searchPage.css";
import { IBill, IBillType } from "../../../../../types";
import {
    BillInstallmentType,
    ICommonBill,
    SanDiegoBillType,
} from "../../../types";

interface BillTableRowsProps {
    billType: IBillType | undefined;
    billTypeName: SanDiegoBillType;
    rows: ICommonBill[];
    selectedBills: IBill[];
    addToCart: (row: IBill) => void;
    handleViewBill: (bill: IBill) => void;
}

export class BillTable extends React.Component<BillTableRowsProps> {
    constructor(props: BillTableRowsProps) {
        super(props);
    }

    render() {
        const {
            billType,
            billTypeName,
            rows,
            selectedBills,
            addToCart,
            handleViewBill,
        } = this.props;
        const tableRows = [] as JSX.Element[];
        rows.forEach((row) => {
            const firstRowKey = `row|${row.parcelNumber}|${row.owner}|${row.billType}|1`;
            const secondRowKey = `row|${row.parcelNumber}|${row.owner}|${row.billType}|2`;
            const firstTrKey = `tr|${row.parcelNumber}|${row.owner}|${row.billType}|1`;
            const secondTrKey = `tr|${row.parcelNumber}|${row.owner}|${row.billType}|2`;
            const isDefaultedOrUnsecured = row.type === "common" ? true : false;

            const isFirstRowAddedToCart = selectedBills.some(
                (s: IBill) =>
                    s.id1 === row.parcelNumber &&
                    s.id2 === row.owner &&
                    s.billType === row.billType &&
                    (s.id3 === BillInstallmentType.balance ||
                        s.id3 === BillInstallmentType.first)
            );
            const isSecondRowAddedToCart = selectedBills.some(
                (s: IBill) =>
                    s.id1 === row.parcelNumber &&
                    s.id2 === row.owner &&
                    s.billType === row.billType &&
                    (s.id3 === BillInstallmentType.installment ||
                        s.id3 === BillInstallmentType.second)
            );

            const itemSize = row.secondPaymentType != null ? 2 : 1;

            if (isDefaultedOrUnsecured) {
                tableRows.push(
                    <FirstRowOfDefaultedUnsecuredBill
                        key={firstRowKey}
                        trKey={firstTrKey}
                        itemSize={itemSize}
                        billType={billType}
                        billTypeName={billTypeName}
                        row={row}
                        addToCart={addToCart}
                        isFirstRowAddedToCart={isFirstRowAddedToCart}
                        isSecondRowAddedToCart={isSecondRowAddedToCart}
                        handleViewBill={handleViewBill}
                    />
                );
            } else {
                tableRows.push(
                    <FirstRowOfSecuredSupplementalBill
                        key={firstRowKey}
                        trKey={firstTrKey}
                        itemSize={itemSize}
                        billType={billType}
                        billTypeName={billTypeName}
                        row={row}
                        addToCart={addToCart}
                        isFirstRowAddedToCart={isFirstRowAddedToCart}
                        handleViewBill={handleViewBill}
                    />
                );
            }

            if (row.secondPaymentType != null) {
                if (isDefaultedOrUnsecured) {
                    tableRows.push(
                        <SecondRowOfDefaultedUnsecuredBill
                            key={secondRowKey}
                            trKey={secondTrKey}
                            billType={billType}
                            billTypeName={billTypeName}
                            row={row}
                            addToCart={addToCart}
                            isFirstRowAddedToCart={isFirstRowAddedToCart}
                            isSecondRowAddedToCart={isSecondRowAddedToCart}
                        />
                    );
                } else {
                    tableRows.push(
                        <SecondRowOfSecuredSupplementalBill
                            key={secondRowKey}
                            trKey={secondTrKey}
                            billType={billType}
                            billTypeName={billTypeName}
                            row={row}
                            addToCart={addToCart}
                            isFirstRowAddedToCart={isFirstRowAddedToCart}
                            isSecondRowAddedToCart={isSecondRowAddedToCart}
                        />
                    );
                }
            }
        });

        return <tbody>{tableRows}</tbody>;
    }
}
