export const OPERA = "OPERA";
export const FIREFOX = "FIREFOX";
export const SAFARI = "SAFARI";
export const IE = "IE";
export const EDGE = "EDGE";
export const CHROME = "CHROME";
export const BLINK = "BLINK";
export const UNKNOWN = "UNKNOWN";

export function GetType() {
    const isOpera = !!(window as any).opera || navigator.userAgent.indexOf(" OPR/") >= 0;
    const isFirefox = navigator.userAgent.toLowerCase().indexOf('firefox') > -1; /*typeof InstallTrigger !== "undefined";*/
    const isSafari = Object.prototype.toString.call((window as any).HTMLElement).indexOf("Constructor") > 0;
    const isIE = false || !!(document as any).documentMode;
    const isEdge = !(isIE) && !!(window as any).StyleMedia;
    const isChrome = !!(window as any).chrome && !!(window as any).chrome.webstore;
    const isBlink = (isChrome || isOpera) && !!(window as any).CSS;
    if (isOpera) {
        return OPERA;
    } else if (isFirefox) {
        return FIREFOX;
    } else if (isSafari) {
        return SAFARI;
    } else if (isIE) {
        return IE;
    } else if (isEdge) {
        return EDGE;
    } else if (isChrome) {
        return CHROME;
    } else if (isBlink) {
        return BLINK;
    }
    return UNKNOWN;
}