import {
  ApplicationActions,
  APPLICATION_SET_LOCATION,
  APPLICATION_SET_FUTUREDATEDPAYMENT,
  APPLICATION_SET_ISPAYMENTCOMPLETED,
} from "../actionTypes/Application";
import { IAppThunkAction } from "../types";

export const setLocation =
  (location: string | null): IAppThunkAction<ApplicationActions> =>
  (dispatch) => {
    dispatch({
      type: APPLICATION_SET_LOCATION,
      data: location,
    });
  };

export const setFutureDatedPayment =
  (isFutureDatedPayment: boolean): IAppThunkAction<ApplicationActions> =>
  (dispatch) => {
    dispatch({
      type: APPLICATION_SET_FUTUREDATEDPAYMENT,
      data: isFutureDatedPayment,
    });
  };

export const setIsPaymentCompleted =
  (isPaymentCompleted: boolean): IAppThunkAction<ApplicationActions> =>
  (dispatch) => {
    dispatch({
      type: APPLICATION_SET_ISPAYMENTCOMPLETED,
      data: isPaymentCompleted,
    });
  };
