import { subtract } from "lodash";
import React from "react";
import { InputGroup } from "react-bootstrap";
import { injectIntl } from "react-intl";
import { NumberInput } from "./NumberInput";

export enum CurrencyInputPropsEnum {
    intl,
    maxAmount,
    minAmount,
    decimalSeparator,
    onBlur,
    onChange,
}
class CurrencyInput extends React.Component<any, any> {
    public static defaultProps: Partial<any> = {
        decimalSeparator: ".",
    };

    constructor(props: any) {
        super(props);
        this.handleBlur = this.handleBlur.bind(this);
        this.handleChange = this.handleChange.bind(this);
    }

    CheckFieldValidity(element: any) {
        const {
            intl: { formatMessage, formatNumber },
            maxAmount,
            minAmount,
        } = this.props;
        if (maxAmount) {
            if (subtract(maxAmount, element.value) >= 0) {
                element.setCustomValidity("");
            } else {
                const amount = formatNumber(maxAmount, {
                    style: "currency",
                    currency: "USD",
                });
                element.setCustomValidity(
                    formatMessage({ id: "error.overlimit" }, { amount: amount })
                );
                return;
            }
        }
        if (minAmount) {
            if (subtract(element.value, minAmount) >= 0) {
                element.setCustomValidity("");
            } else {
                const amount = formatNumber(minAmount, {
                    style: "currency",
                    currency: "USD",
                });
                element.setCustomValidity(
                    formatMessage(
                        { id: "error.underlimit" },
                        { amount: amount }
                    )
                );
            }
        }
    }

    handleBlur(event: any) {
        const { decimalSeparator } = this.props;
        const val = event.target.value;
        if (val) {
            const hasDecimals = val.indexOf(decimalSeparator) !== -1;
            if (decimalSeparator && hasDecimals) {
                const parts = val.split(decimalSeparator);
                const afterDecimal = parts[1];
                for (let i = afterDecimal.length; i < 2; i++) {
                    event.target.value += "0";
                }
            } else {
                event.target.value += decimalSeparator + "00";
            }
            this.props.onChange(event);
        }
        if (event.target.value) {
            this.CheckFieldValidity(event.target);
        }
        if (this.props.onBlur) {
            this.props.onBlur(event);
        }
    }

    handleChange(event: any) {
        const { decimalSeparator } = this.props;
        const val = event.target.value;
        if (val) {
            let beforeDecimal = val;
            let afterDecimal = "";
            const hasDecimals = val.indexOf(decimalSeparator) !== -1;
            if (decimalSeparator && hasDecimals) {
                const parts = val.split(decimalSeparator);
                [beforeDecimal, afterDecimal] = parts;
            }
            event.target.value =
                beforeDecimal +
                ((hasDecimals && decimalSeparator) || "") +
                afterDecimal.substr(0, 2);
        }
        if (!event.target.validity.valid) {
            this.CheckFieldValidity(event.target);
        }
        if (this.props.onChange) {
            this.props.onChange(event);
        }
    }

    render() {
        const { decimalSeparator } = this.props;
        const props: any = Object.assign({}, this.props);
        Object.keys(this.props).forEach((key) => {
            if (key in CurrencyInputPropsEnum) {
                delete props[key];
            }
        });
        const addonBefore = <InputGroup.Addon>$</InputGroup.Addon>;
        return (
            <NumberInput
                {...props}
                type="number"
                decimalSeparator={decimalSeparator}
                onBlur={this.handleBlur}
                onChange={this.handleChange}
                addonBefore={addonBefore}
            />
        );
    }
}

export default injectIntl(CurrencyInput);
