import React from "react";
import { Button } from "react-bootstrap";
import { IBillDetail, IBill, IBillType, ISettings, IUser } from "../../types";
import { ButtonAutopaySetup } from "../../resources";

export interface IAutoPayButtonProps {
    user: IUser;
    bills: IBill[];
    billTypes: IBillType[];
    billDetails: IBillDetail[];
    settings: ISettings;
    submitting: boolean;
    onClick: () => void;
}

export interface IAutoPayButtonState {
    termsAccepted: boolean;
}

class AutoPayButton extends React.Component<
    IAutoPayButtonProps,
    IAutoPayButtonState
> {
    constructor(props: any) {
        super(props);
        this.state = {
            termsAccepted: false,
        };
    }
    render() {
        const { bills, billTypes, billDetails, settings, submitting } =
            this.props;
        const showButton =
            settings.enableMyAccount &&
            !settings.allowBlindPayments &&
            (settings.allowAutoBillPay || settings.enableNewBillNotifications);
        const billsNotInBillDetails = bills.filter(
            (b: IBill) =>
                billDetails &&
                billDetails.findIndex(
                    (billDetail: IBillDetail) =>
                        billDetail.identifier1 === b.id1 &&
                        billDetail.isAutopayEnabled
                ) < 0
        );
        if (
            showButton &&
            bills &&
            bills.length > 0 &&
            billsNotInBillDetails.length > 0
        ) {
            const billTypesAutoPayEligible = bills.filter(
                (b: IBill) =>
                    billTypes &&
                    billTypes.findIndex(
                        (billType: IBillType) =>
                            billType.name === b.billType &&
                            billType.autopayEligible
                    ) >= 0
            );
            const billTypesNotificationEligible = bills.filter(
                (b: IBill) =>
                    billTypes &&
                    billTypes.findIndex(
                        (billType: IBillType) =>
                            billType.name === b.billType &&
                            billType.notificationEligible
                    ) >= 0
            );
            if (
                (settings.allowAutoBillPay &&
                    billTypesAutoPayEligible.length > 0) ||
                (settings.enableNewBillNotifications &&
                    billTypesNotificationEligible.length > 0)
            ) {
                return (
                    <Button
                        bsClass="btn btn-success"
                        disabled={this.props.submitting}
                        onClick={this.props.onClick}
                        style={{ minWidth: "6.5em" }}
                    >
                        {ButtonAutopaySetup}
                    </Button>
                );
            } else {
                return null;
            }
        } else {
            return null;
        }
    }
}

export default AutoPayButton;
