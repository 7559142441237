import { HelpBlock } from "react-bootstrap";
import { FormattedMessage } from "react-intl";
import * as Resources from "../resources";
import { IFormError } from "../types/IFormError";

//polyfill function because IE is stupid.
//Math.trunc returns the integer value of a number with no rounding
if (!Math.trunc) {
    Math.trunc = function (v) {
        v = +v;
        if (!isFinite(v)) return v;

        return v - (v % 1) || (v < 0 ? -0 : v === 0 ? v : 0);

        // returns:
        //  0        ->  0
        // -0        -> -0
        //  0.2      ->  0
        // -0.2      -> -0
        //  0.7      ->  0
        // -0.7      -> -0
        //  Infinity ->  Infinity
        // -Infinity -> -Infinity
        //  NaN      ->  NaN
        //  null     ->  0
    };
}

const mapStringToNumberArray = (value: string): number[] =>
    value
        .split("")
        .filter((v) => /\d/.test(v))
        .map((v) => Number(v));

const parityDigitSum = (value: number): number =>
    Math.trunc(value / 10) + (value % 10);

const positionWeightedSum = (values: number[]): number =>
    3 * (values[0] + values[3] + values[6]) +
    7 * (values[1] + values[4] + values[7]) +
    (values[2] + values[5] + values[8]);

const mapParityDigits = (
    values: number[],
    strategy: (idx: number) => boolean
): number[] =>
    values.map((v: number, idx: number) =>
        strategy(idx) ? v : parityDigitSum(v * 2)
    );

const sum = (accumulator: number, value: number) => (accumulator += value);

const isCardNumberValid = (value: string): boolean => {
    const cardNumberArray = mapStringToNumberArray(value).reverse();

    if (cardNumberArray.length < 12 || cardNumberArray.length > 19)
        return false;

    const checkSum = mapParityDigits(
        cardNumberArray,
        (idx: number): boolean => idx % 2 === 0
    ).reduce(sum, 0);

    return checkSum % 10 === 0;
};

const isRoutingNumberValid = (value: string): boolean => {
    const values = mapStringToNumberArray(value);
    const checkSum = positionWeightedSum(values);

    return checkSum % 10 === 0;
};

export function GetValidationMessage(element: any) {
    if (element) {
        if (!element.validity.valid) {
            if (element.validity.customError) {
                switch (element.validationMessage) {
                    case "postalPatternMismatch":
                        return Resources.PostalPatternMismatch;
                    case "emailPatternMismatch":
                        return Resources.EmailPatternMismatch;
                    case "form.error.cardNumber":
                        return (
                            <FormattedMessage
                                id="form.error.cardNumber"
                                defaultMessage="This is not a valid card number."
                            >
                                {(message) => <HelpBlock>{message}</HelpBlock>}
                            </FormattedMessage>
                        );
                    case "form.error.cardNotAccepted":
                        return (
                            <FormattedMessage
                                id="form.error.cardNotAccepted"
                                defaultMessage="The merchant does not accept {cardBrand}"
                                values={{
                                    cardBrand:
                                        element.attributes["data-cardtype"]
                                            .value,
                                }}
                            >
                                {(message) => <HelpBlock>{message}</HelpBlock>}
                            </FormattedMessage>
                        );
                    case "phonePatternMismatch":
                        return (
                            <FormattedMessage
                                id="form.error.phoneNumber"
                                defaultMessage="This is not a valid phone number for the country provided."
                            >
                                {(message) => <HelpBlock>{message}</HelpBlock>}
                            </FormattedMessage>
                        );
                    case "form.error.routingNumber":
                        return (
                            <FormattedMessage
                                id="form.error.routingNumber"
                                defaultMessage="This is not a valid bank routing number."
                            >
                                {(message) => <HelpBlock>{message}</HelpBlock>}
                            </FormattedMessage>
                        );
                    case "form.error.nomatch":
                        return (
                            <FormattedMessage
                                id="form.error.nomatch"
                                defaultMessage="This field does not match."
                            >
                                {(message) => <HelpBlock>{message}</HelpBlock>}
                            </FormattedMessage>
                        );
                    case "form.error.accountMismatch":
                        return (
                            <FormattedMessage
                                id="form.error.accountMismatch"
                                defaultMessage="The account number entries do not match, please verify both numbers are correct."
                            >
                                {(message) => <HelpBlock>{message}</HelpBlock>}
                            </FormattedMessage>
                        );
                    case "form.error.patternMismatch":
                        return (
                            <FormattedMessage
                                id="form.error.patternMismatch"
                                defaultMessage="This field does not match the expected pattern."
                            >
                                {(message) => <HelpBlock>{message}</HelpBlock>}
                            </FormattedMessage>
                        );
                    case "form.error.expiredCard":
                        return (
                            <FormattedMessage
                                id="form.error.expiredCard"
                                defaultMessage="This expiration date has already passed."
                            >
                                {(message) => <HelpBlock>{message}</HelpBlock>}
                            </FormattedMessage>
                        );
                    case "form.error.valueMissing":
                        return (
                            <FormattedMessage
                                id="form.error.valueMissing"
                                defaultMessage="This field is required."
                            >
                                {(message) => <HelpBlock>{message}</HelpBlock>}
                            </FormattedMessage>
                        );
                    default:
                        return (
                            <HelpBlock>{element.validationMessage}</HelpBlock>
                        );
                }
            } else if (element.title) {
                switch (element.title) {
                    case "form.error.accountNumber":
                        return (
                            <FormattedMessage
                                id="form.error.accountNumber"
                                defaultMessage="This field must be between 4 and 17 digits."
                            >
                                {(message) => <HelpBlock>{message}</HelpBlock>}
                            </FormattedMessage>
                        );
                    default:
                        return <HelpBlock>{element.title}</HelpBlock>;
                }
            } else if (element.validity.badInput) {
                return (
                    <FormattedMessage
                        id="form.error.badInput"
                        defaultMessage="This field has bad input."
                    >
                        {(message) => <HelpBlock>{message}</HelpBlock>}
                    </FormattedMessage>
                ); // "This field has bad input.";
            } else if (element.validity.patternMismatch) {
                return (
                    <FormattedMessage
                        id="form.error.patternMismatch"
                        defaultMessage="This field does not match the expected pattern."
                    >
                        {(message) => <HelpBlock>{message}</HelpBlock>}
                    </FormattedMessage>
                ); // "This field does not match the expected pattern.";
            } else if (element.validity.rangeOverflow) {
                return (
                    <FormattedMessage
                        id="form.error.rangeOverflow"
                        defaultMessage="This field must be less than {number}."
                        values={{ number: element.max }}
                    >
                        {(message) => <HelpBlock>{message}</HelpBlock>}
                    </FormattedMessage>
                ); // `This field must be less than ${element.max}.`;
            } else if (element.validity.rangeUnderflow) {
                return (
                    <FormattedMessage
                        id="form.error.rangeUnderflow"
                        defaultMessage="This field must be more than {number}."
                        values={{ number: element.min }}
                    >
                        {(message) => <HelpBlock>{message}</HelpBlock>}
                    </FormattedMessage>
                ); // `This field must be more than ${element.min}.`;
            } else if (element.validity.tooLong) {
                return (
                    <FormattedMessage
                        id="form.error.tooLong"
                        defaultMessage="This field must be less than {number} characters."
                        values={{ number: element.maxLength }}
                    >
                        {(message) => <HelpBlock>{message}</HelpBlock>}
                    </FormattedMessage>
                ); // `This field must be less than ${element.maxLength} characters.`;
            } else if (element.validity.tooShort) {
                return (
                    <FormattedMessage
                        id="form.error.tooShort"
                        defaultMessage="This field must at least {number} characters."
                        values={{ number: element.minLength }}
                    >
                        {(message) => <HelpBlock>{message}</HelpBlock>}
                    </FormattedMessage>
                ); // `This field must be more than ${element.minLength} characters.`;
            } else if (element.validity.valueMissing) {
                return (
                    <FormattedMessage
                        id="form.error.valueMissing"
                        defaultMessage="This field is required."
                    >
                        {(message) => <HelpBlock>{message}</HelpBlock>}
                    </FormattedMessage>
                ); // "This field is required.";
            }
        }
    }
    return null;
}

export function CheckFormValidity(
    id: any,
    onError: (errors: IFormError) => void
) {
    const errors: { [key: string]: string | JSX.Element | null } = {};
    let valid = true;

    for (
        let i = 0;
        i <= (document.getElementById(id) as any).elements.length;
        i++
    ) {
        const element: any = document.forms[id].elements[i];
        if (element) {
            const message = GetValidationMessage(element);

            if (message) {
                errors[element.name] = message;
            }

            if (!element.validity.valid) {
                if (valid) {
                    element.focus();
                }
                valid = false;
            }
        }
    }

    if (onError) {
        onError(errors);
    }

    return valid;
}

export function CheckFormValidityOnLoad(id: any, onError: any) {
    for (
        let i = 0;
        i <= (document.getElementById(id) as any).elements.length;
        i++
    ) {
        const element: any = document.forms[id].elements[i];
        if (element && element.value) {
            if (onError) {
                onError(element.name, GetValidationMessage(element));
            }
        }
    }
}

export function CheckFieldValidity(element: any, onError: any) {
    if (element) {
        if (onError) {
            onError(element.name, GetValidationMessage(element));
        }
    }
}

export function routingNumberCheck(value: string): boolean {
    return (
        value.length === 9 &&
        value !== "000000000" &&
        isRoutingNumberValid(value)
    );
}

export function checkLuhn(cardNumber: string): boolean {
    if (!cardNumber || cardNumber.length === 0) return true;

    return isCardNumberValid(cardNumber);
}

export function isCardExpired(
    expirationMonth: string | number,
    expirationYear: string | number
): boolean {
    if (
        typeof expirationMonth === "undefined" ||
        typeof expirationYear === "undefined"
    )
        return false;

    const currentYear = new Date().getFullYear();
    const currentMonth = new Date().getMonth() + 1;

    return (
        expirationYear < currentYear ||
        (expirationYear === currentYear && expirationMonth < currentMonth)
    );
}
