import { Reducer } from "redux";
import {
    BILLS_LOAD,
    BILLS_LOAD_SUCCESS,
    BILL_IMAGE_LOAD_SUCCESS,
    BILL_IMAGE_LOAD_FAIL,
    BILLS_LOAD_FAIL,
    BILLS_CREATE,
    BILLS_UPDATE,
    BILLS_DELETE,
    BILLS_CLEAR,
    RequestBillsAction,
    ReceiveBillsAction,
    ReceiveBillImageAction,
    FailedBillImageAction,
    FailedBillsAction,
    CreateBillAction,
    UpdateBillAction,
    DeleteBillAction,
    BillsActions,
} from "../actionTypes/Bills";
import {
    CONFIGURATION_LOAD_SUCCESS,
    CONFIGURATION_LOAD_FAIL,
    ReceiveConfigurationAction,
    FailedConfigurationAction,
} from "../actionTypes/Configuration";
import { CANCEL, CLEAR } from "../actionTypes/Common";
import { IBill, IMerchantBills } from "../types";

export const initialState: IMerchantBills = {
    isLoaded: false,
    isLoading: false,
    error: null,
    bills: [] as IBill[],
    shareTokenWithGroup: false,
    maxFuturePaymentDays: 0,
};

const billsLoadAction = (
    state: IMerchantBills,
    action: RequestBillsAction
): IMerchantBills => {
    return {
        ...state,
        isLoading: true,
        isLoaded: false,
        error: null,
    };
};

const billsLoadSuccessAction = (
    state: IMerchantBills,
    action: ReceiveBillsAction
): IMerchantBills => {
    return {
        ...state,
        isLoading: false,
        isLoaded: true,
        error: null,
        bills: action.data || [],
    };
};

const billsLoadFailAction = (
    state: IMerchantBills,
    action: FailedBillsAction
): IMerchantBills => {
    return {
        ...state,
        isLoading: false,
        isLoaded: false,
        error: action.data,
    };
};

const billImageLoadSuccessAction = (
    state: IMerchantBills,
    action: ReceiveBillImageAction
): IMerchantBills => {
    return {
        ...state,
        isLoading: false,
        isLoaded: true,
        error: null,
        bills: state.bills.map((b) => {
            if (b.id === action.billId || b.billId === action.billId) {
                b.imageContent = action.data.billImage.fileContent;
                b.detectedMIMEType = action.data.billImage.detectedMIMEType;
            }
            return b;
        }),
    };
};

const billCreateAction = (
    state: IMerchantBills,
    action: CreateBillAction
): IMerchantBills => {
    return {
        ...state,
        error: null,
        bills: [...state.bills, action.data],
    };
};

const billUpdateAction = (
    state: IMerchantBills,
    action: UpdateBillAction
): IMerchantBills => {
    return {
        ...state,
        bills: [
            ...state.bills.filter(
                (bill) => bill.id.toString() !== action.data.id.toString()
            ),
            Object.assign({}, action.data),
        ],
    };
};

const billDeleteAction = (
    state: IMerchantBills,
    action: DeleteBillAction
): IMerchantBills => {
    return {
        ...state,
        bills: state.bills.filter((b: IBill) => b.id !== action.data),
    };
};

const configurationLoadSuccessAction = (
    state: IMerchantBills,
    action: ReceiveConfigurationAction
): IMerchantBills => {
    return {
        ...state,
        isLoading: false,
        isLoaded: true,
        error: null,
        bills: action.data.bills.bills || [],
        shareTokenWithGroup: action.data.bills.shareTokenWithGroup,
        maxFuturePaymentDays: action.data.bills.maxFuturePaymentDays,
    };
};

const configurationLoadFailAction = (
    state: IMerchantBills,
    action: FailedConfigurationAction
): IMerchantBills => {
    return {
        ...state,
        isLoading: false,
        isLoaded: false,
        error: action.data,
    };
};

export const reducer: Reducer<IMerchantBills> = (
    state: IMerchantBills = initialState,
    action: BillsActions
) => {
    switch (action.type) {
        case BILLS_LOAD:
            return billsLoadAction(state, action);
        case BILLS_LOAD_SUCCESS:
            return billsLoadSuccessAction(state, action);
        case BILLS_LOAD_FAIL:
            return billsLoadFailAction(state, action);
        case BILLS_CREATE:
            return billCreateAction(state, action);
        case BILLS_UPDATE:
            return billUpdateAction(state, action);
        case BILLS_DELETE:
            return billDeleteAction(state, action);
        case BILL_IMAGE_LOAD_SUCCESS:
            return billImageLoadSuccessAction(state, action);
        case CONFIGURATION_LOAD_SUCCESS:
            return configurationLoadSuccessAction(state, action);
        case CONFIGURATION_LOAD_FAIL:
            return configurationLoadFailAction(state, action);
        case BILLS_CLEAR:
            return { ...initialState };
        case CLEAR:
            return { ...initialState };
        case CANCEL:
            return { ...initialState };
        default:
            return state;
    }
};
