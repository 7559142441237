import { FormattedDate, FormattedNumber } from "react-intl";
import { CurrencyInput } from "../Input";
import ResultsButton from "./ResultsButton";
const dataStyle: React.CSSProperties = { verticalAlign: "middle" }
const ResultsRow = ({
    settings,
    billType,
    bill,
    selected,
    addToBills,
    deleteFromBills,
    onChange,
    onError,
    onBlur,
    maxAmount,
    calculateAmount,
}: {
    settings: any;
    billType: any;
    bill: any;
    selected: any;
    addToBills: any;
    deleteFromBills: any;
    onChange: any;
    onError: any;
    onBlur: any;
    maxAmount: any;
    calculateAmount: any;
}) => (
    <tr id={bill.id} style={{ verticalAlign: "middle" }}>
        {billType.id1.label && !billType.id1.isSearchOnly ? (
            <td style={dataStyle}>{bill.id1}</td>
        ) : null}
        {billType.id2.label && !billType.id2.isSearchOnly ? (
      <td style={dataStyle}>{bill.id2}</td>
        ) : null}
        {billType.id3.label && !billType.id3.isSearchOnly ? (
      <td style={dataStyle}>{bill.id3}</td>
        ) : null}
        {billType.id4.label && !billType.id4.isSearchOnly ? (
            <td style={{ verticalAlign: "middle" }}>{bill.id4}</td>
        ) : null}
        {!settings.billDueDateIsHidden && (
      <td style={dataStyle}>
                <FormattedDate
                    value={bill.dueDate}
                    year="numeric"
                    month="2-digit"
                    day="2-digit"
                />
            </td>
        )}
    <td style={dataStyle}>
            <FormattedNumber
                value={bill.amountDue}
                style="currency"
                currency="USD"
            />
        </td>
        {settings.allowBillAmountToBeEdited && (
            <td
            style={dataStyle}
            >
                <CurrencyInput
                    name={["customAmount", bill.id].join("_")}
                    value={calculateAmount(bill)}
                    maxAmount={maxAmount}
                    disableScrollStep={true}
                    disableArrowStep={true}
                    style={{
                      verticalAlign: "middle",
                      textSite: "small",
                      minWidth: "5.0em",
                      maxWidth: "8.0em",
                    }}
                    className="customamount"
                    error={bill.error}
                    onChange={onChange}
                    onError={onError}
                    onBlur={onBlur}
                    disabled={
                        selected ||
                        ((bill.paidInFullDate || bill.amountDue <= 0) &&
                            !settings.allowBillOverpayment)
                            ? true
                            : false
                    }
                />
            </td>
        )}
        <td className="text-center" style={dataStyle}>
            <ResultsButton
                settings={settings}
                bill={bill}
                billType={billType}
                selected={selected}
                deleteFromBills={deleteFromBills}
                addToBills={addToBills}
            />
        </td>
    </tr>
);

export default ResultsRow;
