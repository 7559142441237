import React, { ReactNode } from "react";
import { Alert } from "react-bootstrap";
import { IntlProvider } from "react-intl";
import { connect } from "react-redux";
import ReduxToastr from "react-redux-toastr";
import "react-redux-toastr/lib/css/react-redux-toastr.min.css";
import { matchPath, withRouter } from "react-router-dom";
import { RouteComponentProps } from "react-router-dom";
import { setLocation } from "../actions/Application";
import { cancel } from "../actions/Common";
import { loadInitialData, loadMaintenanceData } from "../actions/Configuration";
import { set } from "../actions/Intl";
import { setActive } from "../actions/MyAccount";
import { loadPaymentTerms } from "../actions/PaymentTerms";
import { HeaderMyAccount } from "../resources";
import {
    IApplication,
    IApplicationAnalytics,
    IApplicationRoute,
    IApplicationState,
    IInternationalization,
    IMyAccount,
    IPaymentTerms,
    IReceipt,
    ISettings,
    IUser,
    IDateResponse,
} from "../types";
import { BlockUi } from "./Layout/BlockUi";
import { Footer } from "./Layout/Footer/Footer";
import { FooterSanDiego } from "./Layout/FooterSanDiego/FooterSanDiego";
import Header from "./Layout/Header/Header";
import HeaderSanDiego from "./Layout/HeaderSanDiego/HeaderSanDiego";
import IdleTimeout from "./Layout/IdleTimeout";
import StepWizard from "./Layout/StepWizard";
import MaintenancePage from "./Maintenance/MaintenancePage";
import { routesSDInternal, defaultRoutes } from "../routes";
import BillSearch from "./SanDiegoBillSearch/BillSearch";
import { GetCurrentUTCDate } from "../api/Settings";
import moment from "moment";
import { IApplicationMaintenance } from "../types/IApplicationMaintenanceState";
import { MaintenanceResultType } from "../types/enums/MaintenanceResultType";

interface LayoutRouteProps extends IApplicationRoute {
    page: string;
}

interface LayoutOwnProps extends RouteComponentProps<LayoutRouteProps> {
    children?: ReactNode | ReactNode[];
}

interface LayoutStateProps {
    intl: IInternationalization;
    application: IApplication;
    myAccount: IMyAccount;
    settings: ISettings;
    ajaxCounter: IApplicationAnalytics;
    receipt: IReceipt;
    paymentTerms: IPaymentTerms;
    user: IUser;
    isPaymentCompleted?: boolean;
    applicationMaintenance?: IApplicationMaintenance;
}

interface LayoutDispatchProps {
    loadPaymentTerms: (
        application: IApplication,
        merchantName: string,
        templateId: number,
        methodId: number,
        languageId: number,
        feeAmount: number
    ) => void;
    loadInitialData: (
        merchantName: string,
        guid?: string,
        transactionId?: string
    ) => void;
    refreshIntl: (language: string) => void;
    setLocation: (location: string | null) => void;
    setActive: (active: boolean) => void;
    cancel: VoidFunction;
    loadMaintenanceData: (
        merchantName: string,
        application?: IApplication
    ) => void;
}

interface LayoutState {
    currentDate: moment.Moment | null;
    maintenanceDataIsLoaded: boolean;
}

export type LayoutProps = LayoutOwnProps &
    LayoutStateProps &
    LayoutDispatchProps;

const getRouteProps = (path: string): LayoutRouteProps => {
    const pathToMatch = path.includes("CredentialManager")
        ? "/:merchantName?/:page?/:passwordResetToken?/:guid?"
        : "/:merchantName?/:page?/:guid?/:transactionId?";

    const match = matchPath<LayoutRouteProps>(path, {
        path: pathToMatch,
        exact: false,
        strict: false,
    });

    return match ? match.params : ({} as LayoutRouteProps);
};
export class Layout extends React.Component<LayoutProps, LayoutState> {
    constructor(props: LayoutProps) {
        super(props);

        this.state = {
            currentDate: null,
            maintenanceDataIsLoaded: false,
        };

        const { intl, loadInitialData, refreshIntl, settings } = this.props;
        const params = getRouteProps(this.props.location.pathname);

        const securePayGuid =
            (params.page || "").toUpperCase() === "GUID"
                ? params.guid
                : undefined;
        const ebppGuid =
            (params.page || "").toUpperCase() === "UID"
                ? params.guid
                : undefined;
        const transactionId = params.transactionId;

        const initialDataLoaded = settings.isLoaded;
        if (!initialDataLoaded) {
            loadInitialData(params.merchantName, params.guid, transactionId);
        } else {
            refreshIntl(intl.locale);
        }

        this.showStepWizard = this.showStepWizard.bind(this);
    }

    public componentDidMount(): void {
        GetCurrentUTCDate().then((result: IDateResponse) => {
            if (result.isSuccessful) {
                this.setState({
                    currentDate: moment(result.date),
                });
            }
        });
    }

    componentDidUpdate(prevProps: LayoutProps) {
        if (this.props.location != prevProps.location) {
            window.scrollTo(0, 0);
        }
        if (
            this.props.settings.isLoaded &&
            !this.state.maintenanceDataIsLoaded
        ) {
            const params = getRouteProps(this.props.location.pathname);
            this.props.loadMaintenanceData(
                params.merchantName,
                this.props.application
            );
            this.setState({
                maintenanceDataIsLoaded: true,
            });
        }
        if (this.isLoginRequired()) {
            this.props.history.push(
                `/${this.props.settings.merchantName}/Login/${this.props.application.guid}`
            );
        }
    }

    /* componentDidUpdate(prevProps: LayoutProps, prevState: LayoutState) {
        //const { intl, loadInitialData, receipt, loadPaymentTerms } = this.props;
        let params = {} as LayoutRouteProps;
        //let securePayGuid: string | undefined;
        //let ebppGuid: string | undefined;
        //let transactionId: string | undefined;

        if (prevState.location !== this.props.location.pathname) {
            params = getRouteProps(this.props.location.pathname);
            securePayGuid = (params.page || '').toUpperCase() === 'GUID' ? params.guid : undefined;
            ebppGuid = (params.page || '').toUpperCase() === 'UID' ? params.guid : undefined;
            transactionId = params.transactionId;

            this.setState({ params, location: this.props.location.pathname });
        }

        if (!intl.isLoaded && !Boolean(intl.error)) {
            loadInitialData(params.merchantName, securePayGuid, ebppGuid, transactionId);
        }
    } */

    componentWillReceiveProps(nextProps: LayoutProps) {
        if (this.props !== nextProps) {
            const pathname = nextProps.location.pathname;
            const isMyAccountPath = pathname
                .toLowerCase()
                .includes("myaccount");
            if (
                isMyAccountPath &&
                pathname !== nextProps.application.location
            ) {
                this.props.setLocation(pathname);
            }
            if (isMyAccountPath !== nextProps.myAccount.active) {
                if (!isMyAccountPath) this.props.setLocation(null);
                this.props.setActive(isMyAccountPath);
            }
            const isBillDetailsPath = pathname
                .toLowerCase()
                .includes("billdetail");
            if (
                isBillDetailsPath &&
                pathname !== nextProps.application.location
            ) {
                this.props.setLocation(pathname);
            }
            if (isBillDetailsPath !== nextProps.application.isBillDetail) {
                if (!isBillDetailsPath) this.props.setLocation(null);
            }
            const isLoginPath = pathname.toLowerCase().includes("login");
            if (isLoginPath && pathname !== nextProps.application.location) {
                this.props.setLocation(pathname);
            }
        }
    }

    private showStepWizard(): boolean {
        const { application } = this.props;
        const params = getRouteProps(this.props.location.pathname);
        const pageName = params.page ? params.page : "";
        const loweredPageName = pageName.toLowerCase();
        if (loweredPageName === "billsearch") {
            return false;
        }
        return (
            application.isSuccessful &&
            application.securePayPaymentType !== 3 &&
            !application.canceled &&
            this.props.settings.isSuccessful &&
            !this.props.myAccount.active &&
            Boolean(params.merchantName) &&
            !params.transactionId &&
            !params.passwordResetToken &&
            !application.isBillDetail &&
            params.page !== "Login"
        );
    }

    private isLoginRequired = (): boolean => {
        const { application, settings, user, location } = this.props;

        // Do not redirect if already loging in, changing password, or already logged in
        if (
            location.pathname.toLowerCase().includes("login") ||
            location.pathname.toLowerCase().includes("credentialmanager") ||
            user.isLoggedIn
        ) {
            return false;
        }

        // Require login for EBPP
        if (application.isEBPP && settings.requireLoginEBPP) {
            return true;
        }

        return false;
    };

    private myAccountHeader() {
        return (
            <div className="breadcrumbs hidden-print">
                <ul className="cf">
                    <li>
                        <span>
                            <h4
                                style={{ color: "white", padding: "10px 15px" }}
                            >
                                {HeaderMyAccount}
                            </h4>
                        </span>
                    </li>
                </ul>
            </div>
        );
    }

    private showMessageOnSearchPage() {
        return (
            this.props.application.id === 33 &&
            !this.props.settings.allowBlindPayments
        );
    }

    private showMessageOnBillsPage() {
        // The Bills page can only be skipped for SecurePay applications
        return (
            (this.props.application.id === 33 &&
                this.props.settings.allowBlindPayments) ||
            (this.props.application.id !== 33 &&
                !this.props.settings.skipBillsPage)
        );
    }

    private showMessageOnPayorPage() {
        return (
            !this.showMessageOnBillsPage() &&
            !this.showMessageOnSearchPage() &&
            !this.props.settings.skipPayorPage
        );
    }

    private showMessageOnPaymentPage() {
        return (
            !this.showMessageOnBillsPage() &&
            !this.showMessageOnSearchPage() &&
            !this.showMessageOnPayorPage()
        );
    }

    private showInAppMessage() {
        const match = getRouteProps(this.props.location.pathname);
        const page = match.page ? match.page.toLowerCase() : "";

        return (
            !!this.props.settings.webPaymentsAppMessage &&
            ((page === "search" && this.showMessageOnSearchPage()) ||
                (page === "bills" && this.showMessageOnBillsPage()) ||
                (page === "payor" && this.showMessageOnPayorPage()) ||
                (page === "payment" && this.showMessageOnPaymentPage()))
        );
    }

    private resolveCurrentMaintenance = (
        maintenanceData: IApplicationMaintenance,
        currentDate: moment.Moment
    ): MaintenanceResultType | undefined => {
        const maintenanceMessageFeatureFlag =
            maintenanceData.maintenance.isFeatureFlagEnabled;
        if (!maintenanceMessageFeatureFlag) {
            return;
        }
        const messages = maintenanceData.maintenance.maintenanceEvents?.map(
            (x) => {
                return {
                    fromUTC: this.addUtcMarkerToDateString(`${x.from}`),
                    toUTC: this.addUtcMarkerToDateString(`${x.to}`),
                    messages: x.texts,
                };
            }
        );

        if (messages && messages.length) {
            return messages.find(
                (messageRecord) =>
                    messageRecord &&
                    currentDate.isBetween(
                        messageRecord.fromUTC,
                        messageRecord.toUTC
                    )
            );
        }
    };

    private addUtcMarkerToDateString = (date: string) => {
        if (date && date.indexOf("Z") < 0) {
            return `${date}Z`;
        }
    };

    public render() {
        if (!this.props.intl.isLoaded || this.state.currentDate === null)
            return <BlockUi shouldRender={true} />;
        if (this.props.settings.billSearchType === "SanDiego") {
            return this.renderSandiegoLayout();
        } else {
            return this.renderDefaultLayout();
        }
    }

    private renderSandiegoLayout = () => {
        const {
            settings,
            ajaxCounter: { loadCount },
            myAccount,
            applicationMaintenance,
        } = this.props;

        const maintenanceToShow = this.resolveCurrentMaintenance(
            applicationMaintenance!,
            this.state.currentDate!
        );
        const params = getRouteProps(this.props.location.pathname);
        const hideLoginButton = params.page === "Login";

        return (
            <div>
                <div className="billsearch-bar-top" />
                <div className="billsearch-background">
                    <div className="container">
                        <IntlProvider
                            locale={this.props.intl.locale}
                            messages={this.props.intl.messages}
                        >
                            <div>
                                <HeaderSanDiego
                                    history={this.props.history}
                                    match={this.props.match}
                                    location={this.props.location}
                                    hideLoginButton={hideLoginButton}
                                    hideNavbar={false}
                                />
                                {this.renderSharedComponents(
                                    loadCount,
                                    settings
                                )}

                                {maintenanceToShow && (
                                    <MaintenancePage
                                        messages={maintenanceToShow!.messages!}
                                    />
                                )}

                                {this.showInAppMessage() && (
                                    <Alert bsStyle="warning">
                                        {settings.webPaymentsAppMessage}
                                    </Alert>
                                )}
                                {myAccount &&
                                    myAccount.active &&
                                    this.myAccountHeader()}
                                {!maintenanceToShow && (
                                    <BillSearch {...this.props}>
                                        {routesSDInternal.map(
                                            (component, index) => (
                                                <span key={index}>
                                                    {component}
                                                </span>
                                            )
                                        )}
                                    </BillSearch>
                                )}
                            </div>
                        </IntlProvider>
                    </div>
                </div>
                <FooterSanDiego />
            </div>
        );
    };

    private renderDefaultLayout = () => {
        const {
            application,
            settings,
            ajaxCounter: { loadCount },
            myAccount,
            applicationMaintenance,
        } = this.props;
        const params = getRouteProps(this.props.location.pathname);

        const hideLoginButton = params.page === "Login";
        const maintenanceToShow = this.resolveCurrentMaintenance(
            applicationMaintenance!,
            this.state.currentDate!
        );
        return (
            <div className="container">
                <IntlProvider
                    key={this.props.intl.locale}
                    locale={this.props.intl.locale}
                    messages={this.props.intl.messages}
                >
                    <div>
                        {this.renderSharedComponents(loadCount, settings)}
                        <Header
                            history={this.props.history}
                            hideLoginButton={hideLoginButton}
                            hideNavbar={false}
                        />
                        {this.showInAppMessage() && (
                            <Alert
                                bsStyle="warning"
                                style={{ margin: 0, marginBottom: 20 }}
                            >
                                {settings.webPaymentsAppMessage}
                            </Alert>
                        )}
                        {!maintenanceToShow && this.showStepWizard() && (
                            <StepWizard
                                application={application}
                                settings={settings}
                                location={this.props.location}
                            />
                        )}
                        {myAccount &&
                            myAccount.active &&
                            this.myAccountHeader()}
                        {!maintenanceToShow && (
                            <div className="wrap-overflow">
                                <div className="row">
                                    <div className="col-md-12">
                                        {defaultRoutes.map(
                                            (component, index) => (
                                                <span key={index}>
                                                    {component}
                                                </span>
                                            )
                                        )}
                                    </div>
                                </div>
                            </div>
                        )}
                        {maintenanceToShow && (
                            <MaintenancePage
                                messages={maintenanceToShow!.messages!}
                            />
                        )}
                        <Footer
                            customFooterHtml={settings.customFooterHtml}
                            friendlyName={settings.friendlyName}
                            footerImageUrl={settings.footerImageUrl}
                        />
                    </div>
                </IntlProvider>
            </div>
        );
    };

    private renderSharedComponents = (
        loadCount: number,
        settings: ISettings
    ) => {
        return (
            <div>
                <BlockUi shouldRender={loadCount > 0} />
                <IdleTimeout
                    application={this.props.application}
                    settings={this.props.settings}
                    history={this.props.history}
                    cancel={this.props.cancel}
                />
                <ReduxToastr
                    timeOut={5000}
                    newestOnTop={false}
                    position="top-center"
                    progressBar={false}
                    transitionIn="fadeIn"
                    transitionOut="fadeOut"
                    closeOnToastrClick={false}
                />
                {settings && settings.stylesheet && (
                    <link
                        rel="stylesheet"
                        type="text/css"
                        href={settings.stylesheet}
                    />
                )}
                {settings && settings.stylesheet && (
                    <link
                        rel="stylesheet"
                        type="text/css"
                        media="print"
                        href={settings.stylesheet}
                    />
                )}
            </div>
        );
    };
}

export default withRouter(
    connect(
        (state: IApplicationState) => ({
            intl: state.intl,
            myAccount: state.myAccount,
            settings: state.settings,
            application: state.application,
            ajaxCounter: state.ajaxCounter,
            receipt: state.receipt,
            paymentTerms: state.paymentTerms,
            user: state.user,
            isPaymentCompleted: state.application.isPaymentCompleted,
            applicationMaintenance: state.applicationMaintenance,
        }),
        {
            loadInitialData,
            refreshIntl: set,
            loadPaymentTerms,
            setActive,
            setLocation,
            cancel,
            loadMaintenanceData,
        }
    )(Layout)
);
