import { Reducer } from "redux";
import {
  BillTypeActions,
  BILLTYPEIMAGE_LOAD,
  BILLTYPEIMAGE_LOAD_FAIL,
  BILLTYPEIMAGE_LOAD_SUCCESS,
  BILLTYPES_LOAD,
  BILLTYPES_LOAD_FAIL,
  BILLTYPES_LOAD_SUCCESS,
  FailedBillTypeAction,
  FailedBillTypeImageAction,
  ReceiveBillTypeAction,
  ReceiveBillTypeImageAction,
  RequestBillTypeAction,
  RequestBillTypeImageAction,
} from "../actionTypes/BillType";
import {
  CONFIGURATION_EBPP_LOAD_SUCCESS,
  CONFIGURATION_LOAD_FAIL,
  CONFIGURATION_LOAD_SUCCESS,
  FailedConfigurationAction,
  ReceiveConfigurationAction,
  ReceiveConfigurationEBPPAction,
} from "../actionTypes/Configuration";
import { IApplicationBillTypes, IBillType } from "../types";

export const initialState: IApplicationBillTypes = {
  isLoaded: false,
  isLoading: false,
  error: null,
  billTypes: [] as IBillType[],
};

const billTypesLoadAction = (
  state: IApplicationBillTypes,
  action: RequestBillTypeAction
): IApplicationBillTypes => {
  return {
    ...state,
    isLoading: true,
    isLoaded: false,
    error: null,
  };
};

const billTypesLoadSuccessAction = (
  state: IApplicationBillTypes,
  action: ReceiveBillTypeAction
): IApplicationBillTypes => {
  return {
    ...state,
    isLoading: false,
    isLoaded: true,
    error: null,
    billTypes: action.data,
  };
};

const billTypesLoadFailAction = (
  state: IApplicationBillTypes,
  action: FailedBillTypeAction
): IApplicationBillTypes => {
  return {
    ...state,
    isLoading: false,
    isLoaded: false,
    error: action.data,
  };
};

const configurationLoadSuccessAction = (
  state: IApplicationBillTypes,
  action: ReceiveConfigurationAction
): IApplicationBillTypes => {
  return {
    ...state,
    isLoading: false,
    isLoaded: true,
    error: null,
    billTypes: action.data.billTypes,
  };
};

const configurationEbppLoadSuccessAction = (
  state: IApplicationBillTypes,
  action: ReceiveConfigurationEBPPAction
): IApplicationBillTypes => {
  return {
    ...state,
    isLoading: false,
    isLoaded: true,
    error: null,
    billTypes: action.data.billTypes,
  };
};

const configurationLoadFailAction = (
  state: IApplicationBillTypes,
  action: FailedConfigurationAction
): IApplicationBillTypes => {
  return {
    ...state,
    isLoading: false,
    isLoaded: false,
    error: action.data,
  };
};

const billTypeImageLoadAction = (
  state: IApplicationBillTypes,
  action: RequestBillTypeImageAction
): IApplicationBillTypes => {
  return {
    ...state,
    isLoading: true,
    isLoaded: false,
    error: null,
  };
};

const billTypeImageLoadSuccessAction = (
  state: IApplicationBillTypes,
  action: ReceiveBillTypeImageAction
): IApplicationBillTypes => {
  return {
    ...state,
    isLoading: false,
    isLoaded: true,
    error: null,
    billTypes: state.billTypes.map((b: IBillType) => {
      if (b.name === action.billName) {
        b.imageContent = action.data;
      }

      return b;
    }),
  };
};

const billTypeImageLoadFailAction = (
  state: IApplicationBillTypes,
  action: FailedBillTypeImageAction
): IApplicationBillTypes => {
  return {
    ...state,
    isLoading: false,
    isLoaded: true,
    error: action.data,
  };
};

export const reducer: Reducer<IApplicationBillTypes> = (
  state: IApplicationBillTypes = initialState,
  action: BillTypeActions
): IApplicationBillTypes => {
  switch (action.type) {
    case BILLTYPES_LOAD:
      return billTypesLoadAction(state, action);
    case BILLTYPES_LOAD_SUCCESS:
      return billTypesLoadSuccessAction(state, action);
    case BILLTYPES_LOAD_FAIL:
      return billTypesLoadFailAction(state, action);
    case CONFIGURATION_LOAD_SUCCESS:
      return configurationLoadSuccessAction(state, action);
    case CONFIGURATION_EBPP_LOAD_SUCCESS:
      return configurationEbppLoadSuccessAction(state, action);
    case CONFIGURATION_LOAD_FAIL:
      return configurationLoadFailAction(state, action);
    case BILLTYPEIMAGE_LOAD:
      return billTypeImageLoadAction(state, action);
    case BILLTYPEIMAGE_LOAD_SUCCESS:
      return billTypeImageLoadSuccessAction(state, action);
    case BILLTYPEIMAGE_LOAD_FAIL:
      return billTypeImageLoadFailAction(state, action);
    default:
      return state;
  }
};
