import { CancelAction, ClearAction } from './Common';
import { ConfigurationActions } from '../actionTypes/Configuration';
import { IPayor } from '../types';

export const PAYOR_LOAD = "redux/payor/LOAD";
export const PAYOR_LOAD_SUCCESS = "redux/payor/LOAD_SUCCESS";
export const PAYOR_LOAD_FAIL = "redux/payor/LOAD_FAIL";
export const PAYOR_UPDATE = "redux/payor/UPDATE";

export type RequestPayorAction = {
    type: typeof PAYOR_LOAD;
}

export type ReceivePayorAction = {
    type: typeof PAYOR_LOAD_SUCCESS;
    data: IPayor;
}

export type FailedPayorAction = {
    type: typeof PAYOR_LOAD_FAIL;
    data: string;
}

export type UpdatePayorAction = {
    type: typeof PAYOR_UPDATE;
    data: IPayor;
}

export type PayorActions = RequestPayorAction | ReceivePayorAction | FailedPayorAction | UpdatePayorAction | CancelAction | ClearAction | ConfigurationActions;