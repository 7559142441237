import React from "react";

import { Image, Navbar } from "react-bootstrap";

export interface HeaderLogoProps {
    imageUrl: string | null;
    linkUrl: string | null;
    name: string;
}

export const HeaderLogo = (props: HeaderLogoProps) => {
    const navBarBrand = (
        <span>
            {props.imageUrl && (
                <span className="logo">
                    <Navbar.Brand>
                        <Image src={props.imageUrl} />
                    </Navbar.Brand>
                </span>
            )}
            {props.name && <Navbar.Brand>{props.name}</Navbar.Brand>}
        </span>
    );
    const header = props.linkUrl ? (
        <a href={props.linkUrl}>{navBarBrand}</a>
    ) : (
        navBarBrand
    );

    return <span>{header}</span>;
};
