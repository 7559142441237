import React from "react";
import { TextInput } from "./TextInput";

export enum EmailInputPropsEnum {
    type,
    pattern,
}
export class EmailInput extends React.Component<any, any> {
    public static defaultProps: Partial<any> = {
        type: "email",
        pattern:
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
    };

    constructor(props: any) {
        super(props);
    }

    render() {
        const { type, pattern } = this.props;
        const props: any = Object.assign({}, this.props);
        Object.keys(this.props).forEach((key) => {
            if (key in EmailInputPropsEnum) {
                delete props[key];
            }
        });
        return (
            <TextInput
                {...props}
                type={type}
                pattern={pattern}
                customError="emailPatternMismatch"
            />
        );
    }
}
