import React from "react";
import * as Resources from "../../resources";
import {
    GetCardImageByPaymentMethod,
    GetCardName,
} from "../../utils/CardTypes";
import PaymentForm from "../Payment/PaymentForm";
import AlertMessage from "../Layout/AlertMessage";
import ButtonBar from "./ButtonBar";
import {
    Panel,
    Row,
    Col,
    Table,
    Button,
    ButtonToolbar,
    Modal,
    Radio,
} from "react-bootstrap";
import { IAccount, IAlertMessage, IPayment, IBillDetail } from "../../types";
import {
    faExclamationTriangle,
    faCheck,
} from "@fortawesome/free-solid-svg-icons";

export class ManageAccountsForm extends React.Component<any, any> {
    constructor(props: any) {
        super(props);

        this.updateMessage = this.updateMessage.bind(this);
        this.showAddAccount = this.showAddAccount.bind(this);
        this.showEditAccount = this.showEditAccount.bind(this);
        this.initializePayment = this.initializePayment.bind(this);
        this.onSaveError = this.onSaveError.bind(this);
        this.onSaveSuccess = this.onSaveSuccess.bind(this);
        this.onDeleteError = this.onDeleteError.bind(this);
        this.onDeleteSuccess = this.onDeleteSuccess.bind(this);
        this.handleBackButton = this.handleBackButton.bind(this);
        this.handleAddAccount = this.handleAddAccount.bind(this);
        this.handleOpenModal = this.handleOpenModal.bind(this);
        this.handleCloseModal = this.handleCloseModal.bind(this);
        this.handleDelete = this.handleDelete.bind(this);
        this.handleEdit = this.handleEdit.bind(this);
        this.onIsDefaultChanged = this.onIsDefaultChanged.bind(this);

        this.state = {
            showAdd: false,
            showModal: false,
            showAutoPayModal: false,
            deleteParam: "",
            message: (props.userAccountsError
                ? { text: props.userAccountsError, isSuccess: false }
                : {}) as IAlertMessage,
        };
    }

    public componentWillMount(): void {
        const { application, settings, user, loadUserAccounts } = this.props;

        if (application.guid && application.expired) {
            const path = `/${settings.merchantName}/expired/${application.guid}`;
            this.props.history.push(path);
            return;
        }

        if (application.guid && application.canceled) {
            const path = `/${settings.merchantName}/canceled/${application.guid}`;
            this.props.history.push(path);
            return;
        }
    }

    public componentDidMount(): void {
        if (this.props.user.isLoggedIn) {
            this.props.loadUserAccounts(
                this.props.application,
                this.props.settings.merchantName,
                this.props.user.authToken,
                (text: string) => {
                    this.updateMessage({
                        text: Resources.NotAuthorized,
                        isSuccess: false,
                    });
                }
            );
        }
    }

    public componentDidUpdate(prevProps: any) {
        if (
            prevProps.application.location !== this.props.application.location
        ) {
            const location = this.props.application.location;
            if (
                location.toLowerCase().includes("add") ||
                location.toLowerCase().includes("edit")
            ) {
                this.showAddAccount(true);
            } else {
                this.showAddAccount(false);
            }
        }
    }

    private handleBackButton(event: React.MouseEvent<Button>): void {
        event.preventDefault();
        const location = this.props.application.location;
        if (
            location.toLowerCase().includes("add") ||
            location.toLowerCase().includes("edit")
        ) {
            const path = `/${this.props.settings.merchantName}/myaccount/accounts`;
            this.props.history.push(path);
        } else {
            this.props.history.goBack();
        }
    }

    private handleAddAccount(event: React.MouseEvent<Button>): void {
        event.preventDefault();
        this.showAddAccount(true);
        this.showEditAccount();
        const path = `/${this.props.settings.merchantName}/myaccount/accounts/add`;
        this.props.history.push(path);
    }

    private handleEdit(param: string, event: React.MouseEvent<Button>): void {
        event.preventDefault();
        this.showAddAccount(true);
        this.showEditAccount(param);
        const path = `/${this.props.settings.merchantName}/myaccount/accounts/edit`;
        this.props.history.push(path);
    }

    private handleOpenModal(param: string): void {
        const { billDetails, userAccounts } = this.props;
        const userAccount: IAccount = userAccounts.find(
            (ua: IAccount) => ua.nickname === param
        );
        const token = userAccount ? userAccount.token : "";
        let showAutoPayModal = false;
        let showModal = true;

        if (billDetails && billDetails.length > 0) {
            const index = billDetails.findIndex(
                (b: IBillDetail) => b.token === token
            );
            showAutoPayModal = index >= 0;
            showModal = !showAutoPayModal;
        }
        this.setState({
            showAutoPayModal: showAutoPayModal,
            showModal: showModal,
            deleteParam: param,
        });
    }

    private handleCloseModal(): void {
        this.setState({
            showAutoPayModal: false,
            showModal: false,
            deleteParam: "",
        });
    }

    private handleDelete(): void {
        const { deleteParam } = this.state;
        const { application, settings, user, deleteAccount } = this.props;

        if (this.state.showModal) {
            deleteAccount(
                application,
                settings.merchantName,
                user,
                deleteParam,
                this.onDeleteError,
                this.onDeleteSuccess
            );
        } else if (this.state.showAutoPayModal) {
            const path = `/${this.props.settings.merchantName}/billdetail`;
            this.props.history.push(path);
        }
        this.setState({
            showAutoPayModal: false,
            submitting: false,
            showModal: false,
            deleteParam: "",
        });
    }

    private onSaveSuccess(token: string): void {
        this.updateMessage({
            text: Resources.MessageSaveAccount,
            isSuccess: true,
        });
        this.setState({ isSubmitting: false });
        this.showAddAccount(false);
    }

    private onSaveError(text: string): void {
        this.updateMessage({ text: text, isSuccess: false });
        this.setState({ isSubmitting: false });
        this.showAddAccount(false);
    }

    private onDeleteSuccess(accountName: string): void {
        this.updateMessage({
            text: Resources.MessageDeleteAccount,
            isSuccess: true,
        });
    }

    private onDeleteError(text: string): void {
        this.updateMessage({ text: text, isSuccess: false });
    }

    private showAddAccount(showAdd?: boolean): void {
        if (showAdd !== this.state.showAdd) {
            if (showAdd && !this.props.myAccount.selected) {
                this.updateMessage();
            } else if (!showAdd && this.props.myAccount.selected) {
                this.showEditAccount();
            }

            this.setState({
                showAdd: showAdd,
            });
        }
    }

    private showEditAccount(selected?: string): void {
        this.props.selectAccount(selected);
    }

    private updateMessage(newmessage?: IAlertMessage): void {
        this.setState({
            message: newmessage,
        });
    }

    private initializePayment(): IPayment {
        const { userAccounts, myAccount } = this.props;
        const account: IAccount = userAccounts.find(
            (s: IAccount) => s.nickname === myAccount.selected
        );
        let payment = { ...this.props.payment, ...account };

        if (account !== undefined) {
            const accountTypeToLower = account.accountType.toLowerCase();

            payment.cardNumber = (account && account.accountNumber) || "";
            payment.method =
                accountTypeToLower !== "ach" ? accountTypeToLower : "checking";
            payment.expirationMonth =
                payment.expirationMonth < 10
                    ? "0" + payment.expirationMonth
                    : payment.expirationMonth.toString();

            if (accountTypeToLower === "ach") {
                payment.accountType =
                    account.achAccountType.toLowerCase() +
                    account.achDepositType;
            }
        } else {
            payment = { ...this.props.payment, ...this.props.user.profile };
            payment.method = "credit";
        }

        payment.phoneCountry = payment.phoneCountry || "US";

        return payment;
    }

    private onIsDefaultChanged(event: any) {
        const {
            application,
            settings: { merchantName },
            userAccounts,
            setAccountAsDefault,
            user: { authToken },
        } = this.props;
        const account = userAccounts.find(
            (x: any) => x.nickname === event.target.value
        );

        if (account) {
            setAccountAsDefault(application, account, merchantName, authToken);
        }
    }

    private buildGrid(): JSX.Element {
        const { userAccounts } = this.props;

        return this.state.showAdd ? (
            <PaymentForm
                application={this.props.application}
                settings={this.props.settings}
                payor={this.props.payor}
                user={this.props.user}
                userAccounts={this.props.userAccounts}
                bills={this.props.bills.bills}
                billDetails={this.props.billDetails}
                billTypes={this.props.billTypes}
                history={this.props.history}
                myAccount={this.props.myAccount}
                cancel={this.props.cancel}
                payment={this.initializePayment()}
                showAddAccount={this.showAddAccount}
                updateMessage={this.updateMessage}
                saveAccount={this.props.saveAccount}
                onSaveAccountError={this.onSaveError}
                onSaveAccountSuccess={this.onSaveSuccess}
            />
        ) : userAccounts.length === 0 ? (
            <h4>{Resources.MessageNoAccounts}</h4>
        ) : (
            <Row>
                <Col className="mngacctsform" xs={1} sm={1} md={1} lg={1}></Col>
                <Col className="mngacctsform" xs={10} sm={10} md={10} lg={10}>
                    <Panel default>
                        <Table
                            className="rtable rtable--flip"
                            condensed
                            bordered
                        >
                            <thead>
                                <tr>
                                    {/* <th>{Resources.LabelAccountNickname}</th> */}
                                    <th>{Resources.LabelAccountType}</th>
                                    <th>{Resources.LabelAccountIsDefault}</th>
                                    <th>{Resources.LabelAccountActions}</th>
                                </tr>
                            </thead>
                            <tbody>
                                {userAccounts.map(
                                    (userAccount: IAccount, index: number) => {
                                        const keyNames =
                                            Object.keys(userAccount);
                                        const last4 =
                                            userAccount.accountNumber.slice(-5);
                                        const cardType = (
                                            <span>
                                                {GetCardImageByPaymentMethod(
                                                    userAccount.paymentMethod
                                                )}
                                                &nbsp;{" "}
                                                <strong>
                                                    {GetCardName(
                                                        userAccount.paymentMethod
                                                    )}
                                                </strong>
                                            </span>
                                        );

                                        return (
                                            <tr key={index}>
                                                {/* <td data-title={keyNames[0]}>
                                                    {userAccount.nickname}
                                                </td> */}
                                                <td data-title={keyNames[1]}>
                                                    {Resources.MessageCardInformation(
                                                        cardType,
                                                        last4
                                                    )}
                                                </td>
                                                <td>
                                                    <Radio
                                                        checked={
                                                            userAccount.isDefault
                                                                ? true
                                                                : false
                                                        }
                                                        name="customerAccountName"
                                                        onChange={
                                                            this
                                                                .onIsDefaultChanged
                                                        }
                                                        value={
                                                            userAccount.nickname
                                                        }
                                                    >
                                                        {userAccount.isDefault
                                                            ? Resources.ButtonYes
                                                            : Resources.ButtonNo}
                                                    </Radio>
                                                </td>
                                                <td>
                                                    <ButtonBar
                                                        parameter={
                                                            userAccount.nickname
                                                        }
                                                        handleDelete={
                                                            this.handleOpenModal
                                                        }
                                                        handleEdit={
                                                            this.handleEdit
                                                        }
                                                        isManageAccountsForm
                                                    />
                                                </td>
                                            </tr>
                                        );
                                    }
                                )}
                            </tbody>
                        </Table>
                    </Panel>
                </Col>
            </Row>
        );
    }

    public render(): JSX.Element {
        const grid = this.buildGrid();
        const { userAccounts } = this.props;
        const { message, showModal, deleteParam } = this.state;
        const bsStyle =
            message === undefined
                ? ""
                : message.isSuccess
                ? "success"
                : "danger";
        const icon =
            message !== undefined && message.isSuccess
                ? faCheck
                : faExclamationTriangle;
        const showAlert = message !== undefined && message.text;
        const account: IAccount = userAccounts.find(
            (ua: IAccount) => ua.nickname === deleteParam
        );
        const lastFour =
            account &&
            account.accountNumber.substr(account.accountNumber.length - 4);
        const cardType = account && (
            <span>
                <strong>{GetCardName(account.paymentMethod)}</strong>
            </span>
        );
        const deleteMessage = Resources.MessageCardInformation(
            cardType,
            lastFour
        );
        return (
            <div>
                <Modal
                    show={this.state.showAutoPayModal}
                    onHide={this.handleCloseModal}
                >
                    <Modal.Header closeButton>
                        <Modal.Title>
                            {Resources.HeaderDeleteAccount}
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>{Resources.MessageAutopayDelete}</Modal.Body>
                    <Modal.Footer>
                        <div className="pull-right">
                            <ButtonToolbar>
                                <Button onClick={this.handleCloseModal}>
                                    {Resources.ButtonNo}
                                </Button>
                                <Button
                                    bsStyle="primary"
                                    onClick={this.handleDelete}
                                    style={{ minWidth: "6.5em" }}
                                >
                                    {Resources.ButtonYes}
                                </Button>
                            </ButtonToolbar>
                        </div>
                    </Modal.Footer>
                </Modal>
                {showModal && (
                    <div>
                        <Modal
                            show={this.state.showModal}
                            onHide={this.handleCloseModal}
                        >
                            <Modal.Header closeButton>
                                <Modal.Title>
                                    {Resources.HeaderDeleteAccount}
                                </Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                {Resources.MessageDeleteAccountDialog(
                                    deleteMessage
                                )}
                            </Modal.Body>
                            <Modal.Footer>
                                <div className="pull-right">
                                    <ButtonToolbar>
                                        <Button onClick={this.handleCloseModal}>
                                            {Resources.ButtonNo}
                                        </Button>
                                        <Button
                                            bsStyle="primary"
                                            onClick={this.handleDelete}
                                            style={{ minWidth: "6.5em" }}
                                        >
                                            {Resources.ButtonYes}
                                        </Button>
                                    </ButtonToolbar>
                                </div>
                            </Modal.Footer>
                        </Modal>
                    </div>
                )}
                <Panel bsStyle="primary">
                    <Panel.Heading>
                        {Resources.HeaderAccountsInformation}
                    </Panel.Heading>
                    <Panel.Body>
                        {showAlert && (
                            <AlertMessage
                                bsStyle={bsStyle}
                                showIcon
                                icon={icon}
                                message={message}
                            />
                        )}
                        <div
                            className="well"
                            style={{ alignContent: "center" }}
                        >
                            <section>{grid}</section>
                        </div>
                        {!this.state.showAdd && (
                            <div className="well">
                                <Row>
                                    <Col xs={12}>
                                        <ButtonToolbar>
                                            <div className="btn-group btn-group-justified-sm-12 pull-right">
                                                {
                                                    <Button
                                                        bsClass="btn btn-default btn-justified"
                                                        onClick={
                                                            this
                                                                .handleBackButton
                                                        }
                                                        style={{
                                                            minWidth: "6.5em",
                                                        }}
                                                    >
                                                        {Resources.ButtonBack}
                                                    </Button>
                                                }
                                                <Button
                                                    bsClass="btn btn-primary btn-justified"
                                                    onClick={
                                                        this.handleAddAccount
                                                    }
                                                    style={{
                                                        minWidth: "6.5em",
                                                    }}
                                                >
                                                    {Resources.ButtonAdd}
                                                </Button>
                                            </div>
                                        </ButtonToolbar>
                                    </Col>
                                </Row>
                            </div>
                        )}
                    </Panel.Body>
                </Panel>
            </div>
        );
    }
}
