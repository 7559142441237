import {
    applyMiddleware,
    createStore,
    Middleware,
    Reducer,
    ReducersMapObject,
} from "redux";
import thunk from "redux-thunk";
import * as history from "history";
import { reducer as toastrReducer } from "react-redux-toastr";
import { createReduxHistoryContext } from "redux-first-history";
import {
    createTransform,
    persistCombineReducers,
    persistStore,
} from "redux-persist";
import autoMergeLevel1 from "redux-persist/es/stateReconciler/autoMergeLevel1";
import storageSession from "redux-persist/lib/storage/session";
import { rootReducer } from "./reducers";
import { generateMaskFromNumber } from "./utils/CardTypes";

function configureTheStore(
    routerReducer: Reducer,
    routerMiddleware: Middleware,
    currentPathParams: { merchant: string; guid: string }
) {
    const middlewares = [];
    middlewares.push(thunk);
    middlewares.push(routerMiddleware);

    if (process.env.NODE_ENV === `development`) {
        // eslint-disable-next-line @typescript-eslint/no-var-requires
        const { logger } = require(`redux-logger`);
        middlewares.push(logger); //redux logger must be last
    }

    const store = createStore(
        buildRootReducer(rootReducer, routerReducer, currentPathParams),
        applyMiddleware(...middlewares)
    );

    return store;
}

function buildRootReducer(
    allReducers: ReducersMapObject,
    routerReducer: Reducer,
    currentPathParams: { merchant: string; guid: string }
) {
    const rootLevelPersistenceConfig =
        createPersistenceConfiguration(currentPathParams);
    return persistCombineReducers(rootLevelPersistenceConfig, {
        ...allReducers,
        router: routerReducer,
        toastr: toastrReducer,
    });
}

function createPersistenceConfiguration(currentPathParams: {
    merchant: string;
    guid: string;
}) {
    const ignoreListForPaymentStateFilter = createTransform(
        (inboundState: any, key) => {
            return {
                ...inboundState,
                ...{
                    cardNumber: "",
                    saved: false,
                    expirationMonth: "",
                    expirationYear: "",
                    cvc: "",
                    routingNumber: "",
                    accountNumber: "",
                    confirmAccountNumber: "",
                },
            };
        },
        (outboundState, key) => {
            return { ...outboundState };
        },
        { whitelist: ["payment"] }
    );

    const ignoreListForReceiptStateFilter = createTransform(
        (inboundState: any, key) => {
            return {
                ...inboundState,
                ...{
                    payment: {
                        ...inboundState.payment,
                        ...{
                            cardNumber: inboundState.payment.cardNumber
                                ? generateMaskFromNumber(
                                      inboundState.payment.cardNumber
                                  )
                                : "",
                            expirationMonth: "",
                            expirationYear: "",
                            cvc: "",
                            routingNumber: "",
                            accountNumber: "",
                            confirmAccountNumber: "",
                        },
                    },
                },
            };
        },
        (outboundState, key) => {
            return { ...outboundState };
        },
        { whitelist: ["receipt"] }
    );
    let rootStorageKet = `root:${currentPathParams.merchant.toLowerCase()}`;
    if (currentPathParams.guid) {
        rootStorageKet += `:${currentPathParams.guid.toLowerCase()}`;
    }

    return {
        key: rootStorageKet,
        storage: storageSession,
        stateReconciler: autoMergeLevel1, //hardSet,
        blacklist: [
            "toastr",
            "ajaxCounter",
            /*to test*/ "billSearch",
            "router",
            "transactions",
        ],
        transforms: [
            ignoreListForPaymentStateFilter,
            ignoreListForReceiptStateFilter,
        ],
    };
}

const configurePersistedStore = (currentPathParams: {
    merchant: string;
    guid: string;
}) => {
    const { createReduxHistory, routerMiddleware, routerReducer } =
        createReduxHistoryContext({
            history: history.createBrowserHistory({
                basename: process.env.PUBLIC_URL,
            }),
        });
    const store = configureTheStore(
        routerReducer,
        routerMiddleware,
        currentPathParams
    );
    const persistor = persistStore(store);
    return { store, persistor, history: createReduxHistory(store) };
};

export default configurePersistedStore;
