import React from "react";
import { Col, Row } from "react-bootstrap";
import * as Resources from "../../resources";
import {
    GetCardImageByPaymentMethod,
    GetCardLast4,
    GetCardName,
} from "../../utils/CardTypes";

class SavedPayment extends React.Component<any, any> {
    constructor(props: any) {
        super(props);
    }

    buildInput(label: any, value: any) {
        return (
            <Row>
                <Col xs={5} sm={4} md={4}>
                    <strong>{label}</strong>
                </Col>
                <Col xs={7} sm={8} md={8}>
                    <span className="uneditable-input">{value}</span>
                </Col>
            </Row>
        );
    }

    render() {
        const { userAccounts, payment } = this.props;
        const userAccount = userAccounts.find(
            (x: any) => x.nickname === payment.customerAccountName
        );
        let message;
        if (userAccount) {
            if (userAccount.paymentMethod !== 7) {
                const cardType = (
                    <span>
                        {GetCardImageByPaymentMethod(userAccount.paymentMethod)}
                        &nbsp;{" "}
                        <strong>
                            {GetCardName(userAccount.paymentMethod)}
                        </strong>
                    </span>
                );
                const last4 = GetCardLast4(userAccount.accountNumber);
                message = this.buildInput(
                    Resources.LabelCardNumber,
                    Resources.MessageCardInformation(cardType, last4)
                );
            } else {
                const cardType = (
                    <span>
                        <strong>
                            {GetCardName(userAccount.paymentMethod)}
                        </strong>
                    </span>
                );
                message = this.buildInput(
                    Resources.LabelAccountNumber,
                    Resources.MessageCardInformation(
                        cardType,
                        userAccount.accountNumber
                    )
                );
            }
        }
        return (
            <div>
                {message}
                <br />
            </div>
        );
    }
}

export default SavedPayment;
