import { IApplication } from "../types";
import { IMaintenance } from "../types/IApplicationMaintenanceState";

export function loadMaintenance(
    merchantName: string,
    application?: IApplication
): Promise<IMaintenance> {
    const baseUrl = process.env.REACT_APP_API_BASEURL;
    const url = `${baseUrl}/webapi/api/Maintenance`;

    return fetch(url, {
        method: "POST",
        mode: baseUrl ? "cors" : "same-origin",
        headers: { "Content-Type": "application/json; charset=utf-8" },
        body: JSON.stringify({
            merchantName: merchantName,
            application: application,
        }),
    })
        .then((response) => response.json() as Promise<IMaintenance>)
        .catch((error) => error);
}
