import React from "react";
import { injectIntl } from "react-intl";
import { CheckFieldValidity } from "../../utils/Validation";
import SelectInput from "./SelectInput";
import StatesList from "./StatesList";

export enum StateInputPropsEnum {
    intl,
    blacklist,
    id,
    name,
    onBlur,
    value,
}
class StateInput extends React.Component<any, any> {
    private stateInput: any;

    constructor(props: any) {
        super(props);
        this.state = {
            options: [],
            blacklistedError: false,
            id: props.id || props.name,
            value: props.value,
        };
        this.validate = this.validate.bind(this);
        this.handleBlur = this.handleBlur.bind(this);
    }

    componentWillMount() {
        const states = StatesList.getStates();
        this.setState({
            options: states,
            blacklistedError: false,
            id: this.props.id || this.props.name,
            value: this.props.value,
        });
    }

    componentDidMount() {
        const { value } = this.state;
        if (value) {
            this.validate(value);
        }
    }

    componentWillReceiveProps(nextProps: any) {
        if (this.state.value !== nextProps.value) {
            this.setState({
                value: nextProps.value,
            });

            this.validate(nextProps.value);
        }
    }

    componentDidUpdate(prevProps: any) {
        if (prevProps.value !== this.state.value) {
            if (this.props.sameAsPayor) {
                CheckFieldValidity(this.stateInput, this.props.onError);
            }
        }
    }

    handleBlur(event: any) {
        this.validate(event.currentTarget.value);
        if (this.props.onBlur) {
            this.props.onBlur(event);
        }
    }

    validate(value: any) {
        const el = this.stateInput;
        const {
            intl: { formatMessage },
        } = this.props;
        if (this.props.blacklist && this.props.blacklist.includes(value)) {
            el.setCustomValidity(
                formatMessage({ id: "form.error.stateNotAllowed" })
            );
            this.setState({ blacklistedError: true });
        } else if (this.state.blacklistedError) {
            el.setCustomValidity("");
            this.setState({ blacklistedError: false });
        }
    }

    render() {
        const { name } = this.props;
        const props: any = Object.assign({}, this.props);
        Object.keys(this.props).forEach((key) => {
            if (key in StateInputPropsEnum) {
                delete props[key];
            }
        });
        const inputRef = (select: any) => (this.stateInput = select);
        return (
            <SelectInput
                {...props}
                name={name}
                inputRef={inputRef}
                onBlur={this.handleBlur}
                options={this.state.options}
                value={this.state.value}
            />
        );
    }
}

export default injectIntl(StateInput);
