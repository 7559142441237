import React from "react";
import { NavLink } from "react-router-dom";

class StepLink extends React.Component<any, any> {
    constructor(props: any) {
        super(props);
        this.handleOnClick = this.handleOnClick.bind(this);
    }

    handleOnClick(e: any) {
        const { disabled } = this.props;
        if (disabled) {
            e.preventDefault();
        }
    }

    render() {
        const { step, label, link, disabled } = this.props;
        return (
            <NavLink to={link} onClick={this.handleOnClick}>
                <span>{step}</span>
                {label}
            </NavLink>
        );
    }
}

export default StepLink;
