export function getTerms(languageId: number): Promise<string> {
    const baseUrl = process.env.REACT_APP_API_BASEURL;

    return fetch(`${baseUrl}/webapi/api/TermsOfUse?language=${languageId}`, {
        mode: baseUrl ? "cors" : "same-origin",
    })
        .then((response) => response.text() as Promise<string>)
        .then((terms: string) =>
            terms
                .replace(/\\r\\n/g, "")
                .replace(/^"|"$/g, "")
                .replace(/\\"/g, "“")
        )
        .catch((error) => error);
}
