import { SanDiegoBillType } from "../components/SanDiegoBillSearch/types";
import { IBill, IBillType, ISelectOption } from "../types";

export function GetDefaultValue(options: string[] | null): string {
    if (!options || options.length === 0) return "";

    const defaultOption = options.find((o) => o.endsWith("*"));

    return (defaultOption || "").slice(0, -1).trim();
}

export function GetBillOptions(billTypes: IBillType[] | null): ISelectOption[] {
    if (!billTypes) return [];

    return billTypes.map((b) => {
        return {
            value: b.id.toString(),
            text: b.displayName,
        };
    });
}

export function GetAccountType(type: string) {
    switch (type) {
        case "personalChecking":
            return "option.personalChecking";
        case "businessChecking":
            return "option.businessChecking";
        case "personalSavings":
            return "option.personalSavings";
        case "businessSavings":
            return "option.businessSavings";
        default:
            return type;
    }
}

export function GetMaskedBillNumber(
    billType: string,
    parcelNumber: string
): string {
    if (parcelNumber && parcelNumber.length === 10) {
        switch (billType) {
            case SanDiegoBillType.unsecured:
                return `${parcelNumber.substring(0, 4)}-${parcelNumber.slice(
                    -6
                )}`;
            default:
                return `${parcelNumber.substring(
                    0,
                    3
                )}-${parcelNumber.substring(3, 6)}-${parcelNumber.substring(
                    6,
                    8
                )}-${parcelNumber.slice(-2)}`;
        }
    }

    return parcelNumber;
}

export function GetMaxPaymentAmount(
    allowOverPayment: boolean,
    maxPaymentAmount: number,
    billAmount: string | number
) {
    if (!allowOverPayment)
        return Math.min(maxPaymentAmount, Number(billAmount));

    return maxPaymentAmount;
}

export function ConfigureBillFromBillType(
    billTypes: IBillType[] | null,
    selectedName?: string
): IBill {
    const bill: IBill = {
        id: "",
        billId: 0,
        billType: "",
        id1: "",
        id2: "",
        id3: "",
        id4: "",
        id1Label: "",
        id2Label: "",
        id3Label: "",
        id4Label: "",
        amount: 0,
        amountDue: null,
        customAmount: null,
        dueDate: "",
        error: "",
        hasImage: false,
        imageContent: "",
        detectedMIMEType: "",
        purchaseOrder: null,
        isTaxExempt: null,
        taxAmount: null,
    };
    let billType;

    if (!billTypes || billTypes.length === 0) return bill;

    if (billTypes.length === 1) {
        billType = billTypes[0];
    } else {
        billType = billTypes.find((b) => b.id.toString() === selectedName);
    }

    if (billType) {
        bill.billType = billType.name;
        bill.id1 = GetDefaultValue(billType.id1.options);
        bill.id2 = GetDefaultValue(billType.id2.options);
        bill.id3 = GetDefaultValue(billType.id3.options);
        bill.id4 = GetDefaultValue(billType.id4.options);
        bill.id1Label = billType.id1.label;
        bill.id2Label = billType.id2.label;
        bill.id3Label = billType.id3.label;
        bill.id4Label = billType.id4.label;
        bill.amount = Number(GetDefaultValue(billType.amount.options));
    }

    return bill;
}
