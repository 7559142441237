import { Reducer } from "redux";
import {
    TERMSOFUSE_LOAD_SUCCESS,
    TERMSOFUSE_LOAD_FAIL,
    ReceiveTermsAction,
    FailedTermsAction,
    TermsActions,
} from "../actionTypes/Terms";

export const initialState = "";

const termsLoadSuccessAction = (
    state: string,
    action: ReceiveTermsAction
): string => {
    return action.data;
};

const termsLoadFailAction = (
    state: string,
    action: FailedTermsAction
): string => {
    return "";
};

export const reducer: Reducer<string> = (
    state: string = initialState,
    action: TermsActions
) => {
    switch (action.type) {
        case TERMSOFUSE_LOAD_SUCCESS:
            return termsLoadSuccessAction(state, action);
        case TERMSOFUSE_LOAD_FAIL:
            return termsLoadFailAction(state, action);
        default:
            return state;
    }
};
