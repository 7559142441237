import { ConfigurationActions } from '../actionTypes/Configuration';
import { IApplication } from '../types';


export const APPLICATION_SET = "redux/application/SET";
export const APPLICATION_LOAD = "redux/application/LOAD";
export const APPLICATION_LOAD_SUCCESS = "redux/application/LOAD_SUCCESS";
export const APPLICATION_LOAD_FAIL = "redux/application/LOAD_FAIL";
export const APPLICATION_SET_LOCATION = "redux/application/SET_LOCATION";
export const APPLICATION_SET_FUTUREDATEDPAYMENT = "redux/application/SET_FUTUREDATEDPAYMENT";
export const APPLICATION_SET_ISPAYMENTCOMPLETED = "redux/application/SET_ISPAYMENTCOMPLETED";

/* export const APPLICATION_CLEAR = 'redux/application/CLEAR';
export const APPLICATION_CANCEL = 'redux/application/CANCEL'; */

//export interface IApplicationSetAction {
//    type: typeof APPLICATION_SET;
//    data: IApplication;
//}

export type ApplicationLoadAction = {
    type: typeof APPLICATION_LOAD;
}

export type ApplicationLoadSuccessAction = {
    type: typeof APPLICATION_LOAD_SUCCESS;
    data: IApplication;
}

export type ApplicationLoadFailedAction = {
    type: typeof APPLICATION_LOAD_FAIL;
    data: string;
}

export type ApplicationSetLocationAction = {
    type: typeof APPLICATION_SET_LOCATION;
    data: string | null;
}

export type SetFutureDatedPaymentAction = {
    type: typeof APPLICATION_SET_FUTUREDATEDPAYMENT;
    data: boolean;
}

export type SetIsPaymentCompletedAction = {
    type: typeof APPLICATION_SET_ISPAYMENTCOMPLETED;
    data: boolean;
}


/* export type ApplicationClearAction = {
    type: typeof APPLICATION_CLEAR;
}

export type ApplicationCancelAction = {
    type: typeof APPLICATION_CANCEL;
} */

export type ApplicationActions = ApplicationLoadAction | ApplicationLoadSuccessAction | 
    ApplicationLoadFailedAction | ConfigurationActions | ApplicationSetLocationAction |
    SetFutureDatedPaymentAction | SetIsPaymentCompletedAction;
