import React from "react";
import { Input } from "./Input";

function escapeRegExp(str: any) {
    return str.replace(/[-[\]/{}()*+?.\\^$|]/g, "\\$&");
}

export enum NumberInputPropsEnum {
    id,
    name,
    type,
    thousandSeparator,
    decimalSeparator,
    format,
    value,
    onChange,
    onKeyDown,
    disableScrollStep,
    disableArrowStep,
}
export class NumberInput extends React.Component<any, any> {
    public static defaultProps: Partial<any> = {
        type: "tel",
        decimalSeparator: ".",
        thousandSeparator: false,
    };

    constructor(props: any) {
        super(props);
        this.state = {
            value: this.formatInput(props.value).formattedValue,
        };
    }

    componentWillMount() {
        const id = this.props.id || this.props.name;
        this.setState({
            id: id,
        });
    }

    componentWillReceiveProps(newProps: any) {
        this.setState({
            value: this.formatInput(newProps.value).formattedValue,
        });
    }

    formatWithPattern(str: any) {
        const { format } = this.props;
        if (!format) return str;
        const hashCount = format.split("#").length - 1;
        let hashIdx = 0;
        let frmtdStr = format;

        for (let i = 0, ln = str.length; i < ln; i++) {
            if (i < hashCount) {
                hashIdx = frmtdStr.indexOf("#");
                frmtdStr = frmtdStr.replace("#", str[i]);
            }
        }
        const lastIdx = frmtdStr.lastIndexOf("#");
        return (
            frmtdStr.substring(0, hashIdx + 1) +
            (lastIdx !== -1
                ? frmtdStr.substring(lastIdx + 1, frmtdStr.length)
                : "")
        );
    }

    getNumberRegex(g?: any) {
        const { decimalSeparator } = this.getSeperators();
        return new RegExp(
            `\\d${
                decimalSeparator ? `|${escapeRegExp(decimalSeparator)}` : ""
            }`,
            g ? "g" : undefined
        );
    }

    formatInput(val: any) {
        const { format } = this.props;
        const { thousandSeparator, decimalSeparator } = this.getSeperators();

        const numRegex = this.getNumberRegex(true);

        if (!val || !(val + "").match(numRegex)) {
            return {
                value: "",
                formattedValue: "",
            };
        }
        const num = ((val + "").match(numRegex) as any).join("");

        let formattedValue = num;

        if (format) {
            if (typeof format === "string") {
                formattedValue = this.formatWithPattern(formattedValue);
            } else if (typeof format === "function") {
                formattedValue = format(formattedValue);
            }
        } else {
            let beforeDecimal = formattedValue;
            let afterDecimal = "";
            const hasDecimals = formattedValue.indexOf(decimalSeparator) !== -1;
            if (decimalSeparator && hasDecimals) {
                const parts = formattedValue.split(decimalSeparator);
                beforeDecimal = parts[0];
                afterDecimal = parts[1];
            }
            if (thousandSeparator) {
                beforeDecimal = beforeDecimal.replace(
                    /(\d)(?=(\d{3})+(?!\d))/g,
                    `$1${thousandSeparator}`
                );
            }
            formattedValue =
                beforeDecimal +
                ((hasDecimals && decimalSeparator) || "") +
                afterDecimal;
        }

        return {
            value: formattedValue.match(numRegex).join(""),
            formattedValue: formattedValue,
        };
    }

    getSeperators() {
        let { thousandSeparator, decimalSeparator } = this.props;
        if (thousandSeparator === true) {
            thousandSeparator = ",";
        }

        if (decimalSeparator && thousandSeparator) {
            decimalSeparator = thousandSeparator === "," ? "." : ",";
        }

        if (decimalSeparator === true) {
            decimalSeparator = ".";
        }

        return {
            decimalSeparator,
            thousandSeparator,
        };
    }

    onWheel = (event: React.WheelEvent<HTMLInputElement>) => {
        if (this.props.disableScrollStep) {
            //event.bubbles = true;
            //event.preventDefault();
            const el = event.currentTarget;
            el.blur();
            setTimeout(() => el.focus(), 100);
        }
    };

    onKeyUpDownEvent = (event: any) => {
        if (this.props.disableArrowStep) {
            const key = event.key;
            switch (key) {
                case "ArrowUp":
                case "ArrowDown":
                    event.preventDefault();
                    break;
                case "ArrowLeft":
                case "ArrowRight":
                default:
                    break;
            }
        }

        if (this.props.onKeyDown) {
            this.props.onKeyDown(event);
        }
    };

    render() {
        const { type, name } = this.props;
        const { id, value } = this.state;
        const props: any = Object.assign({}, this.props);
        Object.keys(this.props).forEach((key) => {
            if (key in NumberInputPropsEnum) {
                delete props[key];
            }
        });
        return (
            <Input
                {...props}
                id={id}
                type={type}
                name={name}
                value={value}
                onChange={this.props.onChange}
                onWheel={this.onWheel}
                onKeyDown={this.onKeyUpDownEvent}
            />
        );
    }
}
