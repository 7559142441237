import { CancelAction, ClearAction } from './Common';
import { ConfigurationActions } from '../actionTypes/Configuration';
import { IBill, IBillImageResponse, IUser } from '../types';
import { USER_UPDATE } from './User';

export const EBPP_BILLS_LOAD = "redux/ebppBills/LOAD";
export const EBPP_BILLS_LOAD_SUCCESS = "redux/ebppBills/LOAD_SUCCESS";
export const EBPP_BILLS_LOAD_FAIL = "redux/ebppBills/LOAD_FAIL";
export const EBPP_BILL_IMAGE_LOAD_SUCCESS = "redux/billimage/LOAD_SUCCESS";
export const EBPP_BILL_IMAGE_LOAD_FAIL = "redux/billimage/LOAD_FAIL";
export const EBPP_BILLS_CREATE = "redux/ebppBills/CREATE";
export const EBPP_BILLS_UPDATE = "redux/ebppBills/UPDATE";
export const EBPP_BILLS_DELETE = "redux/ebppBills/DELETE";
export const EBPP_BILLS_CLEAR = "redux/ebppBills/EBPP_BILLS_CLEAR";

export type RequestEbppBillsAction = {
    type: typeof EBPP_BILLS_LOAD;
}

export type ReceiveEbppBillsAction = {
    type: typeof EBPP_BILLS_LOAD_SUCCESS;
    data: IBill[];
}

export type FailedEbppBillsAction = {
    type: typeof EBPP_BILLS_LOAD_FAIL;
    data: string;
}

export type ClearEbppBillAction = {
    type: typeof EBPP_BILLS_CLEAR;
    data: string;
}

export type EbppBillsActions = RequestEbppBillsAction | ReceiveEbppBillsAction | FailedEbppBillsAction | ClearEbppBillAction | CancelAction | ClearAction |ConfigurationActions;