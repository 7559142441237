import React from "react";
import { Pagination } from "react-bootstrap";

class PaginationBar extends React.Component<any, any> {
    public static defaultProps: Partial<any> = {
        prev: false,
        next: false,
        first: false,
        last: false,
    };

    constructor(props: any) {
        super(props);
        this.firstOnClick = this.firstOnClick.bind(this);
        this.lastOnClick = this.lastOnClick.bind(this);
        this.prevOnClick = this.prevOnClick.bind(this);
        this.nextOnClick = this.nextOnClick.bind(this);
        this.getLastPage = this.getLastPage.bind(this);
        this.getFirstPage = this.getFirstPage.bind(this);
    }
    getFirstPage() {
        const { activePage, totalPages, maxPages } = this.props;
        if (maxPages) {
            let firstPage = activePage - Math.floor(maxPages / 2);
            if (firstPage + maxPages > totalPages) {
                firstPage = totalPages - maxPages + 1;
            }
            if (firstPage > 0) {
                return firstPage;
            }
        }
        return 1;
    }
    getLastPage() {
        const { activePage, totalPages, maxPages } = this.props;
        const firstPage = this.getFirstPage();
        if (maxPages) {
            let lastPage = firstPage + maxPages - 1;
            if (lastPage > totalPages) {
                lastPage = totalPages;
            }
            return lastPage;
        }
        return totalPages;
    }
    firstOnClick(e: any) {
        const { activePage, onSelect } = this.props;
        if (activePage === 1) {
            e.preventDefault();
            return;
        }
        onSelect(1);
    }
    prevOnClick(e: any) {
        const { activePage, totalPages, onSelect } = this.props;
        if (activePage === 1) {
            e.preventDefault();
            return;
        }
        onSelect(activePage - 1);
    }
    nextOnClick(e: any) {
        const { activePage, totalPages, onSelect } = this.props;
        if (activePage === totalPages) {
            e.preventDefault();
            return;
        }
        onSelect(activePage + 1);
    }
    lastOnClick(e: any) {
        const { activePage, totalPages, onSelect } = this.props;
        if (activePage === totalPages) {
            e.preventDefault();
            return;
        }
        onSelect(totalPages);
    }
    render() {
        const {
            activePage,
            totalPages,
            maxPages,
            onSelect,
            first,
            prev,
            next,
            last,
        } = this.props;
        const numbers = [];
        const firstPage = this.getFirstPage();
        const lastPage = this.getLastPage();
        for (let number = firstPage; number <= lastPage; number++) {
            numbers.push(number);
        }
        return (
            <Pagination>
                {first && (
                    <Pagination.First
                        onClick={this.firstOnClick}
                        disabled={activePage === 1}
                    />
                )}
                {prev && (
                    <Pagination.Prev
                        onClick={this.prevOnClick}
                        disabled={activePage === 1}
                    />
                )}
                {numbers.map((number, index) => {
                    const handleOnClick = function (event: any) {
                        onSelect(number);
                    };
                    return (
                        <Pagination.Item
                            key={index}
                            active={number === activePage}
                            onClick={handleOnClick}
                        >
                            {number}
                        </Pagination.Item>
                    );
                })}
                {next && (
                    <Pagination.Next
                        onClick={this.nextOnClick}
                        disabled={activePage === totalPages}
                    />
                )}
                {last && (
                    <Pagination.Last
                        onClick={this.lastOnClick}
                        disabled={activePage === totalPages}
                    />
                )}
            </Pagination>
        );
    }
}

export default PaginationBar;
