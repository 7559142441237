import React from "react";
import { connect } from "react-redux";
import { RouteComponentProps } from "react-router-dom";
import { loadUserAccounts } from "../../actions/Account";
import {
    createCustomerBillDetail,
    deleteCustomerBillDetail,
    loadAutoPayTerms,
    loadCustomerBillDetails,
    resetAutoPayTerms,
    setCustomerAutoPay,
    setCustomerBillNotification,
    updateAcceptedAutoPayTerms,
    updateCaptchaAutoPayTerms,
    updateCustomerBillDetail,
} from "../../actions/BillDetails";
import { cancel } from "../../actions/Common";
import {
    IAccount,
    IApplication,
    IApplicationRoute,
    IApplicationState,
    IBill,
    IBillDetail,
    IBillType,
    IInternationalization,
    IMyAccount,
    IPayment,
    IPaymentTerms,
    IPayor,
    ISettings,
    IUser,
} from "../../types";
import { totalBillAmount } from "../../utils/BollettaMath";
import { BillDetailPanel } from "./BillDetailPanel";

interface BillDetailStateProps {
    application: IApplication;
    bills: IBill[];
    billTypes: IBillType[];
    billDetails: IBillDetail[];
    autoPayTerms: IPaymentTerms;
    intl: IInternationalization;
    myAccount: IMyAccount;
    payor: IPayor;
    payment: IPayment;
    paymentTerms: IPaymentTerms;
    settings: ISettings;
    user: IUser;
    userAccounts: any;
}

interface BillDetailDispatchProps {
    loadAutoPayTerms: (
        application: IApplication,
        merchantName: string,
        templateId: number,
        methodId: number,
        languageId: number
    ) => void;
    loadCustomerBillDetails: (
        merchantName: string,
        authToken: string,
        merchantCustomerId: string
    ) => void;
    createCustomerBillDetail: (billDetails: IBillDetail[]) => void;
    deleteCustomerBillDetail: (billDetails: IBillDetail[]) => void;
    updateCustomerBillDetail: (billDetail: IBillDetail) => void;
    updateCaptchaAutoPayTerms: (value: string) => void;
    updateAcceptedAutoPayTerms: (value: boolean) => void;
    setCustomerBillNotification: (
        application: IApplication,
        merchantName: string,
        billDetails: IBillDetail[],
        merchantCustomerId: string,
        onError: (text: string) => void,
        onSuccess: (id1: string) => void
    ) => void;
    setCustomerAutoPay: (
        application: IApplication,
        merchantName: string,
        billDetails: IBillDetail[],
        user: IUser,
        account: IAccount,
        onError: (text: string) => void,
        onSuccess: (id1: string) => void
    ) => void;
    resetAutoPayTerms: VoidFunction;
    loadUserAccounts: (
        application: IApplication,
        merchantName: string,
        authToken: string
    ) => void;
    cancel: VoidFunction;
}

export type BillDetailProps = BillDetailStateProps &
    BillDetailDispatchProps &
    RouteComponentProps<IApplicationRoute>;

class BillDetailPage extends React.Component<BillDetailProps> {
    public componentDidMount() {
        if (!this.props.user.isLoggedIn) {
            const path = this.props.application.guid
                ? `/${this.props.settings.merchantName}/bills/${this.props.application.guid}`
                : `/${this.props.settings.merchantName}/bills`;
            this.props.history.push(path);
            return;
        }
    }

    render() {
        return (
            <div className="margin-top-20">
                <BillDetailPanel
                    application={this.props.application}
                    autoPayTerms={this.props.autoPayTerms}
                    billDetails={this.props.billDetails}
                    settings={this.props.settings}
                    intl={this.props.intl}
                    myAccount={this.props.myAccount}
                    payment={this.props.payment}
                    payor={this.props.payor}
                    user={this.props.user}
                    bills={this.props.bills}
                    billTotal={totalBillAmount(this.props.bills) || 0}
                    billTypes={this.props.billTypes}
                    loadAutoPayTerms={this.props.loadAutoPayTerms}
                    loadUserAccounts={this.props.loadUserAccounts}
                    userAccounts={this.props.userAccounts}
                    setAutoPay={this.props.setCustomerAutoPay}
                    setBillNotification={this.props.setCustomerBillNotification}
                    loadBillDetails={this.props.loadCustomerBillDetails}
                    createBillDetail={this.props.createCustomerBillDetail}
                    deleteBillDetail={this.props.deleteCustomerBillDetail}
                    updateBillDetail={this.props.updateCustomerBillDetail}
                    updateAccepted={this.props.updateAcceptedAutoPayTerms}
                    updateCaptcha={this.props.updateCaptchaAutoPayTerms}
                    resetAutoPayTerms={this.props.resetAutoPayTerms}
                    cancel={this.props.cancel}
                    history={this.props.history}
                />
            </div>
        );
    }
}

export default connect(
    (state: IApplicationState) =>
        ({
            application: state.application,
            intl: state.intl,
            settings: state.settings,
            bills: state.bills.bills,
            billTypes: state.billTypes.billTypes,
            billDetails: state.billDetails.customerBillDetails,
            autoPayTerms: state.billDetails.autoPayTerms,
            myAccount: state.myAccount,
            payor: state.payor,
            payment: state.payment,
            paymentTerms: state.paymentTerms,
            userAccounts: state.userAccounts.accounts,
            user: state.user,
        } as BillDetailStateProps),
    {
        loadAutoPayTerms,
        createCustomerBillDetail,
        deleteCustomerBillDetail,
        updateCustomerBillDetail,
        loadCustomerBillDetails,
        updateCaptchaAutoPayTerms,
        updateAcceptedAutoPayTerms,
        setCustomerBillNotification,
        setCustomerAutoPay,
        resetAutoPayTerms,
        loadUserAccounts,
        cancel,
    }
)(BillDetailPage);
