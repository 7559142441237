import React from "react";
import { connect } from "react-redux";
import { RouteComponentProps } from "react-router-dom";
import {
    createOrUpdateAccount,
    deleteAccount,
    loadUserAccounts,
    setAccountAsDefault,
} from "../../actions/Account";
import { selectAccount } from "../../actions/MyAccount";
import {
    IAccount,
    IApplication,
    IApplicationRoute,
    IApplicationState,
    IMyAccount,
    IPayment,
    IPayor,
    ISettings,
    IUser,
} from "../../types";
import { ManageAccountsForm } from "./ManageAccountsForm";

interface AccountsStateProps {
    application: IApplication;
    settings: ISettings;
    payment: IPayment;
    payor: IPayor;
    user: IUser;
    userAccounts: IAccount[];
    userAccountsError: string;
    myAccount: IMyAccount;
}

interface AccountsDispatchProps {
    saveAccount: typeof createOrUpdateAccount;
    selectAccount: typeof selectAccount;
    loadUserAccounts: typeof loadUserAccounts;
    deleteAccount: typeof deleteAccount;
    setAccountAsDefault: typeof setAccountAsDefault;
}

export type AccountsProps = AccountsStateProps &
    AccountsDispatchProps &
    RouteComponentProps<IApplicationRoute>;

class ManageAccounts extends React.Component<AccountsProps> {
    constructor(props: any) {
        super(props);
    }
    render() {
        return (
            <div className="margin-top-20">
                <ManageAccountsForm {...this.props} />
            </div>
        );
    }
}

export default connect(
    (state: IApplicationState) => ({
        application: state.application,
        settings: state.settings,
        payment: state.payment,
        payor: state.payor,
        user: state.user,
        userAccounts: state.userAccounts.accounts,
        userAccountsError: state.userAccounts.error,
        billDetails: state.billDetails.customerBillDetails,
        bills: state.bills.bills,
        billTypes: state.billTypes,
        myAccount: state.myAccount,
    }),
    {
        saveAccount: createOrUpdateAccount,
        selectAccount,
        loadUserAccounts,
        deleteAccount,
        setAccountAsDefault,
    }
)(ManageAccounts);
