import React from "react";

import {
    faFileInvoiceDollar,
    faFilePdf,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import b64toBlob from "b64-to-blob";
import { Button, Modal } from "react-bootstrap";
import { ButtonClose } from "../../resources";
import { BackgroundImage } from "../Input";

export interface SampleBillProps {
    title: string | JSX.Element;
    imageContent: string | null;
    detectedMIMEType?: string | null;
    onOpenSampleImage?: () => void;
}

export interface SampleBillState {
    showModal: boolean;
}

export class SampleBill extends React.Component<
    SampleBillProps,
    SampleBillState
> {
    constructor(props: SampleBillProps) {
        super(props);

        this.state = {
            showModal: false,
        };

        this.onCloseModal = this.onCloseModal.bind(this);
        this.onOpenModal = this.onOpenModal.bind(this);
    }

    public componentDidUpdate(prevProps: SampleBillProps) {
        if (
            this.props.imageContent &&
            this.props.detectedMIMEType === "application/pdf" &&
            this.state.showModal
        ) {
            const blob = b64toBlob(this.props.imageContent, "application/pdf");
            if (
                window.navigator &&
                (window.navigator as any).msSaveOrOpenBlob
            ) {
                (window.navigator as any).msSaveOrOpenBlob(blob);
            } else {
                const objectUrl = URL.createObjectURL(blob);
                window.open(objectUrl, "_blank");
            }
            this.setState({
                showModal: false,
            });
        }
    }

    private onCloseModal(): void {
        this.setState({ showModal: false });
    }

    private onOpenModal(): void {
        this.setState({ showModal: true });

        if (this.props.onOpenSampleImage) {
            this.props.onOpenSampleImage();
        }
    }

    public render(): JSX.Element {
        const icon =
            this.props.detectedMIMEType !== "application/pdf"
                ? faFileInvoiceDollar
                : faFilePdf;
        return (
            <span>
                <Button
                    bsStyle="primary"
                    bsSize="xsmall"
                    onClick={this.onOpenModal}
                >
                    <FontAwesomeIcon icon={icon} />
                </Button>
                <Modal show={this.state.showModal} onHide={this.onCloseModal}>
                    <Modal.Header closeButton>
                        <Modal.Title>{this.props.title}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        {this.props.imageContent && (
                            <BackgroundImage
                                scale={3}
                                imageContent={this.props.imageContent}
                            />
                        )}
                    </Modal.Body>
                    <Modal.Footer>
                        <Button onClick={this.onCloseModal}>
                            {ButtonClose}
                        </Button>
                    </Modal.Footer>
                </Modal>
            </span>
        );
    }
}
