import React from "react";
import {
    FormGroup,
    ControlLabel,
    FormControl,
    InputGroup,
} from "react-bootstrap";
import InputMask from "react-input-mask";
import { CheckFieldValidity } from "../../utils/Validation";

export enum InputPropsEnum {
    hideSelectOne,
    autoFocused,
    intl,
    id,
    name,
    label,
    value,
    format,
    options,
    required,
    error,
    readOnly,
    disabled,
    minLength,
    maxLength,
    pattern,
    matchValue,
    onBlur,
    onChange,
    onKeyDown,
    onErrorcustomError,
    customError,
    labelIcon,
    addonBefore,
    addonAfter,
    sameAsPayor,
    autocomplete,
}
export class Input extends React.Component<any, any> {
    public static defaultProps: Partial<any> = {
        type: "text",
        readOnly: false,
        maxLength: 100,
    };

    constructor(props: any) {
        super(props);
    }

    componentWillMount() {
        const id = this.props.id || this.props.name;
        this.setState({
            id: id,
            minLengthError: false,
            maxLengthError: false,
            regexError: false,
            valueMissingError: false,
        });
    }

    componentDidMount() {
        const { id } = this.state;
        const { value } = this.props;
        if (value) {
            this.validate(value);
            const el = document.getElementById(id);
            CheckFieldValidity(el, this.handleError);
        }
    }

    validate = (value: any) => {
        const { id } = this.state;
        const { pattern, customError, minLength, maxLength, required } =
            this.props;
        const el: any = document.getElementById(id);
        if (value && minLength && value.length < minLength) {
            el.setCustomValidity("form.error.patternMismatch");
            this.setState({ minLengthError: true });
        } else if (this.state.minLengthError) {
            el.setCustomValidity("");
            this.setState({ minLengthError: false });
        }
        if (value && maxLength && value.length > maxLength) {
            el.setCustomValidity("form.error.patternMismatch");
            this.setState({ maxLengthError: true });
        } else if (this.state.maxLengthError) {
            el.setCustomValidity("");
            this.setState({ maxLengthError: false });
        }
        if (value && pattern) {
            const regExp = new RegExp(pattern);
            if (!regExp.test(value)) {
                if (customError) {
                    el.setCustomValidity(customError);
                } else {
                    el.setCustomValidity("form.error.patternMismatch");
                }
                this.setState({ regexError: true });
            } else if (this.state.regexError) {
                el.setCustomValidity("");
                this.setState({ regexError: false });
            }
        } else if (this.state.regexError) {
            el.setCustomValidity("");
            this.setState({ regexError: false });
        }
        if (required && !value.toString().trim()) {
            this.setState({ valueMissingError: true });
            el.setCustomValidity("form.error.valueMissing");
        } else if (this.state.valueMissingError) {
            el.setCustomValidity("");
            this.setState({ valueMissingError: false });
        }
    };

    handleBlur = (event: any) => {
        this.validate(event.target.value);
        CheckFieldValidity(event.target, this.handleError);
        if (this.props.onBlur) {
            this.props.onBlur(event);
        }
    };

    handleError = (name: any, message: any) => {
        if (this.props.onError) {
            this.props.onError(name, message);
        }
    };

    handleChange = (event: any) => {
        const val = event.target.value;
        if (val) {
            // eslint-disable-next-line no-control-regex
            event.target.value = val.replace(/[^\x00-\xFF]/g, "");
        }
        if (
            !event.target.validity.valid &&
            !event.target.validity.typeMismatch
        ) {
            this.validate(event.target.value);
            CheckFieldValidity(event.target, this.handleError);
        }
        if (this.props.onChange) {
            this.props.onChange(event);
        }
    };

    handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
        if (this.props.onKeyDown) {
            this.validate(event.currentTarget.value);
            this.props.onKeyDown(event);
        }
    };

    render() {
        const { id } = this.state;
        const {
            label,
            name,
            value,
            maxLength,
            options,
            required,
            error,
            labelIcon,
            addonBefore,
            addonAfter,
            feedback,
            format,
            disabled,
            readOnly,
            autocomplete,
        } = this.props;

        const isDisabled = disabled || readOnly;
        let selectOptions = null;
        if (options) {
            selectOptions = options.map((option: any, index: any) => {
                let key =
                    option !== null && typeof option === "object"
                        ? option.value
                        : option;
                let text =
                    option !== null && typeof option === "object"
                        ? option.text
                        : option;
                if (key.endsWith("*")) {
                    key = key.slice(0, -1).trim();
                }
                if (text.endsWith("*")) {
                    text = text.slice(0, -1).trim();
                }
                return (
                    <option
                        key={index}
                        value={key}
                        disabled={key === "" && required}
                    >
                        {text}
                    </option>
                );
            });
        }

        const autocompleteString = autocomplete === false ? "off" : "on";

        const props: any = Object.assign({}, this.props);
        Object.keys(this.props).forEach((key) => {
            if (key in InputPropsEnum) {
                delete props[key];
            }
        });
        if ("isRequiredForSearch" in props) {
            delete props.isRequiredForSearch;
        }
        if ("isSearchOnly" in props) {
            delete props.isSearchOnly;
        }
        if ("isSearchable" in props) {
            delete props.isSearchable;
        }
        const hasError = error ? true : false;
        const hasAddon = addonBefore || addonAfter;
        const validationState = error ? "error" : null;

        if (props.type === "number") {
            props.step = "0.01";
        }

        let inputElement = null;
        if (format) {
            inputElement = (
                <InputMask
                    {...props}
                    id={id}
                    name={name}
                    className="form-control"
                    mask={format}
                    maskChar=""
                    alwaysShowMask={false}
                    value={value}
                    onBlur={this.handleBlur}
                    onChange={this.handleChange}
                    onKeyDown={this.handleKeyDown}
                    required={required}
                    readOnly={readOnly}
                    disabled={isDisabled}
                    aria-required={required}
                    aria-invalid={hasError}
                    autoComplete={autocompleteString}
                />
            );
        } else {
            inputElement = (
                <FormControl
                    {...props}
                    id={id}
                    name={name}
                    value={value || ""}
                    maxLength={maxLength}
                    onBlur={this.handleBlur}
                    onChange={this.handleChange}
                    onKeyDown={this.handleKeyDown}
                    required={required}
                    readOnly={readOnly}
                    disabled={isDisabled}
                    aria-required={required}
                    aria-invalid={hasError}
                    autoComplete={autocompleteString}
                >
                    {selectOptions}
                </FormControl>
            );
        }
        return (
            <FormGroup controlId={id} validationState={validationState}>
                {label && <ControlLabel>{label}</ControlLabel>}
                {label && required && <span className="text-danger"> * </span>}
                {labelIcon && <span className="pull-right">{labelIcon}</span>}
                {hasAddon ? (
                    <InputGroup>
                        {addonBefore}
                        {inputElement}
                        {addonAfter}
                    </InputGroup>
                ) : (
                    <div>{inputElement}</div>
                )}
                {feedback && (
                    <FormControl.Feedback>{feedback}</FormControl.Feedback>
                )}
                {error}
            </FormGroup>
        );
    }
}
