import { getEBPPBills } from "../api/Bill";

import {
  EbppBillsActions,
  EBPP_BILLS_CLEAR,
  EBPP_BILLS_LOAD,
  EBPP_BILLS_LOAD_FAIL,
  EBPP_BILLS_LOAD_SUCCESS,
} from "../actionTypes/EbppBills";
import { IApplication, IAppThunkAction } from "../types";

export const searchEBPP =
  (
    merchantName: string,
    guid: string,
    application: IApplication,
    merchantCustomerId: string,
    authToken: string
  ): IAppThunkAction<EbppBillsActions> =>
  (dispatch) => {
    getEBPPBills(merchantName, guid, application, merchantCustomerId, authToken)
      .then((response) =>
        dispatch({ type: EBPP_BILLS_LOAD_SUCCESS, data: response.bills })
      )
      .catch((error) => dispatch({ type: EBPP_BILLS_LOAD_FAIL, data: error }));

    dispatch({ type: EBPP_BILLS_LOAD });
  };

export const clearEbppBills =
  (): IAppThunkAction<EbppBillsActions> => (dispatch) => {
    dispatch({
      type: EBPP_BILLS_CLEAR,
      data: "",
    });
  };
