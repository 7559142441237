import React from "react";
import { injectIntl } from "react-intl";
import SelectInput from "./SelectInput";

export enum MonthSelectInputPropsEnum {
    intl,
}
class MonthSelectInput extends React.Component<any, any> {
    constructor(props: any) {
        super(props);
        this.state = {
            options: [],
        };
    }

    componentWillMount() {
        const {
            intl: { formatMessage },
        } = this.props;
        const months = [
            {
                value: "01",
                text: `01 - ${formatMessage({ id: "option.january" })}`,
            },
            {
                value: "02",
                text: `02 - ${formatMessage({ id: "option.february" })}`,
            },
            {
                value: "03",
                text: `03 - ${formatMessage({ id: "option.march" })}`,
            },
            {
                value: "04",
                text: `04 - ${formatMessage({ id: "option.april" })}`,
            },
            {
                value: "05",
                text: `05 - ${formatMessage({ id: "option.may" })}`,
            },
            {
                value: "06",
                text: `06 - ${formatMessage({ id: "option.june" })}`,
            },
            {
                value: "07",
                text: `07 - ${formatMessage({ id: "option.july" })}`,
            },
            {
                value: "08",
                text: `08 - ${formatMessage({ id: "option.august" })}`,
            },
            {
                value: "09",
                text: `09 - ${formatMessage({ id: "option.september" })}`,
            },
            {
                value: "10",
                text: `10 - ${formatMessage({ id: "option.october" })}`,
            },
            {
                value: "11",
                text: `11 - ${formatMessage({ id: "option.november" })}`,
            },
            {
                value: "12",
                text: `12 - ${formatMessage({ id: "option.december" })}`,
            },
        ];
        this.setState({
            options: months,
        });
    }

    render() {
        const props: any = Object.assign({}, this.props);
        Object.keys(this.props).forEach((key) => {
            if (key in MonthSelectInputPropsEnum) {
                delete props[key];
            }
        });
        return <SelectInput {...props} options={this.state.options} />;
    }
}

export default injectIntl(MonthSelectInput);
