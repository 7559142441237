import React from "react";
import {
    BillSearchCartBtnRemove,
    BillSearchCartBtnRemoveMobile,
    BillSearchResultCellInstl1,
    BillSearchResultCellInstl1Mobile,
    BillSearchResultCellInstl2,
    BillSearchResultCellInstl2Mobile,
} from "../../../../resources/index";
import { FormattedNumber } from "react-intl";
import { Button } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import "../../../../css/SanDiegoBillSearch/shoppingCart.css";
import { BillInstallmentType } from "../../types";
import { GetMaskedBillNumber } from "../../../../utils/BillHelpers";

interface IOwnProps {
    billId: string;
    amount: number;
    paymentType: string;
    billType: string;
    billNumber: string;
    deleteBill: (billId: string) => void;
}

export const CartTableRow = (props: IOwnProps) => {
    const { billType, billNumber, amount, paymentType, billId, deleteBill } =
        props;

    const onClick = (e: React.MouseEvent<Button>) => {
        deleteBill && deleteBill(billId);
    };

    const paymentTypeLabel =
        paymentType === BillInstallmentType.first
            ? BillSearchResultCellInstl1
            : paymentType === BillInstallmentType.second
            ? BillSearchResultCellInstl2
            : paymentType;

    return (
        <tr className="cart-table-row">
            <td className="gridRowText">
                {GetMaskedBillNumber(billType, billNumber)}
            </td>
            <td className="gridRowText">{billType}</td>
            <td className="gridRowText hidden-xs">{paymentTypeLabel}</td>
            <td className="gridRowText visible-xs">
                {paymentTypeLabel == BillSearchResultCellInstl1
                    ? BillSearchResultCellInstl1Mobile
                    : BillSearchResultCellInstl2Mobile}
            </td>
            <td className="gridRowNumeric">
                <FormattedNumber
                    value={amount}
                    style="currency"
                    currency="USD"
                />
            </td>
            <td className="gridRowText">
                <Button
                    onClick={onClick}
                    bsClass="btn btn-danger btn-xs btn-remove-mobile"
                >
                    <FontAwesomeIcon icon={faTimes} className="fa-icon" />
                    <span
                        aria-hidden="true"
                        className="hidden-xs inline-block align-top"
                    >
                        {BillSearchCartBtnRemove}
                    </span>
                    <span
                        aria-hidden="true"
                        className="visible-xs inline-block align-top"
                    >
                        {BillSearchCartBtnRemoveMobile}
                    </span>
                </Button>
            </td>
        </tr>
    );
};
