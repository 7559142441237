import { OktaErrorCode, RecaptchaErrorCode } from "../core/constants";
import { IntlError } from "../core/exceptions";
import {
    DEVICE_ID,
    IApplication,
    IPayment,
    IPayor,
    IProfile,
    IResponse,
    IToken,
    IUser,
} from "../types";
import { IMfaExceptionResponse } from "../types/IMfaExceptionResponse";
import { IFactorTotp, IMfaResponse } from "../types/IUser";
import CookieFactory from "../utils/CookieFactory";

export function loginUser(
    username: string,
    password: string,
    recaptcha: string
): Promise<IUser> {
    const baseUrl = process.env.REACT_APP_API_BASEURL;

    return fetch(
        `${baseUrl}/webapi/api/Account?username=${username}&password=${password}&recaptcha=${recaptcha}`,
        {
            mode: baseUrl ? "cors" : "same-origin",
        }
    )
        .then((response) => response.json() as Promise<IUser>)
        .catch((error) => error);
}

export const login = (
    application: IApplication,
    merchantName: string,
    username: string,
    password: string,
    recaptcha?: string | null
): Promise<IUser> => {
    const baseUrl = process.env.REACT_APP_API_BASEURL;
    const deviceId = new CookieFactory().getValue(DEVICE_ID);

    return fetch(
        `${baseUrl}/webapi/api/Account/Login?merchantName=${merchantName}`,
        {
            method: "POST",
            mode: baseUrl ? "cors" : "same-origin",
            headers: { "Content-Type": "application/json; charset=utf-8" },
            body: JSON.stringify({
                application,
                username,
                password,
                recaptcha,
                deviceId,
            }),
        }
    ).then((response) => response.json() as Promise<IUser>);
};

export function register(
    application: IApplication,
    merchantName: string,
    userName: string,
    password: string,
    firstName: string,
    lastName: string,
    recaptcha?: string | null
): Promise<IUser> {
    const baseUrl = process.env.REACT_APP_API_BASEURL;

    return fetch(
        `${baseUrl}/webapi/api/Account/Register?merchantName=${merchantName}`,
        {
            method: "POST",
            mode: baseUrl ? "cors" : "same-origin",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({
                application,
                emailAddress: userName,
                password: password,
                firstname: firstName,
                lastname: lastName,
                recaptcha,
            }),
        }
    ).then((response) => response.json() as Promise<IUser>);
}

export const enrollSmsFactor = (
    userId: string,
    phone: string,
    authToken: string
): Promise<IMfaResponse> => {
    const baseUrl = process.env.REACT_APP_API_BASEURL;

    return fetch(`${baseUrl}/webapi/api/factor/enrollsms`, {
        method: "POST",
        mode: baseUrl ? "cors" : "same-origin",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
            authToken,
            userId,
            factorType: "sms",
            enrollData: {
                phone,
            },
        }),
    }).then((response: Response) => {
        if (response.ok) {
            return response.json() as Promise<IMfaResponse>;
        }

        return (response.json() as Promise<IMfaExceptionResponse>).then(
            (result) => {
                if (result.exceptionMessage === "Too Many Requests 429") {
                    throw new Error(
                        "We apologize for the inconvenience but you must wait at least 30 seconds and try again."
                    );
                }

                throw new Error(
                    "A problem occurred sending the challenge code to your selected authentication factor, please try again."
                );
            }
        );
    });
};

export const enrollTotpOktaFactor = (
    userId: string
): Promise<IMfaResponse<IFactorTotp>> => {
    const baseUrl = process.env.REACT_APP_API_BASEURL;

    return fetch(`${baseUrl}/webapi/api/factor/enrolltotp`, {
        method: "POST",
        mode: baseUrl ? "cors" : "same-origin",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
            userId,
            factorType: "totpokta",
        }),
    }).then((response: Response) => {
        if (response.ok) {
            return response.json() as Promise<IMfaResponse<IFactorTotp>>;
        }

        return (response.json() as Promise<IMfaExceptionResponse>).then(
            (result) => {
                if (result.exceptionMessage === "Too Many Requests 429") {
                    throw new Error(
                        "We apologize for the inconvenience but you must wait at least 30 seconds and try again."
                    );
                }

                throw new Error(
                    "A problem occurred sending the challenge code to your selected authentication factor, please try again."
                );
            }
        );
    });
};

export const enrollCallFactor = (
    userId: string,
    phone: string,
    extension: string,
    regionCode: string
): Promise<IMfaResponse> => {
    const baseUrl = process.env.REACT_APP_API_BASEURL;

    return fetch(`${baseUrl}/webapi/api/factor/enrollcall`, {
        method: "POST",
        mode: baseUrl ? "cors" : "same-origin",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
            userId,
            factorType: "call",
            enrollData: {
                phone,
                extension,
                regionCode,
            },
        }),
    }).then((response: Response) => {
        if (response.ok) {
            return response.json() as Promise<IMfaResponse>;
        }

        return (response.json() as Promise<IMfaExceptionResponse>).then(
            (result) => {
                if (result.exceptionMessage === "Too Many Requests 429") {
                    throw new Error(
                        "We apologize for the inconvenience but you must wait at least 30 seconds and try again."
                    );
                }

                throw new Error(
                    "A problem occurred sending the challenge code to your selected authentication factor, please try again."
                );
            }
        );
    });
};

export const unenrollFactor = (
    userId: string,
    factorId: string
): Promise<IMfaResponse> => {
    const baseUrl = process.env.REACT_APP_API_BASEURL;

    return fetch(`${baseUrl}/webapi/api/factor/unenroll`, {
        method: "POST",
        mode: baseUrl ? "cors" : "same-origin",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
            factorId,
            userId,
        }),
    }).then((response: Response) => {
        if (response.ok) {
            return response.json() as Promise<IMfaResponse>;
        }

        return (response.json() as Promise<IMfaExceptionResponse>).then(
            (result) => {
                if (result.exceptionMessage === "Too Many Requests 429") {
                    throw new Error(
                        "We apologize for the inconvenience but you must wait at least 30 seconds and try again."
                    );
                }

                throw new Error(
                    "A problem occurred unenrolling from the selected authentication factor, please try again."
                );
            }
        );
    });
};

export const activateFactor = (
    application: IApplication,
    authToken: string,
    merchantName: string,
    factorId: string,
    userId: string,
    code: string,
    rememberDevice: boolean
): Promise<IMfaResponse> => {
    const baseUrl = process.env.REACT_APP_API_BASEURL;

    return fetch(`${baseUrl}/webapi/api/factor/activate`, {
        method: "POST",
        mode: baseUrl ? "cors" : "same-origin",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
            application,
            authToken,
            id: factorId,
            merchantName,
            userId,
            passCode: code,
            deviceId: rememberDevice
                ? new CookieFactory().getValue(DEVICE_ID)
                : null,
        }),
    }).then((response: Response) => {
        if (response.ok) {
            return response.json() as Promise<IMfaResponse>;
        }

        return (response.json() as Promise<IMfaExceptionResponse>).then(
            (result) => {
                if (result.exceptionMessage === "Too Many Requests 429") {
                    throw new Error(
                        "We apologize for the inconvenience but you must wait 30 seconds between authentication challenges."
                    );
                }

                if (result.exceptionMessage === "Forbidden Forbidden") {
                    throw new Error(
                        "Your code may have been incorrect, please try again."
                    );
                }

                throw new Error(
                    "A problem occurred activating the authentication factor, please try again."
                );
            }
        );
    });
};

export const challenge = (
    factorId: string,
    userId: string,
    language: string
): Promise<IMfaResponse> => {
    const baseUrl = process.env.REACT_APP_API_BASEURL;

    return fetch(`${baseUrl}/webapi/api/factor/challenge`, {
        method: "POST",
        mode: baseUrl ? "cors" : "same-origin",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
            id: factorId,
            userId,
            language,
        }),
    }).then((response: Response) => {
        if (response.ok) {
            return response.json() as Promise<IMfaResponse>;
        }

        return (response.json() as Promise<IMfaExceptionResponse>).then(
            (result) => {
                if (result.exceptionMessage === "Too Many Requests 429") {
                    throw new Error(
                        "We apologize for the inconvenience but you must wait 30 seconds between authentication challenges."
                    );
                }

                throw new Error(
                    "A problem occurred sending the challenge code to your selected authentication factor, please try again."
                );
            }
        );
    });
};

export const verifyFactor = (
    application: IApplication,
    factorId: string,
    merchantName: string,
    userId: string,
    code: string,
    rememberDevice: boolean,
    locale: string,
    authToken: string,
    recaptcha?: string | null
): Promise<IMfaResponse> => {
    const baseUrl = process.env.REACT_APP_API_BASEURL;

    return fetch(`${baseUrl}/webapi/api/factor/verify`, {
        method: "POST",
        mode: baseUrl ? "cors" : "same-origin",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
            application,
            authToken,
            id: factorId,
            merchantName,
            userId,
            passCode: code,
            deviceId: rememberDevice
                ? new CookieFactory().getValue(DEVICE_ID)
                : null,
            recaptcha,
            language: locale,
        }),
    }).then((response: Response) => {
        if (response.ok) {
            return (response.json() as Promise<IMfaResponse>).then(
                (mfaResponse) => {
                    if (
                        mfaResponse.isSuccessful ||
                        mfaResponse.signOutRequired
                    ) {
                        return mfaResponse;
                    }

                    if (
                        mfaResponse.messages.some(
                            ({ message }) => message === OktaErrorCode
                        )
                    ) {
                        throw new IntlError(
                            "message.mfa_verification_error",
                            "There was a verification error."
                        );
                    } else if (
                        mfaResponse.messages.some(
                            ({ message }) => message === RecaptchaErrorCode
                        )
                    ) {
                        throw new IntlError(
                            "message.recaptcha_error",
                            "There was a recaptcha validation system error."
                        );
                    } else {
                        throw new IntlError(
                            "message.system_error",
                            "There was an internal server error."
                        );
                    }
                }
            );
        }

        return (response.json() as Promise<IMfaExceptionResponse>).then(
            (mfaExceptionResponse) => {
                if (
                    mfaExceptionResponse.exceptionMessage ===
                    "Too Many Requests 429"
                ) {
                    throw new IntlError(
                        "message.mfa_timeout_error",
                        "We apologize for the inconvenience but you must wait 30 seconds between authentication challenges."
                    );
                }

                if (
                    mfaExceptionResponse.exceptionMessage ===
                    "Forbidden Forbidden"
                ) {
                    throw new IntlError(
                        "message.mfa_wrong_code",
                        "Your code may have been incorrect, please try again."
                    );
                }

                throw new IntlError(
                    "message.system_error",
                    "There was an internal server error."
                );
            }
        );
    });
};

export const updateUserProfile = (
    application: IApplication,
    merchantName: string,
    authToken: string,
    profile: IProfile | IPayor,
    password: string | null,
    currentPassword: string | null = null
): Promise<IUser> => {
    const baseUrl = process.env.REACT_APP_API_BASEURL;

    return fetch(`${baseUrl}/webapi/api/Profile?merchantName=${merchantName}`, {
        method: "PUT",
        mode: baseUrl ? "cors" : "same-origin",
        headers: { "Content-Type": "application/json; charset=utf-8" },
        body: JSON.stringify({
            application: application,
            AuthToken: authToken,
            Customer: profile,
            password: password,
            currentPassword: currentPassword,
        }),
    }).then((response) => {
        if (response.status === 401) {
            throw new Error("Not Authorized");
        }

        return response.ok
            ? (response.json() as Promise<IUser>)
            : response.json();
    });
};

export const updateUserAutopay = (
    application: IApplication,
    merchantName: string,
    user: IUser
): Promise<IUser> => {
    const baseUrl = process.env.REACT_APP_API_BASEURL;

    return fetch(
        `${baseUrl}/webapi/api/Profile/UpdateAutopay?merchantName=${merchantName}`,
        {
            method: "PUT",
            mode: baseUrl ? "cors" : "same-origin",
            headers: { "Content-Type": "application/json; charset=utf-8" },
            body: JSON.stringify({
                application: application,
                AuthToken: user.authToken,
                Customer: user.profile,
                autoPayEnabled: user.autoPayEnabled,
                notificationEnabled: user.notificationsEnabled,
            }),
        }
    ).then((response) => response.json() as Promise<IUser>);
};

export const sendReceiptEmail = (
    application: IApplication,
    merchantName: string,
    languageId: number,
    emailAddress: string,
    recaptcha?: string | null
): Promise<IResponse> => {
    const baseUrl = process.env.REACT_APP_API_BASEURL;

    return fetch(
        `${baseUrl}/webapi/api/SendWebReceiptEmail?merchantName=${merchantName}&language=${languageId}`,
        {
            method: "POST",
            mode: baseUrl ? "cors" : "same-origin",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({
                application: application,
                toAddress: emailAddress,
                captcha: recaptcha,
            }),
        }
    ).then((response) => response.json() as Promise<IResponse>);
};

export const getToken = (
    application: IApplication,
    merchantName: string,
    languageId: number,
    payment: IPayment,
    shareTokenWithGroup: boolean,
    recaptcha?: string | null
): Promise<IToken> => {
    const baseUrl = process.env.REACT_APP_API_BASEURL;

    return fetch(
        `${baseUrl}/webapi/api/Token?merchantName=${merchantName}&language=${languageId}`,
        {
            method: "POST",
            mode: baseUrl ? "cors" : "same-origin",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({
                application: application,
                payment: payment,
                recaptcha: recaptcha,
                shareTokenWithGroup,
            }),
        }
    ).then((response) => response.json() as Promise<IToken>);
};
