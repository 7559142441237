import React from "react";
import { FormattedNumber } from "react-intl";
import {
    AddBillToCardButton,
    ViewBillButton,
    PaymentsDisabledMessage,
} from "../../../..";
import {
    BillSearchResultCellInstl1,
    BillSearchResultBtnAddC1,
    BillSearchResultBtnAddC1Mobile,
    BillSearchResultBtnInCart,
} from "../../../../../../resources";
import { IBill, IBillType } from "../../../../../../types";
import { GetMaskedBillNumber } from "../../../../../../utils/BillHelpers";
import {
    BillInstallmentType,
    ICommonBill,
    SanDiegoBillType,
} from "../../../../types";
import BillUtils from "../../Tables/Mapper";

interface FirstRowOfSecuredSupplementalBillProps {
    trKey: string;
    itemSize: number;
    billType: IBillType | undefined;
    billTypeName: SanDiegoBillType;
    row: ICommonBill;
    addToCart: (row: IBill) => void;
    isFirstRowAddedToCart: boolean;
    handleViewBill: (bill: IBill) => void;
}

export class FirstRowOfSecuredSupplementalBill extends React.Component<FirstRowOfSecuredSupplementalBillProps> {
    constructor(props: FirstRowOfSecuredSupplementalBillProps) {
        super(props);
    }

    handleAddToCart = () => {
        const { row, billTypeName, addToCart } = this.props;
        const bill = BillUtils.getBillFromCommonBill(
            row,
            billTypeName,
            BillInstallmentType.first
        );
        addToCart(bill);
    };

    handleViewBillForMobile = () => {
        const { row, billTypeName } = this.props;
        const bill = BillUtils.getBillFromCommonBill(
            row,
            billTypeName,
            BillInstallmentType.first
        );
        this.props.handleViewBill(bill);
    };

    render() {
        const {
            trKey,
            itemSize,
            billType,
            billTypeName,
            handleViewBill,
            row,
            isFirstRowAddedToCart,
        } = this.props;
        const renderButton = row.firstPaymentType.isPayable;
        const showPaymentsDisabledMessage =
            !row.firstPaymentType.isPayable &&
            billType != undefined &&
            billType.paymentsDisabled === true;
        const buttonLabel = isFirstRowAddedToCart
            ? BillSearchResultBtnInCart
            : BillSearchResultBtnAddC1;
        const buttonLabelMobile = isFirstRowAddedToCart
            ? BillSearchResultBtnInCart
            : BillSearchResultBtnAddC1Mobile;

        return (
            <tr key={trKey}>
                <td
                    className="gridRowText hidden-xs view-bill-cell td-vert-aln-center"
                    rowSpan={itemSize}
                >
                    <ViewBillButton
                        billType={billTypeName}
                        handleViewBill={handleViewBill}
                        row={row}
                    ></ViewBillButton>
                </td>
                <td
                    className="gridRowText td-vert-aln-center hidden-xs"
                    rowSpan={itemSize}
                >
                    {GetMaskedBillNumber(billTypeName, row.parcelNumber)}
                </td>
                <td
                    className="gridRowText td-vert-aln-center visible-xs"
                    rowSpan={itemSize}
                >
                    <a onClick={this.handleViewBillForMobile}>
                        {GetMaskedBillNumber(billTypeName, row.parcelNumber)}
                    </a>
                </td>
                <td
                    className="gridRowText owner td-vert-aln-center"
                    rowSpan={itemSize}
                >
                    {row.owner}
                </td>
                <td className="gridRowText hidden-xs td-width-95p">
                    {BillSearchResultCellInstl1}
                </td>
                <td className="gridRowText visible-xs">1st</td>
                <td className="gridRowText visible-xs">
                    {renderButton && (
                        <AddBillToCardButton
                            label={buttonLabelMobile}
                            disabled={isFirstRowAddedToCart}
                            handler={this.handleAddToCart}
                        />
                    )}
                    {showPaymentsDisabledMessage && (
                        <PaymentsDisabledMessage
                            message={billType!.paymentsDisabledMessage}
                        />
                    )}
                </td>
                <td className="gridRowNumeric hidden-xs">
                    <FormattedNumber
                        value={row.firstPaymentType.amount}
                        style="currency"
                        currency="USD"
                    />
                </td>
                <td className="gridRowText hidden-xs">
                    {row.firstPaymentType.dueDate.toLocaleDateString("en-US")}
                </td>
                <td className="gridRowText status">
                    {row.firstPaymentType.status}
                </td>
                <td className="gridRowNumeric">
                    <FormattedNumber
                        value={row.firstPaymentType.amountDue}
                        style="currency"
                        currency="USD"
                    />
                </td>
                <td
                    className="gridRowNumeric hidden-xs td-vert-aln-center"
                    rowSpan={itemSize}
                >
                    <FormattedNumber
                        value={row.totalDue}
                        style="currency"
                        currency="USD"
                    />
                </td>
                <td className="gridRowText action-cell hidden-xs">
                    {renderButton && (
                        <AddBillToCardButton
                            label={buttonLabel}
                            disabled={isFirstRowAddedToCart}
                            handler={this.handleAddToCart}
                        />
                    )}
                    {showPaymentsDisabledMessage && (
                        <PaymentsDisabledMessage
                            message={billType!.paymentsDisabledMessage}
                        />
                    )}
                </td>
                <td className="gridRowText visible-xs">
                    {row.firstPaymentType.dueDate.toLocaleDateString("en-US")}
                </td>
            </tr>
        );
    }
}
