import React from "react";
import { History } from "history";
import {
    Button,
    ButtonToolbar,
    Panel,
    Row,
    Col,
    Modal,
    Form,
} from "react-bootstrap";
import { PhoneInput, SelectInput, EmailInput } from "../Input";
import { CheckFieldValidity, CheckFormValidity } from "../../utils/Validation";
import { ConfirmModal } from "../Layout/Modals/ConfirmModal";
import BillDetailTable from "../BillDetail/BillDetailTable";
import { BlockUi } from "../Layout/BlockUi";
import PaymentForm from "../Payment/PaymentForm";
import AlertMessage from "../Layout/AlertMessage";
import { IFormError } from "../../types/IFormError";
import {
    IAccount,
    IAlertMessage,
    IApplication,
    IBill,
    IBillDetail,
    IBillType,
    IMyAccount,
    ISelectOption,
    IPaymentTerms,
} from "../../types";
import {
    IInternationalization,
    IPayment,
    IPayor,
    ISettings,
    IUser,
    PreferredContactEnum,
} from "../../types";
import {
    faExclamationTriangle,
    faCheck,
    faCircleNotch,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import * as Resources from "../../resources";

export interface BillDetailProps {
    application: IApplication;
    settings: ISettings;
    bills: IBill[];
    billTotal: number;
    billTypes: IBillType[];
    billDetails: IBillDetail[];
    autoPayTerms: IPaymentTerms;
    intl: IInternationalization;
    payment: IPayment;
    payor: IPayor;
    user: IUser;
    userAccounts: IAccount[];
    myAccount: IMyAccount;
    history: History;
    cancel: () => void;
    resetAutoPayTerms: () => void;
    updateCaptcha: (value: string) => void;
    updateAccepted: (value: boolean) => void;
    updateBillDetail: (billDetail: IBillDetail) => void;
    createBillDetail: (billDetails: IBillDetail[]) => void;
    deleteBillDetail: (billDetails: IBillDetail[]) => void;
    setBillNotification: (
        application: IApplication,
        merchantName: string,
        billDetails: IBillDetail[],
        merchantCustomerId: string,
        onError: (text: string) => void,
        onSuccess: (id1: string) => void
    ) => void;
    setAutoPay: (
        application: IApplication,
        merchantName: string,
        billDetails: IBillDetail[],
        user: IUser,
        account: IAccount,
        onError: (text: string) => void,
        onSuccess: (id1: string) => void
    ) => void;
    updateProfile?: (
        application: IApplication,
        merchantName: string,
        authToken: string,
        payor: IPayor,
        onSaveProfileError: (text: string | JSX.Element) => void,
        onSaveProfileSuccess: (email: string) => void
    ) => void;
    loadUserAccounts?: (
        application: IApplication,
        merchantName: string,
        authToken: string
    ) => void;
    loadBillDetails: (
        merchantName: string,
        authToken: string,
        merchantCustomerId: string
    ) => void;
    loadAutoPayTerms: (
        application: IApplication,
        merchantName: string,
        templateId: number,
        methodId: number,
        languageId: number
    ) => void;
}

export interface BillDetailState {
    selectedId: string;
    saveButtonId: string;
    showEdit: boolean;
    showModal: boolean;
    submitting: boolean;
    message: IAlertMessage;
    showMessageNext: boolean;
    showAutoPayConfirm: boolean;
    errors: IFormError;
    account: IAccount;
    dirty: boolean;
    payor: IPayor;
}

export class BillDetailPanel extends React.Component<
    BillDetailProps,
    BillDetailState
> {
    constructor(props: BillDetailProps) {
        super(props);

        let initialPayor = {} as IPayor;
        if (
            props.user.isLoggedIn &&
            props.user.profile &&
            !this.props.payor.saved
        ) {
            initialPayor = Object.assign({}, props.payor, props.user.profile);
            initialPayor.confirmEmail = props.user.profile.email;
        } else {
            initialPayor = Object.assign({}, props.payor);
            initialPayor.confirmEmail = props.payor.email;
        }
        initialPayor.mobilePhoneCountry =
            initialPayor.mobilePhoneCountry || "US";
        initialPayor.phoneCountry = initialPayor.phoneCountry || "US";
        initialPayor.country = initialPayor.country || "US";

        this.state = {
            selectedId: "",
            saveButtonId: "",
            showEdit: false,
            showModal: false,
            submitting: false,
            account: {} as IAccount,
            message: {} as IAlertMessage,
            showMessageNext: false,
            showAutoPayConfirm: false,
            payor: initialPayor,
            dirty: false,
            errors: {},
        };

        this.handleEdit = this.handleEdit.bind(this);
        this.handleSave = this.handleSave.bind(this);
        this.handleBlur = this.handleBlur.bind(this);
        this.handleError = this.handleError.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleMobilePhoneChange = this.handleMobilePhoneChange.bind(this);
        this.handleIsValid = this.handleIsValid.bind(this);
        this.handleContinue = this.handleContinue.bind(this);
        this.handleCloseModal = this.handleCloseModal.bind(this);
        this.handleFormErrors = this.handleFormErrors.bind(this);
        this.handleExitPayment = this.handleExitPayment.bind(this);
        this.handleResumePayment = this.handleResumePayment.bind(this);
        this.handleAutoPayModal = this.handleAutoPayModal.bind(this);
        this.handleSaveProfile = this.handleSaveProfile.bind(this);
        this.handleSaveBillDetail = this.handleSaveBillDetail.bind(this);
        this.handleMobilePhoneCountryChange =
            this.handleMobilePhoneCountryChange.bind(this);
        this.onAutoPayChanged = this.onAutoPayChanged.bind(this);
        this.onNotificationChanged = this.onNotificationChanged.bind(this);
        this.retrieveBillDetail = this.retrieveBillDetail.bind(this);
        this.showEditForm = this.showEditForm.bind(this);
        this.updateMessage = this.updateMessage.bind(this);
        this.onSaveProfileError = this.onSaveProfileError.bind(this);
        this.onSaveProfileSuccess = this.onSaveProfileSuccess.bind(this);
    }

    public componentDidMount(): void {
        const {
            application,
            settings,
            bills,
            billTypes,
            userAccounts,
            createBillDetail,
        } = this.props;

        if (application.guid && application.expired) {
            const path = `/${settings.merchantName}/expired/${application.guid}`;
            this.props.history.push(path);
            return;
        }

        if (application.guid && application.canceled) {
            const path = `/${settings.merchantName}/canceled/${application.guid}`;
            this.props.history.push(path);
            return;
        }

        if (bills && bills.length > 0) {
            const newBillDetails: IBillDetail[] = [];
            bills.map((bill) => {
                const billDetail = this.retrieveBillDetail(bill.id1);
                const billType =
                    billTypes &&
                    billTypes.find((bt) => bt.name === bill.billType);
                const isAutopayEnabled =
                    settings.allowAutoBillPay &&
                    billType &&
                    billType.autopayEligible
                        ? true
                        : false;
                const isNotificationEnabled =
                    settings.enableNewBillNotifications &&
                    billType &&
                    billType.notificationEligible
                        ? true
                        : false;
                const userAccount = userAccounts.find(
                    (s: IAccount) =>
                        s.nickname === this.props.payment.customerAccountName
                );
                const lastFour =
                    userAccount && userAccount.accountNumber
                        ? userAccount.accountNumber.substr(
                              userAccount.accountNumber.length - 4
                          )
                        : "";
                const method = userAccount ? userAccount.paymentMethod : 0;
                if (!billDetail) {
                    const newBillDetail: IBillDetail = {
                        autopayId: 0,
                        billType: bill.billType,
                        customerBillDetailId: -1,
                        identifier1: bill.id1,
                        identifier2: bill.id2,
                        identifier3: bill.id3,
                        identifier4: bill.id4,
                        isNewRecord: true,
                        isAutopayEnabled: isAutopayEnabled,
                        isNotificationEnabled: isNotificationEnabled,
                        lastPaidDateUTC: null,
                        preferredContactMethod: "",
                        paymentMethod: method,
                        tokenLastFour: lastFour,
                        token: "",
                    };

                    newBillDetails.push(newBillDetail);
                }
            });
            if (
                !this.props.myAccount.active &&
                newBillDetails &&
                newBillDetails.length > 0
            ) {
                createBillDetail(newBillDetails);
                this.showEditForm(true);
            }
        }

        if (
            !this.props.myAccount.active &&
            this.props.billDetails.findIndex((b) => b.isNewRecord) >= 0
        ) {
            this.showEditForm(true);
        }
    }

    public componentDidUpdate(prevProps: any) {
        if (
            prevProps.application.location !== this.props.application.location
        ) {
            const location = this.props.application.location;
            if (location && !location.endsWith("billdetail")) {
                this.updateMessage({ text: "", isSuccess: false });
                this.showEditForm(true);
            } else {
                this.showEditForm(false);
            }
        }
    }

    private handleIsValid(isValid: boolean): void {
        const { submitting } = this.state;
        this.setState({
            submitting: false,
        });
    }

    private handleChange(event: any): void {
        const field = event.target.name;
        const payor = { ...this.state.payor };
        if (
            field === "country" &&
            payor.country === "US" &&
            event.target.value !== "US"
        ) {
            payor.state = "";
        }
        if (field === "preferredContactMethod") {
            (payor as any)[field] = PreferredContactEnum[event.target.value];
        } else {
            (payor as any)[field] = event.target.value;
        }
        if (!event.target.validity.valid) {
            CheckFieldValidity(event.target, this.handleError);
        }
        if (event.target.id === "email") {
            payor.confirmEmail = "";
        }
        let isDirty = true;
        const {
            user: { profile },
        } = this.props;
        if (!this.state.dirty && profile) {
            isDirty =
                event.target.value === (profile as any)[field] ? false : true;
        }
        this.setState({ dirty: isDirty, payor });
    }

    private handleMobilePhoneChange(phone: string): void {
        this.setState({
            payor: {
                ...this.state.payor,
                mobilePhone: phone,
            },
        });
    }

    private handleEdit(event: any): void {
        event.preventDefault();
        this.showEditForm(true);
        this.setState({ selectedId: event.currentTarget.id });
    }

    private handleBlur(event: any): void {
        CheckFieldValidity(event.target, this.handleError);
    }

    private handleError(name: string, message: string): void {
        this.setState({
            errors: {
                ...this.state.errors,
                [name]: message,
            },
        });
    }

    private handleFormErrors = (errors: IFormError): void => {
        this.setState({
            errors: {
                ...this.state.errors,
                ...errors,
            },
        });
    };

    private handleMobilePhoneCountryChange(country: string): void {
        this.setState({
            payor: {
                ...this.state.payor,
                mobilePhoneCountry: country,
            },
        });
    }

    private handleSave(
        account: IAccount,
        saveButtonId: string,
        onSubmitBillDetail: (saveButtonId: string) => void
    ): void {
        this.setState({ account, saveButtonId, submitting: false });
        if (onSubmitBillDetail) {
            onSubmitBillDetail(saveButtonId);
        }

        const validateBillDetail =
            this.props.settings.enableTextNotifications && this.state.showEdit;
        if (
            validateBillDetail &&
            !CheckFormValidity("billdetail-form", this.handleFormErrors)
        ) {
            return;
        }

        const { dirty, payor } = this.state;
        if (dirty) {
            this.handleSaveProfile(payor);
        } else {
            this.handleSaveBillDetail(account);
        }
    }

    private handleSaveBillDetail(account: IAccount): void {
        const {
            application,
            settings,
            setAutoPay,
            setBillNotification,
            user,
            billDetails,
            bills,
        } = this.props;
        const billDetail = this.retrieveBillDetail(this.state.selectedId);
        const billNotificationOnly = billDetails.filter(
            (billDetail) =>
                billDetail.isNewRecord &&
                billDetail.isNotificationEnabled &&
                !billDetail.isAutopayEnabled
        );

        if (!settings.allowAutoBillPay) {
            const billDetailsToSave = billDetails.filter(
                (billDetail) => billDetail.isNotificationEnabled
            );
            setBillNotification(
                application,
                settings.merchantName,
                billDetailsToSave,
                user.merchantCustomerID,
                this.onSaveError,
                this.onSaveNotificationSuccess
            );
        } else if (!billDetail) {
            let billDetailsToSave: IBillDetail[] = [];
            if (!(account as IAccount).token) {
                if (this.props.userAccounts.length === 0) {
                    account.isDefault = true;
                }
            }

            if (bills.length > 0) {
                billDetailsToSave = billDetails.filter(
                    (b: IBillDetail) =>
                        bills.length > 0 &&
                        bills.findIndex(
                            (bill: IBill) => bill.id1 === b.identifier1
                        ) >= 0
                );
            } else {
                billDetailsToSave = billDetails;
            }

            if (billNotificationOnly.length > 0) {
                const billDetailsParam = billDetailsToSave.filter(
                    (billDetail) => billDetail.isNotificationEnabled
                );
                setBillNotification(
                    application,
                    settings.merchantName,
                    billDetailsParam,
                    user.merchantCustomerID,
                    this.onSaveError,
                    this.onSaveNotificationSuccess
                );
            }

            const billDetailsParam =
                billDetailsToSave.filter(
                    (billDetail) =>
                        billDetail.isAutopayEnabled && billDetail.isNewRecord
                ).length > 0
                    ? billDetailsToSave.filter(
                          (billDetail) =>
                              billDetail.isAutopayEnabled &&
                              billDetail.isNewRecord
                      )
                    : billDetailsToSave.filter(
                          (billDetail) =>
                              billDetail.isAutopayEnabled && !billDetail.token
                      ).length > 0
                    ? billDetailsToSave.filter(
                          (billDetail) =>
                              billDetail.isAutopayEnabled && !billDetail.token
                      )
                    : billDetailsToSave.filter(
                          (billDetail) => billDetail.isAutopayEnabled
                      );

            setAutoPay(
                application,
                settings.merchantName,
                billDetailsParam,
                user,
                account,
                this.onSaveError,
                this.onSaveAutopaySuccess
            );
        } else {
            const billDetailParam = { ...billDetail };
            if (
                billDetailParam.isNotificationEnabled &&
                !billDetailParam.isAutopayEnabled
            ) {
                setBillNotification(
                    application,
                    settings.merchantName,
                    [billDetailParam],
                    user.merchantCustomerID,
                    this.onSaveError,
                    this.onSaveNotificationSuccess
                );
            } else {
                setAutoPay(
                    application,
                    settings.merchantName,
                    [billDetailParam],
                    user,
                    account,
                    this.onSaveError,
                    this.onSaveAutopaySuccess
                );
            }
        }
    }

    private handleSaveProfile(payor: any): void {
        const {
            application,
            settings: { merchantName },
            payment,
            user,
        } = this.props;
        this.props.updateProfile!(
            application,
            merchantName,
            user.authToken,
            payor,
            this.onSaveProfileError,
            this.onSaveProfileSuccess
        );
    }

    private onSaveProfileSuccess(email: string): void {
        this.handleSaveBillDetail(this.state.account);
    }

    private onSaveProfileError(text: string | JSX.Element): void {
        this.setState({
            message: { text: text, isSuccess: false },
        });
    }

    private handleContinue() {
        const { application, bills, billDetails, deleteBillDetail, myAccount } =
            this.props;
        const billDetailsToRemove = billDetails.filter(
            (billDetail) => billDetail.isNewRecord
        );
        if (this.state.selectedId)
            this.resetCustomerBillDetail(this.state.selectedId);
        if (billDetailsToRemove.length > 0)
            deleteBillDetail(billDetailsToRemove);
        if (bills && bills.length > 0) {
            this.setState({ submitting: false, showModal: true });
        } else if (
            myAccount.active &&
            application.location!.toLowerCase().includes("edit")
        ) {
            this.props.history.goBack();
        } else {
            this.handleExitPayment();
        }
    }

    private handleResumePayment() {
        this.setState({ submitting: false, showModal: false, selectedId: "" });
        const { application, settings } = this.props;
        const path = application.guid
            ? `/${settings.merchantName}/verify/${application.guid}`
            : `/${settings.merchantName}/verify`;
        this.props.history.push(path);
    }

    private handleExitPayment() {
        this.setState({ submitting: false, showModal: false, selectedId: "" });
        const { application, settings } = this.props;
        const path = application.guid
            ? `/${settings.merchantName}/search/${application.guid}`
            : `/${settings.merchantName}/search`;
        this.props.history.push(path);
        this.props.cancel();
    }

    private handleAutoPayModal() {
        const billDetail = this.retrieveBillDetail(this.state.selectedId);
        const billDetailParam = billDetail && {
            ...billDetail,
            isAutopayEnabled: false,
        };
        const account =
            billDetail &&
            this.props.userAccounts.find(
                (s: IAccount) => s.token === billDetail.token
            );
        if (billDetailParam && !billDetailParam.isNewRecord && account) {
            const {
                application,
                setAutoPay,
                user,
                settings: { merchantName },
            } = this.props;
            setAutoPay(
                application,
                merchantName,
                [billDetailParam],
                user,
                account,
                this.onSaveError,
                this.onSaveAutopaySuccess
            );
            this.updateMessage({ text: "", isSuccess: false });
            this.setState({ submitting: true });
            this.handleCloseModal();
        }
    }

    private handleCloseModal() {
        this.setState({
            selectedId: "",
            showAutoPayConfirm: false,
            showModal: false,
        });
    }

    private onAutoPayChanged(event: any): void {
        const billDetail = this.retrieveBillDetail(event.currentTarget.name);
        const billType =
            billDetail &&
            this.props.billTypes &&
            this.props.billTypes.find((bt) => bt.name === billDetail.billType);
        const allowNotifications =
            this.props.settings.enableNewBillNotifications &&
            billType &&
            billType.notificationEligible;
        const account =
            billDetail &&
            this.props.userAccounts.find(
                (s: IAccount) => s.token === billDetail.token
            );
        const billDetailParam = billDetail && {
            ...billDetail,
            isAutopayEnabled: event.target.checked,
        };

        if (billDetailParam && billDetailParam.token && account) {
            if (event.target.checked) {
                const {
                    application,
                    setAutoPay,
                    user,
                    settings: { merchantName },
                } = this.props;
                billDetailParam.isNotificationEnabled = allowNotifications
                    ? true
                    : false;
                setAutoPay(
                    application,
                    merchantName,
                    [billDetailParam],
                    user,
                    account,
                    this.onSaveError,
                    this.onSaveAutopaySuccess
                );
            } else {
                this.setState({ selectedId: event.currentTarget.name });
                this.setState({ showAutoPayConfirm: true });
            }
        } else if (billDetailParam && !billDetailParam.token) {
            if (event.target.checked) {
                if (
                    !billDetailParam.isNotificationEnabled &&
                    allowNotifications
                ) {
                    billDetailParam.isNotificationEnabled = true;
                    if (!billDetailParam.isNewRecord) {
                        billDetailParam.isNotificationChanged = true;
                    }
                }
                if (billDetailParam.isNewRecord) {
                    this.showEditForm(true);
                } else {
                    billDetailParam.isAutopayChanged = true;
                    this.showEditForm(true, event.target.name);
                }
            } else if (this.props.myAccount.active && !event.target.checked) {
                billDetailParam.isNotificationEnabled = false;
                this.showEditForm(false);
            }
            this.props.updateBillDetail(billDetailParam);
        }
    }

    private onNotificationChanged(event: any): void {
        const billDetail = this.retrieveBillDetail(event.currentTarget.name);
        const billDetailParam = billDetail && {
            ...billDetail,
            isNotificationEnabled: event.target.checked,
        };
        if (billDetailParam && !billDetailParam.isNewRecord) {
            billDetailParam.isNotificationChanged = true;
            if (event.target.checked) {
                this.props.updateBillDetail(billDetailParam);
                this.showEditForm(true, event.target.name);
            } else if (!event.target.checked) {
                const {
                    application,
                    setBillNotification,
                    user,
                    settings: { merchantName },
                } = this.props;
                setBillNotification(
                    application,
                    merchantName,
                    [billDetailParam],
                    user.merchantCustomerID,
                    this.onSaveError,
                    this.onSaveNotificationSuccess
                );
            }
        } else if (billDetailParam && billDetailParam.isNewRecord) {
            this.props.updateBillDetail(billDetailParam);
        }
    }

    private onSaveAutopaySuccess = (id1: string) => {
        const billDetail = this.retrieveBillDetail(id1);
        const showEnableMessage = billDetail && billDetail.isAutopayEnabled;
        const showDisableMessage =
            billDetail && !billDetail.isAutopayEnabled && billDetail.token;
        const message = showDisableMessage
            ? Resources.MessageDisableAutopay
            : showEnableMessage
            ? Resources.MessageEnableAutopay
            : "";
        const showMessageNext =
            billDetail && billDetail.isAutopayEnabled ? true : false;
        const showEdit = this.state.saveButtonId
            .toLowerCase()
            .includes("continue")
            ? true
            : false;
        this.setState({
            saveButtonId: "",
            selectedId: "",
            submitting: false,
            showMessageNext,
        });
        this.updateMessage({ text: message, isSuccess: true });
        this.showEditForm(showEdit);
    };

    private onSaveNotificationSuccess = (id1: string) => {
        const billDetail = this.retrieveBillDetail(id1);
        const showEnableMessage =
            billDetail && billDetail.isNotificationEnabled;
        const showDisableMessage =
            billDetail && !billDetail.isNotificationEnabled;
        const message = showDisableMessage
            ? Resources.MessageDisableNotification
            : showEnableMessage
            ? Resources.MessageEnableNotification
            : "";
        const showEdit = this.state.saveButtonId
            .toLowerCase()
            .includes("continue")
            ? true
            : false;
        this.setState({
            saveButtonId: "",
            selectedId: "",
            submitting: false,
            showMessageNext: false,
        });
        this.updateMessage({ text: message, isSuccess: true });
        this.showEditForm(showEdit);
    };

    private onSaveError = (text: string) => {
        this.setState({ saveButtonId: "", selectedId: "", submitting: false });
        this.updateMessage({ text: text, isSuccess: false });
        this.showEditForm(false);
    };

    private retrieveBillDetail(id1: string): IBillDetail | undefined {
        return this.props.billDetails.find(
            (billDetail) => billDetail.identifier1 === id1
        );
    }

    private initializePayment(): IPayment {
        const { userAccounts, user } = this.props;
        const payment: IPayment = { ...this.props.payment };

        if (user.isLoggedIn && userAccounts.length !== 0) {
            const billDetail = this.retrieveBillDetail(this.state.selectedId);
            if (billDetail && billDetail.token) {
                const userAccount = userAccounts.find(
                    (x: any) => x.token === billDetail.token
                );
                if (userAccount) {
                    payment.paymentMethod = userAccount.paymentMethod;
                    payment.nameOnAccount = userAccount.nameOnAccount;
                    payment.cardNumber = userAccount.accountNumber;
                    payment.expirationYear =
                        userAccount.expirationYear.toString();
                    payment.accountType = userAccount.accountType;
                    payment.routingNumber = userAccount.routingNumber;
                    payment.accountNumber = userAccount.accountNumber;
                    payment.customerAccountName = userAccount.nickname;
                    payment.firstName = userAccount.firstName;
                    payment.middleName = userAccount.middleName;
                    payment.lastName = userAccount.lastName;
                    payment.address = userAccount.address;
                    payment.country = userAccount.phoneCountry;
                    payment.state = userAccount.state;
                    payment.city = userAccount.city;
                    payment.postal = userAccount.postal;
                    payment.phone = userAccount.phone;
                    payment.phoneCountry = userAccount.phoneCountry;
                    payment.method = "saved";
                }
            } else {
                const userAccount = userAccounts.find((x: any) => x.isDefault);
                if (userAccount) {
                    payment.paymentMethod = userAccount.paymentMethod;
                    payment.nameOnAccount = userAccount.nameOnAccount;
                    payment.cardNumber = userAccount.accountNumber;
                    payment.expirationYear =
                        userAccount.expirationYear.toString();
                    payment.accountType = userAccount.accountType;
                    payment.routingNumber = userAccount.routingNumber;
                    payment.accountNumber = userAccount.accountNumber;
                    payment.customerAccountName = userAccount.nickname;
                    payment.firstName = userAccount.firstName;
                    payment.middleName = userAccount.middleName;
                    payment.lastName = userAccount.lastName;
                    payment.address = userAccount.address;
                    payment.country = userAccount.phoneCountry;
                    payment.state = userAccount.state;
                    payment.city = userAccount.city;
                    payment.postal = userAccount.postal;
                    payment.phone = userAccount.phone;
                    payment.phoneCountry = userAccount.phoneCountry;
                    payment.method = "saved";
                }
            }
        } else {
            payment.method = "credit";
            payment.firstName = this.props.user.profile.firstName;
            payment.middleName = this.props.user.profile.middleName;
            payment.lastName = this.props.user.profile.lastName;
            payment.email = this.props.user.profile.email;
            payment.address = this.props.user.profile.address;
            payment.country = this.props.user.profile.phoneCountry;
            payment.state = this.props.user.profile.state;
            payment.city = this.props.user.profile.city;
            payment.postal = this.props.user.profile.postal;
            payment.phone = this.props.user.profile.phone;
            payment.phoneCountry = this.props.user.profile.phoneCountry || "US";
        }

        payment.mobilePhone = this.props.user.profile.mobilePhone;
        payment.mobilePhoneCountry =
            this.props.user.profile.mobilePhoneCountry || "US";
        return payment;
    }

    private updateMessage(newmessage: IAlertMessage): void {
        this.setState({
            message: newmessage,
        });
    }

    private showEditForm(showEdit: boolean, selectedId?: string): void {
        if (showEdit !== this.state.showEdit) {
            if (!showEdit) {
                this.resetCustomerBillDetail(this.state.selectedId);
                this.setState({ selectedId: "" });
                const path = this.props.history.location.pathname
                    .toLowerCase()
                    .replace("/edit", "");
                this.props.history.push(path);
            } else if (showEdit) {
                if (selectedId) {
                    this.setState({ selectedId });
                }
                const path = this.props.history.location.pathname + "/edit";
                this.props.history.push(path);
            }

            this.setState({
                showEdit: showEdit,
            });
        }
    }

    private resetCustomerBillDetail = (selectedId: string) => {
        const billDetail = this.retrieveBillDetail(selectedId);
        if (billDetail && !billDetail.isNewRecord) {
            if (billDetail.isNotificationChanged) {
                const isNotificationEnabled = billDetail.isNotificationEnabled;
                billDetail.isNotificationEnabled = !isNotificationEnabled;
                billDetail.isNotificationChanged = false;
            }
            if (billDetail.isAutopayChanged) {
                const isAutopayEnabled = billDetail.isAutopayEnabled;
                billDetail.isAutopayEnabled = !isAutopayEnabled;
                billDetail.isAutopayChanged = false;
            }
            this.props.updateBillDetail(billDetail);
        }
    };

    private buildBillDetailTables() {
        const { myAccount, billDetails, bills } = this.props;
        const { selectedId, showEdit } = this.state;
        const selectedIdParam = showEdit ? selectedId : "";
        return this.props.billTypes.map(
            (billType: IBillType, index: number) => {
                let filteredBillDetails: IBillDetail[] = [];
                const billDetailsByType = billDetails.filter(
                    (b) => b.billType === billType.name
                );
                const billDetailsByTypeInBills = billDetailsByType.filter(
                    (b: IBillDetail) =>
                        bills.length > 0 &&
                        bills.findIndex(
                            (bill: IBill) => bill.id1 === b.identifier1
                        ) >= 0
                );
                const savedRecords = billDetailsByType.filter(
                    (b) => !b.isNewRecord
                );
                const newRecords = billDetailsByType.filter(
                    (b) => b.isNewRecord
                );
                const isNewBillDetails =
                    !myAccount.active && newRecords.length > 0;
                if (!myAccount.active && newRecords.length > 0) {
                    filteredBillDetails = newRecords;
                } else if (
                    !myAccount.active &&
                    billDetailsByTypeInBills.length > 0
                ) {
                    filteredBillDetails = billDetailsByTypeInBills;
                } else {
                    if (selectedIdParam) {
                        filteredBillDetails = savedRecords.filter(
                            (b) => b.identifier1 === selectedIdParam
                        );
                    } else if (!selectedIdParam) {
                        filteredBillDetails = savedRecords;
                    }
                }
                return (
                    filteredBillDetails.length > 0 && (
                        <BillDetailTable
                            key={billType.name}
                            selectedId={selectedId}
                            billType={billType}
                            billDetails={filteredBillDetails}
                            settings={this.props.settings}
                            handleEdit={this.handleEdit}
                            isNewBillDetails={isNewBillDetails}
                            onAutoPayChanged={this.onAutoPayChanged}
                            onNotificationChanged={this.onNotificationChanged}
                        />
                    )
                );
            }
        );
    }

    public render(): JSX.Element {
        const {
            settings: { enableTextNotifications },
        } = this.props;
        const {
            message,
            showEdit,
            showModal,
            showAutoPayConfirm,
            showMessageNext,
            submitting,
            selectedId,
            payor,
            errors,
        } = this.state;
        const bsStyle =
            message === undefined
                ? ""
                : message.isSuccess
                ? "success"
                : "danger";
        const icon =
            message !== undefined && message.isSuccess
                ? faCheck
                : faExclamationTriangle;
        const showAlert = message !== undefined && message.text;
        const bsStyleNext = "danger";
        const iconNext = faExclamationTriangle;
        const messageNext: IAlertMessage = {
            isSuccess: false,
            text: Resources.MessageEnableAutopayNext,
        };
        const selectedBillDetail = this.retrieveBillDetail(selectedId);
        const displaySelectedId = <strong>{selectedId}</strong>;
        const continueButton = submitting ? (
            <FontAwesomeIcon icon={faCircleNotch} spin />
        ) : (
            Resources.ButtonContinue
        );

        const options: ISelectOption[] = [];
        const isText2Pay = payor.preferredContactMethod === 2;
        const preferredContactMethod =
            PreferredContactEnum[payor.preferredContactMethod];
        Object.keys(PreferredContactEnum)
            .filter((key) => isNaN(Number(key)))
            .map((key) => {
                options.push({ value: key, text: key } as ISelectOption);
            });
        return (
            <div>
                <Modal show={showModal} onHide={this.handleCloseModal}>
                    <Modal.Header closeButton>
                        <Modal.Title>
                            {Resources.HeaderExitToPayment}
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>{Resources.MessageAutopayNavigate}</Modal.Body>
                    <Modal.Footer>
                        <div className="pull-right">
                            <ButtonToolbar>
                                <Button onClick={this.handleExitPayment}>
                                    {Resources.ButtonNo}
                                </Button>
                                <Button
                                    bsStyle="primary"
                                    onClick={this.handleResumePayment}
                                    style={{ minWidth: "6.5em" }}
                                >
                                    {Resources.ButtonYes}
                                </Button>
                            </ButtonToolbar>
                        </div>
                    </Modal.Footer>
                </Modal>
                <ConfirmModal
                    headerText={Resources.LabelConfirmAutoPayDisable}
                    message={Resources.MessageChangeAutopay(displaySelectedId)}
                    show={showAutoPayConfirm}
                    onClose={this.handleCloseModal}
                    onConfirm={this.handleAutoPayModal}
                />
                <BlockUi shouldRender={submitting} />
                <Panel bsStyle="primary">
                    <Panel.Heading>
                        {Resources.HeaderAutoPayBillNotifications}
                    </Panel.Heading>
                    <Panel.Body>
                        {showAlert && (
                            <div>
                                <AlertMessage
                                    bsStyle={bsStyle}
                                    showIcon
                                    icon={icon}
                                    message={message}
                                />
                                {showMessageNext && (
                                    <AlertMessage
                                        bsStyle={bsStyleNext}
                                        showIcon
                                        icon={iconNext}
                                        message={messageNext}
                                    />
                                )}
                            </div>
                        )}
                        <section>
                            <fieldset>
                                {/* <legend>{Resources.HeaderBillDetailInformation}</legend> */}
                                {this.buildBillDetailTables()}
                                {/* <legend>{Resources.HeaderBillDetailInformation}</legend> */}
                                {enableTextNotifications && showEdit && (
                                    <div className="well">
                                        <fieldset>
                                            <legend>
                                                {
                                                    Resources.HeaderContactInformation
                                                }
                                            </legend>
                                            <Form id="billdetail-form">
                                                <Row>
                                                    <Col xs={12} sm={6} md={6}>
                                                        <SelectInput
                                                            label={
                                                                Resources.LabelPreferredContactMethod
                                                            }
                                                            name="preferredContactMethod"
                                                            value={
                                                                preferredContactMethod
                                                            }
                                                            error={
                                                                errors.accountType
                                                            }
                                                            onChange={
                                                                this
                                                                    .handleChange
                                                            }
                                                            onError={
                                                                this.handleError
                                                            }
                                                            onBlur={
                                                                this.handleBlur
                                                            }
                                                            hideSelectOne={true}
                                                            options={options}
                                                        />
                                                    </Col>
                                                    <Col xs={12} sm={6} md={6}>
                                                        {isText2Pay && (
                                                            <PhoneInput
                                                                label={
                                                                    Resources.LabelMobilePhoneNumber
                                                                }
                                                                name="mobilePhone"
                                                                countryValue={
                                                                    payor.mobilePhoneCountry
                                                                }
                                                                value={
                                                                    payor.mobilePhone
                                                                }
                                                                error={
                                                                    errors.mobilePhone
                                                                }
                                                                onBlur={
                                                                    this
                                                                        .handleBlur
                                                                }
                                                                onChange={
                                                                    this
                                                                        .handleMobilePhoneChange
                                                                }
                                                                onError={
                                                                    this
                                                                        .handleError
                                                                }
                                                                onCountryChange={
                                                                    this
                                                                        .handleMobilePhoneCountryChange
                                                                }
                                                                required={
                                                                    this.props
                                                                        .settings
                                                                        .enableNewBillNotifications
                                                                }
                                                                readOnly={
                                                                    !payor.mobilePhoneIsEditable
                                                                }
                                                                isValid={
                                                                    this
                                                                        .handleIsValid
                                                                }
                                                            />
                                                        )}
                                                        {!isText2Pay && (
                                                            <EmailInput
                                                                label={
                                                                    Resources.LabelEmail
                                                                }
                                                                name="email"
                                                                value={
                                                                    payor.email
                                                                }
                                                                error={
                                                                    errors.email
                                                                }
                                                                onBlur={
                                                                    this
                                                                        .handleBlur
                                                                }
                                                                onChange={
                                                                    this
                                                                        .handleChange
                                                                }
                                                                onError={
                                                                    this
                                                                        .handleError
                                                                }
                                                                required={
                                                                    this.props
                                                                        .settings
                                                                        .enableNewBillNotifications
                                                                }
                                                                readOnly={
                                                                    !payor.emailIsEditable
                                                                }
                                                            />
                                                        )}
                                                    </Col>
                                                </Row>
                                            </Form>
                                        </fieldset>
                                    </div>
                                )}
                                {this.state.showEdit && (
                                    <PaymentForm
                                        application={this.props.application}
                                        settings={this.props.settings}
                                        payment={this.initializePayment()}
                                        payor={this.props.payor}
                                        user={this.props.user}
                                        bills={this.props.bills}
                                        billTypes={this.props.billTypes}
                                        billTotal={this.props.billTotal}
                                        billDetails={this.props.billDetails}
                                        autoPayTerms={this.props.autoPayTerms}
                                        onContinueBillDetail={
                                            this.handleContinue
                                        }
                                        onSaveBillDetail={this.handleSave}
                                        myAccount={this.props.myAccount}
                                        userAccounts={this.props.userAccounts}
                                        locale={this.props.intl.locale}
                                        history={this.props.history}
                                        cancel={this.props.cancel}
                                        updateMessage={this.updateMessage}
                                        showAddAccount={this.showEditForm}
                                        loadAutoPayTerms={
                                            this.props.loadAutoPayTerms
                                        }
                                        updateAccepted={
                                            this.props.updateAccepted
                                        }
                                        resetAutoPayTerms={
                                            this.props.resetAutoPayTerms
                                        }
                                        selectedBillDetail={selectedBillDetail}
                                    />
                                )}
                            </fieldset>
                        </section>
                        {!showEdit && (
                            <div className="well">
                                <Row>
                                    <Col xs={12}>
                                        <ButtonToolbar>
                                            <div className="btn-group btn-group-justified-sm-12 pull-right">
                                                <Button
                                                    bsClass="btn btn-primary btn-justified"
                                                    disabled={submitting}
                                                    onClick={
                                                        this.handleContinue
                                                    }
                                                    style={{
                                                        minWidth: "6.5em",
                                                    }}
                                                >
                                                    {continueButton}
                                                </Button>
                                            </div>
                                        </ButtonToolbar>
                                    </Col>
                                </Row>
                            </div>
                        )}
                    </Panel.Body>
                </Panel>
            </div>
        );
    }
}
