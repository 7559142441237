import React from "react";
import { Button, Col, Form, Panel, Row, Well } from "react-bootstrap";
import { InjectedIntlProps, injectIntl } from "react-intl";
import {
    ButtonCancel,
    ButtonOk,
    LabelPhoneNumber,
    MessageCallEnrollment,
    PhoneExtensionLabel,
} from "../../../resources";
import { IFormError } from "../../../types/IFormError";
import { PhoneInput, TextInput } from "../../Input";

export interface IMultiFactorEnrollCallPanelProps extends InjectedIntlProps {
    onClose: () => void;
    onOk: (phoneNumber: string, extension: string, regionCode: string) => void;
}

export interface IMultiFactorEnrollCallPanelState {
    phoneCountry: string;
    phoneNumber: string;
    extension: string;
    errors: IFormError;
    isValid: boolean;
}

class MultiFactorEnrollCallPanel extends React.Component<
    IMultiFactorEnrollCallPanelProps,
    IMultiFactorEnrollCallPanelState
> {
    constructor(props: IMultiFactorEnrollCallPanelProps) {
        super(props);
        this.state = {
            phoneCountry: "US",
            phoneNumber: "",
            extension: "",
            errors: {},
            isValid: false,
        };
    }

    handleClose = (event: React.MouseEvent<Button>) => {
        event.preventDefault();
        this.props.onClose();
    };

    handleOk = (event: React.MouseEvent<Button>) => {
        event.preventDefault();
        this.props.onOk(
            this.state.phoneNumber,
            this.state.extension,
            this.state.phoneCountry
        );
    };

    handleError = (name: string, message: string): void => {
        this.setState({
            errors: { ...this.state.errors, [name]: message },
        });
    };

    handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const name = event.target
            .name as keyof IMultiFactorEnrollCallPanelState;
        this.setState({
            ...this.state,
            [name]: event.target.value,
        });
    };

    handlePhoneChange = (phone: string): void => {
        this.setState({
            phoneNumber: phone,
        });
    };

    handlePhoneCountryChange = (country: string): void => {
        this.setState({
            phoneCountry: country,
        });
    };

    handleIsValid = (isValid: boolean): void => {
        this.setState({
            isValid,
        });
    };

    render() {
        return (
            <Form id="enroll-sms-form">
                <Panel>
                    <Panel.Body>
                        <Row>
                            <Col xs={12}>
                                <Well>{MessageCallEnrollment}</Well>
                            </Col>
                            <Col xs={12}>
                                <PhoneInput
                                    label={LabelPhoneNumber}
                                    name="phoneNumber"
                                    countryValue={this.state.phoneCountry}
                                    value={this.state.phoneNumber}
                                    error={this.state.errors.phone}
                                    onChange={this.handlePhoneChange}
                                    onError={this.handleError}
                                    onCountryChange={
                                        this.handlePhoneCountryChange
                                    }
                                    required={true}
                                    isValid={this.handleIsValid}
                                />
                                <TextInput
                                    type="text"
                                    name="extension"
                                    placeholder="ext."
                                    maxLength={10}
                                    label={PhoneExtensionLabel}
                                    value={this.state.extension}
                                    error={this.state.errors.extension}
                                    onChange={this.handleChange}
                                    onError={this.handleError}
                                />
                            </Col>
                            <Col xs={12}>
                                <div
                                    style={{ paddingTop: 8, paddingBottom: 8 }}
                                >
                                    <Button
                                        bsStyle="primary"
                                        onClick={this.handleOk}
                                        style={{ minWidth: "6.5em" }}
                                        block
                                        disabled={!this.state.isValid}
                                    >
                                        {ButtonOk}
                                    </Button>
                                    <Button
                                        bsStyle="primary"
                                        onClick={this.handleClose}
                                        style={{ minWidth: "6.5em" }}
                                        block
                                    >
                                        {ButtonCancel}
                                    </Button>
                                </div>
                            </Col>
                        </Row>
                    </Panel.Body>
                </Panel>
            </Form>
        );
    }
}

export default injectIntl(MultiFactorEnrollCallPanel);
