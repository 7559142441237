import { faExclamationTriangle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import * as _ from "lodash";
import { sumBy } from "lodash";
import React from "react";
import { Alert, Button, Col, Form, Modal, Row } from "react-bootstrap";
import { FormattedNumber } from "react-intl";
import * as Resources from "../../resources";
import { IBill } from "../../types";
import BillTotal from "../Layout/BillTotal";
import { BlockUi } from "../Layout/BlockUi";
import ResultsTable from "./ResultsTable";
import ResultsVTable from "./ResultsVTable";

function CalculateAmount(bills: any) {
    return sumBy(bills, function (o: any) {
        return o.customAmount ? Number(o.customAmount) : Number(o.amount);
    });
}
class ResultsPanel extends React.Component<any, any> {
    constructor(props: any) {
        super(props);
        this.disablePayButton = this.disablePayButton.bind(this);
    }
    disablePayButton() {
        const { settings, bills } = this.props;
        const totalAmount = CalculateAmount(bills);
        if (settings.minPaymentAmount > totalAmount) {
            return true;
        }
        if (settings.maxPaymentAmount < totalAmount) {
            return true;
        }
        return bills.length === 0 ? true : false;
    }
    render() {
        const { showModal, onHide, blockUI } = this.props;
        const {
            settings,
            billType,
            bills,
            searchResults,
            onContinue,
            onAddAllBills,
            onRemoveAllBills,
        } = this.props;
        const {
            searchResults: { pageNumber, pageSize, totalRowCount },
        } = this.props;
        const first = 1 + (pageNumber - 1) * pageSize;
        const last =
            (pageNumber - 1) * pageSize +
            (searchResults.bills && searchResults.bills.length);
        const totalAmount = CalculateAmount(bills);
        const disablePayButton = this.disablePayButton();

        const pageBillIds = searchResults.bills
            .filter((bill: IBill) => bill.amountDue != 0)
            .map((b: any): number => b.id.toString());
        const allAddedBillIds = bills
            .filter((bill: IBill) => bill.amountDue != 0)
            .map((b: any): number => b.id.toString());
        const allAddedBillIdsContainPageBillIds =
            _.difference(pageBillIds, allAddedBillIds).length === 0;

        const allowAddAllBills =
            settings.allowMultipleBillPayments &&
            !allAddedBillIdsContainPageBillIds;
        const allowRemoveAllBills =
            settings.allowMultipleBillPayments &&
            allAddedBillIdsContainPageBillIds;

        return (
            <div>
                {searchResults && searchResults.isSuccessful && (
                    <Modal bsSize="large" show={showModal} onHide={onHide}>
                        <Modal.Header closeButton>
                            <Modal.Title>
                                {Resources.HeaderSearchModal(
                                    first,
                                    last,
                                    totalRowCount
                                )}
                            </Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <BlockUi shouldRender={blockUI} />
                            <Form inline>
                                <Row>
                                    <Col xsHidden sm={12} md={12} lg={12}>
                                        <ResultsTable {...this.props} />
                                    </Col>
                                    <Col xs={12} smHidden mdHidden lgHidden>
                                        <ResultsVTable {...this.props} />
                                    </Col>
                                </Row>
                            </Form>
                            {settings.maxPaymentAmount < totalAmount && (
                                <Row>
                                    <Col xs={12}>
                                        <Alert bsStyle="danger">
                                            <h4>
                                                {
                                                    Resources.LabelOvermaxPaymentAmount
                                                }
                                            </h4>
                                            <p>
                                                <FontAwesomeIcon
                                                    icon={faExclamationTriangle}
                                                />{" "}
                                                {Resources.ErrorSumOverlimit(
                                                    <FormattedNumber
                                                        value={
                                                            settings.maxPaymentAmount
                                                        }
                                                        style="currency"
                                                        currency="USD"
                                                    />
                                                )}
                                            </p>
                                        </Alert>
                                    </Col>
                                </Row>
                            )}
                        </Modal.Body>
                        <Modal.Footer>
                            <div className="btn-group btn-group-justified-sm-12 pull-right">
                                <Button
                                    bsClass="btn btn-default btn-justified"
                                    onClick={onHide}
                                >
                                    {Resources.ButtonSearchAgain}
                                </Button>
                                {allowAddAllBills && (
                                    <Button
                                        bsClass="btn btn-default btn-justified"
                                        disabled={billType.paymentsDisabled}
                                        onClick={onAddAllBills}
                                        style={{
                                            minWidth: "6.5em",
                                            width: "125px",
                                        }}
                                    >
                                        {Resources.ButtonAddAllBills}
                                    </Button>
                                )}
                                {allowRemoveAllBills && (
                                    <Button
                                        bsClass="btn btn-default btn-justified"
                                        onClick={onRemoveAllBills}
                                        style={{
                                            minWidth: "6.5em",
                                            width: "125px",
                                        }}
                                    >
                                        {Resources.ButtonRemoveAllBills}
                                    </Button>
                                )}
                                {settings.allowMultipleBillPayments && (
                                    <Button
                                        bsClass="btn btn-primary btn-justified"
                                        disabled={disablePayButton}
                                        onClick={onContinue}
                                        style={{
                                            minWidth: "6.5em",
                                            width: "125px",
                                        }}
                                    >
                                        {Resources.ButtonContinueBillPage}{" "}
                                        {bills.length > 0 && (
                                            <BillTotal bills={bills} />
                                        )}
                                    </Button>
                                )}
                            </div>
                        </Modal.Footer>
                    </Modal>
                )}
            </div>
        );
    }
}

export default ResultsPanel;
