import React from "react";
import { connect, MapDispatchToPropsParam } from "react-redux";
import { RouteComponentProps } from "react-router-dom";
import { loadUserAccounts } from "../../../actions/Account";
import {
    clearBills,
    createOrUpdate as saveBill,
    deleteBill,
    loadBillImage,
} from "../../../actions/Bill";
import { loadCustomerBillDetails } from "../../../actions/BillDetails";
import { loadImage } from "../../../actions/BillType";
import { cancel } from "../../../actions/Common";
import { logout, updateUser, userVerified } from "../../../actions/User";
import { login } from "../../../api/User";
import {
    IApplication,
    IApplicationRoute,
    IApplicationState,
    IBill,
    IInternationalization,
    ISettings,
    IUser,
} from "../../../types";
import {
    totalBillAmount,
    totalBillAmountEBPP,
} from "../../../utils/BollettaMath";
import { LoginModal } from "../../Layout/Header/LoginModal";
import ShoppingCartContent from "./Auxiliary/ShoppingCartContent";

interface CartStateProps {
    bills: IBill[];
    intl: IInternationalization;
    application: IApplication;
    settings: ISettings;
    user: IUser;
    billTotal: number;
}

interface CartDispatchProps {
    cancel: VoidFunction;
    loadUserAccounts: (
        application: IApplication,
        merchantName: string,
        authToken: string,
        onError?: (text: string) => void
    ) => void;
    loadCustomerBillDetails: (
        merchantName: string,
        authToken: string,
        merchantCustomerId: string
    ) => void;
    logout: VoidFunction;
    updateUser: (user: IUser) => void;
    userVerified: (value: boolean) => void;
    saveBill: (bill: IBill) => void;
    deleteBill: (billId: string) => void;
    clearBills: VoidFunction;
    loadImage: (
        application: IApplication,
        merchantName: string,
        billName: string,
        language: number
    ) => void;
    loadBillImage: (
        merchantName: string,
        billId: string,
        application: IApplication
    ) => void;
}

export type CartProps = RouteComponentProps<IApplicationRoute> &
    CartStateProps &
    CartDispatchProps;

export interface ICartState {
    showLoginModal: boolean;
}

class Cart extends React.Component<CartProps, ICartState> {
    constructor(props: CartProps) {
        super(props);

        if (props.bills.length == 0) this.searchResults();

        this.state = {
            showLoginModal: false,
        };
    }

    componentWillReceiveProps(nextProps: CartProps) {
        if (nextProps.bills.length == 0) this.searchResults();
    }

    render() {
        const requirePasswordReset =
            this.props.application.isEBPP &&
            this.props.settings.enableMyAccount &&
            this.props.bills.length > 0 &&
            this.props.user.requirePasswordReset;

        return (
            <div>
                <ShoppingCartContent
                    bills={this.props.bills}
                    billTotal={this.props.billTotal}
                    deleteBill={this.deleteBill}
                    clearBills={this.clearBills}
                    checkout={this.checkout}
                    searchResults={this.searchResults}
                    newSearch={this.newSearch}
                />
                {this.props.settings.enableMyAccount && (
                    <LoginModal
                        application={this.props.application}
                        merchantName={this.props.settings.merchantName}
                        allowAutoPay={this.props.settings.allowAutoBillPay}
                        requireRecaptcha={this.props.settings.requireReCaptcha}
                        requireLoginEBPP={this.props.application.isEBPP}
                        requirePasswordReset={requirePasswordReset}
                        locale={this.props.intl.locale}
                        show={this.state.showLoginModal}
                        allowGuestLogin={true}
                        user={this.props.user}
                        onClose={this.hideLoginModal}
                        onContinue={this.onContinue}
                        updateUser={this.props.updateUser}
                        userVerified={this.props.userVerified}
                        login={this.onLogin}
                        logout={this.props.logout}
                        loadUserAccounts={this.props.loadUserAccounts}
                        loadCustomerBillDetails={
                            this.props.loadCustomerBillDetails
                        }
                        webPaymentsCustomerAccountLoginMessage={
                            this.props.settings
                                .webPaymentsCustomerAccountLoginMessage
                        }
                    />
                )}
            </div>
        );
    }

    private deleteBill = (billId: string) => {
        this.props.deleteBill(billId);
        this.deleteInstallment2IfExists(billId);
    };

    private deleteInstallment2IfExists = (billId: string) => {
        const billToDelete = this.props.bills.find((x) => x.id == billId)!;

        if (billToDelete.id3.toLowerCase() == "first") {
            const installment2ToDelete = this.props.bills.filter(
                (x) =>
                    x.id1 == billToDelete.id1 &&
                    x.billType == billToDelete.billType &&
                    x.id3.toLowerCase() == "second"
            )[0];

            if (installment2ToDelete)
                this.props.deleteBill(installment2ToDelete.id);
        }
    };

    private clearBills = (): void => {
        this.props.clearBills();
    };

    private searchResults = (): void => {
        const { history } = this.props;
        const { merchantName } = this.props.settings;
        history.push(`/${merchantName}/search-result`);
    };

    private newSearch = (): void => {
        const { history } = this.props;
        const { merchantName } = this.props.settings;
        history.push(`/${merchantName}/search`);
    };

    private checkout = (): void => {
        this.props.settings.enableMyAccount &&
        this.props.application.id === 33 &&
        !(this.props.user && this.props.user.isLoggedIn)
            ? this.showLoginModal()
            : this.onContinue();
    };

    private onLogin = (
        userName: string,
        password: string,
        recaptcha?: string | null
    ): Promise<IUser> => {
        return login(
            this.props.application,
            this.props.settings.merchantName,
            userName,
            password,
            recaptcha
        );
    };

    private showLoginModal = (): void => {
        this.setState({ showLoginModal: true });
    };

    private hideLoginModal = (): void => {
        this.setState({ showLoginModal: false });
    };

    private onContinue = (): void => {
        const {
            settings: { skipPayorPage },
        } = this.props;
        const { history } = this.props;
        const { merchantName } = this.props.settings;

        if (skipPayorPage) {
            history.push(`/${merchantName}/payment`);
        } else {
            history.push(`/${merchantName}/payor`);
        }
    };
}

export default connect(
    (state: IApplicationState) =>
        ({
            intl: state.intl,
            application: state.application,
            settings: state.settings,
            bills: state.bills.bills,
            user: state.user,
            billTotal: state.application.isEBPP
                ? totalBillAmountEBPP(state.bills.bills)
                : totalBillAmount(state.bills.bills),
        } as CartStateProps),
    {
        cancel,
        saveBill,
        deleteBill,
        clearBills,
        updateUser,
        userVerified,
        loadUserAccounts,
        loadCustomerBillDetails,
        loadImage,
        loadBillImage,
        logout,
    } as MapDispatchToPropsParam<
        CartDispatchProps,
        RouteComponentProps<IApplicationRoute>
    >
)(Cart);
