import React from "react";
import { LinkContainer } from "react-router-bootstrap";
import { NavDropdown, MenuItem } from "react-bootstrap";
import * as Resources from "../../../resources";
import { IMyAccount } from "../../../types/IMyAccount";

export enum MyAccountEnum {
    profile = 1,
    accounts,
    transactions,
}

export interface IMyAccountProps {
    isAutoPayEnabled: boolean;
    myAccount: IMyAccount;
    merchantName: string;
    openChangePassword: () => void;
    openManageFactors: () => void;
    logout: () => void;
}

const MyAccount = (props: IMyAccountProps) => {
    const { merchantName } = props;
    return (
        <NavDropdown
            title={Resources.HeaderMyAccount as any}
            id="myaccount-dropdown"
        >
            <LinkContainer to={`/${merchantName}/myaccount/profile`}>
                <MenuItem>{Resources.ManageProfile}</MenuItem>
            </LinkContainer>
            <LinkContainer to={`/${merchantName}/myaccount/accounts`}>
                <MenuItem>{Resources.ManageAccounts}</MenuItem>
            </LinkContainer>
            <LinkContainer to={`/${merchantName}/myaccount/mybills`}>
                <MenuItem>{Resources.MyBills}</MenuItem>
            </LinkContainer>
            {props.isAutoPayEnabled && (
                <LinkContainer to={`/${merchantName}/myaccount/billdetail`}>
                    <MenuItem>{Resources.ManageAutoPay}</MenuItem>
                </LinkContainer>
            )}
            <LinkContainer to={`/${merchantName}/myaccount/transactions`}>
                <MenuItem>{Resources.PaymentHistory}</MenuItem>
            </LinkContainer>
            <LinkContainer to={`/${merchantName}/myaccount/returntopayment`}>
                <MenuItem>{Resources.HeaderExitToPayment}</MenuItem>
            </LinkContainer>
            <MenuItem onClick={props.openManageFactors}>
                {Resources.ManageFactors}
            </MenuItem>
            <MenuItem onClick={props.openChangePassword}>
                {Resources.ChangePassword}
            </MenuItem>
            <MenuItem onClick={props.logout}>{Resources.Logout}</MenuItem>
        </NavDropdown>
    );
};

export default MyAccount;
