import React from "react";
import { Row, Col } from "react-bootstrap";
import { TextInput, SelectInput, CurrencyInput } from "../Input";
import * as Resources from "../../resources";
import { IBill, IBillType, ICustomField } from "../../types";
import { IFormError } from "../../types/IFormError";

export interface BillFormProps {
    bill: IBill;
    billType: IBillType;
    maxAmount: number;
    errors: IFormError;
    onChange: ChangeEventHandler;
    onError: ErrorEventHandler;
    onBlur: FocusEventHandler;
}

type ChangeEventHandler = (
    event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
) => void;
type FocusEventHandler = (
    event: React.FocusEvent<HTMLInputElement | HTMLSelectElement>
) => void;
type ErrorEventHandler = (fieldName: string, message: string) => void;

const getRegExp = (pattern: string): RegExp | null =>
    pattern ? new RegExp(pattern) : null;

const buildField = (
    customField: ICustomField,
    name: string,
    value: string,
    errorMessage: string | JSX.Element | null,
    onChange: ChangeEventHandler,
    onError: ErrorEventHandler,
    onBlur: FocusEventHandler
): JSX.Element | null => {
    if (!customField.label) return null;

    const inputDefaultMaxLength = 100;
    const maxLength = name.toLowerCase().includes("id")
        ? 50
        : inputDefaultMaxLength;
    const isDropdown = customField.options && customField.options.length;

    return isDropdown ? (
        <SelectInput
            {...customField}
            name={name}
            value={value}
            error={errorMessage}
            onChange={onChange}
            onError={onError}
            onBlur={onBlur}
            pattern={getRegExp(customField.pattern)}
        />
    ) : (
        <TextInput
            {...customField}
            name={name}
            value={value}
            error={errorMessage}
            onChange={onChange}
            onError={onError}
            onBlur={onBlur}
            pattern={getRegExp(customField.pattern)}
            maxLength={maxLength}
        />
    );
};

const buildCurrencyField = (
    customField: ICustomField,
    value: number,
    maxAmount: number,
    errorMessage: string | JSX.Element | null,
    onChange: ChangeEventHandler,
    onError: ErrorEventHandler,
    onBlur: FocusEventHandler
): JSX.Element => {
    const isDropdown = customField.options && customField.options.length;

    return isDropdown ? (
        <SelectInput
            {...customField}
            name="amount"
            value={value}
            error={errorMessage}
            onChange={onChange}
            onError={onError}
            onBlur={onBlur}
            pattern={getRegExp(customField.pattern)}
        />
    ) : (
        <CurrencyInput
            {...customField}
            name="amount"
            value={value}
            maxAmount={maxAmount}
            disableScrollStep={true}
            disableArrowStep={true}
            error={errorMessage}
            onChange={onChange}
            onError={onError}
            onBlur={onBlur}
            pattern={getRegExp(customField.pattern)}
        />
    );
};

export const BillForm = (props: BillFormProps): JSX.Element => {
    const { bill, billType, maxAmount, errors, onChange, onError, onBlur } =
        props;
    const { id1, id2, id3, id4, amount } = billType;
    const showCustom = isNaN(bill.amount);

    return (
        <Row>
            <Col xs={12}>
                <div>
                    {buildField(
                        id1,
                        "id1",
                        bill.id1,
                        errors.id1,
                        onChange,
                        onError,
                        onBlur
                    )}
                </div>
                <div>
                    {buildField(
                        id2,
                        "id2",
                        bill.id2,
                        errors.id2,
                        onChange,
                        onError,
                        onBlur
                    )}
                </div>
                <div>
                    {buildField(
                        id3,
                        "id3",
                        bill.id3,
                        errors.id3,
                        onChange,
                        onError,
                        onBlur
                    )}
                </div>
                <div>
                    {buildField(
                        id4,
                        "id4",
                        bill.id4,
                        errors.id4,
                        onChange,
                        onError,
                        onBlur
                    )}
                </div>
                <div>
                    {buildCurrencyField(
                        amount,
                        bill.amount,
                        maxAmount,
                        errors.amount,
                        onChange,
                        onError,
                        onBlur
                    )}
                </div>
                <div>
                    {showCustom && (
                        <CurrencyInput
                            label={Resources.LabelCustomAmount}
                            name="customAmount"
                            value={bill.customAmount}
                            maxAmount={maxAmount}
                            disableScrollStep={true}
                            disableArrowStep={true}
                            error={errors.customAmount}
                            onChange={onChange}
                            onError={onError}
                            onBlur={onBlur}
                            required
                        />
                    )}
                </div>
            </Col>
        </Row>
    );
};
