import React from "react";
import { Button, Col, Panel, Row, Well } from "react-bootstrap";
import { InjectedIntlProps, injectIntl } from "react-intl";
import {
    ButtonCancel,
    MessageMultiFactorChallengeInstructions,
} from "../../../resources";
import { IFactor, IUser } from "../../../types/IUser";
import MultiFactorButton from "./MultiFactorButton";

export interface IMultiFactorPanelProps extends InjectedIntlProps {
    factors: IFactor[];
    onClose: () => void;
    onVerifyFactor: (factorId: string) => void;
}

export interface IMultiFactorPanelState {
    authenticated: boolean;
}

class MultiFactorPanel extends React.Component<
    IMultiFactorPanelProps,
    IMultiFactorPanelState
> {
    constructor(props: any) {
        super(props);
        this.state = {
            authenticated: false,
        };
        this.handleClose = this.handleClose.bind(this);
    }

    handleClose = (event: any) => {
        event.preventDefault();
        this.props.onClose();
    };

    render() {
        const smsFactor = this.props.factors.find(
            (f) =>
                f.factorInfo.factorType === "sms" &&
                f.factorInfo.status === "ACTIVE"
        );
        const callFactor = this.props.factors.find(
            (f) =>
                f.factorInfo.factorType === "call" &&
                f.factorInfo.status === "ACTIVE"
        );
        const totpFactor = this.props.factors.find(
            (f) =>
                f.factorInfo.factorType === "token:software:totp" &&
                f.factorInfo.status === "ACTIVE"
        );

        const smsPhone = smsFactor
            ? smsFactor.factorInfo.profile.phoneNumber ||
              smsFactor.factorInfo.profile.mobilePhone ||
              ""
            : "";
        const callPhone = callFactor
            ? callFactor.factorInfo.profile.phoneNumber ||
              callFactor.factorInfo.profile.mobilePhone ||
              ""
            : "";

        return (
            <Panel>
                <Panel.Body>
                    <Row>
                        <Col xs={12}>
                            <Well>
                                {MessageMultiFactorChallengeInstructions}
                            </Well>
                        </Col>
                        <Col xs={12}>
                            {smsFactor && (
                                <MultiFactorButton
                                    factorId={smsFactor.factorId}
                                    value={`Text code to XXX-XXX-${smsPhone.slice(
                                        callPhone.length - 4
                                    )}`}
                                    onVerify={this.props.onVerifyFactor}
                                />
                            )}
                            {callFactor && (
                                <MultiFactorButton
                                    factorId={callFactor.factorId}
                                    value={`Call with code to XXX-XXX-${callPhone.slice(
                                        callPhone.length - 4
                                    )}`}
                                    onVerify={this.props.onVerifyFactor}
                                />
                            )}
                            {totpFactor && (
                                <MultiFactorButton
                                    factorId={totpFactor.factorId}
                                    value={`Enter code from authenticator`}
                                    onVerify={this.props.onVerifyFactor}
                                />
                            )}
                        </Col>
                        <Col xs={12}>
                            <div style={{ paddingTop: 8, paddingBottom: 8 }}>
                                <Button
                                    bsStyle="primary"
                                    onClick={this.handleClose}
                                    style={{ minWidth: "6.5em" }}
                                    block
                                >
                                    {ButtonCancel}
                                </Button>
                            </div>
                        </Col>
                    </Row>
                </Panel.Body>
            </Panel>
        );
    }
}

export default injectIntl(MultiFactorPanel);
