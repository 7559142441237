import React from "react";
import { connect, MapDispatchToPropsParam } from "react-redux";
import { RouteComponentProps } from "react-router-dom";
import {
    IApplicationState,
    IApplication,
    ISettings,
    IApplicationRoute,
    IBillDetail,
    IUser,
} from "../../types";
import { deleteCustomerBillDetail } from "../../actions/BillDetails";

interface ReturnToPaymentStateProps {
    billDetails: IBillDetail[];
    application: IApplication;
    settings: ISettings;
    user: IUser;
}

interface ReturnToPaymentDispatchProps {
    deleteCustomerBillDetail: typeof deleteCustomerBillDetail;
}

export type ReturnToPaymentProps = ReturnToPaymentStateProps &
    ReturnToPaymentDispatchProps &
    RouteComponentProps<IApplicationRoute>;

class ReturnToPayment extends React.Component<ReturnToPaymentProps> {
    constructor(props: any) {
        super(props);
    }

    public componentDidMount(): void {
        const { application, settings, billDetails } = this.props;

        if (this.props.user.isLoggedIn && billDetails) {
            if (
                billDetails.length > 0 &&
                billDetails.findIndex((b) => b.isNewRecord) >= 0
            ) {
                const billDetailsToRemove = billDetails.filter(
                    (billDetail) => billDetail.isNewRecord
                );
                this.props.deleteCustomerBillDetail(billDetailsToRemove);
            }
        }

        if (application.guid && application.expired) {
            const path = `/${settings.merchantName}/expired/${application.guid}`;
            this.props.history.push(path);
            return;
        }

        if (application.guid && application.canceled) {
            const path = `/${settings.merchantName}/canceled/${application.guid}`;
            this.props.history.push(path);
            return;
        }

        const path = application.guid
            ? `/${settings.merchantName}/bills/${application.guid}`
            : `/${settings.merchantName}/bills`;
        this.props.history.push(path);
        return;
    }

    render() {
        return <div></div>;
    }
}

export default connect(
    (state: IApplicationState) => ({
        billDetails: state.billDetails.customerBillDetails,
        application: state.application,
        settings: state.settings,
        user: state.user,
    }),
    {
        deleteCustomerBillDetail,
    }
)(ReturnToPayment);
