import {
    IApplication,
    IBill,
    IBillDetail,
    IBillDetailResponse,
    IBillDetails,
} from "../types";

export const getCustomerBillDetails = (
    merchantName: string,
    authToken: string
): Promise<IBillDetails> => {
    const baseUrl = process.env.REACT_APP_API_BASEURL;

    return fetch(
        `${baseUrl}/webapi/api/CustomerBillDetails/get?merchantName=${merchantName}`,
        {
            method: "POST",
            mode: baseUrl ? "cors" : "same-origin",
            headers: { "Content-Type": "application/json; charset=utf-8" },
            body: JSON.stringify({
                AuthToken: authToken,
            }),
        }
    )
        .then((response) => response.json() as Promise<IBillDetails>)
        .catch((error) => error);
};

export const setAutoPay = (
    application: IApplication,
    merchantName: string,
    billDetail: IBillDetail,
    merchantCustomerID: string
): Promise<IBillDetailResponse> => {
    const baseUrl = process.env.REACT_APP_API_BASEURL;

    return fetch(
        `${baseUrl}/webapi/api/CustomerBillDetails/SetAutopay?merchantName=${merchantName}`,
        {
            method: "POST",
            mode: baseUrl ? "cors" : "same-origin",
            headers: { "Content-Type": "application/json; charset=utf-8" },
            body: JSON.stringify({
                application: application,
                merchantCustomerID: merchantCustomerID,
                isAutopayEnabled: billDetail.isAutopayEnabled,
                billType: billDetail.billType,
                identifier1: billDetail.identifier1,
                identifier2: billDetail.identifier2,
                identifier3: billDetail.identifier3,
                identifier4: billDetail.identifier4,
                token: billDetail.token,
            }),
        }
    )
        .then((response) => response.json() as Promise<IBillDetails>)
        .catch((error) => error);
};

export const setBillNotification = (
    application: IApplication,
    merchantName: string,
    billDetail: IBillDetail,
    merchantCustomerID: string
): Promise<IBillDetailResponse> => {
    const baseUrl = process.env.REACT_APP_API_BASEURL;

    return fetch(
        `${baseUrl}/webapi/api/CustomerBillDetails/SetBillNotification?merchantName=${merchantName}`,
        {
            method: "POST",
            mode: baseUrl ? "cors" : "same-origin",
            headers: { "Content-Type": "application/json; charset=utf-8" },
            body: JSON.stringify({
                application: application,
                merchantCustomerID: merchantCustomerID,
                isNotificationEnabled: billDetail.isNotificationEnabled,
                billType: billDetail.billType,
                identifier1: billDetail.identifier1,
                identifier2: billDetail.identifier2,
                identifier3: billDetail.identifier3,
                identifier4: billDetail.identifier4,
            }),
        }
    )
        .then((response) => response.json() as Promise<IBillDetailResponse>)
        .catch((error) => error);
};

export function getAutopayTerms(
    application: IApplication,
    merchantName: string,
    templateId: number,
    methodId: number,
    languageId: number
): Promise<string> {
    const baseUrl = process.env.REACT_APP_API_BASEURL;

    return fetch(
        `${baseUrl}/webapi/api/PaymentTerms/Autopay?merchantName=${merchantName}&language=${languageId}&method=${methodId}&templateId=${templateId}`,
        {
            method: "POST",
            mode: baseUrl ? "cors" : "same-origin",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(application),
        }
    )
        .then((response) => response.json() as Promise<string>)
        .catch((error) => error);
}

export const generateBillDetailsPdf = (
    merchantName: string,
    billType: string,
    bill: IBill
): Promise<void> => {
    const baseUrl = process.env.REACT_APP_API_BASEURL;
    return new Promise<void>((resolve, reject) => {
        const url = `${baseUrl}/webapi/api/billTemplates?merchantName=${merchantName}&billType=${billType}&id1=${bill.id1}`;
        const newWindow = window.open(url, "_blank", "noopener,noreferrer");

        if (newWindow) {
            newWindow.opener = null;
        }
    });
};
