import React from "react";
import { FormattedMessage } from "react-intl";
import { Row, Col, Checkbox } from "react-bootstrap";
import { IPayment, IPaymentTerms } from "../../types";

export interface IAutopayTermsProps {
    payment: IPayment;
    locale: string;
    autoPayTerms: IPaymentTerms;
    handleTermsAcceptedChange: (event: any) => void;
}

class AutopayTerms extends React.Component<IAutopayTermsProps> {
    constructor(props: any) {
        super(props);

        this.handleChange = this.handleChange.bind(this);
        this.createMarkup = this.createMarkup.bind(this);
    }
    handleChange(event: any) {
        this.props.handleTermsAcceptedChange(event);
    }
    createMarkup() {
        const { autoPayTerms } = this.props;
        return { __html: autoPayTerms.terms };
    }
    render() {
        const accepted =
            this.props.autoPayTerms && this.props.autoPayTerms.accepted
                ? true
                : false;
        const labelTermsAgree = (
            <FormattedMessage
                id="autopay.terms.agree"
                defaultMessage="Yes, I agree to the above auto-pay terms."
            />
        );
        return (
            <div>
                <Row>
                    <Col xs={12}>
                        <div
                            key={this.props.locale}
                            dangerouslySetInnerHTML={this.createMarkup()}
                            style={{
                                border: "1px solid #e5e5e5",
                                maxHeight: "200px",
                                overflow: "auto",
                                padding: "10px",
                            }}
                        />
                    </Col>
                </Row>
                <Row>
                    <Col xs={12}>
                        <Checkbox
                            checked={accepted}
                            name="termsAccepted"
                            onChange={this.handleChange}
                        >
                            {labelTermsAgree}
                        </Checkbox>
                    </Col>
                </Row>
            </div>
        );
    }
}

export default AutopayTerms;
