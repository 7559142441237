import { Reducer } from 'redux';
import { IBillDetails, IBillDetail, IPaymentTerms } from '../types';
import { AUTOPAYTERMS_LOAD, AUTOPAYTERMS_LOAD_SUCCESS, AUTOPAYTERMS_LOAD_FAIL, AUTOPAYTERMS_UPDATE_ACCEPTED, AUTOPAYTERMS_UPDATE_CAPTCHA, AUTOPAYTERMS_RESET } from '../actionTypes/BillDetails';
import { BILLDETAILS_UPDATE, BILLDETAILS_CREATE, BILLDETAILS_LOAD, BILLDETAILS_LOAD_FAIL, BILLDETAILS_LOAD_SUCCESS, BillDetailActions, UpdateBillDetailAction, BILLDETAILS_DELETE } from '../actionTypes/BillDetails';
import { CONFIGURATION_LOAD_SUCCESS, CONFIGURATION_EBPP_LOAD_SUCCESS, CONFIGURATION_LOAD_FAIL, ReceiveConfigurationAction, ReceiveConfigurationEBPPAction,FailedConfigurationAction } from '../actionTypes/Configuration';
import { DeleteBillDetailAction, CreateBillDetailAction, ReceiveBillDetailAction, FailedBillDetailAction, RequestBillDetailAction } from '../actionTypes/BillDetails';
import { RequestAutoPayTermsAction, ReceiveAutoPayTermsAction, FailedAutoPayTermsAction, UpdateAcceptanceAutoPayTermsAction, CaptchaAutoPayTermsAction } from '../actionTypes/BillDetails'

export const initialState: IBillDetails = {
    customerBillDetails: [] as IBillDetail[],
    autoPayTerms: {} as IPaymentTerms,
    isSuccessful: false,
    isLoading: false,
    isLoaded: false,
    error: null,
    messages: []
};

const billDetailsLoadAction = (state: IBillDetails, action: RequestBillDetailAction): IBillDetails => {
    return {
        ...state
    };
};

const billDetailsLoadSuccessAction = (state: IBillDetails, action: ReceiveBillDetailAction): IBillDetails => {
    return {
        ...state,
        isSuccessful: true,
        customerBillDetails: action.data.map(b => {
            b.isNewRecord = false;
            return b;
        })
    };
};

const billDetailsLoadFailAction = (state: IBillDetails, action: FailedBillDetailAction): IBillDetails => {
    return {
        ...state,
        isSuccessful: false,
        messages: [],
        customerBillDetails: []
    };
};

const billDetailsCreateAction = (state: IBillDetails, action: CreateBillDetailAction): IBillDetails => {
    return {
        ...state,
        error: null,
        customerBillDetails: [
            ...state.customerBillDetails,
            ...action.data
        ]
    };
};

const billDetailsDeleteAction = (state: IBillDetails, action: DeleteBillDetailAction): IBillDetails => {
    return {
        ...state,
        error: null,
        customerBillDetails: state.customerBillDetails.filter(b => action.data.indexOf(b) < 0)
    };
};

const billDetailsUpdateAction = (state: IBillDetails, action: UpdateBillDetailAction): IBillDetails => {
    return {
        ...state,
        isSuccessful: true,
        customerBillDetails: [...state.customerBillDetails.map(b => b.identifier1 === action.data.identifier1 ? action.data : b)]
    };
};

const configurationLoadSuccessAction = (state: IBillDetails, action: ReceiveConfigurationAction): IBillDetails => {
    return {
        ...state,
        isLoading: false,
        isLoaded: true,
        error: null,
        customerBillDetails: action.data.billDetails.customerBillDetails || []
    };
};

const configurationEbppLoadSuccessAction = (state: IBillDetails, action: ReceiveConfigurationEBPPAction): IBillDetails => {
    return {
        ...state,
        isLoading: false,
        isLoaded: true,
        error: null,
        customerBillDetails: action.data.billDetails.customerBillDetails || []
    };
};

const configurationLoadFailAction = (state: IBillDetails, action: FailedConfigurationAction): IBillDetails => {
    return {
        ...state,
        isLoading: false,
        isLoaded: false,
        error: action.data
    };
};

const autoPayTermsLoadAction = (state: IBillDetails, action: RequestAutoPayTermsAction): IBillDetails => {
    return {
        ...state,
        autoPayTerms: { ...state.autoPayTerms, isLoaded: false, isLoading: true }
    };
};

const autoPayTermsLoadSuccessAction = (state: IBillDetails, action: ReceiveAutoPayTermsAction): IBillDetails => {
    return {
        ...state,
        autoPayTerms: { ...state.autoPayTerms, terms: action.data, isLoaded: true, isLoading: false }
    };
};

const autoPayTermsLoadFailAction = (state: IBillDetails, action: FailedAutoPayTermsAction): IBillDetails => {
    return {
        ...state,
        autoPayTerms: { ...state.autoPayTerms, error: action.data, isLoaded: false, isLoading: false }
    };
};

const autoPayTermsCaptchaAction = (state: IBillDetails, action: CaptchaAutoPayTermsAction): IBillDetails => {
    return {
        ...state,
        autoPayTerms: { ...state.autoPayTerms, captcha: action.data }
    };
};

const autoPayTermsAcceptAction = (state: IBillDetails, action: UpdateAcceptanceAutoPayTermsAction): IBillDetails => {
    return {
        ...state,
        autoPayTerms: { ...state.autoPayTerms, accepted: action.data }
    };
};

const autoPayTermsResetAction = (state: IBillDetails): IBillDetails => {
    return {
        ...state,
        autoPayTerms: {} as IPaymentTerms
    };
};

export const reducer: Reducer<IBillDetails> = (state: IBillDetails = initialState, action: BillDetailActions): IBillDetails => {
    switch (action.type) {
        case BILLDETAILS_LOAD: return billDetailsLoadAction(state, action);
        case BILLDETAILS_LOAD_SUCCESS: return billDetailsLoadSuccessAction(state, action);
        case BILLDETAILS_LOAD_FAIL: return billDetailsLoadFailAction(state, action);
        case BILLDETAILS_CREATE: return billDetailsCreateAction(state, action);  
        case BILLDETAILS_UPDATE: return billDetailsUpdateAction(state, action); 
        case BILLDETAILS_DELETE: return billDetailsDeleteAction(state, action);    
        case CONFIGURATION_LOAD_SUCCESS: return configurationLoadSuccessAction(state, action);
        case CONFIGURATION_EBPP_LOAD_SUCCESS: return configurationEbppLoadSuccessAction(state, action);
        case CONFIGURATION_LOAD_FAIL: return configurationLoadFailAction(state, action); 
        case AUTOPAYTERMS_LOAD: return autoPayTermsLoadAction(state, action);
        case AUTOPAYTERMS_LOAD_SUCCESS: return autoPayTermsLoadSuccessAction(state, action);
        case AUTOPAYTERMS_LOAD_FAIL: return autoPayTermsLoadFailAction(state, action);
        case AUTOPAYTERMS_UPDATE_CAPTCHA: return autoPayTermsCaptchaAction(state, action);
        case AUTOPAYTERMS_UPDATE_ACCEPTED: return autoPayTermsAcceptAction(state, action);
        case AUTOPAYTERMS_RESET: return autoPayTermsResetAction(state);
        default: return state;
    }
};