import React from "react";
import { Panel, Table } from "react-bootstrap";
import { BillTableHeader } from "./BillTableHeader";
import { BillTableRow } from "./BillTableRow";
import { IBillType, IBill, IInternationalization } from "../../types";
import { IFormError } from "../../types/IFormError";

export interface BillTableProps {
    intl: IInternationalization;
    billType: IBillType;
    bills: IBill[];
    isEBPP: boolean;
    showEdit: boolean;
    billHasImage: boolean;
    errors?: IFormError;
    maxPaymentAmount?: number;
    minPaymentAmount?: number;
    allowBillOverpayment?: boolean;
    allowBillAmountToBeEdited?: boolean;
    onOpenSampleImage?: (billId: string) => void;
    onDelete?: (billId: string) => void;
    onError?: (name: string, message: string) => void;
    saveBill?: (bill: IBill) => void;
}

const buildTableRowsForBills = (props: BillTableProps): JSX.Element[] => {
    return props.bills.map((bill: IBill, index: number) => {
        return (
            <BillTableRow
                key={`${props.billType.name}-tr-${index}`}
                bill={bill}
                billType={props.billType}
                index={index}
                isEBPP={props.isEBPP}
                showEdit={props.showEdit}
                billHasImage={props.billHasImage}
                onOpenSampleImage={props.onOpenSampleImage}
                onDelete={props.onDelete}
                onError={props.onError}
                errors={props.errors}
                saveBill={props.saveBill}
                maxPaymentAmount={props.maxPaymentAmount}
                minPaymentAmount={props.minPaymentAmount}
                allowBillOverpayment={props.allowBillOverpayment}
                allowBillAmountToBeEdited={props.allowBillAmountToBeEdited}
            />
        );
    });
};

export const BillTable = (props: BillTableProps): JSX.Element => {
    return (
        <Panel>
            <Panel.Heading>{props.billType.displayName}</Panel.Heading>
            <Table className="panel-heading" condensed bordered responsive>
                <thead>
                    <BillTableHeader
                        billType={props.billType}
                        billHasImage={props.billHasImage}
                        showEdit={props.showEdit}
                        allowBillAmountToBeEdited={
                            props.allowBillAmountToBeEdited!
                        }
                        isEBPP={props.isEBPP}
                    />
                </thead>
                <tbody>{buildTableRowsForBills(props)}</tbody>
            </Table>
        </Panel>
    );
};
