import { Reducer } from "redux";
import {
  CONFIGURATION_EBPP_LOAD_SUCCESS,
  ReceiveConfigurationEBPPAction,
} from "../actionTypes/Configuration";
import {
  EbppBillsActions,
  EBPP_BILLS_CLEAR,
  EBPP_BILLS_LOAD_FAIL,
  EBPP_BILLS_LOAD_SUCCESS,
  FailedEbppBillsAction,
  ReceiveEbppBillsAction,
} from "../actionTypes/EbppBills";
import { IBill, IMerchantBills } from "../types";

export const initialState: IMerchantBills = {
  isLoaded: false,
  isLoading: false,
  error: null,
  bills: [] as IBill[],
  shareTokenWithGroup: false,
  maxFuturePaymentDays: 0,
};

const ebppBillsLoadSuccessAction = (
  state: IMerchantBills,
  action: ReceiveEbppBillsAction
): IMerchantBills => {
  return {
    ...state,
    isLoading: false,
    isLoaded: true,
    error: null,
    bills: action.data || [],
  };
};

const ebppBillsLoadFailAction = (
  state: IMerchantBills,
  action: FailedEbppBillsAction
): IMerchantBills => {
  return {
    ...state,
    isLoading: false,
    isLoaded: false,
    error: action.data,
  };
};

const configurationLoadSuccessAction = (
  state: IMerchantBills,
  action: ReceiveConfigurationEBPPAction
): IMerchantBills => {
  return {
    ...state,
    isLoading: false,
    isLoaded: true,
    error: null,
    bills: action.data.bills.bills || [],
    shareTokenWithGroup: action.data.bills.shareTokenWithGroup,
    maxFuturePaymentDays: action.data.bills.maxFuturePaymentDays,
  };
};

export const reducer: Reducer<IMerchantBills> = (
  state: IMerchantBills = initialState,
  action: EbppBillsActions
) => {
  switch (action.type) {
    case EBPP_BILLS_LOAD_SUCCESS:
      return ebppBillsLoadSuccessAction(state, action);
    case EBPP_BILLS_LOAD_FAIL:
      return ebppBillsLoadFailAction(state, action);
    case CONFIGURATION_EBPP_LOAD_SUCCESS:
      return configurationLoadSuccessAction(state, action);
    case EBPP_BILLS_CLEAR:
      return { ...initialState };
    default:
      return state;
  }
};
