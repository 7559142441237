import React from "react";
import { NumberInput } from "./NumberInput";

export enum RoutingNumberInputPropsEnum {
    onBlur,
    onError,
    onChange,
}
export class RoutingNumberInput extends React.Component<any, any> {
    constructor(props: any) {
        super(props);

        this.handleChange = this.handleChange.bind(this);
        this.handleBlur = this.handleBlur.bind(this);
        this.handleError = this.handleError.bind(this);
    }

    isValid(number: any) {
        if (number.length !== 9) {
            return false;
        }
        if (number === "000000000") {
            return false;
        }
        if (!new RegExp("^[0-9]{9}$").test(number)) {
            return false;
        }

        const checksumTotal =
            7 *
                (parseInt(number.charAt(0), 10) +
                    parseInt(number.charAt(3), 10) +
                    parseInt(number.charAt(6), 10)) +
            3 *
                (parseInt(number.charAt(1), 10) +
                    parseInt(number.charAt(4), 10) +
                    parseInt(number.charAt(7), 10)) +
            9 *
                (parseInt(number.charAt(2), 10) +
                    parseInt(number.charAt(5), 10) +
                    parseInt(number.charAt(8), 10));
        const checksumMod = checksumTotal % 10;
        return checksumMod === 0;
    }

    CheckFieldValidity(element: any) {
        if (this.isValid(element.value)) {
            element.setCustomValidity("");
        } else {
            element.setCustomValidity("form.error.routingNumber");
        }
    }

    handleError(name: any, message: any) {
        if (this.props.onError) {
            this.props.onError(name, message);
        }
    }

    handleBlur(event: any) {
        if (event.target.value) {
            this.CheckFieldValidity(event.target);
        }
        if (this.props.onBlur) {
            this.props.onBlur(event);
        }
    }

    handleChange(event: any) {
        if (!event.target.validity.valid) {
            this.CheckFieldValidity(event.target);
        }
        if (this.props.onChange) {
            this.props.onChange(event);
        }
    }

    render() {
        const props: any = Object.assign({}, this.props);
        Object.keys(this.props).forEach((key) => {
            if (key in RoutingNumberInputPropsEnum) {
                delete props[key];
            }
        });

        return (
            <NumberInput
                {...props}
                type="tel"
                onChange={this.handleChange}
                onBlur={this.handleBlur}
                onError={this.handleError}
            />
        );
    }
}
