import React from "react";
import { FormattedNumber } from "react-intl";
import { IBill } from "../../types";
import { totalBillAmount } from "../../utils/BollettaMath";

const BillTotal = ({ bills }: { bills: IBill[] }) => {
    const amount = totalBillAmount(bills);

    return amount > 0 ? (
        <span className="small">
            (<FormattedNumber value={amount} style="currency" currency="USD" />)
        </span>
    ) : (
        <span></span>
    );
};

export default BillTotal;
