import { IApplicationAnalytics } from '../types';

export const initialState: IApplicationAnalytics = {
    loadCount: 0
};

export function reducer(state: IApplicationAnalytics = initialState, action = {} as any) {
    const LOAD = new RegExp(/\/LOAD$/g);
    const LOAD_SUCCESS = new RegExp(/LOAD_SUCCESS$/g);
    const LOAD_FAIL = new RegExp(/LOAD_FAIL/g);
    const { loadCount } = state;
    if (LOAD.test(action.type)) {
        return {
            ...state,
            loadCount: loadCount + 1
        };
    } else if (LOAD_SUCCESS.test(action.type) || LOAD_FAIL.test(action.type)) {
        return {
            ...state,
            loadCount: Math.max(loadCount - 1, 0)
        };
    }
    return state;
}