import { IAccount, IAccounts, IAccountResponse } from '../types';
import { LogoutUserAction } from './User';
import { ConfigurationActions } from '../actionTypes/Configuration';

export const ACCOUNTS_LOAD = "redux/accounts/LOAD";
export const ACCOUNTS_LOAD_SUCCESS = "redux/accounts/LOAD_SUCCESS";
export const ACCOUNTS_LOAD_FAIL = "redux/accounts/LOAD_FAIL";
export const ACCOUNTS_CREATE = "redux/accounts/CREATE";
export const ACCOUNTS_UPDATE = "redux/accounts/UPDATE";
export const ACCOUNTS_DELETE = "redux/accounts/DELETE";
export const ACCOUNT_SETASDEFAULT_SUCCESS = 'redux/accounts/SETDEFAULTSUCCESS';
export const ACCOUNT_SETASDEFAULT_FAIL = 'redux/accounts/SETDEFAULTFAIL';

export type RequestAccountAction = {
    type: typeof ACCOUNTS_LOAD;
}

export type ReceiveAccountAction = {
    type: typeof ACCOUNTS_LOAD_SUCCESS;
    data: IAccounts;
}

export type FailedAccountAction = {
    type: typeof ACCOUNTS_LOAD_FAIL;
    data: string;
}

export type CreateAccountAction = {
    type: typeof ACCOUNTS_CREATE;
    data: IAccount;
}

export type UpdateAccountAction = {
    type: typeof ACCOUNTS_UPDATE;
    data: IAccountResponse;
}

export type DeleteAccountAction = {
    type: typeof ACCOUNTS_DELETE;
    data: IAccountResponse;
}

export type SetDefaultAccountSuccessAction = {
    type: typeof ACCOUNT_SETASDEFAULT_SUCCESS;
    data: string;
}

export type SetDefaultAccountFailedAction = {
    type: typeof ACCOUNT_SETASDEFAULT_FAIL;
    data: string;
}

export type AccountActions = RequestAccountAction | ReceiveAccountAction | FailedAccountAction | 
    LogoutUserAction | CreateAccountAction | UpdateAccountAction | DeleteAccountAction | SetDefaultAccountSuccessAction | SetDefaultAccountFailedAction | ConfigurationActions;