import React from "react";
import { connect } from "react-redux";
import { loadBillImage } from "../../actions/Bill";
import { loadReceipt } from "../../actions/Receipt";
import { loadPaymentTerms } from "../../actions/PaymentTerms";
import { clearEbppBills } from "../../actions/EbppBill";
import { setIsPaymentCompleted } from "../../actions/Application";

import { cancel, clear } from "../../actions/Common";
import ReceiptPanel from "./ReceiptPanel";
import {
    IAccount,
    IApplication,
    IApplicationRoute,
    IApplicationState,
    IGeneratedReceiptResponse,
    IInternationalization,
} from "../../types";
import { IPaymentTerms, IReceipt, ISettings, IUser } from "../../types";
import { RouteComponentProps } from "react-router-dom";
import { generate, refresh } from "../../actions/Receipt";

function GetMethodId(method: any) {
    switch (method) {
        case "credit":
            return 1;
        case "debit":
            return 9;
        case "checking":
            return 8;
        default:
            return 0;
    }
}

export type ReceiptStateProps = {
    intl: IInternationalization;
    application: IApplication;
    settings: ISettings;
    user: IUser;
    userAccounts: IAccount[];
    receipt: IReceipt;
    paymentTerms: IPaymentTerms;
};

export type ReceiptDispatchProps = {
    loadReceipt: (transactionId: number, languageId: number) => void;
    loadPaymentTerms: (
        application: IApplication,
        merchantName: string,
        templateId: number,
        methodId: number,
        languageId: number,
        feeAmount: number
    ) => void;
    clear: VoidFunction;
    cancel: VoidFunction;
    loadBillImage: (
        merchantName: string,
        billId: string,
        application: IApplication
    ) => void;
    clearEbppBills: VoidFunction;
    setIsPaymentCompleted: (isPaymentCompleted: boolean) => void;
    generate: (
        application: IApplication,
        user: IUser,
        param: number,
        locale: string,
        onError: (text: string) => void,
        onSuccess: (result: IGeneratedReceiptResponse) => void
    ) => void;
    refresh: (location: string) => void;
};

export type ReceiptProps = ReceiptStateProps &
    ReceiptDispatchProps &
    RouteComponentProps<IApplicationRoute>;

class ReceiptPage extends React.Component<ReceiptProps> {
    render() {
        const {
            receipt,
            match: {
                params: { transactionId },
            },
        } = this.props;
        const paymentTerms =
            receipt.paymentTerms || this.props.paymentTerms.terms;
        return (
            <div className="margin-top-20">
                <ReceiptPanel
                    {...this.props}
                    application={this.props.application}
                    settings={this.props.settings}
                    isWebReceipt={transactionId ? true : false}
                    billTypes={receipt.billTypes}
                    bills={receipt.bills}
                    payor={receipt.payor}
                    payment={receipt.payment}
                    transaction={receipt.transaction}
                    paymentTerms={paymentTerms}
                    userAccounts={this.props.userAccounts}
                    setIsPaymentCompleted={this.props.setIsPaymentCompleted}
                />
            </div>
        );
    }
}

export default connect(
    (state: IApplicationState) => ({
        intl: state.intl,
        application: state.application,
        settings: state.settings,
        user: state.user,
        userAccounts: state.userAccounts.accounts,
        receipt: state.receipt,
        paymentTerms: state.paymentTerms,
    }),
    {
        loadReceipt,
        loadPaymentTerms,
        clear,
        cancel,
        loadBillImage,
        clearEbppBills,
        setIsPaymentCompleted,
        generate,
        refresh,
    }
)(ReceiptPage);
