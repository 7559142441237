import React from "react";

import { Button } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";

export interface GridRowDeleteButtonProps {
    billId: string;
    onDelete?: (billId: string) => void;
}

const onDelete =
    (props: GridRowDeleteButtonProps) =>
    (event: React.MouseEvent<Button>): void =>
        props.onDelete && props.onDelete(props.billId);

export const GridRowDeleteButton = (
    props: GridRowDeleteButtonProps
): JSX.Element => {
    return (
        <td>
            <Button bsStyle="danger" bsSize="xsmall" onClick={onDelete(props)}>
                <FontAwesomeIcon icon={faTimes} />
            </Button>
        </td>
    );
};
