import { sumBy } from "lodash";
import { IGenericBill } from "../types/IBillRecord";
import { isNullOrUndefined } from "./Utilities";

export const totalBillAmount = (bills: IGenericBill[]): number => {
  return Number(
    sumBy(bills, (o: IGenericBill) =>
      Number(
        o.customAmount ||
          (!isNullOrUndefined(o.amountDue) ? o.amountDue! : o.amount)
      )
    ).toFixed(2)
  );
};

export const totalBillAmountEBPP = (bills: IGenericBill[]): number => {
  return Number(
    sumBy(bills, (o: IGenericBill) => {
      return o.amountDue !== undefined
        ? Number(o.amountDue)
        : Number(o.customAmount || o.amount);
    }).toFixed(2)
  );
};
