import React from "react";
import * as Resources from "../../../resources";
import { connect } from "react-redux";
import { matchPath, RouteComponentProps } from "react-router-dom";
import {
    IApplicationRoute,
    IApplicationState,
    ISettings,
} from "../../../types";
import { NavItem } from "react-bootstrap";
interface IMappedFromStateProps {
    billsAny: boolean;
    settings: ISettings;
}
type IComponentProps = IMappedFromStateProps &
    RouteComponentProps<IApplicationRoute>;
interface LayoutRouteProps extends IApplicationRoute {
    page: string;
}
class ShoppingCartNavigationLink extends React.Component<IComponentProps> {
    constructor(props: IComponentProps) {
        super(props);
    }
    navigateToShoppingCart = () => {
        const { history } = this.props;
        const { merchantName } = this.props.settings;
        history.push(`/${merchantName}/bills`);
    };
    render() {
        const currentPage = this.getRouteProps(this.props.location.pathname);
        const currentPageLowerCase = currentPage.page
            ? currentPage.page.toLowerCase()
            : "";
        const showShoppingCartNavigationLink =
            this.props.billsAny &&
            (currentPageLowerCase == "search" ||
                currentPageLowerCase == "search-result");

        if (showShoppingCartNavigationLink) {
            return (
                <NavItem
                    onClick={this.navigateToShoppingCart}
                    href="javascript:void(0)"
                >
                    {Resources.BillSearchNavbarCart}
                </NavItem>
            );
        } else {
            return null;
        }
    }

    private getRouteProps = (path: string): LayoutRouteProps => {
        const pathToMatch = path.includes("CredentialManager")
            ? "/:merchantName?/:page?/:passwordResetToken?/:guid?"
            : "/:merchantName?/:page?/:guid?/:transactionId?";

        const match = matchPath<LayoutRouteProps>(path, {
            path: pathToMatch,
            exact: false,
            strict: false,
        });

        return match ? match.params : ({} as LayoutRouteProps);
    };
}

const mapStateToProps = (state: IApplicationState) => ({
    billsAny: state.bills.bills.length > 0,
    settings: state.settings,
});

export default connect(mapStateToProps)(ShoppingCartNavigationLink);
