import React from "react";
import { IntlProvider, InjectedIntlProps } from "react-intl";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExclamationTriangle } from "@fortawesome/free-solid-svg-icons";
import {
    Row,
    Col,
    Form,
    Modal,
    Panel,
    Button,
    Glyphicon,
    Alert,
} from "react-bootstrap";
import { injectIntl } from "react-intl";
import { PasswordInput } from "../../Input";
import { CheckFieldValidity } from "../../../utils/Validation";
import * as Resources from "../../../resources";
import { IUser, IProfile, IKeyValue } from "../../../types";

const number = new RegExp(/\d{1}/);
const lower = new RegExp(/[a-z]{1}/);
const upper = new RegExp(/[A-Z]{1}/);
const special = new RegExp(/[^a-zA-Z0-9\s]{1}/);
const whitespace = new RegExp(/\s{1}/);

export interface ChangePasswordModalProps extends InjectedIntlProps {
    locale: string;
    localeTranslations: IKeyValue;
    merchantCustomerId: string;
    authToken: string;
    profile: IProfile;
    show: boolean;
    onClose: () => void;
    updateUserProfile: (
        merchantCustomerId: string,
        authToken: string,
        profile: IProfile,
        password: string,
        currentPassword: string
    ) => Promise<IUser>;
}

class ChangePasswordModal extends React.Component<
    ChangePasswordModalProps,
    any
> {
    public static defaultProps: Partial<any> = {
        show: false,
    };

    constructor(props: any) {
        super(props);
        this.state = {
            data: {
                current: "",
                password: "",
                verifyPassword: "",
            },
            submitting: false,
            errors: [],
        };
        this.handleContinue = this.handleContinue.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleError = this.handleError.bind(this);
        this.handleOnClose = this.handleOnClose.bind(this);
        this.disableContinue = this.disableContinue.bind(this);
        this.passwordMatchTest = this.passwordMatchTest.bind(this);
        this.clearFormData = this.clearFormData.bind(this);
    }
    handleChange(event: any) {
        if (event.target.validity.valid) {
            CheckFieldValidity(event.target, this.handleError);
        }
        return this.setState({
            data: {
                ...this.state.data,
                [event.target.name]: event.target.value,
            },
        });
    }
    handleError() {
        //This method did not exist but needed to be stubbed out to support call from the 'handleChange function above.
    }
    passwordMatchTest() {
        const {
            data: { password, verifyPassword },
        } = this.state;
        if (!password) {
            return false;
        }
        if (!verifyPassword) {
            return false;
        }
        return password === verifyPassword;
    }
    handleOnClose(event: any) {
        this.clearFormData();

        if (this.props.onClose) {
            this.props.onClose();
        }
    }
    clearFormData() {
        this.setState({
            error: "",
            data: {
                current: "",
                password: "",
                verifyPassword: "",
            },
        });
    }
    disableContinue() {
        const { data } = this.state;
        if (data.password.length < 9) {
            return true;
        }
        if (data.current.length < 9) {
            return true;
        }
        if (!lower.test(data.password)) {
            return true;
        }
        if (!upper.test(data.password)) {
            return true;
        }
        if (!number.test(data.password)) {
            return true;
        }
        if (!special.test(data.password)) {
            return true;
        }
        if (whitespace.test(data.password)) {
            return true;
        }
        if (!this.passwordMatchTest()) {
            return true;
        }
        return false;
    }
    handleContinue(event: any) {
        const { onClose } = this.props;
        const { data } = this.state;
        this.props
            .updateUserProfile(
                this.props.merchantCustomerId,
                this.props.authToken,
                this.props.profile,
                data.password,
                data.current
            )
            .then((result: any) => {
                if (result.isSuccessful) {
                    // updateUser(result);
                    this.clearFormData();
                    if (onClose) {
                        onClose();
                    }
                } else {
                    this.setState({
                        submitting: false,
                        error:
                            result.messages[0] ||
                            "There was an error processing your request, please try again later.",
                    });
                }
            })
            .catch((reason: any) => {
                //this.recaptcha.reset();
                this.setState({
                    submitting: false,
                    error: "There was an error processing your request, please try again later.",
                });
            });
        this.setState({
            submitting: true,
        });
    }
    render() {
        const {
            show,
            intl: { formatMessage },
        } = this.props;
        const { data, submitting, errors, error } = this.state;
        const success = (
            <span className="text-success">
                <Glyphicon glyph="check" />
            </span>
        );
        const danger = (
            <span className="text-danger">
                <Glyphicon glyph="unchecked" />
            </span>
        );
        const disableContinue = this.disableContinue();
        return (
            <IntlProvider
                locale={this.props.locale}
                messages={this.props.localeTranslations}
            >
                <Modal show={show} onHide={this.handleOnClose}>
                    <Modal.Header closeButton>
                        <Modal.Title>Change Password</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Panel>
                            <Panel.Body>
                                <Row>
                                    <Col xs={12} sm={12} md={12} lg={12}>
                                        <Form id="login-form">
                                            <Row>
                                                <Col xs={12}>
                                                    <PasswordInput
                                                        name="current"
                                                        placeholder={formatMessage(
                                                            {
                                                                id: "message.currentPassword",
                                                            }
                                                        )}
                                                        label={
                                                            Resources.CurrentPassword
                                                        }
                                                        value={data.current}
                                                        error={errors.current}
                                                        onChange={
                                                            this.handleChange
                                                        }
                                                        required
                                                    />
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col xs={12}>
                                                    <PasswordInput
                                                        name="password"
                                                        placeholder={formatMessage(
                                                            {
                                                                id: "message.newPassword",
                                                            }
                                                        )}
                                                        label={
                                                            Resources.NewPassword
                                                        }
                                                        value={data.password}
                                                        error={errors.password}
                                                        onChange={
                                                            this.handleChange
                                                        }
                                                        required
                                                    />
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col xs={12}>
                                                    <PasswordInput
                                                        name="verifyPassword"
                                                        placeholder={formatMessage(
                                                            {
                                                                id: "message.retypePassword",
                                                            }
                                                        )}
                                                        label={
                                                            Resources.RetypePassword
                                                        }
                                                        value={
                                                            data.verifyPassword
                                                        }
                                                        error={
                                                            errors.verifyPassword
                                                        }
                                                        onChange={
                                                            this.handleChange
                                                        }
                                                        required
                                                    />
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col xs={12}>
                                                    <ul>
                                                        <li>
                                                            {data.password
                                                                .length >= 9
                                                                ? success
                                                                : danger}{" "}
                                                            {
                                                                Resources.PasswordLength
                                                            }
                                                        </li>
                                                        <li>
                                                            {lower.test(
                                                                data.password
                                                            )
                                                                ? success
                                                                : danger}{" "}
                                                            {
                                                                Resources.PasswordLower
                                                            }
                                                        </li>
                                                        <li>
                                                            {upper.test(
                                                                data.password
                                                            )
                                                                ? success
                                                                : danger}{" "}
                                                            {
                                                                Resources.PasswordUpper
                                                            }
                                                        </li>
                                                        <li>
                                                            {number.test(
                                                                data.password
                                                            )
                                                                ? success
                                                                : danger}{" "}
                                                            {
                                                                Resources.PasswordNumber
                                                            }
                                                        </li>
                                                        <li>
                                                            {special.test(
                                                                data.password
                                                            )
                                                                ? success
                                                                : danger}{" "}
                                                            {
                                                                Resources.PasswordSpecial
                                                            }
                                                        </li>
                                                        <li>
                                                            {whitespace.test(
                                                                data.password
                                                            )
                                                                ? danger
                                                                : success}{" "}
                                                            {
                                                                Resources.PasswordWhiteSpace
                                                            }
                                                        </li>
                                                        <li>
                                                            {this.passwordMatchTest()
                                                                ? success
                                                                : danger}{" "}
                                                            {
                                                                Resources.PasswordMatch
                                                            }
                                                        </li>
                                                    </ul>
                                                </Col>
                                            </Row>
                                            {error && (
                                                <Row>
                                                    <Col
                                                        xs={12}
                                                        sm={12}
                                                        md={12}
                                                    >
                                                        <Alert bsStyle="danger">
                                                            <FontAwesomeIcon
                                                                icon={
                                                                    faExclamationTriangle
                                                                }
                                                            />{" "}
                                                            {error}
                                                        </Alert>
                                                    </Col>
                                                </Row>
                                            )}
                                            <Row>
                                                <Col xs={12}>
                                                    <Button
                                                        bsStyle="primary"
                                                        onClick={
                                                            this.handleContinue
                                                        }
                                                        disabled={
                                                            disableContinue
                                                        }
                                                        style={{
                                                            minWidth: "6.5em",
                                                        }}
                                                        block
                                                    >
                                                        Change Password
                                                    </Button>
                                                </Col>
                                            </Row>
                                        </Form>
                                    </Col>
                                </Row>
                            </Panel.Body>
                        </Panel>
                    </Modal.Body>
                </Modal>
            </IntlProvider>
        );
    }
}

export default injectIntl(ChangePasswordModal);
