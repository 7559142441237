import React from "react";
import { connect, MapDispatchToPropsParam } from "react-redux";
import PayorForm from "../Payor/PayorForm";
import { RouteComponentProps } from "react-router-dom";
import { updateProfile, updateUser } from "../../actions/User";
import { cancel } from "../../actions/Common";
import {
    IApplicationState,
    IApplication,
    IMyAccount,
    IBill,
    IPayor,
    IUser,
    ISettings,
    IApplicationRoute,
} from "../../types";

interface ProfileStateProps {
    application: IApplication;
    settings: ISettings;
    myAccount: IMyAccount;
    bills: IBill[];
    payor: IPayor;
    user: IUser;
}

interface ProfileDispatchProps {
    savePayor: typeof updateUser;
    cancel: typeof cancel;
    updateProfile: typeof updateProfile;
}

export type ProfileProps = ProfileStateProps &
    ProfileDispatchProps &
    RouteComponentProps<IApplicationRoute>;

class ManageProfile extends React.Component<ProfileProps> {
    constructor(props: any) {
        super(props);
    }

    render() {
        const {
            payor,
            user: { profile },
        } = this.props;
        const userProfile = { ...payor, ...profile };
        return (
            <div className="margin-top-20">
                <PayorForm {...this.props} initialPayor={userProfile} />
            </div>
        );
    }
}

export default connect(
    (state: IApplicationState) => ({
        application: state.application,
        settings: state.settings,
        myAccount: state.myAccount,
        bills: state.bills.bills,
        payor: state.payor,
        user: state.user,
    }),
    {
        savePayor: updateUser,
        updateProfile,
        cancel,
    }
)(ManageProfile);
