import {
  GetCardLast4,
  GetCardName,
  GetPaymentMethod,
} from "../utils/CardTypes";
import { GetAccountType } from "../utils/BillHelpers";

export function GetDataPostData(
  application: any,
  payor: any,
  payment: any,
  transaction: any
) {
  let data: any;
  if (application.securePayPaymentType === 3) {
    data = {
      GUID: application.guid,
      Successful: true,
      RealtimePayment: true,
      TransactionID: 0,
      Token: payment.token,
      FirstName: payment.firstName,
      MiddleInitial: payment.middleName ? payment.middleName.substr(0, 1) : "",
      MiddleName: payment.middleName,
      LastName: payment.lastName,
      Address: payment.address,
      City: payment.city,
      State: payment.state,
      Zip: payment.postal,
      Country: payment.country,
      Phone: payment.phone,
      PhoneCountry: payment.phoneCountry,
      Email: payment.email,
      DataPostMode: "UserAndData",
    };

    data.PaymentMethod = GetPaymentMethod(payment.paymentMethod);
  } else {
    data = {
      GUID: application.guid,
      Successful: true,
      RealtimePayment: true,
      TransactionID: transaction.transactionId,
      TransactionDateTime: transaction.transactionDateUTC,
      Token: transaction.token,
      BaseAmount: transaction.amount,
      FeeAmount: transaction.feeAmount,
      TotalAmount: transaction.amount + transaction.feeAmount,
      FirstName: payor.firstName,
      MiddleInitial: payor.middleName ? payor.middleName.substr(0, 1) : "",
      MiddleName: payor.middleName,
      LastName: payor.lastName,
      Address: payor.address,
      City: payor.city,
      State: payor.state,
      Zip: payor.postal,
      Country: payor.country,
      Phone: payor.phone,
      PhoneCountry: payor.phoneCountry,
      Email: payor.email,
      DataPostMode: "UserAndData",
    };

    data.PaymentMethod = GetCardName(payment.paymentMethod);
  }
  switch (payment.method) {
    case "credit":
      data.AccountType = "Credit Card";
      data.AccountLastFour = GetCardLast4(payment.cardNumber);
      break;
    case "debit":
      data.AccountType = "Debit Card";
      data.AccountLastFour = GetCardLast4(payment.cardNumber);
      break;
    case "checking":
      data.AccountType = GetAccountType(payment.accountType);
      data.AccountLastFour = GetCardLast4(payment.accountNumber);
      break;
    default:
      break;
  }

  if (payment.expirationMonth) {
    data.ExpirationMonth = payment.expirationMonth;
  }
  if (payment.expirationYear) {
    data.ExpirationYear = `${payment.expirationYear}`;
    data.ExpirationYearShort = `${payment.expirationYear - 2000}`;
  }
  if (payor != null && payor.business) {
    data.BusinessName = payor.business;
  }
  return data;
}
