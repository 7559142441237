import React from "react";
import classNames from "classnames";

export class RadioInput extends React.Component<any, any> {
    constructor(props: any) {
        super(props);

        this.handleChange = this.handleChange.bind(this);
    }

    handleChange(event: any) {
        if (this.props.onChange) {
            this.props.onChange(event);
        }
    }

    render() {
        const wrapperClass = classNames("form-group", {
            "has-error": this.props.error && this.props.error.length >= 0,
        });
        const radioButtons = this.props.options.map((option: any) => {
            return (
                <div key={option.value} className="radio">
                    <label>
                        <input
                            type="radio"
                            name={this.props.name}
                            value={option.value}
                            onChange={this.handleChange}
                            checked={this.props.value === option.value}
                        />
                        {option.text}
                    </label>
                </div>
            );
        });
        return (
            <div className={wrapperClass}>
                <div className="field">
                    {radioButtons}
                    {this.props.error && (
                        <div className="alert alert-danger">
                            {this.props.error}
                        </div>
                    )}
                </div>
            </div>
        );
    }
}
