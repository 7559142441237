import { IPhoneValidationResponse } from "../types";

export function validatePhone(
    country: string,
    phoneNumber: string
): Promise<IPhoneValidationResponse> {
    const baseUrl = process.env.REACT_APP_API_BASEURL;

    return fetch(`${baseUrl}/webapi/api/Phone`, {
        method: "POST",
        mode: baseUrl ? "cors" : "same-origin",
        headers: { "Content-Type": "application/json; charset=utf-8" },
        body: JSON.stringify({
            country: country,
            phoneNumber: phoneNumber,
        }),
    })
        .then(
            (response) => response.json() as Promise<IPhoneValidationResponse>
        )
        .catch((error) => error);
}
