import React from "react";
import {navigateToReceipt} from "../../utils/RouteUtils"
import { RouteComponentProps } from "react-router-dom";
import { connect, MapDispatchToPropsParam } from "react-redux";
import { loadUserAccounts } from "../../actions/Account";
import { loadCustomerBillDetails } from "../../actions/BillDetails";
import {
    setFutureDatedPayment,
    setIsPaymentCompleted,
} from "../../actions/Application";

import { createOrUpdate } from "../../actions/Payment";
import { reset } from "../../actions/PaymentTerms";
import { cancel } from "../../actions/Common";
import PaymentForm from "./PaymentForm";
import { LoginModal } from "../Layout/Header/LoginModal";
import { logout, updateUser, userVerified } from "../../actions/User";
import { login } from "../../api/User";

import { totalBillAmount } from "../../utils/BollettaMath";

import { IBillType } from "../../types";
import {
    IApplication,
    IApplicationRoute,
    IApplicationState,
} from "../../types";
import {
    IAccount,
    IBill,
    IBillDetail,
    IInternationalization,
    IMyAccount,
} from "../../types";
import { IPayment, IPayor, ISettings, IUser } from "../../types";

function InitializePayment(state: IApplicationState): IPayment | null {
    if (!state.settings) return null;

    const payment: IPayment = {
        ...state.payment,
    };

    payment.saveAccountInMyAccountWallet = state.user.isLoggedIn;
    if (state.user.isLoggedIn && state.userAccounts.accounts.length !== 0) {
        payment.method = "saved";
        const userAccount = state.userAccounts.accounts.find(
            (x: any) => x.isDefault
        );
        if (userAccount && !payment.customerAccountName) {
            payment.customerAccountName = userAccount.nickname;
            payment.firstName = userAccount.firstName;
            payment.middleName = userAccount.middleName;
            payment.lastName = userAccount.lastName;
            payment.address = userAccount.address;
            payment.country = userAccount.phoneCountry;
            payment.state = userAccount.state;
            payment.city = userAccount.city;
            payment.postal = userAccount.postal;
            payment.phone = userAccount.phone;
        }
    } else if (
        !state.settings.creditIsAccepted &&
        payment.method === "credit"
    ) {
        if (state.settings.debitIsAccepted) {
            payment.method = "debit";
        } else {
            payment.method = "checking";
        }
    } else if (!state.settings.debitIsAccepted && payment.method === "debit") {
        if (state.settings.creditIsAccepted) {
            payment.method = "credit";
        } else {
            payment.method = "checking";
        }
    } else if (!state.settings.achIsAccepted && payment.method === "checking") {
        if (state.settings.creditIsAccepted) {
            payment.method = "credit";
        } else {
            payment.method = "debit";
        }
    } else if (!payment.method) {
        if (
            state.settings.creditIsAccepted &&
            state.settings.defaultPaymentOption == "Credit"
        ) {
            payment.method = "credit";
        } else if (
            state.settings.debitIsAccepted &&
            state.settings.defaultPaymentOption == "Debit"
        ) {
            payment.method = "debit";
        } else if (
            state.settings.achIsAccepted &&
            state.settings.defaultPaymentOption == "ACH"
        ) {
            payment.method = "checking";
        } else {
            if (state.settings.creditIsAccepted) {
                payment.method = "credit";
            } else if (state.settings.debitIsAccepted) {
                payment.method = "debit";
            } else {
                payment.method = "checking";
            }
        }
    }

    return payment;
}

function InitilizePayor(
    state: IApplicationState,
    payment: IPayment | null
): IPayor {
    if (!payment) {
        return state.payor;
    }

    const payor = { ...state.payor };

    if (state.user.isLoggedIn && state.userAccounts.accounts.length !== 0) {
        const userAccount = state.userAccounts.accounts.find(
            (x: any) => x.isDefault
        );

        if (userAccount) {
            payor.firstName = userAccount.firstName;
            payor.middleName = userAccount.middleName;
            payor.lastName = userAccount.lastName;
            payor.address = userAccount.address;
            payor.country = userAccount.phoneCountry;
            payor.state = userAccount.state;
            payor.city = userAccount.city;
            payor.postal = userAccount.postal;
            payor.phone = userAccount.phone;
        }
    }

    return payor;
}

interface PaymentStateProps {
    bills: IBill[];
    intl: IInternationalization;
    application: IApplication;
    settings: ISettings;
    billTypes: IBillType[];
    billDetails: IBillDetail[];
    user: IUser;
    payor: IPayor;
    userAccounts: IAccount[];
    initialPayment: IPayment;
    myAccount: IMyAccount;
    maxFuturePaymentDays: number;
    isPaymentCompleted: boolean;
}

export interface IPaymentState {
    showLoginModal: boolean;
}

interface PaymentDispatchProps {
    cancel: typeof cancel;
    loadUserAccounts: typeof loadUserAccounts;
    loadCustomerBillDetails: typeof loadCustomerBillDetails;
    logout: typeof logout;
    setFutureDatedPayment: typeof setFutureDatedPayment;
    setIsPaymentCompleted: typeof setIsPaymentCompleted;
    updateUser: typeof updateUser;
    savePayment: typeof createOrUpdate;
    resetPaymentTerms: typeof reset;
    userVerified: typeof userVerified;
}

export type PaymentProps = PaymentStateProps &
    PaymentDispatchProps &
    RouteComponentProps<IApplicationRoute>;

class PaymentPage extends React.Component<PaymentProps, IPaymentState> {
    private billTotal = 0;

    constructor(props: any) {
        super(props);

        this.state = {
            showLoginModal: false,
        };
    }

    private showLoginModal = (): void => {
        this.setState({ showLoginModal: true });
    };

    private hideLoginModal = (): void => {
        this.setState({ showLoginModal: false });
    };

    private onLogin = (
        userName: string,
        password: string,
        recaptcha?: string | null
    ): Promise<IUser> => {
        return login(
            this.props.application,
            this.props.settings.merchantName,
            userName,
            password,
            recaptcha
        );
    };

    public componentDidMount(){
        const {isPaymentCompleted, history, settings: {merchantName}} = this.props;
        if (isPaymentCompleted) {
            navigateToReceipt(history,merchantName)
        }        
    }
    public render() {
        const {settings, isPaymentCompleted} = this.props;
        if (!settings || isPaymentCompleted) return null;
        const requirePasswordReset =
            this.props.application.isEBPP &&
            this.props.settings.enableMyAccount &&
            this.props.bills.length > 0 &&
            this.props.user.requirePasswordReset;
        return (
            <div className="margin-top-20">
                <PaymentForm
                    application={this.props.application}
                    settings={this.props.settings}
                    locale={this.props.intl.locale}
                    payment={this.props.initialPayment}
                    bills={this.props.bills}
                    billTypes={this.props.billTypes}
                    billTotal={totalBillAmount(this.props.bills) || 0}
                    billDetails={this.props.billDetails}
                    maxFuturePaymentDays={this.props.maxFuturePaymentDays}
                    payor={this.props.payor}
                    user={this.props.user}
                    myAccount={this.props.myAccount}
                    userAccounts={this.props.userAccounts}
                    history={this.props.history}
                    cancel={this.props.cancel}
                    savePayment={this.props.savePayment}
                    resetPaymentTerms={this.props.resetPaymentTerms}
                    loadUserAccounts={this.props.loadUserAccounts}
                    showLoginModal={this.showLoginModal}
                    setFutureDatedPayment={this.props.setFutureDatedPayment}
                    setIsPaymentCompleted={this.props.setIsPaymentCompleted}
                />
                {this.props.settings.enableMyAccount && (
                    <LoginModal
                        application={this.props.application}
                        merchantName={this.props.settings.merchantName}
                        allowAutoPay={this.props.settings.allowAutoBillPay}
                        requireRecaptcha={this.props.settings.requireReCaptcha}
                        requireLoginEBPP={
                            this.props.application.isEBPP &&
                            this.props.settings.requireLoginEBPP
                        }
                        requirePasswordReset={requirePasswordReset}
                        locale={this.props.intl.locale}
                        show={this.state.showLoginModal}
                        allowGuestLogin={true}
                        user={this.props.user}
                        onClose={this.hideLoginModal}
                        onContinue={this.hideLoginModal}
                        updateUser={this.props.updateUser}
                        userVerified={this.props.userVerified}
                        login={this.onLogin}
                        logout={this.props.logout}
                        loadUserAccounts={this.props.loadUserAccounts}
                        loadCustomerBillDetails={
                            this.props.loadCustomerBillDetails
                        }
                        webPaymentsCustomerAccountLoginMessage={
                            this.props.settings
                                .webPaymentsCustomerAccountLoginMessage
                        }
                    />
                )}
            </div>
        );
    }
}

export default connect(
    (state: IApplicationState) => {
        const payment = InitializePayment(state);
        const payor = InitilizePayor(state, payment);

        const billMaxFutureDays = state.bills.maxFuturePaymentDays;
        const merchantMaxFutureDays =
            state.settings.futureDatedPaymentsNumberOfDaysIntoFutureAllowed;
        const maxFuturePaymentDays =
            billMaxFutureDays && billMaxFutureDays < merchantMaxFutureDays
                ? billMaxFutureDays
                : merchantMaxFutureDays;

        return {
            intl: state.intl,
            application: state.application,
            settings: state.settings,
            bills: state.bills.bills,
            maxFuturePaymentDays: maxFuturePaymentDays,
            billDetails: state.billDetails.customerBillDetails,
            billTypes: state.billTypes.billTypes,
            payor: state.payor,
            user: state.user,
            userAccounts: state.userAccounts.accounts,
            initialPayment: payment,
            myAccount: state.myAccount,
            isPaymentCompleted: state.application.isPaymentCompleted,
        };
    },
    {
        savePayment: createOrUpdate,
        cancel,
        resetPaymentTerms: reset,
        setFutureDatedPayment,
        loadCustomerBillDetails,
        loadUserAccounts,
        updateUser,
        logout,
        userVerified,
        setIsPaymentCompleted
    }
)(PaymentPage);
