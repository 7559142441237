export const CLEAR = "redux/CLEAR";
export const CANCEL = "redux/CANCEL";

export type ClearAction = {
    type: typeof CLEAR;
}

export type CancelAction = {
    type: typeof CANCEL;
}

export type CommonActions = ClearAction | CancelAction;