import { Alert, Col, Row } from "react-bootstrap";
import { LabelConfigError, MessageConfigError } from "../../resources";
import { SelectInput, TextInput } from "../Input";

function GetRegExp(pattern: any) {
    if (pattern) {
        return new RegExp(pattern);
    }
    return null;
}
const SearchForm = ({
    criteria,
    billType,
    hasImage,
    errors,
    onChange,
    onError,
    onBlur,
}: {
    criteria: any;
    billType: any;
    hasImage: any;
    errors: any;
    onChange: any;
    onError: any;
    onBlur: any;
}) => {
    const { id1, id2, id3, id4 } = billType;
    const colSize = hasImage ? 12 : 6;
    const id1IsDropdown = id1.options && id1.options.length > 1;
    const id2IsDropdown = id2.options && id2.options.length > 1;
    const id3IsDropdown = id3.options && id3.options.length > 1;
    const id4IsDropdown = id4.options && id4.options.length > 1;
    const id1Input = id1IsDropdown ? (
        <SelectInput
            label={id1.label}
            name="id1"
            value={criteria.id1}
            error={errors.id1}
            onChange={onChange}
            onError={onError}
            onBlur={onBlur}
            options={id1.options}
            required={id1.isRequiredForSearch}
        />
    ) : (
        <TextInput
            label={id1.label}
            name="id1"
            value={criteria.id1}
            error={errors.id1}
            onChange={onChange}
            onError={onError}
            onBlur={onBlur}
            pattern={GetRegExp(id1.pattern)}
            required={id1.isRequiredForSearch}
        />
    );
    const id2Input = id2IsDropdown ? (
        <SelectInput
            label={id2.label}
            name="id2"
            value={criteria.id2}
            error={errors.id2}
            onChange={onChange}
            onError={onError}
            onBlur={onBlur}
            options={id2.options}
            required={id2.isRequiredForSearch}
        />
    ) : (
        <TextInput
            label={id2.label}
            name="id2"
            value={criteria.id2}
            error={errors.id2}
            onChange={onChange}
            onError={onError}
            onBlur={onBlur}
            pattern={GetRegExp(id2.pattern)}
            required={id2.isRequiredForSearch}
        />
    );
    const id3Input = id3IsDropdown ? (
        <SelectInput
            label={id3.label}
            name="id3"
            value={criteria.id3}
            error={errors.id3}
            onChange={onChange}
            onError={onError}
            onBlur={onBlur}
            options={id3.options}
            required={id3.isRequiredForSearch}
        />
    ) : (
        <TextInput
            label={id3.label}
            name="id3"
            value={criteria.id3}
            error={errors.id3}
            onChange={onChange}
            onError={onError}
            onBlur={onBlur}
            pattern={GetRegExp(id3.pattern)}
            required={id3.isRequiredForSearch}
        />
    );
    const id4Input = id4IsDropdown ? (
        <SelectInput
            label={id4.label}
            name="id4"
            value={criteria.id4}
            error={errors.id4}
            onChange={onChange}
            onError={onError}
            onBlur={onBlur}
            options={id4.options}
            required={id4.isRequiredForSearch}
        />
    ) : (
        <TextInput
            label={id4.label}
            name="id4"
            value={criteria.id4}
            error={errors.id4}
            onChange={onChange}
            onError={onError}
            onBlur={onBlur}
            pattern={GetRegExp(id4.pattern)}
            required={id4.isRequiredForSearch}
        />
    );
    const hasSearchable =
        id1.isSearchable ||
        id2.isSearchable ||
        id3.isSearchable ||
        id4.isSearchable;
    return (
        <div>
            {hasSearchable && (
                <div>
                    <Row>
                        <Col xs={12} sm={colSize}>
                            {id1.label && id1.isSearchable && id1Input}
                        </Col>
                        <Col xs={12} sm={colSize}>
                            {id2.label && id2.isSearchable && id2Input}
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={12} sm={colSize}>
                            {id3.label && id3.isSearchable && id3Input}
                        </Col>
                        <Col xs={12} sm={colSize}>
                            {id4.label && id4.isSearchable && id4Input}
                        </Col>
                    </Row>
                </div>
            )}
            {!hasSearchable && (
                <Row>
                    <Col xs={12} sm={colSize}>
                        <Alert bsStyle="danger">
                            <h4>{LabelConfigError}</h4>
                            <p>{MessageConfigError}</p>
                        </Alert>
                    </Col>
                </Row>
            )}
        </div>
    );
};

export default SearchForm;
