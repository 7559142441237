import React from "react";

import { Modal, ButtonToolbar, Button } from "react-bootstrap";
import * as Resources from "../../../resources";

export interface InformModalProps {
    headerText: string | JSX.Element;
    message: string | JSX.Element;
    show: boolean;
    onClose: () => void;
}

const handleCloseClick =
    (props: InformModalProps) => (event: React.MouseEvent<Button>) =>
        props.onClose();

export const InformModal = (props: InformModalProps) => {
    return (
        <Modal show={props.show} onHide={handleCloseClick(props)}>
            <Modal.Header closeButton>
                <Modal.Title>{props.headerText}</Modal.Title>
            </Modal.Header>
            <Modal.Body>{props.message}</Modal.Body>
            <Modal.Footer>
                <div className="pull-right">
                    <ButtonToolbar>
                        <Button
                            bsStyle="primary"
                            onClick={handleCloseClick(props)}
                            style={{ minWidth: "6.5em" }}
                        >
                            {Resources.ButtonClose}
                        </Button>
                    </ButtonToolbar>
                </div>
            </Modal.Footer>
        </Modal>
    );
};
