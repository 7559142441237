import { faExclamationTriangle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { History } from "history";
import * as _ from "lodash";
import React from "react";
import { Alert, Button, Col, Form, Row } from "react-bootstrap";
import { FormattedNumber, injectIntl, InjectedIntlProps } from "react-intl";
import { connect } from "react-redux";
import { updateCriteria } from "../../actions/BillSearch";
import * as Resources from "../../resources";
import { ModalTitleSampleBill } from "../../resources";
import {
    IApplication,
    IApplicationState,
    IBillRecord,
    IBillSearchCriteria,
    IBillSearchResults,
    IBillType,
    ISelectOption,
    ISettings,
    IUser,
} from "../../types";
import { IBill } from "../../types/IBill";
import { IFormError } from "../../types/IFormError";
import {
    ConfigureBillFromBillType,
    GetBillOptions,
} from "../../utils/BillHelpers";
import { totalBillAmount } from "../../utils/BollettaMath";
import { getLanguageCode } from "../../utils/Intl";
import { CheckFieldValidity, CheckFormValidity } from "../../utils/Validation";
import { SampleBill } from "../Bill/SampleBill";
import { BackgroundImage, CancelButton, SelectInput } from "../Input";
import BillTotal from "../Layout/BillTotal";
import { ButtonBar } from "../Layout/ButtonBar";
import { LoginModal } from "../Layout/Header/LoginModal";
import { InformModal } from "../Layout/Modals/InformModal";
import ResultsPanel from "./ResultsPanel";
import SearchForm from "./SearchForm";

export interface ISearchPanelOwnProps extends InjectedIntlProps {
    addToBills: (bill: IBill) => void;
    application: IApplication;
    bills: IBill[];
    billSearch: (
        merchantName: string,
        application: IApplication,
        billType: string,
        id1: string,
        id2: string,
        id3: string,
        id4: string,
        language: number,
        includePaidBills: boolean,
        address: string,
        postalCode: string,
        offset?: number,
        take?: number
    ) => void;
    billTypes: IBillType[];
    cancel: VoidFunction;
    deleteBill: (billId: string) => void;
    history: History;
    loadImage: (
        application: IApplication,
        merchantName: string,
        billName: string,
        language: number
    ) => void;
    loadUserAccounts: (
        application: IApplication,
        merchantName: string,
        authToken: string,
        onError?: (text: string) => void
    ) => void;
    loadCustomerBillDetails: (
        merchantName: string,
        authToken: string,
        merchantCustomerId: string
    ) => void;
    login: (
        userName: string,
        password: string,
        recaptcha?: string | null
    ) => Promise<IUser>;
    logout: VoidFunction;
    resetSearch: (criteria: IBillSearchCriteria) => void;
    searchResults: IBillSearchResults;
    settings: ISettings;
    updateUser: (user: IUser) => void;
    userVerified: (value: boolean) => void;
    user: IUser;
}

export interface ISearchPanelStateProps {
    criteria: IBillSearchCriteria;
}

export interface ISearchPanelDispatchProps {
    updateCriteria: (criteria: IBillSearchCriteria) => void;
}

export type ISearchPanelProps = ISearchPanelOwnProps &
    ISearchPanelStateProps &
    ISearchPanelDispatchProps;

export interface ISearchPanelState {
    panelExpanded: boolean;
    activeKey: "review" | "search";
    criteria: IBillSearchCriteria;
    //billTypes: IBillType;
    options: ISelectOption[];
    searchDisabled: boolean;
    showModal: boolean;
    showLoginModal: boolean;
    errors: IFormError;
    showMaxPaymentAmountExceeded: boolean;
}

class SearchPanel extends React.Component<
    ISearchPanelProps,
    ISearchPanelState
> {
    constructor(props: ISearchPanelProps) {
        super(props);
        const { billTypes } = props;
        const initialBill = ConfigureBillFromBillType(billTypes);
        this.state = {
            panelExpanded: true,
            activeKey:
                props.bills && props.bills.length > 0 ? "review" : "search",
            criteria: Object.assign({}, initialBill) as IBillSearchCriteria,
            //billTypes: billTypes,
            options: GetBillOptions(billTypes),
            searchDisabled: true,
            showModal: false,
            showLoginModal: false,
            errors: {},
            showMaxPaymentAmountExceeded: false,
        };
        this.handleBlur = this.handleBlur.bind(this);
        this.handleError = this.handleError.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleModalClose = this.handleModalClose.bind(this);
        this.handleMaxPaymentAmountExceededModalClose =
            this.handleMaxPaymentAmountExceededModalClose.bind(this);
        this.handleAddToBills = this.handleAddToBills.bind(this);
        this.handleSearchOnClick = this.handleSearchOnClick.bind(this);
        this.handleTabSelect = this.handleTabSelect.bind(this);
        this.handleBillTypeChange = this.handleBillTypeChange.bind(this);
        this.handleContinue = this.handleContinue.bind(this);
        this.handleAddAllBills = this.handleAddAllBills.bind(this);
        this.handleRemoveAllBills = this.handleRemoveAllBills.bind(this);
        this.disablePayButton = this.disablePayButton.bind(this);
        this.renderAlert = this.renderAlert.bind(this);
        this.createMarkup = this.createMarkup.bind(this);
        this.showLoginModal = this.showLoginModal.bind(this);
        this.hideLoginModal = this.hideLoginModal.bind(this);
        this.onContinueAfterLogin = this.onContinueAfterLogin.bind(this);
        this.getAmountFromBill = this.getAmountFromBill.bind(this);
    }
    componentDidMount() {
        const {
            intl: { locale },
            application,
            settings: { merchantName },
            loadImage,
        } = this.props;
        const { criteria } = this.props;
        if (criteria && criteria.billType) {
            loadImage(
                application,
                merchantName,
                criteria.billType,
                getLanguageCode(locale)
            );
        }

        this.props.updateCriteria(
            ConfigureBillFromBillType(
                this.props.billTypes
            ) as IBillSearchCriteria
        );
    }
    componentWillReceiveProps(nextProps: any) {
        if (this.props !== nextProps) {
            const { billTypes } = nextProps;
            this.setState({
                //billTypes: billTypes,
                options: GetBillOptions(billTypes),
            });
        }
    }
    handleModalClose(event: any) {
        const { bills } = this.props;
        if (bills.length > 0) {
            this.setState({
                showModal: false,
                activeKey: "review",
            });
        }
        this.setState({ searchDisabled: false });
        this.props.resetSearch(
            ConfigureBillFromBillType(
                this.props.billTypes
            ) as IBillSearchCriteria
        );
    }

    handleMaxPaymentAmountExceededModalClose() {
        this.setState({
            showMaxPaymentAmountExceeded: false,
        });
    }

    private showLoginModal(): void {
        this.setState({ showLoginModal: true });
    }

    private hideLoginModal(): void {
        this.setState({ showLoginModal: false });
    }
    handleAddToBills(row: IBillRecord) {
        const { settings, addToBills, bills } = this.props;
        const amount = this.getAmountFromBill(row);

        if (amount <= settings.maxPaymentAmount) {
            addToBills({
                id: row.id.toString(),
                billType: row.billType,
                id1: row.id1,
                id2: row.id2,
                id3: row.id3,
                id4: row.id4,
                amount: row.amount,
                customAmount: row.customAmount,
                purchaseOrder: row.purchaseOrder,
                isTaxExempt: row.isTaxExempt,
                taxAmount: row.taxAmount,
            } as IBill);

            const initialAmount = totalBillAmount(bills);
            const totalAmount = initialAmount + amount;

            if (settings.maxPaymentAmount < totalAmount) {
                this.setState({
                    showMaxPaymentAmountExceeded: true,
                });
            } else {
                if (!settings.allowMultipleBillPayments && row) {
                    this.props.settings.enableMyAccount &&
                    this.props.application.id === 33 &&
                    !(this.props.user && this.props.user.isLoggedIn)
                        ? this.showLoginModal()
                        : this.onContinueAfterLogin();
                }
            }
        }
    }

    handleAddAllBills() {
        const { addToBills, deleteBill, searchResults, settings, bills } =
            this.props;

        searchResults.bills.forEach((bill: IBillRecord) => {
            deleteBill(bill.id.toString());
        });

        searchResults.bills
            .filter((bill: IBillRecord) => bill.amountDue != 0)
            .forEach((bill: IBillRecord) => {
                addToBills({
                    id: bill.id.toString(),
                    billType: bill.billType,
                    id1: bill.id1,
                    id2: bill.id2,
                    id3: bill.id3,
                    id4: bill.id4,
                    amount: bill.amount,
                    customAmount: bill.customAmount,
                    purchaseOrder: bill.purchaseOrder,
                    isTaxExempt: bill.isTaxExempt,
                    taxAmount: bill.taxAmount,
                } as IBill);
            });

        const billsToAdd = searchResults.bills.filter(
            (searchResultBill: IBillRecord) =>
                !_.includes(
                    bills.map((bill: IBill) => bill.id.toString()),
                    searchResultBill.id.toString()
                )
        );
        const amountToAdd = _.sumBy(billsToAdd, (bill: IBillRecord) =>
            this.getAmountFromBill(bill)
        );
        const initialAmount = totalBillAmount(bills);
        const totalAmount = initialAmount + amountToAdd;

        if (settings.maxPaymentAmount < totalAmount) {
            this.setState({
                showMaxPaymentAmountExceeded: true,
            });
        }
    }

    handleRemoveAllBills() {
        const { deleteBill, searchResults } = this.props;

        searchResults.bills.forEach((bill: IBillRecord) => {
            deleteBill(bill.id.toString());
        });
    }

    private getAmountFromBill(bill: IBillRecord): number {
        return Number(bill.customAmount) || bill.amountDue || bill.amount;
    }

    private onContinueAfterLogin(): void {
        const {
            application: { guid },
            settings: { merchantName, skipPayorPage },
        } = this.props;
        const step = skipPayorPage ? "payment" : "payor";
        const path = guid
            ? `/${merchantName}/${step}/${guid}`
            : `/${merchantName}/${step}`;

        this.props.history.push(path);
    }
    handleBlur(event: any) {
        CheckFieldValidity(event.target, this.handleError);
    }

    private handleFormErrors = (errors: IFormError): void => {
        this.setState({
            errors: {
                ...this.state.errors,
                ...errors,
            },
        });
    };

    handleError(name: any, message: any) {
        return this.setState({
            errors: {
                ...this.state.errors,
                [name]: message,
            },
        });
    }
    handleContinue(event: any) {
        event.preventDefault();
        const { settings } = this.props;
        const path = `/${settings.merchantName}/bills`;
        this.props.history.push(path);
        return true;
    }
    handleChange(event: any) {
        event.preventDefault();
        if (event.target.validity.valid) {
            CheckFieldValidity(event.target, this.handleError);
        }
        this.props.updateCriteria({
            ...this.props.criteria,
            [event.target.name]: event.target.value,
        });
        return this.setState({
            searchDisabled: false,
        });
    }
    handleSearchOnClick(event: any) {
        event.stopPropagation();
        this.setState({
            showModal: true,
            searchDisabled: true,
        });
        event.nativeEvent.stopImmediatePropagation();
        if (!CheckFormValidity("bill-search-form", this.handleFormErrors)) {
            return false;
        }
        const {
            intl: { locale },
            application,
            settings: { merchantName },
            billSearch,
        } = this.props;
        const { criteria } = this.props;
        billSearch(
            merchantName,
            application,
            criteria.billType,
            criteria.id1,
            criteria.id2,
            criteria.id3,
            criteria.id4,
            getLanguageCode(locale),
            criteria.showPaidBills,
            "",
            "",
            0,
            10
        );
    }
    handleTabSelect(eventKey: any) {
        this.setState({
            activeKey: eventKey,
        });
    }
    handleBillTypeChange(event: any) {
        event.preventDefault();
        const {
            resetSearch,
            criteria: { id },
            billTypes,
        } = this.props;

        const billType = billTypes.find(
            (s: any) => s.id.toString() === event.target.value
        );
        const criteria = {
            billType: billType ? billType.name : "",
            id1: "",
            id2: "",
            id3: "",
            id4: "",
        } as IBillSearchCriteria;

        resetSearch(criteria);

        if (billType) {
            this.props.updateCriteria(criteria);
            this.setState({
                searchDisabled: !(
                    billType.id1.isSearchable ||
                    billType.id2.isSearchable ||
                    billType.id3.isSearchable ||
                    billType.id4.isSearchable
                ),
                errors: {},
            });
        }
    }
    renderAlert(messages: any) {
        return messages.map((message: any, index: any) => {
            return (
                <Alert key={index} bsStyle="danger">
                    <FontAwesomeIcon icon={faExclamationTriangle} /> {message}
                </Alert>
            );
        });
    }
    disablePayButton() {
        const { settings, bills } = this.props;
        const totalAmount = totalBillAmount(bills);
        if (settings.minPaymentAmount > totalAmount) {
            return true;
        }
        if (settings.maxPaymentAmount < totalAmount) {
            return true;
        }
        return bills.length === 0 ? true : false;
    }
    createMarkup(data: any) {
        return { __html: data };
    }
    render() {
        const {
            intl: { formatMessage },
            application,
            settings,
            bills,
            billTypes,
            searchResults,
        } = this.props;
        const {
            options,
            errors,
            searchDisabled,
            showModal,
            showMaxPaymentAmountExceeded,
        } = this.state;
        const { criteria } = this.props;
        const disableSearch =
            searchDisabled ||
            (!settings.allowMultipleBillPayments && bills.length !== 0);
        const billType = billTypes.find(
            (s: any) => s.name === criteria.billType
        );
        const colSize = billType && billType.imageContent ? 8 : 12;
        const totalAmount = totalBillAmount(bills);
        const disablePayButton = this.disablePayButton();

        const cancelButton = (
            <CancelButton
                disabled={bills.length === 0}
                application={application}
                settings={settings}
                cancel={this.props.cancel}
                history={this.props.history}
            />
        );
        const payButton = (
            <Button
                bsClass="btn btn-primary"
                onClick={this.handleContinue}
                disabled={disablePayButton}
                style={{ minWidth: "6.5em" }}
            >
                {Resources.ButtonContinueBillPage}{" "}
                {bills.length > 0 && <BillTotal bills={bills} />}
            </Button>
        );
        const actionButton = (
            <Button
                bsClass="btn btn-primary"
                disabled={disableSearch}
                onClick={this.handleSearchOnClick}
                style={{ minWidth: "6.5em" }}
            >
                {Resources.ButtonSearch}
            </Button>
        );

        return (
            <div>
                <div className="panel panel-primary">
                    <div className="panel-heading">
                        {this.props.settings.searchForBillsPrompt ||
                            Resources.HeaderSearchForBillsToPay}
                    </div>
                    <div className="panel-body">
                        {options.length > 1 && (
                            <Alert bsStyle="info">
                                {Resources.MessageSelectBillType(
                                    settings.allowMultipleBillPayments
                                )}
                                &nbsp;&nbsp;{Resources.MessageStep1}
                            </Alert>
                        )}
                        <Form id="bill-search-form">
                            {options.length > 1 && (
                                <Row>
                                    <Col xs={12}>
                                        <SelectInput
                                            label={Resources.LabelBillType}
                                            name="billtype"
                                            value={billType ? billType.id : 0}
                                            options={options}
                                            onChange={this.handleBillTypeChange}
                                            disabled={
                                                !settings.allowMultipleBillPayments &&
                                                bills.length !== 0
                                            }
                                        />
                                    </Col>
                                </Row>
                            )}
                            <Row>
                                <Col
                                    xs={12}
                                    sm={colSize}
                                    md={colSize}
                                    lg={colSize}
                                >
                                    {billType && (
                                        <div className="well">
                                            <div className="row">
                                                <div className="col-xs-12">
                                                    <p>
                                                        {Resources.MessageStep2(
                                                            billType,
                                                            formatMessage({
                                                                id: "message.and",
                                                            }),
                                                            options.length
                                                        )}
                                                        &nbsp;&nbsp;
                                                        {
                                                            Resources.MessageSearchBy
                                                        }
                                                        &nbsp;&nbsp;
                                                        {settings.allowMultipleBillPayments &&
                                                            Resources.MessageSearchAgain}
                                                    </p>
                                                </div>
                                            </div>
                                            <fieldset>
                                                <legend>
                                                    {billType.displayName}
                                                    {billType.imageContent && (
                                                        <div className="pull-right hidden-sm hidden-md hidden-lg">
                                                            <SampleBill
                                                                title={
                                                                    ModalTitleSampleBill
                                                                }
                                                                imageContent={
                                                                    billType.imageContent
                                                                }
                                                            />
                                                        </div>
                                                    )}
                                                </legend>
                                                <div className="row mb-30">
                                                    <div className="col-xs-12">
                                                        <div
                                                            dangerouslySetInnerHTML={this.createMarkup(
                                                                billType.description
                                                            )}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-xs-12">
                                                        <SearchForm
                                                            billType={billType}
                                                            criteria={criteria}
                                                            onChange={
                                                                this
                                                                    .handleChange
                                                            }
                                                            onBlur={
                                                                this.handleBlur
                                                            }
                                                            onError={
                                                                this.handleError
                                                            }
                                                            errors={errors}
                                                            hasImage={
                                                                billType &&
                                                                billType.imageContent
                                                            }
                                                        />
                                                    </div>
                                                </div>
                                            </fieldset>
                                        </div>
                                    )}
                                    {searchResults.messages &&
                                        this.renderAlert(
                                            searchResults.messages
                                        )}
                                </Col>
                                {billType && billType.imageContent && (
                                    <Col xsHidden sm={4} md={4} lg={4}>
                                        <BackgroundImage
                                            scale={4}
                                            imageContent={billType.imageContent}
                                            height="400px"
                                        />
                                    </Col>
                                )}
                            </Row>
                            {settings.maxPaymentAmount < totalAmount && (
                                <Alert bsStyle="danger">
                                    <h4>
                                        <FontAwesomeIcon
                                            icon={faExclamationTriangle}
                                        />{" "}
                                        {Resources.LabelOvermaxPaymentAmount}
                                    </h4>
                                    <p>
                                        {Resources.ErrorSumOverlimit(
                                            <FormattedNumber
                                                value={
                                                    settings.maxPaymentAmount
                                                }
                                                style="currency"
                                                currency="USD"
                                            />
                                        )}
                                    </p>
                                </Alert>
                            )}
                            {bills.length !== 0 &&
                                settings.minPaymentAmount > totalAmount && (
                                    <Alert bsStyle="danger">
                                        <h4>
                                            <FontAwesomeIcon
                                                icon={faExclamationTriangle}
                                            />{" "}
                                            {
                                                Resources.LabelUnderMinPaymentAmount
                                            }
                                        </h4>
                                        <p>
                                            {Resources.ErrorSumUnderlimit(
                                                <FormattedNumber
                                                    value={
                                                        settings.minPaymentAmount
                                                    }
                                                    style="currency"
                                                    currency="USD"
                                                />
                                            )}
                                        </p>
                                    </Alert>
                                )}
                            {!settings.allowMultipleBillPayments &&
                                bills.length !== 0 && (
                                    <Alert bsStyle="danger">
                                        <p>
                                            {Resources.MessageSingleBillPay1}
                                            &nbsp;&nbsp;
                                            {Resources.MessageSingleBillPay2}
                                            &nbsp;&nbsp;
                                            {Resources.MessageSingleBillPay3}
                                        </p>
                                    </Alert>
                                )}
                            <ButtonBar
                                cancelButton={cancelButton}
                                leftActionButton={actionButton}
                                middleActionButton={null}
                                rightActionButton={payButton}
                            />
                        </Form>
                    </div>
                </div>
                <div>
                    {billType && searchResults && (
                        <div>
                            <ResultsPanel
                                {...this.props}
                                billType={billType}
                                criteria={criteria}
                                addToBills={this.handleAddToBills}
                                showModal={showModal}
                                onHide={this.handleModalClose}
                                onAddAllBills={this.handleAddAllBills}
                                onRemoveAllBills={this.handleRemoveAllBills}
                                onContinue={this.handleContinue}
                            />
                            <InformModal
                                headerText={Resources.LabelOvermaxPaymentAmount}
                                message={Resources.ErrorSumOverlimit(
                                    <FormattedNumber
                                        value={settings.maxPaymentAmount}
                                        style="currency"
                                        currency="USD"
                                    />
                                )}
                                show={showMaxPaymentAmountExceeded}
                                onClose={
                                    this
                                        .handleMaxPaymentAmountExceededModalClose
                                }
                            />
                        </div>
                    )}
                </div>
                <LoginModal
                    application={this.props.application}
                    merchantName={this.props.settings.merchantName}
                    allowAutoPay={this.props.settings.allowAutoBillPay}
                    requireRecaptcha={this.props.settings.requireReCaptcha}
                    requireLoginEBPP={
                        application.isEBPP && settings.requireLoginEBPP
                    }
                    requirePasswordReset={false}
                    locale={this.props.intl.locale}
                    show={this.state.showLoginModal}
                    allowGuestLogin={true}
                    user={this.props.user}
                    onClose={this.hideLoginModal}
                    onContinue={this.onContinueAfterLogin}
                    updateUser={this.props.updateUser}
                    userVerified={this.props.userVerified}
                    login={this.props.login}
                    logout={this.props.logout}
                    loadUserAccounts={this.props.loadUserAccounts}
                    loadCustomerBillDetails={this.props.loadCustomerBillDetails}
                    webPaymentsCustomerAccountLoginMessage={
                        this.props.settings
                            .webPaymentsCustomerAccountLoginMessage
                    }
                />
            </div>
        );
    }
}

export default injectIntl(
    connect(
        (state: IApplicationState) => ({
            criteria: state.billSearch.criteria || ({} as IBillSearchCriteria),
        }),
        {
            updateCriteria,
        }
    )(SearchPanel)
);
