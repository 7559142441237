import React from "react";
import { Alert, Row, Col } from "react-bootstrap";
import { IAlertMessage } from "../../types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IconDefinition, config } from "@fortawesome/fontawesome-svg-core";
config.autoAddCss = false;

export interface AlertMessageProps {
    message: IAlertMessage;
    bsStyle: string;
    showIcon: boolean;
    icon: IconDefinition;
}
const AlertMessage = (props: AlertMessageProps) => {
    return (
        <Alert bsStyle={props.bsStyle} style={{ marginBottom: "10px" }}>
            <Row>
                <Col xs={12}>
                    <h4>
                        {props.showIcon && (
                            <FontAwesomeIcon icon={props.icon} />
                        )}{" "}
                        {props.message.text}
                    </h4>
                </Col>
            </Row>
        </Alert>
    );
};

export default AlertMessage;
