import React from "react";
import { Nav, Navbar, NavItem } from "react-bootstrap";
import "../../../css/SanDiegoBillSearch/header.css";
import * as Resources from "../../../resources";
import {
    IApplication,
    IApplicationRoute,
    IApplicationState,
    IInternationalization,
    ILink,
    IMyAccount,
    IProfile,
    ISettings,
    IUser,
} from "../../../types";
import MerchantBanner from "../Header/MerchantBanner";
import LanguageSelector from "../LanguageSelector/LanguageSelector";
import ModalContact from "./Modal/ModalContact";
import ModalDisclaimer from "./Modal/ModalDisclaimer";
import ShoppingCartNavigationLink from "./ShoppingCartNavigationLink";
import { NavDropdown } from "react-bootstrap";
import { connect } from "react-redux";
import { RouteComponentProps } from "react-router-dom";
import { loadUserAccounts } from "../../../actions/Account";
import { loadCustomerBillDetails } from "../../../actions/BillDetails";
import { logout, updateUser, userVerified } from "../../../actions/User";
import { login, updateUserProfile } from "../../../api/User";
import ChangePasswordModal from "../Header/ChangePasswordModal";
import { LoginModal } from "../Header/LoginModal";
import ManageFactorsModal from "../Header/ManageFactorsModal";
import MerchantLogin from "../Header/MerchantLogin";

export interface IHeaderOwnProps
    extends RouteComponentProps<IApplicationRoute> {
    hideLoginButton: boolean;
    hideNavbar: boolean;
}

export interface IHeaderStateProps {
    intl: IInternationalization;
    application: IApplication;
    myAccount: IMyAccount;
    settings: ISettings;
    user: IUser;
}

export interface IHeaderDispatchProps {
    updateUser?: (user: IUser) => void;
    loadUserAccounts?: (
        application: IApplication,
        merchantName: string,
        authToken: string,
        onError?: (text: string) => void
    ) => void;
    loadCustomerBillDetails?: (
        merchantName: string,
        authToken: string,
        merchantCustomerId: string
    ) => void;
    logout?: VoidFunction;
    userVerified: (value: boolean) => void;
}

export type HeaderProps = IHeaderOwnProps &
    IHeaderStateProps &
    IHeaderDispatchProps;

export interface IHeaderState {
    showDisclaimer: boolean;
    showContactInfo: boolean;
    expanded: boolean;
    showLoginModal: boolean;
    showChangePasswordModal: boolean;
    showManageFactorsModal: boolean;
    requirePasswordReset: boolean;
}
export interface IModalProps {
    showComponent: boolean;
    hideModal: () => void;
}

export class HeaderSanDiego extends React.Component<HeaderProps, IHeaderState> {
    constructor(props: HeaderProps) {
        super(props);
        this.state = {
            showDisclaimer: false,
            showContactInfo: false,
            showLoginModal: false,
            showChangePasswordModal: false,
            showManageFactorsModal: false,
            requirePasswordReset: false,
            expanded: false,
        };
    }

    private showDisclaimerModal = (): void => {
        this.setState({ showDisclaimer: true });
    };

    private hideDisclaimerModal = (): void => {
        this.setState({ showDisclaimer: false });
    };

    private showContactInfoModal = (): void => {
        this.setState({ showContactInfo: true });
    };

    private hideContactInfoModal = (): void => {
        this.setState({ showContactInfo: false });
    };

    public componentDidUpdate(prevProps: any): void {
        const { application, settings } = this.props;
        if (
            this.props.user.requirePasswordReset !==
            this.state.requirePasswordReset
        ) {
            if (application.isEBPP && settings.enableMyAccount) {
                this.setState({
                    requirePasswordReset: this.props.user.requirePasswordReset,
                });
            }
        }
    }

    private handleOpenModal = (event: any): void => {
        if (event) {
            event.preventDefault();
        }
        this.setState({ showLoginModal: true });
    };

    private handleCloseModal = (): void => {
        this.setState({ showLoginModal: false });
    };

    private handleClosePasswordModal = (): void => {
        this.setState({ showChangePasswordModal: false });
    };

    private handleOpenPasswordModal = (): void => {
        this.setState({ showChangePasswordModal: true });
    };

    private handleToggleManageFactorsModal = (): void => {
        this.setState({
            showManageFactorsModal: !this.state.showManageFactorsModal,
        });
    };

    private handleLogout = (): void => {
        const rootPath = `/${this.props.settings.merchantName}/`;
        const myAccountPath = `/${this.props.settings.merchantName}/myaccount/`;

        if (window.location.href.includes(myAccountPath)) {
            this.props.history.push(rootPath);
        }

        if (this.props.logout) this.props.logout();
    };

    private onLogin = (
        userName: string,
        password: string,
        recaptcha?: string | null
    ): Promise<IUser> => {
        return login(
            this.props.application,
            this.props.settings.merchantName,
            userName,
            password,
            recaptcha
        );
    };

    private onLoadUserAccounts = (
        application: IApplication,
        merchantName: string,
        authToken: string,
        onError?: (text: string) => void
    ): void => {
        this.props.loadUserAccounts!(
            application,
            merchantName,
            authToken,
            onError
        );
    };

    private onLoadCustomerBillDetails = (
        merchantName: string,
        authToken: string,
        merchantCustomerId: string
    ) => {
        this.props.loadCustomerBillDetails!(
            merchantName,
            authToken,
            merchantCustomerId
        );
    };

    private onUpdateUserProfile = (
        merchantCustomerId: string,
        authToken: string,
        profile: IProfile,
        password: string,
        currentPassword: string
    ): Promise<IUser> => {
        return updateUserProfile(
            this.props.application,
            this.props.settings.merchantName,
            authToken,
            profile,
            password,
            currentPassword
        );
    };

    private toggleDropdown = (): void => {
        this.setState({ expanded: !this.state.expanded });
    };

    private closeDropdown = (): void => {
        this.setState({ expanded: false });
    };

    private stopPropagation = (event: React.MouseEvent<NavDropdown>): void => {
        event.preventDefault();
        event.stopPropagation();
    };

    private addConfiguredLink = (links: ILink[] | null) => {
        if (links !== null) {
            const link = links.map((link: ILink) => {
                const text = link.text.split("::");
                return (
                    <NavItem
                        eventKey={link.href}
                        target="_blank"
                        href={link.href}
                        linkId={text[0]}
                        dangerouslySetInnerHTML={{ __html: text[1] }}
                    />
                );
            });
            return link;
        }
    };

    render() {
        const { application, settings, user, hideLoginButton } = this.props;
        const { showChangePasswordModal } = this.state;
        const isSecurePayLoggedIn = application.id === 34 && user.isLoggedIn;
        const navLinks = this.addConfiguredLink(settings.links);

        return (
            <div className="header-billsearch">
                <MerchantBanner
                    bannerImage={settings.bannerImage}
                    bannerImageMobile={settings.mobileLogo}
                />
                <Navbar
                    bsStyle="inverse"
                    onToggle={this.toggleDropdown}
                    expanded={this.state.expanded}
                    onBlur={this.closeDropdown}
                >
                    <Navbar.Header>
                        <Navbar.Toggle />
                    </Navbar.Header>
                    <Navbar.Collapse className="sd-header-ml-200">
                        <Nav onSelect={this.closeDropdown} className="m-top-10">
                            {navLinks &&
                                navLinks.find(
                                    (el) =>
                                        el.props.linkId.toLowerCase() ===
                                        "survey"
                                )}
                        </Nav>
                        <Nav onSelect={this.closeDropdown} pullRight>
                            <ShoppingCartNavigationLink
                                history={this.props.history}
                                match={this.props.match}
                                location={this.props.location}
                            />
                            {navLinks &&
                                navLinks.find(
                                    (el) =>
                                        el.props.linkId.toLowerCase() === "faq"
                                )}
                            {settings.enableLanguageSupport && (
                                <LanguageSelector
                                    stopPropagation={this.stopPropagation}
                                    closeMenu={this.closeDropdown}
                                />
                            )}
                            {navLinks &&
                                navLinks.find(
                                    (el) =>
                                        el.props.linkId.toLowerCase() ===
                                        "enotification"
                                )}
                            <NavItem
                                eventKey={3}
                                onClick={this.showDisclaimerModal}
                                className="visible-xs"
                            >
                                {Resources.BillSearchNavbarDisclaimer}
                            </NavItem>
                            <NavItem
                                eventKey={4}
                                onClick={this.showContactInfoModal}
                                className="visible-xs"
                            >
                                {Resources.BillSearchNavbarContactInfo}
                            </NavItem>

                            {!hideLoginButton &&
                                settings.enableMyAccount &&
                                (application.id === 33 ||
                                    isSecurePayLoggedIn) && (
                                    <MerchantLogin
                                        isAutoPayEnabled={
                                            this.props.settings.allowAutoBillPay
                                        }
                                        isLoggedIn={
                                            user.isLoggedIn && user.isVerified
                                        }
                                        myAccount={this.props.myAccount}
                                        merchantName={settings.merchantName}
                                        openChangePassword={
                                            this.handleOpenPasswordModal
                                        }
                                        openManageFactors={
                                            this.handleToggleManageFactorsModal
                                        }
                                        openLogin={this.handleOpenModal}
                                        logout={this.handleLogout}
                                    />
                                )}
                        </Nav>
                    </Navbar.Collapse>
                </Navbar>
                <LoginModal
                    application={this.props.application}
                    merchantName={this.props.settings.merchantName}
                    allowAutoPay={this.props.settings.allowAutoBillPay}
                    requireRecaptcha={this.props.settings.requireReCaptcha}
                    requireLoginEBPP={
                        application.isEBPP && settings.requireLoginEBPP
                    }
                    requirePasswordReset={this.state.requirePasswordReset}
                    locale={this.props.intl.locale}
                    show={this.state.showLoginModal}
                    allowGuestLogin={false}
                    user={user}
                    onClose={this.handleCloseModal}
                    updateUser={this.props.updateUser}
                    userVerified={this.props.userVerified}
                    login={this.onLogin}
                    logout={this.handleLogout}
                    loadUserAccounts={this.onLoadUserAccounts}
                    loadCustomerBillDetails={this.onLoadCustomerBillDetails}
                    webPaymentsCustomerAccountLoginMessage={
                        this.props.settings
                            .webPaymentsCustomerAccountLoginMessage
                    }
                />
                <ChangePasswordModal
                    locale={this.props.intl.locale}
                    localeTranslations={this.props.intl.messages}
                    merchantCustomerId={this.props.user.merchantCustomerID}
                    authToken={this.props.user.authToken}
                    profile={this.props.user.profile}
                    show={showChangePasswordModal}
                    onClose={this.handleClosePasswordModal}
                    updateUserProfile={this.onUpdateUserProfile}
                />
                <ManageFactorsModal
                    application={this.props.application}
                    user={this.props.user}
                    merchantName={this.props.settings.merchantName}
                    updateUser={this.props.updateUser}
                    locale={this.props.intl.locale}
                    localeTranslations={this.props.intl.messages}
                    show={this.state.showManageFactorsModal}
                    onClose={this.handleToggleManageFactorsModal}
                />
                <ModalDisclaimer
                    showComponent={this.state.showDisclaimer}
                    hideModal={this.hideDisclaimerModal}
                />
                <ModalContact
                    showComponent={this.state.showContactInfo}
                    hideModal={this.hideContactInfoModal}
                />
            </div>
        );
    }
}

export const mapStateToProps = (state: IApplicationState) => ({
    intl: state.intl,
    application: state.application,
    myAccount: state.myAccount,
    settings: state.settings,
    user: state.user,
});

export const mapDispatchToProps = {
    updateUser,
    loadUserAccounts,
    loadCustomerBillDetails,
    logout,
    userVerified,
};

export default connect(mapStateToProps, mapDispatchToProps)(HeaderSanDiego);
