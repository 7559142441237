export const TERMSOFUSE_LOAD = "redux/TermsOfUse/LOAD";
export const TERMSOFUSE_LOAD_SUCCESS = "redux/TermsOfUse/LOAD_SUCCESS";
export const TERMSOFUSE_LOAD_FAIL = "redux/TermsOfUse/LOAD_FAIL";

export type RequestTermsAction = {
    type: typeof TERMSOFUSE_LOAD;
}

export type ReceiveTermsAction = {
    type: typeof TERMSOFUSE_LOAD_SUCCESS;
    data: string;
}

export type FailedTermsAction = {
    type: typeof TERMSOFUSE_LOAD_FAIL;
    data: string;
}

export type TermsActions = RequestTermsAction | ReceiveTermsAction | FailedTermsAction;