import { Reducer } from "redux";
import {
  USER_UPDATE,
  USER_LOGOUT,
  USER_VERIFIED,
  UpdateUserAction,
  LogoutUserAction,
  UserVerifiedAction,
  UserActions,
} from "../actionTypes/User";
import {
  CONFIGURATION_LOAD_SUCCESS,
  CONFIGURATION_EBPP_LOAD_SUCCESS,
  CONFIGURATION_LOAD_FAIL,
  ReceiveConfigurationAction,
  ReceiveConfigurationEBPPAction,
  FailedConfigurationAction,
} from "../actionTypes/Configuration";
import { IUser, IProfile } from "../types";

export const initialState: IUser = {
  requirePasswordReset: false,
  isSuccessful: false,
  messages: [],
  merchantCustomerID: "",
  isLoggedIn: false,
  isVerified: false,
  isManageVerified: false,
  authToken: "",
  profile: {} as IProfile,
  autoPayEnabled: false,
  notificationsEnabled: false,
  mfaRequired: false,
  factors: [],
  userId: "",
};

const userLoginAction = (state: IUser, action: UpdateUserAction): IUser => {
  const { profile } = action.data;

  return {
    ...state,
    ...action.data,
    profile:
      {
        ...state.profile,
        ...profile,
        country: (profile && profile.country) ? profile.country : "US",
        phoneCountry: (profile && profile.phoneCountry) ? profile.phoneCountry : "US",
      } as IProfile
  };
};

const userLogoutAction = (state: IUser, action: LogoutUserAction): IUser => {
  return {
    ...initialState,
  };
};

const userVerified = (state: IUser, action: UserVerifiedAction): IUser => {
  return {
    ...state,
    isVerified: action.data,
  };
};

const configurationLoadSuccessAction = (
  state: IUser,
  action: ReceiveConfigurationAction
): IUser => {
  return {
    ...state,
    ...action.data.bills.authenticateCustomer,
    profile:
      action.data.bills.authenticateCustomer &&
      action.data.bills.authenticateCustomer.profile
        ? {
            ...action.data.bills.authenticateCustomer.profile,
            country:
              action.data.bills.authenticateCustomer.profile.country || "US",
            phoneCountry:
              action.data.bills.authenticateCustomer.profile.phoneCountry ||
              "US",
          }
        : ({} as IProfile),
  };
};

const configurationEbppLoadSuccessAction = (
  state: IUser,
  action: ReceiveConfigurationEBPPAction
): IUser => {
  return {
    ...state,
    ...action.data.bills.authenticateCustomer,
    profile:
      action.data.bills.authenticateCustomer &&
      action.data.bills.authenticateCustomer.profile
        ? {
            ...action.data.bills.authenticateCustomer.profile,
            country:
              action.data.bills.authenticateCustomer.profile.country || "US",
            phoneCountry:
              action.data.bills.authenticateCustomer.profile.phoneCountry ||
              "US",
          }
        : ({} as IProfile),
  };
};

const configurationLoadFailAction = (
  state: IUser,
  action: FailedConfigurationAction
): IUser => {
  return {
    ...state,
    ...initialState,
  };
};

export const reducer: Reducer<IUser> = (
  state: IUser = initialState,
  action: UserActions
): IUser => {
  switch (action.type) {
    case USER_UPDATE:
      return userLoginAction(state, action);
    case USER_LOGOUT:
      return userLogoutAction(state, action);
    case USER_VERIFIED:
      return userVerified(state, action);
    case CONFIGURATION_LOAD_SUCCESS:
      return configurationLoadSuccessAction(state, action);
    case CONFIGURATION_EBPP_LOAD_SUCCESS:
      return configurationEbppLoadSuccessAction(state, action);
    case CONFIGURATION_LOAD_FAIL:
      return configurationLoadFailAction(state, action);
    default:
      return state;
  }
};
