import { IMyAccount } from '../types';
import { LogoutUserAction } from '../actionTypes/User';

export const MYACCOUNT_SET = "redux/MyAccount/SET"
export const MYACCOUNT_SELECT = "redux/MyAccount/SELECT";

export type SetMyAccountAction = {
    type: typeof MYACCOUNT_SET;
    data: boolean;
}

export type SelectMyAccountAction = {
    type: typeof MYACCOUNT_SELECT;
    data: string
}

export type MyAccountActions = SetMyAccountAction | SelectMyAccountAction | LogoutUserAction;