import { IApplication, IBillSearchResults } from "../types";

export function searchBills(
    merchantName: string,
    application: IApplication,
    billType: string,
    id1: string,
    id2: string,
    id3: string,
    id4: string,
    language: number,
    offset = 0,
    take = 25,
    includePaidBills: boolean,
    address: string,
    postalCode: string
): Promise<IBillSearchResults> {
    const baseUrl = process.env.REACT_APP_API_BASEURL;

    return fetch(
        `${baseUrl}/webapi/api/BillSearch?merchantName=${merchantName}&language=${language}&offset=${offset}&take=${take}`,
        {
            method: "POST",
            mode: baseUrl ? "cors" : "same-origin",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({
                application,
                billType,
                id1,
                id2,
                id3,
                id4,
                includePaidBills,
                address,
                postalCode,
            }),
        }
    )
        .then((response) => response.json() as Promise<IBillSearchResults>)
        .catch((error) => error);
}
