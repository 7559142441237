import React from "react";
import { FormGroup, ControlLabel } from "react-bootstrap";
import { DatePickerInput } from "rc-datepicker";
import moment from "moment";

export interface IFutureDatePaymentProps {
    name: string;
    label: string;
    locale: string;
    selectedDate: Date | null;
    maxNumberOfDays: number;
    onChanged: (event: any) => void;
}

const FutureDatePayment = (props: IFutureDatePaymentProps) => {
    const now = new Date();
    const future = new Date();
    const selected = props.selectedDate
        ? new Date(props.selectedDate.valueOf())
        : now;

    future.setDate(now.getDate() + props.maxNumberOfDays);

    const dateFormat = moment.localeData(props.locale).longDateFormat("L");

    const isFutureDateError = (value: Date): boolean => {
        return (
            value.toString() === "Invalid date" ||
            isNaN(value.getTime()) ||
            moment(value).isAfter(future, "day") ||
            moment(value).isBefore(now, "day")
        );
    };

    const handleChange = (value: Date): void => {
        const event = {
            target: {
                name: props.name,
                value: value,
                validity: { valid: !isFutureDateError(value) },
            },
        };

        if (props.onChanged) props.onChanged(event);
    };

    const validationState = isFutureDateError(selected) ? "error" : null;

    return (
        <div>
            <FormGroup validationState={validationState}>
                <ControlLabel>{props.label}</ControlLabel>
                <DatePickerInput
                    value={selected}
                    minDate={now}
                    maxDate={future}
                    displayFormat={dateFormat}
                    onChange={handleChange}
                />
                {validationState && (
                    <span className="help-block">
                        This is not a valid date.
                    </span>
                )}
            </FormGroup>
        </div>
    );
};

export default FutureDatePayment;
