import React from "react";
import { Row } from "react-bootstrap";
import { withRouter, RouteComponentProps } from "react-router-dom";
import * as Resources from "../../resources";
import { IApplicationRoute } from "../../types";

interface IOwnProps extends RouteComponentProps<IApplicationRoute> {
    position: string;
}
interface IOwnStates {
    mobileStepperTitleText: string;
}
class StepperPanel extends React.Component<IOwnProps, IOwnStates> {
    constructor(props: IOwnProps) {
        super(props);
        this.state = {
            mobileStepperTitleText: "Step 1 of 7 - Search",
        };
    }

    private changeMobileStepperTitleText = (step: string): JSX.Element => {
        let stepNumber = Resources.BillSearchMobileStepPosition1;
        switch (step) {
            case "search":
                stepNumber = Resources.BillSearchMobileStepPosition1;
                break;
            case "search-result":
                stepNumber = Resources.BillSearchMobileStepPosition2;
                break;
            case "bills":
                stepNumber = Resources.BillSearchMobileStepPosition3;
                break;
            case "payor":
                stepNumber = Resources.BillSearchMobileStepPosition4;
                break;
            case "payment":
                stepNumber = Resources.BillSearchMobileStepPosition5;
                break;
            case "verify":
                stepNumber = Resources.BillSearchMobileStepPosition6;
                break;
            case "receipt":
                stepNumber = Resources.BillSearchMobileStepPosition7;
                break;
            default:
                stepNumber = Resources.BillSearchMobileStepPosition1;
                break;
        }
        return stepNumber;
    };

    render() {
        return (
            <div>
                <Row className="hidden-xs stepper">
                    <Row className="step">
                        <div
                            id="PaymentApplicationContent_ctrlPageSteps_step1"
                            className={
                                "col-xs-1 col-sm-1 col-lg-1 flex-3rd " +
                                this.isActive("search")
                            }
                        >
                            {Resources.BillSearchSearch}
                        </div>
                        <div
                            id="PaymentApplicationContent_ctrlPageSteps_step2"
                            className={
                                "col-xs-2 col-sm-2 col-lg-2 flex-3rd " +
                                this.isActive("search-result")
                            }
                        >
                            {Resources.BillSearchResults}
                        </div>
                        <div
                            id="PaymentApplicationContent_ctrlPageSteps_step3"
                            className={
                                "col-xs-2 col-sm-2 col-lg-2 flex-3rd " +
                                this.isActive("bills")
                            }
                        >
                            {Resources.BillSearchShoppingCart}
                        </div>
                        <div
                            id="PaymentApplicationContent_ctrlPageSteps_step4"
                            className={
                                "col-xs-2 col-sm-2 col-lg-2 flex-3rd " +
                                this.isActive("payor")
                            }
                        >
                            {Resources.BillSearchPayorInformation}
                        </div>
                        <div
                            id="PaymentApplicationContent_ctrlPageSteps_step5"
                            className={
                                "col-xs-2 col-sm-2 col-lg-2 flex-3rd " +
                                this.isActive("payment")
                            }
                        >
                            {Resources.BillSearchPaymentInformation}
                        </div>
                        <div
                            id="PaymentApplicationContent_ctrlPageSteps_step6"
                            className={
                                "col-xs-2 col-sm-2 col-lg-2 flex-3rd " +
                                this.isActive("verify")
                            }
                        >
                            {Resources.BillSearchVerification}
                        </div>
                        <div
                            id="PaymentApplicationContent_ctrlPageSteps_step7"
                            className={
                                "col-xs-1 col-sm-1 col-lg-1 flex-1n " +
                                this.isActive("receipt")
                            }
                        >
                            {Resources.BillSearchReceipt}
                        </div>
                    </Row>
                </Row>
                <div className="progress-billsearch visible-xs">
                    <span className="progress-type">
                        {this.changeMobileStepperTitleText(this.props.position)}
                    </span>
                </div>
            </div>
        );
    }

    isActive = (step: string) => {
        if (this.props.position === step) {
            return "stepbox activestep";
        } else {
            return "stepbox";
        }
    };
}

export default withRouter(StepperPanel);
