//import { loadInit } from "../api/Init";
import {
  INTL_LOAD,
  INTL_LOAD_SUCCESS,
  INTL_LOAD_FAIL,
  INTL_SET,
  IntlActions,
} from "../actionTypes/Intl";
import { IAppThunkAction } from "../types";

/* export const loadIntl =
  (merchantName: string, guid?: string): IAppThunkAction<IntlActions> =>
  (dispatch) => {
    loadInit(merchantName, guid)
      .then((intlId) => dispatch({ type: INTL_LOAD_SUCCESS, data: intlId }))
      .catch((error) => dispatch({ type: INTL_LOAD_FAIL, data: error }));

    dispatch({ type: INTL_LOAD });
  }; */

export const set =
  (language: string): IAppThunkAction<IntlActions> =>
  (dispatch) => {
    dispatch({
      type: INTL_SET,
      data: language,
    });
  };
