import React from "react";
import { History } from "history";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleNotch } from "@fortawesome/free-solid-svg-icons";
import classNames from "classnames";
import {
    Form,
    Panel,
    ListGroup,
    ListGroupItem,
    ButtonToolbar,
} from "react-bootstrap";
import { SelectInput, CancelButton } from "../Input";

import * as Resources from "../../resources";
import { getLanguageCode } from "../../utils/Intl";
import { BillForm } from "./BillForm";
import { SampleBill } from "./SampleBill";
import { ModalTitleSampleBill } from "../../resources";

import {
    ConfigureBillFromBillType,
    GetBillOptions,
} from "../../utils/BillHelpers";
import { CheckFormValidity, CheckFieldValidity } from "../../utils/Validation";

import {
    IBillType,
    IApplication,
    ISettings,
    IBill,
    IUser,
    ISelectOption,
    IInternationalization,
} from "../../types";
import { IFormError } from "../../types/IFormError";

function GetInitialBill(props: any) {
    const { settings, billTypes, bills } = props;
    let initialBill = ConfigureBillFromBillType(billTypes);
    if (
        settings &&
        !settings.allowMultipleBillPayments &&
        bills &&
        bills.length > 0
    ) {
        initialBill = bills[0];
    }
    return { ...initialBill };
}

export interface SingleBillProps {
    bills: IBill[];
    application: IApplication;
    intl: IInternationalization;
    settings: ISettings;
    billTypes: IBillType[];
    user: IUser;
    history: History;
    params: { merchantName: string; guid?: string };
    saveBill: (bill: IBill) => void;
    cancel: () => void;
    loadImage: (
        application: IApplication,
        merchantName: string,
        billName: string,
        language: number
    ) => void;
    showLoginModal: () => void;
    onContinue: () => void;
}

export interface SingleBillState {
    dirty: boolean;
    bill: IBill;
    options: ISelectOption[];
    isSaving: boolean;
    saveDisabled: boolean;
    errors: IFormError;
}

class SingleBill extends React.Component<SingleBillProps, SingleBillState> {
    constructor(props: any) {
        super(props);
        const { billTypes } = this.props;
        const initialBill = GetInitialBill(this.props);
        const saveDisabled = !initialBill.billType;

        this.state = {
            dirty: false,
            bill: { ...initialBill },
            options: GetBillOptions(billTypes),
            saveDisabled: saveDisabled,
            isSaving: false,
            errors: {},
        };

        this.handleSave = this.handleSave.bind(this);
        this.handleBlur = this.handleBlur.bind(this);
        this.handleError = this.handleError.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.createMarkup = this.createMarkup.bind(this);
        this.handleBillTypeChange = this.handleBillTypeChange.bind(this);
    }

    public componentDidMount() {
        const { application, settings, user } = this.props;
        if (application.guid && application.expired) {
            const path = `/${settings.merchantName}/expired/${application.guid}`;
            this.props.history.push(path);
            return;
        }
        if (application.guid && application.canceled) {
            const path = `/${settings.merchantName}/canceled`;
            this.props.history.push(path);
            return;
        }

        if (this.props.billTypes.length === 1) {
            this.props.loadImage(
                application,
                settings.merchantName,
                this.props.billTypes[0].name,
                getLanguageCode(this.props.intl.locale)
            );
        }

        if (application.isEBPP && settings.enableMyAccount) {
            if (settings.requireLoginEBPP && !user.isLoggedIn) {
                this.props.showLoginModal();
            }
        }
    }

    private handleSave(event: any) {
        event.preventDefault();
        if (!CheckFormValidity("single-bill-form", this.handleFormErrors)) {
            return false;
        }
        const { application, settings, user } = this.props;
        const { bill } = this.state;
        const requireLoginEBPP =
            settings.requireLoginEBPP && !(user && user.isLoggedIn);

        this.props.saveBill(bill);

        settings.enableMyAccount &&
        application.id === 33 &&
        (!(user && user.isLoggedIn) || requireLoginEBPP)
            ? this.props.showLoginModal()
            : this.props.onContinue();
        return true;
    }

    private handleBlur(event: any) {
        CheckFieldValidity(event.target, this.handleError);
    }

    private handleFormErrors = (errors: IFormError): void => {
        this.setState({
            errors: {
                ...this.state.errors,
                ...errors,
            },
        });
    };

    private handleError(name: string, message: string) {
        this.setState({
            errors: {
                ...this.state.errors,
                [name]: message,
            },
        });
    }

    private handleChange(
        event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
    ) {
        const field = event.target.name as keyof IBill;
        const bill: IBill = { ...this.state.bill };

        (bill as Record<typeof field, any>)[field] = event.target.value;

        if (field === "amount") {
            bill.customAmount = null;
        }
        if (event.target.validity.valid) {
            CheckFieldValidity(event.target, this.handleError);
        }
        this.setState({ dirty: true, bill });
    }

    private handleBillTypeChange(e: any) {
        const { billTypes } = this.props;
        const { id } = this.state.bill;
        const bill = Object.assign(
            {},
            ConfigureBillFromBillType(billTypes, e.target.value),
            { id: id }
        ); // persistent the current
        const billType =
            billTypes &&
            billTypes.length > 0 &&
            billTypes.find((s: any) => s.id.toString() === e.target.value);
        if (billType && !billType.imageContent) {
            this.props.loadImage(
                this.props.application,
                this.props.settings.merchantName,
                e.target.value,
                getLanguageCode(this.props.intl.locale)
            );
        }
        this.setState({
            bill,
            saveDisabled: false,
            errors: {},
        });
    }

    private createMarkup(data: any) {
        return { __html: data };
    }

    public render() {
        const { bill, options, errors, saveDisabled, isSaving } = this.state;
        const { billTypes } = this.props;
        const { application, settings } = this.props;

        const headerText = bill.billType
            ? settings.billInstructionsPrompt
                ? settings.billInstructionsPrompt
                : Resources.HeaderEnterBillInformation
            : Resources.HeaderSelectBillInformation;
        const buttonText = isSaving ? (
            <FontAwesomeIcon icon={faCircleNotch} spin />
        ) : (
            Resources.ButtonContinueBillPage
        );
        const listItems = billTypes.map((option: any, index: any) => {
            const active = option.name === bill.billType;
            return (
                <ListGroupItem
                    key={index}
                    value={option.id.toString()}
                    active={active}
                    onClick={this.handleBillTypeChange}
                >
                    {option.displayName}
                </ListGroupItem>
            );
        });

        const billType = billTypes.find((s: any) => s.name === bill.billType);
        const multipleBillType = billTypes.length !== 1;

        const leftSide = multipleBillType ? (
            <div className="col-sm-4 col-xs-12">
                <div className="row">
                    <div className="col-sm-12 hidden-xs">
                        <ListGroup>{listItems}</ListGroup>
                    </div>
                    <div className="col-xs-12 hidden-sm hidden-md hidden-lg">
                        <SelectInput
                            label={Resources.LabelBillType}
                            name="billtype"
                            value={bill.billType}
                            options={options}
                            onChange={this.handleBillTypeChange}
                        />
                    </div>
                </div>
            </div>
        ) : (
            <div className="col-sm-1 col-md-2 hidden-xs"></div>
        );

        const rightSide = !multipleBillType && (
            <div className="col-sm-1 col-md-2 hidden-xs"></div>
        );

        const wrapperClass = classNames(
            "col-xs-12",
            { "col-sm-8": multipleBillType },
            { "col-sm-10": !multipleBillType },
            "col-md-8"
        );

        return (
            <Form id="single-bill-form">
                <Panel bsStyle="primary">
                    <Panel.Heading>{headerText}</Panel.Heading>
                    <Panel.Body>
                        <div className="row">
                            {leftSide}
                            <div className={wrapperClass}>
                                {billType && (
                                    <div>
                                        <div className="well">
                                            <fieldset>
                                                <legend>
                                                    {" "}
                                                    {billType.displayName}
                                                    {billType.imageContent && (
                                                        <div className="pull-right">
                                                            <SampleBill
                                                                title={
                                                                    ModalTitleSampleBill
                                                                }
                                                                imageContent={
                                                                    billType.imageContent
                                                                }
                                                            />
                                                        </div>
                                                    )}
                                                </legend>
                                                <div className="row">
                                                    <div className="col-xs-12">
                                                        <p>
                                                            <span
                                                                dangerouslySetInnerHTML={this.createMarkup(
                                                                    billType.description
                                                                )}
                                                            />
                                                        </p>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-xs-12">
                                                        <BillForm
                                                            bill={bill}
                                                            billType={billType}
                                                            maxAmount={
                                                                settings.maxPaymentAmount
                                                            }
                                                            errors={errors}
                                                            onBlur={
                                                                this.handleBlur
                                                            }
                                                            onChange={
                                                                this
                                                                    .handleChange
                                                            }
                                                            onError={
                                                                this.handleError
                                                            }
                                                        />
                                                    </div>
                                                </div>
                                            </fieldset>
                                        </div>
                                        <div className="well">
                                            <div className="row">
                                                <div className="col-xs-12">
                                                    <div className="inline pull-right">
                                                        <ButtonToolbar>
                                                            <CancelButton
                                                                {...this.props}
                                                                application={
                                                                    application
                                                                }
                                                                settings={
                                                                    settings
                                                                }
                                                            />
                                                            <button
                                                                type="button"
                                                                className="btn btn-primary"
                                                                disabled={
                                                                    saveDisabled
                                                                }
                                                                onClick={
                                                                    this
                                                                        .handleSave
                                                                }
                                                                style={{
                                                                    minWidth:
                                                                        "6.5em",
                                                                }}
                                                            >
                                                                {buttonText}
                                                            </button>
                                                        </ButtonToolbar>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )}
                            </div>
                            {rightSide}
                        </div>
                    </Panel.Body>
                </Panel>
            </Form>
        );
    }
}

export default SingleBill;
