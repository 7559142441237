import React from "react";
import { Input } from "./Input";

export enum TextInputPropsEnum {
    type,
    onBlur,
    onError,
    onChange,
    autocomplete,
}
export class TextInput extends React.Component<any, any> {
    public static defaultProps: Partial<any> = {
        type: "text",
    };
    constructor(props: any) {
        super(props);

        this.handleBlur = this.handleBlur.bind(this);
        this.handleError = this.handleError.bind(this);
        this.handleChange = this.handleChange.bind(this);
    }

    handleBlur(event: any) {
        if (this.props.onBlur) {
            this.props.onBlur(event);
        }
    }

    handleError(name: any, message: any) {
        if (this.props.onError) {
            this.props.onError(name, message);
        }
    }

    handleChange(event: any) {
        if (this.props.onChange) {
            this.props.onChange(event);
        }
    }

    render() {
        const { type, autocomplete } = this.props;
        const props: any = Object.assign({}, this.props);
        Object.keys(this.props).forEach((key) => {
            if (key in TextInputPropsEnum) {
                delete props[key];
            }
        });
        return (
            <Input
                {...props}
                type={type}
                onChange={this.handleChange}
                onBlur={this.handleBlur}
                onError={this.handleError}
                autocomplete={autocomplete}
            />
        );
    }
}
