import React from "react";
import ButtonBar from "./ButtonBar";
import PaginationBar from "../Input/PaginationBar";
import { Panel, Row, Col, Table, Button, ButtonToolbar } from "react-bootstrap";
import {
    GetCardImageByPaymentMethod,
    GetCardName,
} from "../../utils/CardTypes";
import { FormattedNumber, FormattedDate } from "react-intl";
import { getLanguageCode } from "../../utils/Intl";
import * as Resources from "../../resources";
import b64toBlob from "b64-to-blob";
import { IGeneratedReceiptResponse, ITransaction } from "../../types";
import AlertMessage from "../Layout/AlertMessage";
import { faExclamationTriangle } from "@fortawesome/free-solid-svg-icons";

class PaymentHistoryForm extends React.Component<any, any> {
    constructor(props: any) {
        super(props);
        this.state = {
            submitting: false,
            pageNumber: 1,
            pageSize: 10,
            errors: [],
        };
        this.handleBackButton = this.handleBackButton.bind(this);
        this.handleGenerate = this.handleGenerate.bind(this);
        this.handleSelect = this.handleSelect.bind(this);
        this.onGenerateSuccess = this.onGenerateSuccess.bind(this);
        this.onGenerateError = this.onGenerateError.bind(this);
    }
    componentDidMount() {
        const { pageNumber, pageSize } = this.state;
        const { loadTransactions, application, settings, user } = this.props;
        loadTransactions(
            application,
            settings.merchantName,
            user.authToken,
            pageNumber,
            pageSize
        );
    }
    handleBackButton(event: React.MouseEvent<Button>) {
        event.preventDefault();
        this.props.history.goBack();
        return true;
    }
    handleGenerate(param: number, event: React.MouseEvent<Button>) {
        event.preventDefault();
        this.setState({ submitting: true });
        const { application, settings, user, intl, generate, refresh } =
            this.props;
        generate(
            application,
            user,
            param,
            getLanguageCode(intl.locale),
            this.onGenerateError,
            this.onGenerateSuccess
        );
    }
    onGenerateSuccess(result: IGeneratedReceiptResponse) {
        const { refresh } = this.props;
        this.setState({ submitting: false });
        refresh(result.fileContents);
        const blob = b64toBlob(result.fileContents, "application/pdf");
        if (window.navigator && (window.navigator as any).msSaveOrOpenBlob) {
            (window.navigator as any).msSaveOrOpenBlob(blob);
        } else {
            const objectUrl = URL.createObjectURL(blob);
            window.open(objectUrl, "_blank");
        }
    }
    onGenerateError(text: string) {
        this.setState({
            error: text || Resources.AjaxErrorMessage,
            submitting: false,
        });
    }
    handleSelect(eventKey: number) {
        const { pageNumber, pageSize } = this.state;
        const { loadTransactions, application, settings, user } = this.props;
        loadTransactions(
            application,
            settings.merchantName,
            user.authToken,
            eventKey,
            pageSize
        );
        this.setState({
            pageNumber: eventKey,
        });
    }
    buildGrid() {
        const { transactions } = this.props;
        const { pageNumber } = this.state;
        const totalPages = transactions ? transactions.totalPages : 0;
        const paginatedTransactions = transactions
            ? transactions.transactions
            : [];
        return paginatedTransactions.length === 0 ? (
            <h4>{Resources.MessageNoPaymentHistory}</h4>
        ) : (
            <Row>
                <Col xs={12} sm={12} md={12} lg={12}>
                    <div key={0} className="panel panel-default">
                        <div>
                            <Table
                                className="rtable rtable--flip"
                                condensed
                                bordered
                            >
                                <thead>
                                    <tr>
                                        <th>{Resources.LabelTransactionId}</th>
                                        <th>
                                            {Resources.LabelPaymentDateTime}
                                        </th>
                                        <th>
                                            {Resources.HeaderPaymentInformation}
                                        </th>
                                        <th>{Resources.LabelAmount}</th>
                                        <th>
                                            {Resources.LabelDownloadReceipt}
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {paginatedTransactions.map(
                                        (
                                            transaction: ITransaction,
                                            index: number
                                        ) => {
                                            const keyNames =
                                                Object.keys(transaction);
                                            const cardType = (
                                                <span>
                                                    {GetCardImageByPaymentMethod(
                                                        transaction.paymentMethod
                                                    )}
                                                    &nbsp;{" "}
                                                    <strong>
                                                        {GetCardName(
                                                            transaction.paymentMethod
                                                        )}
                                                    </strong>
                                                </span>
                                            );
                                            return (
                                                <tr key={index}>
                                                    <td
                                                        data-title={keyNames[0]}
                                                    >
                                                        {
                                                            transaction.transactionId
                                                        }
                                                    </td>
                                                    <td
                                                        data-title={keyNames[1]}
                                                    >
                                                        <FormattedDate
                                                            value={
                                                                transaction.transactionDateUTC
                                                            }
                                                            year="numeric"
                                                            month="2-digit"
                                                            day="2-digit"
                                                        />
                                                    </td>
                                                    <td
                                                        data-title={keyNames[0]}
                                                    >
                                                        {Resources.MessageCardInformation(
                                                            cardType,
                                                            transaction.maskedAccountNumber
                                                        )}
                                                    </td>
                                                    <td
                                                        data-title={keyNames[2]}
                                                        style={{
                                                            textAlign: "right",
                                                        }}
                                                    >
                                                        <FormattedNumber
                                                            value={
                                                                transaction.amount
                                                            }
                                                            style="currency"
                                                            currency="USD"
                                                        />
                                                    </td>
                                                    <td
                                                        style={{
                                                            textAlign: "center",
                                                        }}
                                                    >
                                                        <ButtonBar
                                                            parameter={transaction.transactionId.toString()}
                                                            handleGenerate={
                                                                this
                                                                    .handleGenerate
                                                            }
                                                            submitting={
                                                                this.state
                                                                    .submitting
                                                            }
                                                            isPaymentHistoryForm
                                                        />
                                                    </td>
                                                </tr>
                                            );
                                        }
                                    )}
                                </tbody>
                            </Table>
                            {totalPages > 1 && (
                                <div className="text-center">
                                    <PaginationBar
                                        first
                                        last
                                        prev
                                        activePage={pageNumber}
                                        next
                                        totalPages={totalPages}
                                        maxPages={5}
                                        onSelect={this.handleSelect}
                                    />
                                </div>
                            )}
                        </div>
                    </div>
                </Col>
            </Row>
        );
    }
    render() {
        const { application, settings } = this.props;
        const grid = this.buildGrid();
        return (
            <div>
                <Panel bsStyle="primary">
                    <Panel.Heading>
                        {Resources.PaymentHistoryInfo}
                    </Panel.Heading>
                    <Panel.Body>
                        <Row>
                            <Col xs={12}>
                                {this.props.transactions.error && (
                                    <AlertMessage
                                        bsStyle="danger"
                                        showIcon
                                        icon={faExclamationTriangle}
                                        message={{
                                            text: Resources.NotAuthorized,
                                            isSuccess: false,
                                        }}
                                    />
                                )}
                                <div className="well">
                                    <Row>
                                        <Col xs={12} sm={12} md={12} lg={12}>
                                            <section>{grid}</section>
                                        </Col>
                                    </Row>
                                </div>
                                <div className="well">
                                    <Row>
                                        <Col xs={12}>
                                            <ButtonToolbar>
                                                <div className="btn-group btn-group-justified-sm-12 pull-right">
                                                    <Button
                                                        bsClass="btn btn-default btn-justified"
                                                        onClick={
                                                            this
                                                                .handleBackButton
                                                        }
                                                        style={{
                                                            minWidth: "6.5em",
                                                        }}
                                                    >
                                                        {Resources.ButtonBack}
                                                    </Button>
                                                </div>
                                            </ButtonToolbar>
                                        </Col>
                                    </Row>
                                </div>
                            </Col>
                        </Row>
                    </Panel.Body>
                </Panel>
            </div>
        );
    }
}

export default PaymentHistoryForm;
