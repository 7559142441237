import { Reducer } from "redux";
import {
  CONFIGURATION_EBPP_LOAD_SUCCESS,
  CONFIGURATION_LOAD_FAIL,
  CONFIGURATION_LOAD_SUCCESS,
  FailedConfigurationAction,
  ReceiveConfigurationAction,
  ReceiveConfigurationEBPPAction,
} from "../actionTypes/Configuration";
import {
  FailedSettingsAction,
  ReceiveSettingsAction,
  RequestSettingsAction,
  SettingsActions,
  SETTINGS_LOAD,
  SETTINGS_LOAD_FAIL,
  SETTINGS_LOAD_SUCCESS,
} from "../actionTypes/Settings";
import { ISettings } from "../types";

export const initialState: ISettings = {
  autoSendPaymentEmail: false,
  isLoaded: false,
  isLoading: false,
  isSuccessful: false,
  achIsAccepted: false,
  achFeeIsAbsorbed: false,
  achFeeDescription: "",
  achPaymentTermsTemplateId: 0,
  addButtonPrompt: "Add Bill",
  addressIsRequired: false,
  allowAutoBillPay: false,
  allowBillAmountToBeEdited: false,
  allowBillOverpayment: false,
  allowBlindPayments: false,
  allowMultipleBillPayments: false,
  americanExpressDebitIsAccepted: false,
  americanExpressIsAccepted: false,
  availableBillsPrompt: "Available Bills",
  bannerImage: null,
  billDueDateIsHidden: false,
  billInstructionsPrompt: "Please enter your bill information.",
  billSearchInstructionsPrompt: "Please enter your search criteria.",
  billsToPayPrompt: "Bills to Pay",
  billTypes: null,
  businessNameIsVisible: false,
  businessNameIsRequired: false,
  businessNameAutofillEnabled: false,
  cancelUrl: "",
  chargeConvenienceFee: false,
  cityIsRequired: false,
  convenienceFeeLabel: "Bolletta Fee",
  countryIsRequired: false,
  creditIsAccepted: false,
  creditPaymentTermsTemplateId: 0,
  customFooterHtml: null,
  dataPostDeclineMessage: null,
  debitIsAccepted: false,
  debitPaymentTermsTemplateId: 0,
  defaultPaymentOption: "Credit",
  desktopLogo: "",
  desktopLogoOnly: false,
  desktopCustomName: "",
  discoverIsAccepted: false,
  discoverDebitIsAccepted: false,
  emailIsRequired: false,
  enableLanguageSupport: false,
  enableSpanish: false,
  enableChinese: false,
  enableVietnamese: false,
  enableArmenian: false,
  enableKorean: false,
  enableMyAccount: false,
  enableNewBillNotifications: false,
  enableTextNotifications: false,
  enableText2Pay: false,
  error: null,
  feeIsSameTransaction: false,
  firstNameIsRequired: false,
  footerImageUrl: "",
  friendlyName: "Bolletta Billing Solutions",
  fromAddress: "",
  futureDatedPaymentLabel: "Payment will process on",
  futureDatedPaymentsAllowed: false,
  futureDatedPaymentsNumberOfDaysIntoFutureAllowed: 0,
  includeConvenienceFeeWithBasePayment: true,
  lastNameIsRequired: false,
  links: [],
  logoUrl: "",
  l2DataEnabled: false,
  masterCardDebitIsAccepted: false,
  masterCardIsAccepted: false,
  maxPaymentAmount: 0,
  merchantName: "Bolletta Billing Solutions",
  merchantNameOnDocuments: "Bolletta Billing Solutions",
  middleNameIsRequired: false,
  minPaymentAmount: 0,
  mobileLogo: "",
  mobileLogoOnly: false,
  mobileCustomName: "",
  onlyChecking: false,
  phoneIsRequired: false,
  postalCodesToDeny: null,
  postalIsRequired: false,
  requireLoginEBPP: false,
  requireReCaptcha: false,
  returnUrl: "",
  sameTransactionReplacementText: null,
  searchForBillsPrompt: null,
  securePayMOTOCancelUrl: "",
  securePayMOTOReturnUrl: "",
  sendACHReturnEmailsToPayor: false,
  showReceipt: false,
  showTokenReceipt: false,
  skipBillsPage: false,
  skipPayorPage: false,
  stateIsRequired: false,
  statesToDeny: null,
  stylesheet: null,
  timeZone: "",
  tokenReplacementText: "AutoPay",
  visaDebitFee: null,
  visaDebitIsAccepted: false,
  visaFee: null,
  visaFeeIsPercentage: false,
  visaIsAccepted: false,
  webPaymentsAppMessage: "",
  webPaymentsCustomerAccountLoginMessage: "",
  maintenance: null,
  maintenanceFeatureEnabled: false,
  billSearchType: "Bolletta",
  receiptFinishButtonCaption: "Finish",
  payByAchPrompt: "eCheck",
  googlePayWebEnabled: false,
  googlePayMerchantID: "",
  heartlandMID: "",
  achError: false,
  environment: "",
};

const settingsLoadAction = (
  state: ISettings,
  action: RequestSettingsAction
): ISettings => {
  return {
    ...state,
    isLoading: true,
    isLoaded: false,
    error: null,
  };
};

const settingsLoadSuccessAction = (
  state: ISettings,
  action: ReceiveSettingsAction
): ISettings => {
  return {
    ...state,
    ...action.data,
    isLoading: false,
    isLoaded: true,
    error: null,
  };
};

const settingsLoadFailAction = (
  state: ISettings,
  action: FailedSettingsAction
): ISettings => {
  return {
    ...state,
    isLoading: false,
    isLoaded: false,
    error: action.data,
  };
};

const configurationLoadSuccessAction = (
  state: ISettings,
  action: ReceiveConfigurationAction
): ISettings => {
  return {
    ...state,
    ...action.data.settings,
    isLoading: false,
    isLoaded: true,
    error: null,
  };
};

const configurationEbppLoadSuccessAction = (
  state: ISettings,
  action: ReceiveConfigurationEBPPAction
): ISettings => {
  return {
    ...state,
    ...action.data.settings,
    isLoading: false,
    isLoaded: true,
    error: null,
  };
};

const configurationLoadFailAction = (
  state: ISettings,
  action: FailedConfigurationAction
): ISettings => {
  return {
    ...state,
    isLoading: false,
    isLoaded: false,
    error: action.data,
  };
};

export const reducer: Reducer<ISettings> = (
  state: ISettings = initialState,
  action: SettingsActions
) => {
  switch (action.type) {
    case SETTINGS_LOAD:
      return settingsLoadAction(state, action);
    case SETTINGS_LOAD_SUCCESS:
      return settingsLoadSuccessAction(state, action);
    case SETTINGS_LOAD_FAIL:
      return settingsLoadFailAction(state, action);
    case CONFIGURATION_LOAD_SUCCESS:
      return configurationLoadSuccessAction(state, action);
    case CONFIGURATION_EBPP_LOAD_SUCCESS:
      return configurationEbppLoadSuccessAction(state, action);
    case CONFIGURATION_LOAD_FAIL:
      return configurationLoadFailAction(state, action);
    default:
      return state;
  }
};
