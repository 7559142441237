import React from "react";

import { Modal, ButtonToolbar, Button } from "react-bootstrap";
import * as Resources from "../../../resources";

export interface ConfirmModalProps {
    headerText: string | JSX.Element;
    message: string | JSX.Element;
    show: boolean;
    onClose: () => void;
    onConfirm: () => void;
}

export const ConfirmModal = (props: ConfirmModalProps) => {
    return (
        <Modal show={props.show} onHide={props.onClose}>
            <Modal.Header closeButton>
                <Modal.Title>{props.headerText}</Modal.Title>
            </Modal.Header>
            <Modal.Body>{props.message}</Modal.Body>
            <Modal.Footer>
                <div className="pull-right">
                    <ButtonToolbar>
                        <Button onClick={props.onClose}>
                            {Resources.ButtonNo}
                        </Button>
                        <Button
                            bsStyle="primary"
                            onClick={props.onConfirm}
                            style={{ minWidth: "6.5em" }}
                        >
                            {Resources.ButtonYes}
                        </Button>
                    </ButtonToolbar>
                </div>
            </Modal.Footer>
        </Modal>
    );
};
