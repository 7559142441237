import {
    IApplication,
    IBill,
    IConvenienceFee,
    IPayment,
    IPayor,
    IReceipt,
    IUser,
} from "../types";
import { ICardType } from "../types/ICardType";
import { IFutureDatedPaymentResponse } from "../types/IFutureDatedPaymentResponse";

export function makePayment(
    application: IApplication,
    merchantName: string,
    languageId: number,
    bills: IBill[],
    merchantCustomerID: string,
    payor: IPayor,
    payment: IPayment,
    accepted: boolean,
    shareTokenWithGroup: boolean,
    recaptcha?: string | null
): Promise<IReceipt> {
    const baseUrl = process.env.REACT_APP_API_BASEURL;

    return fetch(
        `${baseUrl}/webapi/api/MakePayment?merchantName=${merchantName}&language=${languageId}`,
        {
            method: "POST",
            mode: baseUrl ? "cors" : "same-origin",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({
                application: application,
                merchantCustomerID: merchantCustomerID,
                bills: bills,
                payor: payor,
                payment: payment,
                paymentTerms: {
                    accepted,
                    captcha: recaptcha,
                },
                shareTokenWithGroup,
            }),
        }
    ).then((response) => response.json() as Promise<IReceipt>);
}

export function getConvenienceFee(
    application: IApplication,
    merchantName: string,
    languageId: number,
    baseAmount: number,
    paymentMethod: number,
    cardProcessingMethod: number,
    cardNumber: string,
    routingNumber: string
): Promise<IConvenienceFee> {
    const baseUrl = process.env.REACT_APP_API_BASEURL;

    return fetch(
        `${baseUrl}/webapi/api/ConvenienceFee?merchantName=${merchantName}`,
        {
            method: "POST",
            mode: baseUrl ? "cors" : "same-origin",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({
                application: application,
                language: languageId,
                baseAmount: baseAmount,
                paymentMethod: paymentMethod,
                cardProcessingMethod: cardProcessingMethod,
                cardNumber: cardNumber,
                routingNumber: routingNumber,
            }),
        }
    ).then((response) => response.json() as Promise<IConvenienceFee>);
}

export function getCardType(
    application: IApplication,
    merchantName: string,
    languageId: number,
    cardProcessingMethod: number,
    cardNumber: string
): Promise<ICardType> {
    const baseUrl = process.env.REACT_APP_API_BASEURL;

    return fetch(
        `${baseUrl}/webapi/api/CardType?merchantName=${merchantName}`,
        {
            method: "POST",
            mode: baseUrl ? "cors" : "same-origin",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({
                application: application,
                language: languageId,
                cardProcessingMethod: cardProcessingMethod,
                cardNumber: cardNumber,
            }),
        }
    ).then((response) => response.json() as Promise<ICardType>);
}

export function disableSecurePayGuid(
    application: IApplication,
    merchantName: string
): Promise<string> {
    const baseUrl = process.env.REACT_APP_API_BASEURL;

    return fetch(
        `${baseUrl}/webapi/api/DisableSecurePayGuid?merchantName=${merchantName}&guid=${application.guid}`,
        {
            mode: baseUrl ? "cors" : "same-origin",
        }
    ).then((response) => response.json() as Promise<string>);
}

export function schedulePayment(
    application: IApplication,
    merchantName: string,
    languageId: number,
    bills: IBill[],
    user: IUser,
    payor: IPayor,
    payment: IPayment,
    accepted: boolean,
    shareTokenWithGroup: boolean,
    recaptcha?: string | null
): Promise<IFutureDatedPaymentResponse> {
    const baseUrl = process.env.REACT_APP_API_BASEURL;

    return fetch(
        `${baseUrl}/webapi/api/SchedulePayment?merchantName=${merchantName}&language=${languageId}`,
        {
            method: "POST",
            mode: baseUrl ? "cors" : "same-origin",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({
                application: application,
                authToken: user.authToken,
                merchantCustomerID: user.merchantCustomerID,
                bills: bills,
                payor: payor,
                payment: payment,
                paymentTerms: {
                    accepted,
                    captcha: recaptcha,
                },
                shareTokenWithGroup,
            }),
        }
    ).then(
        (response) => response.json() as Promise<IFutureDatedPaymentResponse>
    );
}
