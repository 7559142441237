import React from "react";
import * as Resources from "../../resources";
import {
    Panel,
    Row,
    Col,
    Table,
    Button,
    ButtonToolbar,
    ButtonGroup,
    Collapse,
    Form,
} from "react-bootstrap";
import { IBill, IBillType, ICustomField } from "../../types";
import ResultsButton from "../Search/ResultsButton";
import BillTotal from "../Layout/BillTotal";
import { CurrencyInput } from "../Input";
import { GetMaxPaymentAmount } from "../../utils/BillHelpers";
import { totalBillAmount } from "../../utils/BollettaMath";
import { FormattedNumber, FormattedDate } from "react-intl";
import { LabelAmountDue } from "../../resources";
import { CheckFieldValidity } from "../../utils/Validation";

export class MyBillsForm extends React.Component<any, any> {
    constructor(props: any) {
        super(props);

        this.state = {
            showAdd: false,
            showModal: false,
            showAutoPayModal: false,
            deleteParam: "",
            billsToPay: props.ebppBills.map((bill: IBill) => ({ ...bill })),
        };

        this.onContinue = this.onContinue.bind(this);
        this.checkIfSelected = this.checkIfSelected.bind(this);
        this.handleAddToBills = this.handleAddToBills.bind(this);
        this.addAllBills = this.addAllBills.bind(this);
        this.removeAllBills = this.removeAllBills.bind(this);
        this.onChangeBillAmount = this.onChangeBillAmount.bind(this);
    }

    public calculateAmount = function (record: any) {
        if (record.useCustom) {
            return record.customAmount;
        }
        return (
            record.customAmount ||
            (record.amountDue < 0
                ? Number(0).toFixed(2)
                : record.amountDue.toFixed(2))
        );
    };

    public componentWillMount(): void {
        const {
            application,
            settings,
            user,
            loadUserAccounts,
            bills,
            ebppBills,
        } = this.props;

        if (application.guid && application.expired) {
            const path = `/${settings.merchantName}/expired/${application.guid}`;
            this.props.history.push(path);
            return;
        }

        if (application.guid && application.canceled) {
            const path = `/${settings.merchantName}/canceled/${application.guid}`;
            this.props.history.push(path);
            return;
        }

        if (ebppBills.length === 0) {
            this.props.searchEBPP(
                settings.merchantName,
                this.props.application.guid,
                this.props.application,
                user.merchantCustomerID
            );
        }

        if (bills.length !== 0) {
            this.props.clearBills();
        }
    }

    componentWillReceiveProps(nextProps: any) {
        if (this.props.ebppBills.length !== nextProps.ebppBills.length) {
            this.setState({
                billsToPay: nextProps.ebppBills,
            });
        }
    }

    onContinue(event: any) {
        event.preventDefault();
        const { settings } = this.props;

        const path = `/${settings.merchantName}/payor`;
        this.props.history.push(path);
        return true;
    }

    disablePayButton() {
        const { settings } = this.props;
        const totalAmount = totalBillAmount(this.props.bills);
        if (settings.minPaymentAmount > totalAmount) {
            return true;
        }
        if (settings.maxPaymentAmount < totalAmount) {
            return true;
        }
        if (this.checkIfError()) {
            return true;
        }

        return this.props.bills.length === 0 ? true : false;
    }

    checkIfSelected(bill: IBill) {
        return this.props.bills.some(function (el: IBill) {
            return el.id.toString() === bill.id.toString();
        });
    }

    checkIfError() {
        return this.state.billsToPay.some(function (el: IBill) {
            return el.error !== null && el.error !== undefined;
        });
    }

    handleAddToBills(row: IBill) {
        const { createOrUpdate, settings } = this.props;
        createOrUpdate(row);

        if (!settings.allowMultipleBillPayments) {
            const path = `/${settings.merchantName}/payor`;
            this.props.history.push(path);
        }
    }

    deleteFromBills(row: IBill) {
        const { deleteBill } = this.props;

        deleteBill(row.id);
    }

    addAllBills() {
        const { createOrUpdate } = this.props;

        this.props.clearBills();
        this.state.billsToPay.forEach(function (element: IBill) {
            createOrUpdate(element);
        });
    }

    removeAllBills() {
        this.props.clearBills();
    }

    //Need to modify the bill data in state that is rendered to the grid, as well as the bill in props
    onChangeBillAmount(bill: IBill, e: any) {
        const newBillsArr = [...this.state.billsToPay];

        //Lookup bill in state to modify their value
        const index = newBillsArr.findIndex(
            (obj) => obj.id.toString() === bill.id.toString()
        );
        newBillsArr[index]["customAmount"] = e.target.value;

        this.setState({
            billsToPay: newBillsArr,
        });

        //Update the bill in redux if it exists (the modified bill is currently selected to be paid)
        if (
            this.props.bills.some(function (el: IBill) {
                return el.id === newBillsArr[index].id;
            })
        ) {
            this.props.createOrUpdate(newBillsArr[index]);
        }
    }

    buildBillGrid(): JSX.Element | null {
        const { billTypes } = this.props;
        const showIDColumn = (field: ICustomField): boolean =>
            field.label && !field.isSearchOnly ? true : false;
        return (
            <Form inline>
                {billTypes.map((billType: IBillType, index: number) => {
                    const billsByType = this.state.billsToPay.filter(
                        (bill: IBill) => bill.billType === billType.name
                    );
                    if (billsByType.length === 0) {
                        return null;
                    }

                    return (
                        <Panel key={index} default>
                            <Panel.Heading>
                                <strong>{billType.name}</strong>
                            </Panel.Heading>
                            <Panel.Body>
                                <Table
                                    className="rtable rtable--flip"
                                    condensed
                                    bordered
                                >
                                    <thead>
                                        <tr>
                                            {showIDColumn(billType.id1) ? (
                                                <th>{billType.id1.label}</th>
                                            ) : null}
                                            {showIDColumn(billType.id2) ? (
                                                <th>{billType.id2.label}</th>
                                            ) : null}
                                            {showIDColumn(billType.id3) ? (
                                                <th>{billType.id3.label}</th>
                                            ) : null}
                                            {showIDColumn(billType.id4) ? (
                                                <th>{billType.id4.label}</th>
                                            ) : null}
                                            <th style={{ width: "100px" }}>
                                                Due Date
                                            </th>
                                            <th style={{ width: "120px" }}>
                                                {billType.amount.label}
                                            </th>
                                            {this.props.settings
                                                .allowBillAmountToBeEdited ? (
                                                <th
                                                    style={{
                                                        width: "160px",
                                                        verticalAlign: "middle",
                                                    }}
                                                >
                                                    <strong>
                                                        {LabelAmountDue}
                                                    </strong>
                                                </th>
                                            ) : null}
                                            <th style={{ width: "125px" }}></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {billsByType.map(
                                            (bill: IBill, index: number) => {
                                                const keyNames =
                                                    Object.keys(bill);

                                                const addBill = () => {
                                                    this.handleAddToBills(bill);
                                                };
                                                const removeBill = () => {
                                                    this.deleteFromBills(bill);
                                                };
                                                const onChange = (e: any) => {
                                                    this.onChangeBillAmount(
                                                        bill,
                                                        e
                                                    );
                                                };
                                                const handleError = function (
                                                    name: any,
                                                    message: any
                                                ) {
                                                    bill.error = message;
                                                };
                                                const handleBlur = function (
                                                    event: any
                                                ) {
                                                    event.preventDefault();
                                                    CheckFieldValidity(
                                                        event.target,
                                                        handleError
                                                    );
                                                };
                                                return (
                                                    <tr key={index}>
                                                        <td
                                                            data-title={
                                                                keyNames[1]
                                                            }
                                                            style={{
                                                                verticalAlign:
                                                                    "middle",
                                                            }}
                                                        >
                                                            {bill.id1}
                                                        </td>
                                                        {showIDColumn(
                                                            billType.id2
                                                        ) ? (
                                                            <td
                                                                style={{
                                                                    verticalAlign:
                                                                        "middle",
                                                                }}
                                                            >
                                                                {bill.id2}
                                                            </td>
                                                        ) : null}
                                                        {showIDColumn(
                                                            billType.id3
                                                        ) ? (
                                                            <td
                                                                style={{
                                                                    verticalAlign:
                                                                        "middle",
                                                                }}
                                                            >
                                                                {bill.id3}
                                                            </td>
                                                        ) : null}
                                                        {showIDColumn(
                                                            billType.id4
                                                        ) ? (
                                                            <td
                                                                style={{
                                                                    verticalAlign:
                                                                        "middle",
                                                                }}
                                                            >
                                                                {bill.id4}
                                                            </td>
                                                        ) : null}

                                                        <td
                                                            style={{
                                                                width: "100px",
                                                                verticalAlign:
                                                                    "middle",
                                                            }}
                                                        >
                                                            <FormattedDate
                                                                value={
                                                                    bill.dueDate
                                                                }
                                                                year="numeric"
                                                                month="2-digit"
                                                                day="2-digit"
                                                            />
                                                        </td>
                                                        <td
                                                            style={{
                                                                width: "120px",
                                                                verticalAlign:
                                                                    "middle",
                                                            }}
                                                        >
                                                            <FormattedNumber
                                                                value={
                                                                    bill.amountDue ||
                                                                    0
                                                                }
                                                                style="currency"
                                                                currency="USD"
                                                            />
                                                        </td>
                                                        {this.props.settings
                                                            .allowBillAmountToBeEdited && (
                                                            <td
                                                                style={{
                                                                    width: "160px",
                                                                    verticalAlign:
                                                                        "middle",
                                                                }}
                                                            >
                                                                <div
                                                                    style={{
                                                                        verticalAlign:
                                                                            "middle",
                                                                    }}
                                                                >
                                                                    <CurrencyInput
                                                                        name={[
                                                                            "customAmount",
                                                                            bill.id,
                                                                        ].join(
                                                                            "_"
                                                                        )}
                                                                        value={this.calculateAmount(
                                                                            bill
                                                                        )}
                                                                        maxAmount={GetMaxPaymentAmount(
                                                                            this
                                                                                .props
                                                                                .settings
                                                                                .allowBillOverpayment!,
                                                                            this
                                                                                .props
                                                                                .settings
                                                                                .maxPaymentAmount!,
                                                                            bill.amountDue ||
                                                                                bill.amount
                                                                        )}
                                                                        minAmount={
                                                                            this
                                                                                .props
                                                                                .settings
                                                                                .minPaymentAmount
                                                                        }
                                                                        error={
                                                                            bill.error
                                                                        }
                                                                        onBlur={
                                                                            handleBlur
                                                                        }
                                                                        onChange={
                                                                            onChange
                                                                        }
                                                                        onError={
                                                                            handleError
                                                                        }
                                                                        disabled={
                                                                            (bill.amountDue ===
                                                                                null ||
                                                                                bill.amountDue <=
                                                                                    0) &&
                                                                            !this
                                                                                .props
                                                                                .settings
                                                                                .allowBillOverpayment
                                                                                ? true
                                                                                : false
                                                                        }
                                                                    />
                                                                </div>
                                                            </td>
                                                        )}
                                                        <td
                                                            style={{
                                                                width: "125px",
                                                                verticalAlign:
                                                                    "middle",
                                                            }}
                                                        >
                                                            <div
                                                                style={{
                                                                    verticalAlign:
                                                                        "middle",
                                                                    width: "100%",
                                                                }}
                                                            >
                                                                <ResultsButton
                                                                    settings={
                                                                        this
                                                                            .props
                                                                            .settings
                                                                    }
                                                                    bill={bill}
                                                                    selected={this.checkIfSelected(
                                                                        bill
                                                                    )}
                                                                    deleteFromBills={
                                                                        removeBill
                                                                    }
                                                                    addToBills={
                                                                        addBill
                                                                    }
                                                                />
                                                            </div>
                                                        </td>
                                                    </tr>
                                                );
                                            }
                                        )}
                                    </tbody>
                                </Table>
                            </Panel.Body>
                        </Panel>
                    );
                })}
            </Form>
        );
    }

    buildResponsiveBillGrid(): JSX.Element | null {
        const { billTypes } = this.props;
        const showIDColumn = (field: ICustomField): boolean =>
            field.label && !field.isSearchOnly ? true : false;
        return (
            <Form inline>
                {billTypes.map((billType: IBillType, index: number) => {
                    const billsByType = this.state.billsToPay.filter(
                        (bill: IBill) => bill.billType === billType.name
                    );
                    if (billsByType.length === 0) {
                        return null;
                    }

                    return (
                        <Panel key={index} default>
                            <Panel.Heading>{billType.name}</Panel.Heading>
                            <Panel.Body>
                                {billsByType.map(
                                    (bill: IBill, index: number) => {
                                        const keyNames = Object.keys(bill);
                                        const addBill = () => {
                                            this.handleAddToBills(bill);
                                        };
                                        const removeBill = () => {
                                            this.deleteFromBills(bill);
                                        };
                                        const onChange = (e: any) => {
                                            this.onChangeBillAmount(bill, e);
                                        };
                                        const handleError = function (
                                            name: any,
                                            message: any
                                        ) {
                                            bill.error = message;
                                        };
                                        const handleBlur = function (
                                            event: any
                                        ) {
                                            event.preventDefault();
                                            CheckFieldValidity(
                                                event.target,
                                                handleError
                                            );
                                        };
                                        return (
                                            <Table
                                                key={index}
                                                className="rtable rtable--flip"
                                                condensed
                                                bordered
                                            >
                                                <thead></thead>
                                                <tbody>
                                                    {showIDColumn(
                                                        billType.id1
                                                    ) ? (
                                                        <tr>
                                                            <td
                                                                className="col-xs-6"
                                                                style={{
                                                                    textAlign:
                                                                        "center",
                                                                }}
                                                            >
                                                                <strong>
                                                                    {
                                                                        billType
                                                                            .id1
                                                                            .label
                                                                    }
                                                                </strong>
                                                            </td>
                                                            <td
                                                                className="col-xs-6"
                                                                data-title={
                                                                    keyNames[1]
                                                                }
                                                                style={{
                                                                    textAlign:
                                                                        "center",
                                                                }}
                                                            >
                                                                {bill.id1}
                                                            </td>
                                                        </tr>
                                                    ) : null}
                                                    {showIDColumn(
                                                        billType.id2
                                                    ) ? (
                                                        <tr>
                                                            <td
                                                                className="col-xs-6"
                                                                style={{
                                                                    textAlign:
                                                                        "center",
                                                                }}
                                                            >
                                                                <strong>
                                                                    {
                                                                        billType
                                                                            .id2
                                                                            .label
                                                                    }
                                                                </strong>
                                                            </td>
                                                            <td
                                                                className="col-xs-6"
                                                                data-title={
                                                                    keyNames[1]
                                                                }
                                                                style={{
                                                                    textAlign:
                                                                        "center",
                                                                }}
                                                            >
                                                                {bill.id2}
                                                            </td>
                                                        </tr>
                                                    ) : null}
                                                    {showIDColumn(
                                                        billType.id3
                                                    ) ? (
                                                        <tr>
                                                            <td
                                                                className="col-xs-6"
                                                                style={{
                                                                    textAlign:
                                                                        "center",
                                                                }}
                                                            >
                                                                <strong>
                                                                    {
                                                                        billType
                                                                            .id3
                                                                            .label
                                                                    }
                                                                </strong>
                                                            </td>
                                                            <td
                                                                className="col-xs-6"
                                                                data-title={
                                                                    keyNames[1]
                                                                }
                                                                style={{
                                                                    textAlign:
                                                                        "center",
                                                                }}
                                                            >
                                                                {bill.id3}
                                                            </td>
                                                        </tr>
                                                    ) : null}
                                                    {showIDColumn(
                                                        billType.id4
                                                    ) ? (
                                                        <tr>
                                                            <td
                                                                className="col-xs-6"
                                                                style={{
                                                                    textAlign:
                                                                        "center",
                                                                }}
                                                            >
                                                                <strong>
                                                                    {
                                                                        billType
                                                                            .id4
                                                                            .label
                                                                    }
                                                                </strong>
                                                            </td>
                                                            <td
                                                                className="col-xs-6"
                                                                data-title={
                                                                    keyNames[1]
                                                                }
                                                                style={{
                                                                    textAlign:
                                                                        "center",
                                                                }}
                                                            >
                                                                {bill.id4}
                                                            </td>
                                                        </tr>
                                                    ) : null}
                                                    <tr>
                                                        <td
                                                            className="col-xs-6"
                                                            style={{
                                                                textAlign:
                                                                    "center",
                                                            }}
                                                        >
                                                            <strong>
                                                                Due Date
                                                            </strong>
                                                        </td>
                                                        <td
                                                            className="col-xs-6"
                                                            style={{
                                                                textAlign:
                                                                    "center",
                                                            }}
                                                        >
                                                            <FormattedDate
                                                                value={
                                                                    bill.dueDate
                                                                }
                                                                year="numeric"
                                                                month="2-digit"
                                                                day="2-digit"
                                                            />
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td
                                                            className="col-xs-6"
                                                            style={{
                                                                textAlign:
                                                                    "center",
                                                            }}
                                                        >
                                                            <strong>
                                                                {
                                                                    billType
                                                                        .amount
                                                                        .label
                                                                }
                                                            </strong>
                                                        </td>
                                                        <td
                                                            className="col-xs-6"
                                                            style={{
                                                                textAlign:
                                                                    "center",
                                                            }}
                                                        >
                                                            <FormattedNumber
                                                                value={
                                                                    bill.amountDue ||
                                                                    0
                                                                }
                                                                style="currency"
                                                                currency="USD"
                                                            />
                                                        </td>
                                                    </tr>
                                                    {this.props.settings
                                                        .allowBillAmountToBeEdited && (
                                                        <tr>
                                                            <td
                                                                className="col-xs-6"
                                                                style={{
                                                                    textAlign:
                                                                        "center",
                                                                }}
                                                            >
                                                                <strong>
                                                                    {
                                                                        LabelAmountDue
                                                                    }
                                                                </strong>
                                                            </td>
                                                            <td
                                                                style={{
                                                                    verticalAlign:
                                                                        "middle",
                                                                }}
                                                            >
                                                                <div
                                                                    style={{
                                                                        verticalAlign:
                                                                            "middle",
                                                                    }}
                                                                >
                                                                    {this.props
                                                                        .settings
                                                                        .allowBillAmountToBeEdited && (
                                                                        <CurrencyInput
                                                                            name={[
                                                                                "customAmount",
                                                                                bill.id,
                                                                            ].join(
                                                                                "_"
                                                                            )}
                                                                            value={this.calculateAmount(
                                                                                bill
                                                                            )}
                                                                            maxAmount={GetMaxPaymentAmount(
                                                                                this
                                                                                    .props
                                                                                    .settings
                                                                                    .allowBillOverpayment!,
                                                                                this
                                                                                    .props
                                                                                    .settings
                                                                                    .maxPaymentAmount!,
                                                                                bill.amountDue ||
                                                                                    bill.amount
                                                                            )}
                                                                            minAmount={
                                                                                this
                                                                                    .props
                                                                                    .settings
                                                                                    .minPaymentAmount
                                                                            }
                                                                            error={
                                                                                bill.error
                                                                            }
                                                                            onBlur={
                                                                                handleBlur
                                                                            }
                                                                            onChange={
                                                                                onChange
                                                                            }
                                                                            onError={
                                                                                handleError
                                                                            }
                                                                            disabled={
                                                                                (bill.amountDue ===
                                                                                    null ||
                                                                                    bill.amountDue <=
                                                                                        0) &&
                                                                                !this
                                                                                    .props
                                                                                    .settings
                                                                                    .allowBillOverpayment
                                                                                    ? true
                                                                                    : false
                                                                            }
                                                                        />
                                                                    )}
                                                                </div>
                                                            </td>
                                                        </tr>
                                                    )}
                                                    <tr>
                                                        <td
                                                            className="col-xs-6"
                                                            style={{
                                                                textAlign:
                                                                    "center",
                                                            }}
                                                        ></td>
                                                        <td
                                                            className="col-xs-6"
                                                            style={{
                                                                textAlign:
                                                                    "center",
                                                            }}
                                                        >
                                                            <div>
                                                                <ResultsButton
                                                                    settings={
                                                                        this
                                                                            .props
                                                                            .settings
                                                                    }
                                                                    bill={bill}
                                                                    selected={this.checkIfSelected(
                                                                        bill
                                                                    )}
                                                                    deleteFromBills={
                                                                        removeBill
                                                                    }
                                                                    addToBills={
                                                                        addBill
                                                                    }
                                                                />
                                                            </div>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </Table>
                                        );
                                    }
                                )}
                            </Panel.Body>
                        </Panel>
                    );
                })}
            </Form>
        );
    }

    public render(): JSX.Element {
        const disablePayButton = this.disablePayButton();

        return (
            <div>
                <Panel bsStyle="primary">
                    <Panel.Heading>{Resources.HeaderMyBills}</Panel.Heading>
                    <Panel.Body>
                        <div
                            className="well"
                            style={{ alignContent: "center" }}
                        >
                            <Row>
                                <Col xsHidden sm={12} md={12} lg={12}>
                                    {this.buildBillGrid()}
                                </Col>
                                <Col xs={12} smHidden mdHidden lgHidden>
                                    {this.buildResponsiveBillGrid()}
                                </Col>
                            </Row>
                        </div>
                        {this.props.settings.allowMultipleBillPayments && (
                            <Row>
                                <Col sm={12}>
                                    <div
                                        className="well"
                                        style={{ float: "right" }}
                                    >
                                        <ButtonToolbar>
                                            <ButtonGroup>
                                                {this.state.billsToPay
                                                    .length !==
                                                    this.props.bills.length && (
                                                    <Button
                                                        bsClass="btn btn-primary btn-justified"
                                                        onClick={
                                                            this.addAllBills
                                                        }
                                                        style={{
                                                            minWidth: "6.5em",
                                                            width: "125px",
                                                        }}
                                                    >
                                                        {
                                                            Resources.ButtonAddAllBills
                                                        }
                                                    </Button>
                                                )}
                                                {this.state.billsToPay
                                                    .length ===
                                                    this.props.bills.length && (
                                                    <Button
                                                        bsClass="btn btn-primary btn-justified"
                                                        onClick={
                                                            this.removeAllBills
                                                        }
                                                        style={{
                                                            minWidth: "6.5em",
                                                            width: "125px",
                                                        }}
                                                    >
                                                        {
                                                            Resources.ButtonRemoveAllBills
                                                        }
                                                    </Button>
                                                )}
                                            </ButtonGroup>
                                            <ButtonGroup>
                                                <Button
                                                    bsClass="btn btn-primary btn-justified"
                                                    onClick={this.onContinue}
                                                    disabled={disablePayButton}
                                                    style={{
                                                        minWidth: "6.5em",
                                                        width: "125px",
                                                    }}
                                                >
                                                    {
                                                        Resources.ButtonContinueBillPage
                                                    }{" "}
                                                    {this.props.bills.length >
                                                        0 && (
                                                        <BillTotal
                                                            bills={
                                                                this.props.bills
                                                            }
                                                        />
                                                    )}
                                                </Button>
                                            </ButtonGroup>
                                        </ButtonToolbar>
                                    </div>
                                </Col>
                            </Row>
                        )}
                    </Panel.Body>
                </Panel>
            </div>
        );
    }
}
