import React from "react";
import { FormattedNumber } from "react-intl";
import * as Resources from "../../resources";
import { Table } from "react-bootstrap";
import { IPayment, ISettings } from "../../types";

interface FeeTableOwnProps {
    merchant: ISettings;
    payment: IPayment;
    billTotal: number;
}

class FeeTable extends React.Component<FeeTableOwnProps> {
    constructor(props: FeeTableOwnProps) {
        super(props);
    }

    private setTotalAmount = (
        merchant: ISettings,
        paymentTotal: number | null,
        isACH: boolean,
        billTotal: number
    ) => {
        let totalAmount: number | null = null;
        if (paymentTotal !== null) {
            totalAmount = paymentTotal;
        } else if (isACH && merchant.achFeeIsAbsorbed) {
            totalAmount = billTotal;
        } else if (isACH && !merchant.achFeeIsAbsorbed) {
            totalAmount = paymentTotal;
        } else if (!isACH && !merchant.chargeConvenienceFee) {
            totalAmount = billTotal;
        } else if (!isACH && merchant.chargeConvenienceFee) {
            totalAmount = paymentTotal;
        }
        return totalAmount;
    };

    render() {
        const { merchant, payment, billTotal } = this.props;
        const isAch = payment.method === "checking";
        const totalAmount = this.setTotalAmount(
            merchant,
            payment.total,
            isAch,
            billTotal
        );

        const amount = billTotal ? (
            <FormattedNumber
                value={billTotal}
                style="currency"
                currency="USD"
            />
        ) : null;
        const fee =
            payment.convenienceFee === null ? (
                <i>{Resources.MessagePending}</i>
            ) : (
                <FormattedNumber
                    value={payment.convenienceFee}
                    style="currency"
                    currency="USD"
                />
            );
        const total =
            totalAmount === null ? (
                <i>{Resources.MessagePending}</i>
            ) : (
                <FormattedNumber
                    value={totalAmount}
                    style="currency"
                    currency="USD"
                />
            );

        const isCreditOrDebit =
            payment.method === "credit" || payment.method === "debit";

        const showFee =
            (isAch && !merchant.achFeeIsAbsorbed) ||
            (isCreditOrDebit && merchant.chargeConvenienceFee);

        return (
            <Table bordered>
                <tbody>
                    {showFee && (
                        <tr>
                            <td>
                                <strong>{Resources.LabelAmount}</strong>
                            </td>
                            <td>
                                <span className="pull-right">{amount}</span>
                            </td>
                        </tr>
                    )}
                    {showFee && (
                        <tr>
                            <td>
                                <span className="convenience-fee-label">
                                    {merchant.convenienceFeeLabel}
                                </span>
                            </td>
                            <td>
                                <span className="pull-right">{fee}</span>
                            </td>
                        </tr>
                    )}
                    <tr>
                        <td>
                            <strong>{Resources.LabelTotal}</strong>
                        </td>
                        <td>
                            <span className="pull-right">{total}</span>
                        </td>
                    </tr>
                </tbody>
            </Table>
        );
    }
}

export default FeeTable;
