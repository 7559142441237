import { IConfiguration } from '../types';
import { USER_UPDATE } from './User';
import { IUser } from '../types';

export const CONFIGURATION_LOAD = "redux/configuration/LOAD";
export const CONFIGURATION_LOAD_SUCCESS = "redux/configuration/LOAD_SUCCESS";
export const CONFIGURATION_EBPP_LOAD_SUCCESS = "redux/configuration/EBPP_LOAD_SUCCESS";

export const CONFIGURATION_LOAD_FAIL = "redux/configuration/LOAD_FAIL";

export type RequestConfigurationAction = {
    type: typeof CONFIGURATION_LOAD;
}

export type ReceiveConfigurationAction = {
    type: typeof CONFIGURATION_LOAD_SUCCESS;
    data: IConfiguration;
}

export type ReceiveConfigurationEBPPAction = {
    type: typeof CONFIGURATION_EBPP_LOAD_SUCCESS;
    data: IConfiguration;
}

export type FailedConfigurationAction = {
    type: typeof CONFIGURATION_LOAD_FAIL;
    data: string;
}

export type UpdateUserAction = {
    type: typeof USER_UPDATE;
    data: IUser;
}

export type ConfigurationActions = RequestConfigurationAction | ReceiveConfigurationAction | ReceiveConfigurationEBPPAction | FailedConfigurationAction | UpdateUserAction;