import { IBillDetail } from '../types';
import { LogoutUserAction } from './User';
import { ACCOUNTS_UPDATE } from '../actionTypes/Account';
import { IAccountResponse } from '../types/IAccount';
import { ConfigurationActions } from '../actionTypes/Configuration';

export const BILLDETAILS_LOAD = "redux/BillDetails/LOAD";
export const BILLDETAILS_LOAD_SUCCESS = "redux/BillDetails/LOAD_SUCCESS";
export const BILLDETAILS_LOAD_FAIL = "redux/BillDetails/LOAD_FAIL";
export const BILLDETAILS_CREATE = "redux/BillDetails/CREATE";
export const BILLDETAILS_DELETE = "redux/BillDetails/DELETE";
export const BILLDETAILS_UPDATE = "redux/BillDetails/UPDATE";
export const AUTOPAYTERMS_LOAD = "redux/AUTOPAYTERMS/LOAD";
export const AUTOPAYTERMS_LOAD_SUCCESS = "redux/AUTOPAYTERMS/LOAD_SUCCESS";
export const AUTOPAYTERMS_LOAD_FAIL = "redux/AUTOPAYTERMS/LOAD_FAIL";
export const AUTOPAYTERMS_UPDATE_ACCEPTED = "redux/AUTOPAYTERMS/UPDATE_ACCEPTED";
export const AUTOPAYTERMS_UPDATE_CAPTCHA = "redux/AUTOPAYTERMS/UPDATE_CAPTCHA";
export const AUTOPAYTERMS_RESET = "redux/AUTOPAYTERMS_RESET"

export type RequestBillDetailAction = {
    type: typeof BILLDETAILS_LOAD;
}

export type ReceiveBillDetailAction = {
    type: typeof BILLDETAILS_LOAD_SUCCESS;
    data: IBillDetail[];
}

export type FailedBillDetailAction = {
    type: typeof BILLDETAILS_LOAD_FAIL;
    data: string;
}

export type CreateBillDetailAction = {
    type: typeof BILLDETAILS_CREATE;
    data: IBillDetail[];
}

export type DeleteBillDetailAction = {
    type: typeof BILLDETAILS_DELETE;
    data: IBillDetail[];
}

export type UpdateBillDetailAction = {
    type: typeof BILLDETAILS_UPDATE;
    data: IBillDetail;
}

export type UpdateAccountAction = {
    type: typeof ACCOUNTS_UPDATE;
    data: IAccountResponse;
}

export type RequestAutoPayTermsAction = {
    type: typeof AUTOPAYTERMS_LOAD;
}

export type ReceiveAutoPayTermsAction = {
    type: typeof AUTOPAYTERMS_LOAD_SUCCESS;
    data: string;
}

export type FailedAutoPayTermsAction = {
    type: typeof AUTOPAYTERMS_LOAD_FAIL;
    data: string;
}

export type UpdateAcceptanceAutoPayTermsAction = {
    type: typeof AUTOPAYTERMS_UPDATE_ACCEPTED;
    data: boolean;
}

export type CaptchaAutoPayTermsAction = {
    type: typeof AUTOPAYTERMS_UPDATE_CAPTCHA;
    data: string;
}

export type ResetAutoPayTermsAction = {
    type: typeof AUTOPAYTERMS_RESET;
}

export type BillDetailActions = RequestBillDetailAction | ReceiveBillDetailAction | FailedBillDetailAction | 
    LogoutUserAction | CreateBillDetailAction | UpdateBillDetailAction | DeleteBillDetailAction | UpdateAccountAction | 
    ConfigurationActions | RequestAutoPayTermsAction | ReceiveAutoPayTermsAction | FailedAutoPayTermsAction |
    UpdateAcceptanceAutoPayTermsAction | CaptchaAutoPayTermsAction | ResetAutoPayTermsAction;