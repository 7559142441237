import React from "react";
import { injectIntl } from "react-intl";
import { Input } from "./Input";

const number = new RegExp(/\d{1}/);
const lower = new RegExp(/[a-z]{1}/);
const upper = new RegExp(/[A-Z]{1}/);
const special = new RegExp(/[^a-zA-Z0-9\s]{1}/);
const whitespace = new RegExp(/\s{1}/);

class PasswordInput extends React.Component<any, any> {
    public static defaultProps: Partial<any> = {
        type: "password",
    };

    constructor(props: any) {
        super(props);
        this.handleChange = this.handleChange.bind(this);
        this.handleBlur = this.handleBlur.bind(this);
        this.checkFieldValidity = this.checkFieldValidity.bind(this);
    }

    componentWillReceiveProps(nextProps: any) {
        if (this.props !== nextProps) {
            this.setState({
                value: nextProps.value,
            });
        }
    }

    checkFieldValidity(element: any) {
        const {
            value,
            intl: { formatMessage },
        } = this.props;
        let strongPassword = true;
        if (value.length < 9) {
            strongPassword = false;
        } else if (!number.test(value)) {
            strongPassword = false;
        } else if (!lower.test(value)) {
            strongPassword = false;
        } else if (!upper.test(value)) {
            strongPassword = false;
        } else if (!special.test(value)) {
            strongPassword = false;
        } else if (whitespace.test(value)) {
            strongPassword = false;
        }
        if (strongPassword) {
            element.setCustomValidity("");
        } else {
            element.setCustomValidity(
                formatMessage({ id: "error.passwordStrength" })
            );
        }
    }

    handleBlur(event: any) {
        if (event.target.value) {
            this.checkFieldValidity(event.target);
        }
        if (this.props.onBlur) {
            this.props.onBlur(event);
        }
    }

    handleChange(event: any) {
        if (!event.target.validity.valid) {
            this.checkFieldValidity(event.target);
        }
        if (this.props.onChange) {
            this.props.onChange(event);
        }
    }

    render() {
        return (
            <Input
                {...this.props}
                onChange={this.handleChange}
                onBlur={this.handleBlur}
            />
        );
    }
}

export default injectIntl(PasswordInput);
