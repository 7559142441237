import { checkLuhn } from "./Validation";

function GetNumber(cardNumber: string, digits: number): number {
  cardNumber = cardNumber.replace(/\D/g, "");
  const value = parseInt(cardNumber.substring(0, digits), 10);
  if (isNaN(value)) {
    return -1;
  }
  return value;
}

function isBetweenInclusive(
  cardNumber: string,
  digits: number,
  low: number,
  high: number
): boolean {
  const suffix = GetNumber(cardNumber, digits);

  return suffix >= low && suffix <= high;
}

const visaCardImage = <i className="pay-method-icon pmtype-visa vcenter" />;
const masterCardImage = (
  <i className="pay-method-icon pmtype-mastercard vcenter" />
);
const discoverCardImage = (
  <i className="pay-method-icon pmtype-discover vcenter" />
);
const americanExpressCardImage = (
  <i className="pay-method-icon pmtype-amex vcenter" />
);

export function IsMasterCard(cardNumber: string): boolean {
  return (
    /^(5[1-5])/.test(cardNumber) ||
    isBetweenInclusive(cardNumber, 4, 2221, 2721)
  );
}

export function IsVisa(cardNumber: string): boolean {
  return /^(4)/.test(cardNumber);
}

export function IsDiscoverCard(cardNumber: string): boolean {
  return (
    /^(36|38|39|65|309|6011)/.test(cardNumber) ||
    isBetweenInclusive(cardNumber, 3, 300, 305) ||
    isBetweenInclusive(cardNumber, 3, 644, 649) ||
    isBetweenInclusive(cardNumber, 4, 3528, 3589) ||
    isBetweenInclusive(cardNumber, 6, 622126, 622925)
  );
}

export function IsAmericanExpress(cardNumber: string): boolean {
  return /^(34|37)/.test(cardNumber);
}

export function GetCardLast4(cardNumber: string): string {
  return cardNumber.trim().slice(-4);
}

export function generateMaskFromNumber(cardNumber: string): string {
  const trimmedNumber = cardNumber.trim();

  if (!trimmedNumber || !checkLuhn(trimmedNumber)) return trimmedNumber;

  const chars = trimmedNumber.split("");
  const last4Chars = chars.splice(-4);
  const maskedChars = chars.map((c) => (/[0-9]/.test(c) ? "X" : c));
  const resultingMask = maskedChars.concat(last4Chars);

  return resultingMask.join("");
}

export function GetCardImageByPaymentMethod(
  paymentMethod: number
): string | JSX.Element | null {
  switch (paymentMethod) {
    case 1:
      return visaCardImage;
    case 2:
      return masterCardImage;
    case 3:
      return discoverCardImage;
    case 4:
      return americanExpressCardImage;
    case 5:
      return "JCB";
    case 6:
      return "GSB";
    case 7:
      return "";
    case 8:
      return "eCheck";
    case 9:
      return "Debit";
    case 10:
      return "Cash";
    case 11:
      return visaCardImage;
    case 12:
      return masterCardImage;
    case 13:
      return discoverCardImage;
    case 14:
      return americanExpressCardImage;
    case 15:
      return "ACHCard";
    default:
      return null;
  }
}

export function GetCardImageByNumber(cardNumber: string): JSX.Element | null {
  if (IsVisa(cardNumber)) {
    return visaCardImage;
  }
  if (IsMasterCard(cardNumber)) {
    return masterCardImage;
  }
  if (IsDiscoverCard(cardNumber)) {
    return discoverCardImage;
  }
  if (IsAmericanExpress(cardNumber)) {
    return americanExpressCardImage;
  }
  return null;
}

export function GetCardImageByType(type: string): JSX.Element | null {
    const normalizedType = type.toLowerCase();
  switch (normalizedType) {
    case "visa":
      return visaCardImage;
    case "mastercard":
      return masterCardImage;
    case "discover":
      return discoverCardImage;
    case "american express":
      return americanExpressCardImage;
    case "amex":
      return americanExpressCardImage;
    default:
      return null;
  }
}

export function GetCardName(paymentMethod: number): string | null {
  switch (paymentMethod) {
    case 1:
      return "VISA Credit";
    case 2:
      return "Mastercard Credit";
    case 3:
      return "Discover Credit";
    case 4:
      return "American Express Credit";
    case 5:
      return "JCB";
    case 6:
      return "GSB";
    case 7:
      return "Bank Account";
    case 8:
      return "eCheck";
    case 9:
      return "Debit";
    case 10:
      return "Cash";
    case 11:
      return "Visa Debit";
    case 12:
      return "Mastercard Debit";
    case 13:
      return "Discover Debit";
    case 14:
      return "American Express Debit";
    case 15:
      return "ACHCard";
    default:
      return null;
  }
}

export function GetPaymentMethod(paymentMethod: number): string | null {
  switch (paymentMethod) {
    case 0:
      return "Unassigned";
    case 1:
      return "VisaCredit";
    case 2:
      return "MastercardCredit";
    case 3:
      return "DiscoverCredit";
    case 4:
      return "AmericanExpressCredit";
    case 5:
      return "JCB";
    case 6:
      return "GSB";
    case 7:
      return "ACH";
    case 8:
      return "eCheck";
    case 9:
      return "Debit";
    case 10:
      return "Cash";
    case 11:
      return "VisaDebit";
    case 12:
      return "MastercardDebit";
    case 13:
      return "DiscoverDebit";
    case 14:
      return "AmericanExpressDebit";
    case 15:
      return "ACHCard";
    default:
      return null;
  }
}

export function GetCpcDescription(cpcInd: string): string {
  if (cpcInd === "S") {
    return "Purchasing Card";
  }
  if (cpcInd === "B") {
    return "Business Card";
  }
  return "Corporate Card";
}
