import {
    UserActions,
    USER_LOGOUT,
    USER_UPDATE,
    USER_VERIFIED,
} from "../actionTypes/User";
import { updateUserAutopay, updateUserProfile } from "../api/User";
import * as Resources from "../resources";
import {
    IApplication,
    IAppThunkAction,
    IPayor,
    IProfile,
    IUser,
} from "../types";

export const updateProfile =
    (
        application: IApplication,
        merchantName: string,
        authToken: string,
        payor: IPayor,
        onError: (text: string | JSX.Element) => void,
        onSuccess: (email: string) => void,
        password: string | null = null
    ): IAppThunkAction<UserActions> =>
    (dispatch) => {
        updateUserProfile(application, merchantName, authToken, payor, password)
            .then((result) => {
                if (result.isSuccessful) {
                    dispatch({
                        type: USER_UPDATE,
                        data: {
                            profile: {
                                firstName: result.profile.firstName,
                                middleName: result.profile.middleName,
                                lastName: result.profile.lastName,
                                email: result.profile.email,
                                address: result.profile.address,
                                country: result.profile.country,
                                state: result.profile.state,
                                city: result.profile.city,
                                postal: result.profile.postal,
                                phone: result.profile.phone,
                                phoneCountry: result.profile.phoneCountry,
                                mobilePhone: result.profile.mobilePhone,
                                mobilePhoneCountry:
                                    result.profile.mobilePhoneCountry,
                            } as IProfile,
                        } as IUser,
                    });
                    if (onSuccess) {
                        onSuccess(result.profile.email);
                    }
                } else {
                    if (onError) {
                        onError(
                            result.messages[0] || Resources.AjaxErrorMessage
                        );
                    }
                }
            })
            .catch((error) => {
                if (onError) {
                    onError(error.message || error.exceptionMessage);
                }
            });
    };

export const updateBillDetail =
    (
        application: IApplication,
        merchantName: string,
        user: IUser,
        onContinue: () => void
    ): IAppThunkAction<UserActions> =>
    (dispatch) => {
        updateUserAutopay(application, merchantName, user)
            .then((result) => {
                if (result.isSuccessful) {
                    dispatch({ type: USER_UPDATE, data: result });
                }
                if (onContinue) {
                    onContinue();
                }
            })
            .catch(() => {
                if (onContinue) {
                    onContinue();
                }
            });
    };

export const updateUser =
    (user: IUser): IAppThunkAction<UserActions> =>
    (dispatch) => {
        dispatch({ type: USER_UPDATE, data: user });
    };

export const userVerified =
    (value: boolean): IAppThunkAction<UserActions> =>
    (dispatch) => {
        dispatch({ type: USER_VERIFIED, data: value });
    };

export const logout = (): IAppThunkAction<UserActions> => (dispatch) => {
    dispatch({ type: USER_LOGOUT });
};
