import React, { Fragment } from "react";
import { History } from "history";

import { Button } from "react-bootstrap";
import { ConfirmModal } from "../Layout/Modals/ConfirmModal";
import * as Resources from "../../resources";
import { Post } from "../../utils/Post";
import { disableSecurePayGuid } from "../../api/Payment";
import { IApplication, ISettings } from "../../types";

export interface CancelButtonProps {
    application: IApplication;
    settings: ISettings;
    history: History;
    disabled?: boolean;
    block?: boolean;
    cancel: () => void;
}

export interface CancelButtonState {
    showModal: boolean;
}

export class CancelButton extends React.Component<
    CancelButtonProps,
    CancelButtonState
> {
    public static defaultProps: Partial<CancelButtonProps> = {
        disabled: false,
    };

    constructor(props: CancelButtonProps) {
        super(props);

        this.state = {
            showModal: false,
        };

        this.handleOpen = this.handleOpen.bind(this);
        this.handleClose = this.handleClose.bind(this);
        this.handleCancel = this.handleCancel.bind(this);
    }

    handleClose() {
        this.setState({
            showModal: false,
        });
    }

    handleOpen(event: any) {
        event.preventDefault();
        this.setState({
            showModal: true,
        });
    }

    handleCancel(/* event: any */) {
        //event.preventDefault();
        const { application, settings, cancel } = this.props;

        this.setState({
            showModal: false,
        });

        cancel();
        disableSecurePayGuid(application, settings.merchantName);

        if (application.id === 33) {
            this.props.history.push(`/${settings.merchantName}`);
        } else {
            const data = {
                GUID: application.guid,
                DataPostMode: "Cancel",
            };

            if (application.id === 35) {
                Post(settings.securePayMOTOCancelUrl, data);
            } else {
                Post(settings.cancelUrl, data);
            }
        }
    }

    render() {
        const { showModal } = this.state;
        const {
            application: { securePayPaymentType },
        } = this.props;
        const buttonText =
            securePayPaymentType === 3
                ? Resources.ButtonCancel
                : Resources.ButtonCancelPayment;
        const headerText =
            securePayPaymentType === 3
                ? Resources.HeaderCancelToken //  "Cancel Saving Account"
                : Resources.HeaderCancel;
        const bodyText =
            securePayPaymentType === 3
                ? Resources.MessageCancelTokenDialog // "Are you sure you want to cancel saving this account?"
                : Resources.MessageCancelDialog;
        /* if (securePayPaymentType === 3) {
            buttonText = Resources.ButtonCancel;
        } */

        return (
            <Fragment>
                <Button
                    disabled={this.props.disabled}
                    onClick={this.handleOpen}
                >
                    {buttonText}
                </Button>
                {showModal && (
                    <ConfirmModal
                        headerText={headerText}
                        message={bodyText}
                        show={showModal}
                        onClose={this.handleClose}
                        onConfirm={this.handleCancel}
                    />
                )}
            </Fragment>
        );
    }
}
