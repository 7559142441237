import { getSettingsForApplication } from "../api/Settings";
import {
  SETTINGS_LOAD,
  SETTINGS_LOAD_SUCCESS,
  SETTINGS_LOAD_FAIL,
  SettingsActions,
} from "../actionTypes/Settings";
import { IApplication, IAppThunkAction } from "../types";

export const loadSettings =
  (
    merchantName: string,
    intlId: number,
    application: IApplication
  ): IAppThunkAction<SettingsActions> =>
  (dispatch) => {
    getSettingsForApplication(merchantName, intlId, application)
      .then((settings) =>
        dispatch({ type: SETTINGS_LOAD_SUCCESS, data: settings })
      )
      .catch((error) => dispatch({ type: SETTINGS_LOAD_FAIL, data: error }));

    dispatch({ type: SETTINGS_LOAD });
  };
