import {
    faCheck,
    faEnvelope,
    faExclamation,
    faPrint,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import {
    Alert,
    Button,
    ButtonToolbar,
    Col,
    Form,
    Modal,
    Row,
    Table,
} from "react-bootstrap";
import { FormattedNumber, FormattedTime } from "react-intl";
import { sendReceiptEmail } from "../../api/User";
import * as Resources from "../../resources";
import {
    IApplication,
    IGeneratedReceiptResponse,
    IInternationalization,
    IPayor,
    IResponse,
    ISettings,
    ITransaction,
    IUser,
    PaymentMethodEnum,
} from "../../types";
import { IFormError } from "../../types/IFormError";
import { getLanguageCode } from "../../utils/Intl";
import { isNullOrUndefined } from "../../utils/Utilities";
import { CheckFieldValidity, CheckFormValidity } from "../../utils/Validation";
import { EmailInput } from "../Input";
import Recaptcha from "react-google-recaptcha";
import { RECAPTCHA_SITEKEY } from "../../core/constants";
import b64toBlob from "b64-to-blob";

export interface ISuccessfulPaymentProps {
    application: IApplication;
    settings: ISettings;
    isWebReceipt: boolean;
    intl: IInternationalization;
    payor: IPayor;
    transaction: ITransaction;
    user: IUser;
    generate: (
        application: IApplication,
        user: IUser,
        param: number,
        locale: string,
        onError: (text: string) => void,
        onSuccess: (result: IGeneratedReceiptResponse) => void
    ) => void,
    refresh: (fileContents : string) => void,
}

export interface ISuccessfulPaymentState {
    emailAddress: string;
    emailError: string;
    emailSuccess: string;
    showModal: boolean;
    submitting: boolean;
    validationErrors: IFormError;
}

class SuccessfulPayment extends React.Component<
    ISuccessfulPaymentProps,
    ISuccessfulPaymentState
> {
    private recaptcha = React.createRef<Recaptcha>();

    constructor(props: ISuccessfulPaymentProps) {
        super(props);
        this.state = {
            emailAddress: "",
            emailError: "",
            emailSuccess: "",
            showModal: false,
            submitting: false,
            validationErrors: {}
        };
        this.handleBlur = this.handleBlur.bind(this);
        this.handleSend = this.handleSend.bind(this);
        this.handleOpen = this.handleOpen.bind(this);
        this.handlePrint = this.handlePrint.bind(this);
        this.handleClose = this.handleClose.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleError = this.handleError.bind(this);
        this.handleGenerate = this.handleGenerate.bind(this);
        this.checkRecaptcha = this.checkRecaptcha.bind(this);
        this.onResolved = this.onResolved.bind(this);
        this.onGenerateSuccess = this.onGenerateSuccess.bind(this);
        this.onGenerateError = this.onGenerateError.bind(this);
    }

    handlePrint(event: any) {
        event.preventDefault();
        window.print();
    }

    handleClose(event: any) {
        event.preventDefault();
        this.setState({
            showModal: false,
        });
    }

    handleOpen(event: any) {
        event.preventDefault();
        this.setState({
            showModal: true,
            emailAddress: "",
        });
    }

    handleBlur(event: any) {
        CheckFieldValidity(event.target, this.handleError);
    }

    handleError(name: any, message: any) {
        return this.setState({
            validationErrors: {
                ...this.state.validationErrors,
                [name]: message,
            },
        });
    }

    handleGenerate(event: React.MouseEvent<Button>) {
        event.preventDefault();
        this.setState({ submitting: true });
        const { application, user, intl, generate } =
            this.props;
        generate(
            application,
            user,
            this.props.transaction.transactionId,
            getLanguageCode(intl.locale).toString(),
            this.onGenerateError,
            this.onGenerateSuccess
        );
    }
    onGenerateSuccess(result: IGeneratedReceiptResponse) {
        const { refresh } = this.props;
        this.setState({ submitting: false, emailError: ''});
        refresh(result.fileContents);
        const blob = b64toBlob(result.fileContents, "application/pdf");
        if (window.navigator && (window.navigator as any).msSaveOrOpenBlob) {
            (window.navigator as any).msSaveOrOpenBlob(blob);
        } else {
            const objectUrl = URL.createObjectURL(blob);
            window.open(objectUrl, "_blank");
        }
    }

    onGenerateError(text: string) {
        this.setState({
            emailError: text,
            submitting: false,
        });
    }

    handleSend(event: any): void {
        event.preventDefault();

        const {
            application: { id },
            settings: { requireReCaptcha },
        } = this.props;

        if (
            !CheckFormValidity("email-modal", (errors: IFormError) => {
                this.setState({
                    validationErrors: {
                        ...this.state.validationErrors,
                        ...errors,
                    },
                });
            })
        ) {
            return;
        }

        this.setState({
            submitting: true,
            emailError: "",
            emailSuccess: "",
        });

        this.checkRecaptcha();
    }

    async checkRecaptcha() {
        if (this.props.settings.requireReCaptcha && this.recaptcha) {
            const token = await this.recaptcha.current?.executeAsync();

            this.onResolved(token);
        } else {
            this.onResolved();
        }
    }

    onResolved(token?: string | null) {
        const { application, intl, settings } = this.props;
        const { emailAddress } = this.state;
        //const recaptcha = this.recaptcha ? this.recaptcha.getResponse() : "";

        sendReceiptEmail(
            application,
            settings.merchantName,
            getLanguageCode(intl.locale),
            emailAddress,
            token
        )
            .then((result: IResponse) => {
                if (result.isSuccessful) {
                    this.setState({
                        submitting: false,
                        emailSuccess: "Email has been sent",
                        emailError: "",
                    });
                } else {
                    const [firstMessage] = result.messages;

                    this.setState({
                        submitting: false,
                        emailError:
                            firstMessage ||
                            "There was an error and the email was not sent",
                        emailSuccess: "",
                    });
                }
            })
            .catch((reason: any) => {
                this.setState({
                    submitting: false,
                    emailSuccess: "",
                    emailError: reason,
                });
            });

        this.setState({
            showModal: false,
        });
    }

    handleChange(event: any) {
        if (!event.target.validity.valid) {
            CheckFieldValidity(event.target, this.handleError);
        }
        return this.setState({ emailAddress: event.target.value });
    }

    render() {
        const { intl, settings, payor, transaction, isWebReceipt } = this.props;
        const { emailAddress, showModal, validationErrors } = this.state;
        const amountCurrency = (
            <FormattedNumber
                value={transaction.amount}
                style="currency"
                currency="USD"
            />
        );
        const feeCurrency = (
            <FormattedNumber
                value={transaction.feeAmount}
                style="currency"
                currency="USD"
            />
        );
        const totalCurrency = (
            <FormattedNumber
                value={transaction.amount + transaction.feeAmount}
                style="currency"
                currency="USD"
            />
        );
        const isCpcEdit = !isNullOrUndefined(transaction.isCPCEditSuccessful);
        const showAchFeeDescription =
            transaction.paymentMethod === PaymentMethodEnum.ACH &&
            settings.achFeeDescription &&
            settings.achFeeDescription.length > 0;
        const showConvenienceFee =
            transaction.paymentMethod !== PaymentMethodEnum.ACH
                ? settings.chargeConvenienceFee
                : !settings.achFeeIsAbsorbed;

        const successfulPaymentLabel =
            transaction.paymentMethod === PaymentMethodEnum.ACH
                ? Resources.HeaderPaymentSubmitted
                : Resources.HeaderPaymentSucessfull;
        const paymentTime = (
            <FormattedTime
                timeZone={settings.timeZone || undefined}
                value={transaction.transactionDateUTC}
                timeZoneName={"short"}
                year="numeric"
                month="long"
                day="2-digit"
            />
        );

        return (
            <div>
                <Alert bsStyle="success">
                    <Row>
                        <Col xs={7}>
                            <h4>
                                <FontAwesomeIcon icon={faCheck} />
                                &nbsp;&nbsp;{successfulPaymentLabel}&nbsp;[
                                {transaction.transactionId}]
                            </h4>
                            {Resources.MessagePleasePrint} <br />
                            {payor.email && settings.autoSendPaymentEmail &&
                                Resources.MessageEmailReceipt(payor.email)}
                        </Col>
                        {!isWebReceipt && (
                            <Col xs={5}>
                                <div className="pull-right">
                                    <ButtonToolbar>
                                        <Button
                                            id="btnPrintReceipt"
                                            bsStyle="success"
                                            className="margin-bottom-5em success-btn-width"
                                            onClick={this.handleGenerate}
                                            disabled={this.state.submitting}
                                        >
                                            {Resources.ButtonPrint}{" "}
                                            <FontAwesomeIcon icon={faPrint} />
                                        </Button>
                                        <Button
                                            id="btnEmailReceipt"
                                            bsStyle="success"
                                            className="success-btn-width"
                                            onClick={this.handleOpen}
                                        >
                                            {Resources.ButtonEmail}{" "}
                                            <FontAwesomeIcon
                                                icon={faEnvelope}
                                            />
                                        </Button>
                                    </ButtonToolbar>
                                </div>
                            </Col>
                        )}
                    </Row>
                </Alert>
                {this.state.emailError && (
                    <Alert bsStyle="danger">
                        <h4>
                            <FontAwesomeIcon icon={faExclamation} />{" "}
                            <span>{this.state.emailError}</span>
                        </h4>
                    </Alert>
                )}
                {this.state.emailSuccess && (
                    <Alert bsStyle="success">
                        <h4>
                            <FontAwesomeIcon icon={faCheck} />{" "}
                            <span>{this.state.emailSuccess}</span>
                        </h4>
                    </Alert>
                )}
                {isCpcEdit && transaction.isCPCEditSuccessful && (
                    <Alert bsStyle="success">
                        <h4>
                            <FontAwesomeIcon icon={faCheck} />{" "}
                            {Resources.MessageCpcEditSuccessful}
                        </h4>
                    </Alert>
                )}
                {isCpcEdit && !transaction.isCPCEditSuccessful && (
                    <Alert bsStyle="danger">
                        <h4>
                            <FontAwesomeIcon icon={faCheck} />{" "}
                            {Resources.MessageCpcEditFailed}
                        </h4>
                    </Alert>
                )}
                <Row>
                    <Col xs={12} sm={12}>
                        <Table bordered>
                            <tbody>
                                <tr>
                                    <th>{Resources.LabelTransactionId}</th>
                                    <td>
                                        <span className="pull-right">
                                            {transaction.transactionId}
                                        </span>
                                    </td>
                                </tr>
                                {showConvenienceFee && (
                                    <tr>
                                        <th>{Resources.LabelBaseAmount}</th>
                                        <td>
                                            <span className="pull-right">
                                                {amountCurrency}
                                            </span>
                                        </td>
                                    </tr>
                                )}
                                {showConvenienceFee && (
                                    <tr>
                                        <th>
                                            {showAchFeeDescription
                                                ? settings.achFeeDescription
                                                : settings.convenienceFeeLabel}
                                        </th>
                                        <td>
                                            <span className="pull-right">
                                                {feeCurrency}
                                            </span>
                                        </td>
                                    </tr>
                                )}
                                <tr>
                                    <th>{Resources.LabelTotalAmount}</th>
                                    <td>
                                        <span className="pull-right">
                                            {totalCurrency}
                                        </span>
                                    </td>
                                </tr>
                                <tr>
                                    <th>{Resources.LabelMerchantName}</th>
                                    <td>
                                        <span className="pull-right">
                                            {settings.merchantNameOnDocuments ||
                                                settings.friendlyName}
                                        </span>
                                    </td>
                                </tr>
                                <tr>
                                    <th>{Resources.LabelPaymentDateTime}</th>
                                    <td>
                                        <span className="pull-right">
                                            {paymentTime}
                                        </span>
                                    </td>
                                </tr>
                            </tbody>
                        </Table>
                    </Col>
                </Row>
                <Modal show={showModal} onHide={this.handleClose}>
                    <Form id="email-modal">
                        <Modal.Header closeButton>
                            <Modal.Title>
                                {Resources.ModalTitleEmailReceipt}
                            </Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <EmailInput
                                label="Email Address"
                                name="emailAddress"
                                value={emailAddress}
                                required={true}
                                error={validationErrors.emailAddress}
                                onChange={this.handleChange}
                                onBlur={this.handleBlur}
                                onError={this.handleError}
                            />
                            {settings.requireReCaptcha && (
                                <div style={{ display: "none" }}>
                                    <Recaptcha
                                        ref={this.recaptcha}
                                        hl={intl.locale}
                                        sitekey={RECAPTCHA_SITEKEY}
                                        size="invisible"
                                    />
                                </div>
                            )}
                        </Modal.Body>
                        <Modal.Footer>
                            <div className="pull-right">
                                <ButtonToolbar>
                                    <Button onClick={this.handleClose}>
                                        {Resources.ButtonClose}
                                    </Button>
                                    <Button
                                        type="submit"
                                        bsStyle="primary"
                                        onClick={this.handleSend}
                                        style={{ minWidth: "6.5em" }}
                                    >
                                        {Resources.ButtonSend}
                                    </Button>
                                </ButtonToolbar>
                            </div>
                        </Modal.Footer>
                    </Form>
                </Modal>
            </div>
        );
    }
}

export default SuccessfulPayment;
