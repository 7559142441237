//import "core-js";
import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";
import "proxy-polyfill";
import $ from "jquery";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import configureStore from "./configureStore";
import { DEVICE_ID } from "./types";
import { last } from "lodash";
import { Router } from "react-router-dom";
import { PersistGate } from "redux-persist/integration/react";
import { v4 as uuidv4 } from "uuid";
import Layout from "./components/Layout";
import CookieFactory from "./utils/CookieFactory";
import "./css/bootstrap.min.css";
import "./css/site.css";
import "../node_modules/flag-icon-css/css/flag-icons.min.css";
import "../node_modules/@fortawesome/fontawesome-svg-core/styles.css";
import "../node_modules/rc-datepicker/lib/style.css";

(window as any).jQuery = $;
// eslint-disable-next-line @typescript-eslint/no-var-requires
const bootstrap = require("bootstrap");

const cookieFactory = new CookieFactory();
const cookie = cookieFactory.getValue(DEVICE_ID);

// update cookie expiration or create a new cookie
cookieFactory.create(DEVICE_ID, cookie || uuidv4());

const pathName = window.location.pathname.toLowerCase();

const merchant = safeGetMerchant();
const guid = safeGetGuid();

function safeGetMerchant(): string {
    return pathName.split("/")[2] ? pathName.split("/")[2] : "";
}

function safeGetGuid(): string {
    const guidRegex =
        /([g]{0,1}uid)\/([0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$)/;
    const guidMatch = last(guidRegex.exec(pathName));

    return guidMatch ? guidMatch : "";
}

const storeObj = configureStore({ merchant: merchant, guid: guid });
const store = storeObj.store;
const history = storeObj.history;

function renderApp() {
    // This code starts up the React app when it runs in a browser. It sets up the routing configuration
    // and injects the app into a DOM element.
    ReactDOM.render(
        <Provider store={store}>
            <PersistGate loading={null} persistor={storeObj.persistor}>
                <Router history={history} children={<Layout />} />
            </PersistGate>
        </Provider>,
        document.getElementById("root") as HTMLElement
    );
}

renderApp();
