import React from "react";
import { injectIntl } from "react-intl";
import { CheckFieldValidity } from "../../utils/Validation";
import { TextInput } from "./TextInput";

export enum PostalInputPropsEnum {
    intl,
    country,
    blacklist,
    name,
    id,
    onChange,
    value,
}
class PostalInput extends React.Component<any, any> {
    private postalPattern = /(^\d{5}$)|(^\d{5}-\d{4}$)/;
    private postalInput: any;

    constructor(props: any) {
        super(props);
        this.validate = this.validate.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.isUsBasedPostalCode = this.isUsBasedPostalCode.bind(this);
    }

    private isUsBasedPostalCode() {
        const { country } = this.props;
        return country === "US" || country === "USA";
    }

    componentWillMount() {
        if (this.isUsBasedPostalCode()) {
            this.setState({
                pattern: this.postalPattern,
                id: this.props.id || this.props.name,
                value: this.props.value,
            });
        } else {
            this.setState({
                pattern: null,
                id: this.props.id || this.props.name,
                value: this.props.value,
            });
        }
    }

    componentDidMount() {
        const { value } = this.props;
        if (value) {
            this.setState({
                value: value,
            });
            this.validate(value);
        }
    }

    componentWillReceiveProps(nextProps: any) {
        if (this.state.value !== nextProps.value) {
            if (this.isUsBasedPostalCode()) {
                this.setState({
                    pattern: this.postalPattern,
                    value: nextProps.value,
                });
            } else {
                this.setState({
                    pattern: null,
                    value: nextProps.value,
                });
            }
            this.validate(nextProps.value);
        }
    }

    componentDidUpdate(prevProps: any) {
        if (prevProps.value !== this.state.value) {
            if (
                this.props.sameAsPayor &&
                this.props.value &&
                !this.state.blacklistedError
            ) {
                this.postalInput.setCustomValidity("");
                CheckFieldValidity(this.postalInput, this.props.onError);
            }
        }
        if (prevProps.country !== this.props.country) {
            this.postalInput.setCustomValidity("");
            if (this.isUsBasedPostalCode()) {
                const regExp = new RegExp(this.postalPattern);
                if (!regExp.test(this.props.value)) {
                    this.postalInput.setCustomValidity("postalPatternMismatch");
                }
            }
            CheckFieldValidity(this.postalInput, this.props.onError);
        }
    }

    handleChange(event: any) {
        this.validate(event.currentTarget.value);
        if (this.props.onChange) {
            this.props.onChange(event);
        }
    }

    validate(value: any) {
        const el = this.postalInput;
        const {
            intl: { formatMessage },
        } = this.props;
        if (
            this.props.blacklist &&
            value.length >= 5 &&
            this.props.blacklist.includes(value.substr(0, 5))
        ) {
            el.setCustomValidity(
                formatMessage({ id: "form.error.postalNotAllowed" })
            );
            this.setState({ blacklistedError: true });
        } else if (this.state.blacklistedError) {
            el.setCustomValidity("");
            this.setState({ blacklistedError: false });
        }
    }

    render() {
        const { id, pattern, value } = this.state;
        const { name } = this.props;

        const props: any = Object.assign({}, this.props);
        Object.keys(this.props).forEach((key) => {
            if (key in PostalInputPropsEnum) {
                delete props[key];
            }
        });
        const inputRef = (input: any) => (this.postalInput = input);
        return (
            <TextInput
                {...props}
                inputRef={inputRef}
                pattern={pattern}
                name={name}
                onChange={this.handleChange}
                customError="postalPatternMismatch"
                value={value}
                maxLength={20}
            />
        );
    }
}

export default injectIntl(PostalInput);
