export default class CookieFactory {
  public create(name: string, value: string | number): void {
    const expiration = new Date();

    expiration.setFullYear(expiration.getFullYear() + 1);
    document.cookie = `${name}=${value};expires=${expiration.toUTCString()}`;
  }

  public getValue(name: string): string | null {
    const values = document.cookie.split(";");
    const value = values.find((v) => v.startsWith(`${name}=`));

    if (value) {
      return value.split("=")[1];
    }

    return null;
  }
}
