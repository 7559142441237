import { loadPaymentHistory } from "../api/Transactions";
import { IApplication, IAppThunkAction } from "../types";
import {
  PAYMENTHISTORY_LOAD,
  PAYMENTHISTORY_LOAD_FAIL,
  PAYMENTHISTORY_LOAD_SUCCESS,
  TransactionActions,
} from "../actionTypes/Transactions";

export const loadTransactions =
  (
    application: IApplication,
    merchantName: string,
    authToken: string,
    pageNumber: number,
    pageSize: number
  ): IAppThunkAction<TransactionActions> =>
  (dispatch) => {
    loadPaymentHistory(
      application,
      merchantName,
      authToken,
      pageNumber,
      pageSize
    )
      .then((transactions) =>
        dispatch({ type: PAYMENTHISTORY_LOAD_SUCCESS, data: transactions })
      )
      .catch((error: Error) =>
        dispatch({ type: PAYMENTHISTORY_LOAD_FAIL, data: error.message })
      );

    dispatch({ type: PAYMENTHISTORY_LOAD });
  };
