import React from "react";
import { History } from "history";
import {
    Alert,
    Form,
    Row,
    Col,
    Button,
    Tab,
    Nav,
    NavItem,
    HelpBlock,
    Clearfix,
} from "react-bootstrap";
import { CancelButton, StatesList } from "../Input";
import { ButtonBar } from "../Layout/ButtonBar";

import CreditCardPanel from "./CreditCardPanel";
import DebitCardPanel from "./DebitCardPanel";
import BankAccountPanel from "./BankAccountPanel";
import UserAccountPanel from "./UserAccountPanel";
import BillingPanel from "./BillingPanel";
import { CheckFormValidity, CheckFieldValidity } from "../../utils/Validation";
import AutopayTerms from "../BillDetail/AutopayTerms";

import * as Resources from "../../resources";
import { getConvenienceFee } from "../../api/Payment";
import { getCardType } from "../../api/Payment";
import {
    IAccount,
    IAlertMessage,
    IApplication,
    IBankInformation,
    IBillDetail,
    IBill,
    IBillType,
    IMyAccount,
    IPayment,
    IPaymentTerms,
    IPayor,
    ISettings,
    IUser,
    CardProcessingMethodEnum,
    PaymentMethodEnum,
} from "../../types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleNotch } from "@fortawesome/free-solid-svg-icons";
import { faExclamationTriangle } from "@fortawesome/free-solid-svg-icons";
import { IFormError } from "../../types/IFormError";
import AutoPayButton from "../BillDetail/AutoPayButton";
import moment from "moment";

export interface PaymentFormProps {
    application: IApplication;
    settings: ISettings;
    payment: IPayment;
    bills: IBill[];
    billTotal?: number;
    billDetails: IBillDetail[];
    selectedBillDetail?: IBillDetail;
    maxFuturePaymentDays?: number;
    autoPayTerms?: IPaymentTerms;
    billTypes: IBillType[];
    payor: IPayor;
    user: IUser;
    myAccount: IMyAccount;
    userAccounts: IAccount[];
    locale?: string;
    history: History;
    cancel: () => void;
    updateAccepted?: (value: boolean) => void;
    savePayment?: (payment: IPayment) => void;
    saveAccount?: (
        application: IApplication,
        merchantName: string,
        user: IUser,
        account: IAccount,
        verb: "GET" | "POST" | "PUT" | "PATCH",
        onError: (error: string) => void,
        onSuccess: (name: string) => void
    ) => void;
    onSaveBillDetail?: (
        payment: IAccount,
        saveButtonId: string,
        onSubmitBillDetail: (saveButtonId: string) => void
    ) => void;
    onContinueBillDetail?: () => void;
    resetPaymentTerms?: () => void;
    resetAutoPayTerms?: () => void;
    loadAutoPayTerms?: (
        application: IApplication,
        merchantName: string,
        templateId: number,
        methodId: number,
        languageId: number
    ) => void;
    loadUserAccounts?: (
        application: IApplication,
        merchantName: string,
        authToken: string
    ) => void;
    setFutureDatedPayment?: (isFutureDatedPayment: boolean) => void;
    setIsPaymentCompleted?: (isPaymentCompleted: boolean) => void;
    updateMessage?: (newMessage: IAlertMessage) => void;
    showAddAccount?: (show: boolean) => void;
    onSaveAccountError?: (text: string) => void;
    onSaveAccountSuccess?: (nickname: string) => void;
    showLoginModal?: () => void;
}

export interface PaymentFormState {
    autoPayTermsMessage: string;
    payment: IPayment | IAccount;
    bankInformation: IBankInformation;
    id: string;
    dirty: boolean;
    disableContinue: boolean;
    isSubmitting: boolean;
    message: IAlertMessage;
    errors: IFormError;
}

export default class PaymentForm extends React.Component<
    PaymentFormProps,
    PaymentFormState
> {
    private canceled = false;
    constructor(props: any) {
        super(props);

        // When the user is on the Bill Detail or the Manage Accounts page and if
        // the user has saved accounts then we know that this will be selected by
        // default so we can enable the continue button.
        let disableContinue = true;

        if (props.myAccount.active || props.application.isBillDetail) {
            const account = this.defaultOrFirst(props.userAccounts);

            if (account) {
                disableContinue = false;
            }
        }

        this.state = {
            autoPayTermsMessage: "",
            id: "payment-form",
            bankInformation: {} as IBankInformation,
            payment: { ...props.payment },
            dirty: false,
            disableContinue,
            isSubmitting: false,
            message: {} as IAlertMessage,
            errors: {},
        };

        this.handleAutopayClick = this.handleAutopayClick.bind(this);
        this.clearField = this.clearField.bind(this);
        this.handleConvenienceFee = this.handleConvenienceFee.bind(this);
        this.handleSave = this.handleSave.bind(this);
        this.handleBlur = this.handleBlur.bind(this);
        this.handleError = this.handleError.bind(this);
        this.calculateFee = this.calculateFee.bind(this);
        this.handleCancel = this.handleCancel.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handlePhoneChange = this.handlePhoneChange.bind(this);
        this.handleTabSelect = this.handleTabSelect.bind(this);
        this.handleBackButton = this.handleBackButton.bind(this);
        this.handlePhoneCountryChange =
            this.handlePhoneCountryChange.bind(this);
        this.handleMobilePhoneCountryChange =
            this.handleMobilePhoneCountryChange.bind(this);
        this.handleCopyPayorInformation =
            this.handleCopyPayorInformation.bind(this);
        this.handleClearPayorInformation =
            this.handleClearPayorInformation.bind(this);
        this.handleIsValid = this.handleIsValid.bind(this);
        this.handleSubmitted = this.handleSubmitted.bind(this);
        this.handleOnChecked = this.handleOnChecked.bind(this);
        this.handleSaveAccount = this.handleSaveAccount.bind(this);
        this.handleAfterSubmitBillDetail =
            this.handleAfterSubmitBillDetail.bind(this);
        this.onAutoPayTermsAcceptedChanged =
            this.onAutoPayTermsAcceptedChanged.bind(this);
        this.onCloseBillingModal = this.onCloseBillingModal.bind(this);
    }

    public componentDidMount(): void {
        const { application, settings, payor, userAccounts, myAccount } =
            this.props;
        let payment = { ...this.state.payment };

        if (application.guid && application.expired) {
            const path = `/${settings.merchantName}/expired/${application.guid}`;
            this.props.history.push(path);
            return;
        }

        if (application.guid && application.canceled) {
            const path = `/${settings.merchantName}/canceled/${application.guid}`;
            this.props.history.push(path);
            return;
        }

        if (
            !myAccount.active &&
            !application.isBillDetail &&
            !settings.skipPayorPage &&
            !payor.saved
        ) {
            const path = application.guid
                ? `/${settings.merchantName}/payor/${application.guid}`
                : `/${settings.merchantName}/payor`;
            this.props.history.push(path);
            return;
        }
        if (settings.chargeConvenienceFee)
        {
            if (
                (payment as IPayment).method === "credit" &&
                payment.cardNumber
            ) {
                this.calculateFee(
                    application,
                    settings.merchantName,
                    "en",
                    this.props.billTotal,
                    null,
                    CardProcessingMethodEnum.Credit,
                    payment.cardNumber
                );
            }
            if (
                (payment as IPayment).method === "debit" &&
                payment.cardNumber
            ) {
                this.calculateFee(
                    application,
                    settings.merchantName,
                    "en",
                    this.props.billTotal,
                    null,
                    CardProcessingMethodEnum.Debit,
                    payment.cardNumber
                );
            }
            if (
                (payment as IPayment).method === "checking" &&
                payment.routingNumber
            ) {
                this.calculateFee(
                    application,
                    settings.merchantName,
                    "en",
                    this.props.billTotal,
                    PaymentMethodEnum.ACH,
                    null,
                    null
                );
            }
            if (
                (payment as IPayment).method === "saved" &&
                userAccounts.length > 0
            ) {
                const userAccount = userAccounts.find(
                    (x: any) =>
                        x.nickname === (payment as IPayment).customerAccountName
                );
                if (userAccount) {
                    this.calculateFee(
                        application,
                        settings.merchantName,
                        "en",
                        this.props.billTotal,
                        userAccount.paymentMethod,
                        null,
                        null
                    );
                }
            }
            if ((payment as IPayment).method === "credit" && this.useGooglePay())
            {
                const allowedCard = this.getAcceptedCardType();//check settings prop, if visa, amex, master, discover

                this.calculateFee(
                    application,
                    settings.merchantName,
                    "en",
                    this.props.billTotal,
                    allowedCard,
                    CardProcessingMethodEnum.Credit,
                    "",
                );
            }
             
        }
        else 
        {
            this.showGooglePay = true;
            (payment as IPayment).convenienceFee = 0;
            (payment as IPayment).total =
                (this.props.billTotal || 0) +
                ((payment as IPayment).convenienceFee || 0) /* .toFixed(2) */;
            this.setState({
                disableContinue: false,
            });
        }

        if (
            !(payment as IPayment).business &&
            !payment.firstName &&
            !payment.middleName &&
            !payment.lastName &&
            !payment.address &&
            !payment.state &&
            !payment.city &&
            !payment.postal &&
            !payment.phone
        ) {
            payment = this.copyPayorInformation(payment, payor);
        }

        if ((payment as IPayment).googlePayToken !== "") {
            (payment as IPayment).cardNumber = "";
            (payment as IPayment).googlePayToken = "";
            (payment as IPayment).type = "";
        }

        this.setState({
            payment,
            isSubmitting: false,
        });
    }

    public componentWillUnmount(): void {
        const { application, myAccount } = this.props;
        const { dirty, payment } = this.state;
        if (
            !myAccount.active &&
            !application.isBillDetail &&
            dirty &&
            !this.canceled
        ) {
            this.props.savePayment!(payment as IPayment);
        }
    }

    public componentDidUpdate(prevProps: PaymentFormProps): void {
        if (!this.props.user.isLoggedIn && prevProps.user.isLoggedIn) {
            this.setState({
                payment: {
                    ...this.state.payment,
                    saveAccountInMyAccountWallet: this.props.user.isLoggedIn,
                    method: this.props.payment.method,
                } as IPayment,
            });
        }

        if (
            this.props.application.isFutureDatedPayment !==
            prevProps.application.isFutureDatedPayment
        ) {
            if (!this.props.application.isFutureDatedPayment) {
                const errors = { ...this.state.errors };
                if (errors && errors.email) {
                    errors.email = null;
                }

                this.setState({
                    errors: errors,
                });
            }
        }
    }

    private handleAutopayClick() {
        const { application, settings, user } = this.props;
        if (
            settings.enableMyAccount &&
            application.id === 33 &&
            !(user && user.isLoggedIn)
        ) {
            this.props.showLoginModal!();
        } else {
            const path = application.guid
                ? `/${settings.merchantName}/billdetail/${application.guid}`
                : `/${settings.merchantName}/billdetail`;
            this.props.history.push(path);
            return;
        }
    }

    private handleConvenienceFee(): void {
        //This method was stubbed out to provide for the call in 'clearField' function below.
    }

    private clearField(field: any): void {
        this.setState({
            dirty: true,
            payment: {
                ...this.state.payment,
                [field]: "",
            },
            disableContinue: true,
        });
        this.handleConvenienceFee();
    }

    private getAcceptedCardType = () => {
        const { settings } = this.props;

        if (settings.visaIsAccepted) return 1;
        if (settings.masterCardIsAccepted) return 2;
        if (settings.discoverIsAccepted) return 3;
        if (settings.americanExpressIsAccepted) return 4;

        return 0; // if none are accepted
    };

    private calculateFee(
        application: any,
        merchantName: any,
        language: any,
        amount: any,
        paymentMethod: any,
        cardProcessingMethod: any,
        cardNumber: any
    ): void {
        const { settings, myAccount } = this.props;
        if (myAccount.active || application.isBillDetail) {
            if (application.isBillDetail) this.props.resetAutoPayTerms!();
            return;
        }

        this.props.resetPaymentTerms!();
        this.handleError("system", null);
        this.setState({
            disableContinue: true,
        });
        const request = getConvenienceFee(
            application,
            merchantName,
            language,
            amount,
            paymentMethod,
            cardProcessingMethod,
            cardNumber,
            this.state.payment.routingNumber
        );
        request
            .then((result: any) => {
                if (result.isSuccessful) {
                    this.showGooglePay = true;
                    this.setState({
                        payment: {
                            ...this.state.payment,
                            convenienceFee: Number(result.convenienceFee),
                            total:
                                (this.props.billTotal || 0) +
                                (Number(result.convenienceFee) || 0),
                            paymentMethod: result.paymentMethod,
                        } as IPayment,
                        bankInformation: result.bankInformation,
                        disableContinue: false,
                    });
                } else {
                    const helpText = (
                        <HelpBlock>{result.messages[0]}</HelpBlock>
                    );
                    this.handleError("routingNumber", helpText);
                }
            })
            .catch((reason: any) => {
                this.handleError(
                    "system",
                    Resources.MessageConvenienceFee(
                        settings.convenienceFeeLabel
                    )
                );
            });
    }

    private callCardType = (
        application: any,
        merchantName: any,
        language: any,
        cardProcessingMethod: any,
        cardNumber: any,
        setShowCardType: (cardType: string) => void
    ): void => {
        const { settings, myAccount } = this.props;
        if (myAccount.active || application.isBillDetail) {
            if (application.isBillDetail) this.props.resetAutoPayTerms!();
        } else {
            this.props.resetPaymentTerms!();
        }
        this.handleError("system", null);
        const request = getCardType(
            application,
            merchantName,
            language,
            cardProcessingMethod,
            cardNumber
        );
        request
            .then((result: any) => {
                if (result.isSuccessful) {
                    setShowCardType(result.paymentMethod);
                    this.setState({
                        payment: {
                            ...this.state.payment,
                            paymentMethod: result.paymentMethod,
                        } as IPayment,
                    });
                } else {
                    const helpText = (
                        <HelpBlock>{result.messages[0]}</HelpBlock>
                    );
                    this.handleError("cardNumber", helpText);
                }
            })
            .catch((reason: any) => {
                this.handleError(
                    "system",
                    Resources.MessageConvenienceFee(
                        settings.convenienceFeeLabel
                    )
                );
            });
    };

    private handleCancel(): void {
        this.canceled = true;
        if (this.props.cancel) {
            this.props.cancel();
        }
    }

    private handleAfterSubmitBillDetail(saveButtonId: string) {
        if (
            saveButtonId.toLowerCase().includes("continue") ||
            saveButtonId.toLowerCase().includes("close")
        ) {
            this.setState({ isSubmitting: false });
        }
    }

    private handleSubmitted(event: any): void {
        event.preventDefault();

        if (
            !CheckFormValidity(
                this.state.id,
                this.handleFormErrors /* this.handleError */
            )
        ) {
            this.setState({ isSubmitting: false });
            return;
        }
        this.setState({ isSubmitting: true });
        this.handleSave(event);
    }

    private handleSave(event?: any): void {
        const { disableContinue } = this.state;
        if (
            !CheckFormValidity(
                this.state.id,
                this.handleFormErrors /* this.handleError */
            ) &&
            !disableContinue
        ) {
            return;
        }
        const {
            application,
            settings,
            user,
            myAccount,
            updateMessage,
            userAccounts,
            setIsPaymentCompleted,
        } = this.props;
        const { payment } = this.state;
        if (myAccount.active && !application.isBillDetail)
        {
            (payment as IAccount).tokenPaymentMethod =
                (payment as IPayment).method === "checking"
                    ? 4
                    : (payment as IPayment).method === "debit"
                        ? 2
                        : 1;
            this.handleSaveAccount(payment);
        }
        else if (application.isBillDetail)
        {
            if (
                !this.hidePaymentInformation() &&
                !this.props.autoPayTerms!.accepted &&
                settings.allowAutoBillPay
            ) {
                this.setState({
                    autoPayTermsMessage:
                        Resources.MessageAgreeTerms.props.defaultMessage,
                    isSubmitting: false,
                });
            } else {
                (payment as IAccount).tokenPaymentMethod =
                    (payment as IPayment).method === "checking"
                        ? 4
                        : (payment as IPayment).method === "debit"
                            ? 2
                            : 1;
                if ((payment as IPayment).method === "saved") {
                    const account = userAccounts.find(
                        (userAccount) =>
                            userAccount.nickname ===
                            (payment as IPayment).customerAccountName
                    );
                    account!.mobilePhone = (payment as IPayment).mobilePhone;
                    account!.mobilePhoneCountry = (
                        payment as IPayment
                    ).mobilePhoneCountry;
                    this.props.onSaveBillDetail!(
                        account!,
                        event.currentTarget.id,
                        this.handleAfterSubmitBillDetail
                    );
                } else {
                    this.props.onSaveBillDetail!(
                        payment as IAccount,
                        event.currentTarget.id,
                        this.handleAfterSubmitBillDetail
                    );
                }
            }
        }
        else
        {
            (payment as IPayment).saved = true;
            this.props.savePayment!(payment as IPayment);
            const path = application.guid
                ? `/${settings.merchantName}/verify/${application.guid}`
                : `/${settings.merchantName}/verify`;
            this.props.history.push(path);
        }
        setIsPaymentCompleted && setIsPaymentCompleted(false);
        return;
    }

    handleGooglePaySubmitted = (): void => {
        
        this.setState({ isSubmitting: true });
        this.handleGooglePaySave();
    }

    private handleGooglePaySave(): void {

        const { setIsPaymentCompleted, settings, application } = this.props;
        const { payment } = this.state;

        (payment as IPayment).saved = true;
        this.props.savePayment!(payment as IPayment);
        const path = application.guid
            ? `/${settings.merchantName}/verify/${application.guid}`
            : `/${settings.merchantName}/verify`;
        this.props.history.push(path);

        setIsPaymentCompleted && setIsPaymentCompleted(false);
        return;
    }


    private handleSubmittedOnMouseDown = (e: React.MouseEvent<Button>) => {
        e.preventDefault();
    };

    private handleSaveAccount(payment: any): void {
        const {
            application,
            settings: { merchantName },
            myAccount,
            user,
        } = this.props;
        const verb = myAccount.selected ? "PUT" : "POST";
        const account = payment as IAccount;

        if (this.props.userAccounts.length === 0) {
            account.isDefault = true;
        }

        this.props.saveAccount!(
            application,
            merchantName,
            user,
            account,
            verb,
            this.props.onSaveAccountError!,
            this.props.onSaveAccountSuccess!
        );
    }

    private handleBackButton(event: any): void {
        event.preventDefault();
        const { application, settings, myAccount } = this.props;
        if (
            myAccount.active &&
            !application.isBillDetail &&
            application.location
        ) {
            this.props.history.goBack();
        } else if (
            application.location &&
            application.location!.toLowerCase().includes("billdetail")
        ) {
            if (
                myAccount.active &&
                application.location!.toLowerCase().includes("edit")
            ) {
                //User is on page to edit an existing autopay bill
                const path = this.props.history.location.pathname
                    .toLowerCase()
                    .replace("/edit", "");
                this.props.history.push(path);
            } else {
                //User is on page to add bills to auto pay (as opposed to being on the page to edit an existing autopay bill)
                this.props.onContinueBillDetail!();
            }
        } else if (settings.skipPayorPage) {
            if (
                (this.props.application.id === 34 || application.id === 35) &&
                settings.skipBillsPage
            )
                return;
            const path = application.guid
                ? `/${settings.merchantName}/bills/${application.guid}`
                : `/${settings.merchantName}/bills`;
            this.props.history.push(path);
        } else {
            const path = application.guid
                ? `/${settings.merchantName}/payor/${application.guid}`
                : `/${settings.merchantName}/payor`;
            this.props.history.push(path);
        }
    }

    private handleBlur(event: any): void {
        CheckFieldValidity(event.target, this.handleError);
        if (
            event.target.name === "routingNumber" &&
            !event.target.validity.valid
        ) {
            this.setState({
                bankInformation: {} as IBankInformation,
            });
        }
    }

    private handleError(name: any, message: any): void {
        this.setState({
            errors: {
                ...this.state.errors,
                [name]: message,
            },
        });
    }

    private handleFormErrors = (errors: IFormError) => {
        this.setState({
            errors: {
                ...this.state.errors,
                ...errors,
            },
        });
    };

    private handleIsValid(isValid: any): void {
        const { isSubmitting } = this.state;
        if (isSubmitting && isValid) {
            this.handleSave();
        }
        this.setState({
            isSubmitting: false,
        });
    }

    private handleOnChecked(event: any): void {
        const errors = { ...this.state.errors };
        if (
            event.target.name === "sendEmailOnACHReturn" &&
            !event.target.checked
        ) {
            if (errors && errors.email) {
                errors.email = null;
            }
        }

        this.setState({
            dirty: true,
            errors: errors,
            payment: {
                ...this.state.payment,
                [event.target.name]: event.target.checked,
            },
        });
    }

    private handleChange(event: any): void {
        const { application, settings, myAccount } = this.props;
        const field = event.target.name;
        const payment = { ...this.state.payment };
        if (
            field === "country" &&
            (payment as IPayment).country === "US" &&
            event.target.value !== "US"
        ) {
            payment.state = "";
        }
        if (field === "cardNumber" && settings.chargeConvenienceFee) {
            (payment as IPayment).convenienceFee = null;
            (payment as IPayment).total = null;
        }

        (payment as any)[field] = event.target.value;
        if (!event.target.validity.valid) {
            CheckFieldValidity(event.target, this.handleError);
        }
        if (field === "customerAccountName") return;
        let futurePaymentDateError = false;
        if (field === "futurePaymentDate" && event.target.value) {
            if (event.target.validity.valid) {
                if (this.props.setFutureDatedPayment) {
                    const isFutureDatedPayment = moment(
                        event.target.value
                    ).isAfter(moment(), "day");
                    this.props.setFutureDatedPayment(isFutureDatedPayment);
                }
            } else {
                futurePaymentDateError = true;
            }
        }
        this.setState({
            dirty: true,
            payment,
            disableContinue:
                (!myAccount.active &&
                    !application.isBillDetail &&
                    (payment as IPayment).convenienceFee === null) ||
                futurePaymentDateError,
        });
    }

    private handlePhoneCountryChange(country: any): void {
        this.setState({
            payment: {
                ...this.state.payment,
                phoneCountry: country,
            },
        });
    }

    private handlePhoneChange(phone: string): void {
        this.setState({
            payment: {
                ...this.state.payment,
                phone,
            },
        });
    }

    private handleMobilePhoneCountryChange(country: any): void {
        this.setState({
            payment: {
                ...this.state.payment,
                mobilePhoneCountry: country,
            } as IPayment,
        });
    }

    private handleTabSelect(method: any) {
        const {
            application,
            settings,
            user,
            userAccounts,
            payment: initialPayment,
            myAccount,
        } = this.props;
        const payment = { ...this.state.payment };
        let disableContinue = true;
        (payment as IPayment).saveAccountInMyAccountWallet = user.isLoggedIn;
        (payment as IPayment).method = method;
        if (method === "saved") {
            const userAccount = userAccounts.find((x: any) => x.isDefault);
            disableContinue = false;
            if (userAccount) {
                (payment as IPayment).customerAccountName =
                    userAccount.nickname;
                this.handleCopyPayorInformation(userAccount);
                this.calculateFee(
                    application,
                    settings.merchantName,
                    "en",
                    this.props.billTotal,
                    userAccount.paymentMethod,
                    null,
                    null
                );
            }
        } else {
            (payment as IPayment).customerAccountName = "";
            this.copyPayorInformation(payment, this.props.payor);
        }
        payment.cardNumber = "";
        payment.nameOnAccount = "";
        payment.expirationMonth = "";
        payment.expirationYear = initialPayment.expirationYear;
        (payment as IPayment).cvc = "";
        payment.accountType = "";
        payment.routingNumber = "";
        payment.accountNumber = "";
        (payment as IPayment).confirmAccountNumber = "";
        (payment as IPayment).convenienceFee = null;

        const initialAmount = this.props.billTotal || 0;
        const isCreditOrDebit = method === "credit" || method === "debit";
        const isAch = method === "checking";
        const total = settings.chargeConvenienceFee
            ? null
            : isCreditOrDebit
            ? initialAmount
            : isAch && settings.achFeeIsAbsorbed
            ? initialAmount
            : null;
        (payment as IPayment).total = total;

        this.setState({
            payment,
            disableContinue,
            errors: {},
        });
    }

    private copyPayorInformation = (payment: any, profile: any): any => {
        const { settings } = this.props;

        if (settings.businessNameIsVisible) {
            (payment as IPayment).business = profile.business;
        }
        payment.firstName = profile.firstName;
        payment.middleName = profile.middleName;
        payment.lastName = profile.lastName;
        payment.address = profile.address;
        (payment as IPayment).country = profile.country || "US";
        payment.state = profile.state;
        payment.city = profile.city;
        payment.postal = profile.postal;
        payment.phoneCountry = profile.phoneCountry || "US";
        payment.phone = profile.phone;
        payment.mobilePhoneCountry = profile.mobilePhoneCountry || "US";
        payment.mobilePhone = profile.mobilePhone;
        payment.email = profile.email;

        return payment;
    };

    private clearPayorInformation = (payment: any): any => {
        const { settings } = this.props;

        if (settings.businessNameIsVisible) {
            (payment as IPayment).business = "";
        }
        payment.firstName = "";
        payment.middleName = "";
        payment.lastName = "";
        payment.address = "";
        (payment as IPayment).country = "" || "US";
        payment.state = "";
        payment.city = "";
        payment.postal = "";
        payment.phoneCountry = "" || "US";
        payment.phone = "";
        payment.mobilePhoneCountry = "" || "US";
        payment.mobilePhone = "";
        payment.email = "";

        return payment;
    };

    private handleClearPayorInformation(): void {
        const payment = { ...this.state.payment };
        this.setState({
            payment: this.clearPayorInformation(payment),
            errors: {
                ...this.state.errors
            },
        });
    }

    private handleCopyPayorInformation(account: any): void {
        const payment = { ...this.state.payment };
        if ((account as IAccount).nickname) {
            (payment as IPayment).customerAccountName = (
                account as IAccount
            ).nickname;
        }

        this.setState({
            payment: this.copyPayorInformation(payment, account),
            errors: {
                ...this.state.errors,
                phone: "",
            },
        });
    }

    private handleSavedAccountNotSameAsPayor = (
        customerAccountName: string
    ): void => {
        const userAccount = this.props.userAccounts.find(
            (x: any) => x.nickname === customerAccountName
        );
        if (userAccount) {
            this.handleCopyPayorInformation(userAccount);
        }
    };

    private onAutoPayTermsAcceptedChanged(event: any): void {
        this.props.updateAccepted!(event.target.checked);
        if (event.target.checked) this.setState({ autoPayTermsMessage: "" });
    }

    private onCloseBillingModal() {
        const payment = { ...this.state.payment };
        (payment as IPayment).mobilePhoneCountry =
            this.props.user.profile.mobilePhoneCountry;
        (payment as IPayment).mobilePhone = this.props.user.profile.mobilePhone;
        this.setState({ payment, errors: {} });
    }

    private defaultOrFirst(accounts: IAccount[]): IAccount | null {
        const defaultAccount = accounts.find((a) => a.isDefault);
        const [first] = accounts;

        return defaultAccount || first || null;
    }

    private hidePaymentInformation = (): boolean => {
        const {
            application,
            billDetails,
            myAccount,
            bills,
            selectedBillDetail,
        } = this.props;
        if (
            application.location &&
            application.location!.toLowerCase().includes("billdetail")
        ) {
            if (
                myAccount.active &&
                application.location!.toLowerCase().includes("edit")
            ) {
                return selectedBillDetail && selectedBillDetail.isAutopayEnabled
                    ? false
                    : true;
            } else {
                const billDetailsInBills = billDetails.filter(
                    (b: IBillDetail) =>
                        bills.length > 0 &&
                        bills.findIndex(
                            (bill: IBill) => bill.id1 === b.identifier1
                        ) >= 0
                );
                return (
                    billDetailsInBills.length > 0 &&
                    billDetailsInBills.filter((b) => b.isAutopayEnabled)
                        .length === 0
                );
            }
        } else {
            return false;
        }
    };

    private allowedCardNetworks = (): string[] => {
        const { settings } =
            this.props;
        const allowedCardNetworks = [];
        if (settings.americanExpressIsAccepted) {
            allowedCardNetworks.push("AMEX");
        }
        if (settings.masterCardIsAccepted) {
            allowedCardNetworks.push("MASTERCARD");
        }
        if (settings.visaIsAccepted) {
            allowedCardNetworks.push("VISA");
        }
        if (settings.discoverIsAccepted) {
            allowedCardNetworks.push("DISCOVER");
        }
        return allowedCardNetworks;
    };

    private hideFutureDated = (): boolean => {
        const { application, settings, myAccount, maxFuturePaymentDays } =
            this.props;
        if (
            myAccount.active ||
            application.isBillDetail ||
            !settings.futureDatedPaymentsAllowed
        )
            return true;
        return maxFuturePaymentDays && maxFuturePaymentDays > 0 ? false : true;
    };

    private showGooglePay = false;

    private useGooglePay = (): boolean => {
        const { settings, myAccount, application } = this.props;

        const isDualAuth = (settings.chargeConvenienceFee && !settings.includeConvenienceFeeWithBasePayment);

        // Disable Google Pay if the following conditions are met:
        if (
            myAccount.active ||                                 // User account is active
            !settings.googlePayWebEnabled ||                    // Google Pay web is not enabled in settings
            !settings.requireReCaptcha ||                       // ReCaptcha is not required in settings
            settings.googlePayMerchantID === '' ||              // Google Pay merchant ID is not set in settings
            isDualAuth ||                                       // Dual Auth transaction
            settings.futureDatedPaymentsAllowed ||              // Future-dated payments are allowed
            !(application.securePayPaymentType === 1) ||        // Secure pay payment type is not 1
            !(application.id === 33 || application.id === 34)   // Application ID is not 33 or 34
        ) {
            return false; // Google Pay should not be used
        }

        return true; // Google Pay can be used
    };

    public render(): JSX.Element {
        const {
            /* guid, */ application,
            settings,
            user,
            userAccounts,
            payor,
            myAccount,
            billDetails,
        } = this.props;
        const {
            id,
            payment,
            isSubmitting,
            disableContinue,
            errors,
            bankInformation,
            autoPayTermsMessage,
        } = this.state;
        const isMyAccountBillDetail =
            myAccount.active && application.isBillDetail;
        const saveButton = isSubmitting ? (
            <FontAwesomeIcon icon={faCircleNotch} spin />
        ) : myAccount.active || application.isBillDetail ? (
            Resources.ButtonSave
        ) : (
            Resources.ButtonContinue
        );
        const cancelButton =
            !this.props.myAccount.active && !application.isBillDetail ? (
                <CancelButton
                    disabled={false}
                    application={this.props.application}
                    settings={this.props.settings}
                    history={this.props.history}
                    cancel={this.handleCancel}
                />
            ) : null;
        const actionButton =
            (application.id === 34 || application.id === 35) &&
            settings.skipBillsPage &&
            settings.skipPayorPage ? null : (
                <Button
                    bsClass="btn btn-default"
                    onClick={this.handleBackButton}
                    style={{ minWidth: "6.5em" }}
                >
                    {application.isBillDetail
                        ? Resources.ButtonCancel
                        : Resources.ButtonBack}
                </Button>
            );
        const hideSaveAndContinue = settings.allowAutoBillPay
            ? (billDetails.length > 0 &&
                  billDetails.filter(
                      (b) => b.isNewRecord && !b.isAutopayEnabled
                  ).length === 0) ||
              this.hidePaymentInformation()
            : billDetails.length > 0 &&
              billDetails.filter(
                  (b) => b.isNewRecord && !b.isNotificationEnabled
              ).length === 0;
        const saveAndContinueText = isSubmitting ? (
            <FontAwesomeIcon icon={faCircleNotch} spin />
        ) : (
            Resources.ButtonSaveAndContinue
        );
        const saveAndCloseText = isSubmitting ? (
            <FontAwesomeIcon icon={faCircleNotch} spin />
        ) : (
            Resources.ButtonSaveAndClose
        );
        const newBillDetailSave =
            application.isBillDetail &&
            !isMyAccountBillDetail &&
            this.props.user.isLoggedIn;
        const continueButton =
            newBillDetailSave && hideSaveAndContinue ? (
                <Button
                    id="close"
                    bsClass="btn btn-primary"
                    disabled={disableContinue || isSubmitting}
                    onClick={this.handleSubmitted}
                    onMouseDown={this.handleSubmittedOnMouseDown}
                    style={{ minWidth: "6.5em" }}
                >
                    {saveAndCloseText}
                </Button>
            ) : newBillDetailSave && !hideSaveAndContinue ? (
                <Button
                    id="continue"
                    bsClass="btn btn-primary"
                    disabled={disableContinue || isSubmitting}
                    onClick={this.handleSubmitted}
                    onMouseDown={this.handleSubmittedOnMouseDown}
                    style={{ minWidth: "6.5em" }}
                >
                    {saveAndContinueText}
                </Button>
            ) : (
                <Button
                    bsClass="btn btn-primary"
                    disabled={disableContinue || isSubmitting}
                    onClick={this.handleSubmitted}
                    onMouseDown={this.handleSubmittedOnMouseDown}
                    style={{ minWidth: "6.5em" }}
                >
                    {saveButton}
                </Button>
            );

        const autoPayButton =
            !myAccount.active &&
            !application.isBillDetail &&
            !isMyAccountBillDetail ? (
                <AutoPayButton
                    user={this.props.user}
                    bills={this.props.bills}
                    billTypes={this.props.billTypes}
                    billDetails={this.props.billDetails}
                    settings={this.props.settings}
                    submitting={this.state.isSubmitting}
                    onClick={this.handleAutopayClick}
                />
            ) : null;

        const hideFeePanel = myAccount.active || application.isBillDetail;
        const statesToDeny = settings.statesToDeny
            ? StatesList.getStateNames(settings.statesToDeny)
            : null;
        const myAccountSelected = myAccount.selected !== undefined;
        const panelCommonProps = {
            application,
            merchant: settings,
            payment,
            billTotal: this.props.billTotal || 0,
            errors,
            onChange: this.handleChange,
            calculateFee: this.calculateFee,
            callCardType: this.callCardType,
            allowedCardNetworks: this.allowedCardNetworks(),
            hideFeePanel: hideFeePanel,
            hideFutureDated: this.hideFutureDated(),
            useGooglePay: this.useGooglePay(),
            showGooglePay: this.showGooglePay,
            maxFuturePaymentDays: this.props.maxFuturePaymentDays,
            loadAutoPayTerms: this.props.loadAutoPayTerms,
            locale: this.props.locale || "en",
        };

        const panelCommonPaymentMethodProps = {
            handleGooglePaySubmitted: this.handleGooglePaySubmitted,
            clearField: this.clearField,
            onError: this.handleError,
            onBlur: this.handleBlur,
            onChecked: this.handleOnChecked,
            showRememberAccount:
                user.isLoggedIn &&
                !myAccount.active &&
                !application.isBillDetail,
            showAccountNickName:
                (payment as IPayment).saveAccountInMyAccountWallet &&
                !myAccount.active,
            readOnlyField: myAccountSelected,
        };
        const panelCommonCardProps = {
            hideCvcPanel: myAccount.active || application.isBillDetail,
            checkExpired: myAccount.active || application.isBillDetail,
        };
        const showUserAccountTab =
            (!myAccount.active || isMyAccountBillDetail) &&
            user.isLoggedIn &&
            userAccounts.length !== 0;
        const showAutoPayTerms =
            application.isBillDetail &&
            settings.allowAutoBillPay &&
            !this.hidePaymentInformation();
        return (
            <Form id={id}>
                {!this.hidePaymentInformation() && (
                    <div className="well">
                        <Row>
                            {statesToDeny && (
                                <Alert bsStyle="info">
                                    {Resources.MessageStatesToDeny(
                                        settings.friendlyName,
                                        statesToDeny
                                    )}
                                </Alert>
                            )}
                            <Col xs={12} sm={6} md={6}>
                                <fieldset>
                                    <legend>
                                        {Resources.HeaderPaymentInformation}
                                    </legend>
                                    <Tab.Container
                                        id="PaymentInformation"
                                        activeKey={(payment as IPayment).method}
                                        onSelect={this.handleTabSelect}
                                    >
                                        <Clearfix>
                                            <Nav bsStyle="tabs">
                                                {showUserAccountTab && (
                                                    <NavItem eventKey="saved">
                                                        {Resources.MyAccounts}
                                                    </NavItem>
                                                )}
                                                {!myAccount.selected &&
                                                    settings.creditIsAccepted && (
                                                        <NavItem eventKey="credit">
                                                            <span className="hidden-xs hidden-sm">
                                                                {
                                                                    Resources.TabCreditCard
                                                                }
                                                            </span>
                                                            <span className="hidden-md hidden-lg">
                                                                {
                                                                    Resources.TabCreditCardSm
                                                                }
                                                            </span>
                                                        </NavItem>
                                                    )}
                                                {!myAccount.selected &&
                                                    settings.debitIsAccepted && (
                                                        <NavItem eventKey="debit">
                                                            <span className="hidden-xs hidden-sm">
                                                                {
                                                                    Resources.TabDebitCard
                                                                }
                                                            </span>
                                                            <span className="hidden-md hidden-lg">
                                                                {
                                                                    Resources.TabDebitCardSm
                                                                }
                                                            </span>
                                                        </NavItem>
                                                    )}
                                                {!myAccount.selected &&
                                                    settings.achIsAccepted && (
                                                        <NavItem eventKey="checking" disabled={settings.achError}>
                                                            {
                                                                settings.payByAchPrompt
                                                            }
                                                        </NavItem>
                                                    )}
                                            </Nav>
                                            <Tab.Content
                                                animation
                                                unmountOnExit
                                            >
                                                {showUserAccountTab && (
                                                    <Tab.Pane eventKey="saved">
                                                        <UserAccountPanel
                                                            {...panelCommonProps}
                                                            userAccounts={
                                                                userAccounts
                                                            }
                                                            defaultSelected={this.defaultOrFirst(
                                                                userAccounts
                                                            )}
                                                            copyAccountInformation={
                                                                this
                                                                    .handleCopyPayorInformation
                                                            }
                                                        />
                                                    </Tab.Pane>
                                                )}
                                                <Tab.Pane eventKey="credit">
                                                    <CreditCardPanel
                                                        {...panelCommonProps}
                                                        {...panelCommonPaymentMethodProps}
                                                        {...panelCommonCardProps}
                                                    />
                                                </Tab.Pane>
                                                <Tab.Pane eventKey="debit">
                                                    <DebitCardPanel
                                                        {...panelCommonProps}
                                                        {...panelCommonPaymentMethodProps}
                                                        {...panelCommonCardProps}
                                                    />
                                                </Tab.Pane>
                                                <Tab.Pane eventKey="checking">
                                                    <BankAccountPanel
                                                        {...panelCommonProps}
                                                        {...panelCommonPaymentMethodProps}
                                                        hideRoutingNumber={
                                                            myAccountSelected
                                                        }
                                                        hideConfirmAccount={
                                                            myAccountSelected
                                                        }
                                                        bankInformation={
                                                            bankInformation
                                                        }
                                                    />
                                                </Tab.Pane>
                                            </Tab.Content>
                                        </Clearfix>
                                    </Tab.Container>
                                </fieldset>
                            </Col>
                            <Col xs={12} sm={6} md={6}>
                                <fieldset>
                                    <legend>
                                        {(payment as IPayment).method ===
                                        "checking"
                                            ? Resources.HeaderAccountHolder
                                            : Resources.HeaderCardHolder}
                                    </legend>
                                    <BillingPanel
                                        application={application}
                                        merchant={settings}
                                        user={user}
                                        payor={payor}
                                        payment={payment}
                                        errors={errors}
                                        onChange={this.handleChange}
                                        onPhoneChange={this.handlePhoneChange}
                                        onError={this.handleError}
                                        onErrors={this.handleFormErrors}
                                        onBlur={this.handleBlur}
                                        onChecked={this.handleOnChecked}
                                        phoneCountryChange={
                                            this.handlePhoneCountryChange
                                        }
                                        copyPayorInformation={
                                            this.handleCopyPayorInformation
                                        }
                                        clearPayorInformation={
                                            this.handleClearPayorInformation
                                        }
                                        onSavedAccountNotSameAsPayor={
                                            this
                                                .handleSavedAccountNotSameAsPayor
                                        }
                                        hideCopyProfile={
                                            !myAccount.active ||
                                            myAccountSelected
                                        }
                                        hideCopyPayor={
                                            myAccount.active ||
                                            settings.skipPayorPage
                                        }
                                        hideReceiptEmail={
                                            myAccount.active ||
                                            application.isBillDetail
                                        }
                                        isValid={this.handleIsValid}
                                        requiredToSaveACHForFutureUse={
                                            (payment as IPayment).method ===
                                                "checking" &&
                                            (payment as IPayment)
                                                .saveAccountInMyAccountWallet
                                        }
                                        showACHReturnNotification={settings.sendACHReturnEmailsToPayor &&
                                            (((payment as IPayment).method === "saved" && (payment as IPayment).paymentMethod === 7) || 
                                            (payment as IPayment).method === "checking") &&
                                            !myAccount.active
                                        }
                                    />
                                </fieldset>
                            </Col>
                        </Row>
                    </div>
                )}
                {showAutoPayTerms && (
                    <Row>
                        <Col xs={12} sm={12}>
                            <div>
                                <AutopayTerms
                                    autoPayTerms={this.props.autoPayTerms!}
                                    payment={this.state.payment as IPayment}
                                    locale={
                                        this.props.locale
                                            ? this.props.locale
                                            : ""
                                    }
                                    handleTermsAcceptedChange={
                                        this.onAutoPayTermsAcceptedChanged
                                    }
                                />
                            </div>
                        </Col>
                    </Row>
                )}
                {showAutoPayTerms && autoPayTermsMessage && (
                    <Row>
                        <Col xs={12} sm={12}>
                            <Alert bsStyle="danger">
                                <FontAwesomeIcon icon={faExclamationTriangle} />{" "}
                                {autoPayTermsMessage}
                            </Alert>
                        </Col>
                    </Row>
                )}

                <ButtonBar
                    cancelButton={cancelButton}
                    leftActionButton={actionButton}
                    middleActionButton={autoPayButton}
                    rightActionButton={continueButton}
                />
            </Form>
        );
    }
}
