import { faExclamationTriangle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { Alert, Button, Col, Form, Panel, Row } from "react-bootstrap";
import Recaptcha from "react-google-recaptcha";
import { InjectedIntlProps, injectIntl } from "react-intl";
import { RECAPTCHA_SITEKEY } from "../../../core/constants";
import {
    AjaxErrorMessage,
    LabelEmail,
    LinkResetPassword,
    Password,
    SignIn,
} from "../../../resources";
import { IApplication, IUser } from "../../../types";
import { IFormError } from "../../../types/IFormError";
import {
    CheckFieldValidity,
    CheckFormValidity,
} from "../../../utils/Validation";
import { EmailInput, TextInput } from "../../Input";
import { BlockUi } from "../BlockUi";

export interface SignInPanelProps extends InjectedIntlProps {
    application: IApplication;
    merchantName: string;
    allowAutoPay: boolean;
    requireRecaptcha: boolean;
    isRecaptchaLoaded: boolean;
    onClose?: () => void;
    onContinue?: () => void;
    onChallengeMfa?: () => void;
    onForgotPassword: () => void;
    onRecaptchaLoaded: () => void;
    login: (
        userName: string,
        password: string,
        recaptcha?: string | null
    ) => Promise<IUser>;
    updateUser: (user: IUser) => void;
    loadUserAccounts: (
        application: IApplication,
        merchantName: string,
        authToken: string,
        onError?: (text: string) => void
    ) => void;
    loadCustomerBillDetails: (
        merchantName: string,
        authToken: string,
        merchantCustomerId: string
    ) => void;
}

export interface SignInPanelState {
    username: string;
    password: string;
    isSubmitting: boolean;
    error: string | JSX.Element;
    errors: IFormError;
    rememberDevice: boolean;
}

class SignInPanel extends React.PureComponent<
    SignInPanelProps,
    SignInPanelState
> {
    private recaptcha = React.createRef<Recaptcha>();

    constructor(props: SignInPanelProps) {
        super(props);

        this.state = {
            username: "",
            password: "",
            isSubmitting: false,
            error: "",
            errors: {},
            rememberDevice: false,
        };

        this.onResolved = this.onResolved.bind(this);
        this.handleBlur = this.handleBlur.bind(this);
        this.handleError = this.handleError.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    private handleBlur(event: any): void {
        CheckFieldValidity(event.target, this.handleError);
    }

    private handleFormErrors = (errors: IFormError): void => {
        this.setState({
            errors: {
                ...this.state.errors,
                ...errors,
            },
        });
    };

    private handleError(name: any, message: any): void {
        this.setState({
            errors: { ...this.state.errors, [name]: message },
        });
    }

    private handleChange(event: React.ChangeEvent<HTMLInputElement>): void {
        if (event.target.validity.valid) {
            CheckFieldValidity(event.target, this.handleError);
        }

        this.setState({
            ...this.state,
            [event.target.name]: event.target.value,
        });
    }

    private async handleSubmit(event: React.MouseEvent<Button>): Promise<void> {
        event.preventDefault();

        if (!CheckFormValidity("login-form", this.handleFormErrors)) {
            return;
        }
        this.setState({
            isSubmitting: true,
            errors: {},
        });

        if (this.props.requireRecaptcha) {
            const token = await this.recaptcha.current?.executeAsync();

            this.onResolved(token);
        } else {
            this.onResolved();
        }
    }

    private onResolved(token?: string | null): void {
        const {
            updateUser,
            loadUserAccounts,
            loadCustomerBillDetails,
            onClose,
            onContinue,
        } = this.props;
        const { username, password } = this.state;
        //const recaptcha = this.recaptcha.getResponse();

        this.props
            .login(username, password, token)
            .then((result: IUser) => {
                if (this.props.requireRecaptcha) {
                    this.recaptcha.current?.reset();
                }

                if (result.isLoggedIn) {
                    // if MFA is not enabled for the merchant or the user does not need to be challenged and the
                    // user has at least one factor we can set the user as verified.
                    const challengeMfa = result.mfaRequired;

                    if (!challengeMfa) {
                        result.isVerified = true;
                    }
                    updateUser(result);

                    if (challengeMfa) {
                        if (this.props.onChallengeMfa) {
                            this.props.onChallengeMfa();
                            return;
                        }
                    }

                    if (this.props.application.id !== 35) {
                        loadUserAccounts(
                            this.props.application,
                            this.props.merchantName,
                            result.authToken,
                            (text: string) => {
                                this.setState({ error: text });
                            }
                        );

                        if (this.props.allowAutoPay) {
                            loadCustomerBillDetails(
                                this.props.merchantName,
                                result.authToken,
                                result.merchantCustomerID
                            );
                        }
                    }

                    if (this.props.application.isEBPP) {
                        //Call action to load bills into EbppBills store
                    }

                    if (onClose) {
                        onClose();
                    }
                    if (onContinue) {
                        onContinue();
                    }
                } else {
                    this.setState({
                        isSubmitting: false,
                        error: result.messages[0] || AjaxErrorMessage,
                    });
                }
            })
            .catch((reason: any) => {
                if (this.props.requireRecaptcha) {
                    this.recaptcha.current?.reset();
                }
                this.setState({
                    isSubmitting: false,
                    error: AjaxErrorMessage,
                });
            });
    }

    public render(): JSX.Element {
        const { intl, /* updateUser, onContinue, */ onForgotPassword } =
            this.props;
        const { username, password, isSubmitting, error, errors } = this.state;
        return (
            <div>
                <BlockUi shouldRender={isSubmitting} />
                <Panel>
                    <Panel.Body>
                        <Row>
                            <Col xs={12} sm={12} md={12} lg={12}>
                                <Form id="login-form">
                                    <Row>
                                        <Col xs={12}>
                                            <EmailInput
                                                type="email"
                                                name="username"
                                                placeholder="example@domain.com"
                                                label={LabelEmail}
                                                value={username}
                                                error={errors.username}
                                                onChange={this.handleChange}
                                                onError={this.handleError}
                                                onBlur={this.handleBlur}
                                                required
                                            />
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col xs={12}>
                                            <TextInput
                                                type="password"
                                                name="password"
                                                placeholder={intl.formatMessage(
                                                    {
                                                        id: "message.password",
                                                    }
                                                )}
                                                label={Password}
                                                value={password}
                                                error={errors.password}
                                                onChange={this.handleChange}
                                                onError={this.handleError}
                                                onBlur={this.handleBlur}
                                                required
                                            />
                                        </Col>
                                    </Row>
                                    {error && (
                                        <Row>
                                            <Col xs={12} sm={12} md={12}>
                                                <Alert bsStyle="danger">
                                                    <FontAwesomeIcon
                                                        icon={
                                                            faExclamationTriangle
                                                        }
                                                    />{" "}
                                                    {error}
                                                </Alert>
                                            </Col>
                                        </Row>
                                    )}
                                    <Row>
                                        <Col xs={12}>
                                            <Button
                                                type="submit"
                                                bsStyle="primary"
                                                onClick={this.handleSubmit}
                                                disabled={
                                                    isSubmitting ||
                                                    !this.props
                                                        .isRecaptchaLoaded
                                                }
                                                style={{ minWidth: "6.5em" }}
                                                block
                                            >
                                                {SignIn}
                                            </Button>
                                        </Col>
                                    </Row>
                                </Form>
                            </Col>
                            <div style={{ display: "none" }}>
                                <Recaptcha
                                    ref={(ref: any) => (this.recaptcha = ref)}
                                    hl={intl.locale}
                                    size="invisible"
                                    sitekey={RECAPTCHA_SITEKEY}
                                    asyncScriptOnLoad={
                                        this.props.onRecaptchaLoaded
                                    }
                                />
                            </div>
                        </Row>
                        <Row>
                            <Col xs={12}>
                                <Button
                                    bsStyle="link"
                                    className="pull-right"
                                    onClick={onForgotPassword}
                                >
                                    {LinkResetPassword}
                                </Button>
                            </Col>
                        </Row>
                    </Panel.Body>
                </Panel>
            </div>
        );
    }
}

export default injectIntl(SignInPanel);
