import { ITransaction, ITransactions } from '../types';
import { LogoutUserAction } from './User';

export const PAYMENTHISTORY_LOAD = "redux/PaymentHistory/LOAD";
export const PAYMENTHISTORY_LOAD_SUCCESS = "redux/PaymentHistory/LOAD_SUCCESS";
export const PAYMENTHISTORY_LOAD_FAIL = "redux/PaymentHistory/LOAD_FAIL";

export type RequestTransactionAction = {
    type: typeof PAYMENTHISTORY_LOAD;
}

export type ReceiveTransactionAction = {
    type: typeof PAYMENTHISTORY_LOAD_SUCCESS;
    data: ITransactions;
}

export type FailedTransactionAction = {
    type: typeof PAYMENTHISTORY_LOAD_FAIL;
    data: string;
}

export type TransactionActions = RequestTransactionAction | ReceiveTransactionAction | FailedTransactionAction;