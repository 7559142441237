import {
    IReceipt,
    IApplication,
    IGeneratedReceiptResponse,
    IUser,
    ICpcDataResponse,
    ICpcDataRequest,
} from "../types";

export function getReceipt(
    transactionId: number,
    languageId: number
): Promise<IReceipt> {
    const baseUrl = process.env.REACT_APP_API_BASEURL;

    return fetch(
        `${baseUrl}/webapi/api/Receipt?transactionId=${transactionId}&language=${languageId}`,
        {
            mode: baseUrl ? "cors" : "same-origin",
        }
    )
        .then((response) => response.json() as Promise<IReceipt>)
        .catch((error) => error);
}

export function getReceiptWithPaymentTerms(
    application: IApplication,
    merchantName: string,
    transactionId: number,
    languageId: number
) {
    const baseUrl = process.env.REACT_APP_API_BASEURL;

    return fetch(
        `${baseUrl}/webapi/api/Receipt/template?merchantName=${merchantName}&transactionId=${transactionId}&language=${languageId}`,
        {
            method: "POST",
            mode: baseUrl ? "cors" : "same-origin",
            headers: { "Content-Type": "application/json; charset=utf-8" },
            body: JSON.stringify({
                application: application,
            }),
        }
    ).then((response) => response.json() as Promise<IReceipt>);
}

export function generateReceiptPdf(
    application: IApplication,
    user: IUser,
    transactionId: number,
    languageId: string
) {
    const baseUrl = process.env.REACT_APP_API_BASEURL;
    return fetch(`${baseUrl}/webapi/api/Receipt/generate`, {
        method: "POST",
        mode: baseUrl ? "cors" : "same-origin",
        headers: { "Content-Type": "application/json; charset=utf-8" },
        body: JSON.stringify({
            merchantCustomerId: user.merchantCustomerID !== ''? user.merchantCustomerID : null,
            transactionId: transactionId,
            language: languageId,
            application: application,
            token: user.authToken,
        }),
    }).then(
        (response) => response.json() as Promise<IGeneratedReceiptResponse>
    );
}

export function editCPCDataRequest(
    application: IApplication,
    merchantName: string,
    languageId: number,
    request: ICpcDataRequest
): Promise<ICpcDataResponse> {
    const baseUrl = process.env.REACT_APP_API_BASEURL;

    return fetch(
        `${baseUrl}/webapi/api/EditCPCData?merchantName=${merchantName}&language=${languageId}`,
        {
            method: "POST",
            mode: baseUrl ? "cors" : "same-origin",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({
                application: application,
                cardHolderPONbr: request.cardHolderPONbr,
                endUserBrowserType: request.endUserBrowserType,
                endUserIPAddress: request.endUserIPAddress,
                gatewayTxnId: Number(request.gatewayTxnId),
                taxAmount: request.taxAmount,
                taxType: request.taxType,
                transactionId: request.transactionId,
                x_global_transaction_id: request.x_global_transaction_id,
            }),
        }
    ).then((response) => response.json() as Promise<ICpcDataResponse>);
}
