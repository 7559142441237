import { Reducer } from "redux";
import {
  ApplicationMaintenanceActions,
  ApplicationMaintenanceLoadAction,
  ApplicationMaintenanceLoadFailedAction,
  ApplicationMaintenanceLoadSuccessAction,
  APPLICATION_MAINTENANCE_LOAD,
  APPLICATION_MAINTENANCE_LOAD_FAIL,
  APPLICATION_MAINTENANCE_LOAD_SUCCESS,
} from "../actionTypes/ApplicationMaintenance";
import { IApplicationMaintenance } from "../types/IApplicationMaintenanceState";

export const initialState: IApplicationMaintenance = {
  isLoaded: false,
  isLoading: false,
  error: null,
  maintenance: {
    isFeatureFlagEnabled: false,
    maintenanceEvents: [],
  },
};

const loadAction = (
  state: IApplicationMaintenance,
  action: ApplicationMaintenanceLoadAction
): IApplicationMaintenance => {
  return {
    ...state,
    isLoading: true,
    isLoaded: false,
    error: null,
  };
};

const loadSuccessAction = (
  state: IApplicationMaintenance,
  action: ApplicationMaintenanceLoadSuccessAction
): IApplicationMaintenance => {
  return {
    ...state,
    ...action.data,
    isLoading: false,
    isLoaded: true,
    error: null,
  };
};

const loadFailAction = (
  state: IApplicationMaintenance,
  action: ApplicationMaintenanceLoadFailedAction
): IApplicationMaintenance => {
  return {
    ...state,
    isLoading: false,
    isLoaded: false,
    error: action.data,
  };
};

export const reducer: Reducer<IApplicationMaintenance> = (
  state: IApplicationMaintenance = initialState,
  action: ApplicationMaintenanceActions
) => {
  switch (action.type) {
    case APPLICATION_MAINTENANCE_LOAD:
      return loadAction(state, action);
    case APPLICATION_MAINTENANCE_LOAD_SUCCESS:
      return loadSuccessAction(state, action);
    case APPLICATION_MAINTENANCE_LOAD_FAIL:
      return loadFailAction(state, action);
    default:
      return state;
  }
};
