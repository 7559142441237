import React from "react";
import * as Resources from "../../../../resources";
import ShoppingCartWrapper from "./ShoppingCartWrapper";
import { IBill } from "../../../../types";
import { Button } from "react-bootstrap";
import {
    BillSearchCartBtnCheckOut,
    BillSearchCartBtnClear,
    BillSearchCartBtnSearch,
    BillSearchCartBtnSResult,
} from "../../../../resources";
import "../../../../css/SanDiegoBillSearch/shoppingCart.css";

export interface ShoppingCartContentProps {
    bills: IBill[];
    billTotal: number;
    deleteBill: (billId: string) => void;
    clearBills: () => void;
    checkout: () => void;
    searchResults: () => void;
    newSearch: () => void;
}

class ShoppingCartContent extends React.Component<ShoppingCartContentProps> {
    constructor(props: ShoppingCartContentProps) {
        super(props);
    }

    render() {
        const buttonCssClass =
            "btn btn-large btn-primary text-center btn-custom";

        return (
            <div>
                <ShoppingCartWrapper {...this.props} />
                {Resources.BillSearchCartArticle}
                <div id="container">
                    <div className="span12 text-center">
                        <Button
                            bsClass={buttonCssClass}
                            onClick={this.props.checkout}
                        >
                            {BillSearchCartBtnCheckOut}
                        </Button>
                        <Button
                            bsClass={buttonCssClass}
                            onClick={this.props.clearBills}
                        >
                            {BillSearchCartBtnClear}
                        </Button>
                        <Button
                            bsClass={buttonCssClass}
                            onClick={this.props.searchResults}
                        >
                            {BillSearchCartBtnSResult}
                        </Button>
                        <Button
                            bsClass={buttonCssClass}
                            onClick={this.props.newSearch}
                        >
                            {BillSearchCartBtnSearch}
                        </Button>
                    </div>
                </div>
            </div>
        );
    }
}

export default ShoppingCartContent;
