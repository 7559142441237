import React from "react";
import { FormattedNumber } from "react-intl";
import {
    AddBillToCardButton,
    ViewBillButton,
    PaymentsDisabledMessage,
} from "../../../..";
import {
    BillSearchResultBtnAddCart,
    BillSearchResultBtnAddCartMobile,
    BillSearchResultBtnInCart,
} from "../../../../../../resources";
import { IBill, IBillType } from "../../../../../../types";
import { GetMaskedBillNumber } from "../../../../../../utils/BillHelpers";
import {
    BillInstallmentType,
    ICommonBill,
    SanDiegoBillType,
} from "../../../../types";
import BillUtils from "../../Tables/Mapper";

interface FirstRowOfDefaultedUnsecuredBillProps {
    trKey: string;
    itemSize: number;
    billType: IBillType | undefined;
    billTypeName: SanDiegoBillType;
    row: ICommonBill;
    addToCart: (row: IBill) => void;
    isFirstRowAddedToCart: boolean;
    isSecondRowAddedToCart: boolean;
    handleViewBill: (bill: IBill) => void;
}

export class FirstRowOfDefaultedUnsecuredBill extends React.Component<FirstRowOfDefaultedUnsecuredBillProps> {
    constructor(props: FirstRowOfDefaultedUnsecuredBillProps) {
        super(props);
    }

    handleAddToCart = () => {
        const { row, billTypeName, addToCart } = this.props;
        const bill = BillUtils.getBillFromCommonBill(
            row,
            billTypeName,
            BillInstallmentType.balance
        );
        addToCart(bill);
    };

    handleViewBillForMobile = () => {
        const { row, billTypeName } = this.props;
        const bill = BillUtils.getBillFromCommonBill(
            row,
            billTypeName,
            BillInstallmentType.balance
        );
        this.props.handleViewBill(bill);
    };

    render() {
        const {
            trKey,
            itemSize,
            billType,
            billTypeName,
            handleViewBill,
            row,
            isFirstRowAddedToCart,
            isSecondRowAddedToCart,
        } = this.props;
        const secondRowOfDefaultedUnsecuredBillPaidOrAddedToCart =
            row.secondPaymentType &&
            (!row.secondPaymentType.isPayable || isSecondRowAddedToCart);
        const renderButton =
            row.firstPaymentType.isPayable &&
            !secondRowOfDefaultedUnsecuredBillPaidOrAddedToCart;
        const showPaymentsDisabledMessage =
            !row.firstPaymentType.isPayable &&
            billType != undefined &&
            billType.paymentsDisabled === true;
        const buttonLabel = isFirstRowAddedToCart
            ? BillSearchResultBtnInCart
            : BillSearchResultBtnAddCart;
        const buttonLabelMobile = isFirstRowAddedToCart
            ? BillSearchResultBtnInCart
            : BillSearchResultBtnAddCartMobile;

        return (
            <tr className="sr-btm-rw-l search-result-tables-td" key={trKey}>
                <td
                    className="gridRowText view-bill-cell hidden-xs"
                    rowSpan={itemSize}
                >
                    <ViewBillButton
                        billType={billTypeName}
                        handleViewBill={handleViewBill}
                        row={row}
                    ></ViewBillButton>
                </td>
                <td className="gridRowText hidden-xs" rowSpan={itemSize}>
                    {GetMaskedBillNumber(billTypeName, row.parcelNumber)}
                </td>
                <td className="gridRowText visible-xs" rowSpan={itemSize}>
                    <a onClick={this.handleViewBillForMobile}>
                        {GetMaskedBillNumber(billTypeName, row.parcelNumber)}
                    </a>
                </td>
                <td className="gridRowText" rowSpan={itemSize}>
                    {row.owner}
                </td>
                <td className="gridRowText status">
                    {row.firstPaymentType.status}
                </td>
                <td className="gridRowText visible-xs">
                    {renderButton && (
                        <AddBillToCardButton
                            label={buttonLabelMobile}
                            disabled={isFirstRowAddedToCart}
                            handler={this.handleAddToCart}
                        />
                    )}
                    {showPaymentsDisabledMessage && (
                        <PaymentsDisabledMessage
                            message={billType!.paymentsDisabledMessage}
                        />
                    )}
                </td>
                <td className="gridRowText hidden-xs">
                    {row.firstPaymentType.paymentType}
                </td>
                <td className="gridRowNumeric">
                    <FormattedNumber
                        value={row.firstPaymentType.amountDue}
                        style="currency"
                        currency="USD"
                    />
                </td>
                <td className="gridRowText visible-xs">
                    {row.firstPaymentType.paymentType}
                </td>
                <td className="gridRowText action-cell hidden-xs">
                    {renderButton && (
                        <AddBillToCardButton
                            label={buttonLabel}
                            disabled={isFirstRowAddedToCart}
                            handler={this.handleAddToCart}
                        />
                    )}
                    {showPaymentsDisabledMessage && (
                        <PaymentsDisabledMessage
                            message={billType!.paymentsDisabledMessage}
                        />
                    )}
                </td>
            </tr>
        );
    }
}
