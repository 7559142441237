import { BillSearchResultAction, BillSearchResultAmountDue, BillSearchResultBillNumber, BillSearchResultDelinquent, BillSearchResultInst, BillSearchResultInstlmntAmount, BillSearchResultLate, BillSearchResultOwner, BillSearchResultParcelNumber, BillSearchResultPaymentType, BillSearchResultStatus, BillSearchResultTotalDue } from "../../../../../resources";

const securedNsuplementalGridColumns = [
    { cssClasses: 'hidden-xs', name: '' },
    { cssClasses: 'gridRowText', name: BillSearchResultParcelNumber },
    { cssClasses: 'gridRowText owner', name: BillSearchResultOwner },
    { cssClasses: 'gridRowText hidden-xs', name: BillSearchResultPaymentType },
    { cssClasses: 'gridRowText visible-xs', name: BillSearchResultInst },
    { cssClasses: 'gridRowText visible-xs', name: BillSearchResultAction },
    { cssClasses: 'gridRowNumeric hidden-xs', name: BillSearchResultInstlmntAmount },
    { cssClasses: 'gridRowText hidden-xs', name: BillSearchResultDelinquent },
    { cssClasses: 'gridRowText status', name: BillSearchResultStatus },
    { cssClasses: 'gridRowNumeric', name: BillSearchResultAmountDue },
    { cssClasses: 'gridRowText hidden-xs', name: BillSearchResultTotalDue },
    { cssClasses: 'gridRowText hidden-xs', name: BillSearchResultAction },
    { cssClasses: 'gridRowText visible-xs', name: BillSearchResultLate },
]

export const securedBillGridColumns = [...securedNsuplementalGridColumns];
export const suplementedBillGridColumns = [...securedNsuplementalGridColumns];

export const unsecuredNdefaultBillGridColumns = [
    { cssClasses: 'hidden-xs', name: '' },
    { cssClasses: 'gridRowText', name: BillSearchResultBillNumber },
    { cssClasses: 'gridRowText owner', name: BillSearchResultOwner },
    { cssClasses: 'gridRowText status', name: BillSearchResultStatus },
    { cssClasses: 'gridRowText visible-xs', name: BillSearchResultAction },
    { cssClasses: 'gridRowText hidden-xs', name: BillSearchResultPaymentType },
    { cssClasses: 'gridRowNumeric', name: BillSearchResultAmountDue },
    { cssClasses: 'gridRowText visible-xs', name: BillSearchResultPaymentType },
    { cssClasses: 'gridRowText hidden-xs', name: BillSearchResultAction },
]

export const unsecuredBillGridColumns = [...unsecuredNdefaultBillGridColumns];


export const defaultBillGridColumns = [...unsecuredNdefaultBillGridColumns]