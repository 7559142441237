import React from "react";
import SelectInput from "./SelectInput";

export class YearSelectInput extends React.Component<any, any> {
    constructor(props: any) {
        super(props);
        this.state = {
            options: [],
        };
    }

    componentWillMount() {
        const current = new Date().getFullYear();
        const years = [];
        for (let i = 0; i < 20; i++) {
            years.push(`${current + i}`);
        }
        this.setState({
            options: years,
        });
    }

    render() {
        const props = Object.assign({}, this.props);
        return <SelectInput {...props} options={this.state.options} />;
    }
}
