import React from "react";
import { Button, ButtonToolbar, Glyphicon } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleNotch } from "@fortawesome/free-solid-svg-icons";

class ButtonBar extends React.Component<any, any> {
    constructor(props: any) {
        super(props);
        this.state = {
            submitting: false,
        };
        this.handleEdit = this.handleEdit.bind(this);
        this.handleDelete = this.handleDelete.bind(this);
        this.handleDownload = this.handleDownload.bind(this);
    }
    componentWillReceiveProps(nextProps: any) {
        if (this.props !== nextProps && this.state.submitting) {
            this.setState({ submitting: this.props.submitting });
        }
    }
    handleEdit(event: any) {
        const { handleEdit, parameter } = this.props;
        handleEdit(parameter, event);
    }
    handleDelete(event: any) {
        const { handleDelete, parameter } = this.props;
        handleDelete(parameter, event);
    }
    handleDownload(event: any) {
        const { handleGenerate, parameter } = this.props;
        handleGenerate(parameter, event);
        this.setState({ submitting: true });
    }
    render() {
        const { submitting } = this.state;
        const downloadButton = submitting ? (
            <FontAwesomeIcon icon={faCircleNotch} spin />
        ) : (
            <Glyphicon glyph="download-alt" />
        );
        return (
            <div>
                {this.props.isManageAccountsForm && (
                    <div>
                        <ButtonToolbar>
                            <Button
                                bsStyle="danger"
                                bsSize="xsmall"
                                onClick={this.handleDelete}
                                style={{ minWidth: "1.9em" }}
                            >
                                <Glyphicon glyph="remove" />
                            </Button>
                            <Button
                                bsStyle="info"
                                bsSize="xsmall"
                                onClick={this.handleEdit}
                                style={{ minWidth: "1.9em" }}
                            >
                                <Glyphicon glyph="pencil" />
                            </Button>
                        </ButtonToolbar>
                    </div>
                )}
                {this.props.isPaymentHistoryForm && (
                    <div>
                        <Button
                            bsStyle="default"
                            bsSize="xsmall"
                            onClick={this.handleDownload}
                            style={{ minWidth: "1.9em" }}
                        >
                            {downloadButton}
                        </Button>
                    </div>
                )}
            </div>
        );
    }
}

//ButtonBar.propTypes = {
//    handleEdit: PropTypes.func,
//    handleDelete: PropTypes.func,
//    handleGenerate: PropTypes.func,
//    isPaymentHistoryForm: PropTypes.bool,
//    isManageAccountsForm: PropTypes.bool,
//    submitting: PropTypes.bool,
//    parameter: PropTypes.string
//};

export default ButtonBar;
