import React from "react";
import { FormattedNumber } from "react-intl";
import { AddBillToCardButton, PaymentsDisabledMessage } from "../../../..";
import {
    BillSearchResultCellInstl2,
    BillSearchResultBtnAddC2,
    BillSearchResultBtnAddC2Mobile,
    BillSearchResultBtnCBoth,
    BillSearchResultBtnCBothMobile,
    BillSearchResultBtnInCart,
} from "../../../../../../resources";
import { IBill, IBillType } from "../../../../../../types";
import {
    BillInstallmentType,
    ICommonBill,
    SanDiegoBillType,
} from "../../../../types";
import BillUtils from "../../Tables/Mapper";

interface SecondRowOfSecuredSupplementalBillProps {
    trKey: string;
    billType: IBillType | undefined;
    billTypeName: SanDiegoBillType;
    row: ICommonBill;
    addToCart: (row: IBill) => void;
    isFirstRowAddedToCart: boolean;
    isSecondRowAddedToCart: boolean;
}

export class SecondRowOfSecuredSupplementalBill extends React.Component<SecondRowOfSecuredSupplementalBillProps> {
    constructor(props: SecondRowOfSecuredSupplementalBillProps) {
        super(props);
    }

    handleAddToCart = () => {
        const { row, billTypeName, isFirstRowAddedToCart, addToCart } =
            this.props;
        if (!isFirstRowAddedToCart && row.firstPaymentType.isPayable) {
            const firstRowOfSecuredSupplementalBill =
                BillUtils.getBillFromCommonBill(
                    row,
                    billTypeName,
                    BillInstallmentType.first
                );
            addToCart(firstRowOfSecuredSupplementalBill);
        }
        const bill = BillUtils.getBillFromCommonBill(
            row,
            billTypeName,
            BillInstallmentType.second
        );
        addToCart(bill);
    };

    render() {
        const {
            trKey,
            row,
            billType,
            isFirstRowAddedToCart,
            isSecondRowAddedToCart,
        } = this.props;
        const renderButton = row.secondPaymentType!.isPayable;
        const showPaymentsDisabledMessage =
            !row.secondPaymentType!.isPayable &&
            billType != undefined &&
            billType.paymentsDisabled === true;
        let buttonLabel: JSX.Element | null = null;
        let buttonLabelMobile: JSX.Element | null = null;

        if (renderButton) {
            if (isSecondRowAddedToCart) {
                buttonLabel = BillSearchResultBtnInCart;
                buttonLabelMobile = BillSearchResultBtnInCart;
            } else {
                if (isFirstRowAddedToCart || !row.firstPaymentType.isPayable) {
                    buttonLabel = BillSearchResultBtnAddC2;
                    buttonLabelMobile = BillSearchResultBtnAddC2Mobile;
                } else {
                    buttonLabel = BillSearchResultBtnCBoth;
                    buttonLabelMobile = BillSearchResultBtnCBothMobile;
                }
            }
        }

        return (
            <tr className="sr-btm-rw-l" key={trKey}>
                <td className="gridRowText hidden-xs td-width-95p">
                    {BillSearchResultCellInstl2}
                </td>
                <td className="gridRowText visible-xs">2nd</td>
                <td className="gridRowText visible-xs">
                    {renderButton && (
                        <AddBillToCardButton
                            label={buttonLabelMobile!}
                            disabled={isSecondRowAddedToCart}
                            handler={this.handleAddToCart}
                        />
                    )}
                    {showPaymentsDisabledMessage && (
                        <PaymentsDisabledMessage
                            message={billType!.paymentsDisabledMessage}
                        />
                    )}
                </td>
                <td className="gridRowNumeric hidden-xs">
                    <FormattedNumber
                        value={row.secondPaymentType!.amount}
                        style="currency"
                        currency="USD"
                    />
                </td>
                <td className="gridRowText hidden-xs">
                    {row.secondPaymentType!.dueDate.toLocaleDateString("en-US")}
                </td>
                <td className="gridRowText status">
                    {row.secondPaymentType!.status}
                </td>
                <td className="gridRowNumeric">
                    <FormattedNumber
                        value={row.secondPaymentType!.amountDue}
                        style="currency"
                        currency="USD"
                    />
                </td>
                <td className="gridRowText action-cell hidden-xs">
                    {renderButton && (
                        <AddBillToCardButton
                            label={buttonLabel!}
                            disabled={isSecondRowAddedToCart}
                            handler={this.handleAddToCart}
                        />
                    )}
                    {showPaymentsDisabledMessage && (
                        <PaymentsDisabledMessage
                            message={billType!.paymentsDisabledMessage}
                        />
                    )}
                </td>
                <td className="gridRowText visible-xs">
                    {row.secondPaymentType!.dueDate.toLocaleDateString("en-US")}
                </td>
            </tr>
        );
    }
}
