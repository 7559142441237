import { IApplication, IBillType } from "../types";

export function loadBillTypes(
    merchantName: string,
    intlId: number,
    application: IApplication
): Promise<IBillType[]> {
    const baseUrl = process.env.REACT_APP_API_BASEURL;

    return fetch(
        `${baseUrl}/webapi/api/BillTypes?merchantName=${merchantName}&language=${intlId}`,
        {
            method: "POST",
            mode: baseUrl ? "cors" : "same-origin",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(application),
        }
    )
        .then((response) => response.json() as Promise<IBillType[]>)
        .catch((error) => error);
}

export const loadBillTypeImage = (
    application: IApplication,
    merchantName: string,
    billName: string,
    language: number
): Promise<string> => {
    const baseUrl = process.env.REACT_APP_API_BASEURL;

    return fetch(
        `${baseUrl}/webapi/api/BillTypeImage?merchantName=${merchantName}&language=${language}`,
        {
            method: "POST",
            mode: baseUrl ? "cors" : "same-origin",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({
                application,
                billName: billName,
            }),
        }
    )
        .then((response) => response.json() as Promise<string>)
        .catch((error) => error);
};
