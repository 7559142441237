import { Reducer } from "redux";
import {
  AccountActions,
  ACCOUNTS_CREATE,
  ACCOUNTS_DELETE,
  ACCOUNTS_LOAD,
  ACCOUNTS_LOAD_FAIL,
  ACCOUNTS_LOAD_SUCCESS,
  ACCOUNTS_UPDATE,
  ACCOUNT_SETASDEFAULT_SUCCESS,
  CreateAccountAction,
  DeleteAccountAction,
  FailedAccountAction,
  ReceiveAccountAction,
  RequestAccountAction,
  SetDefaultAccountSuccessAction,
  UpdateAccountAction,
} from "../actionTypes/Account";
import {
  CONFIGURATION_EBPP_LOAD_SUCCESS,
  CONFIGURATION_LOAD_FAIL,
  CONFIGURATION_LOAD_SUCCESS,
  FailedConfigurationAction,
  ReceiveConfigurationAction,
  ReceiveConfigurationEBPPAction,
} from "../actionTypes/Configuration";
import { LogoutUserAction, USER_LOGOUT } from "../actionTypes/User";
import { IAccount, IAccounts } from "../types";

export const initialState: IAccounts = {
  isLoaded: false,
  isLoading: false,
  error: null,
  accounts: [] as IAccount[],
  isSuccessful: false,
  messages: [],
};

const accountsLoadAction = (
  state: IAccounts,
  action: RequestAccountAction
): IAccounts => {
  return {
    ...state,
    isLoading: true,
    isLoaded: false,
    error: null,
  };
};

const accountsLoadSuccessAction = (
  state: IAccounts,
  action: ReceiveAccountAction
): IAccounts => {
  return {
    ...state,
    isLoading: false,
    isLoaded: true,
    error: null,
    isSuccessful: action.data.isSuccessful,
    messages: action.data.messages,
    accounts: action.data.accounts,
  };
};

const accountsLoadFailAction = (
  state: IAccounts,
  action: FailedAccountAction
): IAccounts => {
  return {
    ...state,
    isLoading: false,
    isLoaded: false,
    error: action.data,
    isSuccessful: false,
    messages: [],
    accounts: [],
  };
};

const accountsCreateAction = (
  state: IAccounts,
  action: CreateAccountAction
): IAccounts => {
  return {
    ...state,
    accounts: [...state.accounts, Object.assign({}, action.data)],
  };
};

const accountsUpdateAction = (
  state: IAccounts,
  action: UpdateAccountAction
): IAccounts => {
  return {
    ...state,
    isLoading: false,
    isLoaded: true,
    accounts: [
      ...state.accounts.filter(
        (account: IAccount) => account.nickname !== action.data.account.nickname
      ),
      Object.assign({}, action.data.account, {
        isDefault: action.data.account.isDefault,
      }),
    ],
  };
};

const accountsDeleteAction = (
  state: IAccounts,
  action: DeleteAccountAction
): IAccounts => {
  return {
    ...state,
    isLoading: false,
    isLoaded: true,
    accounts: [
      ...state.accounts.filter(
        (account: IAccount) => account.nickname !== action.data.accountName
      ),
    ],
  };
};

const accountsSetAsDefaultSuccess = (
  state: IAccounts,
  action: SetDefaultAccountSuccessAction
): IAccounts => {
  return {
    ...state,
    isLoaded: true,
    isLoading: false,
    accounts: [
      ...state.accounts.map((account: IAccount) => {
        account.nickname === action.data
          ? (account.isDefault = true)
          : (account.isDefault = false);

        return account;
      }),
    ],
  };
};

const configurationLoadSuccessAction = (
  state: IAccounts,
  action: ReceiveConfigurationAction
): IAccounts => {
  return {
    ...state,
    isLoading: false,
    isLoaded: true,
    error: null,
    accounts: action.data.accounts.accounts || [],
  };
};

const configurationEbppLoadSuccessAction = (
  state: IAccounts,
  action: ReceiveConfigurationEBPPAction
): IAccounts => {
  return {
    ...state,
    isLoading: false,
    isLoaded: true,
    error: null,
    accounts: action.data.accounts.accounts || [],
  };
};

const configurationLoadFailAction = (
  state: IAccounts,
  action: FailedConfigurationAction
): IAccounts => {
  return {
    ...state,
    isLoading: false,
    isLoaded: false,
    error: action.data,
  };
};

const userLogoutAction = (
  state: IAccounts,
  action: LogoutUserAction
): IAccounts => {
  return {
    ...initialState,
  };
};

export const reducer: Reducer<IAccounts> = (
  state: IAccounts = initialState,
  action: AccountActions
): IAccounts => {
  switch (action.type) {
    case ACCOUNTS_LOAD:
      return accountsLoadAction(state, action);
    case ACCOUNTS_LOAD_SUCCESS:
      return accountsLoadSuccessAction(state, action);
    case ACCOUNTS_LOAD_FAIL:
      return accountsLoadFailAction(state, action);
    case ACCOUNTS_CREATE:
      return accountsCreateAction(state, action);
    case ACCOUNTS_UPDATE:
      return accountsUpdateAction(state, action);
    case ACCOUNTS_DELETE:
      return accountsDeleteAction(state, action);
    case CONFIGURATION_LOAD_SUCCESS:
      return configurationLoadSuccessAction(state, action);
    case CONFIGURATION_EBPP_LOAD_SUCCESS:
      return configurationEbppLoadSuccessAction(state, action);
    case CONFIGURATION_LOAD_FAIL:
      return configurationLoadFailAction(state, action);
    case USER_LOGOUT:
      return userLogoutAction(state, action);
    case ACCOUNT_SETASDEFAULT_SUCCESS:
      return accountsSetAsDefaultSuccess(state, action);
    default:
      return state;
  }
};
