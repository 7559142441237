import { Col, Row } from "react-bootstrap";
import { FormattedDate, FormattedNumber } from "react-intl";
import { LabelAmountDue, LabelDueDate } from "../../resources";
import { CurrencyInput } from "../Input";
import ResultsButton from "./ResultsButton";

const ResultsVRow = ({
    settings,
    billType,
    bill,
    selected,
    addToBills,
    deleteFromBills,
    onChange,
    onError,
    onBlur,
    maxAmount,
    calculateAmount,
}: {
    settings: any;
    billType: any;
    bill: any;
    selected: any;
    addToBills: any;
    deleteFromBills: any;
    onChange: any;
    onError: any;
    onBlur: any;
    maxAmount: any;
    calculateAmount: any;
}) => (
    <Row
        id={["resultsVRow", bill.id].join("_")}
        style={{ verticalAlign: "middle" }}
    >
        <Col xs={12}>
            <table className="table table-striped table-bordered">
                <tbody>
                    {billType.id1.label && !billType.id1.isSearchOnly && (
                        <tr>
                            <th>{billType.id1.label}</th>
                            <td>
                                <div className="pull-right">{bill.id1}</div>
                            </td>
                        </tr>
                    )}
                    {billType.id2.label && !billType.id2.isSearchOnly && (
                        <tr>
                            <th>{billType.id2.label}</th>
                            <td>
                                <div className="pull-right">{bill.id2}</div>
                            </td>
                        </tr>
                    )}
                    {billType.id3.label && !billType.id3.isSearchOnly && (
                        <tr>
                            <th>{billType.id3.label}</th>
                            <td>
                                <div className="pull-right">{bill.id3}</div>
                            </td>
                        </tr>
                    )}
                    {billType.id4.label && !billType.id4.isSearchOnly && (
                        <tr>
                            <th>{billType.id4.label}</th>
                            <td>
                                <div className="pull-right">{bill.id4}</div>
                            </td>
                        </tr>
                    )}
                    {!settings.billDueDateIsHidden && (
                        <tr>
                            <th>{LabelDueDate}</th>
                            <td>
                                <div className="pull-right">
                                    <FormattedDate
                                        value={bill.dueDate}
                                        year="numeric"
                                        month="2-digit"
                                        day="2-digit"
                                    />
                                </div>
                            </td>
                        </tr>
                    )}
                    <tr>
                        <th>{billType.amount.label}</th>
                        <td>
                            <div className="pull-right">
                                <FormattedNumber
                                    value={bill.amountDue}
                                    style="currency"
                                    currency="USD"
                                />
                            </div>
                        </td>
                    </tr>
                    {settings.allowBillAmountToBeEdited && (
                        <tr>
                            <th style={{ verticalAlign: "middle" }}>
                                <strong>{LabelAmountDue}</strong>
                            </th>
                            <td style={{ verticalAlign: "middle" }}>
                                <div className="pull-right">
                                    <CurrencyInput
                                        name={["customVAmount", bill.id].join(
                                            "_"
                                        )}
                                        value={calculateAmount(bill)}
                                        maxAmount={maxAmount}
                                        disableScrollStep={true}
                                        disableArrowStep={true}
                                        style={{
                                            verticalAlign: "middle",
                                            minWidth: "6.0em",
                                            maxWidth: "7.0em",
                                        }}
                                        error={bill.error}
                                        onChange={onChange}
                                        onError={onError}
                                        onBlur={onBlur}
                                        disabled={
                                            selected ||
                                            ((bill.paidInFullDate ||
                                                bill.amountDue <= 0) &&
                                                !settings.allowBillOverpayment)
                                                ? true
                                                : false
                                        }
                                    />
                                </div>
                            </td>
                        </tr>
                    )}
                    <tr>
                        <td colSpan={2}>
                            <div className="text-center">
                                <ResultsButton
                                    settings={settings}
                                    bill={bill}
                                    selected={selected}
                                    deleteFromBills={deleteFromBills}
                                    addToBills={addToBills}
                                    block
                                />
                            </div>
                        </td>
                    </tr>
                </tbody>
            </table>
        </Col>
    </Row>
);

export default ResultsVRow;
