import React from "react";
import { Col, Row } from "react-bootstrap";
import { injectIntl } from "react-intl";
import * as Resources from "../../resources";
import { GetAccountType } from "../../utils/BillHelpers";
import { GetCardLast4 } from "../../utils/CardTypes";

class BankPayment extends React.Component<any, any> {
    constructor(props: any) {
        super(props);
    }

    buildInput(label: any, value: any) {
        return (
            <Row>
                <Col xs={5} sm={4} md={4}>
                    <strong>{label}</strong>
                </Col>
                <Col xs={7} sm={8} md={8}>
                    <span className="uneditable-input">{value}</span>
                </Col>
            </Row>
        );
    }

    render() {
        const {
            intl: { formatMessage },
            payment,
        } = this.props;
        const nameOnAccountInput = this.buildInput(
            Resources.LabelNameOnAccount,
            payment.nameOnAccount
        );
        const routingInput = this.buildInput(
            Resources.LabelRoutingNumber,
            payment.routingNumber
        );
        const last4 = GetCardLast4(payment.accountNumber);
        const accountType = (
            <strong>
                {formatMessage({ id: GetAccountType(payment.accountType) })}
            </strong>
        );
        const accountInput = this.buildInput(
            Resources.LabelAccountNumber,
            Resources.MessageCardInformation(accountType, last4)
        );
        return (
            <div>
                <Row>
                    <Col xs={12}>
                        {nameOnAccountInput}
                        <br />
                    </Col>
                </Row>
                <Row>
                    <Col xs={12}>
                        {routingInput}
                        <br />
                    </Col>
                </Row>
                <Row>
                    <Col xs={12}>
                        {accountInput}
                        <br />
                    </Col>
                </Row>
            </div>
        );
    }
}

export default injectIntl(BankPayment);
