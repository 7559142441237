import { IUser } from "../types";
import { ConfigurationActions } from "../actionTypes/Configuration";

export const USER_UPDATE = "redux/user/UPDATE";
export const USER_LOGOUT = "redux/user/LOGOUT";
export const USER_VERIFIED = "redux/user/VERIFIED";

export type UpdateUserAction = {
  type: typeof USER_UPDATE;
  data: IUser;
};

export type LogoutUserAction = {
  type: typeof USER_LOGOUT;
};

export type UserVerifiedAction = {
  type: typeof USER_VERIFIED;
  data: boolean;
};

export type UserActions =
  | UpdateUserAction
  | LogoutUserAction
  | UserVerifiedAction
  | ConfigurationActions;
