import { Reducer } from "redux";
import {
  RECEIPT_LOAD,
  RECEIPT_LOAD_SUCCESS,
  RECEIPT_LOAD_FAIL,
  RECEIPT_SAVE,
  RequestReceiptAction,
  ReceiveReceiptAction,
  FailedReceiptAction,
  SaveReceiptAction,
  ReceiptActions,
  RECEIPT_GENERATE,
  GenerateReceiptAction,
  RequestEditCPCDataRequest,
  ReceiveEditCPCDataAction,
  FailedEditCPCDataAction,
  EDITCPCDATA_LOAD,
  EDITCPCDATA_SUCCESS,
  EDITCPCDATA_FAIL,
} from "../actionTypes/Receipt";
import {
  BILL_IMAGE_LOAD_SUCCESS,
  ReceiveBillImageAction,
} from "../actionTypes/Bills";
import {
  CONFIGURATION_LOAD_SUCCESS,
  CONFIGURATION_EBPP_LOAD_SUCCESS,
  CONFIGURATION_LOAD_FAIL,
  ReceiveConfigurationAction,
  ReceiveConfigurationEBPPAction,
  FailedConfigurationAction,
} from "../actionTypes/Configuration";
import { CLEAR } from "../actionTypes/Common";
import { IObligor, IPayment, IPayor, IReceipt, ITransaction } from "../types";

export const initialState: IReceipt = {
  isLoaded: false,
  isLoading: false,
  isSuccessful: false,
  location: "",
  messages: [],
  error: null,
  billTypes: [],
  bills: [],
  obligor: {} as IObligor,
  payor: {} as IPayor,
  payment: {} as IPayment,
  transaction: {} as ITransaction,
  guid: "",
  paymentTerms: "",
  isDeclined: false,
  accountSaved: false,
  dataPostStatusCode: 0,
};

const receiptLoadAction = (
  state: IReceipt,
  action: RequestReceiptAction
): IReceipt => {
  return {
    ...state,
    isLoading: true,
    isLoaded: false,
    error: null,
  };
};

const receiptLoadSuccessAction = (
  state: IReceipt,
  action: ReceiveReceiptAction
): IReceipt => {
  return {
    ...state,
    ...action.data,
    isLoading: false,
    isLoaded: true,
    error: null,
  };
};

const receiptLoadFailAction = (
  state: IReceipt,
  action: FailedReceiptAction
): IReceipt => {
  return {
    ...state,
    isLoading: false,
    isLoaded: false,
    error: action.data,
  };
};

const receiptGenerateAction = (
  state: IReceipt,
  action: GenerateReceiptAction
): IReceipt => {
  return {
    ...state,
    location: action.data,
  };
};

const receiptCreateAction = (
  state: IReceipt,
  action: SaveReceiptAction
): IReceipt => {
  return {
    ...state,
    ...action.data,
    isLoading: false,
    isLoaded: true,
    error: null,
  };
};

const configurationLoadSuccessAction = (
  state: IReceipt,
  action: ReceiveConfigurationAction
): IReceipt => {
  return {
    ...state,
    ...action.data.receipt,
    isLoading: false,
    isLoaded: true,
    error: null,
  };
};

const configurationEbppLoadSuccessAction = (
  state: IReceipt,
  action: ReceiveConfigurationEBPPAction
): IReceipt => {
  return {
    ...state,
    ...action.data.receipt,
    isLoading: false,
    isLoaded: true,
    error: null,
  };
};

const configurationLoadFailAction = (
  state: IReceipt,
  action: FailedConfigurationAction
): IReceipt => {
  return {
    ...state,
    isLoading: false,
    isLoaded: false,
    error: action.data,
  };
};

const billImageLoadSuccessAction = (
  state: IReceipt,
  action: ReceiveBillImageAction
): IReceipt => {
  return {
    ...state,
    bills: state.bills.map((b) => {
      if (b.id === action.billId || b.billId === action.billId) {
        b.imageContent = action.data.billImage.fileContent;
        b.detectedMIMEType = action.data.billImage.detectedMIMEType;
      }
      return b;
    }),
  };
};

const cpcDataLoadAction = (
  state: IReceipt,
  action: RequestEditCPCDataRequest
): IReceipt => {
  return {
    ...state,
    isLoading: true,
    isLoaded: false,
    error: null,
  };
};

const cpcDataSuccessAction = (
  state: IReceipt,
  action: ReceiveEditCPCDataAction
): IReceipt => {
  return {
    ...state,
    transaction: {
      ...state.transaction,
      isCPCEditSuccessful: action.data.isSuccessful,
    },
    isLoading: false,
    isLoaded: true,
    error: null,
  };
};

const cpcDataFailAction = (
  state: IReceipt,
  action: FailedEditCPCDataAction
): IReceipt => {
  return {
    ...state,
    transaction: { ...state.transaction, isCPCEditSuccessful: false },
    isLoading: false,
    isLoaded: false,
    error: action.data,
  };
};

export const reducer: Reducer<IReceipt> = (
  state: IReceipt = initialState,
  action: ReceiptActions
) => {
  switch (action.type) {
    case RECEIPT_LOAD:
      return receiptLoadAction(state, action);
    case RECEIPT_LOAD_SUCCESS:
      return receiptLoadSuccessAction(state, action);
    case RECEIPT_LOAD_FAIL:
      return receiptLoadFailAction(state, action);
    case RECEIPT_GENERATE:
      return receiptGenerateAction(state, action);
    case RECEIPT_SAVE:
      return receiptCreateAction(state, action);
    case CONFIGURATION_LOAD_SUCCESS:
      return configurationLoadSuccessAction(state, action);
    case CONFIGURATION_EBPP_LOAD_SUCCESS:
      return configurationEbppLoadSuccessAction(state, action);
    case CONFIGURATION_LOAD_FAIL:
      return configurationLoadFailAction(state, action);
    case BILL_IMAGE_LOAD_SUCCESS:
      return billImageLoadSuccessAction(state, action);
    case EDITCPCDATA_LOAD:
      return cpcDataLoadAction(state, action);
    case EDITCPCDATA_SUCCESS:
      return cpcDataSuccessAction(state, action);
    case EDITCPCDATA_FAIL:
      return cpcDataFailAction(state, action);
    case CLEAR:
      return { ...initialState };
    default:
      return state;
  }
};
