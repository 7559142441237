import React from "react";
import { connect } from "react-redux";
import { Post } from "../../utils/Post";
import {
    HeaderPaymentCancelled,
    MessagePaymentCancelled,
    LinkStartOver,
} from "../../resources";
import { IApplicationState } from "../../types";

class Canceled extends React.Component<any, any> {
    constructor(props: any) {
        super(props);
        this.handleStartOver = this.handleStartOver.bind(this);
    }
    handleStartOver(event: any) {
        event.preventDefault();
        this.setState({
            showModal: false,
        });
        const { application, settings } = this.props;
        if (application.id === 33) {
            this.props.history.push(`/${settings.merchantName}`);
        } else {
            const data = {
                GUID: application.guid,
                DataPostMode: "Cancel",
            };
            if (application.id === 35) {
                Post(settings.securePayMOTOCancelUrl, data);
            } else {
                Post(settings.cancelUrl, data);
            }
        }
    }
    render() {
        return (
            <div className="margin-top-20">
                <div>
                    <h2 className="page-header">{HeaderPaymentCancelled}</h2>
                    <p>{MessagePaymentCancelled}</p>
                    <p>
                        <a href="#" onClick={this.handleStartOver}>
                            {LinkStartOver}
                        </a>
                    </p>
                </div>
            </div>
        );
    }
}

export default connect((state: IApplicationState) => ({
    application: state.application,
    settings: state.settings,
}))(Canceled);
