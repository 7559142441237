import {
    IApplication,
    IBill,
    IBillImageResponse,
    ISecurePayData,
} from "../types";

export function getBills(
    merchantName: string,
    guid?: string
): Promise<ISecurePayData> {
    const baseUrl = process.env.REACT_APP_API_BASEURL;

    return fetch(
        `${baseUrl}/webapi/api/Bills/Get?merchantName=${merchantName}&guid=${guid}`,
        {
            mode: baseUrl ? "cors" : "same-origin",
        }
    )
        .then((response) => response.json() as Promise<ISecurePayData>)
        .catch((error) => error);
}

export function getEBPPBills(
    merchantName: string,
    guid: string,
    application: IApplication,
    merchantCustomerId: string | null,
    authToken: string
): Promise<ISecurePayData> {
    const baseUrl = process.env.REACT_APP_API_BASEURL;

    return fetch(
        `${baseUrl}/webapi/api/Bills/GetEBPPBills?merchantName=${merchantName}&guid=${
            guid || ""
        }&merchantCustomerId=${merchantCustomerId}`,
        {
            method: "POST",
            mode: baseUrl ? "cors" : "same-origin",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({
                application,
                authToken,
            }),
        }
    )
        .then((response) => response.json() as Promise<IBill>)
        .catch((error) => error);
}

export function getBillImage(
    merchantName: string,
    billId: string,
    application: IApplication
): Promise<IBillImageResponse> {
    const baseUrl = process.env.REACT_APP_API_BASEURL;

    return fetch(
        `${baseUrl}/webapi/api/Bills/GetBillImage?merchantName=${merchantName}&billId=${billId}`,
        {
            method: "POST",
            mode: baseUrl ? "cors" : "same-origin",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({
                application,
            }),
        }
    )
        .then((response) => response.json() as Promise<IBillImageResponse>)
        .catch((error) => error);
}
