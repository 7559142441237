import React from "react";
import { connect } from "react-redux";
import { set } from "../../../actions/Intl";
import { loadSettings } from "../../../actions/Settings";
import { loadApplicationBillTypes } from "../../../actions/BillType";
import { find, map } from "lodash";
import { NavDropdown, MenuItem } from "react-bootstrap";
import { getLanguageCode } from "../../../utils/Intl";
import {
    IApplication,
    IApplicationState,
    IInternationalization,
    ISettings,
} from "../../../types";

export interface ILanguageSelectorOwnProps {
    closeMenu?: VoidFunction;
    stopPropagation: React.MouseEventHandler<NavDropdown>;
}

export interface ILanguageSelectorStateProps {
    application: IApplication;
    intl: IInternationalization;
    settings: ISettings;
}

export interface ILanguageSelectorDispatchProps {
    setIntl: (language: string) => void;
    loadSettings: (
        merchantName: string,
        intlId: number,
        application: IApplication
    ) => void;
    loadBillTypes: (
        merchantName: string,
        intlId: number,
        application: IApplication
    ) => void;
}

export type LanguageSelectorProps = ILanguageSelectorOwnProps &
    ILanguageSelectorStateProps &
    ILanguageSelectorDispatchProps;

export interface ILanguageSelectorState {
    languages: { locale: string; name: string }[];
}

class LanguageSelector extends React.Component<
    LanguageSelectorProps,
    ILanguageSelectorState
> {
    constructor(props: LanguageSelectorProps) {
        super(props);
        const { settings } = this.props;
        const languages = [{ locale: "en", name: "English" }];
        if (settings.enableSpanish) {
            languages.push({ locale: "es", name: "Español" });
        }
        if (settings.enableChinese) {
            languages.push({ locale: "zh", name: "中文" });
        }
        if (settings.enableVietnamese) {
            languages.push({ locale: "vi", name: "Tiếng Việt" });
        }
        if (settings.enableArmenian) {
            languages.push({ locale: "hy", name: "հայերեն" });
        }
        if (settings.enableKorean) {
            languages.push({ locale: "ko", name: "한국어" });
        }
        this.state = {
            languages: languages,
        };
        this.handleSelect = this.handleSelect.bind(this);
    }

    handleSelect(eventKey: any) {
        const { setIntl, application, loadSettings, loadBillTypes, settings } =
            this.props;

        loadSettings(
            settings.merchantName,
            getLanguageCode(eventKey),
            application
        );
        loadBillTypes(
            settings.merchantName,
            getLanguageCode(eventKey),
            application
        );
        setIntl(eventKey);
        if (this.props.closeMenu) {
            this.props.closeMenu();
        }
    }

    render() {
        const { languages } = this.state;
        const options = map(languages, function (language: any, index: any) {
            return (
                <MenuItem key={index} eventKey={language.locale}>
                    <span>{language.name}</span>
                </MenuItem>
            );
        });
        const language: any = find(this.state.languages, {
            locale: this.props.intl.locale,
        });
        const selected = <span>{language.name}</span>;
        return (
            <NavDropdown
                title={selected}
                onMouseDown={this.props.stopPropagation}
                onSelect={this.handleSelect}
                id="language-dropdown"
            >
                {options}
            </NavDropdown>
        );
    }
}

const mapStateToProps = (state: IApplicationState) => ({
    intl: state.intl,
    settings: state.settings,
    application: state.application,
});

const mapDispatchToProps = {
    setIntl: set,
    loadSettings,
    loadBillTypes: loadApplicationBillTypes,
};

export default connect(mapStateToProps, mapDispatchToProps)(LanguageSelector);
